import React from 'react';
import Table from 'react-bootstrap/Table';


function EIBenefitsHome(props: any) {
	   
  return (
   <div className="masthead">
			<h1>Benefits of Emotional Intelligence at Home</h1>
			
      <div className="separator"></div>
			<p>This lesson is still under development. Please come back later!</p>

			<Table striped borderless hover responsive className="course-table">
				<thead>
					<tr><th className="course-table-header">Module 1: Introduction To Emotional Intelligence</th></tr>
				</thead>
				
				<tbody className="course-table-body">
					<tr> <td> <a href="/what-is-ei">What is emotional intelligence?</a></td> </tr>
					<tr> <td> <a href="/why-ei-matters">Why does emotional intelligence matter in our lives?</a></td> </tr>
					<tr> <td> <a href="/grow-your-ei">How can you grow your emotional intelligence skills?</a></td> </tr>
					<tr> <td> <a href="/elements-of-trust">The 3 elements of trust: Likability, Expertise, Reliability</a></td> </tr>
					<tr> <td> <a href="/ei-benefits-work">Benefits of emotional intelligence at work</a></td> </tr>
					<tr> <td> <a href="/strategies-for-ei">Strategies to build the 4 elements of emotional intelligence (WIP)</a></td> </tr>
				</tbody>
			</Table>			

			<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
            </div>
      
      <div className="separator"></div>
      <div className="separator"></div>
      
   </div>
  )
}
export default EIBenefitsHome;

