import React from 'react';
import { Accordion, Card, Table } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useTranslation } from "react-i18next";


import newspaperimg from '../images/newspaper.jpg';
import adventureimg from '../images/adventure.jpg';
import carefreeimg from '../images/carefree.jpg';
import cleandeskimg from '../images/cleandesk.jpg';
import readingimg from '../images/reading.jpg';
import partyimg from '../images/party.jpg';
import trustimg from '../images/trust.jpg';
import disagreementimg from '../images/disagreement.jpg';
import worry1img from '../images/worry-1.jpg';
import worry3img from '../images/worry-3.jpg';
import relaxedimg from '../images/relaxed.jpg';
import Ospectrum from '../images/openness-spectrum.png';
import Cspectrum from '../images/conscientiousness-spectrum.png';
import Espectrum from '../images/extroversion-spectrum.png';
import Aspectrum from '../images/agreeableness-spectrum.png';
import Nspectrum from '../images/nervosity-spectrum.png';
import conversation2 from '../images/conversation2.jpg';
import conversation3 from '../images/conversation3.jpg';
import conversation4 from '../images/conversation4.jpg';
import conversation5 from '../images/conversation5.jpg';
import conversation6 from '../images/conversation6.jpg';
import conversation7 from '../images/conversation7.jpg';
import conversation8 from '../images/conversation8.jpg';
import conversation9 from '../images/conversation9.jpg';
import highfive from '../images/highfive.jpg';
import communicationtriangle from '../images/communication-triangle.png';
import omismatch from '../images/openness-mismatch.png';
import cmismatch from '../images/conscientiousness-mismatch.png';
import emismatch from '../images/extroversion-mismatch.png';
import amismatch from '../images/agreeableness-mismatch.png';
import nmismatch1 from '../images/nervosity-mismatch-1.png';
import nmismatch2 from '../images/nervosity-mismatch-2.png';



function OceanMisunderstandings(props: any) {

	const { i18n } = useTranslation();


  return (
   <div className="masthead">
		<h1>Misunderstandings of OCEAN Personality Traits</h1>

		<p>Remember that OCEAN stands for:</p>

		<ul>
			<li>Openness to experience</li>
			<li>Conscientiousness</li>
			<li>Extroversion</li>
			<li>Agreeableness</li>
			<li>Nervosity</li>
		</ul>

		<div className="notabene">
			It's important to understand that your personality traits are not entirely fixed in time. Your experiences shape your personality traits over time.
		</div>
		
		<p>For instance, as they become older, people tend to become:</p>

		<ul>
			<li>More agreeable and conscientious</li>
			<li>Less open to experiences, extraverted, and nervous</li>
		</ul>

		<p>Still, knowing about our personality traits is helpful, because it allows us to design lives which will take more advantages of our strengths, while we search for strategies on how we can compensate for our weaknesses and fix the anchors which hold us back.</p>
		
		
		<h3>Discover YOUR Personality Traits</h3>

		<p>This article will help you learn more about the Big Five OCEAN personality traits as well as each of their individual six facets, explaining the differences existing between people who score higher or lower in each of them. <b>Learn more about YOUR personality traits by taking a personal assessment.</b></p>
		
		<div className="content-btn-box">
			<a href="/user/premium/my-assessments" className="content-btn">Discover YOUR Traits<Icon.ArrowRight className="ml-2" /></a>
		</div>

		<hr />

		<h2 id="Otitle">Example of Openness To Experience Misunderstandings</h2>


		<section className="story">

		<h3>At Work</h3>
		<div className="myimghor">
			<img src={omismatch} alt="Openness to experience mismatch" />
		</div>
		<p><b>Pauline, a High-Openness manager</b>, has recently taken over Patricia and Gary’s team. Together, the team has discussed how they can improve how they work as individual contributors and as a team.</p>
		<p>Unfortunately, Pauline did not realize that <b>Gary, a strongly Low-Openness person</b>, feels overwhelmed by all the changes she wants to implement. Gary has been with the company for over twenty years and enjoys the routines he created around his responsibilities and his workflow.</p>
		<p>Frustrated by Pauline’s unwillingness to understand how this situation affects him, he starts shutting down and struggles to adjust. Over time, their relationship sours, and his performance slowly declines.</p>
		
		<h4>What could Pauline have done differently? </h4>
		<p>She should have realized which of Gary's routines were helping him and others be more productive and which were counterproductive. Then, she could discuss with him which of the new tasks she wanted to implement could leverage his strengths, without creating too much uncertainty and novelty in his work.</p>
		
		<h4>What about Gary? </h4>
		<p>He needs to understand Pauline's desire to help Patricia and him do an even better job at contributing to the company's mission. She is not advocating for change for change's sake, but to make sure they stay engaged in helping their clients. He also needs to take a critical look at how new tools and systems could help reduce his workload, rather than continuing working long hours to do repetitive tasks himself. This way, the free time he would gain could be used to do the work he actually loves doing.</p>

		<div className="separator-large" />
		</section>

		<section className="story">
		<h3>With Family and Friends</h3>
		<div className="myimghor">
			<img src={omismatch} alt="Openness to experience mismatch" />
		</div>
		
		<p>Patrick, a High-Openness, adventurous man, is happily married to Gwen, a creature of habit. Every week, Patrick suggests a new and exciting activity they could try together. Usually, he feels disappointed because Gwen would rather do the activities they've cherished since they met.</p>
		<p>His need to experience novelty and discover new places leaves him craving for more and upset with her inflexibility. Meanwhile, Gwen sometimes feels harassed by his endless pleas for adventure. She wonders if he understand how much their beloved habits means to her? Over time, they start growing apart from each other.</p>

		<h4>How could Patrick convince Gwen without rushing her?</h4>
		<p>He could discuss with her why she loves some of their activities and not others, to understand whether they can find new ones which would satisfy both his need for novelty and her need for stability. Perhaps Gwen is scared by some of the audacious adventures Patrick finds exciting? He could also reflect on how much some of their routines mean to him and see how they could spend more quality time engaged in these activities.</p>
		
		<h4>What about Gwen?</h4>
		<p>She could ask him to share which new activities he is truly looking forward to trying, and reflect on which ones she would also enjoy doing, based on her deep understanding of what makes her tick. After all, they've enjoyed hiking through the south of France last summer, why not try exploring the Swiss mountains and sleeping in a hut? This way, he could go on an adventure in a new place, while she could do something she relishes as well. After all, Patrick suggested the French-hiking-experience and he was right!</p>
		
		<div className="separator-large" />
		</section>
		


		<h3>How To Work Well With High-Openness People</h3>
		<div className="myimghor">
			<img src={conversation8} alt="Working with high-openness individuals" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@cottonbro" >cottonbro</a> from <a href="https://www.pexels.com/photo/man-and-woman-leaning-on-table-staring-at-white-board-on-top-of-table-having-a-meeting-3205570/" >Pexels</a></p>
		</div>

		<h4>At Work</h4>
		<p>Ask <b>what new skill sets or experiences they want to acquire</b> and assign them on relevant projects that match their interests and development plans. Encourage their desire to learn new things and give them enough time and resources to work on expanding their skill sets. </p>
		<p>Emphasize the benefits or opportunities when pitching them to try something new. Then, let them share their thoughts, ideas or concerns to build on your proposal. Let them internalize them and make them their own.</p>


		<h4>With Family and Friends</h4>
		<p>Ask what new experiences they want to explore and reflect on which ones you would also enjoy doing with them. Encourage their desire to learn new things and give them enough time and resources to become better at them.</p>
		<p>Ask which new destinations they want to discover and find which ones match with your personal interests. Where could you travel and still be able to engage in activities you enjoy doing? For instance, if you shun music festivals but enjoy art galleries, ask them which ones you could visit together. Search for common interests!</p>
		<p>Ask them to share about their passions and emotions, as they are likely more attuned to them than other people.</p>




		<h3>How To Work Well With Low-Openness People</h3>

		<div className="myimghor">
			<img src={conversation3} alt="Working with low-openness individuals" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@mentatdgt-330508" >mentatdgt</a> from <a href="https://www.pexels.com/photo/man-sitting-on-chair-in-front-of-woman-2029619/" >Pexels</a></p>
		</div>

		<h4>At Work</h4>
		<p>Look at and ask about their previous and favorite experiences, and assign them on projects that <b>build on their existing skill sets and interests</b>. Focus on what they already know and are comfortable with. </p>
		<p>Encourage them to <b>deepen their areas of expertise</b>, because assigning them to too many new, unfamiliar tasks may scare them and impact their well-being and performance.</p>
		<p>Change management can be especially challenging for Low-Openness people. In their minds, you cannot spell <b><i>CHA</i></b>LLE<b><i>NGE</i></b> without <b><i>CHANGE</i></b>. They are likely going to apprehend changes forced in their lives.</p>
		<p>To make things easier on them, <b>emphasize what is not changing</b>. Then, reassure them by sharing all the facts that explain why change is necessary and how they can benefit from it. How can this change improve their daily lives and work? The goal is to minimize the change or the efforts they will have to put in as a result of the change.</p>


		<h4>With Family and Friends</h4>
		<p>Ask about their favorite experiences and try to understand why they mean so much to them. Then, reflect on which activities you would also enjoy doing and let them lead you through them!</p>
		<p>Ask them to share about what interests them the most and search for gifts which would enable them to deepen their knowledge and skills.</p>
		<p>When asking them to try something new, empasize what is close to what they already know and love and discuss with them how they could enjoy trying this variation. Perhaps it will become their new favorite!</p>


		<h3>Questions To Ask To Evaluate Openness To Experience</h3>
		<h4>Have you ever tried ____? <br /> Would you?</h4>
		<p><b>High-Openness individuals</b> will likely answer with indifference if they already have, or with great curiosity if they haven’t, asking you questions to know more about the experience.</p>
		<p><b>Low-Openness individuals</b> will likely answer with enthusiasm if the experience became part of their routines, or with reluctance if they didn’t, or with apprehension from a new experience that makes them uncomfortable.</p>

		<h4>What’s your favorite ____? <br /> What else do you like?</h4>
		<p><b>High-Openness individuals</b> will likely have a broader range of experiences to share, investigating about what others they might have missed and will have to experience for themselves in the future.</p>
		<p><b>Low-Openness individuals</b> will likely list only a few - if not just the one - preferences, emphasizing how this is their favorite and how they may not want to be disappointed by other experiences.</p>

		<h4>What’s your plan for your next holiday? <br /> Have you already been there?</h4>
		<p><b>High-Openness individuals</b> will likely talk about new places they want to visit or which will provide them with the opportunity to experience new and exciting adventures.</p>
		<p><b>Low-Openness individuals</b> will likely talk about places they have already visited and are fond of, because they feel familiar. They may also mention their favorite spots and activities they usually do there.</p>

		<div className="separator" />
		<div className="separator" />
		<hr/>


		<h2 id="Ctitle">Conscientiousness</h2>

		<div className="myimghor">
			<img src={Cspectrum} alt="Conscientiousness spectrum" />
		</div>

		<div className="imgcaption">
			<p>The conscientiousness spectrum</p>
		</div>

		<p>This trait describes how you get things done:</p>

		<ul>
			<li>How self-disciplined are you?</li>
			<li>How organized are you?</li>
			<li>How reliable are you?</li>
			<li>How persistent are you?</li>
		</ul>


		<h3>High-Conscientiousness Description</h3>

		<div className="myimghor">
			<img src={cleandeskimg} alt="High conscientiousness clean desk" />
		</div>

		<div className="imgcaption">
			<p>Being organized makes life easier, right?</p>
			<p>Photo by <a href="https://www.pexels.com/@ken-tomita-127057" >Ken Tomita</a> from <a href="https://www.pexels.com/photo/architecture-business-clean-computer-389818/" >Pexels</a></p>
		</div>

		<p>You tend to pay great attention to details, organize your life meticulously and enjoy feeling like you are in control of the situation.</p>

		<div className="quote">
			<p><i>"For every minute spent organizing, an hour is earned."</i></p>
			<p><b>- Benjamin Franklin</b></p>
        </div>

		<h4>How You May Be Perceived</h4>
		<p>Because of your attention to details and efforts for being organized, others - especially people with lower levels of conscientiousness - may perceive you as controlling, rigid or as a perfectionist. </p>
		
		<h4>Self-development advice</h4>
		<p>Make the most of your strengths, because they allow you to be highly reliable and will help you build a positive reputation as someone people can count on to get things done.</p>
		<p>Stay aware, especially when you communicate with others, that they may likely not need as much information as you do, and may even feel overwhelmed by it. Start with simple, accessible information and move to more accurate information if they ask for more details or clarifications.</p>
	
		<h3>The 6 High Conscientiousness Facets</h3>

		<p>Conscientiousness can be broken down into six facets, which describe how industrious and orderly you are:</p>

		<ol>
			<li><b>Achievement striving</b> describes your need for personal achievement and sense of direction.</li>
			<li><b>Self-efficacy</b> describes your beliefs about how well you can execute courses of action required to deal with prospective situations. <a href="https://www.semanticscholar.org/paper/Self-efficacy-mechanism-in-human-agency.-Bandura/8beec556fe7a650120544a99e9e063eb8fcd987b" >Source</a></li>
			<li><b>Self-discipline</b> describes your capacity to begin tasks and follow through to completion despite boredom or distractions.</li>
			<li><b>Cautiousness</b> describes your tendency to think things through before acting or speaking.</li>
			<li><b>Dutifulness</b> describes how important fulfilling your moral obligations is to you.</li>
			<li><b>Orderliness</b> describes your ability to be well organized and diligent.</li>
		</ol>

		<br/> <br/>

		<section className="accordion-width">
		<Accordion>
			<Card className="border-0">
				<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
				<b className="mr-3">{i18n.t('Discover Your 6 Conscientiousness Facets')}</b><Icon.ChevronDown className="ml-2"/>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body>
						
					<h4 id="Cachievement">C1 | High Achievement-Striving</h4>
					<p>You are naturally skilled at getting things done. You are hard-working and turn plans into actions, plunging into tasks with all your heart and sticking to them until completion. You go straight for your goals, never giving up.</p>
					<p>You are ambitious and set high standards for yourself and others and tend to do more than what’s expected of you. This helps you to become a high-achiever who stays goal-oriented no matter what. Your ambition and diligent work may make others perceive you as a workaholic. </p>

					<div className="quote">
						<p><i>"I find that the harder I work, the more luck I seem to have."</i></p>
						<p><b>- Thomas Jefferson</b></p>
					</div>

					<h4 id="Cselfefficacy">C2 | High Self-Efficacy</h4>
					<p>You excel in what you do and know how to get things done. Your diligence allows you to successfully and smoothly complete your tasks. You are confident that you will be able to overcome difficult situations and come up with good solutions to any problems you face.</p>

					<div className="quote">
						<p><i>"It always seems impossible until it's done."</i></p>
						<p><b>- Nelson Mandela</b></p>
					</div>

					<h4 id="Cselfdiscipline">C3 | High Self-Discipline</h4>
					<p>You start tasks right away and finish them promptly when they are important. Getting to work at once is a priority for you, as is punctuality. You see yourself as a planner who spends time to prepare, which helps you to carry out your plans. </p>
					<p>You are more controlled than random and are able to regulate immediate gratification with your self-discipline. Your perseverance and grit allow you to obtain extraordinary results.</p>

					<div className="quote">
						<p><i>"We all have dreams. But in order to make dreams come into reality, it takes an awful lot of determination, dedication, self-discipline, and effort."</i></p>
						<p><b>- Jesse Owens</b></p>
					</div>

					<h4 id="Ccautiousness">C4 | High Cautiousness</h4>
					<p>Getting things right is a priority to you. You actively seek to avoid mistakes because of your desire for quality and accuracy. Sometimes, you may need to check your work two or three times before being satisfied. You can never be too careful and controlled work matters to you.</p>
					<p>Predictable outcomes are important to you, which will drive you to make sure that everyone is on the same page before proceeding. Because of this, you may ask a lot of in-depth, skeptical questions to clarify what others are thinking, or point out flaws that others may have missed. Paying attention to details matters to you.</p>
					<p>You like to take time to carefully think things through before you speak to make sure people will clearly understand what you think. You also tend to prefer analyzing all the options available, taking your time rather than taking quick or risky decisions. Stay aware that your careful nature and slower pace may be perceived by others as perfectionism.</p>

					<div className="quote">
						<p><i>"Be not afraid of going slowly, be afraid only of standing still."</i></p>
						<p><b>- Chinese proverb</b></p>
					</div>

					<h4 id="Cdutifulness">C5 | High Dutifulness</h4>
					<p>Fulfilling your duties and keeping your promises is a priority to you. You listen to your conscience and try your best do to the right thing and do it the right way: Telling the truth, following the rules, and paying your bills on time is natural and important to you.</p>

					<div className="quote">
						<p><i>"Good people do not need laws to tell them to act responsibly, while bad people will find a way around the laws."</i></p>
						<p><b>- Plato</b></p>
					</div>

					<h4 id="Corderliness">C6 | High Orderliness</h4>
					<p>You prefer structured environments, order, regularity, and doing things methodically, systematically, and efficiently. Following a set schedule, being organized, doing things according to a plan and doing everything “just right” comes naturally to you.</p>
					<p>Thanks to these skills, people may perceive you as being more responsible, dependable, reliable, and efficient than others to obtain predictable and optimal outcomes.</p>

					<div className="quote">
						<p><i>"Fools wait for a lucky day, but everyday is a lucky day for an industrious man."</i></p>
						<p><b>- Gautama Buddha</b></p>
					</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
		</section>

		<br/> <br/>


		<h3>Low-Conscientiousness Description</h3>

		<div className="myimghor">
			<img src={carefreeimg} alt="Low conscientiousness carefree" />
		</div>

		<div className="imgcaption">
			<p>Being carefree and easygoing makes life more enjoyable, right?</p>
			<p>Photo by <a href="https://www.pexels.com/@nappy" >nappy</a> from <a href="https://www.pexels.com/photo/female-leisure-recreation-relaxation-1154638/" >Pexels</a></p>
		</div>

		<p>You tend to prefer strategic thinking and keeping things simple, avoiding the hassle of planning everything in excruciating details.</p>

		<div className="quote">
            <p><i>"Perfection is achieved, not when there is nothing more to add, but when there is nothing left to take away."</i></p>
            <p><b>- Antoine de Saint-Exupéry</b></p>
        </div>

		<h4>How You May Be Perceived</h4>
		<p>Your desire for being more strategic and your ability to navigate uncertainty with fewer information is a strength to making tough decisions in difficult times.</p>
		<p>However, people - especially individuals with higher levels of conscientiousness - may start questioning how reliable you are when you forget about what they asked you to do or when you miss a deadline. They may look down upon your tendency to forget important events or discussions, or on how unorganized it makes you look.</p>
		<p>Taking good notes, planning your priorities in advance and preparing for emergencies are simple steps you can take to become better prepared without having to worry too much about it.</p>

		<h3>The 6 Low Conscientiousness Facets</h3>

		<p>Conscientiousness can be broken down into six facets, which describe how industrious and orderly you are:</p>

		<ol>
			<li><b>Achievement striving</b> describes your need for personal achievement and sense of direction.</li>
			<li><b>Self-efficacy</b> describes your beliefs about how well you can execute courses of action required to deal with prospective situations. <a href="https://www.semanticscholar.org/paper/Self-efficacy-mechanism-in-human-agency.-Bandura/8beec556fe7a650120544a99e9e063eb8fcd987b" >Source</a></li>
			<li><b>Self-discipline</b> describes your capacity to begin tasks and follow through to completion despite boredom or distractions.</li>
			<li><b>Cautiousness</b> describes your tendency to think things through before acting or speaking.</li>
			<li><b>Dutifulness</b> describes how important fulfilling your moral obligations is to you.</li>
			<li><b>Orderliness</b> describes your ability to be well organized and diligent.</li>
		</ol>

		<br/> <br/>

		<section className="accordion-width">
		<Accordion>
			<Card className="border-0">
				<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
				<b className="mr-3">{i18n.t('Discover Your 6 Conscientiousness Facets')}</b><Icon.ChevronDown className="ml-2"/>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body>
						
					<h4 id="Cachievement">C1 | Low Achievement-Striving</h4>
					<p>Succeeding at all costs is not your priority. You tend to be satisfied by good enough solutions and prefer to put less time and effort into your work over grinding. Sometimes, your tendency to do just enough work to get by may mean that you fail to complete what you were supposed to do. You tend to look for shortcuts or at least for the easiest way to complete certain tasks.</p>
					<p>Self-development advice: Keep in mind that although you may get by in some situations with a minimum effort, in doing so you will not fully realize your potential in the long run. There is no substitute for hard work.</p>

					<div className="quote">
						<p><i>"Done is better than perfect."</i></p>
						<p><b>- Sheryl Sandberg</b></p>
					</div>

					<h4 id="Cselfefficacy">C2 | Low Self-Efficacy</h4>
					<p>You sometimes think difficult situations are beyond your capabilities or that you have little to contribute to the team. You may misjudge situations and don’t see the consequences of your actions.</p>

					<div className="quote">
						<p><i>"Never give in except to convictions of honor and good sense. Never yield to force; never yield to the apparently overwhelming might of the enemy."</i></p>
						<p><b>- Winston Churchill</b></p>
					</div>

					<h4 id="Cselfdiscipline">C3 | Low Self-Discipline</h4>
					<p>You may find it difficult to get down to work and need a push to get started to avoid neglecting your duties. You have a hard time starting tasks because of your tendencies to postpone decisions and to procrastinate important things in your life. Sometimes, you also may leave things unfinished because something distracted you from what you were doing.</p>
					<p>As a result, you may be late or miss deadlines because of your unconscious tendency to waste your time. Keep in mind that this may impact the people you work with and may irritate them.</p>

					<div className="quote">
						<p><i>"In any moment of decision, the best thing you can do is the right thing, the next best thing is the wrong thing, and the worst thing you can do is nothing."</i></p>
						<p><b>- Theodore Roosevelt</b></p>
					</div>

					<h4 id="Ccautiousness">C4 | Low Cautiousness</h4>
					<p>You feel comfortable making quick decisions and jumping into things without too much thinking. Your spontaneity helps you to get things moving and done. You are flexible and handle well when confronted with last-minute change of plans. Your easy-going and carefree nature helps you to rapidly adjust when life throws you lemons. Others may perceive you as being too impulsive or careless sometimes.</p>

					<div className="quote">
						<p><i>"I am not a product of my circumstances. I am a product of my decisions."</i></p>
						<p><b>- Stephen Covey</b></p>
					</div>

					<h4 id="Cdutifulness">C5 | Low Dutifulness</h4>
					<p>Sometimes, you believe that the ends justify the means. This means that you may break your promises when you believe they are wrong or break the rules when necessary. You don’t mind getting others to do your duties or doing the opposite of what is asked, as long as you can get things done.</p>

					<div className="quote">
						<p><i>"It is better to act and repent than not to act and regret."</i></p>
						<p><b>- Niccolo Machiavelli</b></p>
					</div>

					<h4 id="Corderliness">C6 | Low Orderliness</h4>
					<p>You are usually not bothered by disorder. Taking care of things is not very important to you, and you may forget to return things or put them back where they belong. You are content with your usual appearance and environment. Sometimes, you have to ask for pens and chargers because you forgot your own.</p>
					<p>You have no issues to improvise, acting in a more disorganized and unplanned fashion than other people. You tend to dislike excessive structure and schedule. Keep in mind that your carefree nature may be perceived as negligent, lazy and messy.</p>

					<div className="quote">
						<p><i>"Clutter and mess show us that life is being lived."</i></p>
						<p><b>- Anne Lamott</b></p>
					</div>	


					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
		</section>

		<br/> <br/>

		<section className="story">
		<h3>Example Of Conscientiousness Misunderstandings</h3>
		
		<h4>At Work</h4>
		<div className="myimghor">
			<img src={cmismatch} alt="Conscientiousness mismatch" />
		</div>

		<p><b>Jon, a highly conscientious person</b>, writes a lengthy email filled with details, hoping to reassure and guide <b>Stacy, the low-conscientious intern</b> who recently joined the company.</p>
		<p>As a result, Stacy is overwhelmed, confused, and doesn’t know how or where to start the work Jon asked her to do. Too much information paralyses her.</p>
		<p>At the end of her six months internship, Stacy is frustrated. She was not able to learn as much as she could because of how long it took her to understand and become comfortable with Jon’s communication style and continuous attention to details.</p>
		
		<h5>What could Jon have done differently?</h5>
		<p>While Jon's thorough and exhaustive explanations are helpful to make sure Stacy wouldn't miss important details, he should have also prepared a more high-level overview of what is expected of her. Starting with a less accurate and more accessible plan would allow her to understand the big picture and ask questions for the details she doesn't understand yet. Then, Jon's methodical work could come in handy, as it would clarify Stacy's uncertainties. Starting with too much information was Jon's biggest mistake.</p>
		
		<h5>What about Stacy? </h5>
		<p>She should have clarified upfront with Jon how lost she was because of all the details he threw at her from the start. She could have drawn with him the big picture of what he expected from her and slowly connect the dots which he provided in his lengthy explanations. This way, she could leverage his attention to details and deep knowledge to create a more understandable plan of action, which she would be sure would be as efficient as could be.</p>
		
		{/* Example 2: LC Project lead and HC contributor */}

		<h4>With Family and Friends</h4>
		<p>Jennifer, a highly conscientious working mother, is frustrated by how her son Steve behaves: He is often late, keeps forgetting things and his room is always a mess. How many times will she have to remind him of picking up his dirty socks?</p>
		<p>She feels like every time she asks him to do something - when he doesn't forget to do it - he never gives it his best effort and she needs to pick up after him. Fed up with his lack of effort, she finally tells him he can't do anything right. She immediately regrets her words when she sees the sadness on his face before he storms out.</p>
		<p>Steve broods on why his mother treated him this way, since he's trying his best. After all, she has extremely high expectations on how clean the house should be and never seems satisfied, even with her husband and their daughters. No matter how hard he tries to please her, he always misses the mark because of a few things he did not consider to be essential to clean.</p>

		<h5>What could Jennifer have done differently?</h5>
		<p>Jennifer did not realize how her attention to details and desire for absolute cleanliness disturbs her whole family, not only Steve who is the least conscientious of them all. They often tell her how they don't understand where she's going with her explanations or how she's nitpicking whenever they do their chores.</p>
		<p>She needs to encourage them when they do a good enough job and accept that they may never reach her standards. She could spend more quality family time with them if she didn't spend so much time cleaning. She sees them waiting for her to join them but dismisses their pleas because there is just this one last chore she wants to finish. Before long, the day is over.</p>
		<p>She should learn to summarize and prioritize the information she wants to give them, to avoid losing them in her detailed explanations of what she expects from them.</p>

		<h5>What about Steve?</h5>
		<p>Steve should emphasize what he's done when she scolds him for not doing a certain task a certain way. It often happens, that he feels lost when she asks him to do something, because of all the information she gives him beforehand. What would work better for him is to receive a high-level understanding of the different chores she expects him to do. This to-do list would help him keep the big picture in mind and not forget what she had in mind.</p>
		<p>He could also ask her for advice on how he can improve the next time he needs to do a task for her. He craves her praise and encouragements and suffers from only receiving negative feedback when he doesn't perform as well as she would expect.</p>
		
		</section>

		<h3>How To Work Well With High-Conscientiousness People</h3>
		<div className="myimghor">
			<img src={conversation7} alt="Working with high-conscientiousness individuals" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@olly" >Andrea Piacquadio</a> from <a href="https://www.pexels.com/photo/photo-of-woman-talking-with-another-woman-3768129/" >Pexels</a></p>
		</div>
		
		<h4>At Work</h4>
		<p>Spend enough time at first to <b>give them as much information as they need</b> to feel comfortable to get started. Then, break down the goals of each project into clear sub-parts and discuss what needs to be done and how they want to approach their work. When following up, give them in-depth reports with as many details as possible.</p>
		<p><b>Be prepared for them to ask many questions</b>. Get ready to review them together and answer all of them. Skipping information may make their wheels spin and prevent them from getting back to work.</p>

		<h4>With Family and Friends</h4>
		<p>If you see they seem uncomfortable or anxious because they don't have a clear enough understanding of what to do, fight the urge to move on and let them figure it out on their own. They need to have enough information to feel comfortable to get started.</p>
		<p>When they start overloading you with too many details, try to summarize what they are saying to see if you are still on the same page. Seek the most important information that you need in order to help them with their requests and problems.</p>
		<p><b>Be prepared for them to ask many questions</b>. Get ready to review them together and answer all of them. Skipping information may make their wheels spin and prevent them from doing their part.</p>


		<h3>How To Work Well With Low-Conscientiousness People</h3>
		<div className="myimghor">
			<img src={conversation4} alt="Working with low-conscientiousness individuals" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@wildlittlethingsphoto" >Helena Lopes</a> from <a href="https://www.pexels.com/photo/photograph-of-men-having-conversation-seating-on-chair-1015568/" >Pexels</a></p>
		</div>

		<h4>At Work</h4>
		<p><b>Clarify the big goals of each projects as well as your overall expectations</b>. Avoid going into too many details, as too much information will likely overwhelm them. Instead, give them a brief summary, which contains the most critical facts.</p>
		<p>Give them the freedom to come up with their own way of doing things to achieve your goals and expectations. <b>Discuss together the next steps to make sure you are aligned and they aren’t missing key steps</b>.</p>


		<h4>At Home</h4>
		<p>Give them a high-level summary of what you have in mind and avoid sharing too much information, which will likely overwhelm them. Stick to the most critical facts and let them ask clarifying questions if they aren't sure they fully understand what you are saying.</p>
		<p>When asking them to do something, ask them to share how they plan to proceed, to make sure they will not miss important steps along the way.</p>


		<h3>Ideas For Communicating With Both Low- And High-Conscientious People</h3>
		<div className="notabene">
			Start with your recommendation, summary, and next steps. Then, follow with the essential insights which will support your rationale.
		</div>

		<div className="myimghor">
			<img src={communicationtriangle} alt="The communication triangle" className="w-75" />
		</div>

		<div className="imgcaption">
			<p>Think of the message you want to communicate as an inverted triangle. Start with the core, then go into more details if necessary</p>
		</div>

		<p>This provides people with the choice to dig into more details, if they need to. Still, because you started with the essential part first, you made their lives easier, and they will likely appreciate it. This is known as the Bottom-Line UpFront message crafting method, or BLUF.</p>
		<p>The top, the largest base, represents your core message. It’s the most crucial part that people need to know about. If they only read this first part, they will still get most of the insights you wanted to convey.</p>
		<p>Again, your core message may be a recommendation, summary, next steps or a question you want your reader to reflect on and answer. The reason why starting with the core message is key is, that it saves a lot of time and effort for your readers. </p>
		<p>The rest of your message will provide them with additional information and levels of details which will allow them to become comfortable in supporting you or the change you are advancing. The more you move towards the peak of the triangle, the more accurate information you provide for those who need to understand as much as possible before being able to make a decision or take action.</p>
		<p>People with lower levels of conscientiousness are more likely to be convinced with fewer information and may take action without needing to thoroughly read the rest of your message.</p>
		<p>In contrast, by explaining your rationale and sharing the facts that support them, you will be able to also convince people with higher levels of conscientiousness.</p>
		<p>From personal experience, I noticed that we tend to bury the core message at the bottom of our emails and documents, because we want to convince people before we ask for something or share our key insights.</p>
		<p>The risk is that people may quickly lose interest or miss the key facts you truly wanted to convey. Some may never read to the end of your message, meaning they will miss the core purpose of your request.</p>
		<p>This is what makes communication challenging for highly conscientious people: All the information seems crucial. They are afraid that excluding a few details may make the entire message less impactful, convincing, meaningful or actionable.</p>
		<p>Remember: If your core message is not clear and mentioned first, your readers will have a hard time understanding what you expect of them and how they can help.</p>


		<h3>Questions To Ask To Evaluate Conscientiousness</h3>
		<h4>What exciting projects are you working on? <br /> What’s your plan?</h4>

		<p><b>People with higher levels of conscientiousness</b> will answer by giving a long, comprehensive, organized, exhaustive list of tasks they need to do to achieve their objectives. They will talk at length about their motivations, why this work matters to themselves and their organizations, and how they will tackle this challenge.</p>
		<p><b>People with lower levels of conscientiousness</b> will answer by giving more brief, big picture overviews, perhaps without organizing their thoughts. The actions they will have to take, who will support them, will likely not be fully articulated or even known. Their answers will likely require more clarifications, as what is clear for them may not be so for you.</p>

		<h4>What are your goals for this year?</h4>
		<p><b>People with higher levels of conscientiousness</b> will again provide a detailed list of what they want to achieve, why they matter to them, and how they will achieve them, step by step. They will likely break down their objectives into clear milestones and timelines, to make sure that they can stay the course and increase the likelihood that they will achieve what they desire.</p>
		<p><b>People with lower levels of conscientiousness</b> will likely be more vague about their objectives, intentions, motivations, and how they will get things done.</p>

		<div className="separator" />
		<div className="separator" />
		<hr/>


		<h2 id="Etitle">Extroversion</h2>

		<div className="myimghor">
			<img src={Espectrum} alt="Extroversion spectrum" />
		</div>

		<div className="imgcaption">
			<p>The extroversion spectrum</p>
		</div>

		<p>This trait describes how you approach and relate to people and the social world:</p>

		<ul>
			<li>How comfortable are you in larger groups of people?</li>
			<li>How comfortable are you in one-on-one interactions?</li>
			<li>How comfortable are you being alone with your thoughts?</li>
			<li>How energized are you by interacting with other people?</li>
		</ul>

		<div className="quote">
            <p><i>"Companionship is a foreign concept to some people. They fear it as much as the majority of people fear loneliness."</i></p>
            <p><b>- Criss Jami</b></p>
        </div>	

		<h3>High-Extroversion Description</h3>

		<div className="myimghor">
			<img src={partyimg} alt="High extroversion party time" />
		</div>

		<div className="imgcaption">
			<p>Life is a party!</p>
			<p>Photo by <a href="https://www.pexels.com/@cottonbro" >cottonbro</a> from <a href="https://www.pexels.com/photo/people-toasting-wine-glasses-3171837/" >Pexels</a></p>
		</div>

		<p>As an extrovert, you enjoy social interactions, which energize you. Initiating conversations, getting to know other people and sharing about you excites you. You tend to work better in groups than alone and to be more actively engaged with the group.</p>

		<div className="quote">
            <p><i>"I have social disease. I have to go out every night. If I stay home one night I start spreading rumours to my dogs."</i></p>
            <p><b>- Andy Warhol</b></p>
        </div>

		<h4>How You May Be Perceived</h4>
		<p>Your natural energy during in-person interactions usually make others perceive you as cheerful, energetic, and optimistic. Your upbeat attitude and tendency to smile to show your enjoyment can be contagious.</p>
		<p>Keep in mind that your enthusiasm to initiate contact, share about yourself and inquire about others’ lives may be perceived as attention-seeking and intrusive, especially for people with lower levels of extroversion. Give people enough space if they seem uncomfortable with sharing about themselves or learning more about you.</p>
		<p>Interesting fact: Extroverts tend to fill their environments with trinkets to make them more inviting!</p>

		<h3>The 6 High Extroversion Facets</h3>

		<p>Extroversion can be broken down into six facets which describe your levels of enthusiasm and assertiveness:</p>

		<ol>
			<li><strong>Gregariousness</strong> describes your preference for the company of others over solitude.</li>
			<li><strong>Cheerfulness</strong> describes your tendency to experience and spread positive emotions.</li>
			<li><strong>Warmth</strong> describes your interest in and friendliness towards others.</li>
			<li><strong>Excitement seeking</strong> describes your need for environmental stimulation.</li>
			<li><strong>Activity level</strong> describes your pace of living.</li>
			<li><strong>Assertiveness</strong> describes your social ascendancy and forcefulness of expression.</li>
		</ol>

		<br/> <br/>

		<section className="accordion-width">
		<Accordion>
			<Card className="border-0">
				<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
				<b className="mr-3">{i18n.t('Discover Your 6 Extroversion Facets')}</b><Icon.ChevronDown className="ml-2"/>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body>
						
					<h4 id="Egregariousness">E1 | High Gregariousness</h4>
					<p>You enjoy being part of a group and involve others in what you are doing. You tend to be the life of the party and are comfortable with speaking to a lot of different people at events, regardless of how large they are. You actively seek social connections and interactions and have no issues joining a conversation or an existing group of people.</p>

					<div className="quote">
						<p><i>"I'm the most gregarious of men and love good company, but never less alone when alone."</i></p>
						<p><b>- Peter O'Toole</b></p>
					</div>

					<h4 id="Echeerfulness">E2 | High Cheerfulness</h4>
					<p>You radiate joy and love life. Having fun, laughing and amusing your friends is a priority to you. Your positive, optimistic, enthusiastic, looking at the bright side of life nature is highly contagious.</p>

					<div className="quote">
						<p><i>"Walk with the dreamers, the believers, the courageous, the cheerful, the planners, the doers, the successful people with their heads in the clouds and their feet on the ground. Let their spirit ignite a fire within you to leave this world better than you found it."</i></p>
						<p><b>- Wilferd A. Peterson</b></p>
					</div>

					<h4 id="Ewarmth">E3 | High Warmth</h4>
					<p>You warm up quickly to others and make friends easily. Because you naturally feel comfortable around people, you make sure that they will also feel comfortable with you and cheer them up.</p>

					<div className="quote">
						<p><i>"Life is about warm friendships, memorable wanderings, and moments of extraordinary beauty."</i></p>
						<p><b>- Avijeet Das</b></p>
					</div>

					<h4 id="Eexcitement">E4 | High Excitement-Seeking</h4>
					<p>You love excitement and seek adventure and thrill. In certain circumstances, you may actively seek danger, acting more wildly and crazily than others would dare, to fulfill your desire to try anything exciting at least once.</p>
					<p>Loud environments filled with people and activities energize you. You draw inspiration from social situations and external stimulation.</p>

					<div className="quote">
						<p><i>"I feel the need to endanger myself every so often."</i></p>
						<p><b>- Tim Daly</b></p>
					</div>

					<h4 id="Eactivity">E5 | High Activity Level</h4>
					<p>You do a lot in your spare time and are always busy and on the go. You are capable of handling many things in a short amount of time and react quickly when somebody or something requires your attention.</p>
					<p><b>Keep in mind that busyness does not mean productivity and may actually be bad for business</b>! Make sure you prioritize the tasks that will support you and your teams the most first.</p>

					<div className="quote">
						<p><i>"I wanted to figure out why I was so busy, but I couldn't find the time to do it."</i></p>
						<p><b>- Todd Stocker</b></p>
					</div>

					<h4 id="Eassertiveness">E6 | High Assertiveness</h4>
					<p>You like to take charge and to lead others, leveraging your skills at influencing others and getting them to do what needs to be done to advance the groups’ interests and causes. Taking control of things comes naturally to you, and standing in the spotlight does not intimidate you.</p>

					<div className="quote">
						<p><i>"A true leader has the confidence to stand alone, the courage to make tough decisions, and the compassion to listen to the needs of others. He does not set out to be a leader, but becomes one by the equality of his actions and the integrity of his intent."</i></p>
						<p><b>- Douglas MacArthur</b></p>
					</div>


					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
		</section>

		<br/> <br/>


		<h3>Low-Extroversion Description</h3>

		<div className="myimg">
			<img src={readingimg} alt="Low extroversion quiet time" />
		</div>

		<div className="imgcaption">
			<p>We all need some quiet time to recharge</p>
			<p>Photo by <a href="https://www.pexels.com/@daria" >Daria Shevtsova</a> from <a href="https://www.pexels.com/photo/woman-sitting-while-reading-a-book-1616779/" >Pexels</a></p>
		</div>

		<p>As an introvert, you tend to prefer one-on-one interactions and evolving in groups of three to four people. You may dislike small talk, have difficulties initiating conversations, getting to know other people and sharing private information about yourself. That being said, once you trust someone, the walls usually slowly come down.</p>

		<div className="quote">
			<p><i>"Quiet people have the loudest minds."</i></p>
			<p><b>- Stephen Hawking</b></p>
        </div>

		<h4>How You May Be Perceived</h4>
		<p>Your tendency to do your best thinking alone and getting back to the group after in-person interactions may make others - especially people with higher levels of extroversion - perceive you as distant, sometimes even cold.</p>

		<h3>The 6 Low Extroversion Facets</h3>

		<p>Extroversion can be broken down into six facets which describe your levels of enthusiasm and assertiveness:</p>

		<ol>
			<li><strong>Gregariousness</strong> describes your preference for the company of others over solitude.</li>
			<li><strong>Cheerfulness</strong> describes your tendency to experience and spread positive emotions.</li>
			<li><strong>Warmth</strong> describes your interest in and friendliness towards others.</li>
			<li><strong>Excitement seeking</strong> describes your need for environmental stimulation.</li>
			<li><strong>Activity level</strong> describes your pace of living.</li>
			<li><strong>Assertiveness</strong> describes your social ascendancy and forcefulness of expression.</li>
		</ol>

		<br/> <br/>

		<section className="accordion-width">
		<Accordion>
			<Card className="border-0">
				<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
				<b className="mr-3">{i18n.t('Discover Your 6 Extroversion Facets')}</b><Icon.ChevronDown className="ml-2"/>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body>
						
					<h4 id="Egregariousness">E1 | Low Gregariousness</h4>
					<p>You prefer to be alone and avoid crowded events. You tend to work and think best alone, needing time to think before you answer and get back to the group. Your solitary, withdrawn and reserved nature may make others perceive you as a loner before they get to know you better.</p>
					<p>Quiet, more personal interactions allow you to thrive. Larger groups and crowded events are likely going to intimidate and drain you. Socializing with others may feel exhausting after a period of time and will require that you take time alone to recover from it.</p>

					<div className="quote">
						<p><i>"The highest form of love is to be the <b>protector</b> of another person's solitude."</i></p>
						<p><b>- Rainer Maria Rilke</b></p>
					</div>
					
					<div className="quote">
						<p><i>"Loneliness is the poverty of self; solitude is the richness of self."</i></p>
						<p><b>- Mary Sarton</b></p>
					</div>

					<h4 id="Echeerfulness">E2 | Low Cheerfulness</h4>
					<p>You tend to be a more serious person who seldom jokes around and smiles occasionally. Your more earnest nature allows you to keep people focused on what needs to get done.</p>
					
					<div className="quote">
						<p><i>"Earnestness is enthusiasm tempered by reason."</i></p>
						<p><b>- Blaise Pascal</b></p>
					</div>

					<h4 id="Ewarmth">E3 | Low Warmth</h4>
					<p>Because you may feel uncomfortable around others or aren’t necessarily interested in others, you are sometimes hard to get to know. You tend to dislike being the center of attention. Your reserved nature will often drive you to keep others at a distance or to avoid contact with them altogether.</p>

					<div className="quote">
						<p><i>"Forgive my indifference; I'd rather be distant than devastated."</i></p>
						<p><b>- Ahmed Mostafa</b></p>
					</div>

					<h4 id="Eexcitement">E4 | Low Excitement-Seeking</h4>
					<p>You prefer comfort over adventure and are more cautious, trying not to hurt yourself or put yourself in dangerous situations. No matter how thrilling they seem, high-adrenaline situations like hand gliding or bungee jumping don’t attract you.</p>
					<p>You tend to be energized by your inner world rather than by external stimulation, which makes you dislike loud music and events.</p>

					<div className="quote">
						<p><i>"The scars of others should teach us caution."</i></p>
						<p><b>- St. Jerome</b></p>
					</div>

					<h4 id="Eactivity">E5 | Low Activity Level</h4>
					<p>You like to take your time and let things proceed at their own, steady pace. You also enjoy calming down, relaxing and enjoying life without rushing through it, living a leisurely lifestyle. </p>

					<div className="quote">
						<p><i>"Never discourage anyone who continually makes progress, no matter how slow."</i></p>
						<p><b>- Plato</b></p>
					</div>

					<h4 id="Eassertiveness">E6 | Low Assertiveness</h4>
					<p>Because you tend to be more quiet and say little, preferring to keep in the background and holding back your opinions, you tend to wait for others to lead the way. You don’t like to draw attention to yourself and prefer to be more passive than active during conversations, to avoid standing in the spotlight and being the center of attention.</p>

					<div className="quote">
						<p><i>"I think a lot, but I don't say much."</i></p>
						<p><b>- Anne Frank</b></p>
					</div>



					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
		</section>

		<br/> <br/>

		
		<section className="story">
		<h3>Example Of Extroversion Misunderstandings</h3>
		
		<h4>At Work</h4>
		
		<div className="myimghor">
			<img src={emismatch} alt="Extroversion mismatch"  />
		</div>

		<p><b>Dana is extremely introverted</b>. Her previous manager, Ellen, highly recommended her because of her ability to gather brilliant ideas and insights during projects.</p>
		<p>Her new manager, <b>a cheerful extrovert called Philip</b>, is excited about having her on the team and has high expectations about what she will bring to their meetings and projects.</p>
		<p>Unfortunately, Philip doesn’t understand how terrified Dana feels when he puts her on the spot in front of everyone or why she sometimes acts awkwardly during meetings. She doesn’t dare to speak up about it.</p>
		<p>Dana misses Ellen, who understood her preference to remain silent during meetings to think, and asked her to share her ideas later, either by email or during one-on-one conversations.</p>
		
		<h5>What could Philip have done differently?</h5>
		<p>He should have discussed alone with Dana to understand how she prefers to work. If he had, he could have understood that she needs solitary time to do her thinking rather than coming up at will with brilliant ideas. He could then have emulated what Ellen did well, which was to allow Dana to come back to her when she was ready, either by email or during a conversation.</p>
		
		<h5>What about Dana?</h5>
		<p>She should have taken Philip aside after he put her in the spotlight during meetings to let him know how uncomfortable it made her feel. She doesn't have to apologize for her shyness, and should inform him how she does her best thinking when given enough time alone. This way, Philip would know he should let her sit in silence during meetings rather than asking her to speak up, and be assured she will contribute given enough time.</p>
		


		<h4>With Family and Friends</h4>
		<div className="myimghor">
			<img src={emismatch} alt="Extroversion mismatch"  />
		</div>

		<p>Penny is the cheerful, energetic extroverted mother of David, a sweet and introverted 9 year old. She feels concerned after hearing from David's teachers that he spends most of his time alone, even during breaktime. As far as she can remember, she always loved meeting new people and playing loudly with other kids.</p>
		<p>She keeps encouraging him to meet new people at school and asks every dinner to talk about his day, which he seldom does, preferring to listen to what the rest of the family has to say. When he does share, he usually talks about what he learned that day and does not know how to answer his mom's questions about "what he did for fun?"</p>
		
		<h5>What could Penny have done differently? </h5>
		<p>If she had asked David, she would have learned that he preferred to spend time with his best friends, Mary and Gregory, after school, because they could quietly play and talk without all the chaos from the playground. It didn't occur to her that his quiet moments of solitude enabled him to process and understand what he had learned, which resulted in his brilliant grades at school. She could have realized that David was gifted at building strong relationships with the people he liked, even teachers, but that he shunned the loud and crowdy activities which drained him of his energy.</p>
		
		<h5>What about David?</h5>
		<p>David did not realize how important his social life is to his mother. If he had, he could have told her about the fantastic adventure he and his best friends made up, or about how he enjoyed their conversations about their favorite movies and books. He also did not realize how perplexed his mother was when he talked about school rather than play at the dinner table. Since he enjoys spending time with his thoughts, tinkering on problems, he greatly enjoys learning. The praise and encouragements from his parents and teachers motivate him to study hard, which he does not mind since his best friends accept him the way he is.</p>
		</section>


		<h3>How To Work Well With High-Extroversion People</h3>
		<div className="myimghor">
			<img src={conversation6} alt="Working with extroverts" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@rethaferguson" >RF._.studio</a> from <a href="https://www.pexels.com/photo/group-of-women-having-a-meeting-3810754/" >Pexels</a></p>
		</div>

		<h4>At Work</h4>
		<p>It’s important to understand that extroverts thrive in social interactions. While they may feel draining for your, their tendencies to approach you, share about themselves and ask you to do the same is not a personal attack. It’s part of their nature.</p>
		<p>Their sociable nature also means that they dislike being alone, because they seek human contact to feel like they belong. Their energy and enthusiasm can be contagious. While they may sometimes feel intimidating, they can also energize you if you let them.</p>
		<p>Assign them to projects or roles that leverage their energy and social skills, such as brainstorming sessions, moderation, etc. They are great in asking and understanding what others think.</p>
		<p>Assign them to mentoring roles, to reduce the time they spend alone, which they might dread. Publicly praise their personal achievements and their team-building efforts.</p>

		<h4>At Home</h4>
		<p>Remember that sharing and getting to know people is crucial for extroverts. Expect them to share a lot of information about themselves and be prepared to do the same, as this will build rapport between you. They will also likely appreciate if you are the one initiating the conversation.</p>
		<p>Suggest social and stimulating activities you could do together, since they get energized from their environments, especially when they are crowded or exciting. Concerts, festivals, amusement parks, and bars are great places to start.</p>
		<p>When talking with extroverts, show your interest and enthusiasm by leaning in, nodding your head, maintaining eye contact and providing verbal acknowledgment to what they are saying. Keep in mind they may think out loud, which helps them to process the information before coming to a conclusion.</p>
		<p>When you have made plans or promised to do something for extroverts, make sure you follow up and keep them updated on your progress, even if you are not done yet. They will appreciate your initiative and will get peace of mind knowing you are doing your part.</p>


		<h3>How To Work Well With Low-Extroversion People</h3>
		<div className="myimghor">
			<img src={conversation2} alt="Working with introverts" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@mentatdgt-330508" >mentatdgt</a> from <a href="https://www.pexels.com/photo/two-woman-chatting-1311518/" >Pexels</a></p>
		</div>

		<p>First, it’s important to address a common misconception: Being shy is not necessarily equal to being an introvert. Research suggests that shyness is a blend between introversion and high-nervosity.</p>
		<p>Introverts are usually more difficult to approach in larger groups, but they may be more expressive and outgoing in one-on-one interactions or in small groups of a few people.</p>
		<p>Although it may seem counterintuitive, introverts can know as many people as extroverts do. The difference is that they thrive in smaller groups when connecting with people, while extroverts feel comfortable with engaging wither larger audiences.</p>
		
		<h4>At Work</h4>
		<p>Give them enough time alone to do their thinking and their work. Assign them to projects with smaller teams and with fewer face-to-face interactions. Accept that they may come back a few days after your previous conversation to share their thoughts. Give them enough resources and learning materials to process things at their on pace and on their own.</p>
		<p>Accept that they may need personal space outside of their desk to work alone, away from the distractions and potentially draining interruptions they could otherwise experience.</p>
		<p>Unless they ask otherwise, praise their personal achievements and team-building efforts during one-on-one sessions rather than putting them on the spot in front of everyone else. They dislike being the center of attention.</p>
		<p>Leverage written communication to let them come back to you if they feel uncomfortable sharing their thoughts during your one-on-one conversations.</p>

		<h4>At Home</h4>
		<p>For introverts, real friendship has to be earned. Although they are friendly and speak kindly, this does not mean they are - or seek to be - your friend yet. Social situations can be difficult, even exhausting for them. Their careful nature makes them think through everything they say, to avoid hurting those they interact with.</p>
		<p>Don't assume you know what they are thinking or feeling. You are more likely to be wrong than lucky. Rather, ask them to share their thoughts and feelings, but be prepared for them to refuse sharing something so personal. They dislike being the center of attention and sharing intimate information may not come naturally to them.</p>
		<p>Don't mistake their silence for weakness. Introverts prefer reflection over visible action. Their silence is no sign for a fragile personnality which needs protecting. In the theater of their minds, so much may happen that words can hardly begin to explain the deep reflections they are going through beneath the surface.</p>
		<p>Respect their boundaries. Don't force them to share if they are unwilling to. Once they trust you and know you will welcome and admire their inner world and feel ready to share, they will. Give them time and respect their silence. They will appreciate your asking how they feel, without feeling the need to elaborate much on it.</p>
		<p>Be aware that they will replay most of your interactions with them, reflecting on how you made them feel and what you made them think. Be gentle and mindful when you talk to them, let them share when they feel like it and give them enough space to silently process the situation without forcing them to speak up.</p>

		<h3>Questions To Ask To Evaluate Extroversion</h3>
		<h4>Do you know anyone else here?</h4>
		<p><b>Extroverts</b> are likely to answer this question enthusiastically, by dropping names or by offering to introduce you to people they think you would enjoy meeting.</p>
		<p><b>Introverts</b> are likely going to look nervous by your question or by becoming suddenly aware of the crowd in which they stand. They may ask to move to a calmer, more private space to talk or to introduce you to someone they know.</p>

		<h4>What are your plans for the weekend?</h4>
		<p><b>Extroverts</b> are likely going to talk with excitement about their planned adventures, how much they are going to do to keep themselves busy, and the people - potentially larger groups of people - they will spend their time with.</p>
		<p><b>Introverts</b> are likely going to talk about relaxing or enjoying a few activities spread out over the weekend, which should include only a smaller group of participants. </p>


		<div className="separator" />
		<div className="separator" />
		<hr/>



		<h2 id="Atitle">Agreeableness</h2>
		
		<div className="myimghor">
			<img src={Aspectrum} alt="Agreeableness spectrum" />
		</div>

		<div className="imgcaption">
			<p>The agreeableness spectrum</p>
		</div>

		<p>This trait describes how you approach cooperation and working with others:</p>

		<ul>
			<li>How empathetic are you? How much do you pay attention to other people’s emotions and well-being?</li>
			<li>How quick to forgive are you?</li>
			<li>How much do you value solving problems over dealing with people?</li>
		</ul>

		
		<h3>High-Agreeableness Description</h3>

		<div className="myimg">
			<img src={trustimg} alt="High agreeableness trust" />
		</div>

		<div className="imgcaption">
			<p>Trust makes anything possible</p>
			<p>Photo by <a href="https://www.pexels.com/@pixabay" >Pixabay</a> from <a href="https://www.pexels.com/photo/hands-people-friends-communication-45842/" >Pexels</a></p>
		</div>

		<p>In situations where you feel safe and at ease, you will care about others and easily trust them. You will encourage people to come to you for help and avoid confrontations.</p>

		<div className="quote">
			<p><i>"When you are kind to others, it not only changes you, it changes the world."</i></p>
			<p><b>- Harold Kushner</b></p>
        </div>

		<h4>How You May Be Perceived</h4>
		<p>Your radiant enthusiasm may be exploited by some people. Make sure that you don’t forget to protect your own self-interests when needed. By trying to please everyone at all costs - saying “yes” by default, even when you don’t mean it - you may be perceived as a pushover and be taken advantage of your caring nature.</p>
		<p>Learn to speak up and defend what you truly stand for. Think of ways to create win-win situations instead of allowing people to walk over you to get what they want.</p>

		<h3>The 6 High Agreeableness Facets</h3>

		<p>Agreeableness can be broken down into six facets which describe your levels of compassion and politeness:</p>

		<ul>
			<li><b>Tender-mindedness</b> describes your attitude of sympathy for others.</li>
			<li><b>Altruism</b> describes your active concern for the welfare of others.</li>
			<li><b>Trust</b> describes your beliefs in the sincerity and good intentions of others.</li>
			<li><b>Compliance</b> describes your response to interpersonal conflict.</li>
			<li><b>Modesty</b> describes your tendency to play down your own achievements and to be humble.</li>
			<li><b>Straightforwardness</b> describes your frankness in expression.</li>
		</ul>

		<br/> <br/>

		<section className="accordion-width">
		<Accordion>
			<Card className="border-0">
				<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
				<b className="mr-3">{i18n.t('discover-your-6-agreeableness-facets')}</b><Icon.ChevronDown className="ml-2"/>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body>
						
					<h4 id="Atender">A1 | High Tender-Mindedness</h4>
					<p>Your caring nature makes you care for and understand for other people’s suffering. This means that you often suffer from seeing other people’s sorrows and suffering.</p>
					<p>You tend to feel sympathy for those who are worse off than yourself, such as the homeless and the less fortunate. This also will make you more prone to collaborate with others, rather than actively compete with them in a win-lose game. </p>

					<div className="quote">
						<p><i>"Too often we underestimate the power of a touch, a smile, a kind word, a listening ear, an honest compliment, or the smallest act of caring, all of which have the potential to turn a life around."</i></p>
						<p><b>- Leo Buscaglia</b></p>
					</div>

					<h4 id="Aaltruism">A2 | High Altruism</h4>
					<p>You are concerned about other people’s wellbeing and want to help others and make them feel welcome. Your tendency to anticipate the need of others, thoughtfulness, and charitable nature are your foundation. You don’t hesitate to serve others and contribute to the benefit of your group, putting the needs of others ahead of your own, willingly doing anything for others. </p>
					<p>Keep in mind that your willingness to sacrifice your interests for the sake of your relationships may be taken advantage of by less scrupulous people, who may see you as a pushover or a doormat.</p>

					<div className="quote">
						<p><i>"Nobody cares how much you know, until they know how much you care."</i></p>
						<p><b>- Theodore Roosevelt</b></p>
					</div>

					<div className="quote">
						<p><i>"The test of our progress is not whether we add more to the abundance of those who have much; it is whether we provide enough for those who have too little."</i></p>
						<p><b>- Franklin D. Roosevelt</b></p>
					</div>

					<h4 id="Atrust">A3 | High Trust</h4>
					<p>You easily trust others and what they say, and tend to believe that they have good intentions. You believe in human goodness and that people are basically moral and good, which leads you to view life with a more optimistic, thinking that all will be well.</p>

					<div className="quote">
						<p><i>"Trust is the glue of life. It’s the most essential ingredient to effective communication. It’s the foundational principle that holds all relationships."</i></p>
						<p><b>- Stephen Covey</b></p>
					</div>

					<h4 id="Acompliance">A4 | High Compliance</h4>
					<p>You usually can’t stand confrontations and conflicts. You prefer to maintain harmony with your relationships and the group. This means you tend to express yourself quietly, modestly, tactfully and with consideration to the other person. You do so to avoid seeming pushy, arrogant or smug.</p>
					<p>You are also easy to satisfy when things don’t go your way and easily forgive when people do you harm. </p>

					<div className="quote">
						<p><i>"Courage is what it takes to stand up and speak; courage is also what it takes to sit down and listen."</i></p>
						<p><b>- Winston Churchill</b></p>
					</div>

					<h4 id="Amodesty">A5 | High Modesty</h4>
					<p>Because you tend to see yourself as a humble, average person, you tend to dislike being the center of attention and talking about yourself. While you value your achievements, it doesn’t mean that you will toot your own horn and brag about them.</p>

					<div className="quote">
						<p><i>"Humility is not thinking less of yourself, it's thinking of yourself less."</i></p>
						<p><b>- C.S. Lewis</b></p>
					</div>

					<h4 id="Astraightforwardness">A6 | High Straightforwardness</h4>
					<p>You have a strong moral compass and act morally. This means you tend to stick to the rules and would never cheat to get ahead in life. Lying to others or hurting them to get what you want is out of the question.</p>
					<p>Instead, you want to make sure you don’t put people under too much pressure, out of your concern for them. You seek solutions that will help you both make progress on your goals.</p>

					<div className="quote">
						<p><i>"About morals, I know only that what is moral is what you feel good after and what is immoral is what you feel bad after."</i></p>
						<p><b>- Ernest Hemingway</b></p>
					</div>

					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
		</section>

		<br/> <br/>


		<h3>Low-Agreeableness Description</h3>

		<div className="myimghor">
			<img src={disagreementimg} alt="Low agreeableness disagreement" />
		</div>

		<div className="imgcaption">
			<p>Disagreements are a part of life</p>
			<p>Photo by <a href="https://www.pexels.com/@ketut-subiyanto" >Ketut Subiyanto</a> from <a href="https://www.pexels.com/photo/upset-young-indian-couple-after-conflict-4308057/" >Pexels</a></p>
		</div>

		<p>In situations where the stakes are high, you may become more pragmatic. How people feel may be less important to you than solving the problem or getting to the right answer.</p>

		<div className="quote">
            <p><i>"Honest disagreement is often a good sign of progress."</i></p>
            <p><b>- Mahatma Gandhi</b></p>
        </div>

		<h4>How You May Be Perceived</h4>
		<p>Your practical and skeptical nature may be perceived as harsh, almost robot-like because of your detachment from how others feel.</p>
		<p>Solving challenges is crucial in life and at work, but you need to remember that you are interacting with human beings. When their feelings get hurt, some people may disengage or sabotage you and your work.</p>


		<h3>The 6 Low Agreeableness Facets</h3>

		<p>Agreeableness can be broken down into six facets which describe your levels of compassion and politeness:</p>

		<ul>
			<li><b>Tender-mindedness</b> describes your attitude of sympathy for others.</li>
			<li><b>Altruism</b> describes your active concern for the welfare of others.</li>
			<li><b>Trust</b> describes your beliefs in the sincerity and good intentions of others.</li>
			<li><b>Compliance</b> describes your response to interpersonal conflict.</li>
			<li><b>Modesty</b> describes your tendency to play down your own achievements and to be humble.</li>
			<li><b>Straightforwardness</b> describes your frankness in expression.</li>
		</ul>

		<br/> <br/>

		<section className="accordion-width">
		<Accordion>
			<Card className="border-0">
				<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
				<b className="mr-3">{i18n.t('discover-your-6-agreeableness-facets')}</b><Icon.ChevronDown className="ml-2"/>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body>
						
					<h4 id="Atender">A1 | Low Tender-Mindedness</h4>
					<p>You tend to have little interest in other people’s problems, nor to think about those in need or less fortunate than you. In some situations, you may even believe that people should fend for themselves. Because of this, you may tend to dislike soft-hearted people who seem weak because of their overly concern about others’ circumstances. </p>
					<p>You usually feel comfortable challenging others, even when it means competing with them rather than collaborating with them, while being unapologetic for your competitive, sometimes harsh or belittling behavior.</p>
					<p>When others have spited you, you may engage in an eye for an eye behaviors, which can make you seem unsympathetic. </p>

					<div className="quote">
						<p><i>"It is impossible to suffer without making someone pay for it; every complaint already contains revenge."</i></p>
						<p><b>- Friedrich Nietzsche</b></p>
					</div>

					<h4 id="Aaltruism">A2 | Low Altruism</h4>
					<p>You tend to be indifferent to the feelings of others and take little time to help them, preferring to see to your own needs first. At times, you may even look down on others.</p>
					<p>You may sometimes believe and act like your own happiness and success are more important than those of others, even when it means turning your back on others who need your help.</p>

					<div className="quote">
						<p><i>"I believe all suffering is caused by ignorance. People inflict pain on others in the selfish pursuit of their happiness or satisfaction. Yet true happiness comes from a sense of inner peace and contentment, which in turn must be achieved through the cultivation of altruism, of love and compassion and elimination of ignorance, selfishness and greed."</i></p>
						<p><b>- Dalai Lama</b></p>
					</div>

					<h4 id="Atrust">A3 | Low Trust</h4>
					<p>You have a hard time trusting people or may even be wary of them, suspecting hidden motive in their actions and words. You tend to believe that people are essentially self-interested and therefore stay suspicious of their every move.</p>

					<div className="quote">
						<p><i>"The more two people open to each other, the more this wide-openness also brings to the surface all the obstacles to it: their deepest, darkest wounds, their desperation and mistrust, and their rawest emotional trigger points. Just as the sun's warmth causes clouds to arise by prompting the earth to release its moisture, so love's pure openness activates the thick clouds of our emotional wounding, the tight places where we are shut down, where we live in fear and resist love."</i></p>
						<p><b>- John Welwood</b></p>
					</div>

					<h4 id="Acompliance">A4 | Low Compliance</h4>
					<p>You are not afraid of confrontations and conflicts. In some instances, you may even enjoy a good fight. This means that you tend to challenge and contradict others, even when it means having a sharp tongue and openly criticizing what people have done. Beware that your assertive nature will not escalade into yelling, insults, or your outright snapping at people.</p>
					<p>This also means that you may tend to hold a grudge when someone does you wrong, and may even seek to get back at people and get revenge for their misdeeds.</p>

					<div className="quote">
						<p><i>"There is nothing I love as much as a good fight."</i></p>
						<p><b>- Franklin D. Roosevelt</b></p>
					</div>

					<h4 id="Amodesty">A5 | Low Modesty</h4>
					<p>You tend to be proud of who you are and what you’ve achieved, talking with pride about your achievements. Because you learned the answers to many questions, you are more likely going to think highly of yourself. Openly sharing your thoughts and therefore making yourself the center of attention doesn’t bother you.</p>
					<p>Keep in mind that others may perceive this as you boasting about your virtues and believing that you are better than them.</p>

					<div className="quote">
						<p><i>"Vanity and pride are different things, though the words are often used synonymously. A person may be proud without being vain. Pride relates more to our opinion of ourselves; vanity, to what we would have others think of us."</i></p>
						<p><b>- Jane Austen</b></p>
					</div>

					<h4 id="Astraightforwardness">A6 | Low Straightforwardness</h4>
					<p>Sometimes, the ends justify the means. This means that you may have to do whatever it takes to achieve what you desire, even when it means getting around some rules. In some circumstances, you may obstruct others’ plans, take advantage of them, use flattery or pressure them for your own ends.</p>
					<p>Keep in mind that people may want to get back to you for treating them unfairly and that your reputation may suffer from these extreme behaviors. What goes around comes around.</p>

					<div className="quote">
						<p><i>"Entrepreneurs are simply those who understand that there is little difference between obstacle and opportunity and are able to turn both into their advantage."</i></p>
						<p><b>- Niccolo Machiavelli</b></p>
					</div>	

					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
		</section>

		<br/> <br/>
		
		<section className="story">
		<h3>Example Of Agreeableness Misunderstandings</h3>
		
		<h4>At Work</h4>
		<div className="myimghor">
			<img src={amismatch} alt="Agreeableness mismatch"  />
		</div>
		
		<p><b>Highly-agreeable Takeda</b> is stepping in a new role, working with <b>Anja, who is highly skeptical and analytical because of her scientific background</b>. In contrast, Takeda always agrees to everything, even when he’s not sure he fully understood what he was asked to do.</p>
		<p>His empathetic nature makes it hard to understand why Anja is so distant with him. His desire to please her makes it even harder for him to open up and share his concerns and about the potential misunderstandings of his assignments. He sometimes feels isolated and like he is banging his head against an unbreakable wall.</p>
		
		<h5>What could Takeda have done differently?</h5>
		<p>Takeda should fight his fear of conflict and speak up when he is unsure about what Anja expects of him. Although she is comfortable with conflicts and having difficult conversations, it does not mean she will attack him. She may challenge his opinions, but she will not actively seek to hurt him or belittle him.</p>
		<p>He should leverage her willingness to solve problems and take care of tasks, which will help him when he is stuck and unsure how to proceed. While she may not care much about the interpersonal aspect of their working relationship, she still cares that they make progress and that their work gets done.</p>

		<h5>What about Anja?</h5>
		<p>She needs to pay attention to Takeda's nonverbal communication, meaning, his face and body language. When he appears fidgety or unsure, she needs to probe what is wrong and how she can help. Reassuring him is important to create a safe environment where he can share his thoughts, concerns, and ideas, knowing that his self-confidence will not be attacked.</p>
		<p>She needs to make an effort at building rapport and avoid jumping into problem-solving mode right away. For him, nurturing their relationship and therefore building trust is key. She needs to show him that she is a human being, not only an intimidating expert in her field.</p>
		
		<h4>With Friends and Family</h4>
		<p>Tom, a highly agreeable teenager, is in a relationship with Alexa since a few years. He always admired her drive and ability to overcome any challenges which come her way. However, although she seems to really enjoy spending time together with him, she often is not interested in talking about her feelings or hearing about his, and would rather act than speak. This pains him, as he seeks harmony in their couple and wants to make sure she feels loved and cared for.</p>
		<p>Over time, Alexa starts to feel smothered by his constant need for validation and avoidance of conflicts. While she views them as a good way to make their relationship stronger, he seems to shy away from them and shuts down when she starts to talk about how they can fix their problems, as individuals and as a couple. She doesn't understand how intimidating such conversations are for Tom. Although he also would like for them to become better people, he does not know how to navigate these difficult conversations. They slowly start to grow apart and don't know how to save their relationship.</p>

		<h5>What could Tom have done differently?</h5>
		<p>Whenever Alexa goes into problem-solving mode, Tom needs to pause and get into the right mindset. He needs to understand that she is not accusing him of anything, she is only suggesting what they can do differently to avoid a problem or fix it. The way she speaks with passion is not an attack on him or a sign of anger. She just loves solving problems and does not realize how her words and actions may be perceived as harsh or blunt.</p>
		<p>He needs to also understand if she does not feel like sharing how she feels, because she may not be as sensitive to her feelings as he is. Her desire for action does not mean she is not interested in preserving the harmony of their relationship. Rather, she seeks ways to make it even better.</p>

		<h5>What about Alexa?</h5>
		<p>She needs to be mindful of when Tom opens up to her. In such occasions, she needs to pause her drive for action and listen to him. Once he seems calmer and satisfied because he felt heard and like he belonged, then she can start discussing how they can address the situation they are facing.</p>
		<p>Their definitions of quality time spent together may differ: While she prefers being active and doing things, he may prefer talking and enjoying quiet time enjoying her presence. Both definitions are perfectly acceptable. There is no right or wrong, only different opinions.</p>
		<p>She should keep in mind that his collaborative and supportive nature, using words and gestures, are different than her drive for action, challenges and getting results. Because he does not jump into action whenever something goes wrong and would rather talk about it and ask how he can help, does not mean he does not care about their relationship and about how to make it even better. On the contrary!</p>
		</section>

		<h3>How To Work Well With High-Agreeableness People</h3>
		<div className="myimghor">
			<img src={conversation5} alt="Working with high-agreeableness individuals" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@rethaferguson" >RF._.studio</a> from <a href="https://www.pexels.com/photo/group-of-women-having-a-meeting-3810754/" >Pexels</a></p>
		</div>

		<h4>At Work</h4>
		<p>Keep in mind their desire to please you. Make sure that when they agree to doing something, they have clearly understood your expectations and know how to get started. If needed, you may ask them to write down their plans of action to make sure they will not get stuck and not dare asking you for help, out of fear of conflict.</p>
		<p>Ask them to share the concerns they have and how they are feeling, emphasizing how this matters to you, because they need to feel heard and like they belong to do their best work.</p>
		<p>During conversations, take time to pause and gather everyone’s thoughts and concerns. Emphasize how getting as much information as possible upfront can prevent further unnecessary challenges and misunderstandings from arising.</p>
		<p>Ask for written thoughts and feedback between your one-on-one conversations to give them a safe space to share their ideas, needs, thoughts, and concerns.</p>

		<h4>With Friends and Family</h4>
		<p>Keep in mind their desire to please you. Make sure that when they agree to doing something, they have clearly understood your expectations and know how to get started. This will prevent them getting stuck without daring asking you for help, out of fear of conflict.</p>
		<p>Ask them to share the concerns they have and how they are feeling, emphasizing how this matters to you, because they need to feel heard and like they belong to build strong relationships.</p>
		<p>Don't hesitate to reach out to them when you need to talk about your worries, because they tend to get tremendous pleasure from helping others and taking care of them. They naturally tend to put other people's needs above their own.</p>

		<h3>How To Work Well With Low-Agreeableness People</h3>
		<div className="myimghor">
			<img src={conversation9} alt="Working with low-agreeableness individuals" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@jopwell" >Jopwell</a> from <a href="https://www.pexels.com/photo/woman-wearing-teal-dress-sitting-on-chair-talking-to-man-2422280/" >Pexels</a></p>
		</div>

		<h4>At Work</h4>
		<p>Keep in mind they will likely tend to say “No” at first and don’t take it personally. It’s a defense mechanism to protect their time, energy, and interests.</p>
		<p>When they refuse, give them time to weight the pros and cons and get back to you at a later stage. When they realize that their helping you will make everyone better off, they are likely going to assist you.</p>
		<p>Accept that they may be skeptical and that you will need to give them enough facts to make your case, because of their practical nature. Focus on giving them facts and letting them focus on solving the challenges rather than looking for an emotional explanation of the situation. They deal better with tasks than with people.</p>

		<h4>With Friends and Family</h4>
		<p>Keep in mind they will likely tend to say “No” at first and don’t take it personally. It’s a defense mechanism to protect their time, energy, and interests.</p>
		<p>When they refuse, give them time to weight the pros and cons and get back to you at a later stage. When they realize that their helping you will make everyone better off, they are likely going to join you.</p>
		<p>Ask for their help to solve problems, as this tends to be what they prefer doing, rather than dealing with people.</p>
		<p>Ask for their opinion about a situation, because they will likely openly speak their mind, since they are not afraid of having difficult conversations or creating conflicts.</p>

		<h3>Questions To Ask To Evaluate Agreeableness</h3>
		<h4>We are going out for ____. <br />Want to join us?</h4>
		<p><b>Highly agreeable people</b> will likely accept your invitation, because they enjoy belonging to a group. Keep in mind that they may accept by default and need to make a change of plan at the last minute, to avoid creating tensions.</p>
		<p><b>Less agreeable people</b> will likely decline your invitation, because they have other priorities to attend. Keep in mind that they may decline by default and still join you at the last minute, because they had time to reconsider their decision.</p>

		<h4>Are you usually the peacemaker or the troublemaker?</h4>
		<p><b>Highly agreeable people</b> will usually try to resolve conflicts, even if it means sacrificing something for the sake of the relationship. They are also likely going to get involved in conflicts between other people, to preserve the harmony of the group.</p>
		<p><b>Less agreeable people</b> will not hesitate to be assertive about their positions and are therefore more comfortable with disagreements, debates, and conflicts. They tend to misjudge how their words and behaviors may impact other people emotionally, without their becoming aware of the invisible scars they may leave on others.</p>


		<div className="separator" />
		<div className="separator" />
		<hr/>



		<h2 id="Ntitle">Nervosity</h2>

		<div className="myimghor">
			<img src={Nspectrum} alt="Nervosity spectrum" />
		</div>

		<div className="imgcaption">
			<p>The nervosity spectrum</p>
		</div>

		<p>This trait describes how well you can keep calm under pressure:</p>

		<ul>
			<li>How do you approach worry?</li>
			<li>How emotionally reactive are you?</li>
			<li>What is your tendency towards negative emotions?</li>
			<li>How often do you engage in self-limiting thinking?</li>
		</ul>



		<h3>High-Nervosity Description</h3>

		<div className="myimghor">
			<img src={worry1img} alt="High nervousness anxiety" />
		</div>

		<div className="imgcaption">
			<p>Life is full of ups and downs, filled with trials for us to overcome</p>
			<p>Photo by <a href="https://www.pexels.com/@katlovessteve" >Kat Jayne</a> from <a href="https://www.pexels.com/photo/adult-alone-anxious-black-and-white-568027/" >Pexels</a></p>
		</div>

		<p>As a high-nervosity person, you are likely a worrier, someone who experiences intense emotions. You may reminisce over the past and worry about what’s ahead and what others think of you. You may be unconsciously looking for the challenges and downsides of everyday situations. Some people might call this pessimism, but it likely has to do with your need to search for multiple scenarios and see how you can address them.</p>

		<div className="quote">
            <p><i>"We suffer more often from imagination than from reality."</i></p>
            <p><b>- Seneca</b></p>
        </div>

		<h4>How You May Be Perceived</h4>
		<p>Your tendency to worry about future challenges is valuable, because it helps you to cover potential scenarios and prepare for the worst. Worrying about others’ well-being also helps you to be seen as a caring person.</p>
		<p>However, some people may perceive you as being insecure or anxious. To avoid making things looking only pessimistic, you can leverage your tendency to think of possible scenarios to also consider what could go right.</p>
		<p>Your emotions are a strength and are contagious. You need to learn to recognize them when they are setting in, to avoid experiencing extreme mood swings and to become better able at soothing them. Doing so is a sign of higher emotional intelligence, focusing on both self-awareness and self-development.</p>

		<h3>The 6 High Nervosity Facets</h3>

		<p>Nervosity can be broken down into six facets which describe your levels of emotional volatility and withdrawal:</p>

		<ol>
			<li><b>Anger/Irritability</b> describes your tendency to experience anger, frustration, bitterness, and resentment.</li>
			<li><b>Impulsiveness</b> describes your tendency to act on cravings and urges rather than reining them in and delaying gratification.</li>
			<li><b>Anxiety</b> describes your levels of free floating anxiety, that is, experiencing fear and anxiety in everyday situations, without being able to identify any real observable threat.</li>
			<li><b>Depression</b> describes your tendency to experience crushing feelings of sadness, guilt, despondency, and loneliness.</li>
			<li><b>Self-consciousness</b> describes your shyness or social anxiety. </li>
			<li><b>Vulnerability</b> describes your general susceptibility to stress.</li>
		</ol>

		<br/> <br/>

		<section className="accordion-width">
		<Accordion>
			<Card className="border-0">
				<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
				<b className="mr-3">{i18n.t('discover-your-6-nervosity-facets')}</b><Icon.ChevronDown className="ml-2"/>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body>
						
					<h4 id="Nanger">N1 | High Anger/Irritability</h4>
					<p>Your tendency to become angry or irritated easily will sometimes drag you down, by setting you in a bad mood. At times, your frustration may degenerate and turn into your losing your temper and become aggressive and hostile towards other people.</p>
					<p>Remember that anger fuels anger. Like kindling a fire which suddenly starts to rage with an intense blaze, anger will consume you and those around you if you don’t pay attention to it and let it dissipate before interacting with others.</p>

					<div className="quote">
						<p><i>"Anger is an acid that can do more harm to the vessel in which it is stored than to anything on which it is poured."</i></p>
						<p><b>- Mark Twain</b></p>
					</div>

					<div className="quote">
						<p><i>"Speak when you are angry and you will make the best speech you will ever regret."</i></p>
						<p><b>- Ambrose Bierce</b></p>
					</div>

					<h4 id="Nimpulsiveness">N2 | High Impulsiveness</h4>
					<p>You sometimes do things you later regret and have difficulties understanding why you do some of the things you do. Without realizing it, you may act on a whim, without considering the consequences of your actions on yourself and others around you.</p>
					<p>When you do so, you choose short-term gains over long-term ones. The tempting immediate gain lures you in, without regard for the long-term consequences. These behaviors can impact your eating, drinking or spending habits and, in the long term, your health and well-being.</p>

					<div className="quote">
						<p><i>"Wisely, and slowly. They stumble that run fast."</i></p>
						<p><b>- William Shakespeare</b></p>
					</div>

					<h4 id="Nanxiety">N3 | High Anxiety</h4>
					<p>Life can sometimes feel overwhelming. You tend to worry about things that may happen and are preoccupied by your existing problems. At times, you may be absorbed into reflecting on something you did or which happened to you, feeling stuck in the past. </p>
					<p>To get out of this mental trap, you need to try to understand what lesson you can learn about your experiences and the mistakes you made, to make sure that you can either prevent them from happening again in the future, or correct them, should they still happen.</p>

					<div className="quote">
						<p><i>"Worry is a state of min based upon fear. It works slowly, but persistently. It is insidious and subtle. Step by step it “digs itself in” until it paralyzes one’s reasoning faculty, destroys self-confidence and initiative. Worry is a form of sustained fear caused by indecision therefore it is a state of mind which can be controlled."</i></p>
						<p><b>- Napoleon Hill</b></p>
					</div>

					<div className="quote">
						<p><i>"There is only one way to happiness and that is to cease worrying about things which are beyond the power of our will."</i></p>
						<p><b>- Epitectus</b></p>
					</div>

					<h4 id="Ndepression">N4 | High Depression</h4>
					<p>You tend to experience frequent mood swings, with intense ups and downs. You often feel down. At times, you may feel desperate, disheartened, and that your life lacks direction.</p>
					<p>Because of the sadness you experience, you sometimes start doubting yourself, have a low opinion of yourself, and may at times even dislike yourself. </p>

					<div className="quote">
						<p><i>“I’m here. I love you. I don’t care if you need to stay up crying all night long, I will stay with you… There’s nothing you can ever do to lose my love. I will protect you until you die, and after your death I will still protect you. I am stronger than depression and I am braver than loneliness and nothing will ever exhaust me.”</i></p>
						<p><b>- Elizabeth Gilbert</b></p>
					</div>
					
					<h4 id="Nselfconsciousness">N5 | High Self-Consciousness</h4>
					<p>Self-consciousness describes a high preoccupation with yourself and the worrying feeling that everyone is watching you. This translates into being easily hurt, intimidated or embarrassed by everyday events.</p>
					<p>When feeling overly self-conscious, you pay too much attention to what you are doing and therefore perform worse than if you were in a more detached state of flow. By worrying about what could go wrong, things can actually go wrong.</p>
					<p>This also makes your social life more difficult: only feeling comfortable with close friends and finding it difficult to approach others or fear of drawing attention to yourself will prevent you from creating new meaningful relationships.</p>
					<p>Keep in mind the words of Henry Ford: <b>“Whether you think you can or think you can’t, you are right.”</b> Your worries may become self-fulling prophecies, stay careful not to become their victim.</p>

					<div className="quote">
						<p><i>“If you don’t believe inside yourself that you can learn a lot, nobody’s ever going to do that for you. Nobody’s ever gonna give you self drive. Nobody’s ever gonna give you self esteem. Nobody’s ever gonna give you your self worth. You have to set it for yourself.”</i></p>
						<p><b>- Brother Ali</b></p>
					</div>

					<h4 id="Nvulnerability">N6 | High Vulnerability</h4>
					<p>Your intense emotions can sometimes get the best of you. When they overwhelm you, you lose your ability to effectively make decisions. When this happens, small setbacks seem to become unsurmountable challenges, which makes you feel that you are unable to deal with them.</p>
					<p>To better deal with your powerful emotions, you need to learn to recognize them when they start appearing and apply strategies that work for you to soothe them. Ask for help if you need it, you are not alone!</p>

					<div className="quote">
						<p><i>“If you hold back on the emotions - if you don’t allow yourself to go all the way through them - you can never get to being detached, you’re too busy being afraid. You’re afraid of the pain, you’re afraid of the grief. You’re afraid of the vulnerability that loving entails. But by throwing yourself into these emotions, by allowing yourself to dive in, all the way, over your head even, you experience them fully and completely.”</i></p>
						<p><b>- Mitch Albom</b></p>
					</div>

					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
		</section>

		<br/> <br/>


		<h3>Low-Nervosity Description</h3>

		<div className="myimghor">
			<img src={relaxedimg} alt="Low nervosity relaxation" />
		</div>

		<div className="imgcaption">
			<p>How can you stay calm and at peace within the storm?</p>
			<p>Photo by <a href="https://www.pexels.com/@mateusz-dach-99805" >Mateusz Dach</a> from <a href="https://www.pexels.com/photo/pair-of-red-and-white-low-top-sneakers-914929/" >Pexels</a></p>
		</div>

		<p>As a low-nervosity person, you are more likely able to keep a clear mind in difficult situations, which makes you invaluable at solving crises. While others may panic and struggle to see the light at the end of the tunnel, your calmness will allow you to reassure them and guide them.</p>
		<p>You should also be better able to consistently refrain from experiencing extreme mood swings, as well as staying more optimistic and believing that everything will work out in the end.</p>

		<div className="quote">
			<p><i>"Life is 10% what you experience and 90% how you respond to it."</i></p>
			<p><b>- Dorothy M. Neddermeyer</b></p>
        </div>

		<h4>How You May Be Perceived</h4>
		<p>While your ability to stay calm is valuable during difficult times, some people may perceive you as an unemotional or cold person. They may perceive your poise and stoic ability to be more relaxed about your busyness as an ability to absorb more work. Be careful that you don’t take on too many responsibilities at the same time, that you will not be able to effectively manage.</p>
		<p>People with higher levels of nervosity tend to focus on worst-case scenarios and may look up to your tendency to focus on what is going right rather than what is going wrong. Support and inspire them.</p>

		<h3>The 6 Low Nervosity Facets</h3>

		<p>Nervosity can be broken down into six facets which describe your levels of emotional volatility and withdrawal:</p>

		<ol>
			<li><b>Anger/Irritability</b> describes your tendency to experience anger, frustration, bitterness, and resentment.</li>
			<li><b>Impulsiveness</b> describes your tendency to act on cravings and urges rather than reining them in and delaying gratification.</li>
			<li><b>Anxiety</b> describes your levels of free floating anxiety, that is, experiencing fear and anxiety in everyday situations, without being able to identify any real observable threat.</li>
			<li><b>Depression</b> describes your tendency to experience crushing feelings of sadness, guilt, despondency, and loneliness.</li>
			<li><b>Self-consciousness</b> describes your shyness or social anxiety. </li>
			<li><b>Vulnerability</b> describes your general susceptibility to stress.</li>
		</ol>

		<br/> <br/>

		<section className="accordion-width">
		<Accordion>
			<Card className="border-0">
				<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
				<b className="mr-3">{i18n.t('discover-your-6-nervosity-facets')}</b><Icon.ChevronDown className="ml-2"/>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body>
						
					<h4 id="Nanger">N1 | Low Anger</h4>
					<p>You are usually adept at keeping your emotions under control and prone to keeping a cool head in heated situations, when facing stress and conflict.</p>
					<p>You rarely get annoyed, upset, irritated or mad at people or during situations which don’t go your way. Instead, you tend to stoically attend to your duties without complaining about your misfortunes.</p>

					<div className="quote">
						<p><i>“A moment of patience in a moment of anger prevents a thousand moments of regret.”</i></p>
						<p><b>- Ali inn Abi Talib</b></p>
					</div>

					<h4 id="Nimpulsiveness">N2 | Low Impulsiveness</h4>
					<p>Your calm nature enables you to resist temptation easily and efficiently. Controlling your cravings, rarely overindulging, and never spending more than you can afford means that you will be able to persist and keep pursuing what you desire.</p>

					<div className="quote">
						<p><i>“The impatient man is his own enemy; he slams the door on his own progress.”</i></p>
						<p><b>- Idries Shah</b></p>
					</div>

					<h4 id="Nanxiety">N3 | Low Anxiety</h4>
					<p>You tend to feel relaxed most of the time and not to be easily bothered by events. You only rarely feel stressed and worried, usually feeling calm and even-tempered.</p>
					<p>You easily adapt to new situations and face challenges with ease. You don’t worry about things that have already happened and rarely get absorbed in reflecting on the mistakes you did in the past.</p>

					<div className="quote">
						<p><i>“Worry does not empty tomorrow of its sorrow. It empties today of its strength.”</i></p>
						<p><b>- Corrie Ten Boom</b></p>
					</div>

					<h4 id="Ndepression">N4 | Low Depression</h4>
					<p>You tend to experience life in a calm, stable, comfortable, and satisfying way, rarely experiencing ups and downs and deep feelings of sadness. Since you often feel like your life has purpose, you tend to feel comfortable, secure, and pleased with yourself.</p>

					<div className="quote">
						<p><i>“Storms make trees take deeper roots.”</i></p>
						<p><b>- Dolly Parton</b></p>
					</div>

					<h4 id="Nselfconsciousness">N5 | Low Self-consciousness</h4>
					<p>You feel self-confident and are able to stand up for yourself. Difficult social situations do not bother you, nor do unfamiliar situations make you uncomfortable. It’s difficult to make you feel embarrassed.</p>

					<div className="quote">
						<p><i>“The man who moves a mountain begins by carrying away small stones.”</i></p>
						<p><b>- Confucius</b></p>
					</div>

					<h4 id="Nvulnerability">N6 | Low Vulnerability</h4>
					<p>You are able to stay calm even in tense situations and unfazed by setbacks in life. Your ability to stay calm under pressure allows you to handle complex problems and to cope with stress.</p>

					<div className="quote">
						<p><i>“Equanmity is calamity's medicine.”</i></p>
						<p><b>- Publilius Syrus</b></p>
					</div>

					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
		</section>

		<br/> <br/>


		<section className="story">
			<h3>Examples Of Nervosity Misunderstandings</h3>
			
			<h4>At Work</h4>

			<h5>1 | Project Management</h5>
			<div className="myimghor">
				<img src={nmismatch1} alt="Nervosity mismatch example 1" />
			</div>

			<p><b>Mary is a chronicle worrier</b>. Her project is stuck and depends on <b>low-nervosity Gwen</b> finishing the tasks she was assigned. Mary sends Gwen concerned emails on a daily basis. This makes Gwen feel so frustrated and pressured that she starts making mistakes, which slows her down even more.</p>
			<p>After being two weeks behind schedule, she is finally done. She feels relieved and hopes that she will not have to work with Mary anymore.</p>

			<h5>What could Mary have done differently?</h5>
			<p>Mary should have given Gwen more space to work on her tasks. Because of how afraid Mary was that Gwen would delay the project and how she projected her worries on Gwen, her fear became a self-fulfilling prophecy and what she feared most became true.</p>
			<p>She should also have checked with the people who needed Gwen's inputs how they could help her prioritize her tasks. By finding the relevant few insights they would need to get started, they could help Gwen focus on what truly mattered first, before she moved on to the trivial details before being able to fully handover her work.</p>

			<h5>What about Gabrielle?</h5>
			<p>Although Gwen is usually able to keep her calm during stressful times, Mary's obsessive follow-up got the best of her. Instead of carrying on and dreading opening her inbox, fearing to see how many requests she received from Mary, she should have been more assertive and told her how counterproductive her behavior was and how it started to affect her ability to concentrate and work.</p>
			<p>She should also have reached out to others and asked them for help, rather than trying to do it all on her own, because she was usually able to absorb a large quantity of work. There is no shame in asking for help during busy times!</p>

			<h5>2 | Prevent & Correct Mistakes</h5>
			<div className="myimghor">
				<img src={nmismatch2} alt="Nervosity mismatch example 2" />
			</div>

			<p><b>I (Chris)</b> have worked with <b>Andi, a low-nervosity manager</b> who perfectly understood how to make the most of our differences - given that I am a highly-nervous person.</p>
			<p>He taught me that my tendency to search for the worst-possible scenarios would allow us to prevent them from happening or to correct them, should they still occur. </p>
			<p>In the world of quality, this is known as CAPA, an acronym which stands for “Corrective Actions & Preventive Actions”:</p>

			<ul>
				<li><b>How can we prevent this problem from happening?</b></li>
				<li><b>How can we correct the damages and learn from our mistakes, to prevent them from occurring again?</b></li>
			</ul>

			<p>His calm nature allowed me to channel my strengths, while calming me down in the process. This is a lesson I will never forget!</p>

			<h4>With Family and Friends</h4>
			<div className="myimghor">
				<img src={nmismatch1} alt="Nervosity mismatch example 1" />
			</div>

			<p>Gabriel, a calm and confident young man, is organizing his first holiday abroad with his best friend, Marco, who is sensitive and prone to worrying. Every time Gabriel suggests what they could do, Marco starts to panic and blurt out how things could go wrong.</p>
			<p>As their departure time is getting closer and they've barely made any progress in their planning, Gabriel starts pushing Marco to make a decision. The more impatient Gabriel becomes, the angrier Marco gets. What should have been a time for bonding and discovering another culture becomes a boring trip stuck around their hotels.</p>

			<h5>What could Gabriel have done differently?</h5>
			<p>Gabriel should have helped Marco realize that his imagined scenarios were unlikely going to happen, and probed him to find solutions to solve them as well as ways to avoid them. Marco needed to vent to avoid spinning his wheels and could have decided on what he really wanted to do, once his mind was calmer.</p>
			<p>He could also have reassured him that they did not have to fully commit to any decision right away, since they still had time to revisit their ideas before having to book them. He knows that Marco tends to see everything as being urgent and important, however no one activity was so urgent nor important to become completely overwhelmed.</p>
			<p>Overall, he needs to help Marco understand how the planned activity is not the source of his anxiety, but his interpretation of the situation. By repetitively thinking about the situation and considering the pros, not only the cons, he could help Marco to get out of his spiral of worries.</p>

			<h5>What about Marco?</h5>
			<p>Marco should have told Gabriel how much this trip meant for him, since it was his first time away from his family. Without realizing it, he tended to idealize the trip, wanting it to be perfect, which paralyzed him when he had to decide what to do. Each time they opted for an option, it meant they couldn't do any other activity, especially since they only had a few weeks before they had to go back to college.</p>
			<p>He could have suggested activities he knew they would both enjoy doing, rather than seeking entirely new ones that they might regret afterwards. Marco knows he tends to easily regret his decisions and is afraid he will let Gabriel down, which prevents him from committing to any idea he suggests.</p>
			<p>Whenever Marco saw a slight look of disappointment on Gabriel's face, he disproportionately started to think that meant their friendship was over. Afraid to start the conversation and ask how Gabriel was feeling and what he was thinking they should do, he started shutting down and wouldn't speak about it for a couple days.</p>
		</section>

		<h3>How To Work Well With High-Nervosity People</h3>
		<div className="myimghor">
			<img src={worry3img} alt="Working with high-nervosity individuals" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@andrew" >Andrew Neel</a> from <a href="https://www.pexels.com/photo/photo-of-man-leaning-on-wooden-table-3132388/" >Pexels</a></p>
		</div>

		<h4>At Work, With Family and Friends</h4>
		<p>Give them enough safe space to express themselves, both orally and in written. Review together the alternatives, pros and cons, and backup plans. Let them be thorough enough so that they don’t spin their wheels and overthink things while they are working.</p>
		<p>Give them enough time to share their ideas, thoughts, and concerns. Resist the urge to put them against a wall or to let them ruminate over their concerns after conversations. Reassure them by letting them know that you are trying to cover multiple potentialities, so they don’t have to worry about them themselves. This will make them trust you and reduce their wariness.</p>
		<p>Leverage their ability to think of multiple scenarios to see which opportunities and risks you may be confronted with. How can you prevent a worst-case scenario from happening? How can you repair the resulting damages?</p>


		<h3>How To Work Well With Low-Nervosity People</h3>
		<div className="myimghor">
			<img src={highfive} alt="Working with low-nervosity individuals" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@fauxels" >fauxels</a> from <a href="https://www.pexels.com/photo/photo-of-people-holding-each-other-s-hands-3184433/" >Pexels</a></p>
		</div>

		<h4>At Work, With Family and Friends</h4>
		<p>If you tend to worry a lot, let them know in advance that this is nothing personal. It’s who you are, and everyone gets treated the same way and you are working on it. Leverage their tendency to not get concerned with potential issues. Show them you have done your due diligence and let them trust your work.</p>
		<p>Ask for their support during crises and leverage their ability to think clearly during stressful situations. They will likely be able to get you unstuck and moving forward again.</p>
		<p>Leverage one-on-one conversations to raise your concerns and let them express theirs, rather than bombarding them with emails or phone calls. They are usually able to absorb more stress than you without being affected, but it doesn’t mean that you should harass them or that they will never break.</p>
		<p>Take the time to prepare for your conversations, which will make them more effective. They will appreciate your ability to share your thoughts and concerns without being emotionally overwhelmed in the moment.</p>


		<h3>Questions To Ask To Evaluate Nervosity</h3>
		<h4>What challenging projects have you worked on recently? <br /> How did they go?</h4>
		<p><b>High-nervosity people</b> will likely look slightly more uncomfortable or stressed while mentally recalling and explaining they challenging work. They may share more about the challenges they faced, their worries and how things felt overwhelming. Do they focus on the negatives?</p>
		<p><b>Low-nervosity people</b> will likely look more relaxed and unfazed by what life threw at them. They may talk serenely about what happened, without seeming affected by what went wrong and challenged them, potentially considering the positive aspects of their work over the negative ones.</p>

		<h4>What do you need help with?</h4>
		<p><b>High-nervosity people</b> tend to quickly know where they struggle and should looked relieved when someone offers to help. They may ask questions to make sure that they are not wasting your time or to see if you know someone else who could better match their needs. </p>
		<p><b>Low-nervosity people</b> tend to be more self-confident and would therefore likely not be sure they need help, unless they are completely stuck with their work. That doesn’t mean that they will not appreciate you offering to help, however they would only resort to asking for it in critical situations. This may be due to their tendency to not suffer from stress as much as other people would.</p>

		<h4>Do you sometimes feel bad, sad or mad for no apparent reason?</h4>
		<p><b>High-nervosity people</b> experience life intensely like an extreme emotional rollercoaster. Their highs and lows are powerful and sometimes overwhelming. Therefore, what would appear for many people as a normal, everyday situation can seem to be an insurmountable trial to them. </p>
		<p>Their emotions rage inside, leaving them feeling angry, sad, ashamed or afraid for what seems to be no apparent reason. When they do spend enough time to reflect, they may find the explanation for their dismay.</p>
		<p><b>Low-nervosity people</b>’s emotional reaction tend to be more tempered and less overwhelming. Only in rare circumstances do they succumb to their emotions and lose control.</p>
		<p>Therefore, they move through life without being especially affected by what they experience. At times, they may even have a difficult time realizing what they are feeling, unable to name their emotions accurately.</p>

		<h4>Do you worry about things that might happen?</h4>
		<p>Worrying is a hallmark of the <b>high-nervosity personality trait</b>. Nervous people experience higher-than-average levels of fear at all times, which makes them prone to wonder and worry about what the future holds. </p>
		<p>You should therefore be able to read fear on their face and body language, where they may suddenly start curling up, shiver or become fidgety. </p>
		<p><b>Low-nervosity people</b> and their calm demeanor also reflects their inner mental and emotional states. Because they are more self-confident about their abilities to tackle the challenges life will throw at them, they will not experience as much fear and stress when they think about their future.</p>
		<p>Their facial expressions and body language should stay more relaxed and they will appear more confident as a result of their emotional stability.</p>

		<h4>Do you worry too long after an embarrassing experience?</h4>
		<p>Shame is one of our five core emotions and is no exception to what <b>high-nervosity people</b> experience regularly. You may hear them say out loud, “Oh God why?”, “What is wrong with me?”, “Why did I do that?” </p>
		<p>In all likelihood, even if they don’t express them openly, they are likely going to think these thoughts and feel shame wash over their bodies. They may also look around them warily, hoping nobody saw what they did and try to disappear.</p>
		<p>Our brains tend to remember shameful experiences strongly, and may frequently play back to them in the theaters of our imagination, trying to help us learn a few lessons that will reduce the likelihood of us behaving in similarly embarrassing manners in the future.</p>
		<p><b>Low-nervosity people</b> will also experience shame, although they tend to be more quick to learn from their mistakes and will therefore not linger on them. Once again, they experience less volatile highs and lows and have faster recovery times when they do.</p>
		

		<div className="separator" />
		<div className="separator" />
		<hr/>



		<h2>Closing Thoughts & Your Next Steps</h2>
		<p>Your OCEAN personality traits shape your life, and your life shapes your personality traits. In this module, you will learn more about how you can better understand the OCEAN personality traits model and how to take action.</p>
		<p>Feel free to explore the following topics:</p>

		<Table striped borderless hover responsive className="course-table">
			<thead>
				<tr><th className="course-table-header">Module 3: OCEAN Personality Traits & Emotional Intelligence</th></tr>
			</thead>
			<tbody className="course-table-body">
				<tr> <td> <a href="/myoceanity-and-ei">How can you use MyOceanity to build your emotional intelligence?</a></td> </tr>
				<tr> <td> <a href="/oceanology">OCEANology</a></td> </tr>
			</tbody>
		</Table>

		<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
        </div>
		
		<div className="separator" />
		<div className="separator" />
		
   </div>
  )
}

export default OceanMisunderstandings;

