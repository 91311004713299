import React from 'react';
import { Card, CardDeck, Image, ProgressBar } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';
import ReactMarkdown from 'react-markdown'
import { useTranslation } from "react-i18next";

import * as Icon from 'react-bootstrap-icons';


import teamworkimg from "../images/teamwork.png";
import problemsolving from "../images/problem-solving.png";
import dilemmaimg from "../images/dilemma.jpg";
import analyticsimg from "../images/analytics.jpg";
import paretoprincipleimg from "../images/pareto-principle.png";
import communicationimg from "../images/communication.jpg";
import leadershipimg from "../images/leadership.png";
import raisedhandsimg from "../images/raised-hands.jpg";
import fingerprintsimg from "../images/researcher.jpg";
import likabilityexpertisereliability from "../images/likability-expertise-reliability.png";
import expertiseimg from "../images/expertise.jpg";
import growthimg from "../images/growth.jpg";
import climbingimg from "../images/climbing.jpg";
import batteryimg from "../images/battery.jpg";
import feedbackimg from "../images/feedback.jpg";
import ovaluesimg from "../images/o-values.png";
import maslowpyramid1img from "../images/maslow-pyramid-1.png";
import emotionalityimg from "../images/emotionality.png";
import warmthimg from "../images/warmth.jpg";
import fivebehaviorsimg from "../images/five-behaviors-1.png";
import feelingvaluedimg from "../images/feeling-valued.png";
import yourratingstheirratingsimg from "../images/your-ratings-their-ratings.png";
import quotedoerrfeedback from "../images/quote-doerr-feedback.png";
import quoteplatodiscourage from "../images/quote-plato-discourage.png";
import quotedarwinsmith from "../images/quote-darwin-smith.png";
import quoteindeedcommunication from "../images/quote-indeed-communication.png";
import quotewerbercommunication from "../images/quote-werber-communication.png";
import quotecoveylisten from "../images/quote-covey-listen.jpg";
import quotebuddhamouth from "../images/quote-buddha-mouth.png";
import quoterooseveltrightthing from "../images/quote-roosevelt-right-thing.jpg";
import quotedweckpotential from "../images/quote-dweck-potential.png";
import quotefordmindset from "../images/quote-ford-mindset.png";
import quotewilliamslearning from "../images/quote-williams-learning.png";
import quotewenzelmistakes from "../images/quote-wenzel-mistakes.png";
import quotesenecamistakes from "../images/quote-seneca-mistakes.png";
import quotewenzelfearfailure from "../images/quote-wenzel-fearfailure.png";
import quoteschwartzfeelvalued from "../images/quote-schwartz-feelvalued.png";
import quotemandelalastspeak from "../images/quote-mandela-lastspeak.png";
import quotedunningselfinsight from "../images/quote-dunning-selfinsight.png";
import quoteassume from "../images/quote-assume.png";

import quotedoerrfeedbackfr from "../images/quote-doerr-feedback-fr.png";
import quoteplatodiscouragefr from "../images/quote-plato-discourage-fr.png";
import quotedarwinsmithfr from "../images/quote-smith-qualified-fr.png";
import quoteindeedcommunicationfr from "../images/quote-indeed-communication-fr.png";
import quotewerbercommunicationfr from "../images/quote-werber-communication-fr.png";
import quotecoveylistenfr from "../images/quote-covey-listen-fr.png";
import quotebuddhamouthfr from "../images/quote-buddha-mouth-fr.png";
import quoterooseveltrightthingfr from "../images/quote-roosevelt-right-thing-fr.png";
import quotedweckpotentialfr from "../images/quote-dweck-potential-fr.png";
import quotefordmindsetfr from "../images/quote-ford-mindset-fr.png";
import quotewilliamslearningfr from "../images/quote-williams-learning-fr.png";
import quotewenzelmistakesfr from "../images/quote-wenzel-mistakes-fr.png";
import quotesenecamistakesfr from "../images/quote-seneca-mistakes.png";
import quotewenzelfearfailurefr from "../images/quote-wenzel-fearfailure-fr.png";
import quoteschwartzfeelvaluedfr from "../images/quote-schwartz-feelvalued-fr.png";
import quotemandelalastspeakfr from "../images/quote-mandela-lastspeak-fr.png";
import quotedunningselfinsightfr from "../images/quote-dunning-selfinsight-fr.png";


import { questionsApi } from "../api/_360_questions";
import i18n from "i18next";

function percent(item){
	return 100*(item/7);
}

const i18nquotedoerrfeedback = () => {
	switch (i18n.language) {
		case "fr" :
			return quotedoerrfeedbackfr
		case "en" :
		default :
			return quotedoerrfeedback
	}
}

const i18nquoteplatodiscourage = () => {
	switch (i18n.language) {
		case "fr" :
			return quoteplatodiscouragefr
		case "en" :
		default :
			return quoteplatodiscourage
	}
}

const i18nquotedarwinsmith = () => {
	switch (i18n.language) {
		case "fr" :
			return quotedarwinsmithfr
		case "en" :
		default :
			return quotedarwinsmith
	}
}

const i18nquoteindeedcommunication = () => {
	switch (i18n.language) {
		case "fr" :
			return quoteindeedcommunicationfr
		case "en" :
		default :
			return quoteindeedcommunication
	}
}

const i18nquotewerbercommunication = () => {
	switch (i18n.language) {
		case "fr" :
			return quotewerbercommunicationfr
		case "en" :
		default :
			return quotewerbercommunication
	}
}

const i18nquotecoveylisten = () => {
	switch (i18n.language) {
		case "fr" :
			return quotecoveylistenfr
		case "en" :
		default :
			return quotecoveylisten
	}
}

const i18nquotebuddhamouth = () => {
	switch (i18n.language) {
		case "fr" :
			return quotebuddhamouthfr
		case "en" :
		default :
			return quotebuddhamouth
	}
}

const i18nquoterooseveltrightthing = () => {
	switch (i18n.language) {
		case "fr" :
			return quoterooseveltrightthingfr
		case "en" :
		default :
			return quoterooseveltrightthing
	}
}

const i18nquotedweckpotential = () => {
	switch (i18n.language) {
		case "fr" :
			return quotedweckpotentialfr
		case "en" :
		default :
			return quotedweckpotential
	}
}

const i18nquotefordmindset = () => {
	switch (i18n.language) {
		case "fr" :
			return quotefordmindsetfr
		case "en" :
		default :
			return quotefordmindset
	}
}

const i18nquotewilliamslearning = () => {
	switch (i18n.language) {
		case "fr" :
			return quotewilliamslearningfr
		case "en" :
		default :
			return quotewilliamslearning
	}
}

const i18nquotewenzelmistakes = () => {
	switch (i18n.language) {
		case "fr" :
			return quotewenzelmistakesfr
		case "en" :
		default :
			return quotewenzelmistakes
	}
}

const i18nquotesenecamistakes = () => {
	switch (i18n.language) {
		case "fr" :
			return quotesenecamistakesfr
		case "en" :
		default :
			return quotesenecamistakes
	}
}

const i18nquotewenzelfearfailure = () => {
	switch (i18n.language) {
		case "fr" :
			return quotewenzelfearfailurefr
		case "en" :
		default :
			return quotewenzelfearfailure
	}
}

const i18nquoteschwartzfeelvalued = () => {
	switch (i18n.language) {
		case "fr" :
			return quoteschwartzfeelvaluedfr
		case "en" :
		default :
			return quoteschwartzfeelvalued
	}
}

const i18nquotemandelalastspeak = () => {
	switch (i18n.language) {
		case "fr" :
			return quotemandelalastspeakfr
		case "en" :
		default :
			return quotemandelalastspeak
	}
}

const i18nquotedunningselfinsight = () => {
	switch (i18n.language) {
		case "fr" :
			return quotedunningselfinsightfr
		case "en" :
		default :
			return quotedunningselfinsight
	}
}


const ReportPBar = (props) => {
	return <div><section className="progress-width mb-5">
				<ProgressBar className="my-2 my-rating" now={percent(props.results.user[props.category])} label={props.results.user[props.category]} />
				<ProgressBar className="my-2 their-rating" now={percent(props.results.feedback[props.category])} label={props.results.feedback[props.category]} />
			</section>

		<div className="myimghor my-0">
			<img src={yourratingstheirratingsimg} alt="Your ratings and their ratings" />
		</div>
	</div>
}
const ReportTopItem = (props) => {
	return (<div><p><a href={`#section-${props.num}`} className="important-link">{props.num-1}. {props.category} <Icon.ArrowRight className="ml-2" /></a></p>
		<ReportPBar results={props.results} category={props.category} num={props.num} />
	</div>)
}

function getQuestionLabel(questionId){
	return questionsApi[i18n.language].filter( q => q['id'] == questionId)[0]['userQuestion'];
}

function IndividualQuestionFeedback(props: { questionId: string, number: number, feedbackByQuestion: any }) {
	return (
		<div><h5>{props.number}. {getQuestionLabel(props.questionId)}</h5>
			{props.feedbackByQuestion[props.questionId]?
				(<div><section className="progress-width">
					<ProgressBar className="my-2 my-rating" now={percent(props.feedbackByQuestion[props.questionId].user)} label={props.feedbackByQuestion[props.questionId].user} />
					{props.feedbackByQuestion[props.questionId].count < 2 ? <ProgressBar striped={true} animated={true} className="my-2 their-rating" now={100} label={'not enough results'} aria-disabled={true} /> :
					<ProgressBar className="my-2 their-rating" now={percent(props.feedbackByQuestion[props.questionId].average)} label={props.feedbackByQuestion[props.questionId].average} />}
				</section>
				<div className="myimghor my-0">
					<img src={yourratingstheirratingsimg} alt="Your ratings and their ratings" />
				</div></div>)
				: <div/>
			}
		</div>);
}

const shbw = (user:number, feedback:number) => {
	const threshold = 5;
	if (user >= threshold && feedback >= threshold){
		return "Strength";
	}
	if (user >= threshold && feedback < threshold){
		return "Blind spot";
	}
	if (user < threshold && feedback >= threshold){
		return "Hidden strength";
	}
	if (user < threshold && feedback < threshold){
		return "Weakness";
	}
	return "Unknown";
}

function SkillResult(props: { category: string, results: any}) {
	const {i18n} = useTranslation();

	const level:string = shbw(props.results.user[props.category],props.results.feedback[props.category])
	return <div>
		<h5>{i18n.t('your-0')} {props.category} {i18n.t('skills-are-a')} {i18n.t(level)}</h5>
		<h5>{i18n.t(level+'.desc')}</h5>
	</div>
}

function ThreeSixtyFeedbackContent(props: any, i18n) {
	const categories = [
		'Problem-solving',
		'Teamwork',
		'Strong work ethics',
		'Analytical Thinking',
		'Communication',
		'Leadership',
		'Initiative',
		'Thoroughness',
		'Expertise',
		'Growth Mindset',
		'Drive for results',
		'Energy management',
		'Openness to feedback',
		'Purpose',
		'Emotional poise',
		'Trust building'
	];
	function topItem(results, category,num){
		return <ReportTopItem results={results} category={category} num={num} />
	}


  return (
   	<div>
		<body className="masthead" data-spy="scroll" data-offset="60" data-target="#myNavbar">

			<Scrollspy items={ ['send-invites','section-1', 'section-2', 'section-3', 'section-4', 'section-5', 'section-6', 'section-7', 'section-8', 'section-9', 'section-10', 'section-11', 'section-12', 'section-13', 'section-14', 'section-15', 'section-16', 'section-17', 'section-18' ] } currentClassName="is-current" className="sticky-top scrollspy overflow-scroll-horizontal" offset={20}>
				<li><a href="#send-invites" className="scrollspy-link">{i18n.t('send-more-invitations')}</a></li>
				<li><a href="#section-1" className="scrollspy-link">{i18n.t('top-0')}</a></li>
				<li><a href="#section-2" className="scrollspy-link">1. {i18n.t('problem-solving')}</a></li>
				<li><a href="#section-3" className="scrollspy-link">2. {i18n.t('teamwork')}</a></li>
				<li><a href="#section-4" className="scrollspy-link">3. {i18n.t('work-ethic')}</a></li>
				<li><a href="#section-5" className="scrollspy-link">4. {i18n.t('analytical-skills')}</a></li>
				<li><a href="#section-6" className="scrollspy-link">5. Communication</a></li>
				<li><a href="#section-7" className="scrollspy-link">6. Leadership</a></li>
				<li><a href="#section-8" className="scrollspy-link">7. Initiative</a></li>
				<li><a href="#section-9" className="scrollspy-link">8. {i18n.t('thoroughness')}</a></li>
				<li><a href="#section-10" className="scrollspy-link">9. Expertise</a></li>
				<li><a href="#section-11" className="scrollspy-link">10. {i18n.t('growth-mindset')}</a></li>
				<li><a href="#section-12" className="scrollspy-link">11. {i18n.t('drive-for-results')}</a></li>
				<li><a href="#section-13" className="scrollspy-link">12. {i18n.t('energy-management')}</a></li>
				<li><a href="#section-14" className="scrollspy-link">13. {i18n.t('openness-to-feedback')}</a></li>
				<li><a href="#section-15" className="scrollspy-link">14. {i18n.t('purpose-orientation')}</a></li>
				<li><a href="#section-16" className="scrollspy-link">15. {i18n.t('emotional-poise')}</a></li>
				<li><a href="#section-17" className="scrollspy-link">16. {i18n.t('trust-building')}</a></li>
			</Scrollspy>

			<section id="section-1">
				<div className="myimghor">
					<img src={feedbackimg} alt="People talking to each other" />
				</div>
				

				<h1>{i18n.t('your-360-degree-feedback')}</h1>
				
				<div className="myimghor">
					<img src={i18nquotedoerrfeedback()} alt="Quote by John Doerr: Constructive, critical feedback is one of the rarest thing to get in a business setting." />
				</div>

				<p><b>{i18n.t('number-of-feedbacks-requested')} {props.totalSent}</b></p>
				<p><b>{i18n.t('number-of-feedbacks-received')} {props.responsesSoFar}</b></p>


				<h2>{i18n.t('your-16-skills-at-a-glance')}</h2>

				<div className="myimghor">
					<img src={yourratingstheirratingsimg} alt="Your ratings and their ratings" />
				</div>
				
				{categories.map((value, index) => topItem(props.results,value,index+2))}
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-2" className="offset-anchor">
				<div className="myimghor">
					<img src={problemsolving} alt="People solving problems" />
				</div>


				<h2>1. {i18n.t('your-problem-solving-skills')}</h2>

				<ReportPBar results={props.results} category={'Problem-solving'} num={1}/>

				<SkillResult category={'Problem-solving'} results={props.results}/>

				<h3>{i18n.t('what-is-problem-solving')}</h3>
				<ReactMarkdown>{i18n.t('problem-solving-describes-your-ability-to-understand-the-root-cause-of-a-problem-how-to-correct-it-and-how-to-prevent-it-from-reoccurring')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('solving-problems-requires-going-through-6-steps')}</ReactMarkdown>

				<ol>
					<li>{i18n.t('defining-the-problem')}</li>
					<li>{i18n.t('identifying-its-root-cause')}</li>
					<li>{i18n.t('brainstorming-potential-solutions')}</li>
					<li>{i18n.t('evaluating-solutions')}</li>
					<li>{i18n.t('implementing-the-chosen-solution')}</li>
					<li>{i18n.t('assessing-the-solutions-effectiveness-post-mortem')}</li>
				</ol>

				<h4>{i18n.t('feedback-on-your-3-problem-solving-answers')}</h4>
				<IndividualQuestionFeedback number={1} questionId={'bb3dd9a1-a3ff-48e3-8578-8e793874b6b8'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h5>{i18n.t('why-it-matters')}</h5>
				<ReactMarkdown>{i18n.t('while-problems-are-inevitable-in-life-and-at-work-not-all-problems-are-created-equal-some-have-more-dire-consequences-than-others-while-others-are-difficult-to-understand')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('however-searching-for-the-root-cause-of-a-problem-is-key-in-both-solving-it-and-preventing-it-from-occurring-again-all-three-activities-are-key-if-you-want-to-become-an-expert-at-solving-problems')}</ReactMarkdown>

				
				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'863e0bdc-8c6e-42b6-b271-170051419294'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h5>{i18n.t('why-it-matters')}</h5>
				<ReactMarkdown>{i18n.t('accurately-defining-your-problem-is-key-because-if-you-dont-define-the-right-problem-you-will-likely-solve-the-wrong-problem-here-is-an-example')}</ReactMarkdown>
				
				<div className="story my-3">
					<ReactMarkdown>{i18n.t('your-companys-profits-have-fallen-dramatically-in-the-last-12-months-what-should-you-do')}</ReactMarkdown>
				</div>
				
				<ReactMarkdown>{i18n.t('by-taking-enough-time-to-research-the-root-cause-of-your-problem-the-fact-that-your-revenues-have-plateaued-while-your-costs-have-exploded-over-the-same-time-period-you-will-not-realize-that-the-question-you-need-to-answer-is-how-can-i-reduce-my-costs')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('if-you-only-focused-on-the-revenue-portion-of-the-profitability-equation-you-may-not-have-been-able-to-solve-your-problem-while-changing-your-cost-structure-would-more-quickly-bring-you-back-to-breakeven')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'7c07dab7-934b-4f82-8d0b-8e617280072f'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h5>{i18n.t('why-it-matters')}</h5>
				<ReactMarkdown>{i18n.t('opinions-are-dangerous-because-you-are-more-likely-going-to-be-wrong-than-lucky-facts-and-data-on-the-other-hand-point-to-a-more-objective-and-realistic-understanding-of-your-situation-therefore-they-are-a-better-leg-to-stand-on-than-opinions-are-if-you-have-an-idea-you-can-test-its-validity-by-searching-for-facts-that-will-prove-or-disprove-it')}</ReactMarkdown>
				
				<h4>{i18n.t('how-you-can-grow-your-problem-solving-skills')}</h4>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>

				<div className="separator-large" />
			</section>
			

			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-3" className="offset-anchor">
				<div className="myimghor">
					<img src={teamworkimg} alt="People helping each other" />
				</div>

				<h2>2. {i18n.t('your-teamwork-skills')}</h2>

				<ReportPBar results={props.results} category={'Teamwork'} num={2}/>

				<SkillResult category={'Teamwork'} results={props.results}/>

				<h3>{i18n.t('what-are-teamwork-skills')}</h3>
				<ReactMarkdown>{i18n.t('teamwork-describes-your-ability-to-work-with-others-anticipate-and-meet-their-demands-to-achieve-a-common-objective')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('teamwork-means-you-have-the-interests-of-the-team-at-heart-rather-than-solely-focusing-on-your-own-the-collective-performance-of-the-team-is-what-truly-matters-if-your-individual-contribution-is-good-yet-you-dont-assist-the-rest-of-the-team-when-it-struggles-your-teamwork-is-poor')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('teamwork-requires-that-you')}</ReactMarkdown>

				<ol>
					<li>{i18n.t('share-a-common-vision-of-the-future-with-the-rest-of-the-team')}</li>
					<li>{i18n.t('are-willing-to-help-when-needed')}</li>
					<li>{i18n.t('do-your-best-and-keep-others-accountable-for-doing-their-best')}</li>
					<li>{i18n.t('engage-in-constructive-conversations-about-the-challenges-at-hand-rather-than-in-destructive-conversations-about-the-people')}</li>
				</ol>

				<h4>{i18n.t('feedback-on-your-3-teamwork-answers')}</h4>
				<IndividualQuestionFeedback number={1} questionId={'2b3888d8-b5f4-4b60-9901-d33b3f3b76db'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h5>{i18n.t('why-it-matters')}</h5>
				<ReactMarkdown>{i18n.t('receiving-praise-and-encouragements-is-a-key-motivator-for-many-people-how-did-you-feel-when-you-worked-hard-on-a-project-and-did-not-receive-any-praise-or-encouragement-along-the-way')}</ReactMarkdown>
				
				<div className="myimghor">
					<img src={i18nquoteplatodiscourage()} alt="Quote by Plato: Never discourage anyone who continually makes progress, no matter how slow." />
				</div>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'e77e6a6b-a4d6-48e7-b0f4-a71b2933f15c'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h5>{i18n.t('why-it-matters')}</h5>
				<ReactMarkdown>{i18n.t('you-dont-have-to-do-everything-yourself-to-be-a-great-team-player-when-someone-asks-you-a-question-and-you-connect-them-with-another-person-who-will-likely-be-able-to-help-them-out-you-are-still-helping-the-team-without-sacrificing-too-much-of-your-precious-time-and-energy')}</ReactMarkdown>

				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'fb6ab099-d18a-4f22-9088-903abbc263c6'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h5>{i18n.t('why-it-matters')}</h5>
				<ReactMarkdown>{i18n.t('being-a-good-individual-contributor-is-not-sufficient-to-become-a-great-team-player-you-have-to-be-willing-to-go-beyond-your-roles-and-responsibilities-and-help-your-teammates-when-they-need-you')}</ReactMarkdown>

				<section className="course-tables-center mt-4">
					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={likabilityexpertisereliability} variant="top" className="mb-3" />
						<Card.Title className="m-3">{i18n.t('what-are-the-3-elements-of-trust')}<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/elements-of-trust" className="stretched-link"></a>
					</Card>
				</section>


				<h4>{i18n.t('how-you-can-grow-your-teamwork-skills')}</h4>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-4" className="offset-anchor">

				<div className="myimghor">
					<img src={dilemmaimg} alt="Person facing a dilemma" />
				</div>

				<h2>3. {i18n.t('your-strong-work-ethic')}</h2>

				<ReportPBar results={props.results} category={'Strong work ethics'} num={3}/>

				<SkillResult category={'Strong work ethics'} results={props.results}/>

				<h3>{i18n.t('what-is-a-strong-work-ethic')}</h3>
				<ReactMarkdown>{i18n.t('a-strong-work-ethic-describes-how-morally-you-behave-at-work-going-the-extra-mile-is-not-enough-because-how-you-interact-with-others-may-affect-morale-and-therefore-the-collective-performance-of-the-team')}</ReactMarkdown>

				<h3>{i18n.t('feedback-on-your-3-strong-work-ethic-answers')}</h3>

				<IndividualQuestionFeedback number={3} questionId={'3aeaae3f-8a95-4b41-81de-780fc96c333b'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('focusing-on-short-term-results-such-as-meeting-the-analysts-quarterly-arbitrary-predictions-at-all-costs-leads-to-cutthroat-working-environments-unethical-behavior-and-mass-layoffs-when-we-put-the-numbers-before-the-people-everyone-suffers-including-the-numbers-we-are-trying-to-reach')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('there-are-many-usual-suspects-to-cutting-corners')}</ReactMarkdown>
				
				<ul>
					<li>{i18n.t('reduce-investments-in-r-and-d')}</li>
					<li>{i18n.t('regularly-laying-off-people')}</li>
					<li>{i18n.t('using-cheaper-materials-resulting-in-lower-quality')}</li>
					<li>{i18n.t('growing-through-acquisitions-without-seeking-to-harmonize-the-two-organizations')}</li>
				</ul>

				<ReactMarkdown>{i18n.t('this-obsession-with-beating-the-competition-and-winning-at-all-costs-has-devastating-results')}</ReactMarkdown>
 
				<ol>
					<li>{i18n.t('people-start-to-realize-they-are-not-safe-from-being-laid-off-at-any-time-for-arbitrary-reasons')}</li>
					<li>{i18n.t('this-forces-them-to-stop-trusting-their-coworkers-leaders-and-organizations')}</li>
					<li>{i18n.t('they-then-start-engaging-in-self-preserving-behaviors-such-as-hoarding-information-hiding-mistakes-and-avoiding-risk-taking')}</li>
					<li>{i18n.t('as-a-result-teamwork-starts-to-suffer-and-so-does-innovation-and-the-companys-performance-which-exacerbates-the-problem')}</li>
				</ol>

				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'86c6f04a-b0a3-4d49-a6fc-af52869dda83'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('in-good-to-great-jim-collins-wrote-about-level-5-leaders-people-who-demonstrate-both-extreme-personal-humility-and-an-intense-professional-will-they-want-their-people-and-their-organizations-to-persevere-and-to-thrive-without-putting-themselves-in-the-spotlight-by-taking-undue-credit')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('by-doing-so-they-enabled-their-people-to-do-the-right-things-and-to-resist-the-temptation-to-put-their-personal-interests-ahead-of-the-interests-of-the-team-and-the-organization-this-as-a-result-made-them-more-resilient-and-led-to-far-greater-performance-which-did-not-decline-over-time')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('one-behavior-that-these-rare-leaders-demonstrated-was-called-by-collins-the-window-and-the-mirror')}</ReactMarkdown>

				<ul>
					<li>{i18n.t('they-looked-out-of-the-window-for-successes-giving-their-partners-more-credit-for-achievements')}</li>
					<li>{i18n.t('they-looked-in-the-mirror-for-responsibility-looking-for-their-accountability-when-things-went-wrong-and-seeking-to-understand-how-they-can-grow-from-their-setbacks')}</li>
				</ul>

				<div className="notabene">
					<ReactMarkdown>{i18n.t('in-other-words-they-gave-away-all-the-credit-when-things-went-well-and-took-all-the-responsibility-when-things-went-wrong-they-were-tough-on-themselves-and-generous-with-others')}</ReactMarkdown>
				</div>
				
				<ReactMarkdown>{i18n.t('in-contrast-leaders-who-did-not-manage-to-achieve-the-transition-from-good-to-great-organizations-demonstrated-the-opposite-tendencies')}</ReactMarkdown>

				<ul>
					<li>{i18n.t('they-looked-out-of-the-window-for-responsibility-searching-for-people-and-events-they-could-blame-to-explain-why-their-brilliant-plans-did-not-work')}</li>
					<li>{i18n.t('they-looked-in-the-mirror-for-successes-claiming-excessive-credit-for-achievements')}</li>
				</ul>

				<div className="notabene">
					<ReactMarkdown>{i18n.t('in-other-words-they-took-all-the-credit-when-things-went-well-and-passed-the-blame-on-everyone-and-everything-else-when-things-went-wrong-they-were-generous-with-themselves-and-tough-on-others')}</ReactMarkdown>
				</div>

				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'e77e6a6b-a4d6-48e7-b0f4-a71b2933f15c'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('in-the-first-century-in-ancient-rome-there-was-already-a-saying-errare-humanum-est-persevare-diabolicum-which-means-to-err-is-human-but-to-persist-in-your-mistake-is-diabolical-humans-are-fallible-and-imperfect-beings-and-that-is-okay-making-mistakes-is-unavoidable-and-can-bring-with-it-much-learning')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('what-the-romans-considered-to-be-unacceptable-was-to-persist-when-you-knew-that-what-you-were-doing-was-wrong-therefore-not-learning-from-your-mistakes-and-changing-your-behavior-perseverance-is-a-wonderful-human-quality-as-long-as-it-aims-towards-the-sky-for-the-improvement-of-humankind-persisting-in-your-mistakes-however-is-the-path-towards-misery-and-despair')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('trying-to-correct-your-mistakes-to-right-your-wrongs-is-a-great-way-of-taking-responsibility-for-your-actions-and-your-mistakes-when-others-see-you-admit-your-mistakes-and-try-to-correct-them-they-will-perceive-you-as-someone-who-they-can-trust')}</ReactMarkdown>
				
				<div className="myimghor">
					<img src={i18nquotedarwinsmith()} alt="Quote by Darwin E. Smith: I never stopped trying to become qualified for the job." />
				</div>

				<h3>{i18n.t('how-you-can-grow-your-work-ethic')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-5" className="offset-anchor">
				<div className="myimghor">
					<img src={analyticsimg} alt="Group of friends together" />
				</div>

				<h2>4. {i18n.t('your-analytical-skills')}</h2>

				<ReportPBar results={props.results} category={'Analytical Thinking'} num={4}/>

				<SkillResult category={'Analytical Thinking'} results={props.results}/>

				<h3>{i18n.t('what-are-analytical-skills')}</h3>
				<ReactMarkdown>{i18n.t('analytical-skills-describe-your-ability-to-collect-and-analyze-information-draw-insightful-conclusions-from-them-and-use-the-latter-to-facilitate-the-decision-making-process')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('here-are-some-examples-which-involve-having-analytical-skills')}</ReactMarkdown>
				
				<ul>
					<li>{i18n.t('brainstorming')}</li>
					<li>{i18n.t('finding-patterns')}</li>
					<li>{i18n.t('interpretating-data')}</li>
					<li>{i18n.t('integrating-new-information-into-existing-knowledge-and-practices')}</li>
					<li>{i18n.t('evaluating-alternatives')}</li>
					<li>{i18n.t('reporting-your-findings-to-make-a-change-happen')}</li>
				</ul>

				<h3>{i18n.t('feedback-on-your-3-analytical-skills-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'887fb4f0-74ef-491a-8abb-b3686d04de9b'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('tackling-one-gigantic-problem-may-sometimes-feel-overwhelming-if-not-impossible-by-breaking-down-said-problem-into-smaller-parts-you-make-the-problem-solving-process-easier')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('why-because-you-can-more-easily-identify-their-root-causes-and-therefore-generate-and-evaluate-alternative-ways-of-solving-these-components')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('moreover-not-all-components-are-born-equal-some-deliver-a-lot-of-benefits-by-solving-them-while-others-dont-your-ability-to-distinguish-the-critical-few-from-the-trivial-many-enables-you-to-focus-the-smallest-amount-of-energy-while-generating-the-biggest-benefits-from-your-efforts-this-is-known-as-the-pareto-principle-or-the-80-20-rule-where-20-of-your-efforts-bring-80-of-the-benefits')}</ReactMarkdown>

				<div className="myimghor">
					<img src={paretoprincipleimg} alt="The Pareto Principle: 20% of productive efforts get 80% of the results" />
				</div>

				<div className="imgcaption">
					{i18n.t('the-pareto-principle')}
				</div>

				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'f937b297-349e-4ce0-897a-f00bf60f9105'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('connecting-the-dots-between-what-first-appear-to-be-unrelated-issues-is-a-great-way-to-solve-a-problem-and-prevent-it-from-reoccurring-indeed-when-you-find-a-correlation-between-a-cause-and-its-consequences-you-will-likely-be-better-able-at-taking-preventive-and-corrective-actions')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('this-also-allows-you-to-anticipate-obstacles-where-your-actions-today-may-have-problematic-consequences-tomorrow-next-week-next-month-or-next-year-by-assessing-risks-you-increase-the-likelihood-of-progressing-while-minimizing-the-potential-damages')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('finally-considering-different-approaches-prevents-you-from-getting-stuck-in-a-binary-approach-where-your-only-alternatives-are-doing-a-versus-not-doing-a-evaluating-different-alternatives-will-enable-you-to-test-what-seems-like-the-best-approach-evaluate-its-benefits-and-drawbacks-and-decide-whether-you-should-try-another-approach-or-not')}</ReactMarkdown>

				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'cdc02e6c-ace4-4361-9692-b3c7b51a3c0e'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('if-people-dont-understand-what-you-say-they-will-not-trust-your-advice-regardless-of-how-important-your-findings-are-if-no-one-can-understand-what-they-mean-and-how-they-may-impact-their-worlds-and-their-work-they-are-unlikely-going-to-act-in-a-way-which-will-be-beneficial-for-them-or-you')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('to-make-a-change-happen-people-need-to-be-able-to-understand-why-your-insights-matter-what-they-mean-and-how-they-can-benefit-from-changing-their-behavior')}</ReactMarkdown>
				
				<h3>{i18n.t('how-you-can-grow-your-analytical-skills')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-6" className="offset-anchor">
				<div className="myimghor">
					<img src={communicationimg} alt="People talking to each other" />
				</div>

				<h2>5. {i18n.t('your-communication-skills')}</h2>

				<ReportPBar results={props.results} category={'Communication'} num={5}/>

				<SkillResult category={'Communication'} results={props.results}/>
				<h3>{i18n.t('what-are-communication-skills')}</h3>
				
				<div className="myimghor">
					<img src={i18nquoteindeedcommunication()} alt="Quote by Indeed: Communication skills allow you to understand and be understood by others." />
				</div>

				
				<ReactMarkdown>{i18n.t('communication-is-a-two-way-process-which-involves-sending-and-receiving-information-whether-you-are-sending-or-receiving-information-your-goal-is-to-maximize-understanding-ask-clarifying-questions-and-make-sure-you-both-have-the-same-understanding-of-the-situation')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('communication-skills-involve')}</ReactMarkdown>

				<ul>
					<li>{i18n.t('listening')}</li>
					<li>{i18n.t('speaking')}</li>
					<li>{i18n.t('reading')}</li>
					<li>{i18n.t('writing')}</li>
					<li>{i18n.t('observing')}</li>
					<li>{i18n.t('empathizing')}</li>
					<li>{i18n.t('understanding')}</li>
				</ul>

				<ReactMarkdown>{i18n.t('by-helping-you-understand-and-be-understood-by-others-communication-will-help-you-to-nurture-your-personal-and-professional-relationships-this-the-4th-element-of-emotional-intelligence-relationships-development')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('if-people-dont-understand-what-you-need-from-them-why-they-should-care-or-how-they-should-proceed-they-will-not-be-willing-or-able-to-help-you-this-may-lead-to-unnecessary-conflicts-caused-by-simple-misunderstandings-and-lack-of-clarity')}</ReactMarkdown>
 

				<div className="myimghor">
					<img src={i18nquotewerbercommunication()} alt="Quote by Bernard Werber" />
				</div>


				<h3>{i18n.t('feedback-on-your-3-communication-skills-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'abcf1f5f-f928-442d-99f0-aaa301b9977f'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('because-good-communication-leads-to-desired-change-if-people-cannot-follow-you-what-you-are-saying-they-are-unlikely-to-do-what-you-expect-them-to-do-you-are-fully-responsible-for-the-other-persons-understanding-of-what-you-are-communicating')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('always-ask-yourself-what-do-you-most-want-them-to-understand-in-other-terms-what-is-your-core-message-providing-too-much-information-is-almost-guaranteed-to-lose-your-audience-except-if-they-have-high-levels-of-conscientiousness-rather-you-should-stick-to-the-most-important-information-you-want-to-provide-and-be-available-to-answer-clarifying-questions-if-necessary')}</ReactMarkdown>

				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'3ff889da-9223-47e4-a171-c85003228c3b'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('because-people-need-to-feel-heard-and-like-they-belong-to-bring-their-best-contributions-to-the-team-when-people-cannot-openly-speak-their-hearts-and-their-minds-they-will-not-be-able-to-realize-their-potential')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('during-many-conversations-we-are-not-truly-listening-to-what-the-other-person-has-to-say-seeking-to-understand-their-perspective-rather-we-are-waiting-for-them-to-finish-so-we-can-say-our-piece')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('however-when-people-feel-they-are-being-heard-which-is-rare-they-will-feel-more-closely-connected-to-you-which-will-raise-their-trust-and-engagement-levels')}</ReactMarkdown>
				
				<div className="myimghor">
					<img src={i18nquotecoveylisten()} alt="Quote by Stephen R. Covey: Most people do not listen with the intent to understand; they listen with the intent to reply." />
				</div>

				<div className="myimghor">
					<img src={i18nquotebuddhamouth()} alt="Quote by Buddha: If your mouth is open, you are not learning." />
				</div>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>
				<IndividualQuestionFeedback number={3} questionId={'4c4fcb2d-cdb7-42df-8b1b-91242fb6ad82'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('your-body-language-and-tone-of-voice-may-make-or-break-your-message-they-can-help-to-build-trust-put-people-at-ease-just-as-much-as-they-can-confuse-or-upset-people-therefore-hurting-their-trust-and-your-relationship')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('here-is-an-example-if-you-say-yes-while-shaking-your-head-no-your-confused-audience-will-have-to-decide-between-trusting-your-words-or-your-behavior-they-will-likely-choose-to-trust-your-body-language-as-nonverbal-communication-is-more-automatic-and-harder-to-fake')}</ReactMarkdown>

				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-grow-your-communication-skills')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>
			

			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-7" className="offset-anchor">
				<div className="myimghor">
					<img src={leadershipimg} alt="People talking to each other" />
				</div>

				<h2>6. {i18n.t('your-leadership-skills')}</h2>

				<ReportPBar results={props.results} category={'Leadership'} num={6}/>

				<SkillResult category={'Leadership'} results={props.results}/>

				<h3>{i18n.t('what-are-leadership-skills')}</h3>
				
				<ReactMarkdown>{i18n.t('leadership-skills-allow-your-people-to-do-their-best-and-to-grow-when-people-feel-like-you-have-their-interests-at-heart-they-will-truly-want-to-work-with-you-being-a-great-leader-means-developing-the-leaders-of-tomorrow-rather-than-seeking-to-be-a-ruler')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('your-guidance-will-help-your-people-to-focus-on-what-matters-to-their-contributions-to-their-teams-and-organizations-missions')}</ReactMarkdown>
				

				<h3>{i18n.t('feedback-on-your-3-leadership-skills-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'8cdc6af2-1c05-467f-8378-8b51614b5f5a'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('we-all-face-challenges-and-setbacks-sometimes-all-we-need-to-keep-going-is-a-little-encouragement-from-someone-we-admire-hearing-someone-saying-you-can-do-it-i-believe-in-you-can-make-all-the-difference-when-we-feel-drained-by-the-challenges-we-face-the-right-words-can-revitalize-us')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('when-we-struggle-or-when-we-are-stuck-we-often-fear-other-peoples-judgement-therefore-receiving-encouragements-delivers-us-hope-which-gives-us-the-strength-to-get-back-up-and-try-again')}</ReactMarkdown>

				<ReactMarkdown>{i18n.t('the-most-admired-leaders-are-both-demanding-and-supportive')}</ReactMarkdown>
				<ul>
					<li>{i18n.t('their-high-standards-help-people-rise-to-the-occasion-and-accomplish-things-they-would-not-have-believed-to-be-capable-of')}</li>
					<li>{i18n.t('their-encouraging-words-refuel-their-peoples-temporary-loss-of-self-esteem-and-act-as-powerful-motivators')}</li>
				</ul>

				<ReactMarkdown>{i18n.t('meanwhile-the-absence-of-one-or-the-other-is-a-recipe-for-disaster')}</ReactMarkdown>
				<ul>
					<li>{i18n.t('when-you-are-not-demanding-enough-people-may-not-dare-try-which-sabotages-their-progress-or-reduces-their-potential')}</li>
					<li>{i18n.t('when-you-are-not-supportive-enough-people-feel-like-they-are-not-enough-and-that-what-they-do-is-never-good-enough-which-is-highly-demotivating')}</li>
				</ul>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'d4a7f918-8503-4046-b78d-7d596620a401'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('difficult-conversations-are-inevitable-when-someone-makes-a-mistake-negative-emotions-run-high-as-a-result-many-people-tend-to-avoid-talking-about-their-mistakes-but-thats-not-the-right-answer-rather-we-need-to-learn-how-to-have-difficult-conversations-in-a-way-which-creates-less-pain-for-you-and-for-the-person-youre-talking-to-for-the-sake-of-the-relationship')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('when-you-can-speak-up-therefore-being-vulnerable-and-see-that-the-other-person-tries-to-understand-and-help-you-rather-than-admonish-you-trust-is-built-consideration-and-compassion-will-go-a-long-way')}</ReactMarkdown>
			
	
				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'6e32174f-a851-494b-ba1c-fd72abd0fd9e'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('the-lessons-we-learn-ourselves-are-the-ones-which-have-the-greatest-impact-on-our-future-behaviors-when-you-jump-straight-to-telling-people-what-they-should-do-you-rob-them-from-a-teachable-moment')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('this-socratic-inquiry-allows-people-to-take-an-active-role-in-their-development-rather-than-being-passive-students-which-do-as-they-are-told-without-thinking-critically')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('you-may-be-surprised-by-the-ideas-your-people-can-imagine-when-you-give-them-a-space-to-reflect-and-to-learn-their-ideas-may-complement-or-surpass-yours')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('finally-people-feel-more-engaged-with-a-solution-they-have-actively-participated-in-creating-they-will-be-more-intrinsically-motivating-in-making-the-change-happen')}</ReactMarkdown>


				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-grow-your-leadership-skills')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>
			

			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-8" className="offset-anchor">
				<div className="myimghor">
					<img src={raisedhandsimg} alt="People talking to each other" />
				</div>

				<h2>7. {i18n.t('your-initiative-skills')}</h2>

				<ReportPBar results={props.results} category={'Initiative'} num={7}/>
				<SkillResult category={'Initiative'} results={props.results}/>

				<h3>{i18n.t('what-are-initiative-skills')}</h3>
				
				<ReactMarkdown>{i18n.t('initiative-describes-your-ability-to-speak-up-when-something-is-wrong-or-when-you-find-new-and-hopefully-better-ways-of-doing-things')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('people-and-organizations-need-to-both-explore-new-opportunities-as-well-as-exploit-what-works-well-today-what-got-you-here-will-not-get-you-there')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('taking-charge-means-trusting-yourself-and-asking-questions-reading-and-listening-to-make-the-best-decision-you-can-for-your-situation')}</ReactMarkdown>

				<div className="myimghor">
					<img src={i18nquoterooseveltrightthing()} alt="Quote by Theodore Roosevelt: In any moment of decision, the best thing you can do is the right thing, the next best thing is the wrong thing, and the worst thing you can do is nothing." />
				</div>


				<div className="separator-large" />

				<h3>{i18n.t('feedback-on-your-3-initiative-skills-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'deda25f2-7376-4b1b-880a-2e1bcec51644'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('high-resistance-to-change-can-stifle-innovation-and-the-progress-of-an-organization-many-people-are-reluctant-to-support-change-initiatives-because-they-are-worried-that-the-proposed-solution-is-missing-a-critical-information-that-can-make-it-or-break-it')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('therefore-it-is-important-to-make-your-voice-be-heard-and-make-sure-that-the-decision-makers-have-sufficient-information-to-decide-on-the-optimal-path-to-take')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'4c1c62a7-c5a1-4f17-bdeb-89ed0f76acc3'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('sometimes-its-better-to-ask-for-forgiveness-than-for-permission-taking-calculated-risks-and-testing-new-ideas-is-how-people-and-organizations-learn-and-grow-making-mistakes-is-human-and-therefore-inevitable-what-matters-is-to-learn-from-our-mistakes-and-to-make-sure-others-can-learn-from-them-as-well')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('when-people-see-that-others-get-blamed-shamed-and-reprimanded-for-their-initiative-efforts-they-are-less-likely-to-dare-trying-themselves-as-a-result-the-progress-and-learning-from-the-entire-company-will-suffer')}</ReactMarkdown>

	
				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>
				<IndividualQuestionFeedback number={3} questionId={'c7043990-4c07-488f-b405-954abf20e7af'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('we-can-all-stand-on-the-shoulders-of-giants-by-learning-from-what-others-before-us-have-built-and-by-improving-on-their-solutions-our-predecessors-may-have-created-the-best-solution-they-could-conceive-based-on-the-knowledge-and-technology-that-were-available-back-then-this-does-not-mean-their-solution-is-set-in-stone-and-cannot-be-improved-upon')}</ReactMarkdown>
				

				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-grow-your-initiative-skills')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>
			

			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-9" className="offset-anchor">
				<div className="myimghor">
					<img src={fingerprintsimg} alt="People talking to each other" />
				</div>

				<h2>8. {i18n.t('your-thoroughness-skills')}</h2>

				<ReportPBar results={props.results} category={'Thoroughness'} num={8}/>
				<SkillResult category={'Thoroughness'} results={props.results}/>

				<h3>{i18n.t('what-are-thoroughness-skills')}</h3>
				
				<ReactMarkdown>{i18n.t('thoroughness-describes-your-ability-to-think-through-the-details-with-a-critical-eye-and-to-focus-on-what-really-matters-for-example-you-provide-accurate-information-in-a-useable-form-and-on-a-timely-basis-to-others-who-need-to-act-on-it')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('thorough-people-tend-to')}</ReactMarkdown>
				
				<ul>
					<li>{i18n.t('maintain-a-checklist')}</li>
					<li>{i18n.t('schedule-their-tasks-to-make-sure-they-dont-forget-about-them')}</li>
					<li>{i18n.t('follow-policies-and-procedures')}</li>
					<li>{i18n.t('write-down-details-to-make-sure-they-are-not-lost-or-forgotten')}</li>
					<li>{i18n.t('do-work-which-requires-less-checking')}</li>
				</ul>


				<div className="separator-large" />

				<h3>{i18n.t('feedback-on-your-3-thoroughness-skills-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'79b95808-4ad0-48f3-9920-3fad46301952'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('paying-attention-to-details-allows-you-to-notice-mistakes-others-would-not-catch-this-helps-you-to-improve-the-productivity-efficiency-and-performance-of-your-teams-and-organizations-your-colleagues-will-appreciate-your-ability-to-prevent-mistakes-and-will-likely-perceive-you-as-being-highly-reliable')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'d42bfa7f-ef1c-4867-90e9-0c3616df21d3'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('uncertainty-and-lack-of-clarity-are-major-causes-of-delays-frustrations-and-unnecessary-conflicts-your-ability-to-know-what-you-need-to-tackle-how-and-when-to-do-it-allow-you-to-be-a-high-performer')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('being-able-to-clarify-your-priorities-with-your-leaders-and-teammates-also-allow-you-to-make-sure-you-are-focusing-your-time-and-energy-on-what-matters-most-to-your-teams-and-organization')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'99d45a46-c4f2-4e2b-b440-7607369d29e3'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('handing-your-work-on-time-and-with-high-standards-of-quality-are-clear-signals-that-you-are-a-reliable-person-reliability-matters-because-its-one-of-the-three-elements-which-build-trust-between-people')}</ReactMarkdown>
				
				<section className="course-tables-center mt-4">
					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={likabilityexpertisereliability} variant="top" className="mb-3" />
						<Card.Title className="m-3">{i18n.t('what-are-the-3-elements-of-trust')}<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/elements-of-trust" className="stretched-link"></a>
					</Card>
				</section>
				

				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-grow-your-thoroughness-skills')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-10" className="offset-anchor">
				<div className="myimghor">
					<img src={expertiseimg} alt="People talking to each other" />
				</div>

				<h2>9. {i18n.t('your-expertise')}</h2>

				<ReportPBar results={props.results} category={'Expertise'} num={9}/>
				<SkillResult category={'Expertise'} results={props.results}/>

				<h3>{i18n.t('what-is-expertise')}</h3>
				
				<ReactMarkdown>{i18n.t('your-expertise-describes-your-ability-to-acquire-and-effectively-use-your-technical-knowledge-skills-and-judgement-to-accomplish-results-expertise-matters-because-its-one-of-the-3-elements-of-trust')}</ReactMarkdown>

				<section className="course-tables-center mt-4">
					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={likabilityexpertisereliability} variant="top" className="mb-3" />
						<Card.Title className="m-3">{i18n.t('what-are-the-3-elements-of-trust')}<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/elements-of-trust" className="stretched-link"></a>
					</Card>
				</section>

				<div className="separator-large" />

				<h3>{i18n.t('feedback-on-your-3-expertise-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'8c70aadb-e979-4780-8261-03f372bb7283'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('achieving-results-requires-that-people-do-everything-they-can-to-learn-and-deliver-value-at-the-same-time-under-the-constant-risk-of-failure-as-alaric-bourgoin-and-jean-francois-harvey-described-in-harvard-business-review')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('for-instance-you-may-select-relevant-pieces-of-information-and-share-them-with-your-people-to-guide-their-work-another-way-is-to-learning-from-past-assignments-and-sharing-the-lessons-youve-learned-to-help-everyones-learning-efforts')}</ReactMarkdown>
				

				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'af33084e-506f-4e71-9675-218e65198ebc'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('making-yourself-available-to-others-to-solve-problems-is-key-to-building-rapport-and-trust-within-the-team-for-instance-they-may-recognize-and-appreciate-your-tendency-to-keep-up-to-date-with-the-latest-and-greatest-theories-and-practices-in-your-field')}</ReactMarkdown>
				

				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'7ceb6063-584b-4bb4-b201-b80cfed37ae2'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('you-are-likely-going-to-be-asked-to-contribute-to-projects-as-a-subject-matter-expert-where-people-will-have-to-trust-your-recommendations-and-act-upon-them-however-if-they-see-others-strongly-question-and-challenge-your-knowledge-they-will-be-less-likely-to-follow-you-and-will-seek-for-approvals-by-your-manager-or-other-experts-before-moving-on')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('you-dont-need-to-know-everything-right-now-and-you-also-should-not-pretend-you-do-when-you-dont-know-the-answer-to-a-difficult-question-admit-it-promise-you-will-look-into-it-and-walk-the-talk-during-your-next-conversation-on-the-topic-your-research-and-preparation-will-allow-you-to-answer-said-question-and-the-team-will-be-able-to-move-on-to-the-next-step-of-the-project')}</ReactMarkdown>
				

				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-grow-your-expertise-skills')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-11" className="offset-anchor">
				<div className="myimgvert">
					<img src={growthimg} alt="People talking to each other" />
				</div>

				<h2>10. {i18n.t('your-growth-mindset')}</h2>

				<ReportPBar results={props.results} category={'Growth Mindset'} num={10}/>
				<SkillResult category={'Growth Mindset'} results={props.results}/>

				<h3>{i18n.t('what-is-a-growth-mindset')}</h3>
				
				<ReactMarkdown>{i18n.t('a-growth-mindset-describes-how-you-approach-setbacks-and-learning')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('having-a-growth-mindset-means')}</ReactMarkdown>
				
				<ul>
					<li>{i18n.t('believe-that-they-can-grow-their-abilities-through-practice-and-efforts')}</li>
					<li>{i18n.t('persisting-in-the-face-of-setbacks')}</li>
					<li>{i18n.t('understanding-failure-as-an-opportunity-to-grow')}</li>
					<li>{i18n.t('prioritizing-learning-over-seeking-approval')}</li>
					<li>{i18n.t('confronting-challenges-to-learn')}</li>
					<li>{i18n.t('focusing-on-the-process-instead-of-the-end-result')}</li>
				</ul>

				<ReactMarkdown>{i18n.t('in-contrast-having-a-fixed-mindset-means')}</ReactMarkdown>

				<ul>
					<li>{i18n.t('believing-that-your-abilities-are-set-in-stone-and-cannot-be-developed')}</li>
					<li>{i18n.t('giving-up-in-the-face-of-setbacks')}</li>
					<li>{i18n.t('understanding-failure-as-the-limit-of-your-ability')}</li>
					<li>{i18n.t('seeking-approval-over-learning')}</li>
					<li>{i18n.t('avoiding-challenges')}</li>
					<li>{i18n.t('focusing-on-proving-your-self-worth')}</li>
				</ul>

				<div className="myimghor">
					<img src={i18nquotedweckpotential()} alt="Quote by Carol S. Dweck: A person’s true potential is unknown and unknowable" />
				</div>

				<div className="myimghor">
					<img src={i18nquotefordmindset()} alt="Quote by Henry Ford: Whether you think you can or think you cannot, you are right" />
				</div>


				<div className="separator-large" />

				<h3>{i18n.t('feedback-on-your-3-growth-mindset-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'096a7e5e-5e3f-4462-94e3-55c463ea8233'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('behind-each-mistake-lies-a-teachable-moment-although-making-and-admitting-your-mistakes-is-painful-they-can-also-become-your-best-teachers')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('learning-from-your-mistakes-means-searching-for-ways-to')}</ReactMarkdown>
				
				<ul>
					<li>{i18n.t('preventing-them-from-happening-again')}</li>
					<li>{i18n.t('correcting-them-quickly-and-effectively-should-they-still-happen-again')}</li>
					<li>{i18n.t('sharing-the-lesson-you-have-learned-with-others-to-contribute-to-your-teams-and-organizations-learning')}</li>
				</ul>
				
				<div className="myimghor">
					<img src={i18nquotewilliamslearning()} alt="Quote by Roy H. Williams: A smart man makes a mistake, learns from it, and never makes that mistake again." />
				</div>

				<div className="myimghor">
					<img src={i18nquotewenzelmistakes()} alt="Quote by Thomas J. Wenzel: Mistakes can promote learning and be the basis of a deeper level of understanding." />
				</div>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'19913602-ba56-40cf-b367-8e1ab558b36e'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>

				<div className="myimghor">
					<img src={i18nquotesenecamistakes()} alt="Quote by Seneca: Errare humanum est." />
				</div>

				
				<ReactMarkdown>{i18n.t('in-the-words-of-seneca-making-mistakes-is-human-we-are-imperfect-fallible-beings-and-we-will-make-wrong-decisions-from-time-to-time')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('even-when-the-stakes-are-high-such-as-when-developing-a-new-drug-or-medical-device-making-mistakes-is-acceptable-what-is-unacceptable-is-not-to-learn-from-it-i-once-heard-a-senior-leader-say-that-making-mistakes-was-unacceptable-which-made-many-people-uncomfortable-why-because-they-said-they-were-now-afraid-they-will-be-scolded-for-every-single-mistake-they-would-make-no-matter-how-big-or-small-since-one-of-the-most-senior-leaders-said-so-make-sure-you-do-not-do-the-same-mistake-he-did')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('why-because-your-people-will-avoid-situations-in-which-they-might-make-mistakes-for-fear-of-being-reprimanded-this-will-rob-them-from-the-experiences-from-which-they-are-most-likely-to-learn')}</ReactMarkdown>

				<div className="myimghor">
					<img src={i18nquotewenzelfearfailure()} alt="Quote by Thomas J. Wenzel: Fear of public failure is a powerful disincentive." />
				</div>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'efcf9388-cd39-4e1c-a2b5-ae979b6a378a'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('brushing-off-your-mistakes-without-reflecting-from-them-will-affect-your-long-term-learning-and-development-as-a-result-you-may-achieve-less-in-your-career-than-you-would-wish')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('the-next-time-you-face-a-setback-ask-yourself')}</ReactMarkdown>

				<ul>
					<li>{i18n.t('what-was-your-intention')}</li>
					<li>{i18n.t('what-went-wrong')}</li>
					<li>{i18n.t('why-did-it-go-wrong')}</li>
					<li>{i18n.t('when-did-it-go-wrong')}</li>
					<li>{i18n.t('what-were-the-consequences')}</li>
					<li>{i18n.t('how-can-you-prevent-it-from-happening-again')}</li>
					<li>{i18n.t('how-can-you-correct-it-should-it-happen-again')}</li>
				</ul>


				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-grow-your-growth-mindset')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-12" className="offset-anchor">
				<div className="myimghor">
					<img src={climbingimg} alt="People talking to each other" />
				</div>

				<h2>11. {i18n.t('your-drive-for-results')}</h2>

				<ReportPBar results={props.results} category={'Drive for results'} num={11}/>
				<SkillResult category={'Drive for results'} results={props.results}/>

				<h3>{i18n.t('what-is-a-drive-for-results')}</h3>
				
				<ReactMarkdown>{i18n.t('drive-for-results-describes-your-desire-to-make-your-mark-which-is-demonstrated-by-your-search-for-new-challenges-and-opportunities-people-with-a-high-drive-for-results-prioritize-action-above-everything-else-seeking-to-achieve-their-goals-quickly-and-forcefully-if-necessary')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('they-also-prefer-fast-paced-environments-which-means-they-appreciate-people-who-get-to-the-point-quickly-and-efficiently-nothing-is-more-despicable-to-them-than-someone-wasting-their-precious-time')}</ReactMarkdown>


				<div className="separator-large" />


				<h3>{i18n.t('feedback-on-your-3-drive-for-results-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'5428308d-080a-4a2f-8a7c-0b468643a93a'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('todays-workplace-is-filled-with-distractions-which-prevents-many-people-from-finishing-what-they-start-as-a-result-your-ability-to-see-your-tasks-to-completion-makes-you-a-valuable-contributor')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('while-others-may-become-bored-with-their-assignments-and-go-above-and-beyond-to-be-assigned-to-more-interesting-tasks-people-with-a-drive-for-results-stick-with-their-challenges-until-they-successfully-overcome-them-because-of-their-determination-and-persistence-they-tend-to-be-viewed-as-more-reliable-than-others')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'bf38df98-a9fa-4eea-937e-286c40e72834'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('setting-goals-is-key-to-achieving-results-however-setting-unrealistic-or-impossible-goals-kills-other-peoples-motivation-and-engagement-once-they-start-believing-that-they-are-fighting-a-losing-battle-they-may-stop-trying-or-give-up-altogether')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('in-contrast-when-they-believe-they-are-making-progress-towards-realistic-and-achievable-objectives-peoples-motivation-and-engagement-soar-one-way-to-do-this-is-to-break-down-your-big-objectives-into-smaller-milestones-that-pave-the-way-to-the-finish-line-by-being-able-to-look-back-at-what-theyve-already-achieved-and-seeing-how-much-still-needs-to-be-done-people-are-more-likely-to-stay-the-course')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'d57f542f-179d-4bc5-9b0d-e2ab72547a10'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('when-you-demonstrate-your-high-standard-of-performance-to-others-you-act-as-a-role-model-and-may-inspire-them-to-do-the-same')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('in-contrast-working-for-someone-who-expects-too-much-from-others-yet-who-produces-sloppy-work-themselves-is-highly-demotivating-we-naturally-dislike-hypocrites-and-unfair-people')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('when-you-set-demanding-goals-to-others-you-give-them-the-opportunity-to-rise-to-the-occasion-people-are-often-surprised-to-see-what-they-can-achieve-when-they-are-encouraged-and-supported-by-a-caring-leader-this-is-the-secret-to-great-leadership-being-both-demanding-and-supportive')}</ReactMarkdown>


				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-grow-your-drive-for-results')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-13" className="offset-anchor">
				<div className="myimghor">
					<img src={batteryimg} alt="People talking to each other" />
				</div>

				<h2>12. {i18n.t('your-energy-management-skills')}</h2>

				<ReportPBar results={props.results} category={'Energy management'} num={12}/>

				<SkillResult category={'Energy management'} results={props.results}/>

				<h3>{i18n.t('what-is-energy-management')}</h3>
				
				<ReactMarkdown>{i18n.t('while-time-management-defines-what-you-will-do-when-you-will-do-it-and-for-how-long-energy-management-describes-the-quality-of-the-work-you-will-do')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('energy-management-can-be-broken-down-into-4-dimensions')}</ReactMarkdown>

				<ol>
					<li>{i18n.t('taking-care-of-your-health')}</li>
					<li>{i18n.t('soothing-your-emotions-to-avoid-destructive-behaviors')}</li>
					<li>{i18n.t('sharpening-your-focus-on-the-task-at-hand')}</li>
					<li>{i18n.t('doing-meaningful-work-which-gives-purpose-to-your-life')}</li>
				</ol>
				

				<div className="separator-large" />


				<h3>{i18n.t('feedback-on-your-3-energy-management-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'799b7508-2f83-44f8-b6e0-a4198005feb4'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('there-is-only-so-much-time-and-energy-available-per-day-if-you-try-to-tackle-too-many-things-in-a-short-period-of-time-you-will-exhaust-your-energy-levels-once-the-latter-hits-their-limit-the-quality-of-your-work-and-of-your-relationships-will-tank')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('such-behaviors-will-have-lasting-damages-over-time')}</ReactMarkdown>

				<ul>
					<li>{i18n.t('your-health-will-suffer')}</li>
					<li>{i18n.t('you-will-be-prone-to-more-negative-emotions-and-emotional-outbursts')}</li>
					<li>{i18n.t('you-will-lose-the-ability-to-focus-on-your-work')}</li>
					<li>{i18n.t('you-will-feel-like-you-are-stuck-in-a-life-devoid-of-meaning')}</li>
				</ul>
				
				<ReactMarkdown>{i18n.t('this-can-result-in-burnout-which-can-be-grueling-to-recover-from')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'61034909-df99-448a-acc5-53f66b93e85e'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('people-are-not-cold-efficient-and-unbreakable-machines-we-are-social-beings-who-need-to-feel-safe-and-like-others-care-about-our-well-being-when-we-do-we-start-building-trust-with-each-other')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('some-people-are-workaholics-who-have-difficulties-noticing-the-damages-that-overwork-starts-to-have-on-their-health-relationships-and-lives-they-need-someone-to-tell-them-when-they-have-done-enough-and-deserve-a-break-to-recover-their-energy-levels-otherwise-they-risk-burning-out-which-will-take-away-a-valuable-member-of-the-team-for-reasons-you-could-have-prevented-from-happening')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'87fb48ce-50c2-44fe-bbf8-1d33be5707ff'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('staying-mentally-focused-and-emotionally-sane-is-essential-to-doing-your-best-work-when-you-are-distracted-worried-angry-depressed-or-ashamed-of-something-you-have-said-or-done-the-quality-of-your-efforts-will-suffer-as-well')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('todays-pace-of-work-is-exhaustingly-fast-which-means-you-have-to-work-hard-to-stay-focused-against-the-plethora-of-distractions-as-well-as-emotionally-sane-against-all-the-threats-in-your-environment')}</ReactMarkdown>

				
				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-grow-your-energy-management')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-14" className="offset-anchor">
				<div className="myimghor">
					<img src={feedbackimg} alt="People talking to each other" />
				</div>

				<h2>13. {i18n.t('your-openness-to-feedback')}</h2>

				<ReportPBar results={props.results} category={'Openness to feedback'} num={13}/>
				<SkillResult category={'Openness to feedback'} results={props.results}/>

				<h3>{i18n.t('what-is-openness-to-feedback')}</h3>
				
				<ReactMarkdown>{i18n.t('openness-to-feedback-describes-your-ability-and-willingness-to-ask-for-feedback-from-a-broad-range-of-sources-to-consider-the-consequences-of-the-information-you-receive-and-to-act-on-it-what-you-do-about-this-new-information-is-what-truly-matters')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('the-more-open-you-are-to-feedback-the-more-coachable-you-are-and-therefore-the-more-you-can-grow-being-truly-and-courageously-open-is-what-makes-us-human-and-therefore-credible-in-the-eyes-of-others')}</ReactMarkdown>


				<div className="separator-large" />


				<h3>{i18n.t('feedback-on-your-3-openness-to-feedback-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'fb41440b-8a0c-4636-8efd-316a787903d1'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('ideas-need-feedback-without-feedback-ideas-will-often-stay-unused-or-forgotten-thanks-to-their-previous-experiences-and-own-ideas-others-may-build-on-yours-different-perspectives-can-lead-to-better-outcomes-for-instance-they-can-imagine-obstacles-you-did-not-consider-or-more-efficient-ways-to-achieve-your-goals')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('when-others-know-you-think-their-opinion-matters-they-will-likely-trust-you-when-you-include-them-they-will-feel-like-they-belong-which-will-strengthen-your-relationship')}</ReactMarkdown>

				<div className="myimghor">
					<img src={i18nquoteschwartzfeelvalued()} alt="Quote by Tony Schwartz: Our core emotional need is to feel valued and valuable. When we don’t, it’s deeply unsettling, a challenge to our equilibrium, security, and well-being. At the most primal level, it can feel like a threat to our survival." />
				</div>			


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'dbc624f0-914a-48f6-aa58-89a6849e06e4'} feedbackByQuestion={props.results.feedbackByQuestion} />
				
				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('working-with-people-who-are-completely-closed-to-suggestions-is-painful-and-demotivating-remember-insecurity-is-usually-what-prompts-bad-behaviors')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('in-psychology-illusory-superiority-describes-our-tendency-to-overestimate-our-strengths-and-to-overlook-our-faults-our-personal-hubris-can-sometimes-lead-to-the-fall-of-our-enterprises-therefore-hurting-many-other-people-know-it-alls-are-perceived-as-arrogant-and-are-therefore-kept-out-of-the-loop')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('al-pittampalli-author-of-persuadable-remarked-that-some-of-the-greatest-leaders-demonstrated-a-willingness-to-consider-emerging-evidence-and-changed-their-behaviors-accordingly-they-listened-attentively-to-new-information-and-adjusted-their-predictions-accordingly')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('when-the-boss-comes-in-the-room-and-says-here-is-what-i-think-we-should-do-what-do-you-think-she-is-killing-any-possibility-of-gathering-constructive-feedback-all-she-is-likely-to-receive-is-approval-although-people-may-think-differently-without-daring-to-speak-up')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('ask-what-people-think-first-listen-to-their-ideas-clarify-any-misunderstandings-compare-with-them-your-own-intuitions-and-see-how-you-can-imagine-a-better-solution-together')}</ReactMarkdown>


				<div className="myimghor">
					<img src={i18nquotemandelalastspeak()} alt="Quote by Nelson Mandela: Be the last person to speak." />
				</div>

				<div className="myimghor">
					<img src={i18nquotedunningselfinsight()} alt="Quote by David Dunning: The road to self-insight runs through other people." />
				</div>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'7328e351-8503-4dfe-91f2-87fa477e1fb3'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('becoming-more-self-aware-about-your-strengths-weaknesses-opportunities-and-threats-to-your-career-is-only-the-first-step-self-development-requires-that-you-take-action-on-this-acquired-knowledge-and-actively-practice-on-the-areas-you-want-to-improve-about-yourself')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('creating-a-development-plan-takes-some-time-upfront-but-it-wields-great-results-over-time-because-it-allows-you-to-focus-your-time-and-energy-on-doing-what-truly-matters-and-forgetting-about-the-trivial-activities-which-do-not-support-you-your-team-or-your-organization')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('one-of-the-best-ways-to-make-sure-a-new-behavior-becomes-a-habit-is-repeating-it-on-a-daily-basis-for-instance-you-may-take-15-minutes-at-the-end-of-your-day-to-reflect-on-what-youve-done-what-youve-learned-and-how-you-could-improve-the-way-you-do-things-tomorrow')}</ReactMarkdown>
				

				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-grow-your-openness-to-feedback')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-15" className="offset-anchor">
				<div className="myimghor">
					<img src={ovaluesimg} alt="People talking to each other" />
				</div>

				<h2>14. {i18n.t('your-purpose-orientation')}</h2>
				<ReportPBar results={props.results} category={'Purpose'} num={14}/>
				<SkillResult category={'Purpose'} results={props.results}/>

				<h3>{i18n.t('what-is-purpose-orientation')}</h3>
				
				<ReactMarkdown>{i18n.t('having-a-purpose-in-life-and-feeling-like-you-are-realizing-your-potential-has-been-identified-by-abraham-maslow-as-the-greatest-source-of-motivation-its-what-drives-you-to-do-your-best-every-day-to-wake-up-with-a-desire-to-make-a-difference-and-to-go-to-bed-with-the-satisfaction-of-what-you-have-achieved-that-day')}</ReactMarkdown>

				<div className="myimghor">
					<img src={maslowpyramid1img} alt="People talking to each other" />
				</div>

				<div className="imgcaption">
					{i18n.t('maslows-hierarchy-of-needs')}
				</div>
				

				<div className="separator-large" />


				<h3>{i18n.t('feedback-on-your-3-purpose-orientation-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'3f9d268a-d533-4eb5-ae25-9ac23fc5ca80'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<div className="notabene">
					<ReactMarkdown>{i18n.t('having-a-purpose-is-a-key-motivator-in-life-because-it-gives-meaning-to-your-life-and-your-efforts')}</ReactMarkdown>
				</div>

				<ReactMarkdown>{i18n.t('why-do-you-want-to-work-for-company-a-one-reason-may-be-because-of-what-it-tries-to-achieve-how-it-tries-to-make-a-difference-in-the-world-in-other-words-because-of-its-mission')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('this-attraction-to-a-companys-mission-and-intentions-is-usually-what-draws-us-to-apply-for-certain-organizations-and-not-others-and-what-motivates-us-in-the-beginning-to-do-our-best-over-time-though-we-may-lose-track-of-how-your-everyday-efforts-and-struggles-contribute-to-your-organizations-mission-as-a-result-your-motivation-wanes')}</ReactMarkdown>
				

				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>


				<IndividualQuestionFeedback number={2} questionId={'510bf8bb-3db1-4bbb-8968-7a928dabbb14'} feedbackByQuestion={props.results.feedbackByQuestion} />
				
				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('reminding-others-about-their-purpose-is-just-as-important-as-reminding-yourself-about-yours-to-survive-the-busyness-of-our-lives-we-tend-to-do-things-on-autopilot')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('the-issue-with-losing-sight-of-your-purpose-in-life-is-that-you-will-likely-feel-like-what-you-are-doing-is-meaningless-or-worse-that-your-life-is-meaningless-we-all-need-someone-to-remind-us-why-we-sacrifice-our-time-and-energy-to-keep-going-and-keep-growing')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'e04c03b1-907d-4f70-ab4e-40cf9f2cfe49'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('making-a-difference-in-the-world-is-part-of-the-4-elements-of-ikigai-living-a-life-worth-living-along-with-doing-something-you-love-using-your-strengths-and-being-paid-for-your-efforts')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('because-we-are-social-and-scient-beings-we-are-motivated-by-the-belief-that-our-actions-are-helping-improve-the-lives-of-people-in-the-world-even-those-we-do-not-know-nor-see-or-to-help-keep-the-planet-habitable-for-future-generations')}</ReactMarkdown>

				<section className="course-tables-center mt-4">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={teamworkimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">{i18n.t('ikigai-what-makes-a-life-worth-living')}<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ikigai" className="stretched-link"></a>
				</Card>
				</section>


				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-grow-your-purpose-orientation')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-16" className="offset-anchor">
				<div className="myimghor">
					<img src={emotionalityimg} alt="People talking to each other" />
				</div>

				<h2>15. {i18n.t('your-emotional-poise')}</h2>

				<ReportPBar results={props.results} category={'Emotional poise'} num={15}/>
				<SkillResult category={'Emotional poise'} results={props.results}/>

				<h3>{i18n.t('what-is-emotional-poise')}</h3>
				
				<ReactMarkdown>{i18n.t('emotional-poise-describes-your-ability-to-soothe-your-emotions-to-prevent-them-from-overwhelming-you-and-those-around-you-it-also-means-being-empathic-and-compassionate-towards-how-others-are-feeling')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('knowing-that-your-emotions-are-contagious-and-may-affect-others-you-need-to-follow-their-guidance-without-letting-them-take-over-control')}</ReactMarkdown>
				

				<div className="separator-large" />


				<h3>{i18n.t('feedback-on-your-3-emotional-poise-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'5ee3000c-efe8-4450-ba88-b7a655254c91'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<div className="notabene">
					<ReactMarkdown>{i18n.t('emotions-are-like-the-brains-post-it-notes-they-make-us-pay-attention-to-what-is-happening-to-us-because-it-helps-our-survival')}</ReactMarkdown>
				</div>

				<ReactMarkdown>{i18n.t('however-emotions-can-become-counterproductive-when-they-overwhelm-us-and-prevent-us-from-doing-what-needs-to-be-done-to-solve-the-challenges-we-are-facing')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('being-able-to-soothe-your-emotions-is-therefore-a-key-strength-when-dealing-with-a-crisis-remaining-calm-and-optimistic-in-the-face-of-adversity-will-likely-make-you-more-resilient-and-will-inspire-others-to-support-and-follow-you')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'f8addacc-a159-4850-b85b-6314d4359a44'} feedbackByQuestion={props.results.feedbackByQuestion} />
				
				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('sharing-your-worries-and-sorrows-is-a-great-way-to-show-vulnerability-and-grow-stronger-relationships-with-people-who-respond-in-a-caring-and-supportive-manner-to-your-troubles-however-throwing-a-pity-party-for-yourself-on-a-regular-basis-or-for-trivial-matters-will-likely-sap-morale')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('we-all-face-doubts-from-time-to-time-and-need-to-know-that-others-will-be-there-to-help-us-overcome-them-when-others-feel-like-you-cannot-overcome-yours-and-therefore-help-them-overcome-theirs-they-will-be-less-likely-to-come-to-you')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'e081b27c-a1a8-4f58-a759-07c724f8890e'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('some-people-avoid-having-candid-conversations-because-of-how-they-may-make-others-feel-uncomfortable-angry-sad-afraid-or-ashamed')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('however-having-such-conversations-is-essential-to-make-sure-your-decisions-will-not-harm-others-for-instance-adding-features-to-your-products-may-worsen-your-customers-experience-likewise-adding-more-to-another-teams-plate-without-aligning-with-them-first-will-create-resentment-and-negatively-affect-your-relationship-with-them')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<h3>{i18n.t('how-you-can-grow-your-emotional-poise')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>


			<section id="section-17" className="offset-anchor">
				<div className="myimghor">
					<img src={warmthimg} alt="People talking to each other" />
				</div>

				<h2>16. {i18n.t('your-trust-building')}</h2>
				<ReportPBar results={props.results} category={'Trust building'} num={16}/>
				<SkillResult category={'Trust building'} results={props.results}/>

				<h3>{i18n.t('what-is-trust-building')}</h3>
				
				<ReactMarkdown>{i18n.t('building-trust-is-the-most-important-skill-of-all-because-trust-is-the-foundation-upon-which-team-performance-is-built-in-one-way-or-another-the-other-15-skills-enable-you-to-be-perceived-by-others-as-a-trustworthy-person')}</ReactMarkdown>
				
				<div className="myimghor">
					<img src={fivebehaviorsimg} alt="People talking to each other" />
				</div>

				<div className="imgcaption">
					{i18n.t('adapted-from-the-5-behaviors-of-a-cohesive-team-by')} Patrick Lencioni
				</div>
				

				<section className="course-tables-center mt-4">
					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={likabilityexpertisereliability} variant="top" className="mb-3" />
						<Card.Title className="m-3">{i18n.t('what-are-the-3-elements-of-trust')}<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/elements-of-trust" className="stretched-link"></a>
					</Card>
				</section>

				<div className="separator-large" />


				<h3>{i18n.t('feedback-on-your-3-trust-building-answers')}</h3>

				<IndividualQuestionFeedback number={1} questionId={'d242e8b6-6222-4af4-9116-c09e7fb9c629'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('as-we-have-seen-in-other-skills-people-crave-for-belonging-to-a-tribe-and-like-their-voices-are-being-heard-when-people-can-talk-openly-and-with-vulnerability-about-their-issues-or-concerns-knowing-that-you-will-care-and-try-to-help-them-trust-is-built')}</ReactMarkdown>
				

				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={2} questionId={'0489df5f-b61c-4111-a780-fef7aa257901'} feedbackByQuestion={props.results.feedbackByQuestion} />
				
				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('active-listening-means-seeking-to-understand-what-the-other-person-is-trying-to-communicate-real-conversations-require-that-each-person-participating-in-them-tries-to-understand-what-the-others-are-saying-and-tries-to-contribute-to-improving-the-discussed-ideas')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('too-often-we-tend-to-interrupt-others-because-we-believe-what-we-have-to-say-is-more-interesting-or-more-important-that-what-the-other-person-is-saying-this-is-what-stephen-covey-meant-by-listening-with-the-intent-to-reply-rather-than-with-the-intent-to-understand')}</ReactMarkdown>


				<div className="myimghor">
					<img src={quoteassume} alt="Quote by Felix Unger: Never assume, because when you do, you make an ass of u and me." />
				</div>


				<ReactMarkdown>{i18n.t('rather-ask-clarifying-questions-and-see-if-you-truly-understand-the-message-the-other-person-is-trying-to-share-with-you')}</ReactMarkdown>


				<div className="dot-container my-5">
					<Icon.Dot className="dot" />
				</div>

				<IndividualQuestionFeedback number={3} questionId={'ffcfe058-5a31-40bd-baf7-228d50723c9a'} feedbackByQuestion={props.results.feedbackByQuestion} />

				<h4>{i18n.t('why-it-matters')}</h4>
				<ReactMarkdown>{i18n.t('when-someone-recognizes-that-what-we-have-done-has-helped-the-team-make-progress-we-feel-valued-when-they-do-we-feel-like-we-are-receiving-confirmation-that-our-efforts-are-desirable-and-worth-something-to-them')}</ReactMarkdown>
				<ReactMarkdown>{i18n.t('the-american-psychological-association-apa-published-interesting-results-linking-feeling-valued-with-positive-effects-such-as')}</ReactMarkdown>

				<ul>
					<li>{i18n.t('feeling-more-engaged-and-satisfied')}</li>
					<li>{i18n.t('feeling-more-motivated')}</li>
					<li>{i18n.t('reporting-better-mental-and-physical-health')}</li>
				</ul>

				<div className="myimghor">
					<img src={feelingvaluedimg} alt="People talking to each other" />
				</div>

				<div className="imgcaption">
					{i18n.t('people-who-feel-valued-become-more-loyal-to-you')}
				</div>


				<div className="separator-large" />

				<h3>{i18n.t('how-you-can-build-trust')}</h3>
				<ReactMarkdown>{i18n.t('this-section-is-still-being-developed-please-come-back-later')}</ReactMarkdown>
			</section>


			<div className="dot-container my-5">
				<Icon.Dot className="dot" /><Icon.Dot className="dot" /><Icon.Dot className="dot" />
			</div>
			

			<section id="section-6" className="offset-anchor">


                <div className="content-btn-box">
                    <a href="/ei-course" className="content-btn">{i18n.t('back-to-the-course')} <Icon.ArrowRight className="ml-2" /></a>
                </div>
			<br /><br /><br />
			</section>
		</body>
   </div>
  )
}
export default ThreeSixtyFeedbackContent;

