import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { ProgressBar, Table } from "react-bootstrap";
import Loading from "./Loading";
import {useTranslation, UseTranslationResponse} from "react-i18next";
import ReactMarkdown from 'react-markdown';


import omismatch from "../images/openness-mismatch.png";
import cmismatch from '../images/conscientiousness-mismatch.png';
import emismatch from '../images/extroversion-mismatch.png';
import amismatch from '../images/agreeableness-mismatch.png';
import nmismatch1 from '../images/nervosity-mismatch-1.png';
import nmismatch2 from '../images/nervosity-mismatch-2.png';

import omismatchfr from "../images/openness-mismatch-fr.png";
import cmismatchfr from '../images/conscientiousness-mismatch-fr.png';
import emismatchfr from '../images/extroversion-mismatch-fr.png';
import amismatchfr from '../images/agreeableness-mismatch-fr.png';
import nmismatch1fr from '../images/nervosity-mismatch-1-fr.png';
import nmismatch2fr from '../images/nervosity-mismatch-2-fr.png';



function ensureCompatible(results: any) {
    if(results['AGREEABLENESS']['score']){
        console.log("converting V2 results to V1 format",results);
        results = {
            "AGREEABLENESS" : results['AGREEABLENESS']['score'],
            "OPENNESS" : results['OPENNESS']['score'],
            "EXTROVERSION" :results['EXTROVERSION']['score'],
            "CONSCIENTIOUSNESS" : results['CONSCIENTIOUSNESS']['score'],
            "NERVOSITY" :  results['NERVOSITY']['score']
        };
    }
    return results;
}

function CompareReport(props) {
    const { i18n }  : {i18n} = useTranslation();
    function lowMediumHigh(score: number,i18n,reverse=false) {

        if (high(score)) {
            return reverse?`(${score}%) ${i18n.t('High')}`:`${i18n.t('High')} (${score}%)`;
        }
        if (low(score)) {
            return reverse?`(${score}%) ${i18n.t('Low')}`:`${i18n.t('Low')} (${score}%)`;
        }
        if (med(score)) {
            return reverse?`(${score}%) ${i18n.t('Medium')}`:`${i18n.t('Medium')} (${score}%)`;
        }
        return "?"
    }

    const caresAboutText = {
        'OPENNESS': [
            i18n.t('what-is-known'),
            i18n.t('balancing-what-is-known-with-what-is-new'),
            i18n.t('what-is-new')
        ],
        'CONSCIENTIOUSNESS': [
            i18n.t('being-carefree-and-easygoing'),
            i18n.t('balancing-a-carefree-life-with-a-responsible-one'),
            i18n.t('being-responsible-and-reliable')
        ],
        'EXTROVERSION': [
            i18n.t('enjoying-a-more-reserved-and-solitary-life'),
            i18n.t('balancing-solitude-with-social-life'),
            i18n.t('enjoying-an-outgoing-and-social-life')
        ],
        'AGREEABLENESS': [
            i18n.t('being-pragmatic'),
            i18n.t('balancing-being-pragmatic-with-being-benevolent'),
            i18n.t('being-benevolent')
        ],
        'NERVOSITY': [
            i18n.t('staying-optimistic-and-calm'),
            i18n.t('balancing-optimism-with-concern'),
            i18n.t('focus-on-what-could-go-wrong')
        ]
    }




    function high(score: number): boolean {
        return score >= 55;
    }

    function med(score: number): boolean {
        return score < 55 && score > 45;
    }

    function low(score: number): boolean {
        return score <= 45;
    }

    function caresAbout(trait: string, results) {
        return high(results[trait]) ? caresAboutText[trait][2]
            : med(results[trait]) ? caresAboutText[trait][1]
                : low(results[trait]) ? caresAboutText[trait][0] : '?';
    }


//### OTHER USER DESCRIPTION ###
    const DescOHigh = (props) => {
        return (<div>
            <p>{props.name} {i18n.t('tends-to-be-excited-about')}</p>

            <ul>
                <li>{i18n.t('discovering-new-activities')}</li>
                <li>{i18n.t('trying-new-experiences')}</li>
                <li>{i18n.t('learning-new-skills')}</li>
                <li>{i18n.t('exploring-new-opportunities')}</li>
            </ul>

            <p>{i18n.t('in-other-words')} {props.name} {i18n.t('usually-enjoys-the-thrill-of-the-unknown-and-challenging-the-status-quo-keep-in-mind')} {props.name}{i18n.t('s-motto')} <i>{i18n.t('what-got-you-here-will-not-get-you-there-why-not-go-on-an-adventure')}</i></p>
        </div>);
    }
    const DescOLow = (props) => {
        return (<div>
            <p>{props.name} {i18n.t('tends-to-prefer')}</p>

            <ul>
                <li>{i18n.t('sticking-to-their-habits-and-routines')}</li>
                <li>{i18n.t('enjoying-the-comfort-of-what-is-already-known')}</li>
            </ul>

            <p>{i18n.t('keep-in-mind')} {props.name}{i18n.t('s-motto')} <i>"{i18n.t('why-change-what-works-well')}"</i></p>
        </div>);
    }

    const DescCHigh = (props) => {
        return (<div>
            <p>{props.name} {i18n.t('tends-to')}</p>

            <ul>
                <li>{i18n.t('pay-great-attention-to-details')}</li>
                <li>{i18n.t('organize-their-life-meticulously')}</li>
                <li>{i18n.t('enjoy-feeling-like-they-are-in-control-of-the-situation')}</li>
            </ul>
        </div>);
    }
    const DescCLow = (props) => {
        return (<div>
            <p>{props.name} {i18n.t('tends-to')}</p>

            <ul>
                <li>{i18n.t('prefer-big-picture-strategic-thinking-and-keeping-things-simple')}</li>
                <li>{i18n.t('avoid-the-hassle-of-planning-everything-in-excruciating-details')}</li>
            </ul>
        </div>);
    }

    const DescEHigh = (props) => {
        return (<div>
            <p>{i18n.t('as-an-extrovert')} {props.name} {i18n.t('enjoys-social-interactions-which-energize-them')}</p>
            <p>{i18n.t('initiating-conversations-getting-to-know-other-people-and-sharing-about-themselves-is-important-to')} {props.name}.</p>
            <p>{props.name} {i18n.t('tends-to-work-better-in-groups-than-alone-and-to-be-more-actively-engaged-with-the-group')}</p>
        </div>);
    }
    const DescELow = (props) => {
        return (<div>
            <p>{i18n.t('as-an-introvert')} {props.name} {i18n.t('tends-to-prefer-one-on-one-interactions-and-evolving-in-groups-of-three-to-four-people')}</p>
            <p>{props.name} {i18n.t('may')}</p>
            <ul>
                <li>{i18n.t('dislike-small-talk')}</li>
                <li>{i18n.t('have-difficulties-initiating-and-continuing-conversations')}</li>
                <li>{i18n.t('feel-uncomfortable-when-getting-to-know-other-people-and-sharing-private-information-about-themselves')}</li>
            </ul>
            <p>{i18n.t('that-being-said-once')} {props.name} {i18n.t('trusts-someone-the-walls-usually-slowly-come-down')}</p>
        </div>);
    }

    const DescAHigh = (props) => {
        return (<div>
            <p>{i18n.t('in-situations-where')} {props.name} {i18n.t('feels-safe-and-at-ease-they-will-care-about-others-and-easily-trust-them')}</p>
            <p>{props.name} {i18n.t('will-encourage-people-to-come-to-then-for-help-and-avoid-confrontations')}</p>
        </div>);
    }
    const DescALow = (props) => {
        return (<div>
            <p>{i18n.t('in-situations-where-the-stakes-are-high')} {props.name} {i18n.t('may-become-more-pragmatic')}</p>
            <p>{i18n.t('how-people-feel-may-be-less-important-to')} {props.name} {i18n.t('than-solving-the-problem-or-getting-to-the-right-answer-please-keep-in-mind-that-this-is-nothing-personal')}</p>
        </div>);
    }

    const DescNHigh = (props) => {
        return (<div>
            <p>{i18n.t('as-a-high-nervosity-person')} {props.name} {i18n.t('is-likely-a-worrier-someone-who-experiences-intense-emotions')}</p>
            <p>{props.name} {i18n.t('may')}</p>

            <ul>
                <li>{i18n.t('reminisce-over-the-past-and-worry-about-whats-ahead-and-what-others-think-of-them')}</li>
                <li>{i18n.t('be-unconsciously-looking-for-the-challenges-and-downsides-of-everyday-situations')}</li>
            </ul>

            <p>{i18n.t('some-people-might-call-this-pessimism-but-it-likely-has-to-do-with')} {props.name}{i18n.t('s-need-to-search-for-multiple-scenarios-and-see-how-they-can-address-them')}</p>
        </div>);
    }
    const DescNLow = (props) => {
        return (<div>
            <p>{i18n.t('as-a-low-nervosity-person')} {props.name} {i18n.t('is-more-likely-able-to-keep-a-clear-mind-in-difficult-situations-which-makes-them-invaluable-at-solving-crises')}</p>
            <p>{i18n.t('while-others-may-panic-and-struggle-to-see-the-light-at-the-end-of-the-tunnel')} {props.name}{i18n.t('s-calmness-will-allow-to-reassure-them-and-guide-them')}</p>
            <p>{props.name} {i18n.t('should-also-be-better-able-to-consistently-refrain-from-experiencing-extreme-mood-swings-as-well-as-staying-more-optimistic-and-believing-that-everything-will-work-out-in-the-end')}</p>
        </div>);
    }

    const PerceptionOHigh = (props) => {
        return (<div>
                <p>{i18n.t('because-of-your-intense-curiosity-and-willingness-to-experiment-and-learn-as-much-as-possible')} {props.name} {i18n.t('may-perceive-you-as-unfocused-unrealistic-or-as-a-daydreamer')}</p>
                <h4>{i18n.t('Self-development advice')}</h4>
                <p>{i18n.t('take-the-time-to-explore-your-passions-and-interests-while-staying-aware-that-others-especially-people-with-lower-levels-of-openness-to-experience-may-feel-like-you-are-going-all-over-the-place-work-on-staying-focused-in-the-present-moment-and-plan-your-experiences')}</p>
            </div>
        );
    }

    const PerceptionOLow = (props) => {
        return (<div>
                <p>{i18n.t('your-cautious-nature-may-make')} {props.name} {i18n.t('perceive-you-as-closed-minded-or-inflexible')}</p>
                <h4>{i18n.t('Self-development advice')}</h4>
                <p>{i18n.t('you-need-to-cherish-your-habits-because-they-are-part-of-who-you-are-and-contribute-to-your-comfort-and-well-being-that-being-said-you-will-sometimes-also-need-to-go-outside-of-your-comfort-zone-and-discover-new-activities-or-ways-of-doing-things-because-you-never-know-what-new-activity-today-will-become-one-of-your-favorite-traditions-tomorrow')}</p>
            </div>
        );
    }
    const PerceptionCHigh = (props) => {
        return (<div>
                <p>{i18n.t('because-of-your-attention-to-details-and-efforts-for-being-organized')} {props.name} {i18n.t('may-perceive-you-as-controlling-rigid-or-as-a-perfectionist')}</p>
                <h4>{i18n.t('Self-development advice')}</h4>
                <p>{i18n.t('make-the-most-of-your-strengths-because-they-allow-you-to-be-highly-reliable-and-will-help-you-build-a-positive-reputation-as-someone-people-can-count-on-to-get-things-done')}</p>
                <p>{i18n.t('stay-aware-especially-when-you-communicate-with')} {props.name}{i18n.t('that-they-may-likely-not-need-as-much-information-as-you-do-and-may-even-feel-overwhelmed-by-it-start-with-simple-accessible-information-and-move-to-more-accurate-information-if')} {props.name} {i18n.t('asks-for-more-details-or-clarifications')}</p>
            </div>
        );
    }

    const PerceptionCLow = (props) => {
        return (<div>
                <p>{i18n.t('your-desire-for-being-more-strategic-and-your-ability-to-navigate-uncertainty-with-fewer-information-is-a-strength-to-making-tough-decisions-in-difficult-times')}</p>
                <p>{i18n.t('however')} {props.name} {i18n.t('may-start-questioning-how-reliable-you-are-when-you-forget-about-what-they-asked-you-to-do-or-when-you-miss-a-deadline-they-may-look-down-upon-your-tendency-to-forget-important-events-or-discussions-or-on-how-unorganized-it-makes-you-look')}</p>
                <h4>{i18n.t('Self-development advice')}</h4>
                <p>{i18n.t('there-are-simple-ideas-you-can-follow-to-be-better-prepared-without-having-to-worry-too-much-about-it')}</p>
                <ul>
                    <li>{i18n.t('take-good-notes-for-later-review')}</li>
                    <li>{i18n.t('plan-your-priorities-to-remember-them')}</li>
                    <li>{i18n.t('prepare-for-emergencies-in-advance')}</li>
                </ul>
            </div>
        );
    }

    const PerceptionEHigh = (props) => {
        return (<div>
                <p>{i18n.t('your-natural-energy-during-in-person-interactions-usually-makes')} {props.name} {i18n.t('perceive-you-as-cheerful-energetic-and-optimistic')} {props.name} {i18n.t('may-think-that-your-upbeat-attitude-and-tendency-to-smile-to-show-your-enjoyment-is-contagious')}</p>
                <h4>{i18n.t('Self-development advice')}</h4>
                <p>{i18n.t('keep-in-mind-that-your-enthusiasm-to-initiate-contact-share-about-yourself-and-inquire-about')} {props.name}{i18n.t('s-live-may-be-perceived-as-attention-seeking-and-intrusive-give-props-name-enough-space-if-they-seem-uncomfortable-with-sharing-about-themselves-or-learning-more-about-you')}</p>
            </div>
        );
    }

    const PerceptionELow = (props) => {
        return (<div>
                <p>{i18n.t('your-tendency-to-do-your-best-thinking-alone-and-getting-back-to-the-group-after-in-person-interactions-may-make')} {props.name} {i18n.t('perceive-you-as-distant-sometimes-even-cold')}</p>
            </div>
        );
    }

    const PerceptionAHigh = (props) => {
        return (<div>
                <p>{i18n.t('your-radiant-enthusiasm-may-be-exploited-by-some-people-make-sure-that-you-dont-forget-to-protect-your-own-self-interests-when-needed-by-trying-to-please')} {props.name} {i18n.t('at-all-costs-saying-yes-by-default-even-when-you-dont-mean-it-you-may-be-perceived-as-a-pushover-and-be-taken-advantage-of-your-caring-nature')}</p>
                <h4>{i18n.t('Self-development advice')}</h4>
                <p>{i18n.t('learn-to-speak-up-and-defend-what-you-truly-stand-for-think-of-ways-to-create-win-win-situations-instead-of-allowing-people-to-walk-over-you-to-get-what-they-want')}</p>
            </div>
        );
    }

    const PerceptionALow = (props) => {
        return (<div>
                <p>{i18n.t('your-practical-and-skeptical-nature-may-be-perceived-as-harsh-almost-robot-like-because-of-your-detachment-from-how')} {props.name} {i18n.t('may-feel')}</p>
                <h4>{i18n.t('Self-development advice')}</h4>
                <p>{i18n.t('solving-challenges-is-crucial-in-life-and-at-work-but-you-need-to-remember-that-you-are-interacting-with-human-beings-when')} {props.name} {i18n.t('feelings-get-hurt-they-may-disengage-or-sabotage-your-work')}</p>
            </div>
        );
    }

    const PerceptionNHigh = (props) => {
        return (<div>
                <p>{i18n.t('your-tendency-to-worry-about-future-challenges-is-valuable-because-it-helps-you-to-cover-potential-scenarios-and-prepare-for-the-worst-worrying-about')} {props.name}{i18n.t('s-well-being-also-helps-you-to-be-seen-as-a-caring-person')}</p>
                <p>{i18n.t('however-when-you-ruminate-too-much-on-things')} {props.name} {i18n.t('may-perceive-you-as-being-insecure-or-anxious-to-avoid-making-things-looking-only-pessimistic-you-can-leverage-your-tendency-to-think-of-possible-scenarios-to-also-consider')}</p>

                <ul>
                    <li>{i18n.t('what-could-go-right')}</li>
                    <li>{i18n.t('how-can-you-prevent-trouble-from-happening')}</li>
                    <li>{i18n.t('how-can-you-correct-what-went-wrong')}</li>
                </ul>

                <h4>{i18n.t('Self-development advice')}</h4>
                <p>{i18n.t('your-emotions-are-a-strength-and-are-contagious-you-need-to-learn-to-recognize-them-when-they-are-setting-in-to-avoid-experiencing-extreme-mood-swings-and-to-become-better-able-at-soothing-them-doing-so-is-a-sign-of-higher-emotional-intelligence-focusing-on-both-self-awareness-and-self-development-respectively')}</p>
            </div>
        );
    }

    const PerceptionNLow = (props) => {
        return (<div>
                <p>{i18n.t('while-your-ability-to-stay-calm-is-valuable-during-difficult-times')} {props.name} {i18n.t('may-perceive-you-as-an-unemotional-or-cold-person-they-may-perceive-your-poise-and-stoic-ability-to-be-more-relaxed-about-your-busyness-as-an-ability-to-absorb-more-work')}</p>
                <h4>{i18n.t('Self-development advice')}</h4>
                <p>{i18n.t('be-careful-that-you-dont-take-on-too-many-responsibilities-at-the-same-time-that-you-will-not-be-able-to-effectively-manage-people-with-higher-levels-of-nervosity-tend-to-focus-on-worst-case-scenarios-and-may-look-up-to-your-tendency-to-focus-on-what-is-going-right-rather-than-what-is-going-wrong-support-and-inspire-them')}</p>
            </div>
        );
    }

    const descriptions = {
        'OPENNESS' : {
            'h' : (userName) => <DescOHigh name={userName} />,
            'l' : (userName) => <DescOLow name={userName} />
        },
        'CONSCIENTIOUSNESS' : {
            'h' : (userName) => <DescCHigh name={userName} />,
            'l' : (userName) => <DescCLow name={userName} />
        },
        'EXTROVERSION' : {
            'h' : (userName) => <DescEHigh name={userName} />,
            'l' : (userName) => <DescELow name={userName} />
        },
        'AGREEABLENESS' : {
            'h' : (userName) => <DescAHigh name={userName} />,
            'l' : (userName) => <DescALow name={userName} />
        },
        'NERVOSITY' : {
            'h' : (userName) => <DescNHigh name={userName} />,
            'l' : (userName) => <DescNLow name={userName} />
        }
    }

    const OtherUserDesc = (props) => {
        if(high(props.comparison.otherUserQR[props.trait])){
            return descriptions[props.trait]['h'](props.comparison.otherUserName);
        }
        if(low(props.comparison.otherUserQR[props.trait])){
            return descriptions[props.trait]['l'](props.comparison.otherUserName);
        }
        //if we're here then they are medium, we'll position them based on our score
        if (high(props.comparison.thisUserQR[props.trait])){
            //we're high so we'll show them as low
            return descriptions[props.trait]['l'](props.comparison.otherUserName);
        }
        if (low(props.comparison.thisUserQR[props.trait])){
            //we're low so we'll show them as high
            return descriptions[props.trait]['h'](props.comparison.otherUserName);
        }
        //if we're here then we're both mediums.. darn
        //let's go by score then
        if (props.comparison.otherUserQR[props.trait] >= props.comparison.thisUserQR[props.trait]){
            return perceptions[props.trait]['h'](props.comparison.otherUserName);
        }else{
            return perceptions[props.trait]['l'](props.comparison.otherUserName);
        }
    }
    const perceptions = {
        'OPENNESS' : {
            'h' : (userName) => <PerceptionOHigh name={userName} />,
            'l' : (userName) => <PerceptionOLow name={userName} />
        },
        'CONSCIENTIOUSNESS' : {
            'h' : (userName) => <PerceptionCHigh name={userName} />,
            'l' : (userName) => <PerceptionCLow name={userName} />
        },
        'EXTROVERSION' : {
            'h' : (userName) => <PerceptionEHigh name={userName} />,
            'l' : (userName) => <PerceptionELow name={userName} />
        },
        'AGREEABLENESS' : {
            'h' : (userName) => <PerceptionAHigh name={userName} />,
            'l' : (userName) => <PerceptionALow name={userName} />
        },
        'NERVOSITY' : {
            'h' : (userName) => <PerceptionNHigh name={userName} />,
            'l' : (userName) => <PerceptionNLow name={userName} />
        }
    }

    const YourPerception = (props) => {
        if(high(props.comparison.thisUserQR[props.trait])){
            return perceptions[props.trait]['h'](props.comparison.otherUserName);
        }
        if(low(props.comparison.thisUserQR[props.trait])){
            return perceptions[props.trait]['l'](props.comparison.otherUserName);
        }
        //if we're here then we are medium, we'll position the perception on other user's score
        if (high(props.comparison.otherUserQR[props.trait])){
            //they're high so they perceive us as low
            return perceptions[props.trait]['l'](props.comparison.otherUserName);
        }
        if (low(props.comparison.thisUserQR[props.trait])){
            //they're low so wethey perceive us as high
            return perceptions[props.trait]['h'](props.comparison.otherUserName);
        }
        //if we're here then we're both mediums.. darn
        //let's go by score then
        if (props.comparison.thisUserQR[props.trait] >= props.comparison.otherUserQR[props.trait]){
            return perceptions[props.trait]['h'](props.comparison.otherUserName);
        }else{
            return perceptions[props.trait]['l'](props.comparison.otherUserName);
        }
    }

    const oMismatchImage = () => {
        switch (i18n.language) {
            case "fr" :
                return omismatchfr
            case "en" :
            default :
                return omismatch
        }
    }

    const MismatchO = (props) => {
        return (<div className="story">
            <h4>{i18n.t('at-work')}</h4>
            <div className="myimghor">
                <img src={oMismatchImage()} alt="Openness to experience mismatch" />
            </div>

            <ReactMarkdown>{i18n.t("Pauline, a High-Openness manager...")}</ReactMarkdown>
            <ReactMarkdown>{i18n.t("Unfortunately, Patricia did not realize...")}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Frustrated by Pauline...')}</ReactMarkdown>

            <h5>{i18n.t('what-could-pauline-have-done-differently')}</h5>
            <ReactMarkdown>{i18n.t('she-should-have-realized-which-of-garys-routines-were-helping-him-and-others-be-more-productive-and-which-were-counterproductive-then-she-could-discuss-with-him-which-of-the-new-tasks-she-wanted-to-implement-could-leverage-his-strengths-without-creating-too-much-uncertainty-and-novelty-in-his-work')}</ReactMarkdown>

            <h5>{i18n.t('what-about-gary')}</h5>
            <ReactMarkdown>{i18n.t('he-needs-to-understand-paulines-desire-to-help-patricia-and-him-do-an-even-better-job-at-contributing-to-the-companys-mission-she-is-not-advocating-for-change-for-changes-sake-but-to-make-sure-they-stay-engaged-in-helping-their-clients-he-also-needs-to-take-a-critical-look-at-how-new-tools-and-systems-could-help-reduce-his-workload-rather-than-continuing-working-long-hours-to-do-repetitive-tasks-himself-this-way-the-free-time-he-would-gain-could-be-used-to-do-the-work-he-actually-loves-doing')}</ReactMarkdown>


            <h4>{i18n.t('with-family-and-friends')}</h4>
            <div className="myimghor">
                <img src={oMismatchImage()} alt="Openness to experience mismatch" />
            </div>

            <ReactMarkdown>{i18n.t('patrick-a-high-openness-adventurous-man-is-happily-married-to-gwen-a-creature-of-habit-every-week-patrick-suggests-a-new-and-exciting-activity-they-could-try-together-usually-he-feels-disappointed-because-gwen-would-rather-do-the-activities-theyve-cherished-since-they-met')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('his-need-to-experience-novelty-and-discover-new-places-leaves-him-craving-for-more-and-upset-with-her-inflexibility-meanwhile-gwen-sometimes-feels-harassed-by-his-endless-pleas-for-adventure-she-wonders-if-he-understand-how-much-their-beloved-habits-means-to-her-over-time-they-start-growing-apart-from-each-other')}</ReactMarkdown>

            <h5>{i18n.t('how-could-patrick-convince-gwen-without-rushing-her')}</h5>
            <ReactMarkdown>{i18n.t('he-could-discuss-with-her-why-she-loves-some-of-their-activities-and-not-others-to-understand-whether-they-can-find-new-ones-which-would-satisfy-both-his-need-for-novelty-and-her-need-for-stability-perhaps-gwen-is-scared-by-some-of-the-audacious-adventures-patrick-finds-exciting-he-could-also-reflect-on-how-much-some-of-their-routines-mean-to-him-and-see-how-they-could-spend-more-quality-time-engaged-in-these-activities')}</ReactMarkdown>

            <h5>{i18n.t('what-about-gwen')}</h5>
            <ReactMarkdown>{i18n.t('she-could-ask-him-to-share-which-new-activities-he-is-truly-looking-forward-to-trying-and-reflect-on-which-ones-she-would-also-enjoy-doing-based-on-her-deep-understanding-of-what-makes-her-tick-after-all-theyve-enjoyed-hiking-through-the-south-of-france-last-summer-why-not-try-exploring-the-swiss-mountains-and-sleeping-in-a-hut-this-way-he-could-go-on-an-adventure-in-a-new-place-while-she-could-do-something-she-relishes-as-well-after-all-patrick-suggested-the-french-hiking-experience-and-he-was-right')}</ReactMarkdown>
        </div>)
    }

    const cMismatchImage = () => {
        switch (i18n.language) {
            case "fr" :
                return cmismatchfr
            case "en" :
            default :
                return cmismatch
        }
    }

    const MismatchC = (props) => {
        return (<div className="story">
            <ReactMarkdown>{i18n.t("Jon, a highly conscientious person...")}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('As a result, Stacy')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('At the end of her six month internship')}</ReactMarkdown>
            <div className="myimghor">
                <img src={cMismatchImage()} alt="Conscientiousness mismatch" />
            </div>
        </div>);
    }

    const eMismatchImage = () => {
        switch (i18n.language) {
            case "fr" :
                return emismatchfr
            case "en" :
            default :
                return emismatch
        }
    }

    const MismatchE = (props) => {
        return (<div className="story">
            <ReactMarkdown>{i18n.t('Dana is extremely introverted')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Her new manager')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Unfortunately, Philip doesnt')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Dana misses Ellen')}</ReactMarkdown>
            <div className="myimghor">
                <img src={eMismatchImage()} alt="Extroversion mismatch"  />
            </div>
        </div>);
    }

    const aMismatchImage = () => {
        switch (i18n.language) {
            case "fr" :
                return amismatchfr
            case "en" :
            default :
                return amismatch
        }
    }

    const MismatchA = (props) => {
        return (<div className="story">
            <ReactMarkdown>{i18n.t('Highly-agreeable Takeda')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('His empathetic nature')}</ReactMarkdown>
            <div className="myimghor">
                <img src={aMismatchImage()} alt="Agreeableness mismatch"  />
            </div>
        </div>);
    }

    const n1MismatchImage = () => {
        switch (i18n.language) {
            case "fr" :
                return nmismatch1fr
            case "en" :
            default :
                return nmismatch1
        }
    }

    const n2MismatchImage = () => {
        switch (i18n.language) {
            case "fr" :
                return nmismatch2fr
            case "en" :
            default :
                return nmismatch2
        }
    }

    const MismatchN = (props) => {
        return (<div className="story">
            <h4>{i18n.t('Project Management')}</h4>
            <ReactMarkdown>{i18n.t('Mary is a chronicle worrier')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('After being two weeks behind schedule')}</ReactMarkdown>
            <div className="myimghor">
                <img src={n1MismatchImage()} alt="Nervosity mismatch example 1"  />
            </div>

            <h4>{i18n.t('2-prevent-and-correct-mistakes')}</h4>
            <ReactMarkdown>{i18n.t('I (Chris)')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('He taught me that my tendency')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('In the world of quality')}</ReactMarkdown>

            <ul>
                <li><ReactMarkdown>{i18n.t('How can we prevent this problem from happening')}</ReactMarkdown></li>
                <li><ReactMarkdown>{i18n.t('How can we correct the damages')}</ReactMarkdown></li>
            </ul>

            <ReactMarkdown>{i18n.t('His calm nature')}</ReactMarkdown>

            <div className="myimghor">
                <img src={n2MismatchImage()} alt="Nervosity mismatch example 2"  />
            </div>
        </div>);
    }


    const mismatch = {
        'OPENNESS' : <MismatchO  />,
        'CONSCIENTIOUSNESS' : <MismatchC  />,
        'EXTROVERSION' : <MismatchE  />,
        'AGREEABLENESS' : <MismatchA />,
        'NERVOSITY' : <MismatchN />
    }

    const ExampleMismatch = (props) => {
        return mismatch[props.trait];
    }


    const WorkWithOHigh = (props) => {
        return (<div>
            <p>
                <ul>
                    <li>{i18n.t('ask-what-new-skill-sets-or-experiences')} {props.name} {i18n.t('wants-to-acquire-and-assign-them-on-relevant-projects-that-match-their-interests-and-development-plans')}</li>
                    <li>{i18n.t('encourage')} {props.name}{i18n.t('s-desire-to-learn-new-things-and-give-them-enough-time-and-resources-to-work-on-expanding-their-skill-sets')}</li>
                    <li>{i18n.t('emphasize-the-benefits-or-opportunities-when-pitching')} {props.name} {i18n.t('to-try-something-new-then-let-them-share-their-thoughts-ideas-or-concerns-to-build-on-your-proposal-let-them-internalize-them-and-make-them-their-own')}</li>
                </ul>
            </p>
        </div>);
    }

    const WorkWithOLow = (props) => {
        return (<div>
            <ul>
                <li>{i18n.t('look-at-and-ask-about')} {props.name}{i18n.t('s-previous-and-favorite-experiences-and-assign-them-on-projects-that')} <b>{i18n.t('build-on-their-existing-skill-sets-and-interests')}</b>. {i18n.t('focus-on-what-they-already-know-and-are-comfortable-with')}</li>
                <li>{i18n.t('encourage')} {props.name} {i18n.t('to')} <b>{i18n.t('deepen-their-areas-of-expertise')}</b>{i18n.t('because-assigning-them-to-too-many-new-unfamiliar-tasks-may-scare-them-and-impact-their-well-being-and-performance')}</li>
                <li>{i18n.t('change-management-can-be-especially-challenging-for')} {props.name}. {i18n.t('in-their-minds-you-cannot-spell')} <b><i>CHA</i></b>LLE<b><i>NGE</i></b> without <b><i>CHANGE</i></b>. {i18n.t('they-are-likely-going-to-apprehend-changes-forced-in-their-lives')}</li>
                <li>{i18n.t('to-make-things-easier-on-them')} <b>{i18n.t('emphasize-what-is-not-changing')}</b>. {i18n.t('then-reassure-them-by-sharing-all-the-facts-that-explain-why-change-is-necessary-and-how-they-can-benefit-from-it-how-can-this-change-improve-their-daily-lives-and-work-the-goal-is-to-minimize-the-change-or-the-efforts-they-will-have-to-put-in-as-a-result-of-the-change')}</li>
            </ul>
        </div>);
    }
    const WorkWithCHigh = (props) => {
        return (<div>
            <ul>
                <li>{i18n.t('spend-enough-time-at-first-to')} <b>{i18n.t('give')} {props.name} {i18n.t('as-much-information-as-they-need')}</b> {i18n.t('to-feel-comfortable-to-get-started-then-break-down-the-goals-of-each-project-into-clear-sub-parts-and-discuss-what-needs-to-be-done-and-how')} {props.name} {i18n.t('wants-to-approach-their-work-when-following-up-give-them-in-depth-reports-with-as-many-details-as-possible')}</li>
                <li><b>{i18n.t('be-prepared-for')} {props.name} {i18n.t('to-ask-many-questions')}</b>. {i18n.t('get-ready-to-review-them-together-and-answer-all-of-them-skipping-information-may-make')} {props.name} {i18n.t('wheels-spin-and-prevent-them-from-getting-back-to-work')}</li>
            </ul>
        </div>);
    }

    const WorkWithCLow = (props) => {
        return (<div>
            <ul>
                <li><b>{i18n.t('clarify-the-big-goals-of-each-projects-as-well-as-your-overall-expectations')}</b>. {i18n.t('avoid-going-into-too-many-details-as-too-much-information-will-likely-overwhelm')} {props.name}. {i18n.t('instead-give-them-a-brief-summary-which-contains-the-most-critical-facts')}</li>
                <li>{i18n.t('allow')} {props.name} {i18n.t('the-freedom-to-come-up-with-their-own-way-of-doing-things-to-achieve-your-goals-and-expectations')} <b>{i18n.t('discuss-together-the-next-steps-to-make-sure-you-are-aligned-and-they-arent-missing-key-steps')}</b>.</li>
            </ul>
        </div>);
    }
    const WorkWithEHigh = (props) => {
        return (<div>
            <ul>
                <li>{i18n.t('its-important-to-understand-that')} {props.name} {i18n.t('thrives-in-social-interactions-while-they-may-feel-draining-for-your')} {props.name}{i18n.t('s-tendencies-to-approach-you-share-about-themselves-and-ask-you-to-do-the-same-is-not-a-personal-attack-its-part-of-their-nature')}</li>
                <li>{i18n.t('consider-that')} {props.name}{i18n.t('s-sociable-nature-means-that-they-dislike-being-alone-because-they-seek-human-contact-to-feel-like-they-belong-their-energy-and-enthusiasm-can-be-contagious-while-they-may-sometimes-feel-intimidating-they-can-also-energize-you-if-you-let-them')}</li>
                <li>{i18n.t('assign')} {props.name} {i18n.t('to-projects-or-roles-that-leverage-their-energy-and-social-skills-such-as-brainstorming-sessions-moderation-etc')} {props.name} {i18n.t('is-great-in-asking-and-understanding-what-others-think')}</li>
                <li>{i18n.t('ask')} {props.name} {i18n.t('to-take-over-in-mentoring-roles-to-reduce-the-time-they-spend-alone-which-they-might-dread-publicly-praise-their-personal-achievements-and-their-team-building-efforts')}</li>
            </ul>
        </div>);
    }

    const WorkWithELow = (props) => {
        return (<div>
            <p>{i18n.t('first-its-important-to-address-a-common-misconception-being-shy-is-not-necessarily-equal-to-being-an-introvert-research-suggests-that-shyness-is-a-blend-between-introversion-and-high-nervosity-introverts-are-usually-more-difficult-to-approach-in-larger-groups-but-they-may-be-more-expressive-and-outgoing-in-one-on-one-interactions-or-in-small-groups-of-a-few-people-although-it-may-seem-counterintuitive-introverts-can-know-as-many-people-as-extroverts-do-the-difference-is-that-they-thrive-in-smaller-groups-when-connecting-with-people-while-extroverts-feel-comfortable-with-engaging-wither-larger-audiences')}</p>
            <ul>
                <li>{props.name} {i18n.t('is-likely-to-prefer-projects-with-smaller-teams-and-with-fewer-face-to-face-interactions')}</li>
                <li>{i18n.t('give')} {props.name} {i18n.t('enough-time-alone-to-do-their-thinking-and-their-work')}</li>
                <li>{i18n.t('give')} {props.name} {i18n.t('enough-resources-and-learning-materials-to-process-things-at-their-on-pace-and-on-their-own')}</li>
                <li>{i18n.t('leverage-written-communication-to-let')} {props.name} {i18n.t('come-back-to-you-if-they-feel-uncomfortable-sharing-their-thoughts-during-your-one-on-one-conversations')}</li>
                <li>{i18n.t('accept-that')} {props.name} {i18n.t('may-come-back-a-few-days-after-your-previous-conversation-to-share-their-thoughts')}</li>
                <li>{i18n.t('know-that')} {props.name} {i18n.t('may-need-personal-space-outside-of-their-desk-to-work-alone-away-from-the-distractions-and-potentially-draining-interruptions-they-could-otherwise-experience')}</li>
                <li>{i18n.t('unless')} {props.name} {i18n.t('asks-otherwise-praise-their-personal-achievements-and-team-building-efforts-during-one-on-one-sessions-rather-than-putting-them-on-the-spot-in-front-of-everyone-else-they-dislike-being-the-center-of-attention')}</li>
            </ul>
        </div>);
    }
    const WorkWithAHigh = (props) => {
        return (<div>
            <ul>
                <li>{i18n.t('keep-in-mind')} {props.name}{i18n.t('s-desire-to-please-you-make-sure-that-when-they-agree-to-doing-something-they-have-clearly-understood-your-expectations-and-know-how-to-get-started-if-needed-you-may-ask')} {props.name} {i18n.t('to-write-down-their-plans-of-action-to-make-sure-they-will-not-get-stuck-and-not-dare-asking-you-for-help-out-of-fear-of-conflict')}</li>
                <li>{i18n.t('ask')} {props.name} {i18n.t('to-share-the-concerns-they-have-and-how-they-are-feeling-emphasizing-how-this-matters-to-you-because-they-need-to-feel-heard-and-like-they-belong-to-do-their-best-work')}</li>
                <li>{i18n.t('during-conversations-take-time-to-pause-and-gather')} {props.name}{i18n.t('s-thoughts-and-concerns-emphasize-how-getting-as-much-information-as-possible-upfront-can-prevent-further-unnecessary-challenges-and-misunderstandings-from-arising')}</li>
                <li>{i18n.t('ask-for-written-thoughts-and-feedback-between-your-one-on-one-conversations-to-give')} {props.name} {i18n.t('a-safe-space-to-share-their-ideas-needs-thoughts-and-concerns')}</li>
            </ul>
        </div>);
    }

    const WorkWithALow = (props) => {
        return (<div>
            <ul>
                <li>{i18n.t('remember-that')} {props.name} {i18n.t('will-likely-tend-to-say-no-at-first-and-dont-take-it-personally-its-a-defense-mechanism-to-protect-their-time-energy-and-interests')}</li>
                <li>{i18n.t('when')} {props.name} {i18n.t('refuses-give-them-time-to-weight-the-pros-and-cons-and-get-back-to-you-at-a-later-stage-when-they-realize-that-their-helping-you-will-make-everyone-better-off-props-name-are-likely-going-to-assist-you')}</li>
                <li>{i18n.t('accept-that')} {props.name} {i18n.t('may-be-skeptical-and-that-you-will-need-to-give-them-enough-facts-to-make-your-case-because-of-their-practical-nature-focus-on-giving-them-facts-and-letting-them-focus-on-solving-the-challenges-rather-than-looking-for-an-emotional-explanation-of-the-situation-they-tend-to-deal-better-with-tasks-than-with-people')}</li>
            </ul>
        </div>);
    }
    const WorkWithNHigh = (props) => {
        return (<div>
            <ul>
                <li>{i18n.t('allow')} {props.name} {i18n.t('enough-safe-space-to-express-themselves-both-orally-and-in-written-review-together-the-alternatives-pros-and-cons-and-backup-plans-let-props-name-be-thorough-enough-so-that-they-dont-spin-their-wheels-and-overthink-things-while-they-are-working')}</li>
                <li>{i18n.t('give')} {props.name} {i18n.t('enough-time-to-share-their-ideas-thoughts-and-concerns-resist-the-urge-to-put-them-against-a-wall-or-to-let-them-ruminate-over-their-concerns-after-conversations-reassure-props-name-by-letting-them-know-that-you-are-trying-to-cover-multiple-potentialities-so-they-dont-have-to-worry-about-them-themselves-this-will-make-them-trust-you-and-reduce-their-wariness')}</li>
                <li>{i18n.t('leverage')} {props.name}{i18n.t('s-ability-to-think-of-multiple-scenarios-to-see-which-opportunities-and-risks-you-may-be-confronted-with-how-can-you-prevent-a-worst-case-scenario-from-happening-how-can-you-repair-the-resulting-damages')}</li>
            </ul>
        </div>);
    }

    const WorkWithNLow = (props) => {
        return (<div>
            <ul>
                <li>{i18n.t('if-you-tend-to-worry-a-lot-let')} {props.name} {i18n.t('know-in-advance-that-this-is-nothing-personal-its-who-you-are-and-everyone-gets-treated-the-same-way-and-you-are-working-on-it-leverage-their-tendency-to-not-get-concerned-with-potential-issues-show-them-you-have-done-your-due-diligence-and-let-them-trust-your-work')}</li>
                <li>{i18n.t('ask')} {props.name} {i18n.t('for-their-support-during-crises-and-leverage-their-ability-to-think-clearly-during-stressful-situations-they-will-likely-be-able-to-get-you-unstuck-and-moving-forward-again')}</li>
                <li>{i18n.t('leverage-one-on-one-conversations-to-raise-your-concerns-and-let')} {props.name} {i18n.t('express-theirs-rather-than-bombarding-them-with-emails-or-phone-calls-they-are-usually-able-to-absorb-more-stress-than-you-without-being-affected-but-it-doesnt-mean-that-you-should-harass-them-or-that-they-will-never-break')}</li>
                <li>{i18n.t('take-the-time-to-prepare-for-your-conversations-with')} {props.name}{i18n.t('which-will-make-them-more-effective-they-will-appreciate-your-ability-to-share-your-thoughts-and-concerns-without-being-emotionally-overwhelmed-in-the-moment')}</li>
            </ul>
        </div>);
    }

    const howToWorkWith = {
        'OPENNESS' : {
            'h' : (username) => <WorkWithOHigh name={username}/>,
            'l' : (username) => <WorkWithOLow name={username}/>
        },
        'CONSCIENTIOUSNESS' : {
            'h' : (username) => <WorkWithCHigh name={username}/>,
            'l' : (username) => <WorkWithCLow name={username}/>
        },
        'EXTROVERSION' : {
            'h' : (username) => <WorkWithEHigh name={username}/>,
            'l' : (username) => <WorkWithELow name={username}/>
        },
        'AGREEABLENESS' : {
            'h' : (username) => <WorkWithAHigh name={username}/>,
            'l' : (username) => <WorkWithALow name={username}/>
        },
        'NERVOSITY' : {
            'h' : (username) => <WorkWithNHigh name={username}/>,
            'l' : (username) => <WorkWithNLow name={username}/>
        }
    }

    const WorkWith = (props) => {
        if(high(props.comparison.otherUserQR[props.trait])){
            return howToWorkWith[props.trait]['h'](props.comparison.otherUserName);
        }
        if(low(props.comparison.otherUserQR[props.trait])){
            return howToWorkWith[props.trait]['l'](props.comparison.otherUserName);
        }
        //if we're here then they are medium, we'll position them based on our score
        if (high(props.comparison.thisUserQR[props.trait])){
            //we're high so we'll show them as low
            return howToWorkWith[props.trait]['l'](props.comparison.otherUserName);
        }
        if (low(props.comparison.thisUserQR[props.trait])){
            //we're low so we'll show them as high
            return howToWorkWith[props.trait]['h'](props.comparison.otherUserName);
        }
        //if we're here then we're both mediums.. darn
        //let's go by score then
        if (props.comparison.otherUserQR[props.trait] >= props.comparison.thisUserQR[props.trait]){
            return howToWorkWith[props.trait]['h'](props.comparison.otherUserName);
        }else{
            return  howToWorkWith[props.trait]['l'](props.comparison.otherUserName);
        }
    }

    let {otherUid} = useParams();

    const [done, setDone] = useState(false);
    const [comparison, setComparison] = useState({
        thisUserQR: {},
        otherUserQR: {},
        otherUserName: ""
    });

    useEffect(() => {
        fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/compare-profile-report', {
            method: 'POST',
            body: JSON.stringify({"otherUid": otherUid}),
            headers: {
                'Authorization': `Bearer ${props.jwt}`,
            }
        }).then(response => {
            if (response.status === 200) {
                const res = response.json();
                return res;
            }
        }).then(res => {
            console.log(res);
            setComparison({
                thisUserQR: ensureCompatible(res.thisUser['quizResults']),
                otherUserQR: ensureCompatible(res.otherQuiz['quizResults']),
                otherUserName: res.otherUserName
            });
            console.log(comparison);
            setDone(true);
        });
    }, [])

    function getTraitCompareTable(title,ourScore, theirScore,i18n){
        return (
            <Table className="table-borderless comparison-table">
            <thead>
                <tr>
                <th className="course-table-header text-center border-top-left-radius">{props.username}</th>
                <th className="course-table-header text-center">Trait</th>
                <th className="course-table-header text-center border-top-right-radius">{comparison.otherUserName}</th>
                </tr>
            </thead>

            <tbody>
            <tr>
                <td>
                    <ProgressBar className="leftProgress" variant='info' now={100}
                                 label={lowMediumHigh(ourScore, i18n,true)}
                                 style={{'width': ourScore + '%'}}/>
                </td>
                <td style={{width:'2px', verticalAlign: 'top', margin: '0'}}><p className="Compare-Table-Trait-OCEAN" style={{margin: '0'}}>{i18n.t(title)}</p></td>
                <td>
                    <ProgressBar className="rightProgress" variant='success' now={100}
                                 label={lowMediumHigh(theirScore, i18n,false)}
                                 style={{'width': theirScore + '%'}}/>
                </td>
            </tr>
            </tbody>
            </Table> )
        ;
    }

    function getCompareTable() {
        return <Table className="table-borderless comparison-table">
            <thead className="mb-2">
            <tr>
                <th className="course-table-header text-center border-top-left-radius">{props.username}</th>
                <th className="course-table-header text-center">Trait</th>
                <th className="course-table-header text-center border-top-right-radius">{comparison.otherUserName}</th>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td>
                    <ProgressBar className="leftProgress" variant='info' now={100}
                        label={lowMediumHigh(comparison.thisUserQR['OPENNESS'], i18n,true)}
                        style={{'width': comparison.thisUserQR['OPENNESS'] + '%'}}/>
                </td>
                <td style={{verticalAlign: 'top'}}><p className="Compare-Table-Trait-OCEAN" style={{margin: '0'}}>O</p></td>
                <td>
                    <ProgressBar className="rightProgress" variant='success' now={100}
                        label={lowMediumHigh(comparison.otherUserQR['OPENNESS'], i18n,false)}
                        style={{'width': comparison.otherUserQR['OPENNESS'] + '%'}}/>
                </td>
            </tr>

            <tr>
                <td>
                    <ProgressBar className="leftProgress" variant='info' now={100}
                                 label={lowMediumHigh(comparison.thisUserQR['CONSCIENTIOUSNESS'], i18n,true)}
                                 style={{'width': comparison.thisUserQR['CONSCIENTIOUSNESS'] + '%'}}/>
                </td>
                <td style={{verticalAlign: 'top'}}><p className="Compare-Table-Trait-OCEAN" style={{margin: '0'}}>C</p></td>
                <td>
                    <ProgressBar className="rightProgress" variant='success' now={100}
                                 label={lowMediumHigh(comparison.otherUserQR['CONSCIENTIOUSNESS'], i18n,false)}
                                 style={{'width': comparison.otherUserQR['CONSCIENTIOUSNESS'] + '%'}}/>
                </td>
            </tr>

            <tr>
                <td>
                    <ProgressBar className="leftProgress" variant='info' now={100}
                                 label={lowMediumHigh(comparison.thisUserQR['EXTROVERSION'], i18n,true)}
                                 style={{'width': comparison.thisUserQR['EXTROVERSION'] + '%'}}/>
                </td>
                <td style={{verticalAlign: 'top'}}><p className="Compare-Table-Trait-OCEAN" style={{margin: '0'}}>E</p></td>
                <td>
                    <ProgressBar className="rightProgress" variant='success' now={100}
                                 label={lowMediumHigh(comparison.otherUserQR['EXTROVERSION'], i18n,false)}
                                 style={{'width': comparison.otherUserQR['EXTROVERSION'] + '%'}}/>
                </td>
            </tr>

            <tr>
                <td>
                    <ProgressBar className="leftProgress" variant='info' now={100}
                                 label={lowMediumHigh(comparison.thisUserQR['AGREEABLENESS'], i18n,true)}
                                 style={{'width': comparison.thisUserQR['AGREEABLENESS'] + '%'}}/>
                </td>
                <td style={{verticalAlign: 'top'}}><p className="Compare-Table-Trait-OCEAN" style={{margin: '0'}}>A</p></td>
                <td>
                    <ProgressBar className="rightProgress" variant='success' now={100}
                                 label={lowMediumHigh(comparison.otherUserQR['AGREEABLENESS'], i18n,false)}
                                 style={{'width': comparison.otherUserQR['AGREEABLENESS'] + '%'}}/>
                </td>
            </tr>

            <tr>
                <td>
                    <ProgressBar className="leftProgress" variant='info' now={100}
                                 label={lowMediumHigh(comparison.thisUserQR['NERVOSITY'], i18n,true)}
                                 style={{'width': comparison.thisUserQR['NERVOSITY'] + '%'}}/>
                </td>
                <td style={{verticalAlign: 'top'}}><p className="Compare-Table-Trait-OCEAN"  style={{margin: '0'}}>N</p></td>
                <td>
                    <ProgressBar className="rightProgress" variant='success' now={100}
                                 label={lowMediumHigh(comparison.otherUserQR['NERVOSITY'], i18n,false)}
                                 style={{'width': comparison.otherUserQR['NERVOSITY'] + '%'}}/>
                </td>
            </tr>
            </tbody>
        </Table>;
    }

    function traitReport(trait) {
        return <>
            <h3>{i18n.t('you')} {lowMediumHigh(comparison.thisUserQR[trait],i18n)} <br/> {comparison.otherUserName} : {lowMediumHigh(comparison.otherUserQR[trait],i18n)}</h3>
            
            <br/>
            <table>
                {getTraitCompareTable(trait,comparison.thisUserQR[trait],comparison.otherUserQR[trait],i18n)}
            </table>
            
            <br/>
            <h3> {comparison.otherUserName}{i18n.t('s')} {i18n.t(trait)} </h3>
            <OtherUserDesc trait={trait} comparison={comparison}/>
            <br/>
            <h3> {i18n.t('how')} {comparison.otherUserName} {i18n.t('can-perceive-you')} </h3>
            <YourPerception trait={trait} comparison={comparison}/>
            <br/>
            <h3> {i18n.t('example-of')} {i18n.t(trait)} {i18n.t('misunderstandings')} </h3>
            <ExampleMismatch trait={trait}/>
            <br/>
            <h3> {i18n.t('how-to-better-interact-with')} {comparison.otherUserName} </h3>
            <WorkWith trait={trait} comparison={comparison}/>
            <br/>
        </>;
    }

    if (done) {
        return (
            <div className="masthead"><br/><br/>
                <h2>{i18n.t('you-and')} <b>{comparison.otherUserName}</b></h2><br/>
                <section className="comparison-table-wrapper">
                    {getCompareTable()}
                </section>
                

                <br/>
                <h3><b>{comparison.otherUserName}</b> {i18n.t('cares-about')} </h3>
                <br/>
                <ul style={{textAlign: 'left'}}>
                    <li><b>O: </b> {caresAbout('OPENNESS', comparison.otherUserQR)}</li>
                    <li><b>C:</b> {caresAbout('CONSCIENTIOUSNESS', comparison.otherUserQR)}</li>
                    <li><b>E:</b> {caresAbout('EXTROVERSION', comparison.otherUserQR)}</li>
                    <li><b>A:</b> {caresAbout('AGREEABLENESS', comparison.otherUserQR)}</li>
                    <li><b>N:</b> {caresAbout('NERVOSITY', comparison.otherUserQR)}</li>
                </ul>
                <br/>
                
                <hr/>

                <h2><b>{i18n.t('your-openness-to-experience')} </b></h2>
                {traitReport('OPENNESS')}
                <hr/>

                <h2><b>{i18n.t('your-conscientiousness')} </b></h2>
                {traitReport('CONSCIENTIOUSNESS')}
                <hr/>
                
                <h2><b>{i18n.t('your-extroversion')} </b></h2>
                {traitReport('EXTROVERSION')}
                <hr/>
                
                <h2><b>{i18n.t('your-agreeableness')} </b></h2>
                {traitReport('AGREEABLENESS')}
                <hr/>
                
                <h2><b>{i18n.t('your-nervosity')} </b></h2>
                {traitReport('NERVOSITY')}
                
                <br/> <br/> <br/>
            </div>
        );
    } else {
        return (<Loading/>);
    }
}

export default CompareReport;