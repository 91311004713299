import React from 'react';
import { Card, CardDeck, Image } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';


import * as Icon from 'react-bootstrap-icons';


import whyeimatters from "../images/emotions.png";
import emotionalityimg from "../images/emotionality.png";
import quotemicroexpressionsimg from "../images/quote-ekman-microexpressions.png";
import readingemotions from "../images/reading-emotions.jpg";
import joyreadingimg from "../images/joy-reading.jpg";
import angerreadingimg from "../images/anger-reading.jpg";
import fearreadingimg from "../images/fear-reading.jpg";
import sadnessreadingimg from "../images/sadness-reading.jpg";
import disgustreadingimg from "../images/disgust-reading.jpg";
import surprisereadingimg from "../images/surprise-reading.jpg";
import contemptreadingimg from "../images/contempt-reading.jpg";
import shamereadingimg from "../images/shame-reading.jpg";


function ReadingEmotions(props: any) {
	   
  	return (
   		<div>
			<body className="masthead" data-spy="scroll" data-offset="60" data-target="#myNavbar">

				<Scrollspy items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5', 'section-6', 'section-7', 'section-8' ] } currentClassName="is-current" className="sticky-top scrollspy overflow-scroll-horizontal" offset={20}>
					<li><a href="#section-1" className="scrollspy-link">Top</a></li>
					<li><a href="#section-2" className="scrollspy-link">1. Joy</a></li>
					<li><a href="#section-3" className="scrollspy-link">2. Anger</a></li>
					<li><a href="#section-4" className="scrollspy-link">3. Fear</a></li>
					<li><a href="#section-5" className="scrollspy-link">4. Sadness</a></li>
					<li><a href="#section-6" className="scrollspy-link">5. Disgust</a></li>
					<li><a href="#section-7" className="scrollspy-link">6. Surprise</a></li>
					<li><a href="#section-8" className="scrollspy-link">7. Contempt</a></li>
				</Scrollspy>

				<div className="myimghor">
					<img src={readingemotions} alt="Mosaique of different people displaying diverse emotions" />
				</div> 

				<section id="section-1">
					<h1 className="my-1">How To Read Emotions On Other People's Faces</h1>
					
					<div className="myimghor">
						<img src={quotemicroexpressionsimg} alt="Quote by Paul Ekman: Micro expressions are facial expressions that occur within a fraction of a second. This involuntary emotional leakage exposes a person’s true emotions." />
					</div> 

					<div className="separator"></div>
					
					<p>This lesson was inspired by the brilliant research on the universal human facial microexpressions done by Dr. Paul Ekman. If you want to dive deeper into the subject, <a href="https://www.paulekman.com/">make sure you check out his work.</a></p>
					<p>There are 7 emotions which you can learn to decipher by looking at other people's faces:</p>

					<ol>
						<li><a href="#section-2">Joy</a></li>
						<li><a href="#section-3">Anger</a></li>
						<li><a href="#section-4">Fear</a></li>
						<li><a href="#section-5">Sadness</a></li>
						<li><a href="#section-6">Disgust</a></li>
						<li><a href="#section-7">Suprise</a></li>
						<li><a href="#section-8">Contempt</a></li>
					</ol>
				</section>


				<section className="justify-content-center d-flex flex-row my-4">
					<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
				</section>


				<section id="section-2" className="offset-anchor">
					<h2 className="my-2">1. Deciphering Joy</h2>

					<div className="myimghor">
						<img src={joyreadingimg} alt="Description of what to look for on a happy face" />
					</div> 

					<p>Happy faces tend to display primary 3 signals:</p>

					<ol>
						<li>Both corners of the mouth are pulled upward</li>
						<li>Teeth may be visible - or not</li>
						<li>Wrinkles show along the side of the eyes</li>
					</ol>
				</section>


				<section className="justify-content-center d-flex flex-row my-4">
					<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
				</section>


				<section id="section-3" className="offset-anchor">
					<h2 className="my-2">2. Deciphering Anger</h2>

					<div className="myimghor">
						<img src={angerreadingimg} alt="Description of what to look for on an angry face" />
					</div> 

					<p>Angry faces tend to display primary 3 signals:</p>

					<ol>
						<li>Sharp wrinkles between the eyebrows</li>
						<li>Lips are tight, either pressed together or open to yell</li>
						<li>Eyes are glaring</li>
					</ol>
				</section>


				<section className="justify-content-center d-flex flex-row my-4">
					<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
				</section>


				<section id="section-4" className="offset-anchor">
					<h2 className="my-2">3. Deciphering Fear</h2>

					<div className="myimghor">
						<img src={fearreadingimg} alt="Description of what to look for on a fearful face" />
					</div> 
	
					<p>Fearful faces tend to display primary 3 signals:</p>

					<ol>
						<li>Raised eyebrows, held close together</li>
						<li>Eyes wide open to capture as much of the scene as possible</li>
						<li>Lips are stretched and the jaw drops open</li>
					</ol>
				</section>


				<section className="justify-content-center d-flex flex-row my-4">
					<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
				</section>


				<section id="section-5" className="offset-anchor">
					<h2 className="my-2">4. Deciphering Sadness</h2>

					<div className="myimghor">
						<img src={sadnessreadingimg} alt="Description of what to look for on a sad face" />
					</div> 

					<p>Sad faces tend to display primary 2 signals:</p>

					<ol>
						<li>The corners of the lips are pulled downward</li>
						<li>The inner corner of the eyebrows are pulled up and closer together</li>
					</ol>
				</section>


				<section className="justify-content-center d-flex flex-row my-4">
					<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
				</section>


				<section id="section-6" className="offset-anchor">
					<h2 className="my-2">5. Deciphering Disgust</h2>

					<div className="myimghor">
						<img src={disgustreadingimg} alt="Description of what to look for on a disgusted face" />
					</div> 

					<p>Disgust faces tend to display primary 2 signals:</p>

					<ol>
						<li>The upper lip is pulled upward</li>
						<li>The upper part of the nose is heavily wrinkled</li>
					</ol>
				</section>


				<section className="justify-content-center d-flex flex-row my-4">
					<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
				</section>


				<section id="section-7" className="offset-anchor">
					<h2 className="my-2">6. Deciphering Surprise</h2>

					<div className="myimghor">
						<img src={surprisereadingimg} alt="Description of what to look for on a surprised face" />
					</div> 

					<p>Surprised faces tend to display primary 3 signals:</p>

					<ol>
						<li>Raised eyebrows, but not close together</li>
						<li>Eyes wide open</li>
						<li>The mouth is wide open in an "O" shape while inhaling air</li>
					</ol>
				</section>


				<section className="justify-content-center d-flex flex-row my-4">
					<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
				</section>


				<section id="section-8" className="offset-anchor">
					<h2 className="my-2">7. Deciphering Contempt</h2>

					<div className="myimghor">
						<img src={contemptreadingimg} alt="Description of what to look for on a contemptful face" />
					</div> 

					<p>Contemptful faces tend to display 1 primary signal:</p>

					<ol>
						<li>Only one corner of the mouth is pulled upward</li>
					</ol>
				</section>


				<section className="justify-content-center d-flex flex-row my-4">
					<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
				</section>


				<h2 className="my-2">What About Shame?</h2>
				<p>Unfortunately, even according to Paul Ekman's research, <a href="https://www.cabinetmagazine.org/issues/31/turner_ekman.php">shame does not have a specific facial expression.</a>  People who feel ashamed are likely going to hide their face, watch their feet or seem to seek to disappear from view.</p>

				<div className="myimghor">
					<img src={shamereadingimg} alt="Small child covering his face with his hands" />
				</div> 


				<section className="justify-content-center d-flex flex-row my-4">
					<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
				</section>


				<h2 className="my-2">Closing Thoughts & Your Next Steps</h2>
				<p>This second module deals with the importance of emotions. Here are other topics you can explore:</p>

				<section className="course-tables-center">

				<CardDeck className="mb-3">
					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={emotionalityimg} variant="top" className="mb-3" />
						<Card.Title className="m-3">What are your 6 core emotions?<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/core-emotions" className="stretched-link"></a>
					</Card>

					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={whyeimatters} variant="top" className="mb-3" />
						<Card.Title className="m-3">How to grow your emotional literacy<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/emotional-literacy" className="stretched-link"></a>
					</Card>
				</CardDeck>
				</section>

			<div className="content-btn-box">
				<a href="/ei-course" className="content-btn">Back to the course<Icon.ArrowRight className="ml-2" /></a>
			</div>
		
			<div className="separator" />
			<div className="separator" />
		</body>
   </div>
  )
}
export default ReadingEmotions;

