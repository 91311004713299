import React from 'react';
import { Card, CardDeck, Image, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';


import elementsoftrustimg from "../images/elements-of-trust.png";
import likabilityexpertisereliability from "../images/likability-expertise-reliability.png";
import trustperformance from "../images/trust-performance.png";
import heartheadhandshake from "../images/heart-head-handshake.jpg";
import whyeimatters from "../images/emotions.png";
import nurturinggrowth from "../images/nurturing-growth.jpg";
import warmthimg from "../images/warmth.jpg";
import teamworkimg from "../images/teamwork.png";
import workshopimg from "../images/workshop.jpg";
import familyimg from "../images/family.jpg";
import quoterooseveltcaringimg from "../images/quote-roosevelt-caring.png";
import quotedaskalgreatnessimg from "../images/quote-daskal-greatness.png";


function ElementsOfTrust() {

  	return (
		<div className="masthead">
			
			<div className="separator"></div>

			<div className="myimghor">
				<img src={likabilityexpertisereliability} alt="The 3 elements of trust" />
			</div>

			<div className="imgcaption">
				<p>Adapted from <a href="https://hbr.org/2019/02/the-3-elements-of-trust" className="intlink">Zenger-Folkman</a></p>
			</div>
			
			
			<h1>The 3 Elements of Trust: Likability, Expertise, and Reliability</h1>

			<p>Research published in Harvard Business Review by <a href="https://hbr.org/2019/02/the-3-elements-of-trust" className="intlink">Zenger-Folkman</a> shows that trust can be broken down into 3 core elements:</p>

			<ol>
				<li><b>Your Likability</b>: Your ability to build mutually beneficial relationships</li>
				<li><b>Your Expertise</b>: How knowledgable and well-informed people perceive you to be</li>
				<li><b>Your Reliability</b>: Your ability to consistently do what you promised</li>
			</ol>

			<div className="notabene">
				<p>The Benefits of Trust</p>
				<ol>
					<li>Higher levels of trust lead to more positive evaluations of leadership effectiveness</li>
					<li>Trust enhances team performance: SEAL Team 6, the elite team in the Navy SEALs, achieves outstanding performance because its members have high levels of trust for each other, not because they are the greatest individual contributors</li>
				</ol>
			</div>

			<div className="myimghor">
				<img src={trustperformance} alt="Trust creates team performance" />
			</div>

			<div className="imgcaption">
				<p>SEAL Team 6 members are selected because they are highly trustworthy, not because they are the best individual performers</p>
			</div>

			<h2>How MyOceanity Will Help You</h2>

			<p><b>MyOceanity focuses on how you can become more likable and reliable</b>, because there are far too many fields of expertise to cover. Your organization and leaders will help you to grow your expertise. Let MyOceanity help you with the other 2 elements of trust and become an even more trustworthy person as a result.</p>

			<p>Make sure you focus on all 3 elements of trust, not only on developing your expertise. Start with likability, for it has the greatest impact of the three.</p>

			<div className="myimg">
				<img src={elementsoftrustimg} alt="How each element influences trust" />
			</div>

			<div className="imgcaption">
				<p>Likability has the highest impact on creating trust, yet you need to leverage all 3 elements to truly realize your potential.</p>
				<p>Adapted from <a href="https://hbr.org/2019/02/the-3-elements-of-trust" className="intlink">Zenger-Folkman</a></p>
			</div>

			<h2>How to Become More Likable With Emotional Intelligence</h2>

			<ul>
				<li>Make sure you are aware of the concerns others have before diving into the work: If you don't, you risk missing out on key ideas because people will be distracted by their thoughts and worries.</li>
				<li>Balance task-orientation with people-orientation: Getting things done is key, yet you need to remember you are working with human beings who need to feel supported and encouraged for their hard work. Being demanding without being supportive is a recipe for disaster.</li>
				<li>Encourage trust-generating behaviors: Whenever you notice someone caring for someone else, point it out. Make the desired positive behavior explicit and show your people they will be appreciated for displaying them. This reward will encourage them to repeat their constructive behavior.</li>
				<li>Give honest feedback on what went well and what can be improved: Make sure emphasize that you are criticizing the work, not the person. Why? Because receiving feedback can feel like a personal attack; make sure your people know it's nothing personal and that you have their personal well-being and development at heart.</li>
				<li>Give away credit for progress and take the blame for mistakes, because the opposite will create a toxic environment</li>
			</ul>


			<div className="myimghor">
				<img src={quoterooseveltcaringimg} alt="Quote by Theodore Roosevelt: People don't care how much you know, until they know how much you care about them." />
			</div>

			<h2>How to Become Perceived as an Expert</h2>

			<ul>
				<li>Refer to previous experiences when making decisions and anticipating potential future problems from occurring</li>
				<li>Share your ideas and opinions with others, asking people how to improve them</li>
				<li>Seek others' ideas and opinions, discuss with people how to improve them</li>
				<li>Contribute your technical skills, knowledge, and experiences to the development of the group, not for personal gains</li>
			</ul>

			<h2>How to Become More Reliable</h2>

			<ul>
				<li>Keep your promises: Don't agree to doing something when you will not be able to follow through</li>
				<li>Be a role model for the behaviors you wish to see in others: Align your actions with your ideals</li>
				<li>Make the time to help people when they need your help: Sometimes, sacrificing time and energy for the sake of others will help you reach your personal objectives, because they may help you with your own responsibilities</li>
			</ul>

			<div className="myimghor">
				<img src={quotedaskalgreatnessimg} alt="Quote by Lolly Daskal: Great leaders inspire greatness in others." />
			</div>

			<div className="story">
				<h3>My Friend's Bootcamp Speech We Still Remember</h3>
				<p>A good friend of mine is a consultant at Accenture. He shared a story with a couple of friends and me one evening, which stuck with us over the years.</p>
				<p>During his first week, all the new hires were invited to a bootcamp event, where they would get to know each other, their leaders, as well as what was expected of them. My friend clearly remembered one speech in particular: A managing director told them that their potential within and outside the firm relied on 3 elements:</p>
				<ol>
					<li>Their likability</li>
					<li>Their expertise</li>
					<li>Their reliability</li>
				</ol>
				<p>He also mentioned that many people tend to focus a majority of their time growing their expertise alone, at the expense of the other two elements. However, those who excelled were trying their best to continuously grow all three elements. Why not follow their lead?</p>
			</div>

			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>Emotional intelligence was included in the top 5 soft skills companies need most in 2020, according to LinkedIn Learning. One key reason why it matters is, that close relationships build trust. There are different ways you can deepen your understanding of emotional intelligence and see how it can help you improve your life and the lives of those around you:</p>

			<section className="course-tables-center">
			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={heartheadhandshake} variant="top" className="mb-3" />
					<Card.Title className="m-3">What is emotional intelligence?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/what-is-ei" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={whyeimatters} variant="top" className="mb-3" />
					<Card.Title className="m-3">Why emotional intelligence matters<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/why-ei-matters" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={nurturinggrowth} variant="top" className="mb-3" />
					<Card.Title className="m-3">Grow your emotional intelligence skills<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/grow-your-ei" className="stretched-link"></a>
				</Card>
			</CardDeck>


			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={teamworkimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Emotional intelligence at work<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ei-benefits-work" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={workshopimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Strategies for emotional intelligence<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/strategies-for-ei" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={familyimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Emotional intelligence at home (Coming soon)<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ei-benefits-home" className="stretched-link"></a>
				</Card>
			</CardDeck>
			</section>
			
                <div className="content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course<Icon.ArrowRight className="ml-2" /></a>
                </div>

			<div className="separator"></div>
			<div className="separator"></div>
			
   		</div>
  	)
}
export default ElementsOfTrust;

