import React from "react";
import { Table, Button, Card, CardDeck, Image, Carousel, Row } from 'react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import christraits from "./images/chris-ocean-traits.png";
import chriscomparisons from "./images/chris-traits-comparisons.png";
import chriscircle from "./images/chris-circle-picture.png"
import emotionscollage from "./images/emotions-collage.jpg";
import elementsofei from "./images/elements-of-ei.png";
import linkedinskills from "./images/linkedin-2020-skills.jpg";
import ReactMarkdown from "react-markdown";

export default function Home() {

    const { loginWithRedirect } = useAuth0()
    const { i18n } = useTranslation();

    //translated images
    if(i18n.language != 'en'){
        const christraits =import("./images/chris-ocean-traits-fr.png");
        const chriscomparisons=import("./images/chris-traits-comparisons-fr.png");
        const chriscircle =import("./images/chris-circle-picture.png")
        const emotionscollage =import( "./images/emotions-collage.jpg");
        const elementsofei =import("./images/elements-of-ei.png");
        const linkedinskills =import("./images/linkedin-2020-skills.jpg");
    }else{
    }


    ReactGA.initialize('UA-188439840-1',{
        debug : false,
        gaOptions :{
            siteSpeedSampleRate : 100
        }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);

    const iconList = Object
	.keys(Icons)
	.filter(key => key !== "fas" && key !== "prefix" )
	.map(icon => Icons[icon])
  
    library.add(...iconList)
      
    return (
        <div>
    {/*
            <MyOceanityNav fixed="top" />
    */}
            <div className="overlay">
                
                <section className="title">
        
                    <div className="separator" />
                    
                    <h1 className="mb-3">{i18n.t('Realize Your Potential')}</h1>
                    <h2>{i18n.t('grow-your-emotional-intelligence-and-productivity-skills')}</h2>

                    <div className="separator" />

                </section>

                <section className="white-section">
                    
                    <div className="separator" />
                    <div className="separator" />

                    <h2>{i18n.t('explore-3-powerful-tools')}</h2>
                    
                    <div className="separator" />
                    <div className="separator" />


                    <CardDeck>
                        
                        <Card className="mb-4 mycardwrapper">
                            <Card.Body className="text-center mycard">
                                <a href="/ei-course"> <FontAwesomeIcon icon="chalkboard-teacher" size="6x" className="card-icon" /> </a>
                                <Card.Title><a href="/ei-course" className="cardlink stretched-link"><h5 className="cardtitle card-btn">{i18n.t('online-course')}</h5></a></Card.Title>
                                <Card.Text className="cardtext">
                                    <div>{i18n.t('join-our-learning-and-development-program')}</div>
                                    <div>{i18n.t('study-at-your-own-pace-anytime-anywhere-without-any-distractions')}</div>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className="mb-4 mycardwrapper">
                            <Card.Body className="text-center mycard">
                                <a href="/user/premium/my-assessments"> <FontAwesomeIcon icon="clipboard-check" size="6x" className="card-icon" /> </a>
                                <Card.Title><a href="/user/premium/my-assessments" className="cardlink stretched-link"><h5 className="cardtitle card-btn">{i18n.t('assess-your-personality-traits')}</h5></a></Card.Title>
                                <Card.Text className="cardtext">
                                    <div>{i18n.t('who-are-you-what-makes-you-tick')}</div>
                                    <div>{i18n.t('how-are-your-personality-traits-affecting-others-how-can-you-improve')}</div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    
                        <Card className="mb-4 mycardwrapper">
                            <Card.Body className="text-center mycard">
                                    <a href="/user/premium/send-invite"> <FontAwesomeIcon icon="users" size="6x" className="card-icon" /> </a>
                                    <Card.Title><a href="/user/premium/send-invite" className="cardlink stretched-link"><h5 className="cardtitle card-btn">{i18n.t('invite-and-compare-to-others')}</h5></a></Card.Title>
                                    <Card.Text className="cardtext">
                                        <div>{i18n.t('how-similar-and-different-are-you')}</div>
                                        <div>{i18n.t('how-can-you-better-understand-and-interact-with-each-other')}</div>
                                    </Card.Text>
                            </Card.Body>
                        </Card>

                    </CardDeck>

                    <Row>
                    <Card className="mb-4 mycardwrapper">
                        <Card.Body className="text-center mycard">
                            <Card.Title><h3>{i18n.t('1-discover-your-personality-traits')}</h3></Card.Title>

                            <Card.Img src={christraits} alt="Chris' personality traits" variant="top" className="myimghor" />
                            <Card.Text className="cardtext" >

                                <p className="text-center"><i>{i18n.t('chris-personality-traits')}</i></p>
                                
                                <div>{i18n.t('your')} <a href="/ocean-personality-traits">{i18n.t('personality-traits')}</a> {i18n.t('influence-your-preferences-motivations-and-behaviors-which-determine-how-trustable-likable-and-reliable-others-perceive-you-to-be')} <b>{i18n.t('when-you-start-to-understand-the-world-within-then-the-world-around-you-starts-to-make-more-sense')}</b></div>

                                    <div className="content-btn-box">
                                        <a href="/user/premium/my-assessments" className="content-btn">{i18n.t('discover-your-traits')}</a>
                                    </div>

                                <div  className="quote">
                                    <div><i>{i18n.t('personality-is-every-individuals-signature-trait-there-will-never-be-anyone-like-you-therefore-hold-on-to-what-makes-you-so-special-as-a-person-a-character-and-a-human-being')}</i></div>
                                    
                                    <div><b>{i18n.t('unknown-author')}</b></div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Row>

                    <Row>
                        <Card className="mb-4 mycardwrapper">
                            <Card.Body className="text-center mycard">
                                <Card.Title><h3>{i18n.t('2-compare-with-your-family-friends-and-coworkers')}</h3></Card.Title>
                                <Card.Img src={chriscomparisons} alt="Comparing Chris' personality traits to Feffef's" variant="top" className="myimghor" />
                                <Card.Text className="cardtext" >
                                    
                                    <div className="imgcaption">
                                        <div>{i18n.t('comparing-chris-personality-traits-to-feffefs')}</div>
                                    </div>

                                    <div>{i18n.t('one-of-the-best-ways-to-grow-your-emotional-intelligence-and-to-build-lasting-mutually-beneficial-relationships-is-to-understand-how-your-personality-traits-differ-from-those-of-people-you-know-and-how-you-can-become-stronger-together-because-of-your-differences-less-than-b-greater-than-why-guess-about-peoples-traits-when-they-can-directly-tell-you-who-they-are-themselves-less-than-b-greater-than')}</div>

                                    <div className="separator" />

                                    <a href="/user/premium/send-invite" className="important-link">{i18n.t('invite-others-and-compare-your-traits')} <FontAwesomeIcon icon="arrow-right" /></a>

                                    <div className="separator" />

                                    <div className="quote">
                                        <div><i>{i18n.t('strength-lies-in-differences-not-in-similarities')}</i></div>
                                        <div><b>Stephen Covey</b></div>
                                    </div>

                                    <div className="quote">
                                        <div><i>{i18n.t('it-is-not-our-differences-that-divide-us-it-is-our-inability-to-recognize-accept-and-celebrate-those-differences')}</i></div>
                                        <div><b>Audre Lorde</b></div>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                    <Row>
                        <Card className="mb-4 mycardwrapper">
                            <Card.Body className="text-center mycard">
                                <Card.Title><h3>{i18n.t('3-grow-your-emotional-intelligence-with-our-online-course')}</h3></Card.Title>
                                
                                <div className="myimg align-center">
                                    <Card.Img src={emotionscollage} alt="We all experience a rich palette of emotions" variant="top" />
                                </div>

                                <Card.Text className="cardtext" >
                                    
                                    <p className="text-center img-caption"><i>{i18n.t('we-all-experience-a-rich-palette-of-emotions')}</i></p>
                                    <p className="text-center img-caption"><i>{i18n.t('Photo by')} <a href="https://www.pexels.com/@olly?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels">Andrea Piacquadio</a> {i18n.t('from')} <a href="https://www.pexels.com/photo/collage-photo-of-woman-3812743/?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels">Pexels</a></i></p>
                                
                                    <div>{i18n.t('emotional-intelligence-makes-people-more-likable-and-trustworthy-it-makes-life-at-home-and-at-work-more-enjoyable-and-was-included-in-the-top-5-skills-you-need-to-learn-according-to')} <a href="https://learning.linkedin.com/blog/top-skills/the-skills-companies-need-most-in-2020and-how-to-learn-them">LinkedIn Learning</a>: </div>

                                    <div className="separator" />

                                    <a href="/ei-course" className="important-link">{i18n.t('discover-the-course')} <FontAwesomeIcon icon="arrow-right" /></a>

                                    <div className="separator" />

                                    <Table striped bordered hover>
                                        <thead>
                                            <tr><th className="course-table-header">{i18n.t('you-may-be-interested-in')}</th></tr>
                                        </thead>

                                        <tbody className="course-table-body">
                                            <tr> <td> <a href="/what-is-ei">{i18n.t('what-is-emotional-intelligence')}</a> </td> </tr>
                                            <tr> <td> <a href="/why-ei-matters">{i18n.t('why-does-emotional-intelligence-matter-in-our-lives')}</a> </td> </tr>
                                            <tr> <td> <a href="/grow-your-ei">{i18n.t('how-can-you-grow-your-emotional-intelligence-skills')}</a> </td> </tr>
                                            <tr> <td> <a href="/elements-of-trust">{i18n.t('the-3-elements-of-trust-likability-expertise-reliability')}</a> </td> </tr>
                                            <tr> <td> <a href="/ei-benefits-work">{i18n.t('benefits-of-emotional-intelligence-at-work')}</a> </td> </tr>
                                            <tr> <td> <a href="/core-emotions">{i18n.t('what-are-our-core-emotions')}</a> </td> </tr>					
                                            <tr> <td> <a href="/emotional-literacy">{i18n.t('how-can-you-grow-your-emotional-literacy')}</a> </td> </tr>
                                            <tr> <td> <a href="/ikigai">{i18n.t('what-makes-a-life-worth-living-what-is-ikigai')}</a> </td> </tr>
                                        </tbody>
                                    </Table>

                                    <div className="separator" />

                                    <div className="myimg">
                                        <img src={linkedinskills} alt="The skills most companies need most in 2020" />
                                    </div>

                                    <div className="imgcaption">
                                        <div>{i18n.t('why-not-start-growing-your-emotional-intelligence-today')}</div>
                                    </div>

                                    <div className="quote">
                                        <div><i>{i18n.t('people-will-forget-what-you-say-they-will-forget-what-you-do-but-they-will-never-forget-how-you-made-them-feel')}</i></div>
                                        <div><b>Maya Angelou</b></div>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                    <div className="separator" />

                    <h2>{i18n.t('what-makes-myoceanity-different')}</h2>

                    <ol>
                        <li><b>{i18n.t('invite-and-compare')}</b> {i18n.t('you-can-easily-invite-people-you-know-and-compare-your-personality-traits-to-theirs-gaining-a-better-understanding-of-how-you-can-better-interact-with-each-other-through-our-personalized-insights-for-family-friends-and-coworkers')}</li>
                        <li><b>{i18n.t('we-continuously-learn-and-develop')}</b> {i18n.t('we-publish-and-review-content-every-week-to-give-you-the-latest-and-greatest-insights-plus-we-integrate-insights-from-other-personality-traits-models-such-as-disc-mbti-and-hexaco-into-our-own-because-people-love-them')}</li>
                        <li><b>{i18n.t('your-privacy-is-respected')}</b> {i18n.t('none-of-your-personal-information-including-your-results-are-shared-with-any-third-party-organization-no-ads-whatsoever-only-learning-and-development')}</li>
                        <li><b>{i18n.t('we-give-away-10-of-our-profits-to-charities-striving-to-preserve-and-improve-human-life')}</b> {i18n.t('this-way-your-support-supports-many-others-as-well')}</li>
                        <li><b>{i18n.t('emotional-intelligence-ready')}</b> {i18n.t('your-personal-report-is-designed-to-help-you-grow-your-emotional-intelligence-by-leveraging-its-4-elements')}</li>
                    </ol>

                    <div className="myimghor">
                        <img src={elementsofei} alt="The 4 elements of emotional intelligence" />
                    </div>

                    <div className="imgcaption">
                        <div>{i18n.t('the-4-elements-of-emotional-intelligence-we-will-discuss')}</div>
                    </div>

                </section>

                <section className="blue-section" >

                    <div className="separator" />

                    <h2>{i18n.t('trusted-by-over-50-people-already')}</h2>

                    <div className="home-carousel">
        
                        <Carousel interval={18000} >

                            <Carousel.Item style={{'height':"500px"}} >
                                <Carousel.Caption className="carousel-caption">
                                    <div><i>{i18n.t('we-invited-chris-for-a-teambuilding-workshop-in-december-2019-to-learn-about-emotional-intelligence-and-how-it-could-help-us-better-understand-and-work-with-each-other')}</i></div>
                                    <div><i>{i18n.t('i-quickly-noticed-an-encouraging-change-in-how-my-people-interacted-with-each-other-and-with-others-outside-the-team-it-also-helps-me-with-how-i-lead-and-motivate-each-of-them-day-after-day')}</i></div>
                                    <div><i>{i18n.t('we-keep-referring-to-what-chris-taught-us-about-what-makes-each-of-us-tick-which-helps-us-to-engage-in-productive-conversations-rather-than-destructive-conflicts')}</i></div>
                                    <div><i>{i18n.t('i-appreciate-the-new-perspectives-chris-taught-us-and-wholeheartedly-recommend-him-to-anyone-seeking-to-grow-their-emotional-intelligence-and-build-lasting-mutually-beneficial-relationships')}</i></div>
                                    <h4>{i18n.t('andi-fluetsch-or-team-leader-johnson-and-johnson')}</h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                                
                            <Carousel.Item style={{'height':"500px"}} >
                                <Carousel.Caption>
                                    <div><i>{i18n.t('we-invited-chris-to-speak-at-different-events-and-participants-were-always-delighted-he-has-a-way-to-connect-and-engage-with-his-audience-which-makes-the-experience-even-more-enriching')}</i></div>
                                    <div><i>{i18n.t('we-appreciated-how-he-clearly-and-simply-guided-people-to-consider-new-perspectives-to-improve-their-lives-and-careers')}</i></div>
                                    <div><i>{i18n.t('anyone-looking-for-a-caring-and-captivating-speaker-should-definitely-contact-chris')}</i></div>
                                    <h4>Claude Duc | President, IIBA Geneva</h4>
                                </Carousel.Caption>
                            </Carousel.Item>

                        </Carousel>
        
                    </div>
                    
                    <div className="separator" />

                </section>

                <section className="white-section" >

                    <div className="separator" />
                    <div className="separator" />

                    <h2>{i18n.t('join-in-3-simple-steps')}</h2>

                    <div className="separator" />
                    <div className="separator" />

                    <CardDeck>

                        <Card className="mb-4 mycardwrapper">
                            <Card.Body className="text-center mycard">
                                <FontAwesomeIcon icon="user-plus" size="6x" className="card-icon" />
                                <Card.Title><Button className="card-btn-signup" onClick={() => loginWithRedirect({ui_locales: i18n.language})}><div>{i18n.t('create-an-account')}</div></Button></Card.Title>
                                <Card.Text className="cardtext">
                                    <div>{i18n.t('your-personal-and-comparison-profiles-will-be-saved-in-your-account')}</div>
                                    <div>{i18n.t('you-can-take-as-many-as-you-want')}</div>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className="mb-4 mycardwrapper">
                            <Card.Body className="text-center mycard">
                                <FontAwesomeIcon icon="shopping-cart" size="6x" className="card-icon" />
                                <Card.Title><h5 className="cardtitle card-btn-disabled" >{i18n.t('activate-your-account')}</h5></Card.Title>
                                <Card.Text className="cardtext">
                                    <div>{i18n.t('you-will-be-redirected-to-our-payment-provider-during-your-first-assessment')}</div>
                                    <div><b>{i18n.t('usd-20-for-lifetime-access')}</b></div>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className="mb-4 mycardwrapper">
                            <Card.Body className="text-center mycard">
                                <FontAwesomeIcon icon="thumbs-up" size="6x" className="card-icon" />
                                <Card.Title><h5 className="cardtitle card-btn-disabled">{i18n.t('thats-it')}</h5></Card.Title>
                                <Card.Text  className="cardtext">
                                    {i18n.t('enjoy-the-full-myoceanity-experience-learn-grow-and-realize-your-potential')}
                                    <span className="white-text"><div> Hello</div></span>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </CardDeck>
                    
                    <div className="separator" />

                </section>

                <section className="blue-section" >
                    
                    <div className="separator" />
                    <h2>{i18n.t('get-to-know-us')}</h2>

                    <div className="two-columns">
                        <div className="chris-img">
                            <a href="/who-we-are" className="chris-img-a">
                            <Image src={chriscircle} alt="Chris Picture Circle"  />
                            </a>
                        </div>
                        
                        <div>{i18n.t('chris-dreamed-of-a-world-where-people-could-thrive-and-take-care-of-each-other-by-growing-their-emotional-intelligence-and-dan-made-this-dream-become-a-reality')}
                        
                        <div className="separator" />
                        
                        <a href="/who-we-are" className="intlink">{i18n.t('learn-more-about-who-we-are-and-what-drives-us')}</a> </div>
                        
                        <div className="separator" />
            
                    </div>

                </section>

                <CookieConsent expires={150} >{i18n.t('myoceanity-uses-cookies-to-enhance-your-user-experience')}</CookieConsent>

            </div>            

        </div>
    )
}