import React from 'react';
import { Accordion, Card, Table } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown'


import newspaperimg from '../images/newspaper.jpg';
import adventureimg from '../images/adventure.jpg';
import carefreeimg from '../images/carefree.jpg';
import cleandeskimg from '../images/cleandesk.jpg';
import readingimg from '../images/reading.jpg';
import partyimg from '../images/party.jpg';
import trustimg from '../images/trust.jpg';
import disagreementimg from '../images/disagreement.jpg';
import worry1img from '../images/worry-1.jpg';
import worry3img from '../images/worry-3.jpg';
import relaxedimg from '../images/relaxed.jpg';
import Ospectrum from '../images/openness-spectrum.png';
import Cspectrum from '../images/conscientiousness-spectrum.png';
import Espectrum from '../images/extroversion-spectrum.png';
import Aspectrum from '../images/agreeableness-spectrum.png';
import Nspectrum from '../images/nervosity-spectrum.png';
import conversation2 from '../images/conversation2.jpg';
import conversation3 from '../images/conversation3.jpg';
import conversation4 from '../images/conversation4.jpg';
import conversation5 from '../images/conversation5.jpg';
import conversation6 from '../images/conversation6.jpg';
import conversation7 from '../images/conversation7.jpg';
import conversation8 from '../images/conversation8.jpg';
import conversation9 from '../images/conversation9.jpg';
import highfive from '../images/highfive.jpg';
import communicationtriangle from '../images/communication-triangle.png';
import omismatch from '../images/openness-mismatch.png';
import cmismatch from '../images/conscientiousness-mismatch.png';
import emismatch from '../images/extroversion-mismatch.png';
import amismatch from '../images/agreeableness-mismatch.png';
import nmismatch1 from '../images/nervosity-mismatch-1.png';
import nmismatch2 from '../images/nervosity-mismatch-2.png';



function OpennessToExperience(props: any) {

	const { i18n } = useTranslation();


  return (
   <div className="masthead">

			<div className="content-btn-box">
				<a href="/user/premium/my-assessments" className="content-btn">Discover YOUR Traits<Icon.ArrowRight className="ml-2" /></a>
			</div>


		<h2 id="Otitle">Openness To Experience</h2>

		<div className="myimghor">
			<img src={Ospectrum} alt="Openness spectrum" />
		</div>

		<div className="imgcaption">
			The openness to experience spectrum
		</div>

		<p>Openness to experience describes:</p>

		<ul>
			<li>How much do you seek and appreciate new ideas and experiences?</li>
			<li>How curious are you?</li>
			<li>How creative and imaginative are you?</li>
			<li>How much do you appreciate variety and originality?</li>
			<li>How receptive to change are you?</li>
		</ul>

		<h3>High-Openness Description</h3>

		<div className="myimghor">
			<img src={adventureimg} alt="High openness adventure" />
		</div>

		<div className="imgcaption">
			What will be your next adventure? <br />
			Photo by <a href="https://www.pexels.com/@alex-tim-249063" >Alex Tim</a> from <a href="https://www.pexels.com/photo/woman-hiking-1183986/" >Pexels</a>
		</div>

		<p>You tend to be excited about discovering new activities, trying new experiences, learning new skills and exploring new opportunities. In other words, you usually enjoy the thrill of the unknown and challenging the status quo. What got you here, will not get you there. Why not go on an adventure?</p>

		<div className="quote">
            <p><i>"A mind is like a parachute. It doesn't work if it is not open."</i></p>
            <p><b>- Frank Zappa</b></p>
        </div>

		<h4>How You May Be Perceived</h4>
		<p>Because of your intense curiosity and willingness to experiment and learn as much as possible, others may perceive you as unfocused, unrealistic or as a daydreamer.</p>
		
		<h4>Self-development advice</h4>
		<p>Take the time to explore your passions and interests while staying aware that others - especially people with lower levels of openness to experience - may feel like you are going all over the place. Work on staying focused in the present moment and plan your experiences.</p>


		<h3>The 6 Openness to Experience Facets</h3>

		<p>Openness to experience can be broken down into six facets which explore how sensible and curious you are to your inner worlds and the world around you:</p>

		<ol>
			<li><b>Openness to ideas</b> describes your intellectual curiosity.</li>
			<li><b>Openness to fantasy</b> describes your receptivity to the inner world of imagination. Imagination is extremely useful to step outside of yourself and reflect on how other people may experience and perceive you.</li>
			<li><b>Openness to aesthetics</b> describes your appreciation of art and beauty.</li>
			<li><b>Openness to emotionality</b> describes your ability and willingness to explore your inner emotions and feelings.</li>
			<li><b>Openness to adventure</b> and action describes your openness to new experiences on a practical level.</li>
			<li><b>Openness to values</b> describes your readiness to challenge your own values and those of authority figures. </li>
		</ol>

		<br/> <br/>

		<section className="accordion-width">
		<Accordion>
			<Card className="border-0">
				<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
				<b className="mr-3">{i18n.t('Discover Your 6 Openness to Experience Facets')}</b><Icon.ChevronDown className="ml-2"/>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body>
						
					<h4 id="Oideas">O1 | High-Openness To Ideas</h4>
					<p>Openness to ideas describes your intellectual curiosity.</p>
					
					<p>You enjoy thinking about abstract concepts and things, and can handle a lot of information at once, which helps you to think outside the box and be more inventive, original, and innovative than other people. You love to read challenging material and have a rich vocabulary.</p>
					<p>You are more interested in intellectual pursuits than anything else and seek out to understand the patterns of the universe around you. You are an experimental person who likes to test their ideas.</p>

					<div className="quote">
						<p><i>"An invasion of armies can be resisted, but not an idea whose time has come."</i></p>
						<p><b>- Victor Hugo</b></p>
					</div>

					<h4 id="Ofantasy">O2 | High-Openness To Fantasy</h4>
					<p>You have a vividly imaginative nature and enjoy getting lost in thought. Time tends to pass quickly when you reflect on things and start daydreaming.</p>

					<div className="quote">
						<p><i>"Fantasy is escapist, and that is its glory."</i></p>
						<p><b>- J.R.R. Tolkien</b></p>
					</div>

					<h4 id="Oaesthetics">O3 | High-Openness to Aesthetics</h4>
					<p>You believe in the importance of art and are sensitive to the beauty of the world that surrounds you. Your artistic nature makes you enjoy all expressions of art and helps you to curate your sophisticated appearance and environment. You sometimes crave to express yourself creatively.</p>
					<p>You enjoy the beauty of nature and see beauty in things that others might not notice, such as birds or flowers. Beautifully designed machines and constructions may move you.</p>

					<div className="quote">
						<p><i>"The world always seems brighter when you've just made something that wasn't there before."</i></p>
						<p><b>- Neil Gaiman</b></p>
					</div>

					<h4 id="Oemotionality">O4 | High-Openness To Emotionality</h4>
					<p>You tend to experience your emotions intensely and are able to feel and understand the others’ emotions well. You try to understand yourself and enjoy examining yourself and your life through deep introspection.</p>
					<p>You are passionate about the causes you want to advance and speak of them with a contagious enthusiasm that inspires others.</p>

					<div className="quote">
						<p><i>"Nothing great in the world has ever been accomplished without passion."</i></p>
						<p><b>- George Hegel</b></p>
					</div>

					<h4 id="Oadventure">O5 | High-Openness To Adventure</h4>
					<p>You prefer variety to routine and have a wide range of interests. You are focused on novelty and tackling new challenges to satisfy your adventurous nature. You are usually willing and eager to try new things and visit new places.</p>

					<div className="quote">
						<p><i>"Jobs fill your pockets, but adventures fill your soul."</i></p>
						<p><b>- Jaime Lyn</b></p>
					</div>

					<h4 id="Ovalues">O6 | High-Openness To Values</h4>
					<p>You tend to be an idealist and believe that there is no absolute right or wrong. You are more tolerant of other people’s beliefs, behaviors, and social diversity. You appreciate discussing diverse views. You may be more interested in discussions of more liberal ideas than conservative ones and following a more unconventional path.</p>

					<div className="quote">
						<p><i>"Anger and intolerance are the enemies of correct understanding."</i></p>
						<p><b>- Mahatma Gandhi</b></p>
					</div>

					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
		</section>

		<br/> <br/>


		<h3>Low-Openness Description</h3>

		<div className="myimghor">
			<img src={newspaperimg} alt="Low Openness Routine" />
		</div>

		<div className="imgcaption">
			<p>What are your favorite routines?</p>
			<p>Photo by <a href="https://www.pexels.com/@elijahsad" >Elijah O'Donnell</a> from <a href="https://www.pexels.com/photo/black-man-reading-a-newspaper-3473492/" >Pexels</a></p>
		</div>

		<p>You tend to prefer sticking to your habits and routines, enjoying the comfort of what is already known. Why change what works well?</p>

		<div className="quote">
			<p><i>“Good habits are hard to develop but easy to live with; bad habits are easy to develop but hard to live with. The habits you have and the habits that have you will determine almost everything you achieve or fail to achieve.”</i></p>
			<p><b>- Brian Tracy</b></p>
        </div>

		<h4>How You May Be Perceived</h4>
		<p>Your cautious nature may make others - especially people with higher levels of openness to experience - perceive you as closed-minded or inflexible.</p>
		
		<h4>Self-development advice</h4>
		<p>You need to cherish your habits because they are part of who you are and contribute to your comfort and well-being. That being said, you will sometimes also need to go outside of your comfort zone and discover new activities or ways of doing things. Because <b>you never know what new activity today will become one of your favorite traditions tomorrow</b>.</p>

		<h3>The 6 Openness to Experience Facets</h3>

		<p>Openness to experience can be broken down into six facets which explore how sensible and curious you are to your inner worlds and the world around you:</p>

		<ol>
			<li><b>Openness to ideas</b> describes your intellectual curiosity.</li>
			<li><b>Openness to fantasy</b> describes your receptivity to the inner world of imagination. Imagination is extremely useful to step outside of yourself and reflect on how other people may experience and perceive you.</li>
			<li><b>Openness to aesthetics</b> describes your appreciation of art and beauty.</li>
			<li><b>Openness to emotionality</b> describes your ability and willingness to explore your inner emotions and feelings.</li>
			<li><b>Openness to adventure</b> and action describes your openness to new experiences on a practical level.</li>
			<li><b>Openness to values</b> describes your readiness to challenge your own values and those of authority figures. </li>
		</ol>

		<br/> <br/>

		<br/> <br/>
		
		<section className="story">
		<h3>Example Of Openness Misunderstandings</h3>

		<h4>At Work</h4>
		<div className="myimghor">
			<img src={omismatch} alt="Openness to experience mismatch" />
		</div>
		<p><b>Pauline, a High-Openness manager</b>, has recently taken over Patricia and Gary’s team. Together, the team has discussed how they can improve how they work as individual contributors and as a team.</p>
		<p>Unfortunately, Pauline did not realize that <b>Gary, a strongly Low-Openness person</b>, feels overwhelmed by all the changes she wants to implement. Gary has been with the company for over twenty years and enjoys the routines he created around his responsibilities and his workflow.</p>
		<p>Frustrated by Pauline’s unwillingness to understand how this situation affects him, he starts shutting down and struggles to adjust. Over time, their relationship sours, and his performance slowly declines.</p>
		
		<h5>What could Pauline have done differently? </h5>
		<p>She should have realized which of Gary's routines were helping him and others be more productive and which were counterproductive. Then, she could discuss with him which of the new tasks she wanted to implement could leverage his strengths, without creating too much uncertainty and novelty in his work.</p>
		
		<h5>What about Gary? </h5>
		<p>He needs to understand Pauline's desire to help Patricia and him do an even better job at contributing to the company's mission. She is not advocating for change for change's sake, but to make sure they stay engaged in helping their clients. He also needs to take a critical look at how new tools and systems could help reduce his workload, rather than continuing working long hours to do repetitive tasks himself. This way, the free time he would gain could be used to do the work he actually loves doing.</p>

		<div className="separator-large" />


		<h4>With Family and Friends</h4>
		<div className="myimghor">
			<img src={omismatch} alt="Openness to experience mismatch" />
		</div>
		
		<p>Patrick, a High-Openness, adventurous man, is happily married to Gwen, a creature of habit. Every week, Patrick suggests a new and exciting activity they could try together. Usually, he feels disappointed because Gwen would rather do the activities they've cherished since they met.</p>
		<p>His need to experience novelty and discover new places leaves him craving for more and upset with her inflexibility. Meanwhile, Gwen sometimes feels harassed by his endless pleas for adventure. She wonders if he understand how much their beloved habits means to her? Over time, they start growing apart from each other.</p>

		<h5>How could Patrick convince Gwen without rushing her?</h5>
		<p>He could discuss with her why she loves some of their activities and not others, to understand whether they can find new ones which would satisfy both his need for novelty and her need for stability. Perhaps Gwen is scared by some of the audacious adventures Patrick finds exciting? He could also reflect on how much some of their routines mean to him and see how they could spend more quality time engaged in these activities.</p>
		
		<h5>What about Gwen?</h5>
		<p>She could ask him to share which new activities he is truly looking forward to trying, and reflect on which ones she would also enjoy doing, based on her deep understanding of what makes her tick. After all, they've enjoyed hiking through the south of France last summer, why not try exploring the Swiss mountains and sleeping in a hut? This way, he could go on an adventure in a new place, while she could do something she relishes as well. After all, Patrick suggested the French-hiking-experience and he was right!</p>
		
		<div className="separator-large" />
		</section>


		<h3>How To Work Well With High-Openness People</h3>
		<div className="myimghor">
			<img src={conversation8} alt="Working with high-openness individuals" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@cottonbro" >cottonbro</a> from <a href="https://www.pexels.com/photo/man-and-woman-leaning-on-table-staring-at-white-board-on-top-of-table-having-a-meeting-3205570/" >Pexels</a></p>
		</div>

		<h4>At Work</h4>
		<p>Ask <b>what new skill sets or experiences they want to acquire</b> and assign them on relevant projects that match their interests and development plans. Encourage their desire to learn new things and give them enough time and resources to work on expanding their skill sets. </p>
		<p>Emphasize the benefits or opportunities when pitching them to try something new. Then, let them share their thoughts, ideas or concerns to build on your proposal. Let them internalize them and make them their own.</p>


		<h4>With Family and Friends</h4>
		<p>Ask what new experiences they want to explore and reflect on which ones you would also enjoy doing with them. Encourage their desire to learn new things and give them enough time and resources to become better at them.</p>
		<p>Ask which new destinations they want to discover and find which ones match with your personal interests. Where could you travel and still be able to engage in activities you enjoy doing? For instance, if you shun music festivals but enjoy art galleries, ask them which ones you could visit together. Search for common interests!</p>
		<p>Ask them to share about their passions and emotions, as they are likely more attuned to them than other people.</p>


		<h3>How To Work Well With Low-Openness People</h3>

		<div className="myimghor">
			<img src={conversation3} alt="Working with low-openness individuals" />
		</div>

		<div className="imgcaption">
			<p>Photo by <a href="https://www.pexels.com/@mentatdgt-330508" >mentatdgt</a> from <a href="https://www.pexels.com/photo/man-sitting-on-chair-in-front-of-woman-2029619/" >Pexels</a></p>
		</div>

		<h4>At Work</h4>
		<p>Look at and ask about their previous and favorite experiences, and assign them on projects that <b>build on their existing skill sets and interests</b>. Focus on what they already know and are comfortable with. </p>
		<p>Encourage them to <b>deepen their areas of expertise</b>, because assigning them to too many new, unfamiliar tasks may scare them and impact their well-being and performance.</p>
		<p>Change management can be especially challenging for Low-Openness people. In their minds, you cannot spell <b><i>CHA</i></b>LLE<b><i>NGE</i></b> without <b><i>CHANGE</i></b>. They are likely going to apprehend changes forced in their lives.</p>
		<p>To make things easier on them, <b>emphasize what is not changing</b>. Then, reassure them by sharing all the facts that explain why change is necessary and how they can benefit from it. How can this change improve their daily lives and work? The goal is to minimize the change or the efforts they will have to put in as a result of the change.</p>


		<h4>With Family and Friends</h4>
		<p>Ask about their favorite experiences and try to understand why they mean so much to them. Then, reflect on which activities you would also enjoy doing and let them lead you through them!</p>
		<p>Ask them to share about what interests them the most and search for gifts which would enable them to deepen their knowledge and skills.</p>
		<p>When asking them to try something new, empasize what is close to what they already know and love and discuss with them how they could enjoy trying this variation. Perhaps it will become their new favorite!</p>


		<h3>Questions To Ask To Evaluate Openness To Experience</h3>
		<h4>Have you ever tried ____? <br /> Would you?</h4>
		<p><b>High-Openness individuals</b> will likely answer with indifference if they already have, or with great curiosity if they haven’t, asking you questions to know more about the experience.</p>
		<p><b>Low-Openness individuals</b> will likely answer with enthusiasm if the experience became part of their routines, or with reluctance if they didn’t, or with apprehension from a new experience that makes them uncomfortable.</p>

		<h4>What’s your favorite ____? <br /> What else do you like?</h4>
		<p><b>High-Openness individuals</b> will likely have a broader range of experiences to share, investigating about what others they might have missed and will have to experience for themselves in the future.</p>
		<p><b>Low-Openness individuals</b> will likely list only a few - if not just the one - preferences, emphasizing how this is their favorite and how they may not want to be disappointed by other experiences.</p>

		<h4>What’s your plan for your next holiday? <br /> Have you already been there?</h4>
		<p><b>High-Openness individuals</b> will likely talk about new places they want to visit or which will provide them with the opportunity to experience new and exciting adventures.</p>
		<p><b>Low-Openness individuals</b> will likely talk about places they have already visited and are fond of, because they feel familiar. They may also mention their favorite spots and activities they usually do there.</p>

		<div className="separator" />
		<div className="separator" />
		<hr/>

		<h2>Closing Thoughts & Your Next Steps</h2>
		<p>Your OCEAN personality traits shape your life, and your life shapes your personality traits. In this module, you will learn more about how you can better understand the OCEAN personality traits model and how to take action.</p>
		<p>Feel free to explore the following topics:</p>

		<Table striped borderless hover responsive className="course-table">
			<thead>
				<tr><th className="course-table-header">Module 3: OCEAN Personality Traits & Emotional Intelligence</th></tr>
			</thead>
			<tbody className="course-table-body">
				<tr> <td> <a href="/myoceanity-and-ei">How can you use MyOceanity to build your emotional intelligence?</a></td> </tr>
				<tr> <td> <a href="/oceanology">OCEANology</a></td> </tr>
			</tbody>
		</Table>

                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
		
		<div className="separator" />
		<div className="separator" />
		
   </div>
  )
}

export default OpennessToExperience;

