import React from 'react';
import Button from 'react-bootstrap/Button';


 function SupportAndDonations(props: any) {
	   
  return (
   <div>
		<h1>Donate and Support Us</h1>

		<h2>How are we giving back?</h2>
		<p>Contributing to the advancement of healthcare and education around the world is what drives us. </p>
		<p>It’s part of our Mission, to preserve and improve human life, doing well by doing good.</p>
		<p>Therefore, we pledge to give away 10% of our profits and 80% of your donations to charities that help the less fortunate in developing countries. Hoping that one day, they will be able to live lives like ours.</p>
		<p>We also pledge to provide free access to people in the world’s poorest countries and communities, thanks to the generous support of our community.</p>
		<p>We are grateful to <strong>GiveWell.org</strong> for their efforts in helping us find the most effective charities, which save or improve lives the most per dollar.</p>

		<p>Image: Philanthropy</p>

		<h2>How can you help us help others?</h2>
		<p>MyOceanity is able to advance this cause we believe in thanks to our community, those who activated their accounts to enjoy full access to our tools and resources.</p>
		<p>If you would like to do more, we offer two solutions:</p>

		<ol>
			<li>When we publish new modules, you may support our work with an optional additional purchase. All new modules are available to everyone on release, and such support allows us to do our best work while sharing 10% of our annual profits with charities.</li>
			<li>Making a donation, which will mainly be given forward to charities to help them advance the causes they believe in.</li>
		</ol>

		<p>Click the below buttons to donate or to support our work. You will be redirected to our payment processing platform, Stripe. </p>
		<p>Thank you for your trust and generosity!</p>

		<Button>Stripe Placeholder: Donate</Button>

		<Button>Stripe Placeholder: Support Us</Button>

   </div>
  )
}
export default SupportAndDonations;

