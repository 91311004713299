import React from 'react';


 function Updates(props: any) {
	   
  return (
   <div>
			<h1>MyOceanity Updates</h1>
			
      <h2>Week of 2020-12-15</h2>
      
      <h3>What has changed</h3>
      <ul>
        <li>Major re-design for the website</li>
        <li>Fixed login issues</li>
      </ul>

      <h2>Week of 2020-11-30</h2>
			<p><strong>MyOceanity is officially live!</strong></p>
			
      <h3>What is New</h3>

      <ul>
        <li><a href="/user/premium/my-assessments">Ocean personality traits assessment & personal report</a></li>
        <li><a href="/user/premium/send-invite">OCEAN personality traits invitation & comparison report</a></li>
        <li><a href="/ei-course">Course: Fundamentals of Emotional Intelligence</a></li>
        <li><a href="/what-we-do">What we do</a></li>
        <li><a href="/who-we-are">Who we are</a></li>
        <li><a href="/support-and-donations">Donate & support us</a></li>
        <li><a href="/resources">Resources</a></li>
      </ul>

   </div>
  )
}
export default Updates;

