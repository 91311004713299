import React from 'react';
import Table from 'react-bootstrap/Table';


function Ikigai(props: any) {
	   
  return (
   <div className="masthead">
			<h1>What Makes a Life Worth Living?</h1>
			
			<h2>What is Ikigai?</h2>
			
			<div className="notabene"> 
				Ikigai is a Japanese principle which means <i>"a reason for being"</i>.
			</div>
			
			<p>It means having a Mission or a purpose in life. Pursuing it makes your life worth living. But it also means so much more. It brings meaning into our lives, which helps us to persevere as life throws trials at us.</p>
			<p>The Buddhists believe that existence is suffering, which is caused by our cravings and attachments. However, there is also a path towards the cessation of suffering, which is nirvana.</p>
			<p>Jordan B. Peterson, a former professor of Psychology at Harvard and currently at the University of Toronto, believes that <b>searching for meaning in life is what makes the sufferings of life tolerable.</b> </p>
			<p>He argues that we suffer because we are fragile and mortal beings: we can get sick and hurt - physically and psychologically - and we eventually die. Life continuously throws trials at us to help us learn and grow, if we are brave enough to try.</p>
			<p>Through Ikigai, our reason for being, the meaning we give our lives, we can feel motivated, persevere through the trials, and prosper. </p>
			<p>When we live a meaningful life and have a reason for being, we can justify the long days of toil, the setbacks, disappointments and sacrifices we make as worth it, because they make other people's lives better.</p>
			<p>The German philosopher Friedrich Nietzsche said it well:</p>
			
			<div className="quote"> 
			<p><i>"He who has a <b>'Why'</b> to live can bear almost any <b>'How'</b>."</i></p>
			<p><strong>Friedrich Nietzsche</strong></p>
			</div>
			
			<h2>The Four Elements of Ikigai</h2>
			<p>How can you find your Ikigai?</p>
			
			<p><b>Ikigai can be found when you align four elements in life:</b></p>
			
			<ul>
				<li>What do you love doing? What is <b>your passion</b>?</li>
				<li>What are you good at doing? What are <b>your talents</b>?</li>
				<li>What does the world need, where you can help? What is <b>your contribution to the world</b>?</li>
				<li>What can you get paid for? <b>How can you financially sustain your family</b>?</li>
			</ul>

			
			<p>Passion matters, because doing something you love doing will not feel like work. Because you enjoy doing it, you are more likely to persevere and less likely to give up.</p>
			<p>Without passion, you may be able to live comfortably but still feel empty inside, without any desire, which will likely make you quit when things become too hard.</p>
			<p>Talents matter, because seeing that we are continuously improving and making progress is highly motivating. In contrast, struggling continuously without any feelings of progress and development are frustrating and are also likely to make you give up.</p>
			<p>The uncertainty of being able to effectively do what is required of you will pressure you and could crush your spirits.</p>
			<p>Contribution matters, because seeing that we are making a positive impact on people's lives and on the planet is also highly motivating. Because we can see how the results from our work are making things better, even for a moment. </p>
			<p>Adam Grant, professor at the Wharton School of the University of Pennsylvania, calls this making a prosocial difference, meaning making a positive difference in other people's lives. Doing this gives purpose to their lives and their work.</p>
			<p>For example, leaders help their protégés realize their potential by developing their skills and advancing their careers, like firefighters protect their communities from harm, because it gives meaning and significance to what they do.</p>
			<p>Egoistically accumulating wealth, therefore taking away precious resources from other people who need them more than us, not contributing to helping those who need help, may feel somewhat satisfying in the short term, but will likely leave us feeling useless, with an emptiness inside.</p>
			<p>Finally, finances matter, because it brings you the freedom to choose how you want to live in your spare time and to take care of your loved ones.</p>
			<p>Without sufficient financial security, the stress and anguish of not being able to provide for your family will likely make you search for another, more sustainable alternative.</p>
			
			<h2>Ikigai Makes Us Grittier</h2>
			<p>In her groundbreaking book, Grit: Why passion and persistence are the secrets to success, Angela Duckworth makes the case for why consistency over time matters so much.</p>
			<p>According to her research, the people who become the best at their crafts are those who possess two qualities: Passion and persistence.</p>
			<p>Her work suggests that passion can be broken down into two sub-elements:</p>
			
			<ul>
				<li>Interest, and</li>
				<li>Purpose</li>
			</ul>
			
			<p>Likewise, persistence can also be broken down into two sub-elements:</p>
			
			<ul>
				<li>Practice, and</li>
				<li>Hope</li>
			</ul>
			
			<p>Those who persist because they are passionate at what they do, over time, are more likely to become better at what they do as well. </p>
			<p>Because they become masters at their beloved crafts, as long as they contribute to something the world needs, they are more likely to also get paid for their work and ideas.</p>
			<p>Passion is fed by our curiosity and interests. When something tickles our minds, we are more likely to engage in it and the satisfaction we get by tinkering with the ideas and the work keeps us coming back for more.</p>
			<p>Talents are shaped by the thousands of hours of daily discipline and deliberate practice we devote ourselves to. It satisfies this constant appetite for learning and development, no matter how excellent people are at their crafts at any given time.</p>
			<p>Will Durant thought of it this way, by reflecting on Aristotle's own thoughts:</p>
			
			<div className="quote"> 
			<p><i>"We are what we repeatedly do.</i></p>
			<p><i>Excellence, then, is not an act, but a habit."</i></p>
			<p><strong>Will Durant</strong></p>
			</div>

			<p>When we learn and develop, we work hard to become better today than we were yesterday, every day. So that tomorrow, we can become better than we are today.</p>
			<p>We are not comparing ourselves to how great someone else is at our crafts today, which can leave us jealous, bitter, and resentful. Rather, we are comparing ourselves to how good we were yesterday and build from there.</p>
			<p>Contribution gives us a sense of purpose and is fed by the belief that what we do matters, because it helps people and/or the planet. Today and for the future.</p>
			<p>While interest helps us get engaged and immersed into what we are doing right now, purpose will keep us going for a lifetime. By contributing to the well-being of other people and the world we live in, we intrinsically feel fulfilled.</p>
			<p>Financial security gives us hope that we will be able to continue to sacrifice today for a better tomorrow without putting our family's livelihood at risk. </p>
			<p>Hope is essential, because while yesterday and today may have been hard on us, tomorrow comes with a promise of a better situation. </p>
			<p>The trials of life will bring us down to our knees from time to time. It is therefore normal to have doubts in such difficult times. But it's also essential to get back up and keep moving forward.</p>
			<p>Those who keep striving regardless of how hard things are, will be the ones who prevail and make a difference.</p>
			
			<h2>Chris' Personal Illustration</h2>
			<p>MyOceanity is my personal source of Ikigai, my reason for being.</p>
			<p>I am passionate about psychology and emotional intelligence, as well as understanding how they can help us to preserve and improve our lives. Both at home and at work.</p>
			<p>I am good at researching sources that I can connect and summarize to share the essence of their teachings. </p>
			<p>By standing on the shoulders of giants, I am able to get a better perspective and keep their work alive and growing. I try to connect the dots between multiple viewpoints and ideas and make them my own.</p>
			<p>I contribute to something greater than myself, which is to help people to realize their potential and live more meaningful lives. </p>
			<p>Finally, I try to make ends meet by sharing my work for a price which should make it affordable to most people. Those who cannot spend the 20 dollars - such as students, unemployed people or those with any other financial difficulties - should contact me to see how we can come to an arrangement. </p>
			<p>Because of all of these elements, I know in my heart that I will continue the Journey and enjoy it as long as I can, even when there will be trials. I hope that so will you.</p>

			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>Whether we realize it or not, our identity shapes our lives.</p>
			<p>Ikigai serves as a framework to help you clarify what gives your life meaning and purpose, to keep the fire burning inside you alive, to keep you going no matter what.</p>
			<p>There are a few ways you can discover your identity:</p>

			<Table striped borderless hover responsive className="course-table">
				<thead>
					<tr><th className="course-table-header">Module 4: Discovering Your Identity</th></tr>
				</thead>
				<tbody className="course-table-body">
					<tr> <td> <a href="/identity-introduction">Introduction to the concept of Identity</a></td> </tr>
					<tr> <td> <a href="/identity-mission">Why are you here? What is your Mission? What gives your life purpose?</a></td> </tr>
					<tr> <td> <a href="/identity-values">What do you stand for? What are your core values in life?</a></td> </tr>
					<tr> <td> <a href="/identity-beliefs">Who are you? What are your beliefs about yourself and the world around you?</a></td> </tr>
				</tbody>
			</Table>

			<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
            </div>
			
			<div className="separator"></div>
			<div className="separator"></div>
   </div>
  )
}
export default Ikigai;

