import React from 'react';
import Table from 'react-bootstrap/Table';

import elementsofei from "../images/elements-of-ei.png";
import emotionswhycore from "../images/emotions-why-core.png";
import oceanwave from "../images/ocean-wave.png";
import myotool2 from "../images/myotool2.png";
import meyouus from "../images/me-you-us.png";


function GrowYourEI(props: any) {

	   
  return (
   <div className="masthead">

			<h1>How Can You Grow Your Emotional Intelligence Skills?</h1>
			
			<div className="separator"></div>

			<p>How can you grow your emotional intelligence skills? This is the key question this course tries to answer. Its goal is to provide you with different and synergistic approaches.</p>
			<p>Indeed, I believe there are plenty of ways we can grow our skills in each of the four elements of emotional intelligence.</p>
			<p>As a reminder, here is the framework I use to define and work on emotional intelligence, by breaking it down into four elements:</p>
			
			<ol>
				<li><b>Self-Awareness</b>: Your ability to recognize and understand your emotions, tendencies, motivations in life and their reasons, as well as how they impact others around you.</li>
				<li><b>Self-Development</b>: Your ability to leverage the insights gained from Self-Awareness to create strategies to focus your time and energy on what truly matters to you. A way to live a more intentional and meaningful life.</li>
				<li><b>Social Awareness</b>: The mirror of Self-Awareness: Your ability to recognize and understand the emotions, tendencies, motivations of others and their reasons.</li>
				<li><b>Relationships Development</b>: Your ability to leverage the other three elements and to create lasting, mutually beneficial relationships with others. How can your strengths compensate for their weaknesses, and vice versa? How can you become stronger, together?</li>
			</ol>
			
			<div className="separator"></div>

			<div className="myimghor">
			<img src={elementsofei} alt="The 4 elements of emotional intelligence" />
			</div>

			<div className="imgcaption">
			<p>The 4 elements of emotional intelligence</p>
			</div>

			<p>This course is divided in five modules. Each module provides you with different approaches, which will ultimately help you to gain a more wholistic understanding of emotional intelligence, yourself and those around you.</p>
			
			<h2>Module 1 | Introduction To Emotional Intelligence</h2>
			<p>In the module you are currently in, you will learn more about:</p>

			<ul>
				<li><a href="/what-is-ei" className="intlink">What emotional intelligence is</a></li>
				<li><a href="/why-ei-matters" className="intlink">Why it matters in our lives</a></li>
				<li><a href="/ei-benefits-home" className="intlink">How we can benefit from it at home</a></li>
				<li><a href="/ei-benefits-work" className="intlink">How we can benefit from it at work</a></li>
				<li><a href="/strategies-for-ei" className="intlink">Which simple strategies you can try to grow each element of emotional intelligence</a></li>
			</ul>

			<div className="separator"></div>

			<div className="myimghor">
			<img src={meyouus} alt="Me, You, Us" />
			</div>

			<div className="imgcaption">
			<p>How can we build an us from you and me?</p>
			</div>

			<h2>Module 2 | The Importance Of Emotions</h2>

			<p>In the second module, we will discuss in greater length emotions and their importance in our lives.</p>
			<p>Most of the books and articles I have found on emotional intelligence to date talk in great length about emotions. </p>
			<p>This makes sense, since learning more about emotions and their impact on our lives was the first step towards the movement which is now known as emotional intelligence.</p>
			<p>For this reason, there are three topics you will discover in this module:</p>

			<ul>
				<li><a href="/core-emotions" className="intlink">What are our core emotions?</a></li>
				<li><a href="/emotional-literacy" className="intlink">How can you become more emotionally literate?</a></li>
				<li><a href="/reading-emotions" className="intlink">How can you better read people and understand how they are feeling?</a></li>
			</ul>

			<p>I believe, however, that emotions are just the starting point and that there is much more to be done to become more emotionally intelligent.</p>
			<p>To dive deeper into the core of our selves, which are shaped by our cultures, personality traits and our identity, we need to go through deep introspections. All of this will be explored in the following modules.</p>
			
			<div className="separator"></div>

			<div className="myimg">
			<img src={emotionswhycore} alt="Digging towards the core of our selves" />
			</div>

			<div className="imgcaption">
			<p>Digging towards the core of our selves</p>
			</div>

			<h2>Module 3 | OCEAN Personality Traits & Emotional Intelligence</h2>
			<p>I believe assessing our personality traits and learning how we can leverage them for our self-development as well as the development of our relationships is a great way to grow our emotional intelligence.</p>
			<p>My favorite personality traits model is OCEAN, which is an acronym for the big five personality traits which influence our motivations, preferences, and tendencies.</p>
			<p>OCEAN is an acronym which stands for:</p>
			
			<ul>
				<li>Openness to experience</li>
				<li>Conscientiousness</li>
				<li>Extraversion</li>
				<li>Agreeableness</li>
				<li>Nervosity</li>
			</ul>

			<div className="separator"></div>

			<div className="myimgvert">
			<img src={oceanwave} alt="Chris' OCEAN wave" />
			</div>

			<div className="imgcaption">
			<p>Chris' OCEAN wave</p>
			</div>

			<p>Personality traits offer a simple way to grow your emotional intelligence. Here is how:</p>

			<ul>
				<li><b>Self-Awareness can be developed by taking a personality traits assessment</b> to learn more about how your traits influence your behaviors and impact those around you.</li>
				<li><b>Based on your traits, self-Development strategies will be offered in your personal report to increase your personal effectiveness</b> and, sometimes, get out of your own way. You are also free to create your own!</li>
				<li><b>Social Awareness can be developed by encouraging others to take a MyOceanity personality assessment and comparing your traits to theirs.</b></li>
				<li><b>Based on your traits and those of your counterpart, relationships development strategies will be offered in your comparison reports</b>, by discovering your similarities and differences in each of the Big Five personality traits.</li>
			</ul>

			<div className="separator"></div>

			<div className="myimgvert">
			<a href="/user/premium/send-invite" className="img-link"><img src={myotool2} alt="Comparison reports" /></a>
			</div>

			<div className="imgcaption">
			<p>MyOceanity's second tool | Compare yourself to people you know</p>
			</div>

			<p>I developed MyOceanity as a platform to help people grow their emotional intelligence, mainly thanks to the OCEAN personality traits research, and by including this entire e-learning course into it. </p>
			<p>In this module, you will learn more about:</p>

			<ul>
				<li><a href="/ocean-personality-traits" className="intlink">What are the Big Five OCEAN personality traits?</a></li>
				<li><a href="/myoceanity-and-ei" className="intlink">How can you use MyOceanity to build your emotional intelligence?</a></li>
				<li><a href="/oceanology" className="intlink">OCEANology</a></li>
			</ul>
			
			<h2>Module 4 | Discovering Your Identity</h2>
			<p>Our Identity constitutes the very core of our selves. It guides us through life, although we may not always be conscious of it.</p>
			<p>Living and behaving in alignment with our Identity, therefore living a more intentional life, makes life much more fulfilling and meaningful.</p>
			<p>I like to think that Identity can be broken down into three elements:</p>
			
			<div className="notabene">
				<b>Identity = Mission + Values + Beliefs</b>
			</div>

			<p>This module will go over each one, by covering the following topics:</p>

			<ul>
				<li><a href="/identity-introduction" className="intlink">Introduction to the concept of Identity</a></li>
				<li><a href="/ikigai" className="intlink">What is Ikigai? What makes a life worth living?</a></li>
				<li><a href="/identity-mission" className="intlink">Why are you here? What is your Mission in life?</a></li>
				<li><a href="/identity-values" className="intlink">What do you stand for? What are your Core Values in life?</a></li>
				<li><a href="/identity-beliefs" className="intlink">Who are you? What are your Beliefs about yourself and the world around you?</a></li>
			</ul>

			<h2>Module 5 | Why Introspection Matters In Emotional Intelligence</h2>
			<p>Following my burnout of 2018, I started writing down my thoughts, feelings, fears and ideas in a small journal on a daily basis. </p>
			<p>During this period of introspection and study, I learned a lot about who I was and what made me tick. Psychology and emotional intelligence helped me heal and grow from this trial.</p>
			<p>I was trying to understand why I was thinking, feeling, and acting in the ways I was. I wanted to understand how I was responsible for my situation and what I could do to change it for the better.</p>
			<p>For this reason, module 3 will focus on introspection by discussing the following three topics:</p>

			<ul>
				<li><a href="/journaling-self-awareness" className="intlink">How journaling helps you to become more self-aware.</a></li>
				<li><a href="/journaling-self-development" className="intlink">How you can leverage your self-awareness insights to develop strategies for self-development.</a></li>
				<li><a href="/journaling-introspection" className="intlink">How introspections help us dig towards the core of our selves and why it matters.</a></li>
			</ul>
			
			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>Emotional intelligence is a crucial key to living a richer, more meaningful life filled with strong relationships.</p>
			<p>Don't know where to start? There are different ways you can deepen your understanding of emotional intelligence and see how it can help you improve your life and the lives of those around you:</p>

			<Table striped borderless hover responsive className="course-table">
				<thead>
					<tr><th className="course-table-header">Module 1: Introduction To Emotional Intelligence</th></tr>
				</thead>
				
				<tbody className="course-table-body">
					<tr> <td> <a href="/what-is-ei">What is emotional intelligence?</a></td> </tr>
					<tr> <td> <a href="/why-ei-matters">Why does emotional intelligence matter in our lives?</a></td> </tr>
					<tr> <td> <a href="/elements-of-trust">The 3 elements of trust: Likability, Expertise, Reliability</a></td> </tr>
					<tr> <td> <a href="/ei-benefits-work">Benefits of emotional intelligence at work</a></td> </tr>
					<tr> <td> <a href="/ei-benefits-home">Benefits of emotional intelligence at home (WIP)</a></td> </tr>					
					<tr> <td> <a href="/strategies-for-ei">Strategies to build the 4 elements of emotional intelligence (WIP)</a></td> </tr>
				</tbody>
			</Table>

			<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
            </div>
			<br /><br /><br />
   </div>
  )
}
export default GrowYourEI;

