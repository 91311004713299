import React from 'react';
import { Card, CardDeck, Image } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';

import * as Icon from 'react-bootstrap-icons';

import emotionsimg from "../images/emotions.png";
import joyimg from "../images/joy-face.png";
import angerimg from "../images/anger-face.png";
import sadnessimg from "../images/sadness-face.png";
import fearimg from "../images/fear-face.png";
import shameimg from "../images/shame-face.png";
import disgustimg from "../images/disgust-face.png";
import emotionalityimg from "../images/emotionality.png";
import emojisimg from "../images/emojis.jpg";
import whyeimatters from "../images/emotions.png";


 function CoreEmotions(props: any) {

  return (
   <div>
	   <body className="masthead" data-spy="scroll" data-offset="60" data-target="#myNavbar">
		   
	  		<Scrollspy items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5', 'section-6', 'section-7'] } currentClassName="is-current" className="sticky-top scrollspy overflow-scroll-horizontal" offset={20}>
				<li><a href="#section-1" className="scrollspy-link">Top</a></li>
				<li><a href="#section-2" className="scrollspy-link">1. Joy</a></li>
				<li><a href="#section-3" className="scrollspy-link">2. Anger</a></li>
				<li><a href="#section-4" className="scrollspy-link">3. Sadness</a></li>
				<li><a href="#section-5" className="scrollspy-link">4. Fear</a></li>
				<li><a href="#section-6" className="scrollspy-link">5. Shame</a></li>
				<li><a href="#section-7" className="scrollspy-link">6. Disgust</a></li>
			</Scrollspy>
	   
	   		<section id="section-1">
				
				<div className="separator"></div>
				<div className="separator"></div>

				<div className="myimghor">
					<img src={emotionsimg} alt="Palette of emotions" />
				</div>

				<div className="imgcaption">
					<p>We all experience a rich palette of emotions</p>
				</div>

				<h1>What Are Your 6 Core Emotions?</h1>


				<div className="notabene">
					Emotions are your psychological and physiological responses to the events of life. They guide your attention to ensure your survival.
				</div>
				
				<p>There are 6 core emotions which shape most of our lives:</p>

				<ol>
					<li><a href="#section-2">Joy</a></li>
					<li><a href="#section-3">Anger</a></li>
					<li><a href="#section-4">Sadness</a></li>
					<li><a href="#section-5">Fear</a></li>
					<li><a href="#section-6">Shame</a></li>
					<li><a href="#section-7">Disgust</a></li>
				</ol>

				<p>This article will describe each in greater details. For more information on how to describe the emotions you are feeling, please make sure to learn more about <a href="/emotional-literacy">how to grow your emotional literacy.</a></p>
			</section>

			<div className="separator"></div>
			<hr/>
			<div className="separator"></div>

			<section id="section-2" className="offset-anchor">
				
				<div className="myimgmini">
					<img src={joyimg} alt="A person making a joyful facial expression" />
				</div>

				<h2 id="joy">Joy & Life Satisfaction</h2>

				<div className="notabene">
					Love, hope, gratitude, curiosity, and enthusiasm make us happy and satisfied with life.
				</div>

				<ul>
					<li>Love connects us with one another and makes us care for each other.</li>
					<li>Hope makes us persist in building a better future, no matter how challenging today is.</li>
					<li>Gratitude roots us with the present moment. When we appreciate who we already are and what we already have, we become thankful for our blessings, because so many others have it way worse.</li>
					<li>Curiosity feeds our passion. It makes us explore new horizons and challenge our current skills and abilities.</li>
					<li>Enthusiasm feeds the fire within and its warmth affects those around us. It makes life more enjoyable and attracts others, who seek our positivity and energy. It keeps us striving to continuously learn and develop, which helps us achieve our dreams and aspirations in life.</li>
				</ul>
				
				<p>Psychological research suggests that there are 3 sources of wellbeing in life:</p>

				<ol>
					<li><a href="#gratification">Gratification of our cravings</a></li>
					<li><a href="#flow">Experiencing flow</a></li>
					<li><a href="#meaning">Living a life filled with purpose and meaning</a></li>
				</ol>
				
				<p>Let's discuss each of these 3 sources, starting with gratification.</p>
				

				<h3 id="gratification">Gratification</h3>
				
				<div className="notabene">
					Immediate gratification of a craving provides us a temporary pleasure derived from the cessation of pain. Think, eating to satisfy your hunger.
				</div>

				<p>The danger of this hedonistic approach to satisfying our cravings is that it leads us to a misleading sense of: </p>
				
				<div className="quote"> 
				<p><i>"<b>If</b> I do/have ____, <b>then</b> I will be happy."</i></p>
				</div>
				
				<p>We imagine that fulfilling this condition of finding what is missing will provide us with eternal bliss. Unfortunately, sometimes the anticipation of the event may feel more satisfactory than the event unfolding. </p>
				
				<div className="story">
				<p>Think back to the last time you deeply wanted to buy something for yourself, which really excited you. Perhaps it was a car, a watch, a smartphone or a piece of jewelry. You spent countless moments imagining what using it or wearing it would feel like. When you finally bought it, you may have felt overjoyed. </p>
				</div>

				<p>How long did this excitement last? Most likely, it passed after a short moment. Because you realized that it did not bring you this lasting feeling of fulfillment you were craving. Therefore, you searched for the next big thing, an ideal that would finally bring your chase of pleasure to an end, filled with eternal happiness. <b>This is a common criticism of hedonism: people become preoccupied with artificial ways of achieving pleasure, without experiencing deep levels of fulfillment.</b></p>
				
				<h3 id="flow">Engagement | Experiencing Flow</h3>
				<div className="notabene">
					The second way we feel satisfied with life is when we lose ourselves in the moment and our activities.
				</div>

				<p>When we are completely engaged and emerged into what we are doing, we lose track of time. We even lose our sense of self and of the world around us. We are entirely focused on what we are doing and nothing else matters.</p>
				<p>People often experience flow when they are “in the zone”, may it be by writing, drawing or any other artistic activity, as well as athletic activities. During this state, people report being able to work effortlessly, with a great sense of ease, control, and intense focus. They use their current skills and abilities to their fullest and tackle challenges, therefore pushing beyond their previous limits.</p>
				<p>The loss of self seems critical, because it removes hesitation and second guessing, which leads to peak performance. Without the limiting power of negative thoughts and beliefs, we can achieve things we wouldn’t have imagined ourselves capable of. This intensely immersive, nonemotional and non conscious experience is what psychologist Mihaly Csikszentmihalyi calls “Flow”.</p>
				<p>Being engaged and experiencing flow is not in itself source of satisfaction. The satisfaction comes after this experience. <b>Becoming aware of what happened and what we’ve just achieved brings a powerful feeling of happiness, excitement, achievement and fulfillment.</b></p>
				<p>Personally, I tend to experience flow when I am writing courses and articles. Once the initial struggle of the blank page is gone, words and ideas seem to flow through me. Quite often, I realize that thirty or sixty minutes have passed without my being aware of it. When such events occur, I feel ecstatic and hope to experience many more in the future. It boosts my self-esteem and, hopefully, my capacity to write.</p>
				<p>This is what I find so exciting about flow: It increases both satisfaction with life and performance. Over time, I am convinced that it is a key element to our ability to realize our potential by becoming the best versions of our selves. This idea leads us to the last element of life satisfaction, meaning.</p>
				
				<h3 id="meaning">Meaning | Living a Life Filled With Purpose & Meaning</h3>
				<p>The last source of life satisfaction goes back thousands of years. Aristotle thought that the good life and true happiness were about identifying our virtues, cultivating them, and living in accordance with them. </p>
				
				<div className="notabene">
					Aristotle argued that we should develop what is best within ourselves and use our skills in the service of greater goods, especially in the service of others and the planet. In doing so, we are pursuing a meaningful life, filled with purpose, because we are trying to make a positive difference in the lives of people and striving to become all that we can be.
				</div>

				<p>The difference with flow, with becoming lost in activity, is that not all activities are connecting us to a greater good, therefore bringing meaning. For instance, playing games or exercising may be immersive and rewarding after the fact, yet they may not be truly meaningful, if they don’t make a positive difference on other people’s lives.</p>
				<p>To learn more about living a life filled with purpose and meaning, please refer to module 6 of this course, which refers to the concept of Identity and Ikigai. To summarize this module’s content, we should strive to do two things:</p>
				
				<ol>
					<li>Reflect deeply on who we are, why we are here, and how we can contribute to the improvement of other people’s lives and the survival of our planet.</li>
					<li>Continuously seek to live - and therefore behave - in a way which aligns with our ideal selves, therefore living an intentional life and becoming better versions of our selves.</li>
				</ol>
				
				<h3>Life Satisfaction | Bringing This All Together With Maslow's Hierarchy of Needs</h3>
				<p>Psychologist Abraham Maslow tried to understand what motivated people, by categorizing our human needs in five levels:</p>
				
				<ol>
					<li>Physiological needs</li>
					<li>Safety needs</li>
					<li>Belongingness and love needs</li>
					<li>Esteem needs</li>
					<li>Self-actualization needs</li>
				</ol>
				
				<p>His original work stated that to move to the next level, we must first satisfy the current level’s needs. Research has since shown that our motivations are not so strictly linked. Still, these five levels describe fairly well what may motivate us in life.</p>
				
				<h4>Level 1 | Physiological Needs</h4>
				<p>The first level is associated with our physical health and are key for the proper functioning of our bodies and therefore of our survival. <b>Think food, drink, sleep, fitness, warmth and shelter.</b></p>
				<p>Lack of any of these elements may affect our emotional stability and our ability to mentally focus on what we seek to achieve. Fulfilling our physiological needs is a way to experience pleasure.</p>
				
				<h4>Level 2 | Psychological Safety Needs</h4>
				<p>Once are bodies are well equipped to function optimally, we start to worry about our safety. <b>We need and seek order, predictability, and control in our lives, because life is sometimes chaotic and unpredictable.</b></p>
				<p>For example, employment allows us to financially secure the resources we will need to continue to care for our families and, therefore, to ensure their physiological needs will be satisfied in the future. We also expect society to provide law and order, which will protect us from potential harm:</p>

				<ul>
					<li>Police to maintain law and order</li>
					<li>Schools to educate our children and prepare them for becoming productive contributing members of society</li>
					<li>Organizations to provide goods and services as well as employment</li>
					<li>Medical care to care for us in times of illness or injury</li>
				</ul>

				<p>We also need to feel psychologically safe before we can truly and meaningfully contribute to our team’s or our organization’s efforts. If we feel like others may betray us, whether by taking credit for our work or actively sabotaging us, we may hold back and therefore delay everyone’s progress.</p>

				<div className="quote"> 
					<p><i>"If survival is the brain’s most important priority, safety is the most important expression of that priority."</i></p>
					<p><strong>John Medina</strong></p>
				</div>
				
				<h4>Level 3 | Belongingness Needs</h4>
				<p>Human beings are social beings. We survived and thrived because of our ability to build tools and to work together for the survival of every member of the tribe. <b>Therefore, our behaviors are also highly motivated by our needs for interpersonal relationships. We need to feel like we belong to the tribe and that people care for us, like we care for them. We need to feel accepted.</b></p>
				<p>We need to build lasting, mutually beneficial relationships with family, friends, and coworkers. In doing so, we are building trust towards each other. Without trust, there can be no progress, as people will always look behind their backs. <b>Lack of feelings of belongingness lead to loneliness, depression, and anxiety.</b></p>
				
				<h4>Level 4 | Esteem Needs</h4>
				<p>Maslow divided esteem in two categories: esteem for oneself and the desire for respect from others.</p>
				
				<ul>
					<li><b>Self-esteem</b> here refers to the ability to learn and develop, therefore seeking mastery in what we love doing and are good at doing. We desire accomplishment.</li>
					<li><b>Respect from others</b> refers to our need to feel that our efforts and contributions are noticed and valued by others. They appreciate what we are doing for them.</li>
				</ul>

				<p>This is why being grateful and thankful when someone does something for you goes a long way. I heard many people say “Why should I thank them? It’s their job.” While this is technically true, still, I believe we need to thank people who do an act of service or generosity towards us. It’s a simple way of showing respect to them and making them feel appreciated.</p>
				
				<h4>Level 5 | Self-Actualization Needs</h4>
				<p>People seek to realize their potential. In Maslow’s terms:</p>
				
				<div className="quote"> 
					<p><i>"What a man can be, he must be."</i></p>
					<p><strong>Abraham Maslow</strong></p>
				</div>

				<p>To empasize the need we feel to realize our potential, I think Maslow's words should be changed slightly:</p>
				
				<div className="quote"> 
					<p><i>"What a man can <b>become</b>, he must <b>become</b>."</i></p>
					<p><strong>Chris Ostorero</strong></p>
				</div>

				<p>In this desire to become the most that we can be - in our own terms - we seek personal growth and peak experiences, therefore experiencing the flow that characterizes complete engagement.</p>
				<p><b>I believe that to find the greatest satisfaction in life, we need to find a way to experience flow in the pursuit of meaningful activities, ones who benefit others and the planet, therefore contributing to greater goods.</b> This way, immersing ourselves in meaningful prosocial activities, should prove more fulfilling and lasting than accumulating wealth and consuming goods alone, to fill the void inside.</p>
				<p>How would society change, if more people engaged in such activities? How much more productive and happier may we become? It seems that many people struggle and suffer in boring, meaningless jobs they hate, which takes a toll on their family and friendships. For them, life satisfaction is low and contributes to their misery. Pleasure alone is not enough. We need to strive for engagement - with flow - and meaning, seeking to live more intentional lives and purpose.</p>
			</section>



			<div className="separator"></div>
			<hr/>
			<div className="separator"></div>


				<section id="section-3" className="offset-anchor">
				
				<div className="myimgmini">
					<img src={angerimg} alt="A person making an angry facial expression" />
				</div>

				<h2 id="anger">Anger</h2>

				<div className="notabene">
					Anger is an instinctive response to threats.
				</div>
				
				<p>Anger helps us to fight. Not necessarily physically by throwing punches to hurt others, but also by fighting against something which we believe is not right or not fair. Anger motivates us to combat injustice. We feel angry towards someone or something that we feel have done us wrong. We see this even in young children who become infuriated and yell, “It’s not fair!”</p>
				<p>Anger can be useful, because it makes us aware of what we believe in. When we find a problem, we can search for solutions. The danger of anger is the risk of self-harm or of harming others when unleashed. Simply lashing out of anger will not heal it and may even deepen it in the future. Over time, if they never keep their anger in check, people may become hostile, aggressive or even violent towards others.</p>
				<p>Our words and actions have emotional consequences on others. Maya Angelou, an American poet and civil rights activist, said,</p>
				
				<div className="quote">
					<p><i>"People will forget what you say. They will forget what you do. But <b>they will never forget how you made them feel.</b>"</i></p>
					<p><strong>Maya Angelou</strong></p>
				</div>
				
				<p>We need to be cautious of how we affect others, because some damages happen beneath the surface and leave invisible scars. Anger can also fuel resentment and desire of vengeance. Forgiveness of others and the harm they have inflicted on us is essential to combat this bitterness. While forgiveness may be best expressed directly, in person, imagining ourselves forgiving others for what they’ve done also feels liberating.</p>
			</section>



			<div className="separator"></div>
			<hr/>
			<div className="separator"></div>


			<section id="section-4" className="offset-anchor">
				<div className="myimgmini">
					<img src={sadnessimg} alt="A small girl making a sad facial expression" />
				</div>

				<h2 id="sadness">Sadness</h2>

				<div className="notabene">
					Sadness is a way of expressing our pain - whether physical or psychological.
				</div>
				
				<p>When we feel helpless, disappointed or lost, we suffer psychologically and feel sad as a result. Loss and grief are powerful sources of pain and sadness. Sadness is common for infants, because they need to separate from their mothers and parents to become more independent. Each event will be experienced as a small loss with which they have to cope. We need to deal with our situations instead of repressing our pain.</p>
				<p>We all cope differently. While some people prefer to be alone and let their pain heal, others will seek social support. Personally, I like to write down my thoughts and let them pour over paper. This allows me to exorcize any pain that I may not be fully aware of. I try to understand why I feel hurt and what I can do about it.</p>
				<p>Pain and sadness are here to teach us. We sometimes ruminate over painful experiences, because our minds want to learn how to prevent them from happening again in the future or how to better deal with them should they still happen. If we don’t and only feel sorry for ourselves, without learning and developing, then we will not be able to move forward and these thoughts and feelings will haunt us.</p>
				<p>Persistent sadness may lead to depression, which interferes with how a person feels, thinks, and acts. Over time, people may become helpless and hopeless, their ability to experience joy impaired by their overwhelming sadness.</p>
			</section>

			<div className="separator"></div>
			<hr/>
			<div className="separator"></div>

			<div className="myimgmini">
				<img src={fearimg} alt="A woman making a fearful facial expression" />
			</div>

			<section id="section-5" className="offset-anchor">
				
				<h2 id="fear">Fear</h2>

				<div className="notabene">
					Fear is our body’s way of alerting us to the presence of danger, capable of causing us either physical or psychological harm. It’s a survival mechanism. A way to prepare ourselves to take action and overcome the trial life presents us with.
				</div>
				
				<p>When experiencing fear, our heart starts to race and adrenaline rushes through our veins to make us ready to face the situation. The uncertainty the future may hold for us or the loss of control over our present situation may trigger fear. In some situations, we become anxious of doing some things and follow the easy path: we delay it. We ignore it. This unfortunately doesn’t make the problem go away. It creeps in the back of our minds and jumps at us at the worse possible times.</p>
				
				<p>An important advice I received from a good friend is to start the day with what I least want to do. He argued that this way, I would be free from worrying about it the whole day and could better focus on what I truly wanted to achieve. As simple as it sounds, this advice is extremely helpful, at least in my experience. As a high-nervosity person, I tend to make mountains out of mole hills and imagine the worse, to the point that I get paralyzed by fear and don’t act, although I know I should.</p>
				<p>Seneca summarized this problem well when he said,</p>
				
				<div className="quote">
					<p><i>"We suffer more in imagination than in reality."</i></p>
					<p><strong>Seneca</strong></p>
				</div>
				
				<p>Most of the fears I create in the theater of my imagination are greatly exaggerated and the reality is often less stressful than I anticipated. To address this problem, Tim Ferris created what he calls fear setting, as opposed to goal setting. In this exercise, he tries to do two things:</p>
			
				<ol>
					<li>Imagine what the worst scenarios in a given situation may be, in order to prevent them from happening or by fixing them, should they still happen.</li>
					<li>Imagine what the costs of inaction may be. A year from now, what would your regrets be, if you didn’t do something you knew you should do but failed to act upon it?</li>
				</ol>

				<p>Remember, <b>fear is here to help us overcome life’s trials by preparing our bodies for action.</b> You can interpret this as excitement, your body getting ready to face the trial, rather than as anxiety, where fear gets the best of you.</p>
			</section>



			<div className="separator"></div>
			<hr/>
			<div className="separator"></div>


			<section id="section-6" className="offset-anchor">				
				<div className="myimgmini">
					<img src={shameimg} alt="A shameful colored boy hiding his face between his hands" />
				</div>

				<h2 id="shame">Shame</h2>
				
				<div className="notabene">
					Shame is born from our self-consciousness and sense of inadequacy. We tend to know well our personal deficiencies and shortcomings. We know when what we do is wrong, although we may hide it or deny it.
				</div>
				
				<p>A common thought which demonstrates shame is, “What is wrong with me?” Instead of seeking for help outside, we withdraw into ourselves and isolate ourselves, feeling distressed and worthless. We punish ourselves. When we are struck by shame, we blush, look down to avoid crossing other people’s gaze, lower our heads and slouch.</p>
			</section>



			<div className="separator"></div>
			<hr/>
			<div className="separator"></div>


			<section id="section-7" className="offset-anchor">					
				<div className="myimgmini">
					<img src={disgustimg} alt="A colored woman making a disgusted facial expression" />
				</div>

				<h2 id="disgust">Disgust</h2>
				
				<div className="notabene">
					We experience disgust as a protective mechanism against things which could make us sick. Disgust is extremely useful in our survival, because it’s more effective to avoid a potential sickness than to have our immune system have to fight it.
				</div>
				
				<p>If something smells bad, tastes bad or looks like it carries a disease, we become viscerally revulsed by it and try to avoid coming into contact with it. This can also work for spaces we inhabit as well as people - through their appearances, hygiene, and actions - and extends even to ideas. Even thinking about rotten meat or spoiled food can trigger disgusted reactions.</p>
			</section>


			<div className="separator"></div>
			<hr/>
			<div className="separator"></div>

			
			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>This second module deals with the importance of emotions. Here are other topics you can explore:</p>

			<section className="course-tables-center">

				<CardDeck className="mb-3">
					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={whyeimatters} variant="top" className="mb-3" />
						<Card.Title className="m-3">How to grow your emotional literacy<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/emotional-literacy" className="stretched-link"></a>
					</Card>

					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={emojisimg} variant="top" className="mb-3" />
						<Card.Title className="m-3">How to read emotions (coming soon)<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/reading-emotions" className="stretched-link"></a>
					</Card>
				</CardDeck>
			
			</section>
			
                <div className="content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
			
			<div className="separator"></div>
			<div className="separator"></div>
		</body>
   </div>
  )
}
export default CoreEmotions;

