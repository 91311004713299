import React from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown';
import StepWizard from "react-step-wizard";


import * as Icon from 'react-bootstrap-icons';



function StepWizardTest() {
    const {i18n} = useTranslation();

	const ThreeSixtyIntro = (props) => {

		return (
			<div className="masthead">
                <h3 className="my-4">Welcome to MyOceanity!</h3>

                <p>The following survey will allow you to provide feedback to (requestedBy). Thank you for contributing to their learning and development efforts!</p>
                <p>Once you have answered every question, you will be able to submit your feedback. Please note that you can only submit your feedback once. Your answers will not be saved until submission.</p>

				<div className="notabene">
					Your feedback is anonymous and safe. (requestedBy) will not be able to trace your answers back to you. Nobody will be able to review them.
				</div>
				
				<div className="accordion-width text-center">
					<Button className="login-btn-big" onClick={props.nextStep}>Start the survey <Icon.ArrowRight className="ml-2"/></Button>
				</div>

				<div className="separator"/>
			</div>
		)
	}

	const StepNumberTwo = () => {

		return (
		<div>
			<p>hello, i am step number two.</p>
		</div>	
			
		)
	}
	
		return (
		<div>
			<body className="masthead">
				<StepWizard>
					<ThreeSixtyIntro />
					<StepNumberTwo />				
				</StepWizard>
			</body>
		</div>
		)

			
}
export default StepWizardTest;

