import React from 'react';
import Table from 'react-bootstrap/Table';

import intentionalliving from "../images/intentional-living.png";


function IdentityBeliefs(props: any) {
	   
  return (
   <div className="masthead">
			<h1>Discovering Your Beliefs</h1>
			
			<div className="separator"></div>
			<p>I would like to introduce you to a different way of handling the daily thought and beliefs we have about ourselves. The idea is to associate a positive belief, in the form of an affirmation, to a behavior you want to reinforce. </p>

			
			<div className="notabene">
				I call this method the <i>"Behavior-Belief Association"</i> or <i>BBA</i>.
			</div>

			<p>This lesson will give you guidelines to design your own BBAs as well as my personal example. The goal is to build positive habits that you desire, because they can help improve your daily life.</p>
			<p>For example, when doing chores - such as washing the dishes - <b>consciously thinking "I am clean & organized." to reinforce your constructive behavior with a positive affirmation about who you are</b>.</p>
			<p>This idea is derived from the "Pointing-and-calling" method (also known as <i>shisa kanko</i> in Japanese), which is used in the Japanese railways to reduce mistakes and to increase safety: By using a physical movement (the pointing) and a verbal confirmation that everything is fine (the calling), workers become increasingly aware of their actions and therefore reinforce their good working habits.</p>
			<p>This lesson will discuss three key ideas in greater details:</p>
			
			<ol>
				<li><a href="#the-inner-critic">A short discussion about our subconscious minds and the inner critic that lives there.</a></li>
				<li><a href="#affirmations">How positive affirmations can fight against the inner critic.</a></li>
				<li><a href="#bba-examples">Some concrete examples of how I have implemented BBAs in the past year, which have produced remarkable results.</a></li>
			</ol>
			
			<p>This lesson is part of the module of Identity and to live a more intentional and meaningful life. Please make sure that you also read more about the other two elements of Identity, which are your core values and your mission in life.</p>
			
			<div className="notabene">
				<b>Identity = Mission + Values + Beliefs</b>
			</div>

			<ol>
				<li> <a href="/identity-mission">Your Mission</a>, answer the question, "Why am I here?"</li>
				<li> <a href="/identity-values">Your Core values</a>, answer the question, "What do I truly stand for?"</li>
				<li> <a href="/identity-beliefs">Your Beliefs</a> about yourself, answer the question, "Who am I?"</li>
			</ol>	

			<h2>Worksheets</h2>
			<p>Here are three worksheets and template to help you with this exercise of discovering your beliefs about yourself:</p>
			
			<ul>
				<li>The first, the "Self Beliefs List" is a file which summarizes all the core beliefs I developed for myself and that I want to reinforce.</li>
				<li>The second, the "BBA Examples" contains a list of examples of affirmations I use to reinforce my behaviors and beliefs about myself.</li>
				<li>The last, the "BBA Template" is a blank canvas on which you can write down your personal behaviors and the beliefs/affirmations that they represent.</li>
			</ul>
			
			<p>Download placeholder: Self Beliefs List</p>
			<p>Download placeholder: BBA examples</p>
			<p>Download placeholder: BBA template</p>
			
			<h2 id="the-inner-critic">The Subconscious Mind And The Inner Critic</h2>
			<p>I find the subconscious mind fascinating. A lot is happening beneath the surface of our consciousness. </p>
			<p>A lot of metaphors were used to describe it:</p>
			
			<ul>
				<li>Some refer to it an iceberg, where only the very tip - the conscious mind - is emerged, while the massive rest is invisible and lies beneath the surface.</li>
				<li>Some refer to it as a sponge, because it automatically and effortlessly absorbs at all times every internal and external stimuli, without our awareness. This is meant to prevent us from mental exhaustion, as consciously analyzing everything which happens to us and around us would overwhelm our mental processing capacity.</li>
				<li>Some refer to it as a filing cabinet, where all the information it acquired over time is archived for future review.</li>
			</ul>

			<p>By combining these different metaphors, we can imagine an interesting observation: Repeated exposure unconsciously creates the prominent thoughts or beliefs, overwriting what may have been there previously.</p>
			<p>This can be highly beneficial, because every step towards a desired goal is providing a rewarding feeling, to encourage us to keep going.</p>
			<p>When we are hungry and we see the cookie jar on the counter, the anticipation of the pleasurable experience makes our brains produce dopamine. Each step we take closer towards the jar reinforces this feeling, which is ultimately accomplished when we eat the cookie and satisfy our hunger.</p>
			<p>The same is true when we spend quality time with our loved ones, which reinforces our bonds and relationships. The more we do it, the stronger they become.</p>
			<p>This can also be dangerous: Constant advertising messages influence us to believe that we always need more. There can never be enough, otherwise organizations would go bankrupt for lack of revenues. </p>
			<p>Seeing others wearing nice clothes, living in beautiful homes, and driving exciting cars gives us the impression that acquiring more will make us feel better than we do today.</p>
			<p>The formula works like this:</p>
			
			<div className="quote">
			<p>"<b>If</b> I get ____, <b>then</b> I will be happy." </p>
			</div>
			
			<p>Putting a condition on our happiness, derived from the world around us and our possessions, is dangerous, as it is short-lived and will always require us to spend more and acquire more to quench this never-ending thirst. That being said, I'm afraid these external threats are not the worst ones impacting our subconscious minds.</p>
			<p>We all have an enemy within, an inner critic. A voice that judges everything we think, say or do, trying to pull us down into the abyss in which it lives.</p>
			<p>Research suggests that thousands of thoughts go through our minds every day, and that most of them are recurring, often negative thoughts.</p>
			<p>Why is the inner critic so dangerous then? Because he's the one whispering "I'm not smart/good enough." or "I'm lazy/fat." in our minds' ear, over and over. His incessant nagging increases his influence on our identity and potential by limiting our self-appreciation. Over time, it destroys our self-confidence in our abilities.</p>
			<p>How can we fight the inner critic then? How can we prevent his influence over our unconscious minds to keep spreading? This is where I believe positive affirmation and beliefs can help.</p>
			
			<h2 id="affirmations">Affirmations | Our Best Weapon Against The Inner Critic</h2>
			<p>I like to break down beliefs in two categories:</p>

			<ol><b>
				<li>Internal: Self-believes we hold about ourselves and who we are;</li>
				<li>External: Believes we hold about the world and the people around us.</li>
			</b></ol>

			<p>Affirmations are tools we can use to move away from the inner critic's destructive influence and towards a more positive and constructive conversation with ourselves, through our internal beliefs. External beliefs will be discussed at the end.</p>
			<p>I read a lot about affirmations and how they can positively impact us these last years, but I imagined a different way of using them: By associating them with a positive behavior or habit I want to reinforce.</p>
			<p>I first discovered affirmations in 2013, when studying the late Zig Ziglar's "See you over the top" book. He encourages people to read a list of affirmations every morning and every night in front of the mirror. Which I did. A week into this daily ritual, I felt good. <b>I started thinking, "Am I actually better than I thought?"</b></p>
			<p>This is what makes beliefs and affirmations so powerful in my opinion: they open your eyes to your unknown potential.</p>
			<p>After reflecting on Zig's affirmations and the positive effect they had on me, I think that his method has <b>two limits</b>:</p>
			
			<ol>
				<li><b>Reading this list takes time</b> - sometimes, even five minutes seems like an unreasonable amount of time - and it requires a lot of willpower to start doing it when you don't feel like it, as long as it hasn't become a second-nature habit. If I didn't plan enough time in my morning or evening schedule or if I am in a hurry, I may start slipping and stopping doing this exercise altogether.</li>
				<li><b>Doing this only twice a day</b> doesn't seem sufficient to me to effectively fight the inner critic: How can two large swings defeat his incessant daily attacks on your self-image? How can it be enough?</li>
			</ol>

			<p>How are BBAs trying to surpass these two limits then? In three ways:</p>
			
			<ol>
				<li>Each <b>takes only a few seconds to practice</b>, which means I have fewer excuses to defer doing it.</li>
				<li>Because they are anchored to hundreds of small behaviors and habits I repeat every day, <b>this repetition means that they start to better match in numbers the attacks of the inner critic.</b></li>
				<li><b>Repetition over time builds behaviors into habits</b>, meaning that we start doing them more automatically, with less conscious efforts, which are taxing on our energy levels. Conscious thinking and willpower are exhausting.</li>
			</ol>
			
			<p>Another benefit of practicing what I believe is that it helps me live a more intentional life, which feels greatly rewarding and satisfying. I'm slowly progressing on the journey to self-development, one step at a time. I am continuously becoming a better person.</p>
			
			<div className="myimghor w-75">
				<img src={intentionalliving} alt="Living an intentional life" />
			</div>

			<div className="imgcaption">
				<p><b>Living an intentional life means continuously trying to align your actions with your identity</b></p>
			</div>
			

			<h2 id="bba-examples">Personal Illustrations Of Behavior-Belief Associations</h2>
			
			<h3>I Am Clean & Organized</h3>
			<p>For most of my life, until 2018, I never considered myself to be a very clean or organized person. My wife and family would tell you as much.</p>
			<p>I am, after all, a low-conscientiousness person, as my OCEAN personality traits would describe. This means I tend to forget things, procrastinate and get overwhelmed by too much structure or information. I am not working as industriously as I should towards my goals, but I am trying to become better at it.</p>
			<p>The result is that chaos usually slowly starts building around me and I have to either be told to take care of it or it eventually became so bad that it became intolerable even for me. The good news is that at least I had a limit of acceptable mess!</p>
			<p>The problem with cleaning that accumulated mess took a lot of time and effort. Inevitably, the chaos would start building up yet again.</p>
			<p>Every month or so, I spent a whole day cleaning and I hated it. Then, the cycle repeated.</p>
			<p>In the summer of 2018, as I studied more about habits and how to build them and went through a minimalism exercise by getting rid of some of the stuff I didn't need anymore before my wife and I moved to Zurich, I realized that my actual self was not all it could be.</p>
			<p>I realized that I could become better. I could become someone I would like being and that my family and friends would also appreciate better.</p>
			<p>I knew that my lack of cleanliness and organization was bothering my wife. This is the time when I started playing with the idea of linking an affirmation - namely "I am clean & organized" - to every chore I did, not matter how small:</p>
			
			<ul>
				<li>Cleaning the dishes</li>
				<li>Tidying up the apartment</li>
				<li>Doing laundry</li>
				<li>Making the bed (which even my mom stopped believing I could do as a child and teenager)</li>
			</ul>

			<p>Small, seemingly insignificant actions, every single day, that I tried to make more enjoyable and rewarding by linking them to who I wanted to become.</p>
			<p>I didn't have to enjoy what I was doing, instead I enjoyed why I was doing it: To become a better person, one that my wife would enjoy living with more.</p>
			<p>After a few weeks, as I walked around my apartment, I was surprised to see that I felt better in it and noticed that my wife's mood had improved. </p>
			<p>It felt nice for both of us to live in a clean place! This motivated me to keep going with this experiment and I started asking myself, what else could I change about my life by doing so?</p>
			
			<h3>I am Fit & Healthy</h3>
			<p>The guy in the mirror pointed at my stomach as a next area of improvement. Since I turned 30, I kept going on a weight rollercoaster ride, gaining and losing 8 kg / 16 pounds every six months or so.</p>
			<p>My strategy at the time was to force myself to go to the gym in my office to work out for an hour, mainly focusing on cardio training. Although I did enjoy the "runner's high" endorphins rush after each workout, I hated being there. The gym is not my thing.</p>
			<p>However, my wife and I always enjoyed swimming, and she is a much better swimmer than I am. When we moved to Zurich, we each bought a 6-entry pass to the swimming pool, as we were not yet sure we wanted to buy an annual pass.</p>
			<p>A month later, we had to buy another 6-entry pass, curious to see if we would be able to keep up the pace or if this was only a "new resolution" fad that would pass as quickly as it came. To our surprise, the result was the same, and we used our 6 entries in a month. This is when we decided to buy the annual pass.</p>
			<p>Because we wanted to make the most of our unlimited access, we started going twice, sometimes thrice a week when we were the most motivated. We didn't need to force ourselves to swim for an hour, we could swim 30-40 minutes, which was good enough for most sessions, as we knew others would follow a few days later.</p>
			<p>After two years, I have swam more times than in my entire life!</p>
			<p>You may ask, "what does this have to do with beliefs? In the beginning, I struggled a lot to swim and frequently stopped to catch my breath. When it happened, I kept thinking, "It's okay. I am fit an healthy. I can keep on going. Just a few more." Over time, this belief shrunk down to "I am fit and healthy."</p>
			<p>I wanted to explore what other behaviors could be a positive demonstration of this belief. So I started taking the stairs instead of the elevator, since we live on the third floor of our building and that my office was located on the fourth floor. As I started to pant going up due to my lack of exercising, I kept thinking, "I am fit and healthy." and kept going. I even starting taking my colleagues with me and as time went by, although they still hesitated when seeing the elevators next to the staircase, they usually followed me up the stairs. </p>
			<p>I took the idea even further, by walking home instead of riding the bus home. My commute was extended by an additional 20 minutes, but the feeling of bursting energy I got when I arrived home is priceless.</p>
			<p>Before, when I arrived home, I felt tired and lacked any motivation to do anything. Since, I am leveraging this additional energy to cook and do some chores - remember, I am clean & organized!</p>
			<p>My last experiment so far was to ride our stationary bike while playing video games or watching a show. It made this experience healthier, or it made bike-riding more enjoyable, depending on how you look at it.</p>
			<p>All of this to say that I managed to silence my inner critic, who used to call me lazy and fat, thanks to my behaviors, which were an embodiment of my beliefs about myself.</p>
			
			<div className="notabene">
				Instead of trying to make myself feel good by reading a list of wonderful affirmations, I try to live by my beliefs and reinforce them, by pointing them out and calling them in my head. It feels really fulfilling.
			</div>

			<h2>How Does This Apply to External Beliefs?</h2>
			<p>I believe beliefs about the external world around us and the people who inhabit it can be treated in a similar way. The inner critic can also sabotage us by leading us to falsely believe that "nobody really loves you" or "the world is out to get you".</p>
			<p>We need to reframe our interpretation of a situation before responding to it. Over time, this perception becomes the new norm, the new status quo. There are too many situations that could be reframed to list here, but here are the ones I have been working on:</p>
			
			<h3>1 | My Locus Of Control</h3>
			<ul>
				<li>Moving from: "I can't do anything about it."</li>
				<li>To: "How can I take responsibility for what is happening to me?"</li>
			</ul>
			
			<h3>2 | My Growth Mindset</h3>
			<ul>
				<li>Moving from: "I am bad at ____." or "I can't do ____."</li>
				<li>To: "I can't do ____ well <b><i>yet</i></b>. How can I learn and become better?"</li>
			</ul>
			
			<h3>3 | My Social Interactions</h3>
			<ul>
				<li>Moving from: "How can I make the most of this situation and person?"</li>
				<li>To: "How can I befriend and help people? How can we build lasting, mutually beneficial relationships?"</li>
			</ul>
			
			<h3>4 | Tensions & conflicts</h3>
			<ul>
				<li>Moving from: "You are wrong and I am right."</li>
				<li>To: "What does this person know that I don't? What can they teach me? How can their ideas expand mine?"</li>
			</ul>

			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>Whether we realize it or not, our identity shapes our lives. <b>Your daily beliefs about yourself and the world around you can empower you or limit you. The choice is yours.</b></p>
			<p>There are a few ways you can discover your identity:</p>

			<Table striped borderless hover responsive className="course-table">
				<thead>
					<tr><th className="course-table-header">Module 4: Discovering Your Identity</th></tr>
				</thead>
				<tbody className="course-table-body">
					<tr> <td> <a href="/identity-introduction">Introduction to the concept of Identity</a></td> </tr>
					<tr> <td> <a href="/ikigai">What is a meaningful life? What is Ikigai?</a></td> </tr>
					<tr> <td> <a href="/identity-mission">Why are you here? What is your Mission? What gives your life purpose?</a></td> </tr>
					<tr> <td> <a href="/identity-values">What do you stand for? What are your core values in life?</a></td> </tr>
				</tbody>
			</Table>

			<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
            </div>
			
			<div className="separator"></div>
			<div className="separator"></div>
   </div>
  )
}
export default IdentityBeliefs;

