import React from 'react';
import { Card, CardDeck, Image, Table } from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons';


import emotionscollage from "../images/emotions-collage.jpg";
import meyouus from "../images/me-you-us.png";
import elementsofei from "../images/elements-of-ei.png";
import selfcore from "../images/emotions-why-core.png";
import selfawarenesspercentage from "../images/self-awareness-percentage.png";
import chriscomparisons from "../images/chris-traits-comparisons.png";
import heartheadhandshake from "../images/heart-head-handshake.jpg";
import whyeimatters from "../images/emotions.png";
import nurturinggrowth from "../images/nurturing-growth.jpg";
import warmthimg from "../images/warmth.jpg";
import teamworkimg from "../images/teamwork.png";
import workshopimg from "../images/workshop.jpg";
import familyimg from "../images/family.jpg";



function WhatIsEI() {

  return (
   <div className="masthead">



			<div className="myimghor">
				<img src={heartheadhandshake} alt="Heart and Head Shaking Hands" />
			</div>

			<div className="imgcaption">
				<p>Emotional intelligence requires that our hearts and heads work together.</p>
			</div>

			<h2>What is Emotional Intelligence?</h2>

			<div className="notabene">
				Emotional intelligence helps you to become a better person and to build stronger relationships with others.
			</div>

			<p>The best way I found to understand and describe emotional intelligence is by breaking it down into four elements:</p>
			
			<div className="myimghor">
				<img src={elementsofei} alt="The 4 elements of emotional intelligence" />
			</div>

			<div className="imgcaption">
				<p>The 4 elements of emotional intelligence</p>
			</div>

			<p>In other words, emotional intelligence is the collection of knowledge and skills that enable you to become more effective and to create lasting, mutually beneficial relationships with others, such as:</p>

			<ul>
				<li>Trust</li>
				<li>Empathy</li>
				<li>Communication</li>
				<li>Social skills</li>
				<li>Presentation skills</li>
				<li>Flexibility</li>
				<li>Accountability</li>
				<li>Assertiveness</li>
				<li>Anger management</li>
				<li>Stress tolerance</li>
				<li>Time management</li>
				<li>Decision-making</li>
				<li>Change tolerance</li>
				<li>Customer service</li>
			</ul>

			<div className="imgcaption">
				<p>Source: "Emotional Intelligence 2.0" | Travis Bradberry & Jean Greaves</p>
			</div>

			<p>Emotional intelligence describes our ability to understand who we are, what makes us tick, and why:</p>
			
			<ul>
				<li>One of the first key lessons from emotional intelligence is that <b>we are both different and similar at the same time. Our differences enrich us.</b></li>
				<li>The second key lesson is that <b>we are stronger together, because we are interdependent: I need you and you need me.</b> Together, we can achieve more. Because we all have unique backgrounds, experiences, personalities, ideas, and opinions. How can your strengths compensate for my weaknesses, and vice versa?</li>
			</ul>
			
			<div className="separator"></div>

			<div className="myimghor">
				<img src={meyouus} alt="Me, You, Us" />
			</div>

			<div className="imgcaption">
				<p>How can we create an us from you and me?</p>
			</div>

			<p><b>The intersection between the two cirles represent our similarities; the rest of each circle represents our differences</b>. We all tend to appreciate and focus on our similarities while shying away from our differences. As the following quotes eloquently phrase it, however, this is a mistake:</p>

			<div className="quote">
                <p><i>"Strength lies in differences, not in similarities."</i></p>
                <p><b>Stephen Covey</b></p>
            </div>

			<div className="quote">
				<p><i>"It is not our differences that divide us. It is our inability to recognize, accept, and celebrate those differences."</i></p>
				<p><b>Audre Lorde</b></p>
			</div>
		
			<div className="notabene">
				Emotions are our psychological and physiological responses to the events of life. They guide our attention.
			</div>


			<h2>1. Self-Awareness</h2>
			<p><b>Self-Awareness describes your ability to notice and understand your emotions, motivations, tendencies, and how they impact those around you</b>. It's about introspection, self-reflection and self-understanding. Reflecting on questions such as:</p>

			<ul>
				<li>How are you feeling, right now? Why?</li>
				<li>Why are you here? What is your personal Mission?</li>
				<li>Who are you? What are your beliefs about yourself and the world around you?</li>
				<li>What do you stand for? What are your core values?</li>
				<li>What drives you? What motivates you? What inspires you? What gets you up in the morning? Why?</li>
				<li>What makes you feel fulfilled when you go to bed? What makes you happy? Why?</li>
				<li>What are your strengths? What are your weaknesses?</li>
				<li>What opportunities can you explore? Where do you want to go in life? Why?</li>
				<li>How do you react in stressful situations? Why?</li>
			</ul>

			<p><b>The goal of self-awareness is to reveal our unconscious thoughts, beliefs, and tendencies across situations</b>. Because they influence how you behave, at times without you being aware of it. Understanding our emotions is only the beginning of the journey to self-understanding. By searching for answers to these questions, we start to dig towards the core of our selves.</p>

			<div className="myimg">
				<img src={selfcore} alt="The different layers of the self" />
			</div>

			<div className="imgcaption">
				<p>Emotional intelligence involves uncovering multiple layers of your self. Emotions are just the beginning</p>
			</div>

			<p>It may sound simple, yet few people have high, accurate levels of self-awareness. Based on her research, <a href="https://hbr.org/2018/01/what-self-awareness-really-is-and-how-to-cultivate-it">Tasha Eurich</a> found that only a minority of the population are highly self-aware:</p>

			<div className="myimghor">
				<img src={selfawarenesspercentage} alt="Percentage of highly self-aware people" />
			</div>

			<div className="imgcaption">
				<p><b>Only 10-15% of people tend to have high levels of self-awareness</b></p>
			</div>


			<h2>2. Self-Development</h2>
			<p><b>Self-Development describes your ability to focus your time, attention, and energy to achieve what truly matters to you in life, and how to get out of your own way.</b></p>


			<h2>3. Social Awareness</h2>
			<p>Social Awareness is the mirror of Self-Awareness: <b>Your ability to notice and understand the emotions, motivations and tendencies of others, how they might differ from your own, and how you can help each other.</b></p>

			<div className="myimghor">
				<img src={chriscomparisons} alt="Comparing Chris' personality traits to Feffef's" />
			</div>

			<div className="imgcaption">
				<p>Comparing Chris' personality traits to Feffef's</p>
			</div>


			<h2>4. Relationships Development</h2>
			<p>Relationships development describes <b>your ability to use the knowledge of your differences and similarities with other people, in order to build lasting and mutually beneficial relationships with them</b>.</p>
			
			<div className="myimghor">
				<img src={meyouus} alt="Me, You, Us" />
			</div>

			<div className="imgcaption">
				<p>How can we create an us from you and me?</p>
			</div>


			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>Emotional intelligence was included in the top 5 soft skills companies need most in 2020, according to LinkedIn Learning. There are different ways you can deepen your understanding of emotional intelligence and see how it can help you improve your life and the lives of those around you:</p>

			<section className="course-tables-center">

			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={whyeimatters} variant="top" className="mb-3" />
					<Card.Title className="m-3">Why emotional intelligence matters<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/why-ei-matters" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={nurturinggrowth} variant="top" className="mb-3" />
					<Card.Title className="m-3">Grow your emotional intelligence skills<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/grow-your-ei" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={warmthimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">What are the 3 elements of trust?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/elements-of-trust" className="stretched-link"></a>
				</Card>
			</CardDeck>

			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={teamworkimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Emotional intelligence at work<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ei-benefits-work" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={workshopimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Strategies for emotional intelligence<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/strategies-for-ei" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={familyimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Emotional intelligence at home (Coming soon)<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ei-benefits-home" className="stretched-link"></a>
				</Card>
			</CardDeck>


			<div className="myimg">
				<img src={meyouus} alt="Me, You, Us" />
			</div>

			<div className="imgcaption">
				<p>How can we create an us from you and me? How can we build lasting, mutually beneficial relationships? This is what emotional intelligence is all about.</p>
			</div>

			</section>
			
			<div className="content-btn-box">
				<a href="/ei-course" className="content-btn">Back to the course<Icon.ArrowRight className="ml-2" /></a>
			</div>

			<div className="separator"></div>
			<div className="separator"></div>
   </div>
  )
}
export default WhatIsEI;

