import React from 'react';
import { Card, CardDeck, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Scrollspy from 'react-scrollspy';
import ReactMarkdown from 'react-markdown'

import * as Icon from 'react-bootstrap-icons';

import adventureimg from '../images/adventure.jpg';

import Ospectrum from '../images/openness-spectrum.png';
import emotionalityimg from '../images/emotionality.png';
import ideagenerationimg from '../images/idea-generation.jpg';
import fantasyimg from '../images/fantasy.jpg';
import aestheticsimg from '../images/aesthetics.jpg';
import adventure1img from '../images/adventure1.jpg';
import valuesimg from '../images/o-values.jpg';
import nurturinggrowth from "../images/nurturing-growth.jpg";
import groupoffriends from "../images/group-of-friends.jpg";
import studyingmaterials from "../images/studying-materials.jpg";



function OpennessFacets(props: any) {

	const { i18n } = useTranslation();


  	return (
	<div>
		<body className="masthead" data-spy="scroll" data-offset="60" data-target="#myNavbar">
			
			<Scrollspy items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5', 'section-6', 'section-7', 'section-8' ] } currentClassName="is-current" className="sticky-top scrollspy overflow-scroll-horizontal" offset={20}>
				<li><a href="#section-1" className="scrollspy-link">Top</a></li>
				<li><a href="#section-2" className="scrollspy-link">1. Ideas</a></li>
				<li><a href="#section-3" className="scrollspy-link">2. Fantasy</a></li>
				<li><a href="#section-4" className="scrollspy-link">3. Aesthetics</a></li>
				<li><a href="#section-5" className="scrollspy-link">4. Emotionality</a></li>
				<li><a href="#section-6" className="scrollspy-link">5. Adventure</a></li>
				<li><a href="#section-7" className="scrollspy-link">6. Values</a></li>
				<li><a href="#section-8" className="scrollspy-link">Your Next Steps</a></li>
			</Scrollspy>

		<section id="section-1">
			<div className="myimghor mt-0">
				<img src={adventureimg} alt="Woman hiking" />
			</div>

			<div className="imgcaption">
				What will be your next adventure?
			</div>

			<h1 id="Otitle">The 6 Facets of Openness to Experience</h1>

			<div className="myimghor">
				<img src={Ospectrum} alt="Openness spectrum" />
			</div>

			<div className="imgcaption">
				The openness to experience spectrum
			</div>

			<p>Openness to experience can be broken down into 6 facets which explore how sensible and curious you are to your inner worlds and the world around you:</p>

			<ol>
				<li><a href="#section-2">Openness to ideas</a></li>
				<li><a href="#section-3">Openness to fantasy</a></li>
				<li><a href="#section-4">Openness to aesthetics</a></li>
				<li><a href="#section-5">Openness to emotionality</a></li>
				<li><a href="#section-6">Openness to adventure</a></li>
				<li><a href="#section-7">Openness to values</a></li>
			</ol>

			<div className="content-btn-box mt-5">
				<a href="/user/premium/my-assessments" className="content-btn">Discover YOUR Traits<Icon.ArrowRight className="ml-2" /></a>
			</div>
		</section>

		<hr />



		<section id="section-2" className="offset-anchor">
			<div className="myimghor">
				<img src={ideagenerationimg} alt="Openness spectrum" />
			</div>

			<h2>O1 - Openness to Ideas</h2>
			<div className="notabene">
				<ReactMarkdown>Openness to ideas describes your intellectual curiosity.</ReactMarkdown>
			</div>

			<h4 id="Oideas">When You Have High-Openness To Ideas</h4>
			<p>You enjoy thinking about abstract concepts and things, and can handle a lot of information at once, which helps you to think outside the box and be more inventive, original, and innovative than other people. You love to read challenging material and have a rich vocabulary.</p>
			<p>You are more interested in intellectual pursuits than anything else and seek out to understand the patterns of the universe around you. You are an experimental person who likes to test their ideas.</p>

			<div className="quote">
				<p><i>"An invasion of armies can be resisted, but not an idea whose time has come."</i></p>
				<p><b>- Victor Hugo</b></p>
			</div>

			<h4 id="Oideas">When You Have Low-Openness To Ideas</h4>
			<p>You are more down-to-earth, practical and pragmatic than others. Abstract ideas don’t interest you that much and you have difficulties understanding them. You may even sometimes resist new ideas.</p>
			<p>You tend to avoid or are not interested in theoretical or philosophical discussions and prefer actions over theories. You may avoid difficult reading material or skip words while reading.</p>

			<div className="quote">
				<p><i>“It is much easier to propose than to execute.”</i></p>
				<p><b>- David Noonan</b></p>
			</div>
		</section>

		<hr />

		
		<section id="section-3" className="offset-anchor">
			<div className="myimghor">
				<img src={fantasyimg} alt="Openness spectrum" />
			</div>
			<h2>O2 - Openness to Fantasy</h2>
			
			<div className="notabene">
				Openness to fantasy describes your receptivity to the inner world of imagination. Imagination is extremely useful to step outside of yourself and reflect on how other people may experience and perceive you.
			</div>

			<h4 id="Ofantasy">When You Have High-Openness To Fantasy</h4>
			<p>You have a vividly imaginative mind and enjoy getting lost in thought. Time tends to pass quickly when you reflect on things and start daydreaming.</p>

			<div className="quote">
				<p><i>"Fantasy is escapist, and that is its glory."</i></p>
				<p><b>- J.R.R. Tolkien</b></p>
			</div>

			<h4 id="Ofantasy">When You Have Low-Openness To Fantasy</h4>
			<p>You prefer acting to thinking. You seldom daydream or get lost in thought. Your practical nature makes it more difficult for you to imagine things and you may sometimes feel that you don’t have such a good imagination.</p>
			
			<div className="quote">
				<p><i>"Vision wihout action is merely a dream. Action without vision just passes the time. Vision with action can change the world."</i></p>
				<p><b>- Joel A. Barker</b></p>
			</div>
		</section>

		<hr />

		<section id="section-4" className="offset-anchor">
			<div className="myimghor">
				<img src={aestheticsimg} alt="Openness spectrum" />
			</div>

			<h2>O3 - Openness to aesthetics </h2>
			<div className="notabene">
				Openness to aesthetics describes your appreciation of art and beauty.
			</div>

			<h4 id="Oaesthetics">When You Have High-Openness to Aesthetics</h4>
			<p>You believe in the importance of art and are sensitive to the beauty of the world that surrounds you. Your artistic nature makes you enjoy all expressions of art and helps you to curate your sophisticated appearance and environment. You sometimes crave to express yourself creatively.</p>
			<p>You enjoy the beauty of nature and see beauty in things that others might not notice, such as birds or flowers. Beautifully designed machines and constructions may move you.</p>

			<div className="quote">
				<p><i>"The world always seems brighter when you've just made something that wasn't there before."</i></p>
				<p><b>- Neil Gaiman</b></p>
			</div>

			<h4 id="Oaesthetics">When You Have Low-Openness To Aesthetics</h4>
			<p>Art rarely makes you feel anything significant and you may not understand it. You tend not to be interested going to concerts, museums, dance performances or poetry recitals. </p>

			<div className="quote">
				<p><i>"Sometimes it is the simplest, seemingly most inane, most practical stuff that matters the most to someone."</i></p>
				<p><b>- Patty Duke</b></p>
			</div>
		</section>

		<hr />

		<section id="section-5" className="offset-anchor">
			<div className="myimghor">
				<img src={emotionalityimg} alt="Openness spectrum" />
			</div>

			<h2>O4 - Openness to Emotionality</h2>
			<div className="notabene">
				<p>Openness to emotionality describes your ability and willingness to explore your inner emotions and feelings.</p>
			</div>

			<h4 id="Oemotionality">When You Have High-Openness To Emotionality</h4>
			<p>You tend to experience intense emotions and are able to feel and understand the emotions of others well. You try to understand yourself and enjoy examining yourself and your life through deep introspection.</p>
			<p>You are passionate about the causes you want to advance and speak of them with a contagious enthusiasm that inspires others.</p>

			<div className="quote">
				<p><i>"Nothing great in the world has ever been accomplished without passion."</i></p>
				<p><b>- George Hegel</b></p>
			</div>

			<h4 id="Oemotionality">When You Have Low-Openness To Emotionality</h4>
			<p>You are not easily affected by your emotions and seldom get emotional or rarely notice your emotional reactions. You experience very few emotional highs and lows and have a difficult time understanding people who get emotional.</p>

			<div className="quote">
				<p><i>“True courage is cool and calm. The bravest of men have the least of a brutal, bullying insolence, and in the very time of danger are found the most serene and free.”</i></p>
				<p><b>- Lord Shaftesbury</b></p>
			</div>
		</section>


		<hr />

		<section id="section-6" className="offset-anchor">
			<div className="myimghor">
				<img src={adventure1img} alt="Openness spectrum" />
			</div>

			<h2>O5 - Openness to adventure</h2>
			<div className="notabene">
				<p>Openness to adventure and action describes your openness to new experiences on a practical level.</p>
			</div>		
			
			<h4 id="Oadventure">When You Have High-Openness To Adventure</h4>
			<p>You prefer variety to routine and have a wide range of interests. You are focused on novelty and tackling new challenges to satisfy your adventurous nature. You are usually willing and eager to try new things and visit new places.</p>

			<div className="quote">
				<p><i>"Jobs fill your pockets, but adventures fill your soul."</i></p>
				<p><b>- Jaime Lyn</b></p>
			</div>

			<h4 id="Oadventure">When You Have Low-Openness To Adventure</h4>
			<p>You prefer to stick with things that you know and like the tried-and-true. Your routines matter more to you than variety of experiences.</p>
			<p>You tend to dislike change and are more a creature of habit, attached to your conventional ways, and you thrive in your narrower field of interests. You tend to be more risk averse and change averse than others and are therefore more cautious and consistent by nature.</p>

			<div className="quote">
				<p><i>“Good habits are worth being fanatical about.”</i></p>
				<p><b>- John Irving</b></p>
			</div>
		</section>


		<hr />


		<section id="section-7" className="offset-anchor">
			<div className="myimghor">
				<img src={valuesimg} alt="Openness spectrum" />
			</div>

			<h2>O6 - Openness to values</h2>
			<div className="notabene">
				<p>Openness to values describes your readiness to challenge your own values and those of authority figures.</p>
			</div>

			<h4 id="Ovalues">When You Have High-Openness To Values</h4>
			<p>You tend to be an idealist and believe that there is no absolute right or wrong. You are more tolerant of other people’s beliefs, behaviors, and social diversity. You appreciate discussing diverse views. You may be more interested in discussions of more liberal ideas than conservative ones and following a more unconventional path.</p>

			<div className="quote">
				<p><i>"Anger and intolerance are the enemies of correct understanding."</i></p>
				<p><b>- Mahatma Gandhi</b></p>
			</div>

			<h4 id="Ovalues">When You Have Low-Openness To Values</h4>
			<p>You see yourself as more of a conservative, conventional, and conforming person. You may be more interested in discussing traditional ideas over liberal ones.</p>

			<div className="quote">
				<p><i>“To be conservative, then, is to prefer the familiar to the unknown, to prefer the tried to the untried, fact to mystery, the actual to the possible, the limited to the unbounded, the near to the distant, the sufficient to the superabundant, the convenient to the perfect, present laughter to utopian bliss.”</i></p>
				<p><b>- Michael Joseph Oakeshott</b></p>
			</div>
		</section>
		
		<hr />


		<section id="section-8" className="offset-anchor">
			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>Your OCEAN personality traits shape your life, and your life shapes your personality traits. In this module, you will learn more about how you can better understand the OCEAN personality traits model and how to take action.</p>
			<p>Feel free to explore the following topics:</p>

			<section className="course-tables-center my-4">
				<CardDeck className="mb-3">
					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={groupoffriends} variant="top" className="mb-3" />
						<Card.Title className="m-3">What are your Big Five personality traits?<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/ocean-personality-traits" className="stretched-link"></a>
					</Card>

					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={nurturinggrowth} variant="top" className="mb-3" />
						<Card.Title className="m-3">Grow your EI with MyOceanity<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/myoceanity-and-ei" className="stretched-link"></a>
					</Card>

					<Card className="hover-increase" style={{ width: '18rem' }}>
						<Card.Img as={Image} src={studyingmaterials} variant="top" className="mb-3" />
						<Card.Title className="m-3">Learn more about the Big Five Model<Icon.ArrowRight className="ml-2" /></Card.Title>
						<a href="/oceanology" className="stretched-link"></a>
					</Card>
				</CardDeck>
			</section>


			<div className="content-btn-box">
				<a href="/ei-course" className="content-btn">Back to the course<Icon.ArrowRight className="ml-2" /></a>
			</div>
		</section>

		
		<div className="separator" />
		<div className="separator" />
		</body>

   </div>
  )
}

export default OpennessFacets;

