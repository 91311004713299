import React from 'react';
import Result from './Result'
import {useParams} from "react-router-dom";

function Fake(props : any) {
      let { o,c,e,a,n }  = useParams();
     const result =  {
      AGREEABLENESS: a,
      CONSCIENTIOUSNESS: c,
      EXTROVERSION: e,
      NERVOSITY: n,
      OPENNESS: o
    };
    return (<Result quizResult={result} username="fake user"/>);
  }
export default Fake;