import React from 'react';
import { Accordion, Card, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useTranslation } from "react-i18next";


import elementsofei from "../images/elements-of-ei.png";
import newspaperimg from '../images/newspaper.jpg';
import carefreeimg from '../images/carefree.jpg';
import readingimg from '../images/reading.jpg';
import trustimg from '../images/trust.jpg';
import worry1img from '../images/worry-1.jpg';
import worry3img from '../images/worry-3.jpg';
import conversation2 from '../images/conversation2.jpg';
import conversation3 from '../images/conversation3.jpg';
import conversation4 from '../images/conversation4.jpg';
import conversation5 from '../images/conversation5.jpg';
import conversation6 from '../images/conversation6.jpg';
import conversation7 from '../images/conversation7.jpg';
import conversation8 from '../images/conversation8.jpg';
import conversation9 from '../images/conversation9.jpg';
import highfive from '../images/highfive.jpg';
import communicationtriangle from '../images/communication-triangle.png';
import omismatch from '../images/openness-mismatch.png';
import cmismatch from '../images/conscientiousness-mismatch.png';
import emismatch from '../images/extroversion-mismatch.png';
import amismatch from '../images/agreeableness-mismatch.png';
import nmismatch1 from '../images/nervosity-mismatch-1.png';
import nmismatch2 from '../images/nervosity-mismatch-2.png';
import chrisattributecloud from '../images/chris-attribute-cloud.png';
import osample from '../images/o-continuum-sample.png';
import csample from '../images/c-continuum-sample.png';
import esample from '../images/e-continuum-sample.png';
import asample from '../images/a-continuum-sample.png';
import nsample from '../images/n-continuum-sample.png';



function SamplePersonalReport(props: any) {

	const { i18n } = useTranslation();

	
return (
<div className="masthead">
	<section className="grey-section">
		<h2 className="text-white font-weight-bold">{i18n.t('this-is-what-your-personal-page-will-look-like-the-below-report-is-based-on-chris-traits-discover-your-traits-by-clicking-on-the-button-below')}</h2>

			<div className="content-btn-box">
				<a href="/user/premium/my-assessments" className="content-btn-white">{i18n.t('discover-your-traits')}<Icon.ArrowRight className="ml-2" /></a>
			</div>
		<div className="separator mb-5" />
	</section>

	<div className="three-dots-hr">
		<p>...</p>
	</div>

	<p>Dear <b>Chris</b>,</p>

	<p>Thank you for your trust! Assessing your OCEAN personality traits is your first step towards increasing your self-awareness and personal effectiveness. Self-awareness is crucial, because the better you understand yourself, the better you will be able to identify your personal blindspots and to constructively address them.</p>
	<p>Understanding your personality traits will help you to take advantage of your strengths, and will offer strategies on how you can compensate for your weaknesses and fix the anchors which hold you back. This is what Self-Development is all about.</p>
	
	<div className="myimghor">
		<img src={elementsofei} alt="The 4 elements of emotional intelligence" />
	</div>

	<div className="imgcaption">
		<p><b>Your personality traits can help you grow all <a href="/what-is-ei"> 4 elements of emotional intelligence</a></b></p>
	</div>
	
	<p>You will discover your Big Five OCEAN personality traits, which will shortly explain:</p>
		
	<ul>
		<li>Tendencies you may usually have</li>
		<li>How others may perceive you</li>
		<li>Examples of personality traits misunderstandings</li>
		<li>How to work well with different personality traits</li>
		<li>Questions to assess each personality trait</li>
	</ul>
		
	<div className="notabene">
		<b>It is crucial to understand that there is no "better than". “High” is not better than “Low”, and vice versa. What matters is understanding how our differences enrich us, rather than how they limit us.</b> <b>How they make us stronger, together</b>
	</div>		
	
	<p>Remember that the OCEAN acronym stands for:</p>

	<ul>
		<li>Openness to experience</li>
		<li>Conscientiousness</li>
		<li>Extroversion</li>
		<li>Agreeableness</li>
		<li>Nervosity</li>
	</ul>

	<div className="notabene">
		It’s important to understand that our personality traits are not entirely fixed in time. Our experiences shape our personality traits over time.
	</div>
	
	<p>For instance, as they become older, people tend to become:</p>

	<ul>
		<li>More agreeable and conscientious</li>
		<li>Less open to experiences, extraverted, and nervous</li>
	</ul>


	<h2>Your Big Five Traits at a Glance</h2>

	<Container className="accordion-width">
		<Row className="my-report-row mb-3">
			<Col className="col-1 my-report-col-title round"><a href="#Otitle" className="report-btn-a">O</a></Col>
			<Col className="col-11"><ProgressBar className="reportprogressbar" now={39} label={`${39}%`}/></Col>
		</Row>

		<Row className="my-report-row mb-3">
			<Col className="col-1 my-report-col-title round"><a href="#Ctitle" className="report-btn-a">C</a></Col>
			<Col className="col-11"><ProgressBar className="reportprogressbar" now={36} label={`${36}%`} /></Col>
		</Row>
		
		<Row className="my-report-row mb-3">
			<Col className="col-1 my-report-col-title round"><a href="#Etitle" className="report-btn-a">E</a></Col>
			<Col className="col-11"><ProgressBar className="reportprogressbar" now={23} label={`${23}%`} /></Col>
		</Row>

		<Row className="my-report-row mb-3">
			<Col className="col-1 my-report-col-title round"><a href="#Atitle" className="report-btn-a">A</a></Col>
			<Col className="col-11"><ProgressBar className="reportprogressbar" now={88} label={`${88}%`} /></Col>
		</Row>

		<Row className="my-report-row mb-3">
			<Col className="col-1 my-report-col-title round"><a href="#Ntitle" className="report-btn-a">N</a></Col>
			<Col className="col-11"><ProgressBar className="reportprogressbar" now={82} label={`${82}%`} /></Col>
		</Row>
	</Container>

	<div className="myimghor">
		<img src={chrisattributecloud} alt="Chris' attribute cloud" />
	</div>

	<div className="three-dots-hr">
		<p>...</p>
	</div>

	<h2 id="Otitle">Your Openness To Experience</h2>

	<div className="myimghor">
		<img src={osample} alt="Openness spectrum" />
	</div>

	<p>This trait describes:</p>

	<ul>
		<li>How much do you seek and appreciate new ideas and experiences?</li>
		<li>How curious are you?</li>
		<li>How creative and imaginative are you?</li>
		<li>How much do you appreciate variety and originality?</li>
		<li>How receptive to change are you?</li>
	</ul>


	<h3>You Have Low-Openness Levels</h3>

	<div className="myimg">
		<img src={newspaperimg} alt="Low Openness Routine" />
	</div>

	<div className="imgcaption">
		<p>What are your favorite routines?</p>
		<p>Photo by <a href="https://www.pexels.com/@elijahsad">Elijah O'Donnell</a> from <a href="https://www.pexels.com/photo/black-man-reading-a-newspaper-3473492/">Pexels</a></p>
	</div>

	<p>You tend to prefer sticking to your habits and routines, enjoying the comfort of what is already known. Why change what works well?</p>

	<div className="quote">
		<p><i>“Good habits are hard to develop but easy to live with; bad habits are easy to develop but hard to live with. The habits you have and the habits that have you will determine almost everything you achieve or fail to achieve.”</i></p>
		<p><b>- Brian Tracy</b></p>
	</div>

	<h3>Your 6 Openness to Experience Facets</h3>

	<p>Openness to experience can be broken down into six facets which explore how sensible and curious you are to your inner worlds and the world around you:</p>

	<ol>
		<li><b>Openness to ideas</b> describes your intellectual curiosity.</li>
		<li><b>Openness to fantasy</b> describes your receptivity to the inner world of imagination. Imagination is extremely useful to step outside of yourself and reflect on how other people may experience and perceive you.</li>
		<li><b>Openness to aesthetics</b> describes your appreciation of art and beauty.</li>
		<li><b>Openness to emotionality</b> describes your ability and willingness to explore your inner emotions and feelings.</li>
		<li><b>Openness to adventure</b> and action describes your openness to new experiences on a practical level.</li>
		<li><b>Openness to values</b> describes your readiness to challenge your own values and those of authority figures. </li>
	</ol>

	<div className="separator" />

	<section className="accordion-width">
	<Accordion>
		<Card className="border-0">
			<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
			<b className="mr-3">{i18n.t('Discover Your 6 Openness to Experience Facets')}</b><Icon.ChevronDown className="ml-2"/>
			</Accordion.Toggle>

			<Accordion.Collapse eventKey="0">
				<Card.Body>
					<h4 id="Oideas">O1 | High-Openness To Ideas</h4>
					<p>You enjoy thinking about abstract concepts and things, and can handle a lot of information at once, which helps you to think outside the box and be more inventive, original, and innovative than other people. You love to read challenging material and have a rich vocabulary.</p>
					<p>You are more interested in intellectual pursuits than anything else and seek out to understand the patterns of the universe around you. You are an experimental person who likes to test their ideas.</p>

					<div className="quote">
						<p><i>"An invasion of armies can be resisted, but not an idea whose time has come."</i></p>
						<p><b>- Victor Hugo</b></p>
					</div>

					<h4 id="Ofantasy">O2 | High-Openness To Fantasy</h4>
					<p>You have a vividly imaginative nature and enjoy getting lost in thought. Time tends to pass quickly when you reflect on things and start daydreaming.</p>

					<div className="quote">
						<p><i>"Fantasy is escapist, and that is its glory."</i></p>
						<p><b>- J.R.R. Tolkien</b></p>
					</div>

					<h4 id="Oaesthetics">O3 | Low-Openness To Aesthetics</h4>
					<p>Art rarely makes you feel anything significant and you may not understand it. You tend not to be interested going to concerts, museums, dance performances or poetry recitals. </p>

					<div className="quote">
						<p><i>"Sometimes it is the simplest, seemingly most inane, most practical stuff that matters the most to someone."</i></p>
						<p><b>- Patty Duke</b></p>
					</div>

					<h4 id="Oemotionality">O4 | High-Openness To Emotionality</h4>
					<p>You tend to experience your emotions intensely and are able to feel and understand the others’ emotions well. You try to understand yourself and enjoy examining yourself and your life through deep introspection.</p>
					<p>You are passionate about the causes you want to advance and speak of them with a contagious enthusiasm that inspires others.</p>

					<div className="quote">
						<p><i>"Nothing great in the world has ever been accomplished without passion."</i></p>
						<p><b>- George Hegel</b></p>
					</div>

					<h4 id="Oadventure">O5 | Low-Openness To Adventure</h4>
					<p>You prefer to stick with things that you know and like the tried-and-true. Your routines matter more to you than variety of experiences.</p>
					<p>You tend to dislike change and are more a creature of habit, attached to your conventional ways, and you thrive in your narrower field of interests. You tend to be more risk averse and change averse than others and are therefore more cautious and consistent by nature.</p>

					<div className="quote">
						<p><i>“Good habits are worth being fanatical about.”</i></p>
						<p><b>- John Irving</b></p>
					</div>

					<h4 id="Ovalues">O6 | Low-Openness To Values</h4>
					<p>You see yourself as more of a conservative, conventional, and conforming person. You may be more interested in discussing traditional ideas over liberal ones.</p>

					<div className="quote">
						<p><i>“To be conservative, then, is to prefer the familiar to the unknown, to prefer the tried to the untried, fact to mystery, the actual to the possible, the limited to the unbounded, the near to the distant, the sufficient to the superabundant, the convenient to the perfect, present laughter to utopian bliss.”</i></p>
						<p><b>- Michael Joseph Oakeshott</b></p>
					</div>
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	</Accordion>
	</section>

	<div className="separator" />


	<h4>How You May Be Perceived</h4>
	<p>Your cautious nature may make others - especially people with higher levels of openness to experience - perceive you as closed-minded or inflexible.</p>
	
	<h4>Self-Development Advice</h4>
	<p>You need to cherish your habits because they are part of who you are and contribute to your comfort and well-being. That being said, you will sometimes also need to go outside of your comfort zone and discover new activities or ways of doing things. Because <b>you never know what new activity today will become one of your favorite traditions tomorrow</b>.</p>
	
	<section className="story">
		<h3>Example of Openness Misunderstandings</h3>
		<p><b>Pauline, a High-Openness manager</b>, has recently taken over Patricia and Gary’s team. Together, the team has discussed how they can improve how they work as individual contributors and as a team.</p>
		<p>Unfortunately, Patricia did not realize that <b>Gary, a strongly Low-Openness person</b>, feels overwhelmed by all the changes she wants to implement. Gary has been with the company for over twenty years and enjoys the routines he created around his responsibilities and his workflow.</p>
		<p>Frustrated by Pauline’s unwillingness to understand how this situation affects him, he starts shutting down and struggles to adjust. Over time, their relationship sours, and his performance slowly declines.</p>
		
		<div className="myimghor">
			<img src={omismatch} alt="Openness to experience misunderstanding" />
		</div>
	</section>


	<h3>How to Work Well With High-Openness People</h3>
	<p>Ask <b>what new skill sets or experiences they want to acquire</b> and assign them on relevant projects that match their interests and development plans. Encourage their desire to learn new things and give them enough time and resources to work on expanding their skill sets. </p>
	<p>Emphasize the benefits or opportunities when pitching them to try something new. Then, let them share their thoughts, ideas or concerns to build on your proposal. Let them internalize them and make them their own.</p>

	<div className="myimghor">
		<img src={conversation8} alt="Working with high-openness individuals" />
	</div>

	<div className="imgcaption">
		<p>Photo by <a href="https://www.pexels.com/@cottonbro">cottonbro</a> from <a href="https://www.pexels.com/photo/man-and-woman-leaning-on-table-staring-at-white-board-on-top-of-table-having-a-meeting-3205570/">Pexels</a></p>
	</div>


	<h3>How to Work Well With Low-Openness People</h3>
	<p>Look at and ask about their previous and favorite experiences, and assign them on projects that <b>build on their existing skill sets and interests</b>. Focus on what they already know and are comfortable with. </p>
	<p>Encourage them to <b>deepen their areas of expertise</b>, because assigning them to too many new, unfamiliar tasks may scare them and impact their well-being and performance.</p>
	<p>Change management can be especially challenging for Low-Openness people. In their minds, you cannot spell <b><i>CHA</i></b>LLE<b><i>NGE</i></b> without <b><i>CHANGE</i></b>. They are likely going to apprehend changes forced in their lives.</p>
	<p>To make things easier on them, <b>emphasize what is not changing</b>. Then, reassure them by sharing all the facts that explain why change is necessary and how they can benefit from it. How can this change improve their daily lives and work? The goal is to minimize the change or the efforts they will have to put in as a result of the change.</p>

	<div className="myimghor">
		<img src={conversation3} alt="Working with low-openness individuals" />
	</div>

	<div className="imgcaption">
		<p>Photo by <a href="https://www.pexels.com/@mentatdgt-330508">mentatdgt</a> from <a href="https://www.pexels.com/photo/man-sitting-on-chair-in-front-of-woman-2029619/">Pexels</a></p>
	</div>


	<h3>Questions to Ask to Evaluate Openness to Experience</h3>
	<h4>Have you ever tried ____? <br /> Would you?</h4>
	<p><b>High-Openness individuals</b> will likely answer with indifference if they already have, or with great curiosity if they haven’t, asking you questions to know more about the experience.</p>
	<p><b>Low-Openness individuals</b> will likely answer with enthusiasm if the experience became part of their routines, or with reluctance if they didn’t, or with apprehension from a new experience that makes them uncomfortable.</p>

	<h4>What’s your favorite ____? <br /> What else do you like?</h4>
	<p><b>High-Openness individuals</b> will likely have a broader range of experiences to share, investigating about what others they might have missed and will have to experience for themselves in the future.</p>
	<p><b>Low-Openness individuals</b> will likely list only a few - if not just the one - preferences, emphasizing how this is their favorite and how they may not want to be disappointed by other experiences.</p>

	<h4>What’s your plan for your next holiday? <br /> Have you already been there?</h4>
	<p><b>High-Openness individuals</b> will likely talk about new places they want to visit or which will provide them with the opportunity to experience new and exciting adventures.</p>
	<p><b>Low-Openness individuals</b> will likely talk about places they have already visited and are fond of, because they feel familiar. They may also mention their favorite spots and activities they usually do there.</p>


	
	<div className="three-dots-hr">
		<p>...</p>
	</div>


	<h2 id="Ctitle">Your Conscientiousness</h2>

	<div className="myimghor">
		<img src={csample} alt="Conscientiousness spectrum" />
	</div>

	<p>This trait describes how you get things done:</p>

	<ul>
		<li>How self-disciplined are you?</li>
		<li>How organized are you?</li>
		<li>How reliable are you?</li>
		<li>How persistent are you?</li>
	</ul>


	<h3>You Have Low-Conscientiousness Levels</h3>

	<div className="myimghor">
		<img src={carefreeimg} alt="Low conscientiousness carefree" />
	</div>

	<div className="imgcaption">
		<p>Being carefree and easygoing makes life more enjoyable, right?</p>
		<p>Photo by <a href="https://www.pexels.com/@nappy">nappy</a> from <a href="https://www.pexels.com/photo/female-leisure-recreation-relaxation-1154638/">Pexels</a></p>
	</div>

	<p>You tend to prefer strategic thinking and keeping things simple, avoiding the hassle of planning everything in excruciating details.</p>

	<div className="quote">
		<p><i>"Perfection is achieved, not when there is nothing more to add, but when there is nothing left to take away."</i></p>
		<p><b>- Antoine de Saint-Exupéry</b></p>
	</div>

	<h3>Your 6 Conscientiousness Facets</h3>

	<p>Conscientiousness can be broken down into six facets, which describe how industrious and orderly you are:</p>

	<ol>
		<li><b>Achievement striving</b> describes your need for personal achievement and sense of direction.</li>
		<li><b>Self-efficacy</b> describes your beliefs about how well you can execute courses of action required to deal with prospective situations. <a href="https://www.semanticscholar.org/paper/Self-efficacy-mechanism-in-human-agency.-Bandura/8beec556fe7a650120544a99e9e063eb8fcd987b">Source</a></li>
		<li><b>Self-discipline</b> describes your capacity to begin tasks and follow through to completion despite boredom or distractions.</li>
		<li><b>Cautiousness</b> describes your tendency to think things through before acting or speaking.</li>
		<li><b>Dutifulness</b> describes how important fulfilling your moral obligations is to you.</li>
		<li><b>Orderliness</b> describes your ability to be well organized and diligent.</li>
	</ol>

	<div className="separator" />

	<section className="accordion-width">
	<Accordion>
		<Card className="border-0">
			<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
			<b className="mr-3">{i18n.t('Discover Your 6 Conscientiousness Facets')}</b><Icon.ChevronDown className="ml-2"/>
			</Accordion.Toggle>

			<Accordion.Collapse eventKey="0">
				<Card.Body>

					<h4 id="Cachievement">C1 | Low Achievement-Striving</h4>
					<p>Succeeding at all costs is not your priority. You tend to be satisfied by good enough solutions and prefer to put less time and effort into your work over grinding. Sometimes, your tendency to do just enough work to get by may mean that you fail to complete what you were supposed to do. You tend to look for shortcuts or at least for the easiest way to complete certain tasks.</p>
					<p>Self-development advice: Keep in mind that although you may get by in some situations with a minimum effort, in doing so you will not fully realize your potential in the long run. There is no substitute for hard work.</p>

					<div className="quote">
						<p><i>"Done is better than perfect."</i></p>
						<p><b>- Sheryl Sandberg</b></p>
					</div>

					<h4 id="Cselfefficacy">C2 | Low Self-Efficacy</h4>
					<p>You sometimes think difficult situations are beyond your capabilities or that you have little to contribute to the team. You may misjudge situations and don’t see the consequences of your actions.</p>

					<div className="quote">
						<p><i>"Never give in except to convictions of honor and good sense. Never yield to force; never yield to the apparently overwhelming might of the enemy."</i></p>
						<p><b>- Winston Churchill</b></p>
					</div>

					<h4 id="Cselfdiscipline">C3 | Low Self-Discipline</h4>
					<p>You may find it difficult to get down to work and need a push to get started to avoid neglecting your duties. You have a hard time starting tasks because of your tendencies to postpone decisions and to procrastinate important things in your life. Sometimes, you also may leave things unfinished because something distracted you from what you were doing.</p>
					<p>As a result, you may be late or miss deadlines because of your unconscious tendency to waste your time. Keep in mind that this may impact the people you work with and may irritate them.</p>

					<div className="quote">
						<p><i>"In any moment of decision, the best thing you can do is the right thing, the next best thing is the wrong thing, and the worst thing you can do is nothing."</i></p>
						<p><b>- Theodore Roosevelt</b></p>
					</div>

					<h4 id="Ccautiousness">C4 | High Cautiousness</h4>
					<p>Getting things right is a priority to you. You actively seek to avoid mistakes because of your desire for quality and accuracy. Sometimes, you may need to check your work two or three times before being satisfied. You can never be too careful and controlled work matters to you.</p>
					<p>Predictable outcomes are important to you, which will drive you to make sure that everyone is on the same page before proceeding. Because of this, you may ask a lot of in-depth, skeptical questions to clarify what others are thinking, or point out flaws that others may have missed. Paying attention to details matters to you.</p>
					<p>You like to take time to carefully think things through before you speak to make sure people will clearly understand what you think. You also tend to prefer analyzing all the options available, taking your time rather than taking quick or risky decisions. Stay aware that your careful nature and slower pace may be perceived by others as perfectionism.</p>

					<div className="quote">
						<p><i>"Be not afraid of going slowly, be afraid only of standing still."</i></p>
						<p><b>- Chinese proverb</b></p>
					</div>

					<h4 id="Cdutifulness">C5 | High Dutifulness</h4>
					<p>Fulfilling your duties and keeping your promises is a priority to you. You listen to your conscience and try your best do to the right thing and do it the right way: Telling the truth, following the rules, and paying your bills on time is natural and important to you.</p>

					<div className="quote">
						<p><i>"Good people do not need laws to tell them to act responsibly, while bad people will find a way around the laws."</i></p>
						<p><b>- Plato</b></p>
					</div>

					<h4 id="Corderliness">C6 | Low Orderliness</h4>
					<p>You are usually not bothered by disorder. Taking care of things is not very important to you, and you may forget to return things or put them back where they belong. You are content with your usual appearance and environment. Sometimes, you have to ask for pens and chargers because you forgot your own.</p>
					<p>You have no issues to improvise, acting in a more disorganized and unplanned fashion than other people. You tend to dislike excessive structure and schedule. Keep in mind that your carefree nature may be perceived as negligent, lazy and messy.</p>

					<div className="quote">
						<p><i>"Clutter and mess show us that life is being lived."</i></p>
						<p><b>- Anne Lamott</b></p>
					</div>					
					
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	</Accordion>
	</section>

	<div className="separator" />
	
	
	<h4>How You May Be Perceived</h4>
	<p>Your desire for being more strategic and your ability to navigate uncertainty with fewer information is a strength to making tough decisions in difficult times.</p>
	<p>However, people - especially individuals with higher levels of conscientiousness - may start questioning how reliable you are when you forget about what they asked you to do or when you miss a deadline. They may look down upon your tendency to forget important events or discussions, or on how unorganized it makes you look.</p>
	<p>Taking good notes, planning your priorities in advance and preparing for emergencies are simple steps you can take to become better prepared without having to worry too much about it.</p>


	<section className="story">
		<h3>Example of Conscientiousness Misunderstandings</h3>
		<p><b>Jon, a highly conscientious person</b>, writes a lengthy email filled with details, hoping to reassure and guide <b>Stacy, the low-conscientious intern</b> who recently joined the company.</p>
		<p>As a result, Stacy is overwhelmed, confused, and doesn’t know how or where to start the work Jon asked her to do. Too much information paralyses her.</p>
		<p>At the end of her six months internship, Stacy is frustrated. She was not able to learn as much as she could because of how long it took her to understand and become comfortable with Jon’s communication style and continuous attention to details.</p>
		
		<div className="myimghor">
			<img src={cmismatch} alt="Conscientiousness misunderstanding" />
		</div>
	</section>


	<h3>How to Work Well With High-Conscientiousness People</h3>
	<p>Spend enough time at first to <b>give them as much information as they need</b> to feel comfortable to get started. Then, break down the goals of each project into clear sub-parts and discuss what needs to be done and how they want to approach their work. When following up, give them in-depth reports with as many details as possible.</p>
	<p><b>Be prepared for them to ask many questions</b>. Get ready to review them together and answer all of them. Skipping information may make their wheels spin and prevent them from getting back to work.</p>

	<div className="myimghor">
		<img src={conversation7} alt="Working with high-conscientiousness individuals" />
	</div>

	<div className="imgcaption">
		<p>Photo by <a href="https://www.pexels.com/@olly">Andrea Piacquadio</a> from <a href="https://www.pexels.com/photo/photo-of-woman-talking-with-another-woman-3768129/">Pexels</a></p>
	</div>


	<h3>How to Work Well With Low-Conscientiousness People</h3>
	<p><b>Clarify the big goals of each projects as well as your overall expectations</b>. Avoid going into too many details, as too much information will likely overwhelm them. Instead, give them a brief summary, which contains the most critical facts.</p>
	<p>Give them the freedom to come up with their own way of doing things to achieve your goals and expectations. <b>Discuss together the next steps to make sure you are aligned and they aren’t missing key steps</b>.</p>

	<div className="myimghor">
		<img src={conversation4} alt="Working with low-conscientiousness individuals" />
	</div>

	<div className="imgcaption">
		<p>Photo by <a href="https://www.pexels.com/@wildlittlethingsphoto">Helena Lopes</a> from <a href="https://www.pexels.com/photo/photograph-of-men-having-conversation-seating-on-chair-1015568/">Pexels</a></p>
	</div>


	<h3>Ideas for Communicating With Both Low- And High-Conscientious People</h3>
	<div className="notabene">
		Start with your recommendation, summary, and next steps. Then, follow with the essential insights which will support your rationale.
	</div>

	<div className="myimghor">
		<img src={communicationtriangle} alt="The communication triangle" className="w-50" />
	</div>

	<div className="imgcaption">
		<p>Think of the message you want to communicate as an inverted triangle. Start with the core, then go into more details if necessary</p>
	</div>

	<p>This provides people with the choice to dig into more details, if they need to. Still, because you started with the essential part first, you made their lives easier, and they will likely appreciate it. This is known as the Bottom-Line UpFront message crafting method, or BLUF.</p>
	<p>The top, the largest base, represents your core message. It’s the most crucial part that people need to know about. If they only read this first part, they will still get most of the insights you wanted to convey.</p>
	<p>Again, your core message may be a recommendation, summary, next steps or a question you want your reader to reflect on and answer. The reason why starting with the core message is key is, that it saves a lot of time and effort for your readers. </p>
	<p>The rest of your message will provide them with additional information and levels of details which will allow them to become comfortable in supporting you or the change you are advancing. The more you move towards the peak of the triangle, the more accurate information you provide for those who need to understand as much as possible before being able to make a decision or take action.</p>
	<p>People with lower levels of conscientiousness are more likely to be convinced with fewer information and may take action without needing to thoroughly read the rest of your message.</p>
	<p>In contrast, by explaining your rationale and sharing the facts that support them, you will be able to also convince people with higher levels of conscientiousness.</p>
	<p>From personal experience, I noticed that we tend to bury the core message at the bottom of our emails and documents, because we want to convince people before we ask for something or share our key insights.</p>
	<p>The risk is that people may quickly lose interest or miss the key facts you truly wanted to convey. Some may never read to the end of your message, meaning they will miss the core purpose of your request.</p>
	<p>This is what makes communication challenging for highly conscientious people: All the information seems crucial. They are afraid that excluding a few details may make the entire message less impactful, convincing, meaningful or actionable.</p>
	<p>Remember: If your core message is not clear and mentioned first, your readers will have a hard time understanding what you expect of them and how they can help.</p>


	<h3>Questions to Ask to Evaluate Conscientiousness</h3>
	<h4>What exciting projects are you working on? <br /> What’s your plan?</h4>

	<p><b>People with higher levels of conscientiousness</b> will answer by giving a long, comprehensive, organized, exhaustive list of tasks they need to do to achieve their objectives. They will talk at length about their motivations, why this work matters to themselves and their organizations, and how they will tackle this challenge.</p>
	<p><b>People with lower levels of conscientiousness</b> will answer by giving more brief, big picture overviews, perhaps without organizing their thoughts. The actions they will have to take, who will support them, will likely not be fully articulated or even known. Their answers will likely require more clarifications, as what is clear for them may not be so for you.</p>

	<h4>What are your goals for this year?</h4>
	<p><b>People with higher levels of conscientiousness</b> will again provide a detailed list of what they want to achieve, why they matter to them, and how they will achieve them, step by step. They will likely break down their objectives into clear milestones and timelines, to make sure that they can stay the course and increase the likelihood that they will achieve what they desire.</p>
	<p><b>People with lower levels of conscientiousness</b> will likely be more vague about their objectives, intentions, motivations, and how they will get things done.</p>


	<div className="separator" />
	<div className="separator" />
	<hr/>



	<h2 id="Etitle">Your Extroversion</h2>

	<div className="myimghor">
		<img src={esample} alt="Extroversion spectrum" />
	</div>

	<p>This trait describes how you approach and relate to people and the social world:</p>

	<ul>
		<li>How comfortable are you in larger groups of people?</li>
		<li>How comfortable are you in one-on-one interactions?</li>
		<li>How comfortable are you being alone with your thoughts?</li>
		<li>How energized are you by interacting with other people?</li>
	</ul>

	<div className="quote">
		<p><i>"Companionship is a foreign concept to some people. They fear it as much as the majority of people fear loneliness."</i></p>
		<p><b>- Criss Jami</b></p>
	</div>


	<h3>You Have Low-Extroversion Levels</h3>

	<div className="myimgvert">
		<img src={readingimg} alt="Low extroversion quiet time"/>
	</div>

	<div className="imgcaption">
		<p>We all need some quiet time to recharge</p>
		<p>Photo by <a href="https://www.pexels.com/@daria">Daria Shevtsova</a> from <a href="https://www.pexels.com/photo/woman-sitting-while-reading-a-book-1616779/">Pexels</a></p>
	</div>

	<p>As an introvert, you tend to prefer one-on-one interactions and evolving in groups of three to four people. You may dislike small talk, have difficulties initiating conversations, getting to know other people and sharing private information about yourself. That being said, once you trust someone, the walls usually slowly come down.</p>

	<div className="quote">
		<p><i>"Quiet people have the loudest minds."</i></p>
		<p><b>- Stephen Hawking</b></p>
	</div>

	<h3>Your 6 Extroversion Facets</h3>

	<p>Extroversion can be broken down into six facets which describe your levels of enthusiasm and assertiveness:</p>

	<ol>
		<li><strong>Gregariousness</strong> describes your preference for the company of others over solitude.</li>
		<li><strong>Cheerfulness</strong> describes your tendency to experience and spread positive emotions.</li>
		<li><strong>Warmth</strong> describes your interest in and friendliness towards others.</li>
		<li><strong>Excitement seeking</strong> describes your need for environmental stimulation.</li>
		<li><strong>Activity level</strong> describes your pace of living.</li>
		<li><strong>Assertiveness</strong> describes your social ascendancy and forcefulness of expression.</li>
	</ol>

	<div className="separator" />

	<section className="accordion-width">
	<Accordion>
		<Card className="border-0">
			<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
			<b className="mr-3">{i18n.t('Discover Your 6 Extroversion Facets')}</b><Icon.ChevronDown className="ml-2"/>
			</Accordion.Toggle>

			<Accordion.Collapse eventKey="0">
				<Card.Body>
					<h4 id="Egregariousness">E1 | Low Gregariousness</h4>
					<p>You prefer to be alone and avoid crowded events. You tend to work and think best alone, needing time to think before you answer and get back to the group. Your solitary, withdrawn and reserved nature may make others perceive you as a loner before they get to know you better.</p>
					<p>Quiet, more personal interactions allow you to thrive. Larger groups and crowded events are likely going to intimidate and drain you. Socializing with others may feel exhausting after a period of time and will require that you take time alone to recover from it.</p>

					<div className="quote">
						<p><i>"The highest form of love is to be the <b>protector</b> of another person's solitude."</i></p>
						<p><b>- Rainer Maria Rilke</b></p>
					</div>
					
					<div className="quote">
						<p><i>"Loneliness is the poverty of self; solitude is the richness of self."</i></p>
						<p><b>- Mary Sarton</b></p>
					</div>

					<h4 id="Echeerfulness">E2 | High Cheerfulness</h4>
					<p>You radiate joy and love life. Having fun, laughing and amusing your friends is a priority to you. Your positive, optimistic, enthusiastic, looking at the bright side of life nature is highly contagious.</p>

					<div className="quote">
						<p><i>"Walk with the dreamers, the believers, the courageous, the cheerful, the planners, the doers, the successful people with their heads in the clouds and their feet on the ground. Let their spirit ignite a fire within you to leave this world better than you found it."</i></p>
						<p><b>- Wilferd A. Peterson</b></p>
					</div>

					<h4 id="Ewarmth">E3 | Low Warmth</h4>
					<p>Because you may feel uncomfortable around others or aren’t necessarily interested in others, you are sometimes hard to get to know. You tend to dislike being the center of attention. Your reserved nature will often drive you to keep others at a distance or to avoid contact with them altogether.</p>

					<div className="quote">
						<p><i>"Forgive my indifference; I'd rather be distant than devastated."</i></p>
						<p><b>- Ahmed Mostafa</b></p>
					</div>

					<h4 id="Eexcitement">E4 | Low Excitement-Seeking</h4>
					<p>You prefer comfort over adventure and are more cautious, trying not to hurt yourself or put yourself in dangerous situations. No matter how thrilling they seem, high-adrenaline situations like hand gliding or bungee jumping don’t attract you.</p>
					<p>You tend to be energized by your inner world rather than by external stimulation, which makes you dislike loud music and events.</p>

					<div className="quote">
						<p><i>"The scars of others should teach us caution."</i></p>
						<p><b>- St. Jerome</b></p>
					</div>

					<h4 id="Eactivity">E5 | Low Activity Level</h4>
					<p>You like to take your time and let things proceed at their own, steady pace. You also enjoy calming down, relaxing and enjoying life without rushing through it, living a leisurely lifestyle. </p>

					<div className="quote">
						<p><i>"Never discourage anyone who continually makes progress, no matter how slow."</i></p>
						<p><b>- Plato</b></p>
					</div>

					<h4 id="Eassertiveness">E6 | Low Assertiveness</h4>
					<p>Because you tend to be more quiet and say little, preferring to keep in the background and holding back your opinions, you tend to wait for others to lead the way. You don’t like to draw attention to yourself and prefer to be more passive than active during conversations, to avoid standing in the spotlight and being the center of attention.</p>

					<div className="quote">
						<p><i>"I think a lot, but I don't say much."</i></p>
						<p><b>- Anne Frank</b></p>
					</div>
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	</Accordion>
	</section>

	<div className="separator" />


	<h4>How You May Be Perceived</h4>
	<p>Your tendency to do your best thinking alone and getting back to the group after in-person interactions may make others - especially people with higher levels of extroversion - perceive you as distant, sometimes even cold.</p>


	<section className="story">
		<h3>Example of Extroversion Misunderstandings</h3>
		<p><b>Dana is extremely introverted</b>. Her previous manager, Ellen, highly recommended her because of her ability to gather brilliant ideas and insights during projects.</p>
		<p>Her new manager, <b>a cheerful extrovert called Philip</b>, is excited about having her on the team and has high expectations about what she will bring to their meetings and projects.</p>
		<p>Unfortunately, Philip doesn’t understand how terrified Dana feels when he puts her on the spot in front of everyone or why she sometimes acts awkwardly during meetings. She doesn’t dare to speak up about it.</p>
		<p>Dana misses Ellen, who understood her preference to remain silent during meetings to think, and asked her to share her ideas later, either by email or during one-on-one conversations.</p>
		
		<div className="myimghor">
			<img src={emismatch} alt="Extroversion misunderstanding" />
		</div>
	</section>


	<h3>How to Work Well With Extroverts</h3>
	<p>It’s important to understand that extroverts thrive in social interactions. While they may feel draining for your, their tendencies to approach you, share about themselves and ask you to do the same is not a personal attack. It’s part of their nature.</p>
	<p>Their sociable nature also means that they dislike being alone, because they seek human contact to feel like they belong. Their energy and enthusiasm can be contagious. While they may sometimes feel intimidating, they can also energize you if you let them.</p>
	<p>Assign them to projects or roles that leverage their energy and social skills, such as brainstorming sessions, moderation, etc. They are great in asking and understanding what others think.</p>
	<p>Assign them to mentoring roles, to reduce the time they spend alone, which they might dread. Publicly praise their personal achievements and their team-building efforts.</p>

	<div className="myimghor">
		<img src={conversation6} alt="Working with extroverts" />
	</div>

	<div className="imgcaption">
		<p>Photo by <a href="https://www.pexels.com/@rethaferguson">RF._.studio</a> from <a href="https://www.pexels.com/photo/group-of-women-having-a-meeting-3810754/">Pexels</a></p>
	</div>


	<h3>How to Work Well With Introverts</h3>
	<p>First, it’s important to address a common misconception: Being shy is not necessarily equal to being an introvert. Research suggests that shyness is a blend between introversion and high-nervosity.</p>
	<p>Introverts are usually more difficult to approach in larger groups, but they may be more expressive and outgoing in one-on-one interactions or in small groups of a few people.</p>
	<p>Although it may seem counterintuitive, introverts can know as many people as extroverts do. The difference is that they thrive in smaller groups when connecting with people, while extroverts feel comfortable with engaging wither larger audiences.</p>
	<p>Give them enough time alone to do their thinking and their work. Assign them to projects with smaller teams and with fewer face-to-face interactions. Accept that they may come back a few days after your previous conversation to share their thoughts. Give them enough resources and learning materials to process things at their on pace and on their own.</p>
	<p>Accept that they may need personal space outside of their desk to work alone, away from the distractions and potentially draining interruptions they could otherwise experience.</p>
	<p>Unless they ask otherwise, praise their personal achievements and team-building efforts during one-on-one sessions rather than putting them on the spot in front of everyone else. They dislike being the center of attention.</p>
	<p>Leverage written communication to let them come back to you if they feel uncomfortable sharing their thoughts during your one-on-one conversations.</p>

	<div className="myimghor">
		<img src={conversation2} alt="Working with introverts" />
	</div>

	<div className="imgcaption">
		<p>Photo by <a href="https://www.pexels.com/@mentatdgt-330508">mentatdgt</a> from <a href="https://www.pexels.com/photo/two-woman-chatting-1311518/">Pexels</a></p>
	</div>


	<h3>Questions to Ask to Evaluate Extroversion</h3>
	<h4>Do you know anyone else here?</h4>
	<p><b>Extroverts</b> are likely to answer this question enthusiastically, by dropping names or by offering to introduce you to people they think you would enjoy meeting.</p>
	<p><b>Introverts</b> are likely going to look nervous by your question or by becoming suddenly aware of the crowd in which they stand. They may ask to move to a calmer, more private space to talk or to introduce you to someone they know.</p>

	<h4>What are your plans for the weekend?</h4>
	<p><b>Extroverts</b> are likely going to talk with excitement about their planned adventures, how much they are going to do to keep themselves busy, and the people - potentially larger groups of people - they will spend their time with.</p>
	<p><b>Introverts</b> are likely going to talk about relaxing or enjoying a few activities spread out over the weekend, which should include only a smaller group of participants. </p>


	<div className="separator" />
	<div className="separator" />
	<hr/>



	<h2 id="Atitle">Your Agreeableness</h2>
	
	<div className="myimghor">
		<img src={asample} alt="Agreeableness spectrum" />
	</div>

	<p>This trait describes how you approach cooperation and working with others:</p>

	<ul>
		<li>How empathetic are you? How much do you pay attention to other people’s emotions and well-being?</li>
		<li>How quick to forgive are you?</li>
		<li>How much do you value solving problems over dealing with people?</li>
	</ul>

	<h3>You Have High-Agreeableness Levels</h3>

	<div className="myimg">
		<img src={trustimg} alt="High agreeableness trust" />
	</div>

	<div className="imgcaption">
		<p>Trust makes anything possible</p>
		<p>Photo by <a href="https://www.pexels.com/@pixabay">Pixabay</a> from <a href="https://www.pexels.com/photo/hands-people-friends-communication-45842/">Pexels</a></p>
	</div>

	<p>In situations where you feel safe and at ease, you will care about others and easily trust them. You will encourage people to come to you for help and avoid confrontations.</p>

	<div className="quote">
		<p><i>"When you are kind to others, it not only changes you, it changes the world."</i></p>
		<p><b>- Harold Kushner</b></p>
	</div>

	<h3>Your 6 Agreeableness Facets</h3>

	<p>Agreeableness can be broken down into six facets which describe your levels of compassion and politeness:</p>

	<ul>
		<li><b>Tender-mindedness</b> describes your attitude of sympathy for others.</li>
		<li><b>Altruism</b> describes your active concern for the welfare of others.</li>
		<li><b>Trust</b> describes your beliefs in the sincerity and good intentions of others.</li>
		<li><b>Compliance</b> describes your response to interpersonal conflict.</li>
		<li><b>Modesty</b> describes your tendency to play down your own achievements and to be humble.</li>
		<li><b>Straightforwardness</b> describes your frankness in expression.</li>
	</ul>

	<div className="separator" />

	<section className="accordion-width">
	<Accordion>
		<Card className="border-0">
			<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
			<b className="mr-3">{i18n.t('discover-your-6-agreeableness-facets')}</b><Icon.ChevronDown className="ml-2"/>
			</Accordion.Toggle>

			<Accordion.Collapse eventKey="0">
				<Card.Body>
					<h4 id="Atender">A1 | High Tender-Mindedness</h4>
					<p>Your caring nature makes you care for and understand for other people’s suffering. This means that you often suffer from seeing other people’s sorrows and suffering.</p>
					<p>You tend to feel sympathy for those who are worse off than yourself, such as the homeless and the less fortunate. This also will make you more prone to collaborate with others, rather than actively compete with them in a win-lose game. </p>

					<div className="quote">
						<p><i>"Too often we underestimate the power of a touch, a smile, a kind word, a listening ear, an honest compliment, or the smallest act of caring, all of which have the potential to turn a life around."</i></p>
						<p><b>- Leo Buscaglia</b></p>
					</div>

					<h4 id="Aaltruism">A2 | High Altruism</h4>
					<p>You are concerned about other people’s wellbeing and want to help others and make them feel welcome. Your tendency to anticipate the need of others, thoughtfulness, and charitable nature are your foundation. You don’t hesitate to serve others and contribute to the benefit of your group, putting the needs of others ahead of your own, willingly doing anything for others. </p>
					<p>Keep in mind that your willingness to sacrifice your interests for the sake of your relationships may be taken advantage of by less scrupulous people, who may see you as a pushover or a doormat.</p>

					<div className="quote">
						<p><i>"Nobody cares how much you know, until they know how much you care."</i></p>
						<p><b>- Theodore Roosevelt</b></p>
					</div>

					<div className="quote">
						<p><i>"The test of our progress is not whether we add more to the abundance of those who have much; it is whether we provide enough for those who have too little."</i></p>
						<p><b>- Franklin D. Roosevelt</b></p>
					</div>

					<h4 id="Atrust">A3 | High Trust</h4>
					<p>You easily trust others and what they say, and tend to believe that they have good intentions. You believe in human goodness and that people are basically moral and good, which leads you to view life with a more optimistic, thinking that all will be well.</p>

					<div className="quote">
						<p><i>"Trust is the glue of life. It’s the most essential ingredient to effective communication. It’s the foundational principle that holds all relationships."</i></p>
						<p><b>- Stephen Covey</b></p>
					</div>

					<h4 id="Acompliance">A4 | High Compliance</h4>
					<p>You usually can’t stand confrontations and conflicts. You prefer to maintain harmony with your relationships and the group. This means you tend to express yourself quietly, modestly, tactfully and with consideration to the other person. You do so to avoid seeming pushy, arrogant or smug.</p>
					<p>You are also easy to satisfy when things don’t go your way and easily forgive when people do you harm. </p>

					<div className="quote">
						<p><i>"Courage is what it takes to stand up and speak; courage is also what it takes to sit down and listen."</i></p>
						<p><b>- Winston Churchill</b></p>
					</div>

					<h4 id="Amodesty">A5 | High Modesty</h4>
					<p>Because you tend to see yourself as a humble, average person, you tend to dislike being the center of attention and talking about yourself. While you value your achievements, it doesn’t mean that you will toot your own horn and brag about them.</p>

					<div className="quote">
						<p><i>"Humility is not thinking less of yourself, it's thinking of yourself less."</i></p>
						<p><b>- C.S. Lewis</b></p>
					</div>

					<h4 id="Astraightforwardness">A6 | High Straightforwardness</h4>
					<p>You have a strong moral compass and act morally. This means you tend to stick to the rules and would never cheat to get ahead in life. Lying to others or hurting them to get what you want is out of the question.</p>
					<p>Instead, you want to make sure you don’t put people under too much pressure, out of your concern for them. You seek solutions that will help you both make progress on your goals.</p>

					<div className="quote">
						<p><i>"About morals, I know only that what is moral is what you feel good after and what is immoral is what you feel bad after."</i></p>
						<p><b>- Ernest Hemingway</b></p>
					</div>
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	</Accordion>
	</section>

	<div className="separator" />


	<h4>How You May Be Perceived</h4>
	<p>Your radiant enthusiasm may be exploited by some people. Make sure that you don’t forget to protect your own self-interests when needed. By trying to please everyone at all costs - saying “yes” by default, even when you don’t mean it - you may be perceived as a pushover and be taken advantage of your caring nature.</p>
	<p>Learn to speak up and defend what you truly stand for. Think of ways to create win-win situations instead of allowing people to walk over you to get what they want.</p>


	<section className="story">
		<h3>Example of Agreeableness Misunderstandings</h3>
		<p><b>Highly-agreeable Takeda</b> is stepping in a new role, working with <b>Anja, who is highly skeptical and analytical because of her scientific background</b>. In contrast, Takeda always agrees to everything, even when he’s not sure he fully understood what he was asked to do.</p>
		<p>His empathetic nature makes it hard to understand why Anja is so distant with him. His desire to please her makes it even harder for him to open up and share his concerns and about the potential misunderstandings of his assignments. He sometimes feels isolated and like he is banging his head against an unbreakable wall.</p>
		
		<div className="myimghor">
			<img src={amismatch} alt="Agreeableness misunderstanding" />
		</div>
	</section>


	<h3>How to Work Well With High-Agreeableness People</h3>
	<p>Keep in mind their desire to please you. Make sure that when they agree to doing something, they have clearly understood your expectations and know how to get started. If needed, you may ask them to write down their plans of action to make sure they will not get stuck and not dare asking you for help, out of fear of conflict.</p>
	<p>Ask them to share the concerns they have and how they are feeling, emphasizing how this matters to you, because they need to feel heard and like they belong to do their best work.</p>
	<p>During conversations, take time to pause and gather everyone’s thoughts and concerns. Emphasize how getting as much information as possible upfront can prevent further unnecessary challenges and misunderstandings from arising.</p>
	<p>Ask for written thoughts and feedback between your one-on-one conversations to give them a safe space to share their ideas, needs, thoughts, and concerns.</p>

	<div className="myimghor">
		<img src={conversation5} alt="Working with high-agreeableness individuals" />
	</div>

	<div className="imgcaption">
		<p>Photo by <a href="https://www.pexels.com/@rethaferguson">RF._.studio</a> from <a href="https://www.pexels.com/photo/group-of-women-having-a-meeting-3810754/">Pexels</a></p>
	</div>


	<h3>How to Work Well With Low-Agreeableness People</h3>
	<p>Keep in mind they will likely tend to say “No” at first and don’t take it personally. It’s a defense mechanism to protect their time, energy, and interests.</p>
	<p>When they refuse, give them time to weight the pros and cons and get back to you at a later stage. When they realize that their helping you will make everyone better off, they are likely going to assist you.</p>
	<p>Accept that they may be skeptical and that you will need to give them enough facts to make your case, because of their practical nature. Focus on giving them facts and letting them focus on solving the challenges rather than looking for an emotional explanation of the situation. They deal better with tasks than with people.</p>

	<div className="myimghor">
		<img src={conversation9} alt="Working with low-agreeableness individuals" />
	</div>

	<div className="imgcaption">
		<p>Photo by <a href="https://www.pexels.com/@jopwell">Jopwell</a> from <a href="https://www.pexels.com/photo/woman-wearing-teal-dress-sitting-on-chair-talking-to-man-2422280/">Pexels</a></p>
	</div>


	<h3>Questions to Ask to Evaluate Agreeableness</h3>
	<h4>We are going out for ____. <br />Want to join us?</h4>
	<p><b>Highly agreeable people</b> will likely accept your invitation, because they enjoy belonging to a group. Keep in mind that they may accept by default and need to make a change of plan at the last minute, to avoid creating tensions.</p>
	<p><b>Less agreeable people</b> will likely decline your invitation, because they have other priorities to attend. Keep in mind that they may decline by default and still join you at the last minute, because they had time to reconsider their decision.</p>

	<h4>Are you usually the peacemaker or the troublemaker?</h4>
	<p><b>Highly agreeable people</b> will usually try to resolve conflicts, even if it means sacrificing something for the sake of the relationship. They are also likely going to get involved in conflicts between other people, to preserve the harmony of the group.</p>
	<p><b>Less agreeable people</b> will not hesitate to be assertive about their positions and are therefore more comfortable with disagreements, debates, and conflicts. They tend to misjudge how their words and behaviors may impact other people emotionally, without their becoming aware of the invisible scars they may leave on others.</p>


	<div className="separator" />
	<div className="separator" />
	<hr/>



	<h2 id="Ntitle">Your Nervosity</h2>

	<div className="myimghor">
		<img src={nsample} alt="Nervosity spectrum" />
	</div>

	<p>This trait describes how well you can keep calm under pressure:</p>

	<ul>
		<li>How do you approach worry?</li>
		<li>How emotionally reactive are you?</li>
		<li>What is your tendency towards negative emotions?</li>
		<li>How often do you engage in self-limiting thinking?</li>
	</ul>


	<h3>You Have High-Nervosity Levels</h3>

	<div className="myimghor">
		<img src={worry1img} alt="High nervousness anxiety" />
	</div>

	<div className="imgcaption">
		<p>Life is full of ups and downs, filled with trials for us to overcome</p>
		<p>Photo by <a href="https://www.pexels.com/@katlovessteve">Kat Jayne</a> from <a href="https://www.pexels.com/photo/adult-alone-anxious-black-and-white-568027/">Pexels</a></p>
	</div>

	<p>As a high-nervosity person, you are likely a worrier, someone who experiences intense emotions. You may reminisce over the past and worry about what’s ahead and what others think of you. You may be unconsciously looking for the challenges and downsides of everyday situations. Some people might call this pessimism, but it likely has to do with your need to search for multiple scenarios and see how you can address them.</p>

	<div className="quote">
		<p><i>"We suffer more often from imagination than from reality."</i></p>
		<p><b>- Seneca</b></p>
	</div>
	
	<h3>Your 6 Nervosity Facets</h3>

	<p>Nervosity can be broken down into six facets which describe your levels of emotional volatility and withdrawal:</p>

	<ol>
		<li><b>Anger/Irritability</b> describes your tendency to experience anger, frustration, bitterness, and resentment.</li>
		<li><b>Impulsiveness</b> describes your tendency to act on cravings and urges rather than reining them in and delaying gratification.</li>
		<li><b>Anxiety</b> describes your levels of free floating anxiety, that is, experiencing fear and anxiety in everyday situations, without being able to identify any real observable threat.</li>
		<li><b>Depression</b> describes your tendency to experience crushing feelings of sadness, guilt, despondency, and loneliness.</li>
		<li><b>Self-consciousness</b> describes your shyness or social anxiety. </li>
		<li><b>Vulnerability</b> describes your general susceptibility to stress.</li>
	</ol>

	<div className="separator" />

	<section className="accordion-width">
	<Accordion>
		<Card className="border-0">
			<Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
			<b className="mr-3">{i18n.t('discover-your-6-nervosity-facets')}</b><Icon.ChevronDown className="ml-2"/>
			</Accordion.Toggle>

			<Accordion.Collapse eventKey="0">
				<Card.Body>
					<h4 id="Nanger">N1 | High Anger/Irritability</h4>
					<p>Your tendency to become angry or irritated easily will sometimes drag you down, by setting you in a bad mood. At times, your frustration may degenerate and turn into your losing your temper and become aggressive and hostile towards other people.</p>
					<p>Remember that anger fuels anger. Like kindling a fire which suddenly starts to rage with an intense blaze, anger will consume you and those around you if you don’t pay attention to it and let it dissipate before interacting with others.</p>

					<div className="quote">
						<p><i>"Anger is an acid that can do more harm to the vessel in which it is stored than to anything on which it is poured."</i></p>
						<p><b>- Mark Twain</b></p>
					</div>

					<div className="quote">
						<p><i>"Speak when you are angry and you will make the best speech you will ever regret."</i></p>
						<p><b>- Ambrose Bierce</b></p>
					</div>

					<h4 id="Nimpulsiveness">N2 | High Impulsiveness</h4>
					<p>You sometimes do things you later regret and have difficulties understanding why you do some of the things you do. Without realizing it, you may act on a whim, without considering the consequences of your actions on yourself and others around you.</p>
					<p>When you do so, you choose short-term gains over long-term ones. The tempting immediate gain lures you in, without regard for the long-term consequences. These behaviors can impact your eating, drinking or spending habits and, in the long term, your health and well-being.</p>

					<div className="quote">
						<p><i>"Wisely, and slowly. They stumble that run fast."</i></p>
						<p><b>- William Shakespeare</b></p>
					</div>

					<h4 id="Nanxiety">N3 | High Anxiety</h4>
					<p>Life can sometimes feel overwhelming. You tend to worry about things that may happen and are preoccupied by your existing problems. At times, you may be absorbed into reflecting on something you did or which happened to you, feeling stuck in the past. </p>
					<p>To get out of this mental trap, you need to try to understand what lesson you can learn about your experiences and the mistakes you made, to make sure that you can either prevent them from happening again in the future, or correct them, should they still happen.</p>

					<div className="quote">
						<p><i>"Worry is a state of min based upon fear. It works slowly, but persistently. It is insidious and subtle. Step by step it “digs itself in” until it paralyzes one’s reasoning faculty, destroys self-confidence and initiative. Worry is a form of sustained fear caused by indecision therefore it is a state of mind which can be controlled."</i></p>
						<p><b>- Napoleon Hill</b></p>
					</div>

					<div className="quote">
						<p><i>"There is only one way to happiness and that is to cease worrying about things which are beyond the power of our will."</i></p>
						<p><b>- Epitectus</b></p>
					</div>

					<h4 id="Ndepression">N4 | Low Depression</h4>
					<p>You tend to experience life in a calm, stable, comfortable, and satisfying way, rarely experiencing ups and downs and deep feelings of sadness. Since you often feel like your life has purpose, you tend to feel comfortable, secure, and pleased with yourself.</p>

					<div className="quote">
						<p><i>“Storms make trees take deeper roots.”</i></p>
						<p><b>- Dolly Parton</b></p>
					</div>
						
					<h4 id="Nselfconsciousness">N5 | High Self-Consciousness</h4>
					<p>Self-consciousness describes a high preoccupation with yourself and the worrying feeling that everyone is watching you. This translates into being easily hurt, intimidated or embarrassed by everyday events.</p>
					<p>When feeling overly self-conscious, you pay too much attention to what you are doing and therefore perform worse than if you were in a more detached state of flow. By worrying about what could go wrong, things can actually go wrong.</p>
					<p>This also makes your social life more difficult: only feeling comfortable with close friends and finding it difficult to approach others or fear of drawing attention to yourself will prevent you from creating new meaningful relationships.</p>
					<p>Keep in mind the words of Henry Ford: <b>“Whether you think you can or think you can’t, you are right.”</b> Your worries may become self-fulling prophecies, stay careful not to become their victim.</p>

					<div className="quote">
						<p><i>“If you don’t believe inside yourself that you can learn a lot, nobody’s ever going to do that for you. Nobody’s ever gonna give you self drive. Nobody’s ever gonna give you self esteem. Nobody’s ever gonna give you your self worth. You have to set it for yourself.”</i></p>
						<p><b>- Brother Ali</b></p>
					</div>

					<h4 id="Nvulnerability">N6 | High Vulnerability</h4>
					<p>Your intense emotions can sometimes get the best of you. When they overwhelm you, you lose your ability to effectively make decisions. When this happens, small setbacks seem to become unsurmountable challenges, which makes you feel that you are unable to deal with them.</p>
					<p>To better deal with your powerful emotions, you need to learn to recognize them when they start appearing and apply strategies that work for you to soothe them. Ask for help if you need it, you are not alone!</p>

					<div className="quote">
						<p><i>“If you hold back on the emotions - if you don’t allow yourself to go all the way through them - you can never get to being detached, you’re too busy being afraid. You’re afraid of the pain, you’re afraid of the grief. You’re afraid of the vulnerability that loving entails. But by throwing yourself into these emotions, by allowing yourself to dive in, all the way, over your head even, you experience them fully and completely.”</i></p>
						<p><b>- Mitch Albom</b></p>
					</div>

				</Card.Body>
			</Accordion.Collapse>
		</Card>
	</Accordion>
	</section>

	<div className="separator" />


	<h4>How You May Be Perceived</h4>
	<p>Your tendency to worry about future challenges is valuable, because it helps you to cover potential scenarios and prepare for the worst. Worrying about others’ well-being also helps you to be seen as a caring person.</p>
	<p>However, some people may perceive you as being insecure or anxious. To avoid making things looking only pessimistic, you can leverage your tendency to think of possible scenarios to also consider what could go right.</p>
	<p>Your emotions are a strength and are contagious. You need to learn to recognize them when they are setting in, to avoid experiencing extreme mood swings and to become better able at soothing them. Doing so is a sign of higher emotional intelligence, focusing on both self-awareness and self-development.</p>


	<section className="story">
		<h3>Examples of Nervosity Misunderstandings</h3>
		
		<h4>1 | Project Management</h4>
		<p><b>Mary is a chronicle worrier</b>. Her project is stuck and depends on <b>low-nervosity Gwen</b> finishing the tasks she was assigned. Mary sends Gwen concerned emails on a daily basis. This makes Gwen feel so frustrated and pressured that she starts making mistakes, which slows her down even more.</p>
		<p>After being two weeks behind schedule, she is finally done. She feels relieved and hopes that she will not have to work with Mary anymore.</p>
		
		<div className="myimghor">
			<img src={nmismatch1} alt="Nervosity misunderstanding example 1" />
		</div>

		<h4>2 | Prevent & Correct Mistakes</h4>
		<p><b>I (Chris)</b> have worked with <b>Andi, a low-nervosity manager</b> who perfectly understood how to make the most of our differences - given that I am a highly-nervous person.</p>
		<p>He taught me that my tendency to search for the worst-possible scenarios would allow us to prevent them from happening or to correct them, should they still occur. </p>
		<p>In the world of quality, this is known as CAPA, an acronym which stands for “Corrective Actions & Preventive Actions”:</p>

		<ul>
			<li><b>How can we prevent this problem from happening?</b></li>
			<li><b>How can we correct the damages and learn from our mistakes, to prevent them from occurring again?</b></li>
		</ul>

		<p>His calm nature allowed me to channel my strengths, while calming me down in the process. This is a lesson I will never forget!</p>

		<div className="myimghor">
			<img src={nmismatch2} alt="Nervosity misunderstanding example 2" />
		</div>
	</section>

	<h3>How to Work Well With High-Nervosity People</h3>
	<p>Give them enough safe space to express themselves, both orally and in written. Review together the alternatives, pros and cons, and backup plans. Let them be thorough enough so that they don’t spin their wheels and overthink things while they are working.</p>
	<p>Give them enough time to share their ideas, thoughts, and concerns. Resist the urge to put them against a wall or to let them ruminate over their concerns after conversations. Reassure them by letting them know that you are trying to cover multiple potentialities, so they don’t have to worry about them themselves. This will make them trust you and reduce their wariness.</p>
	<p>Leverage their ability to think of multiple scenarios to see which opportunities and risks you may be confronted with. How can you prevent a worst-case scenario from happening? How can you repair the resulting damages?</p>

	<div className="myimghor">
		<img src={worry3img} alt="Working with high-nervosity individuals" />
	</div>

	<div className="imgcaption">
		<p>Photo by <a href="https://www.pexels.com/@andrew">Andrew Neel</a> from <a href="https://www.pexels.com/photo/photo-of-man-leaning-on-wooden-table-3132388/">Pexels</a></p>
	</div>


	<h3>How to Work Well With Low-Nervosity People</h3>
	<p>If you tend to worry a lot, let them know in advance that this is nothing personal. It’s who you are, and everyone gets treated the same way and you are working on it. Leverage their tendency to not get concerned with potential issues. Show them you have done your due diligence and let them trust your work.</p>
	<p>Ask for their support during crises and leverage their ability to think clearly during stressful situations. They will likely be able to get you unstuck and moving forward again.</p>
	<p>Leverage one-on-one conversations to raise your concerns and let them express theirs, rather than bombarding them with emails or phone calls. They are usually able to absorb more stress than you without being affected, but it doesn’t mean that you should harass them or that they will never break.</p>
	<p>Take the time to prepare for your conversations, which will make them more effective. They will appreciate your ability to share your thoughts and concerns without being emotionally overwhelmed in the moment.</p>

	<div className="myimghor">
		<img src={highfive} alt="Working with low-nervosity individuals" />
	</div>

	<div className="imgcaption">
		<p>Photo by <a href="https://www.pexels.com/@fauxels">fauxels</a> from <a href="https://www.pexels.com/photo/photo-of-people-holding-each-other-s-hands-3184433/">Pexels</a></p>
	</div>


	<h3>Questions to Ask to Evaluate Nervosity</h3>
	<h4>What challenging projects have you worked on recently? <br /> How did they go?</h4>
	<p><b>High-nervosity people</b> will likely look slightly more uncomfortable or stressed while mentally recalling and explaining they challenging work. They may share more about the challenges they faced, their worries and how things felt overwhelming. Do they focus on the negatives?</p>
	<p><b>Low-nervosity people</b> will likely look more relaxed and unfazed by what life threw at them. They may talk serenely about what happened, without seeming affected by what went wrong and challenged them, potentially considering the positive aspects of their work over the negative ones.</p>

	<h4>What do you need help with?</h4>
	<p><b>High-nervosity people</b> tend to quickly know where they struggle and should looked relieved when someone offers to help. They may ask questions to make sure that they are not wasting your time or to see if you know someone else who could better match their needs. </p>
	<p><b>Low-nervosity people</b> tend to be more self-confident and would therefore likely not be sure they need help, unless they are completely stuck with their work. That doesn’t mean that they will not appreciate you offering to help, however they would only resort to asking for it in critical situations. This may be due to their tendency to not suffer from stress as much as other people would.</p>

	<h4>Do you sometimes feel bad, sad or mad for no apparent reason?</h4>
	<p><b>High-nervosity people</b> experience life intensely like an extreme emotional rollercoaster. Their highs and lows are powerful and sometimes overwhelming. Therefore, what would appear for many people as a normal, everyday situation can seem to be an insurmountable trial to them. </p>
	<p>Their emotions rage inside, leaving them feeling angry, sad, ashamed or afraid for what seems to be no apparent reason. When they do spend enough time to reflect, they may find the explanation for their dismay.</p>
	<p><b>Low-nervosity people</b>’s emotional reaction tend to be more tempered and less overwhelming. Only in rare circumstances do they succumb to their emotions and lose control.</p>
	<p>Therefore, they move through life without being especially affected by what they experience. At times, they may even have a difficult time realizing what they are feeling, unable to name their emotions accurately.</p>

	<h4>Do you worry about things that might happen?</h4>
	<p>Worrying is a hallmark of the <b>high-nervosity personality trait</b>. Nervous people experience higher-than-average levels of fear at all times, which makes them prone to wonder and worry about what the future holds. </p>
	<p>You should therefore be able to read fear on their face and body language, where they may suddenly start curling up, shiver or become fidgety. </p>
	<p><b>Low-nervosity people</b> and their calm demeanor also reflects their inner mental and emotional states. Because they are more self-confident about their abilities to tackle the challenges life will throw at them, they will not experience as much fear and stress when they think about their future.</p>
	<p>Their facial expressions and body language should stay more relaxed and they will appear more confident as a result of their emotional stability.</p>

	<h4>Do you worry too long after an embarrassing experience?</h4>
	<p>Shame is one of our five core emotions and is no exception to what <b>high-nervosity people</b> experience regularly. You may hear them say out loud, “Oh God why?”, “What is wrong with me?”, “Why did I do that?” </p>
	<p>In all likelihood, even if they don’t express them openly, they are likely going to think these thoughts and feel shame wash over their bodies. They may also look around them warily, hoping nobody saw what they did and try to disappear.</p>
	<p>Our brains tend to remember shameful experiences strongly, and may frequently play back to them in the theaters of our imagination, trying to help us learn a few lessons that will reduce the likelihood of us behaving in similarly embarrassing manners in the future.</p>
	<p><b>Low-nervosity people</b> will also experience shame, although they tend to be more quick to learn from their mistakes and will therefore not linger on them. Once again, they experience less volatile highs and lows and have faster recovery times when they do.</p>
	
	<h3>Want to discover your personality traits?</h3>

	<div className="input-group">
		<div className="input-group-append content-btn-box">
			<a href="/user/premium/my-assessments" className="content-btn">Get started</a>
		</div>
	</div>

	<div className="separator" />
	<div className="separator" />
	
</div>
)
}

export default SamplePersonalReport;

