import React from 'react';
import Table from 'react-bootstrap/Table';

import spacex from "../images/spacex.jpg";
import journeyimg from "../images/journey.jpg";
import elementsofei from "../images/elements-of-ei.png";


function IdentityMission(props: any) {
	   
return (
   <div className="masthead">
			<h1>What is Your Mission in Life?</h1>
			
			<div className="separator"></div>

			<div className="myimghor">
			<img src={spacex} alt="SpaceX Dragon" className="img-responsive"/>
			</div>

			<div className="imgcaption">
			<p>"To make humanity multiplanetary" | SpaceX's Mission</p>
			</div>

			<p>Our modern societies are offering us one of the greatest gifts in life: The opportunity to ask ourselves, <b>"Why am I here?"</b>. I believe we all have a mission or purpose in life, which answers this fundamental question.</p>
			<p>This question can be phrased in different ways:</p>
			
			<ul>
				<li><b>"What gives my life purpose and meaning?"</b></li>
				<li><b>"How can I use my limited time on Earth to do something meaningful and important?"</b></li>
			</ul>
			
			<div className="notabene">
				Answering this question makes the pursuit of our purpose in life - which can be at home and/or at work - rewarding, by bringing meaning into our lives.
			</div>


			<h2>Why Does This Matter?</h2>
			<p>From personal experience, I noticed that trying to live a more intentional life, by pursuing my mission in life, <b>makes life so much more enjoyable</b>. It's like a beacon of light and hope, which sustains and guides me during difficult times and keeps me going. </p>
			<p>I believe that our "Why" in life is vastly influenced by what I call our Identity, which consists of three elements:</p>
			
			<div className="notabene">
				Identity = Mission + Values + Beliefs
			</div>

			<ol>
				<li>Your <a href="/identity-mission" className="intlink">Mission</a>, answering the question, "Why am I here?"</li>
				<li>Your <a href="/identity-values" className="intlink">Core values</a>, answering the question, "What do I truly stand for?"</li>
				<li>Your <a href="/identity-beliefs" className="intlink">Beliefs</a> about yourself, answering the question, "Who am I?"</li>
			</ol>
			
			<p>I believe discovering our Identity is crucial, because it helps us to grow our emotional intelligence, by growing two of its elements: </p>
			
			<ol>
				<li><b>Self-Awareness</b>, through better understanding of who we are and who we desire to become</li>
				<li><b>Self-Development</b>, through searching for ways to move towards our potential selves and futures</li>
			</ol>
			
			<div className="myimghor w-75">
				<img src={elementsofei} alt="The 4 elements of emotional intelligence" />
			</div>

			<div className="imgcaption">
				<p>The 4 elements of emotional intelligence</p>
			</div>

			<p>As Simon Sinek described in his book Start with why, our "Why" influences our behavior (the "How"), which ultimately dictates the results we obtain (the "What").</p>
			<p>Take exercising as an example. Many people start with a measurable goal in mind, say lose 10kg or 20 pounds.  Without having a meaningful reason why they want to lose weight - in their own terms - they are likely going to give up along the way and not achieve their goal. As Friedrich Nietzsche said,</p>
			
			<div className="quote">
			<p><i>"He who has a <b>'why'</b> to live can bear almost any <b>'how'</b>."</i></p>
			<p><strong>- Friedrich Nietzsche</strong></p>
			</div>

			<p>What does that mean? To me, this means that when we are moved by a meaningful reason why we want to achieve something, we will keep going even when life gets in the way and things become difficult.</p>
			<p>This is an example of intrinsic motivation, meaning motivation which comes from within ourselves, rather than pursuing an external reward. Trying to exercise regularly because we want to become fitter and healthier, so that we can live longer and healthier lives, with the chance of playing with and enjoying our children and grandchildren, is an example of what I consider to be a meaningful reason.</p>
			<p>Let's face it: Life has always been difficult for everyone from time to time, and will always be so. </p>
			<p>Our goals must be worth it to ourselves, because pursuing them will be hard. It's necessary that we have a clear vision of where we want to go and who we wish to become along the way, to avoid straying off the path to get there or stopping altogether.</p>
			<p>In contrast, extrinsic motivation, meaning motivation which comes from the outside world, pursuing external rewards, are usually short-lived. Setting the weight loss numerical target is not motivating enough to go through the trials of physical exercising and dieting. We eventually start asking ourselves, "why am I punishing myself?" or "is this really worth all this pain?" and may give up.</p>
			
			<div className="notabene">
				This is why having this mission, purpose or vision in life so important: Because it will remind us of why we are sacrificing ourselves today, for a better tomorrow for everyone, including ourselves. It will keep us going. <br />
				Working with purpose, contributing to something bigger than ourselves, is deeply meaningful and satisfying.
			</div>
			
			<p>The feelings of pride, joy, and progress we get makes us more resilient in dealing with setbacks. We follow through, even when life sucks. I often feel like nothing works and feel down, yet I keep going because I realize that it's not something I do for myself alone but for others.</p>
			<p>Work is like a vehicle, it takes you from where you are today to a potential future, a vision of a different world, represented by your Mission in life.</p>
			
			<div className="myimghor">
			<img src={journeyimg} alt="Enjoy the journey" />
			</div>

			<div className="imgcaption">
			<p>We need to enjoy the journey of life</p>
			<p>Photo by <a href="https://www.pexels.com/@ninauhlikova" className="intlink">Nina Uhlíková</a> from <a href="https://www.pexels.com/photo/adult-adventure-beautiful-climb-287240/" className="intlink">Pexels</a></p>

			</div>


			<h2>How Can You Discover Your Mission?</h2>
			<p>Take a look at the following <a href="#mission-statements" className="intlink"> list of personal and corporate mission statements</a>. I created it while I was trying to discover my personal Mission, by searching for and collecting what others had discovered for themselves before me.</p>
			<p>Take the time to read each of these statements and see if they resonate with you. If so, why? If not, why?</p>
			<p>Asking yourself these questions will help you get closer to your definition of your mission in life. Using your own words is key to making sure it truly reflects who you are.</p>
			
			<p>Download placeholder: List of mission statements</p>
			
			<p>Missions, like values and beliefs, are deeply personal. Some examples may include:</p>

			<ul>
				<li>Making healthcare affordable to all</li>
				<li>Creating something truly beautiful, like a painting, a movie or a building</li>
				<li>Advancing humanity through breakthrough technologies</li>
			</ul>

			<div className="notabene">
				What is amazing with discovering your life's mission is the clarity it brings. By better understanding yourself and where you want to go, it gives you further freedom to choose <i>how</i> you want to act to get there.
			</div>
			
			<p>For instance, it gives you the freedom to decide whether you want to pursue your mission within an existing organization as an employee, or to start your own as none exist as you would envision it yet.</p>
			<p>You don't HAVE to be an entrepreneur to fulfill your mission. Many people, myself included, find this idea liberating.</p>
			<p>This means that after you discovered your personal mission in life, you can search for people and organizations who share a similar one to yours. So that you can work together to create your shared vision of the potential future.</p>
			<p>Often, some people are drawn to certain careers, industries or organizations without being able to describe why that is. I suspect that it is because their mission is unconsciously showing them the way. </p>
			
			<h2>Chris' Personal Mission & Story</h2>
			
			<div className="quote">
			<p><i><b>To preserve and improve human life, doing well by doing good.</b></i></p>
			</div>

			<p>Although I didn't articulate my mission until my late 20s, I now realize that I've been spending most of my life pursuing it unconsciously. Since I was 12 years old, I wanted to be a medical doctor. This drew me towards the courses that helped me better understand how the human body works, namely biology and chemistry.</p>
			<p>Then, reality struck. I failed my second year of medical school. This was a life-shaking experience. I went through a period of deep introspection and questioning. I had to decide between two alternatives:</p>
			
			<ul>
				<li>Continuing to help patients directly by becoming a nurse, pharmacist, or other healthcare worker, or</li>
				<li>Helping them indirectly, by working for organizations which support the doctors, nurses and medical staff.</li>
			</ul>
			
			<p>I chose the latter. This decision helped me to follow through by providing me direction, in contrast to most of my classmates who were clueless to what they wanted to do in life. Every class I took fitted into the bigger picture of working for an organization in the pharmaceutical or medical devices industries.</p>
			<p>There were two dream companies: Johnson & Johnson and Merck. However, in my mind, I would have to first get some experience - even in unrelated industries and organizations - before being able to join one of them. Unexpectedly, I saw Johnson & Johnson opened an internship close to where I lived. It was a moonshot, but I applied and got the job.</p>
			<p>I was far less qualified than some of their top candidates who studied at the EPFL, one of the world's best schools. What made me stand out was how passionate I was about helping people live healthier lives. My future supervisor could feel it. She also said that I really understood their current challenges and how to address them, something that other candidates did not, because they seemed too focused on showing their technical knowledge.</p>
			<p>Today, I still feel deeply drawn to companies which share a mission encouraging more accessible health to all. </p>
			<p>Full disclosure, the first part of my mission statement comes from Merck's "To preserve and improve human life". It resonated so well with me and the phrasing felt so right I did not want to change it at first.</p>
			<p>After some time, I added the second part of the statement, "doing well by doing good" to continuously remind me that money should not be my main purpose, but helping people should be. Money would come as a result of my working to preserving and improving human life. I was initially inspired by Merck's mission statement and then made it my own by adding the second element.</p>
			<p>With even more time and reflection, I realized that I initially associated "to preserve and improve human life" with physical health alone. One reason why is that I believe our health is our most precious resource and that we too often take it for granted.</p>
			<p>I now also believe that human life means mental health, wellbeing and happiness, which is the reason why I started this website. I feel driven to share my research, ideas and findings to help anyone out there.</p>
			<p>Keeping all this in mind helps me to navigate through hard times: It reminds me that it's not about me and that I can make someone else's life better. So I get back to work.</p>
			<p>I sincerely hope that my family, friends and anyone else would benefit from my work. This experience was a defining moment in my life and I hope it will be one in yours as well.</p>

			<h2 id="mission-statements">List of Mission Statements</h2>

			<div className="quote">
				<p><i>"To preserve and improve human life, doing well by doing good."</i></p>
				<p><b>- MyOceanity</b></p>

				<br />
				<p><i>"To spread ideas."</i></p>
				<p><b>- TED</b></p>

				<br />
				<p><i>"To think differently."</i></p>
				<p><b>- Apple</b></p>

				<br />
				<p><i>"To accelerate the world's transition to sustainable energy."</i></p>
				<p><b>- Tesla</b></p>

				<br />
				<p><i>"To have fun in my journey through life and learn from my mistakes."</i></p>
				<p><b>- Sir Richard Branson</b></p>

				<br />
				<p><i>"To try something important, even if the probable outcome is failure."</i></p>
				<p><b>- Elon Musk</b></p>

				<br />
				<p><i>"To be a teacher and to be known for inspiring my students to be more than they thought they could be."</i></p>
				<p><b>- Oprah Winfrey</b></p>

				<br />
				<p><i>"To enable people to live on other planets by revolutionizing space technologies."</i></p>
				<p><b>- SpaceX</b></p>

				<br />
				<p><i>"To empower every person and every organization on the planet to achieve more."</i></p>
				<p><b>- Microsoft</b></p>

				<br />
				<p><i>"To empower people with creative ideas to succeed."</i></p>
				<p><b>- Squarespace</b></p>

				<br />
				<p><i>"To help bring creative projects to life."</i></p>
				<p><b>- Kickstarter</b></p>

				<br />
				<p><i>"To prevent and alleviate human suffering in the face of emergencies by mobilizing the power of volunteers and the generosity of donors."</i></p>
				<p><b>- The American Red Cross</b></p>

				<br />
				<p><i>"To bring inspiration and innovation to every athlete in the world."</i></p>
				<p><b>- Nike</b></p>

				<br />
				<p><i>"To give customers the most compelling shopping experience possible."</i></p>
				<p><b>- Nordstrom</b></p>

				<br />
				<p><i>"To nourish people and the planet. To set the standards of excellence for food retailers."</i></p>
				<p><b>- Whole Foods Market</b></p>

				<br />
				<p><i>"To deliver the highest quality of customer service with a sense of warmth, friendliness, individual price, and company spirit."</i></p>
				<p><b>- Southwest Airlines</b></p>

				<br />
				<p><i>"To ignite opportunity by setting the world in motion."</i></p>
				<p><b>- Uber</b></p>

				<br />
				<p><i>"To organize the world's information and make it universally accessible and useful."</i></p>
				<p><b>- Google</b></p>

			</div>

			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>Whether we realize it or not, our identity shapes our lives. Your personal mission will remind you of what gives your life meaning and purpose, to keep the fire burning inside you alive, to keep you going no matter what.</p>
			<p>There are a few ways you can discover your identity:</p>

			<Table striped borderless hover responsive className="course-table">
				<thead>
					<tr><th className="course-table-header">Module 4: Discovering Your Identity</th></tr>
				</thead>
				<tbody className="course-table-body">
					<tr> <td> <a href="/identity-introduction">Introduction to the concept of Identity</a></td> </tr>
					<tr> <td> <a href="/ikigai">What is a meaningful life? What is Ikigai?</a></td> </tr>
					<tr> <td> <a href="/identity-values">What do you stand for? What are your core values in life?</a></td> </tr>
					<tr> <td> <a href="/identity-beliefs">Who are you? What are your beliefs about yourself and the world around you?</a></td> </tr>
				</tbody>
			</Table>

			<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
            </div>
			
			<div className="separator"/>
			<div className="separator"/>
   </div>
  )
}
export default IdentityMission;

