import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as Icon from 'react-bootstrap-icons';
import testimg from "../images/test-img.jpg";
import groupoffriends from "../images/group-of-friends.jpg";



function InviteAccepted(props : any) {
	const { i18n } = useTranslation();
	
	let { inviteCode }  = useParams();

  fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/compare-accepted', {
        method : 'POST',
        body : JSON.stringify({inviteCode:inviteCode, acceptor:props.username}),
        headers: {
        'Authorization': `Bearer ${props.jwt}`,
      }
  }).then(response => {
    if(response.status === 200){
      return response.json();
    }
  });

	  const renderMore = () => {
	  	if (props.quiz.length >0) {
	  		return (
				<div>
					<h5>
						{i18n.t('youve-already-completed-the-quiz')}<br />
						<Link to="/user/premium/send-invite">{i18n.t('take-a-look-at-the-people-you-know')}</Link> <Icon.ArrowRight className="ml-2" />
					</h5>
					<div className="myimghor">
						<img src={groupoffriends} alt="Group of friends together" />
					</div>
				</div>
			)
			
	  	}else{
		  	return (
				<div className="masthead">

					<div className="input-group">
						<div className="input-group-append content-btn-box">
							<a href="/user/premium/quiz" className="content-btn">{i18n.t('start-the-quiz')}<Icon.ArrowRight className="ml-2" /></a>
						</div>
					</div>

					<div className="myimghor">
						<img src={testimg} alt="Take the test" />
					</div>
		  		</div>
			);
	  	}
	  }

	    return (
	    	<div>
	    		<br /><br /><br />
				<Alert variant="success">
				{i18n.t('thank-you-for-your-trust-youre-all-set')}
				<br />
				</Alert>
				<br/>
				{renderMore()}
	     	</div>
	     );
  }
export default InviteAccepted;