import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";

import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import JavascriptTimeAgo from 'javascript-time-ago'

// The desired locales.
import en from 'javascript-time-ago/locale/en'

// Initialize the desired locales.
JavascriptTimeAgo.locale(en)

const onRedirectCallback = appState => {
    console.log("state",appState);
    window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
    window.history.go(0)
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
 /*   domain="dev-uzddf1k5.eu.auth0.com"
 */
        domain="login.myoceanity.com"
        audience="quiz-backend"
        clientId="x7UKld31HB4bl9bj4W4Bkx6Fo5tY59Aj"
    redirectUri={window.location.origin+"/callback"}
    onRedirectCallback={onRedirectCallback}
    cacheLocation='localstorage'
    useRefreshTokens={true}
      >
    <App />
      </Auth0Provider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
