import React from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";


function Intro(props : any) {
  const { i18n } = useTranslation();

    return (
      <div>
        <br /><br />

        <h3>{i18n.t('this-test-will-assess-your-ocean-personality-traits')}</h3>
        <br />
        <h4>{i18n.t('this-is-your-first-step-towards-increasing-your-self-awareness-and-personal-effectiveness')}</h4>
        <br /><br />

        <p>{i18n.t('please-answer-by-being-honest-with-yourself')}</p>
        <p>{i18n.t('its-crucial-that-you-answer-based-on-your-current-actual-self')}</p>
        <p>{i18n.t('please-dont-answer-based-on-who-you-wish-you-were-or-what-you-think-the-right-answer-is')}</p>
        <br /><br />

        <p>{i18n.t('you-can-select-one-option-for-each-question-and-use-the-1-5-keys-to-answer')}</p>
        <br /><br />  

        <Button variant="info" size="lg" onClick={props.action}>{i18n.t('lets-go')}</Button>
        <br /><br />  
      </div> 
    );
  }
export default Intro;
