/**


*/

const quizQuestions = [
{id:"3007df45-e1be-453c-a1e6-f3e2cb50c483",question:"I enjoy trying new activities and discovering new movies, book genres or food"},
{id:"eebeb301-28b1-4229-92fb-362a4b5312f0",question:"I enjoy keeping things simple"},
{id:"69408d40-35aa-489d-92ff-ba0a68e5f969",question:"I enjoy caring for and working with others"},
{id:"1b1357a6-2f1b-4019-91c7-cbe2a8ded66e",question:"I’m able to stay calm and rarely experience mood swings"},
{id:"2f8c801e-a972-4c47-8ca1-255da5b2d768",question:"I prefer to keep emotions out of decisions"},
{id:"742c20aa-242b-4ce9-8bd3-b66d2a89d84d",question:"I prefer quiet places to loud environments"},
{id:"df8357b3-e5eb-4138-b162-2d01b0c2ce79",question:"I like to eat in places where I already know the staff"},
{id:"7dca250c-83be-452a-acf9-8e0beca85515",question:"I feel comfortable making decisions when I have a lot of information"},
{id:"c3f2e611-655e-42a6-9552-bf6afba6d545",question:"I am concerned about the well-being of others and check up on them often"},
{id:"1e28d1ad-0ad4-4d53-b337-2b7c8257b678",question:"I get energized in groups of people and seek out social time"},
{id:"c1422711-a5d3-4997-b132-598261f6973b",question:"I tend to remember others’ emotional needs. I empathize with them"},
{id:"aafc61c9-261c-42a4-9268-e8ada440f878",question:"I enjoy traveling to different places and share my experience with others"},
{id:"e1c5f7b2-6193-4986-b943-fff7bdae8b06",question:"I brainstorm and work best alone"},
{id:"1f379808-5884-4e3c-9da0-75151c96b1c8",question:"I tend to worry a lot. I reminisce over the past and worry about the future"},
{id:"23654dbf-a1e5-4985-beda-52e6ed08efd6",question:"I often ask people for their pens or phone chargers, because I forgot mine"},
{id:"430b598c-f2f7-4877-ae88-653fcc9f4756",question:"I am more driven by facts than by emotions"},
{id:"51afdcae-2b55-44c5-be8f-901dfa8f3573",question:"I tend to believe that everything always works out in the end"},
{id:"46e34b71-151f-43d1-9895-a87f1784c823",question:"I love to chat with people and learn more about them"},
{id:"93a9b996-e169-415f-9861-2af8f20adff0",question:"I am fond of perpetuating my cultural traditions"},
{id:"6da37e42-a812-409f-a097-618a5af64dc3",question:"I love being organized: scheduling my time, making checklists and to-do lists"},
{id:"43499083-359c-4893-b745-097c6dfb025d",question:"I can forget to get back to people (via texts, emails or phone calls)"},
{id:"e0e14af0-1289-4880-9736-b6797f5fa848",question:"I like to think differently and challenge the status quo"},
{id:"1a0e9992-50c3-4a98-83dc-d2241998850c",question:"I encourage people to come to me when they need help"},
{id:"381bc85a-9b65-4feb-924f-9371583d6c94",question:"I have difficulty thinking during difficult situations"},
{id:"aa191017-84fe-492c-b9db-146d4cb4f9da",question:"I enjoy being more practical, analytical and sceptical. The facts matter to me"},
{id:"638b7af7-51ae-4b87-b2cb-a767084c1355",question:"I can be late because I don’t pay attention to what time it is"},
{id:"40438a27-b119-4436-9c29-a4bb148db8c5",question:"I like to initiate conversations and reach out to people via texts or emails"},
{id:"95a335b5-de46-4042-a753-9db7f68ccf13",question:"I prefer staying home than going out"},
{id:"ec0a297b-a357-406c-8305-820d30e205b7",question:"I dislike sharing personal information"},
{id:"404b4c1d-9cad-4a92-b8c3-37b1935c07f6",question:"I enjoy getting assigned to new tasks at work and explore new opportunities"},
{id:"13a2eb72-601d-47c5-8c65-063cc5236fc9",question:"I’m able to keep a clear mind in difficult situations"},
{id:"a853ffe2-19f1-4a4b-9cbe-9a26c53a178b",question:"I know exactly what’s coming up in my life"},
{id:"3662825e-87df-4411-928d-8a495d8a61c0",question:"I try to avoid confrontations and try to protect everyone’s feelings"},
{id:"ec89a95c-799d-4413-9b70-5088139deb46",question:"I enjoy publicly sharing my opinion, ideas and life accomplishments with others"},
{id:"d6ebe623-8a9b-4784-b828-6a2d1f43a314",question:"I enjoy big ideas, strategies and thinking outside-of-the-box"},
{id:"f99665e2-f48b-40e5-8976-d49140267d18",question:"I enjoy traveling to places I already know"},
{id:"6537ed50-6c42-422c-b37f-8aaffe7b0d30",question:"I sometimes experience mood swings"},
{id:"cf8bb1f2-8d96-42c8-8d7b-d5bb44b641a1",question:"I prefer solving problems with people but I’m not interested in how they feel"},
{id:"4aeede10-d70a-4916-9de9-0dfed7fdeb69",question:"I enjoy taking care of my appearance and to live in a clean space"},
{id:"98414ea3-ad41-41ac-8938-9ebcf7741917",question:"I accept that mistakes are part of learning"},
{id:"02776484-23c5-4db4-87a2-e9fabbc4e2b6",question:"I enjoy spending time alone, especially after social activities"},
{id:"3de0423b-242f-4f17-a7c7-dbc00dfbd6f0",question:"I trust others and love being on teams"},
{id:"3569949c-f297-4694-88d5-448aeb4d91db",question:"I tend to do my laundry and go grocery shopping at the last minute"},
{id:"cbffc4ee-9f00-4b9c-832e-2273506775f4",question:"I enjoy my routines and my habits"},
{id:"165a1258-c0ff-4aab-af86-3ce62db3c03f",question:"I like to dig into the details and notice when people forgot to mention something"},
{id:"04ff2156-4c04-47a5-997f-952108c6804c",question:"I brainstorm and work best in groups"},
{id:"dffc6290-a447-4f9c-9a33-230ac72263b4",question:"I believe getting to the right answer matters more than cooperation"},
{id:"d62c0ec4-2447-40e0-a657-8abff8d256f3",question:"I like receiving information in advance. It calms me down"},
{id:"de7a13c9-f455-45e9-8fe3-13b34b08ab2a",question:"I don’t place much importance in my appearance and how my space looks like"},
{id:"73202404-b7f8-4596-9c2c-21cf0da1999c",question:"I enjoy learning new skills"},
{id:"4c628514-a424-4ee3-8c32-9d181576d44e",question:"I tend to agree when someone asks me to do something, but may decline afterwards, after weighing the pros and cons on my own"},
{id:"bdc69cdc-0ffb-4d60-b077-797fc6b743ec",question:"When people ask me for my opinion and ideas, I prefer to spend time alone thinking before answering"},
{id:"617d5728-d102-44c7-b67a-d4541d126931",question:"I’m able to absorb more work without feeling anxious about it"},
{id:"21a36564-e1c3-4583-9c97-c6cdc2cc8c1e",question:"I am comfortable making a decision when I have little information"},
{id:"14c295ec-41cb-4dda-b975-5db8d3677593",question:"I feel uncomfortable in times of change"},
{id:"c037a651-888a-4788-86be-aeb4f3f334dd",question:"I like to plan for every possible scenario"},
{id:"80669d38-9a09-4494-9468-d0e8763f7f9a",question:"I tend to decline when someone asks me to do something, but may agree afterwards, after weighing the pros and cons on my own"},
{id:"cfebd3f3-fd8b-47a5-83bd-d9abeca4c5eb",question:"I prefer one-on-one interactions, because being in large groups of people exhausts me"},
{id:"1522098d-ffe8-4c97-afab-882c0012fae8",question:"I have difficulties with long lists and too many details and prefer improvising"},
{id:"c667ce5e-95d2-4597-b4e4-883151739151",question:"I’m excited when I think about social interactions with others"},
{id:"67dd6a59-d066-4d84-89fc-655edd181b8b",question:"I like reading inspirational messages. They calm me down"},
{id:"944e545f-b960-4cd4-89be-18d4d6d33caf",question:"I like to ask people questions to know more about them"},
{id:"c6ebe858-4cff-4e6a-8d6c-60319838cf7a",question:"I enjoy feeling like I’m in control"},
{id:"ae6e3f1c-23c7-4a47-9112-f70259095fa2",question:"I enjoy my privacy. I prefer to keep my work-life and my personal-life separate"}
];

export const quizQuestionsFR = [

    {id:"3007df45-e1be-453c-a1e6-f3e2cb50c483",question:"J'aime essayer et découvrir de nouvelles activités."},

    {id:"eebeb301-28b1-4229-92fb-362a4b5312f0",question:"J’aime garder les choses simples."},

    {id:"69408d40-35aa-489d-92ff-ba0a68e5f969",question:"J'aime travailler avec et prendre soin des autres"},

    {id:"1b1357a6-2f1b-4019-91c7-cbe2a8ded66e",question:"Je reste calme même dans les situations difficiles."},

    {id:"2f8c801e-a972-4c47-8ca1-255da5b2d768",question:"Je préfère prendre des décisions en laissant les émotions de côté"},

    {id:"742c20aa-242b-4ce9-8bd3-b66d2a89d84d",question:"Je préfère les endroits calmes plutôt que bruyants."},

    {id:"df8357b3-e5eb-4138-b162-2d01b0c2ce79",question:"Je préfère manger dans des restaurants où je connais le personnel et le menu."},

    {id:"7dca250c-83be-452a-acf9-8e0beca85515",question:"Je suis à l’aise pour prendre des décisions lorsque j’ai beaucoup d’informations."},

    {id:"c3f2e611-655e-42a6-9552-bf6afba6d545",question:"Je m'inquiète pour le bien-être des autres et prends de leur nouvelle."},

    {id:"1e28d1ad-0ad4-4d53-b337-2b7c8257b678",question:"Je suis stimulé/e par les interactions sociales."},

    {id:"c1422711-a5d3-4997-b132-598261f6973b",question:"J'ai tendance à me rappeler des besoins et des émotions des autres."},

    {id:"aafc61c9-261c-42a4-9268-e8ada440f878",question:"J'aime voyager dans de nouveaux endroits."},

    {id:"e1c5f7b2-6193-4986-b943-fff7bdae8b06",question:"Je préfère travailler seul/e."},

    {id:"1f379808-5884-4e3c-9da0-75151c96b1c8",question:"J'ai tendance à beaucoup m’inquiéter. Je repense au passé et m’inquiète pour l’avenir."},

    {id:"23654dbf-a1e5-4985-beda-52e6ed08efd6",question:"Je demande souvent aux autres s’ils ont un stylo ou un chargeur de portable car j’ai oublié le mien."},

    {id:"430b598c-f2f7-4877-ae88-653fcc9f4756",question:"Je préfère les faits aux émotions."},

    {id:"51afdcae-2b55-44c5-be8f-901dfa8f3573",question:"Je crois que tout finira par aller bien."},

    {id:"46e34b71-151f-43d1-9895-a87f1784c823",question:"J’aime discuter avec les gens et en apprendre plus sur eux."},

    {id:"93a9b996-e169-415f-9861-2af8f20adff0",question:"J’aime perpétuer mes traditions culturelles."},

    {id:"6da37e42-a812-409f-a097-618a5af64dc3",question:"J’aime être organisé/e, planifier mon temps et mes tâches."},

    {id:"43499083-359c-4893-b745-097c6dfb025d",question:"J’oublie souvent de contacter mes proches."},

    {id:"e0e14af0-1289-4880-9736-b6797f5fa848",question:"J’aime penser différemment et défier le status quo."},

    {id:"1a0e9992-50c3-4a98-83dc-d2241998850c",question:"J’encourage les gens à venir me voir lorsqu'ils ont besoin d'aide."},

    {id:"381bc85a-9b65-4feb-924f-9371583d6c94",question:"J’ai de la peine à réfléchir clairement pendant des situations stressantes et difficiles."},

    {id:"aa191017-84fe-492c-b9db-146d4cb4f9da",question:"Je suis plutôt pragmatique, analytique et sceptique. Les faits m’importent beaucoup."},

    {id:"638b7af7-51ae-4b87-b2cb-a767084c1355",question:"Il m’arrive d’être en retard parce que je ne fais pas attention à l’heure."},

    {id:"40438a27-b119-4436-9c29-a4bb148db8c5",question:"J’aime engager la conversation et contacter les gens."},

    {id:"95a335b5-de46-4042-a753-9db7f68ccf13",question:"Je préfère rester à la maison plutôt que de sortir."},

    {id:"ec0a297b-a357-406c-8305-820d30e205b7",question:"Je n’aime pas partager des informations privées."},

    {id:"404b4c1d-9cad-4a92-b8c3-37b1935c07f6",question:"J’aime recevoir de nouvelles tâches et explorer de nouvelles opportunités."},

    {id:"13a2eb72-601d-47c5-8c65-063cc5236fc9",question:"J’arrive à réfléchir clairement, même dans les situations les plus difficiles."},

    {id:"a853ffe2-19f1-4a4b-9cbe-9a26c53a178b",question:"Je planifie ma vie à l’avance."},

    {id:"3662825e-87df-4411-928d-8a495d8a61c0",question:"J’évite les conflits et essaie de ne pas blesser les autres."},

    {id:"ec89a95c-799d-4413-9b70-5088139deb46",question:"J’aime partager ouvertement mon opinion et mes idées en public."},

    {id:"d6ebe623-8a9b-4784-b828-6a2d1f43a314",question:"Je préfère avoir une vision globale de la situation plutôt que minutieuse."},

    {id:"f99665e2-f48b-40e5-8976-d49140267d18",question:"Je préfère voyager dans des endroits que je connais déjà."},

    {id:"6537ed50-6c42-422c-b37f-8aaffe7b0d30",question:"J’ai parfois des sautes d’humeur intenses."},

    {id:"cf8bb1f2-8d96-42c8-8d7b-d5bb44b641a1",question:"Je ne m’intéresse pas aux sentiments des autres lorsque je cherche à résoudre un problème."},

    {id:"4aeede10-d70a-4916-9de9-0dfed7fdeb69",question:"J’aime prendre soin de mon apparence et de mon environnement."},

    {id:"98414ea3-ad41-41ac-8938-9ebcf7741917",question:"Je pense que faire des erreurs fait partie de l’apprentissage."},

    {id:"02776484-23c5-4db4-87a2-e9fabbc4e2b6",question:"Je préfère passer du temps seul/e, surtout après des activités sociales."},

    {id:"3de0423b-242f-4f17-a7c7-dbc00dfbd6f0",question:"Je fais facilement confiance aux gens et j’aime travailler en groupe."},

    {id:"3569949c-f297-4694-88d5-448aeb4d91db",question:"J’ai tendance à faire mes lessives et mes courses à la dernière minute."},

    {id:"cbffc4ee-9f00-4b9c-832e-2273506775f4",question:"J’aime mes routines et mes habitudes."},

    {id:"165a1258-c0ff-4aab-af86-3ce62db3c03f",question:"J’aime faire attention aux détails et je trouve souvent des erreurs que d’autres ne remarquent pas."},

    {id:"04ff2156-4c04-47a5-997f-952108c6804c",question:"Je préfère travailler en groupe."},

    {id:"dffc6290-a447-4f9c-9a33-230ac72263b4",question:"Je crois que trouver la bonne réponse importe plus que de collaborer."},

    {id:"d62c0ec4-2447-40e0-a657-8abff8d256f3",question:"J’aime être averti en avance, cela me calme."},

    {id:"de7a13c9-f455-45e9-8fe3-13b34b08ab2a",question:"Je ne fais pas particulièrement attention à mon apparence."},

    {id:"73202404-b7f8-4596-9c2c-21cf0da1999c",question:"J’aime apprendre de nouvelles choses."},

    {id:"4c628514-a424-4ee3-8c32-9d181576d44e",question:"J’ai tendance à accepter par défaut lorsque l’on me demande de faire quelque chose, même si je dois ensuite refuser après mûre réflexion."},

    {id:"bdc69cdc-0ffb-4d60-b077-797fc6b743ec",question:"Je préfère prendre du temps pour réfléchir avant de répondre aux demandes des autres."},

    {id:"617d5728-d102-44c7-b67a-d4541d126931",question:"J’arrive à absorber une large charge de travail sans en être affecté."},

    {id:"21a36564-e1c3-4583-9c97-c6cdc2cc8c1e",question:"J’arrive à prendre des décisions même en ayant peu d’information."},

    {id:"14c295ec-41cb-4dda-b975-5db8d3677593",question:"Je n'aime pas le changement."},

    {id:"c037a651-888a-4788-86be-aeb4f3f334dd",question:"J’aime envisager tous les scénarios possibles et imaginables."},

    {id:"80669d38-9a09-4494-9468-d0e8763f7f9a",question:"J’ai tendance à refuser par défaut lorsqu’on me demande de faire quelque chose, en finissant par accepter après mûre réflexion."},

    {id:"cfebd3f3-fd8b-47a5-83bd-d9abeca4c5eb",question:"Je préfère interagir tête-à-tête et en petits groupes, parce que les foules me fatiguent."},

    {id:"1522098d-ffe8-4c97-afab-882c0012fae8",question:"Je suis facilement perdu/e par les longues listes et trop d’information. Je préfère improviser."},

    {id:"c667ce5e-95d2-4597-b4e4-883151739151",question:"Je suis impatient/e lorsqu’un événement social se présente."},

    {id:"67dd6a59-d066-4d84-89fc-655edd181b8b",question:"J’aime lire les citations inspirantes, car elles me calment."},

    {id:"944e545f-b960-4cd4-89be-18d4d6d33caf",question:"J’aime poser des questions aux gens pour en apprendre plus sur eux."},

    {id:"c6ebe858-4cff-4e6a-8d6c-60319838cf7a",question:"J’aime sentir que j’ai le contrôle sur la situation."},

    {id:"ae6e3f1c-23c7-4a47-9112-f70259095fa2",question:"Je tiens à ma vie privée et préfère la séparer de ma vie professionnelle."}

];
export default quizQuestions;
export const quizQuestionsV2 = [
    {id:"bfb4d768-36c4-4708-8bba-71791f34c648",question:"I love to daydream."},
    {id:"acf368fe-5206-4a2e-82e4-e57953e17fd9",question:"I like to get lost in thought."},
    {id:"9a0e0eae-7275-416e-9113-dba8d84fe175",question:"I do not have a good imagination."},
    {id:"791a9ee6-97b6-465d-8497-39a46cbdfd4c",question:"I seldom get lost in thought."},
    {id:"13d1e042-efe1-4e68-a118-fb5783f73d7c",question:"I believe in the importance of art."},
    {id:"28338d7d-62a1-47db-a79c-575e7f8669d5",question:"I need a way to express myself creatively."},
    {id:"fb422dcf-4a78-47d1-ba67-53ed9137ee58",question:"Art rarely makes me feel anything."},
    {id:"62ad914d-e77f-4292-b014-38bb78ad93fa",question:"I am not interested going to concerts or music festivals."},
    {id:"b51a1244-ac1f-49a0-b6da-cb9564ed8160",question:"I feel others' emotions."},
    {id:"7e7fc0ec-0cdc-40ab-bb16-fa44e17a1557",question:"I am passionate about causes."},
    {id:"ec4324ec-b39c-468e-b535-d9d4bcfd20f9",question:"I seldom get emotional."},
    {id:"ae32883c-84ed-4973-a1f1-1b54fe99f5fd",question:"I rarely notice my emotional reactions."},
    {id:"0834e909-c54c-45dd-a13b-c05df4884d79",question:"I like to visit new places."},
    {id:"d54db8e5-ecd9-41a8-a08d-64556deea2a2",question:"I like to begin new things."},
    {id:"2dcbe9d5-5964-44a7-8cd8-c637b8f7497d",question:"I dislike changes."},
    {id:"0c172d4a-b92d-4fab-b7c6-f2f1fb2b09b3",question:"I am attached to conventional ways."},
    {id:"2a39aa22-7d55-4672-982e-668191055bd1",question:"I can handle a lot of information."},
    {id:"24af4c4a-eba2-4a1b-8259-283fcb50500f",question:"I am more interested in intellectual pursuits than anything else."},
    {id:"75bc2bf5-57e5-4cd3-bc00-fe024bbe9841",question:"I have difficulty understanding abstract ideas."},
    {id:"7205ec9e-4f81-459e-bd31-d9691e03d1a0",question:"I avoid difficult reading material."},
    {id:"d4c6d6d3-9666-4724-ba92-492c61636516",question:"I tend to vote for idealistic ideas and candidates."},
    {id:"b8d183db-2e1e-47fd-a527-6b5db9a99fea",question:"I believe that criminals should receive help rather than punishment."},
    {id:"b4698241-fb2c-4320-9eea-dfbe6caa0e56",question:"I tend to vote for conservative ideas and candidates."},
    {id:"f0b5702a-aba3-44be-9ec7-6fb4a001bd68",question:"I believe that we should be tough on crime."},
    {id:"10cde2a4-17b7-4df6-b046-dceea74ce033",question:"I excel in what I do."},
    {id:"e9a328f3-74b7-4bf3-8852-0379d795ab94",question:"I come up with good solutions."},
    {id:"5f22d054-5cbe-4940-be18-f76ab1ea20a3",question:"I sometimes misjudge situations."},
    {id:"453ca76f-50ea-421d-a387-fbcd46c00356",question:"I sometimes don't see the consequences of things."},
    {id:"530bc83c-6cad-46d6-b41a-eb17091b0712",question:"I like order."},
    {id:"4ea5be00-98c8-4cc4-ad6f-8b6e07a6eaa9",question:"I prefer structured environments."},
    {id:"b266c00e-ab14-4ff6-b412-c1d8ba24e671",question:"I often forget to put things back in their proper place."},
    {id:"a5445ccf-c41d-425d-ac78-1d83c2a7acff",question:"I am not bothered by disorder."},
    {id:"c9464941-00e9-4694-8529-61760be1890e",question:"I pay my bills on time."},
    {id:"81013dca-e2f5-4431-8523-19b68e5d6147",question:"I tell the truth."},
    {id:"7725174f-c33e-4909-b3db-5ad11f7930c2",question:"I break my promises when I believe they are wrong."},
    {id:"68b5cc8d-38d9-4a12-b526-60ca0e60db0c",question:"I get others to do my duties."},
    {id:"4fe4c7a8-5e21-40e4-b6b2-edf6abb55d02",question:"I plunge into tasks with all my heart."},
    {id:"9f89e27c-aa59-4d89-b86a-fd6ecf99724c",question:"I set high standards for myself and others."},
    {id:"a14f98bd-1c06-465b-b475-e5370bddbacf",question:"I am satisfied by good enough solutions."},
    {id:"876e1863-cdf8-4b2d-9a2a-356d4d49012c",question:"I put little time and effort into my work."},
    {id:"c6735233-e5db-481b-a474-e743480b66e8",question:"I start tasks right away."},
    {id:"824b7208-1be1-4e3c-bec0-c84aba1f6403",question:"I am more controlled than random."},
    {id:"61517bb6-791d-4ea8-b0cd-38b697901489",question:"I need a push to get started."},
    {id:"cedeae40-2217-4e20-b0eb-342f1e02a26c",question:"I tend to procrastinate a lot."},
    {id:"d598ab78-a496-48e2-9654-d4e819545c8e",question:"I stick to my chosen path."},
    {id:"da990da7-fbb2-426e-b61a-a399e8cec7ca",question:"I analyze factors from multiple perspectives."},
    {id:"41a9ecc9-0e6e-4666-bb7a-558a8e78d60d",question:"I like to act on a whim."},
    {id:"6d8627a3-9142-465d-9c8b-0ec0c3d65b58",question:"I do crazy things."},
    {id:"de4d2010-ca0d-484b-a24d-ee9d12e40591",question:"I warm up quick to others."},
    {id:"2f21ba31-08b8-49d8-b8a1-c5c2f0a2a5a0",question:"I feel comfortable around people."},
    {id:"cb2623dd-e22b-4b75-9fc6-d9be0d75dab5",question:"I often feel uncomfortable around others."},
    {id:"4a0625b5-6c2d-4c8b-bae4-7be8a17d3c2f",question:"I am not really interested in others."},
    {id:"13bd3f17-3f1c-49d3-94f9-4f6581486553",question:"I enjoy being part of a group."},
    {id:"0ea3016f-8b00-474e-938d-b5e84b86fd7a",question:"I involve others in what I am doing."},
    {id:"9019a3da-e497-4aa3-816e-4a163fe5ce3c",question:"I prefer to be alone."},
    {id:"cdb16860-6a88-4cd0-8fbe-e05feb433cd0",question:"I avoid crowds."},
    {id:"096311b9-9f10-47a1-9660-468454dffc8b",question:"I take charge."},
    {id:"dd5ea526-2a5f-448d-a55e-f9a1fff8d16f",question:"I seek to influence others."},
    {id:"f726b26d-d5d7-4739-b710-e5eb93e3bd80",question:"I hold back my opinions."},
    {id:"3f50b3aa-d432-4cee-8c7e-8e92cd4dd12d",question:"I say little."},
    {id:"d9ff8848-3c79-4984-870e-a5d983e9827f",question:"I can manage many things at the same time."},
    {id:"77613e74-d9de-417f-a19a-251d1a78ebd5",question:"I react quickly."},
    {id:"1e82176d-a510-4126-b533-edfbf3172104",question:"I like to take it easy."},
    {id:"b43ed2a3-5a89-4acc-8dc6-2f049319d944",question:"I let things proceed at their own pace."},
    {id:"8b9c0fe7-fda9-4b38-937d-017989921aa0",question:"I enjoy being reckless."},
    {id:"7579798b-2b0b-4a12-b098-6efbaa2de8b0",question:"I am willing to try anything exciting once."},
    {id:"414b4778-07b1-4ff4-99f2-17e3d20aa7a3",question:"I would never go hang gliding or bungee jumping."},
    {id:"abc56726-49f1-45e2-aee0-fdd28d2bf6c8",question:"I dislike loud music and events."},
    {id:"d4aa1839-4086-434d-bad9-2bc47ccf43d0",question:"I have a lot of fun."},
    {id:"7fb81e51-d5d5-4e17-af45-1cf180877599",question:"I love life."},
    {id:"1bee49f7-ed27-40a2-bb25-e19ade463c0f",question:"I am not easily amused."},
    {id:"e7c1a414-933c-4f95-9cdc-0beced0d098e",question:"I seldom joke around."},
    {id:"1574b116-c6de-486d-8c09-f122f7285b7a",question:"I believe that others have good intentions."},
    {id:"48eed50f-abbf-47e2-8f0d-4c0fc4bca6ee",question:"I trust what people say."},
    {id:"3131aff9-93ab-4d16-a122-4ff116d4a320",question:"I distrust people."},
    {id:"86a18b63-e20c-48bb-9b70-c06e4e26c10e",question:"I am wary of others."},
    {id:"7c7e0ba1-aea0-4561-a221-74802ddfbc7c",question:"I would never cheat on my taxes."},
    {id:"b02d7f2f-c900-4b72-9c02-562de4eeaa26",question:"I hate lying to others."},
    {id:"8d4d7ea3-eaef-4cf6-a750-71f2ef9508f0",question:"I know how to get around the rules."},
    {id:"b6d9bbf9-16b0-4a84-93a2-d2ab4aa8780d",question:"I obstruct others' plans."},
    {id:"8dccad07-504a-496e-bea9-5c586c772138",question:"I make people feel welcome."},
    {id:"338eb88d-1797-466c-b47a-9e2367a78eb4",question:"I am concerned about others."},
    {id:"483ee29f-feed-4fc0-9edf-fe0ebd830c07",question:"I am indifferent to the feelings of others."},
    {id:"75d3f490-b16c-41d2-b94e-a25d3b5c647d",question:"I take little time for others."},
    {id:"fad1cbca-a82f-4ca1-8b2c-1ae849bb309e",question:"I am easy to satisfy."},
    {id:"92ced2f7-61ac-4228-8420-b4a2e46da009",question:"I hate to seem pushy."},
    {id:"1ba06628-ca73-4423-9819-2c3792dd2f76",question:"I have a sharp tongue."},
    {id:"0434cdc7-e9fc-4598-aa0a-780f19a4187b",question:"I get back at others."},
    {id:"be9fd20e-98ee-4660-b7fe-2a0e2cefabc4",question:"I dislike talking about myself."},
    {id:"5626cf1b-dc21-43a3-9f5a-5248264d79da",question:"I consider myself an average person."},
    {id:"ed52916a-de39-4923-9dd1-f4ccb66efb83",question:"I know the answers to many questions."},
    {id:"f8b2c1d5-2cfc-4588-a8b6-18a4db85daf7",question:"I don't mind being the center of attention."},
    {id:"28fd3faa-fcfe-4dbd-b6a5-20853513d743",question:"I sympathize with the homeless and the less fortunate."},
    {id:"0b50ed98-f2f3-437a-955b-ba9f8417a52f",question:"I suffer from others' sorrows."},
    {id:"d2d3d75c-047b-40a9-be4c-c33061314080",question:"I tend to dislike soft-hearted people."},
    {id:"e121a515-efac-49af-bf3f-ec8afd997c78",question:"I try not to think about those in need."},
    {id:"e20e4710-5479-4200-96a7-37b955685edd",question:"I get stressed out easily."},
    {id:"48cb200e-9401-4c74-a3ad-400b4a72c819",question:"I have many fears."},
    {id:"627babeb-716d-4b09-8e3d-cda70828f5fa",question:"I am not easily bothered by things."},
    {id:"37745ffe-9fec-45d9-b791-53372b3fbd25",question:"I am relaxed most of the time."},
    {id:"a0441087-0d6d-4190-aabe-7f0feecf3619",question:"I get angry easily."},
    {id:"2337856f-5dab-4ff0-999e-dbf6a7340927",question:"I am often in a bad mood."},
    {id:"0cdde8f5-2d58-44b9-a369-aaa7f53eda51",question:"I rarely get irritated."},
    {id:"279b9040-4faa-4641-94ec-dd70e6058f44",question:"I keep my cool."},
    {id:"3b58de42-9732-49e2-8fb2-bd3056952c5e",question:"I have frequent mood swings."},
    {id:"92c855ea-b9eb-495e-add8-274500cce58f",question:"I sometimes feel disheartened."},
    {id:"efe999f7-ee67-4fc7-b0a5-be88d72d3efb",question:"I rarely feel sad."},
    {id:"38aaf9fa-88c4-458d-9475-5c2cf866dda2",question:"I feel comfortable with myself."},
    {id:"8c8e7d26-43da-402f-ba7a-1c41496e6884",question:"I am easily intimidated."},
    {id:"0fa6511d-f160-494a-8a18-7c50cb002764",question:"I am easily hurt."},
    {id:"af28b9db-a877-4500-9e38-6eabbff9a9ac",question:"I am not embarrased easily."},
    {id:"79b8b401-6fa0-4ed8-977a-ebe50e999ae4",question:"I am not bothered by difficult social situations."},
    {id:"1c6a2188-76f3-469b-8ec6-3bc22c3d2bb5",question:"I often eat too much."},
    {id:"33319474-be8b-45d4-99ef-329e9e8406c3",question:"I go on binges."},
    {id:"a5f91dc4-c097-4d23-82e4-d39d55d8593a",question:"I rarely overindulge."},
    {id:"8fd93e65-368f-4fa9-9f92-6230f2ea89c6",question:"I never spend more than I can afford."},
    {id:"8c8813bc-f070-4df0-813c-a37a62b3eb17",question:"I sometimes can't make up my mind."},
    {id:"b1688eb6-c91a-4e32-8d4d-bf8ec5408c3f",question:"I get overwhlemed by emotions."},
    {id:"af013429-cf62-4421-8a84-0cfec7ca98f1",question:"I can handle complex problems."},
    {id:"029ac525-2b5a-468d-82a4-6c21ac0f1cb3",question:"I readily overcome setbacks."}
];