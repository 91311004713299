import React from 'react';
import Button from 'react-bootstrap/Button';


 function Resources(props: any) {
	   
  return (
   <div>
		<h1>Resources</h1>

		<p>Here are a list of articles we created - which are part of our e-learning courses - as well as articles and books others have written, which fueled our work.</p>

		<Button><a href="/ei-course">Go To The Course</a></Button>

		<h2>Articles From MyOceanity</h2>

		<h3>Emotional Intelligence</h3>

		<ul>
			<li><a href="/what-is-ei">What is emotional intelligence?</a></li>
			<li><a href="/why-ei-matters">Why does emotional intelligence matter in our lives?</a></li>
			<li><a href="/grow-your-ei">How to grow your emotional intelligence</a></li>
			<li><a href="/ei-benefits-work">Benefits of emotional intelligence at work</a></li>
			<li><a href="/ei-benefits-home">Benefits of emotional intelligence at home</a></li>
			<li><a href="/core-emotions">What are our core emotions?</a></li>
			<li><a href="/emotional-literacy">How can you grow your emotional literacy?</a></li>
			<li><a href="/reading-emotions">How to read people’s emotions</a></li>
			<li><a href="/ocean-personality-traits">OCEAN personality traits</a></li>
			<li><a href="/oceanology">OCEANology</a></li>
			<li><a href="/myoceanity-and-ei">How to grow your emotional intelligence using MyOceanity</a></li>
			<li><a href="/identity-introduction">Introduction to the concept of identity</a></li>
			<li><a href="/ikigai">What makes a life worth living? What is Ikigai?</a></li>
			<li><a href="/identity-mission">Why are you here? What is your mission in life?</a></li>
			<li><a href="/identity-values">What do you stand for? What are your core values?</a></li>
			<li><a href="/identity-beliefs">Who are you? What are your beliefs about yourself and the world around you?</a></li>
			<li><a href="/journaling-self-awareness">How journaling helps you become more self-aware (WIP)</a></li>
			<li><a href="/journaling-self-development">How you can leverage your self-awareness to create strategies for self-development (WIP)</a></li>
			<li><a href="/journaling-introspection">How introspections help us dig towards the core of our selves and why it matters (WIP)</a></li>
		</ul>

		<h2>Great Reads</h2>

		<h3>Articles We Recommend</h3>

		<ul>
			<li>The 3 elements of trust | Harvard Business Review | By Zenger Folkman</li>
			<li>Harnessing the science of persuasion | Harvard Business Review | By Robert B. Cialdini</li>
			<li>Manage your energy, not your time | Harvard Business Review | By Tony Schwartz &amp; Catherine McCarthy</li>
		</ul>

		<h3>Books We Recommend</h3>

		<ul>
			<li>Mindset: the new psychology of success | By Carol Dweck</li>
			<li>Give and take: a revolutionary approach to success | By Adam Grant</li>
			<li>Start with why | By Simon Sinek</li>
			<li>The Infinite Game | By Simon Sinek</li>
			<li>Leaders Eat Last | By Simon Sinek</li>
			<li>Atomic Habits: tiny habits, remarkable changes | By James Clear</li>
			<li>Emotional Intelligence | By Daniel Goldman</li>
			<li>Emotional Intelligence 2.0 | By Travis &amp; Bradberry </li>
			<li>Captivate: the science of succeeding with people | By Vanessa van Edwards</li>
			<li>12 Rules For Life | By Jordan P. Peterson</li>
			<li>The Mind of the Leader: how to lead yourself, your people and your organization for extraordinary results | By Rasmus Hougaard &amp; Jacqueline Carter</li>
			<li>Flow: The Psychology of Optimal Experience | By Mihaly Csikszentmihalyi</li>
			<li>Deep Work: Rules for Focused Success in a Distracted World | By Cal Newport</li>
			<li>So Good They Can’t Ignore You: Why Skills Trump Passion in the Quest for Work You Love | By Cal Newport</li>
			<li>The Lean Startup: How Constant Innovation Creates Radically Successful Businesses | By Eric Ries</li>
		</ul>

   </div>
  )
}
export default Resources;

