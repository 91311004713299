import React from 'react';
import Table from 'react-bootstrap/Table';


function JournalingIntrospection(props: any) {
	   
  return (
   <div className="masthead">
			<h1>How Journaling Helps Understanding Yourself Better Through Introspection</h1>
			
      <br /><br />
			<p>This lesson is still under development. Please come back later!</p>
			
			<Table striped borderless hover responsive className="course-table">
				<thead>
					<tr><th className="course-table-header">Module 5: Why Introspection Matters In Emotional Intelligence</th></tr>
				</thead>
				<tbody className="course-table-body">
					<tr> <td> <a href="/journaling-self-awareness">How journaling helps you become more aware (WIP)</a></td> </tr>
					<tr> <td> <a href="/journaling-self-development">How you can leverage your self-awareness to create strategies for self-development (WIP)</a></td> </tr>
				</tbody>
			</Table>

			<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
            </div>
			<br /><br /><br />

   </div>
  )
}
export default JournalingIntrospection;

