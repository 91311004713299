import React from 'react';
import { Table } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';
import * as Icon from 'react-bootstrap-icons';


import elementsofei from "../images/elements-of-ei.png";


function IdentityValues() {
	
return (
      
<div>
	   <body className="masthead" data-spy="scroll" data-offset="60" data-target="#myNavbar">

			<Scrollspy items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5', 'section-6' ] } currentClassName="is-current" className="sticky-top scrollspy overflow-scroll-horizontal" offset={20}>
				<li><a href="#section-1" className="scrollspy-link">Top</a></li>
				<li><a href="#section-2" className="scrollspy-link">Why values matter</a></li>
				<li><a href="#section-3" className="scrollspy-link">Values & habits</a></li>
				<li><a href="#section-4" className="scrollspy-link">Tips & advice</a></li>
				<li><a href="#section-5" className="scrollspy-link">E.g. Chris' values</a></li>
				<li><a href="#section-6" className="scrollspy-link">Your next steps</a></li>
			</Scrollspy>


			<h1>What are Your Core Values in Life? What Do You Stand For?</h1>
			

			<section id="section-1" className="mt-3">
			<h2>Discovering Your Core Values</h2>
			<p>This a life-changing exercise I went through in early 2018, which is about discovering your Core Values in life. You can do so by downloading the following PDF file, which contains almost 200 values.</p>

			<p>Download placeholder: Value list worksheet</p>

			<h3>Here is how it works:</h3>

			<ol>
				<li>Review the entire list of values.</li>
				<li>Search for the ones that really stick and resonate with you, ideally around 10.</li>
				<li>Reflect on <i>WHY</i> they matter to you.</li>
				<li>Go back to your 10 values and reduce the list to 5.</li>
				<li>Reflect again on how these 5 values define what you stand for and why they matter to you.</li>
				<li>Reduce the list to your core three values.</li>
				<li>Use your own words to define your core values, to help you internalize them and make them your own.</li>
				<li>If you want, define which one is your essential core value.</li>
			</ol>
			</section>


			<section id="section-2">
			<h2>Why Values Matter</h2>
			
			<div className="notabene">
				<b>I believe discovering your values is one of the first steps towards living a more intentional life.</b>
			</div>
			
			<p>Why? <b>Because living in alignment with what you stand for feels fulfilling</b>. Similarly, acting or thinking in contradiction to your values and beliefs feels uncomfortable because of cognitive dissonance.</p>
			<p>When we act against what we believe in or what we stand for, we may become defensive or aggressive and start hurting ourselves and those around us. The worse part is that we may not even be aware of what is happening! All we know is that it feels wrong and that it hurts.</p>
			
			<p>Let's take an example and imagine that one of your core values is "Helping others": </p>
			<ul>
				<li>Helping your coworker Joan felt good and rewarding, even when it cost you a promotion, because you sacrificed time and energy you could have spent working towards your objectives.</li>
				<li>Similarly, getting a promotion by crushing Joan can leave you dissatisfied, because you were not acting consistently with what mattered to you.</li>
			</ul>
			
			<p>This situation can be flipped, by considering "Achievement" as a value, rather than "Helping others". In this situation, getting ahead, even at the expense of Joan's well-being and of the relationship, trumps helping her.</p>
			<p>So far, I believe that our personal Identity can be broken down into three elements:</p>
			
			<div className="notabene">
				<b>Identity = Mission + Values + Beliefs</b>
			</div>

			<ol>
				<li><a href="/identity-mission">Your Mission</a>, answers the question, "Why am I here?"</li>
				<li><a href="/identity-values">Your Core values</a>, answer the question, "What do I truly stand for?"</li>
				<li><a href="/identity-beliefs">Your Beliefs</a> about yourself, answer the question, "Who am I?"</li>
			</ol>		
				
			<p>This lesson focuses on Values, while others will cover your Mission and your Beliefs.</p>
			<p></p>
			
			<div className="myimghor w-75">
				<img src={elementsofei} alt="The 4 elements of emotional intelligence" />
			</div>

			<div className="imgcaption">
				<p><b>Discovering your Identity is key, because it allows you to build your Self-Awareness, one of the 4 elements of emotional intelligence.</b></p>
			</div>

			<p>Becoming more self-aware, therefore better understanding who you are and what makes you tick, brings clarity in your life, which makes decisions and living easier. </p>
			<p><b>When you understand where you want to go and who you want to become, as well as the core values that will help you get there, you can then engage in Self-Development, the second element of emotional intelligence.</b></p>
			<p>This idea is derived from Jim Collins' <b>"first who, then what" principle</b>, taken from his book "Good to Great", which focuses on building great organizations. </p>
			<p>Taken to the individual level, the knowledge derived from Self-Awareness helps you become grittier and better able to adapt to changes in your environment and to stay the course. You become self-motivated by your inner drive to continuously become a better version of yourself. </p>
			<p>This also means that you need to work hard to understand who you are today and who you wish to become tomorrow and why that is, before starting any change. Otherwise, how will you realize that you are going in the right direction?</p>
			<p>I believe our values shape our identity because they guide and judge our actions and interactions. We make decisions based on our values and beliefs. As a result, our values not only influence who we are and how we behave today, but also shape the future we wish to experience by acting as a beacon of light.</p>
			<p>Your values, beliefs and mission in life, in short, your Identity, embody your WHY, as Simon Sinek described in his book, "Start With Why": Your WHY (Identity) influences your HOW (Behavior), which finally influences your WHAT (Results).</p>
			<p>Your WHAT represents the results you get from acting a certain way. It's the proof that what you are doing works well, or not. It guides you to see if you are making progress or not.</p>
			</section>



			<section  id="section-3">
			<h2>Values And Changing Habits</h2>
			<p>Discovering my values was key to helping me change my habits. Beforehand, whenever I tried to break a bad habit - or to build a new one - I did so through sheer willpower and discipline, which always made me give up. What seemed to work in the short run could not be sustainable in the long run, because it was too exhausting to do so. </p>
			<p>Since I turned 30, I kept slowly gaining weight, mostly because I barely exercised at all. This went against my value of development, where I believe I can continuously become slightly better every day.</p>
			<p>My value was reinforced by my belief that I could become a fit and healthy person. And I started thinking, "How can I become fitter and healthier?" and started changing my habits:</p>
			
			<ul>
				<li>I walked home instead of taking the bus</li>
				<li>I took the stairs instead of the elevator</li>
				<li>My wife and I spent more time off hiking and biking</li>
				<li>My wife and I went swimming more time in two years than in almost two decades, once or twice per week!</li>
			</ul>
			
			<p>This demonstrates something I also believe in: Through our mental filter, our values steer what we should focus on and what truly matters to us in life, in addition to what we should do.</p>
			<p><b>The last reason why I find values crucial in our lives, is that they bring people together</b>. Simon Sinek described this, by saying that people who share similar values to an organization's will want to work for them or to buy from them.</p>
			<p>Dr. Robert Cialdini described this phenomenon of attraction as the "Liking principle" in his book "Influence: The Psychology Of Persuasion", among five other principles. </p>
			<p>When we like some people, because of the way they act or speak, we may like them because we share common values. We develop relationships with some people or organizations because of our similarities.</p>
	   		</section>



			<section  id="section-4">
			<h2>Tips And Advice</h2>
			<p>I want to stress that the exercise of discovering your core values can feel extremely challenging and that it is likely going to take a long time until you feel like you've gotten it right.</p>
			<p>It took me months to discover mine. It took a lot of reflection and was a long iterative process. One reason why that was is that I did not have a list of values I could refer to and reflect on. I was only looking for common themes of meaningful events in my life.</p>
			<p>However, every time I did discover a core value, I experienced a deep "aha!" moment. This usually happened when my mind was calm, after I spent much time intensely reflecting. These defining experiences signal that you are on the right track. A lightbulb goes off into your mind and makes things clearer.</p>
			<p>When designing the list of values, I intentionally picked similar words, as I think that one may resonate more with you than others. If you are hesitant on which one to choose, you can group them into categories and choose the one that feels right.</p>
			<p>For example, I identify strongly with the words "Learning", "Growth", and "Development". But for some reason, "Development" is the one which stuck with me the most, which is why I chose it to be one of my three core values.</p>
			<p>If you are stuck in the process, you can try asking yourself the following questions:</p>
			
			<ul>
				<li>What meaningful moments did I experience in my life? What value(s) did I live up to in these moments?</li>
				<li>What dreadful moments did I experience in my life? What value(s) were neglected or attacked?</li>
				<li>In both the good and the bad moments, what did I learn about myself? What could I do differently?</li>
				<li>What values do I currently embody well? Which one(s) do I want to develop further? How can I do so?</li>
			</ul>
			</section>

			<section  id="section-5">
			<section className="story">
			<h3>Illustration | Chris' Core Values</h3>
			
			<div className="quote">
				<p><i>Respect, Development, Durability</i></p>
			</div>

			<p>A key part of this exercise is to define your core values. When describing a core value, you should think of situations where you would truly live by it.</p>
			
			<h4>Respect</h4>
			<ul>
				<li>Treat each person I encounter with respect & decency. Everyone deserves to be treated well, especially as people nowadays are gradually treating each other poorly.</li>
				<li>Apologize for my mistakes.</li>
				<li>Sincerely forgive when someone hurts me.</li>
				<li>Stand up when noticing someone else's suffering.</li>
				<li>Take the blame for my own and my people's faults; give credit and show others' achievements.</li>
				<li>Take a time out to put myself in other people's shoes when conflicts arise, instead of negatively reacting and feeding the fire. Understand that someone may have pushed their buttons earlier and realize that I have a choice between:</li>
			</ul>
			
			<ol>
				<li>Letting my emotions take the best of me and making things worse by escalating the conflict, or</li>
				<li>Listening to the other person's perspective and understand how I could help them.</li>
			</ol>
			
			<h4>Development</h4>
			<ul>
				<li>Live with a Growth Mindset, trying to learn and read every day, to continuously become a better person than I was yesterday.</li>
				<li>Accept mistakes as part of the learning and development journey.</li>
				<li>Encourage others in their own journeys, asking for and offering them feedback and sharing ideas to grow lasting, mutually beneficial relationships.</li>
				<li>Believing that there is always at least one thing a person can teach me in any interaction.</li>
			</ul>
			
			<h4>Durability</h4>
			<ul>
				<li>Reflect on the long-term impact of my words, actions and decisions and avoid focusing mainly on short-term gratifications. This is applicable for ecological, financial, organizational or social situations.</li>
				<li>Accept that I must sometimes experience discomfort, should this result in the greater good of everyone, myself included.</li>
			</ul>
			</section>
			</section>

			<div className="separator" />


			<section id="section-6">
			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>I hope that getting to know yourself better by discovering your core values will help you, as this was a defining moment in my life. Discovering my core values allowed me to improve my relationships with others both personally and professionally and has been a big driver of my development in both areas of life.</p>
			<p>The initial clarity that this simple exercise has created has rippled into many situations, where I catch myself being surprised by how much I don't truly know myself. These moments are usually followed by great satisfaction derived from the opportunity to get to know myself a little better.</p>
			<p>I encourage you to stick to it and to frequently revisit your core values as well as the list of values I provided, as well as how you defined them, as this is a continuous improvement process. As you experience life and change, perhaps your values will shift slightly. And that's okay. What matters is that they resonate with you and that they help you and others around you.</p>
			
			<p>Whether we realize it or not, our identity shapes our lives. Your values are the foundation upon which you build your life. They reward you when you honor them and remind you when you start straying off your path.</p>
			<p>There are a few ways you can discover your identity:</p>

			<div className="separator" />

			<Table striped borderless hover responsive className="course-table">
				<thead>
					<tr><th className="course-table-header">Module 4: Discovering Your Identity</th></tr>
				</thead>
				<tbody className="course-table-body">
					<tr> <td> <a href="/identity-introduction">Introduction to the concept of Identity</a></td> </tr>
					<tr> <td> <a href="/ikigai">What is a meaningful life? What is Ikigai?</a></td> </tr>
					<tr> <td> <a href="/identity-mission">Why are you here? What is your Mission? What gives your life purpose?</a></td> </tr>
					<tr> <td> <a href="/identity-beliefs">Who are you? What are your beliefs about yourself and the world around you?</a></td> </tr>
				</tbody>
			</Table>

			<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
            </div>
			<br /><br /><br />
			</section>
	</body>
			
</div>
  
)
}
export default IdentityValues;

