import React from 'react';
import { ASubstyle, CSubstyle, ESubstyle, NSubstyle, OSubstyle } from "../components/types";
import { Accordion, Card } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { ChevronDown } from 'react-bootstrap-icons';

import ReactMarkdown from 'react-markdown'

import newspaperimg from '../images/newspaper.jpg';
import adventureimg from '../images/adventure.jpg';
import carefreeimg from '../images/carefree.jpg';
import cleandeskimg from '../images/cleandesk.jpg';
import readingimg from '../images/reading.jpg';
import partyimg from '../images/party.jpg';
import trustimg from '../images/trust.jpg';
import disagreementimg from '../images/disagreement.jpg';
import worry1img from '../images/worry-1.jpg';
import worry3img from '../images/worry-3.jpg';
import relaxedimg from '../images/relaxed.jpg';
import conversation2 from '../images/conversation2.jpg';
import conversation3 from '../images/conversation3.jpg';
import conversation4 from '../images/conversation4.jpg';
import conversation5 from '../images/conversation5.jpg';
import conversation6 from '../images/conversation6.jpg';
import conversation7 from '../images/conversation7.jpg';
import conversation8 from '../images/conversation8.jpg';
import conversation9 from '../images/conversation9.jpg';
import highfive from '../images/highfive.jpg';
import communicationtriangle from '../images/communication-triangle.png';
import omismatch from '../images/openness-mismatch.png';
import cmismatch from '../images/conscientiousness-mismatch.png';
import emismatch from '../images/extroversion-mismatch.png';
import amismatch from '../images/agreeableness-mismatch.png';
import nmismatch1 from '../images/nervosity-mismatch-1.png';
import nmismatch2 from '../images/nervosity-mismatch-2.png';
import Oideas from '../images/idea.png';
import Ofantasy from '../images/imagination.png';
import Oaesthetics from '../images/aesthetics.jpg';
import Oemotionality from '../images/emotionality.png';
import Oadventure from '../images/adventure1.jpg';
import Ovalues from '../images/o-values.png';

import omismatchfr from "../images/openness-mismatch-fr.png";
import cmismatchfr from '../images/conscientiousness-mismatch-fr.png';
import emismatchfr from '../images/extroversion-mismatch-fr.png';
import amismatchfr from '../images/agreeableness-mismatch-fr.png';
import nmismatch1fr from '../images/nervosity-mismatch-1-fr.png';
import nmismatch2fr from '../images/nervosity-mismatch-2-fr.png';


function overviewhighO(i18n, oSubStyle: OSubstyle) {
    return (
        <div>
            <h4>{i18n.t('Your High-Openness Levels')}</h4>
            <ReactMarkdown>{i18n.t('You tend to be excited about discovering new activities')}</ReactMarkdown>
        </div>
    )
}

function overviewlowO(i18n, oSubStyle: OSubstyle) {
    return (
        <div>
            <h4>{i18n.t('You Have Low Openness Levels')}</h4>
            <ReactMarkdown>{i18n.t('You tend to prefer sticking to your habits and routines')}</ReactMarkdown>
        </div>
    )
}

function overviewhighC(i18n, cSubStyle: CSubstyle) {
    return (
        <div>
            <h4>{i18n.t('Your High-Conscientiousness Levels')}</h4>
            <ReactMarkdown>{i18n.t('You tend to pay great attention to details')}</ReactMarkdown>
        </div>
    )
}

function introO(i18n) {
    return (
        <div className="trait-intro myreportP">
            <ReactMarkdown className="myreportP">{i18n.t('This trait describes')}</ReactMarkdown>
            <ul>
                <li>{i18n.t('How much do you seek and appreciate new ideas and experiences?')}</li>
                <li>{i18n.t('How curious are you?')}</li>
                <li>{i18n.t('How creative and imaginative are you?')}</li>
                <li>{i18n.t('How much do you appreciate variety and originality?')}</li>
                <li>{i18n.t('How receptive to change are you?')}</li>
            </ul>

            <ReactMarkdown className="myreportP">{i18n.t('you-will-learn-more-about')}</ReactMarkdown>

            <ul>
                <li><a href="#Odescription">{i18n.t('your-openness-to-experience-levels')}</a></li>
                <li><a href="#Ofacets">{i18n.t('your-6-openness-to-experience-facets')}</a></li>
                <li><a href="#Operception">{i18n.t('how-you-may-be-perceived')}</a></li>
                <li><a href="#mismatchO">{i18n.t('examples-of-misunderstandings-of-openness-to-experience')}</a></li>
                <li><a href="#workwithhighO">{i18n.t('how-to-better-interact-with-high-and-low-openness-to-experience-people')}</a></li>
                <li><a href="#questionsO">{i18n.t('questions-to-assess-openness-to-experience')}</a></li>
            </ul>

        </div>
    );
};

function introC(i18n) {
    return (
        <div className="trait-intro">
            <ReactMarkdown>{i18n.t('This trait describes how you get things done')}</ReactMarkdown>
            <ul>
                <li>{i18n.t('How self-disciplined are you?')}</li>
                <li>{i18n.t('How organized are you?')}</li>
                <li>{i18n.t('How reliable are you?')}</li>
                <li>{i18n.t('How persistent are you?')}</li>
            </ul>

            <ReactMarkdown className="myreportP">{i18n.t('you-will-learn-more-about')}</ReactMarkdown>

            <ul>
                <li><a href="#Cdescription">{i18n.t('your-conscientiousness-levels')}</a></li>
                <li><a href="#Cfacets">{i18n.t('your-6-conscientiousness-facets')}</a></li>
                <li><a href="#Cperception">{i18n.t('how-you-may-be-perceived')}</a></li>
                <li><a href="#mismatchC">{i18n.t('examples-of-misunderstandings-of-conscientiousness')}</a></li>
                <li><a href="#workwithhighC">{i18n.t('how-to-better-interact-with-high-and-low-conscientiousness-people')}</a></li>
                <li><a href="#questionsC">{i18n.t('questions-to-assess-conscientiousness')}</a></li>
            </ul>

        </div>
    );
};

function introE(i18n) {
    return (
        <div className="trait-intro">
            <ReactMarkdown>{i18n.t('This trait describes how you approach and relate to people and the social world')}</ReactMarkdown>
            <ul>
                <li>{i18n.t('How comfortable are you in larger groups of people?')}</li>
                <li>{i18n.t('How comfortable are you in one-on-one interactions?')}</li>
                <li>{i18n.t('How comfortable are you being alone with your thoughts?')}</li>
                <li>{i18n.t('How energized are you by interacting with other people?')}</li>
            </ul>
        </div>
    );
};

function introA(i18n) {
    return (
        <div className="trait-intro">
            <ReactMarkdown>{i18n.t('This trait describes how you approach cooperation and working with others')}</ReactMarkdown>
            <ul>
                <li>{i18n.t('How empathetic are you?')}</li>
                <li>{i18n.t('How much do you pay attention to other peoples emotions and needs?')}</li>
                <li>{i18n.t('How quick to forget are you?')}</li>
                <li>{i18n.t('How much do you value solving the problem versus helping people?')}</li>
            </ul>
        </div>
    );
};

function introN(i18n) {
    return (
        <div className="trait-intro">
            <ReactMarkdown>{i18n.t('This trait describes how well you can keep calm under pressure')}</ReactMarkdown>
            <ul>
                <li>{i18n.t('How do you approach worry?')}</li>
                <li>{i18n.t('How emotionally reactive are you?')}</li>
                <li>{i18n.t('What is your tendency towards negative emotions?')}</li>
                <li>{i18n.t('How often do you engage in self-limiting thinking?')}</li>
            </ul>
        </div>
    );
};

function mismatchO(i18n) {
    return (
        <div className="trait-mismatch">
            <br />
            <section className="story" id="mismatchO">
                <h3>{i18n.t('Example Of Openness Misunderstandings')}</h3>
                
                <h4>{i18n.t('at-work')}</h4>
                <div className="myimghor">
                    <img src={omismatch} alt="Openness to experience mismatch" />
                </div>

                <ReactMarkdown>{i18n.t("Pauline, a High-Openness manager...")}</ReactMarkdown>
                <ReactMarkdown>{i18n.t("Unfortunately, Patricia did not realize...")}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('Frustrated by Pauline...')}</ReactMarkdown>

                <h5>{i18n.t('what-could-pauline-have-done-differently')}</h5>
                <ReactMarkdown>{i18n.t('she-should-have-realized-which-of-garys-routines-were-helping-him-and-others-be-more-productive-and-which-were-counterproductive-then-she-could-discuss-with-him-which-of-the-new-tasks-she-wanted-to-implement-could-leverage-his-strengths-without-creating-too-much-uncertainty-and-novelty-in-his-work')}</ReactMarkdown>
                                
                <h5>{i18n.t('what-about-gary')}</h5>
                <ReactMarkdown>{i18n.t('he-needs-to-understand-paulines-desire-to-help-patricia-and-him-do-an-even-better-job-at-contributing-to-the-companys-mission-she-is-not-advocating-for-change-for-changes-sake-but-to-make-sure-they-stay-engaged-in-helping-their-clients-he-also-needs-to-take-a-critical-look-at-how-new-tools-and-systems-could-help-reduce-his-workload-rather-than-continuing-working-long-hours-to-do-repetitive-tasks-himself-this-way-the-free-time-he-would-gain-could-be-used-to-do-the-work-he-actually-loves-doing')}</ReactMarkdown>


                <h4>{i18n.t('with-family-and-friends')}</h4>
                <div className="myimghor">
                    <img src={omismatch} alt="Openness to experience mismatch" />
                </div>

                <ReactMarkdown>{i18n.t('patrick-a-high-openness-adventurous-man-is-happily-married-to-gwen-a-creature-of-habit-every-week-patrick-suggests-a-new-and-exciting-activity-they-could-try-together-usually-he-feels-disappointed-because-gwen-would-rather-do-the-activities-theyve-cherished-since-they-met')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('his-need-to-experience-novelty-and-discover-new-places-leaves-him-craving-for-more-and-upset-with-her-inflexibility-meanwhile-gwen-sometimes-feels-harassed-by-his-endless-pleas-for-adventure-she-wonders-if-he-understand-how-much-their-beloved-habits-means-to-her-over-time-they-start-growing-apart-from-each-other')}</ReactMarkdown>
                
                <h5>{i18n.t('how-could-patrick-convince-gwen-without-rushing-her')}</h5>
                <ReactMarkdown>{i18n.t('he-could-discuss-with-her-why-she-loves-some-of-their-activities-and-not-others-to-understand-whether-they-can-find-new-ones-which-would-satisfy-both-his-need-for-novelty-and-her-need-for-stability-perhaps-gwen-is-scared-by-some-of-the-audacious-adventures-patrick-finds-exciting-he-could-also-reflect-on-how-much-some-of-their-routines-mean-to-him-and-see-how-they-could-spend-more-quality-time-engaged-in-these-activities')}</ReactMarkdown>

                <h5>{i18n.t('what-about-gwen')}</h5>
                <ReactMarkdown>{i18n.t('she-could-ask-him-to-share-which-new-activities-he-is-truly-looking-forward-to-trying-and-reflect-on-which-ones-she-would-also-enjoy-doing-based-on-her-deep-understanding-of-what-makes-her-tick-after-all-theyve-enjoyed-hiking-through-the-south-of-france-last-summer-why-not-try-exploring-the-swiss-mountains-and-sleeping-in-a-hut-this-way-he-could-go-on-an-adventure-in-a-new-place-while-she-could-do-something-she-relishes-as-well-after-all-patrick-suggested-the-french-hiking-experience-and-he-was-right')}</ReactMarkdown>
            </section>
        </div>
    );
};

function mismatchC(i18n) {
    return (
        <div className="trait-mismatch">
            <br />
            <section className="story" id="mismatchC">
                <h3>{i18n.t('Example Of Conscientiousness Misunderstandings')}</h3>

                <h4>{i18n.t('at-work')}</h4>
                
                <div className="myimghor">
                    <img src={cmismatch} alt="Conscientiousness mismatch" />
                </div>

                <ReactMarkdown>{i18n.t("Jon, a highly conscientious person...")}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('As a result, Stacy')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('At the end of her six month internship')}</ReactMarkdown>
                
                <h5>{i18n.t('what-could-jon-have-done-differently')}</h5>
                <ReactMarkdown>{i18n.t('while-jons-thorough-and-exhaustive-explanations-are-helpful-to-make-sure-stacy-wouldnt-miss-important-details-he-should-have-also-prepared-a-more-high-level-overview-of-what-is-expected-of-her-starting-with-a-less-accurate-and-more-accessible-plan-would-allow-her-to-understand-the-big-picture-and-ask-questions-for-the-details-she-doesnt-understand-yet-then-jons-methodical-work-could-come-in-handy-as-it-would-clarify-stacys-uncertainties-starting-with-too-much-information-was-jons-biggest-mistake')}</ReactMarkdown>

                <h5>{i18n.t('what-about-stacy')}</h5>
                <ReactMarkdown>{i18n.t('she-should-have-clarified-upfront-with-jon-how-lost-she-was-because-of-all-the-details-he-threw-at-her-from-the-start-she-could-have-drawn-with-him-the-big-picture-of-what-he-expected-from-her-and-slowly-connect-the-dots-which-he-provided-in-his-lengthy-explanations-this-way-she-could-leverage-his-attention-to-details-and-deep-knowledge-to-create-a-more-understandable-plan-of-action-which-she-would-be-sure-would-be-as-efficient-as-could-be')}</ReactMarkdown>

                <h4>{i18n.t('with-family-and-friends')}</h4>

                <div className="myimghor">
                    <img src={cmismatch} alt="Conscientiousness mismatch" />
                </div>

                <ReactMarkdown>{i18n.t('jennifer-a-highly-conscientious-working-mother-is-frustrated-by-how-her-son-steve-behaves-he-is-often-late-keeps-forgetting-things-and-his-room-is-always-a-mess-how-many-times-will-she-have-to-remind-him-of-picking-up-his-dirty-socks')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('she-feels-like-every-time-she-asks-him-to-do-something-when-he-doesnt-forget-to-do-it-he-never-gives-it-his-best-effort-and-she-needs-to-pick-up-after-him-fed-up-with-his-lack-of-effort-she-finally-tells-him-he-cant-do-anything-right-she-immediately-regrets-her-words-when-she-sees-the-sadness-on-his-face-before-he-storms-out')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('steve-broods-on-why-his-mother-treated-him-this-way-since-hes-trying-his-best-after-all-she-has-extremely-high-expectations-on-how-clean-the-house-should-be-and-never-seems-satisfied-even-with-her-husband-and-their-daughters-no-matter-how-hard-he-tries-to-please-her-he-always-misses-the-mark-because-of-a-few-things-he-did-not-consider-to-be-essential-to-clean')}</ReactMarkdown>

                <h5>{i18n.t('what-could-jennifer-have-done-differently')}</h5>
                <ReactMarkdown>{i18n.t('jennifer-did-not-realize-how-her-attention-to-details-and-desire-for-absolute-cleanliness-disturbs-her-whole-family-not-only-steve-who-is-the-least-conscientious-of-them-all-they-often-tell-her-how-they-dont-understand-where-shes-going-with-her-explanations-or-how-shes-nitpicking-whenever-they-do-their-chores')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('she-needs-to-encourage-them-when-they-do-a-good-enough-job-and-accept-that-they-may-never-reach-her-standards-she-could-spend-more-quality-family-time-with-them-if-she-didnt-spend-so-much-time-cleaning-she-sees-them-waiting-for-her-to-join-them-but-dismisses-their-pleas-because-there-is-just-this-one-last-chore-she-wants-to-finish-before-long-the-day-is-over')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('she-should-learn-to-summarize-and-prioritize-the-information-she-wants-to-give-them-to-avoid-losing-them-in-her-detailed-explanations-of-what-she-expects-from-them')}</ReactMarkdown>

                <h5>{i18n.t('what-about-steve')}</h5>
                <ReactMarkdown>{i18n.t('steve-should-emphasize-what-hes-done-when-she-scolds-him-for-not-doing-a-certain-task-a-certain-way-it-often-happens-that-he-feels-lost-when-she-asks-him-to-do-something-because-of-all-the-information-she-gives-him-beforehand-what-would-work-better-for-him-is-to-receive-a-high-level-understanding-of-the-different-chores-she-expects-him-to-do-this-to-do-list-would-help-him-keep-the-big-picture-in-mind-and-not-forget-what-she-had-in-mind')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('he-could-also-ask-her-for-advice-on-how-he-can-improve-the-next-time-he-needs-to-do-a-task-for-her-he-craves-her-praise-and-encouragements-and-suffers-from-only-receiving-negative-feedback-when-he-doesnt-perform-as-well-as-she-would-expect')}</ReactMarkdown>
            </section>
        </div>
    );
};

function mismatchE(i18n) {
    return (
        <div className="trait-mismatch">
            <br />
            <section className="story" id="mismatchE">
                <h3>{i18n.t('Example Of Extroversion Misunderstandings')}</h3>

                <h4>{i18n.t('at-work')}</h4>
                
                <div className="myimghor">
                    <img src={emismatch} alt="Extroversion mismatch" />
                </div>

                <ReactMarkdown>{i18n.t('Dana is extremely introverted')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('Her new manager')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('Unfortunately, Philip doesnt')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('Dana misses Ellen')}</ReactMarkdown>

                <h5>{i18n.t('what-could-philip-have-done-differently')}</h5>
                <ReactMarkdown>{i18n.t('he-should-have-discussed-alone-with-dana-to-understand-how-she-prefers-to-work-if-he-had-he-could-have-understood-that-she-needs-solitary-time-to-do-her-thinking-rather-than-coming-up-at-will-with-brilliant-ideas-he-could-then-have-emulated-what-ellen-did-well-which-was-to-allow-dana-to-come-back-to-her-when-she-was-ready-either-by-email-or-during-a-conversation')}</ReactMarkdown>

                <h5>{i18n.t('what-about-dana')}</h5>
                <ReactMarkdown>{i18n.t('she-should-have-taken-philip-aside-after-he-put-her-in-the-spotlight-during-meetings-to-let-him-know-how-uncomfortable-it-made-her-feel-she-doesnt-have-to-apologize-for-her-shyness-and-should-inform-him-how-she-does-her-best-thinking-when-given-enough-time-alone-this-way-philip-would-know-he-should-let-her-sit-in-silence-during-meetings-rather-than-asking-her-to-speak-up-and-be-assured-she-will-contribute-given-enough-time')}</ReactMarkdown>

                <h4>{i18n.t('with-family-and-friends')}</h4>
                
                <div className="myimghor">
                    <img src={emismatch} alt="Extroversion mismatch"  />
                </div>

                <ReactMarkdown>{i18n.t('penny-is-the-cheerful-energetic-extroverted-mother-of-david-a-sweet-and-introverted-9-year-old-she-feels-concerned-after-hearing-from-davids-teachers-that-he-spends-most-of-his-time-alone-even-during-breaktime-as-far-as-she-can-remember-she-always-loved-meeting-new-people-and-playing-loudly-with-other-kids')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('she-keeps-encouraging-him-to-meet-new-people-at-school-and-asks-every-dinner-to-talk-about-his-day-which-he-seldom-does-preferring-to-listen-to-what-the-rest-of-the-family-has-to-say-when-he-does-share-he-usually-talks-about-what-he-learned-that-day-and-does-not-know-how-to-answer-his-moms-questions-about-what-he-did-for-fun')}</ReactMarkdown>

                <h5>{i18n.t('what-could-penny-have-done-differently')}</h5>
                <ReactMarkdown>{i18n.t('if-she-had-asked-david-she-would-have-learned-that-he-preferred-to-spend-time-with-his-best-friends-mary-and-gregory-after-school-because-they-could-quietly-play-and-talk-without-all-the-chaos-from-the-playground-it-didnt-occur-to-her-that-his-quiet-moments-of-solitude-enabled-him-to-process-and-understand-what-he-had-learned-which-resulted-in-his-brilliant-grades-at-school-she-could-have-realized-that-david-was-gifted-at-building-strong-relationships-with-the-people-he-liked-even-teachers-but-that-he-shunned-the-loud-and-crowdy-activities-which-drained-him-of-his-energy')}</ReactMarkdown>

                <h5>{i18n.t('what-about-david')}</h5>
                <ReactMarkdown>{i18n.t('david-did-not-realize-how-important-his-social-life-is-to-his-mother-if-he-had-he-could-have-told-her-about-the-fantastic-adventure-he-and-his-best-friends-made-up-or-about-how-he-enjoyed-their-conversations-about-their-favorite-movies-and-books-he-also-did-not-realize-how-perplexed-his-mother-was-when-he-talked-about-school-rather-than-play-at-the-dinner-table-since-he-enjoys-spending-time-with-his-thoughts-tinkering-on-problems-he-greatly-enjoys-learning-the-praise-and-encouragements-from-his-parents-and-teachers-motivate-him-to-study-hard-which-he-does-not-mind-since-his-best-friends-accept-him-the-way-he-is')}</ReactMarkdown>
            </section>
        </div>
    );
};

function mismatchA(i18n) {
    return (
        <div className="trait-mismatch">
            <br />
            <section className="story" id="mismatchA">
                <h3>{i18n.t('Example Of Agreeableness Misunderstandings')}</h3>

                <h4>{i18n.t('at-work')}</h4>

                <div className="myimghor">
                    <img src={amismatch} alt="Agreeableness mismatch" />
                </div>

                <ReactMarkdown>{i18n.t('Highly-agreeable Takeda')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('His empathetic nature')}</ReactMarkdown>

                <h5>{i18n.t('what-could-takeda-have-done-differently')}</h5>
                <ReactMarkdown>{i18n.t('takeda-should-fight-his-fear-of-conflict-and-speak-up-when-he-is-unsure-about-what-anja-expects-of-him-although-she-is-comfortable-with-conflicts-and-having-difficult-conversations-it-does-not-mean-she-will-attack-him-she-may-challenge-his-opinions-but-she-will-not-actively-seek-to-hurt-him-or-belittle-him')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('he-should-leverage-her-willingness-to-solve-problems-and-take-care-of-tasks-which-will-help-him-when-he-is-stuck-and-unsure-how-to-proceed-while-she-may-not-care-much-about-the-interpersonal-aspect-of-their-working-relationship-she-still-cares-that-they-make-progress-and-that-their-work-gets-done')}</ReactMarkdown>

                <h5>{i18n.t('what-about-anja')}</h5>
                <ReactMarkdown>{i18n.t('she-needs-to-pay-attention-to-takedas-nonverbal-communication-meaning-his-face-and-body-language-when-he-appears-fidgety-or-unsure-she-needs-to-probe-what-is-wrong-and-how-she-can-help-reassuring-him-is-important-to-create-a-safe-environment-where-he-can-share-his-thoughts-concerns-and-ideas-knowing-that-his-self-confidence-will-not-be-attacked')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('she-needs-to-make-an-effort-at-building-rapport-and-avoid-jumping-into-problem-solving-mode-right-away-for-him-nurturing-their-relationship-and-therefore-building-trust-is-key-she-needs-to-show-him-that-she-is-a-human-being-not-only-an-intimidating-expert-in-her-field')}</ReactMarkdown>

                <h4>{i18n.t('with-family-and-friends')}</h4>

                <div className="myimghor">
                    <img src={amismatch} alt="Agreeableness mismatch" />
                </div>

                <ReactMarkdown>{i18n.t('tom-a-highly-agreeable-teenager-is-in-a-relationship-with-alexa-since-a-few-years-he-always-admired-her-drive-and-ability-to-overcome-any-challenges-which-come-her-way-however-although-she-seems-to-really-enjoy-spending-time-together-with-him-she-often-is-not-interested-in-talking-about-her-feelings-or-hearing-about-his-and-would-rather-act-than-speak-this-pains-him-as-he-seeks-harmony-in-their-couple-and-wants-to-make-sure-she-feels-loved-and-cared-for')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('over-time-alexa-starts-to-feel-smothered-by-his-constant-need-for-validation-and-avoidance-of-conflicts-while-she-views-them-as-a-good-way-to-make-their-relationship-stronger-he-seems-to-shy-away-from-them-and-shuts-down-when-she-starts-to-talk-about-how-they-can-fix-their-problems-as-individuals-and-as-a-couple-she-doesnt-understand-how-intimidating-such-conversations-are-for-tom-although-he-also-would-like-for-them-to-become-better-people-he-does-not-know-how-to-navigate-these-difficult-conversations-they-slowly-start-to-grow-apart-and-dont-know-how-to-save-their-relationship')}</ReactMarkdown>

                <h5>{i18n.t('what-could-tom-have-done-differently')}</h5>
                <ReactMarkdown>{i18n.t('whenever-alexa-goes-into-problem-solving-mode-tom-needs-to-pause-and-get-into-the-right-mindset-he-needs-to-understand-that-she-is-not-accusing-him-of-anything-she-is-only-suggesting-what-they-can-do-differently-to-avoid-a-problem-or-fix-it-the-way-she-speaks-with-passion-is-not-an-attack-on-him-or-a-sign-of-anger-she-just-loves-solving-problems-and-does-not-realize-how-her-words-and-actions-may-be-perceived-as-harsh-or-blunt')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('he-needs-to-also-understand-if-she-does-not-feel-like-sharing-how-she-feels-because-she-may-not-be-as-sensitive-to-her-feelings-as-he-is-her-desire-for-action-does-not-mean-she-is-not-interested-in-preserving-the-harmony-of-their-relationship-rather-she-seeks-ways-to-make-it-even-better')}</ReactMarkdown>

                <h5>{i18n.t('what-about-alexa')}</h5>
                <ReactMarkdown>{i18n.t('she-needs-to-be-mindful-of-when-tom-opens-up-to-her-in-such-occasions-she-needs-to-pause-her-drive-for-action-and-listen-to-him-once-he-seems-calmer-and-satisfied-because-he-felt-heard-and-like-he-belonged-then-she-can-start-discussing-how-they-can-address-the-situation-they-are-facing')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('their-definitions-of-quality-time-spent-together-may-differ-while-she-prefers-being-active-and-doing-things-he-may-prefer-talking-and-enjoying-quiet-time-enjoying-her-presence-both-definitions-are-perfectly-acceptable-there-is-no-right-or-wrong-only-different-opinions')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('she-should-keep-in-mind-that-his-collaborative-and-supportive-nature-using-words-and-gestures-are-different-than-her-drive-for-action-challenges-and-getting-results-because-he-does-not-jump-into-action-whenever-something-goes-wrong-and-would-rather-talk-about-it-and-ask-how-he-can-help-does-not-mean-he-does-not-care-about-their-relationship-and-about-how-to-make-it-even-better-on-the-contrary')}</ReactMarkdown>
            </section>
        </div>
    );
};

function mismatchN(i18n) {
    return (
        <div className="trait-mismatch">
            <br />
            <section className="story" id="mismatchN">
                <h3>{i18n.t('Examples Of Nervosity Misunderstandings')}</h3>

                <h4>{i18n.t('at-work')}</h4>

                <h4>{i18n.t('Project Management')}</h4>
                <div className="myimghor">
                    <img src={nmismatch1} alt="Nervosity mismatch example 1" />
                </div>

                <ReactMarkdown>{i18n.t('Mary is a chronicle worrier')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('After being two weeks behind schedule')}</ReactMarkdown>

                <h5>{i18n.t('what-could-mary-have-done-differently')}</h5>
                <ReactMarkdown>{i18n.t('mary-should-have-given-gwen-more-space-to-work-on-her-tasks-because-of-how-afraid-mary-was-that-gwen-would-delay-the-project-and-how-she-projected-her-worries-on-gwen-her-fear-became-a-self-fulfilling-prophecy-and-what-she-feared-most-became-true')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('she-should-also-have-checked-with-the-people-who-needed-gwens-inputs-how-they-could-help-her-prioritize-her-tasks-by-finding-the-relevant-few-insights-they-would-need-to-get-started-they-could-help-gwen-focus-on-what-truly-mattered-first-before-she-moved-on-to-the-trivial-details-before-being-able-to-fully-handover-her-work')}</ReactMarkdown>

                <h5>{i18n.t('what-about-gabrielle')}</h5>
                <ReactMarkdown>{i18n.t('although-gabrielle-is-usually-able-to-keep-her-calm-during-stressful-times-marys-obsessive-follow-up-got-the-best-of-her-instead-of-carrying-on-and-dreading-opening-her-inbox-fearing-to-see-how-many-requests-she-received-from-mary-she-should-have-been-more-assertive-and-told-her-how-counterproductive-her-behavior-was-and-how-it-started-to-affect-her-ability-to-concentrate-and-work')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('she-should-also-have-reached-out-to-others-and-asked-them-for-help-rather-than-trying-to-do-it-all-on-her-own-because-she-was-usually-able-to-absorb-a-large-quantity-of-work-there-is-no-shame-in-asking-for-help-during-busy-times')}</ReactMarkdown>

                <h4>{i18n.t('2-prevent-and-correct-mistakes')}</h4> 

                <div className="myimghor">
                    <img src={nmismatch2} alt="Nervosity mismatch example 2" />
                </div>

                <ReactMarkdown>{i18n.t('I (Chris)')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('He taught me that my tendency')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('In the world of quality')}</ReactMarkdown>

                <ul>
                    <li><ReactMarkdown>{i18n.t('How can we prevent this problem from happening')}</ReactMarkdown></li>
                    <li><ReactMarkdown>{i18n.t('How can we correct the damages')}</ReactMarkdown></li>
                </ul>

                <ReactMarkdown>{i18n.t('His calm nature')}</ReactMarkdown>

                <h4>{i18n.t('with-family-and-friends')}</h4>

                <div className="myimghor">
                    <img src={nmismatch1} alt="Nervosity mismatch example 1" />
                </div>

                <ReactMarkdown>{i18n.t('gabriel-a-calm-and-confident-young-man-is-organizing-his-first-holiday-abroad-with-his-best-friend-marco-who-is-sensitive-and-prone-to-worrying-every-time-gabriel-suggests-what-they-could-do-marco-starts-to-panic-and-blurt-out-how-things-could-go-wrong')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('as-their-departure-time-is-getting-closer-and-theyve-barely-made-any-progress-in-their-planning-gabriel-starts-pushing-marco-to-make-a-decision-the-more-impatient-gabriel-becomes-the-angrier-marco-gets-what-should-have-been-a-time-for-bonding-and-discovering-another-culture-becomes-a-boring-trip-stuck-around-their-hotels')}</ReactMarkdown>

                <h5>{i18n.t('what-could-gabriel-have-done-differently')}</h5>
                <ReactMarkdown>{i18n.t('gabriel-should-have-helped-marco-realize-that-his-imagined-scenarios-were-unlikely-going-to-happen-and-probed-him-to-find-solutions-to-solve-them-as-well-as-ways-to-avoid-them-marco-needed-to-vent-to-avoid-spinning-his-wheels-and-could-have-decided-on-what-he-really-wanted-to-do-once-his-mind-was-calmer')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('he-could-also-have-reassured-him-that-they-did-not-have-to-fully-commit-to-any-decision-right-away-since-they-still-had-time-to-revisit-their-ideas-before-having-to-book-them-he-knows-that-marco-tends-to-see-everything-as-being-urgent-and-important-however-no-one-activity-was-so-urgent-nor-important-to-become-completely-overwhelmed')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('overall-he-needs-to-help-marco-understand-how-the-planned-activity-is-not-the-source-of-his-anxiety-but-his-interpretation-of-the-situation-by-repetitively-thinking-about-the-situation-and-considering-the-pros-not-only-the-cons-he-could-help-marco-to-get-out-of-his-spiral-of-worries')}</ReactMarkdown>

                <h5>{i18n.t('what-about-marco')}</h5>
                <ReactMarkdown>{i18n.t('marco-should-have-told-gabriel-how-much-this-trip-meant-for-him-since-it-was-his-first-time-away-from-his-family-without-realizing-it-he-tended-to-idealize-the-trip-wanting-it-to-be-perfect-which-paralyzed-him-when-he-had-to-decide-what-to-do-each-time-they-opted-for-an-option-it-meant-they-couldnt-do-any-other-activity-especially-since-they-only-had-a-few-weeks-before-they-had-to-go-back-to-college')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('he-could-have-suggested-activities-he-knew-they-would-both-enjoy-doing-rather-than-seeking-entirely-new-ones-that-they-might-regret-afterwards-marco-knows-he-tends-to-easily-regret-his-decisions-and-is-afraid-he-will-let-gabriel-down-which-prevents-him-from-committing-to-any-idea-he-suggests')}</ReactMarkdown>
                <ReactMarkdown>{i18n.t('whenever-marco-saw-a-slight-look-of-disappointment-on-gabriels-face-he-disproportionately-started-to-think-that-meant-their-friendship-was-over-afraid-to-start-the-conversation-and-ask-how-gabriel-was-feeling-and-what-he-was-thinking-they-should-do-he-started-shutting-down-and-wouldnt-speak-about-it-for-a-couple-days')}</ReactMarkdown>
            </section>
        </div>
    );
};

function questionsO(i18n) {
    return (
        <div className="trait-questions">
            <section id="questionsO" className="offset-anchor">
            <h3>{i18n.t('Questions To Ask To Evaluate Openness To Experience')}</h3>
            <h4>{i18n.t('Have you ever tried')}<br />{i18n.t('Would you?')}</h4>
            <ReactMarkdown>{i18n.t('High-Openness individuals will')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Low-Openness individuals will')}</ReactMarkdown>

            <h4>{i18n.t('Whats your favorite')}<br />{i18n.t('What else do you like')}</h4>
            <ReactMarkdown>{i18n.t('broader range of experiences to share')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('just one preferences')}</ReactMarkdown>

            <h4>{i18n.t('Whats your plan for your next holiday')} <br />{i18n.t('Have you already been there?')}</h4>
            <ReactMarkdown>{i18n.t('new places')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('places they have already visited')}</ReactMarkdown>
            </section>
        </div>
    );
};

function questionsC(i18n) {
    return (
        <div className="trait-questions">
            <section id="questionsC">
            <h3>{i18n.t('Ideas For Communicating With Both Low- And High-Conscientious People')}</h3>

            <div className="notabene">
                <ReactMarkdown>{i18n.t('Start with your recommendation,')}</ReactMarkdown>
            </div>

            <div className="myimghor">
                <img src={communicationtriangle} alt="The communication triangle" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('Think of the message inverted triangle')}</ReactMarkdown>
            </div>

            <ReactMarkdown>{i18n.t('This provides people with the choice')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('The top represents your core message')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Again your core may be a recommendation')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('The rest of your message')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('people lower conscientiousness convinced with fewer info')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('in contrast, by explaining, convince higher conscientiousness')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('from personal experience, notices tend to bury core message')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('the risk lose interest and miss key facts')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('this makes communication challenging for high c people')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('remember if core message not clear')}</ReactMarkdown>


            <h3>{i18n.t('Questions To Ask To Evaluate Conscientiousness')}</h3>
            <h4>{i18n.t('What exciting projects are you working on?')}</h4>
            <h4>{i18n.t('What’s your plan?')}</h4>

            <ReactMarkdown>{i18n.t('long, comprehensive, organized, exhaustive list of tasks')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('more brief, big picture overviews')}</ReactMarkdown>

            <h4>{i18n.t('What are your goals for this year?')}</h4>
            <ReactMarkdown>{i18n.t('detailed list of what they want to achieve')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('more vague about their objectives')}</ReactMarkdown>
            </section>
        </div>
    );
};

function questionsE(i18n) {
    return (
        <div className="trait-questions">
            <section id="questionsE">
            <h3>{i18n.t('Questions To Ask To Evaluate Extroversion')}</h3>
            <h4>{i18n.t('Do you know anyone else here?')}</h4>
            <ReactMarkdown>{i18n.t('by dropping names or by offering                 to introduce you')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('introverts nervous by your question')}</ReactMarkdown>

            <h4>{i18n.t('What are your plans for the weekend?')}</h4>
            <ReactMarkdown>{i18n.t('extroverts excitements about their planned adventures')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('introverts likely relaxing few activities spread out')}</ReactMarkdown>
            </section>
        </div>
    );
}

function questionsA(i18n) {
    return (
        <div className="trait-questions">
            <section id="questionsA">
            <h3>{i18n.t('Questions To Ask To Evaluate Agreeableness')}</h3>
            <h4>{i18n.t('We are going out for')} </h4>
            <h4>{i18n.t('Want to join us?')}</h4>
            <ReactMarkdown>{i18n.t('high agreeable accept invitation')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('less agreeable decline invitation')}</ReactMarkdown>

            <h4>{i18n.t('Are you usually the peacemaker or the troublemaker?')}</h4>
            <ReactMarkdown>{i18n.t('high agreeable try resolve conflicts')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('less agreeable hesitate to be assertive')}</ReactMarkdown>
            </section>
        </div>
    );
}

function questionsN(i18n) {
    return (
        <div className="trait-questions">
            <section id="questionsN">
            <h3>{i18n.t('Questions To Ask To Evaluate Nervosity')}</h3>
            <h4>{i18n.t('What challenging projects have you worked on recently?')}</h4>
            <h4>{i18n.t('How did they go?')}</h4>
            <ReactMarkdown>{i18n.t('high nervosity stressed while recalling challenging work')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('low nervosity unfazed by challenged')}</ReactMarkdown>

            <h4>{i18n.t('What do you need help with?')}</h4>
            <ReactMarkdown>{i18n.t('high nervosity know where they struggle')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('low nervosity more self confident')}</ReactMarkdown>

            <h4>{i18n.t('Do you sometimes feel bad, sad or mad for no apparent reason?')}</h4>
            <ReactMarkdown>{i18n.t('high nervosity extreme emotional rollercoaster')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Their emotions rage inside')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('low nervosity tend to be more tempered')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('move through life not affected by experience')}</ReactMarkdown>

            <h4>{i18n.t('Do you worry about things that might happen?')}</h4>
            <ReactMarkdown>{i18n.t('worrying is hallmark of high nervosity')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('you should be able read fear')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('low nervosity reflect inner mental and emotional states')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('facial expression relaxed')}</ReactMarkdown>

            <h4>{i18n.t('Do you worry too long after an embarrassing experience?')}</h4>
            <ReactMarkdown>{i18n.t('shame is one of core emotions and high nervosity')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('even if they dont express them openly')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('out brains tend to remember shameful experiences strongly')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('low nervosity learn from mistakes not linger')}</ReactMarkdown>
            </section>
        </div>
    );
};

function workwithhighO(i18n) {
    return (
        <div className="trait-workwith">
            <section id="workwithhighO" className="offset-anchor">
            <h3>{i18n.t('how-to-work-well-with-high-openness-people')}</h3>

            <h4>{i18n.t('at-work')}</h4>
            <ReactMarkdown>{i18n.t('ask-what-new-skill-sets-or-experiences-they-want-to-acquire-and-assign-them-on-relevant-projects-that-match-their-interests-and-development-plans-encourage-their-desire-to-learn-new-things-and-give-them-enough-time-and-resources-to-work-on-expanding-their-skill-sets')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('emphasize-the-benefits-or-opportunities-when-pitching-them-to-try-something-new-then-let-them-share-their-thoughts-ideas-or-concerns-to-build-on-your-proposal-let-them-internalize-them-and-make-them-their-own')}</ReactMarkdown>

            <h4>{i18n.t('with-family-and-friends')}</h4>
            <ReactMarkdown>{i18n.t('ask-what-new-experiences-they-want-to-explore-and-reflect-on-which-ones-you-would-also-enjoy-doing-with-them-encourage-their-desire-to-learn-new-things-and-give-them-enough-time-and-resources-to-become-better-at-them')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('ask-which-new-destinations-they-want-to-discover-and-find-which-ones-match-with-your-personal-interests-where-could-you-travel-and-still-be-able-to-engage-in-activities-you-enjoy-doing')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('ask-them-to-share-about-their-passions-and-emotions-as-they-are-likely-more-attuned-to-them-than-other-people')}</ReactMarkdown>

            <div className="myimghor mt-5">
                <img src={conversation8} alt="Working with high-openness individuals" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@cottonbro">cottonbro</a> {i18n.t('from')} <a
                    href="https://www.pexels.com/photo/man-and-woman-leaning-on-table-staring-at-white-board-on-top-of-table-having-a-meeting-3205570/">Pexels</a>
                </p>
            </div>
            </section>
        </div>
    );
};

function workwithlowO(i18n) {
    return (
        <div className="trait-workwith">
            <section id="workwithlowO">
            
            <h3>{i18n.t('How To Work Well With Low-Openness People')}</h3>

            <h4>{i18n.t('at-work')}</h4>
            <ReactMarkdown>{i18n.t('look at and ask about previous favorite experience')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('encourage them to deepen their expertise')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Change management can be especially challenging for low o people')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('emphasize what is not changing')}</ReactMarkdown>

            <h4>{i18n.t('with-family-and-friends')}</h4>
            <ReactMarkdown>{i18n.t('ask-about-their-favorite-experiences-and-try-to-understand-why-they-mean-so-much-to-them-then-reflect-on-which-activities-you-would-also-enjoy-doing-and-let-them-lead-you-through-them')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('ask-them-to-share-about-what-interests-them-the-most-and-search-for-gifts-which-would-enable-them-to-deepen-their-knowledge-and-skills')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('when-asking-them-to-try-something-new-empasize-what-is-close-to-what-they-already-know-and-love-and-discuss-with-them-how-they-could-enjoy-trying-this-variation-perhaps-it-will-become-their-new-favorite')}</ReactMarkdown>

            <div className="myimghor mt-5">
                <img src={conversation3} alt="Working with low-openness individuals" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@mentatdgt-330508">mentatdgt</a> {i18n.t('from (photo credits)')} <a
                    href="https://www.pexels.com/photo/man-sitting-on-chair-in-front-of-woman-2029619/">Pexels</a></p>
            </div>
            </section>
        </div>
    );
};

function workwithhighC(i18n) {
    return (
        <div className="trait-workwith">
            <section id="workwithhighC">

            <h3>{i18n.t('How To Work Well With High-Conscientiousness People')}</h3>

            <h4>{i18n.t('at-work')}</h4>
            <ReactMarkdown>{i18n.t('Spend enough time at first to give as much information')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Be prepared for them to ask many questions')}</ReactMarkdown>

            <h4>{i18n.t('with-family-and-friends')}</h4>
            <ReactMarkdown>{i18n.t('if-you-see-they-seem-uncomfortable-or-anxious-because-they-dont-have-a-clear-enough-understanding-of-what-to-do-fight-the-urge-to-move-on-and-let-them-figure-it-out-on-their-own-they-need-to-have-enough-information-to-feel-comfortable-to-get-started')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('when-they-start-overloading-you-with-too-many-details-try-to-summarize-what-they-are-saying-to-see-if-you-are-still-on-the-same-page-seek-the-most-important-information-that-you-need-in-order-to-help-them-with-their-requests-and-problems')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('be-prepared-for-them-to-ask-many-questions-get-ready-to-review-them-together-and-answer-all-of-them-skipping-information-may-make-their-wheels-spin-and-prevent-them-from-doing-their-part')}</ReactMarkdown>

            <div className="myimghor">
                <img src={conversation7} alt="Working with high-conscientiousness individuals" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@olly">Andrea Piacquadio</a> {i18n.t('from (photo credits)')} <a
                    href="https://www.pexels.com/photo/photo-of-woman-talking-with-another-woman-3768129/">Pexels</a>
                </p>
            </div>
            </section>
        </div>
    );
};

function workwithlowC(i18n) {
    return (
        <div className="trait-workwith">
            
            <h3>{i18n.t('How To Work Well With Low-Conscientiousness People')}</h3>

            <h4>{i18n.t('at-work')}</h4>
            <ReactMarkdown>{i18n.t('Clarify the big goals of each projects')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Give them the freedom to come up with their own way')}</ReactMarkdown>

            <h4>{i18n.t('with-family-and-friends')}</h4>
            <ReactMarkdown>{i18n.t('give-them-a-high-level-summary-of-what-you-have-in-mind-and-avoid-sharing-too-much-information-which-will-likely-overwhelm-them-stick-to-the-most-critical-facts-and-let-them-ask-clarifying-questions-if-they-arent-sure-they-fully-understand-what-you-are-saying')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('when-asking-them-to-do-something-ask-them-to-share-how-they-plan-to-proceed-to-make-sure-they-will-not-miss-important-steps-along-the-way')}</ReactMarkdown>

            <div className="myimghor">
                <img src={conversation4} alt="Working with low-conscientiousness individuals" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@wildlittlethingsphoto">Helena Lopes</a> {i18n.t('from (photo credits)')} <a
                    href="https://www.pexels.com/photo/photograph-of-men-having-conversation-seating-on-chair-1015568/">Pexels</a>
                </p>
            </div>
        </div>
    );
};

function workwithhighE(i18n) {
    return (
        <div className="trait-workwith">
            <section id="workwithhighE">
        
            <h3>{i18n.t('How To Work Well With High-Extroversion People')}</h3>
            <h4>{i18n.t('at-work')}</h4>
            <ReactMarkdown>{i18n.t('understand that extroverts thrive in social interactions')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Their sociable nature also means that they dislike being alone')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Assign them to projects or roles that leverage their energy and social skills')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Assign them to mentoring roles, to reduce the time they spend alone')}</ReactMarkdown>

            <h4>{i18n.t('with-family-and-friends')}</h4>
            <ReactMarkdown>{i18n.t('remember-that-sharing-and-getting-to-know-people-is-crucial-for-extroverts-expect-them-to-share-a-lot-of-information-about-themselves-and-be-prepared-to-do-the-same-as-this-will-build-rapport-between-you-they-will-also-likely-appreciate-if-you-are-the-one-initiating-the-conversation')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('suggest-social-and-stimulating-activities-you-could-do-together-since-they-get-energized-from-their-environments-especially-when-they-are-crowded-or-exciting-concerts-festivals-amusement-parks-and-bars-are-great-places-to-start')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('when-talking-with-extroverts-show-your-interest-and-enthusiasm-by-leaning-in-nodding-your-head-maintaining-eye-contact-and-providing-verbal-acknowledgment-to-what-they-are-saying-keep-in-mind-they-may-think-out-loud-which-helps-them-to-process-the-information-before-coming-to-a-conclusion')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('when-you-have-made-plans-or-promised-to-do-something-for-extroverts-make-sure-you-follow-up-and-keep-them-updated-on-your-progress-even-if-you-are-not-done-yet-they-will-appreciate-your-initiative-and-will-get-peace-of-mind-knowing-you-are-doing-your-part')}</ReactMarkdown>

            <div className="myimghor">
                <img src={conversation6} alt="Working with extroverts" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@rethaferguson">RF._.studio</a> {i18n.t('from (photo credits)')} <a
                    href="https://www.pexels.com/photo/group-of-women-having-a-meeting-3810754/">Pexels</a></p>
            </div>
            </section>
        </div>
    );
};

function workwithlowE(i18n) {
    return (
        <div className="trait-workwith">

            <h3>{i18n.t('How To Work Well With Low-Extroversion People')}</h3>

            <h4>{i18n.t('at-work')}</h4>
            <ReactMarkdown>{i18n.t('Being shy is not necessarily equal to being an                 introvert')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Introverts are usually more difficult to approach in larger groups')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('introverts can know as many people as extroverts do')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Give them enough time alone to do their thinking and their work')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Accept that they may need personal space outside of their desk to work alone')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('praise their personal achievements and team-building efforts')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Leverage written communication to let them come back to you if they feel uncomfortable')}</ReactMarkdown>

            <h4>{i18n.t('with-family-and-friends')}</h4>
            <ReactMarkdown>{i18n.t('for-introverts-real-friendship-has-to-be-earned-although-they-are-friendly-and-speak-kindly-this-does-not-mean-they-are-or-seek-to-be-your-friend-yet-social-situations-can-be-difficult-even-exhausting-for-them-their-careful-nature-makes-them-think-through-everything-they-say-to-avoid-hurting-those-they-interact-with')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('dont-assume-you-know-what-they-are-thinking-or-feeling-you-are-more-likely-to-be-wrong-than-lucky-rather-ask-them-to-share-their-thoughts-and-feelings-but-be-prepared-for-them-to-refuse-sharing-something-so-personal-they-dislike-being-the-center-of-attention-and-sharing-intimate-information-may-not-come-naturally-to-them')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('dont-mistake-their-silence-for-weakness-introverts-prefer-reflection-over-visible-action-their-silence-is-no-sign-for-a-fragile-personnality-which-needs-protecting-in-the-theater-of-their-minds-so-much-may-happen-that-words-can-hardly-begin-to-explain-the-deep-reflections-they-are-going-through-beneath-the-surface')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('respect-their-boundaries-dont-force-them-to-share-if-they-are-unwilling-to-once-they-trust-you-and-know-you-will-welcome-and-admire-their-inner-world-and-feel-ready-to-share-they-will-give-them-time-and-respect-their-silence-they-will-appreciate-your-asking-how-they-feel-without-feeling-the-need-to-elaborate-much-on-it')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('be-aware-that-they-will-replay-most-of-your-interactions-with-them-reflecting-on-how-you-made-them-feel-and-what-you-made-them-think-be-gentle-and-mindful-when-you-talk-to-them-let-them-share-when-they-feel-like-it-and-give-them-enough-space-to-silently-process-the-situation-without-forcing-them-to-speak-up')}</ReactMarkdown>

            <div className="myimghor">
                <img src={conversation2} alt="Working with introverts" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@mentatdgt-330508">mentatdgt</a> {i18n.t('from (photo credits)')} <a
                    href="https://www.pexels.com/photo/two-woman-chatting-1311518/">Pexels</a></p>
            </div>
        </div>
    );
};

function workwithhighA(i18n) {
    return (
        <div className="trait-workwith">
            <section id="workwithhighA">

            <h3>{i18n.t('How To Work Well With High-Agreeableness People')}</h3>
            
            <h4>{i18n.t('at-work')}</h4>
            <ReactMarkdown>{i18n.t('Keep in mind their desire to please you')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Ask them to share the concerns they have and how they are feeling')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('During conversations, take time to pause and gather thoughts and concerns')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Ask for written thoughts and feedback between your one-on-one conversations')}</ReactMarkdown>

            <h4>{i18n.t('with-family-and-friends')}</h4>
            <ReactMarkdown>{i18n.t('keep-in-mind-their-desire-to-please-you-make-sure-that-when-they-agree-to-doing-something-they-have-clearly-understood-your-expectations-and-know-how-to-get-started-this-will-prevent-them-getting-stuck-without-daring-asking-you-for-help-out-of-fear-of-conflict')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('ask-them-to-share-the-concerns-they-have-and-how-they-are-feeling-emphasizing-how-this-matters-to-you-because-they-need-to-feel-heard-and-like-they-belong-to-build-strong-relationships')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('dont-hesitate-to-reach-out-to-them-when-you-need-to-talk-about-your-worries-because-they-tend-to-get-tremendous-pleasure-from-helping-others-and-taking-care-of-them-they-naturally-tend-to-put-other-peoples-needs-above-their-own')}</ReactMarkdown>

            <div className="myimghor">
                <img src={conversation5} alt="Working with high-agreeableness individuals" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@rethaferguson">RF._.studio</a> {i18n.t('from (photo credits)')} <a
                    href="https://www.pexels.com/photo/group-of-women-having-a-meeting-3810754/">Pexels</a></p>
            </div>
            </section>
        </div>
    );
};

function workwithlowA(i18n) {
    return (
        <div className="trait-workwith">

            <h3>{i18n.t('How To Work Well With Low-Agreeableness People')}</h3>

            <h4>{i18n.t('at-work')}</h4>
            <ReactMarkdown>{i18n.t('Keep in mind they will likely tend to say No')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('When they refuse, give them time')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Accept that they may be skeptical')}</ReactMarkdown>

            <h4>{i18n.t('with-family-and-friends')}</h4>
            <ReactMarkdown>{i18n.t('keep-in-mind-they-will-likely-tend-to-say-no-at-first-and-dont-take-it-personally-its-a-defense-mechanism-to-protect-their-time-energy-and-interests')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('when-they-refuse-give-them-time-to-weight-the-pros-and-cons-and-get-back-to-you-at-a-later-stage-when-they-realize-that-their-helping-you-will-make-everyone-better-off-they-are-likely-going-to-join-you')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('ask-for-their-help-to-solve-problems-as-this-tends-to-be-what-they-prefer-doing-rather-than-dealing-with-people')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('ask-for-their-opinion-about-a-situation-because-they-will-likely-openly-speak-their-mind-since-they-are-not-afraid-of-having-difficult-conversations-or-creating-conflicts')}</ReactMarkdown>

            <div className="myimghor">
                <img src={conversation9} alt="Working with low-agreeableness individuals" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@jopwell">Jopwell</a> {i18n.t('from (photo credits)')} <a
                    href="https://www.pexels.com/photo/woman-wearing-teal-dress-sitting-on-chair-talking-to-man-2422280/">Pexels</a>
                </p>
            </div>
        </div>
    );
};

function workwithhighN(i18n) {
    return (
        <div className="trait-workwith">
            <section id="workwithhighN">
            
            <h3>{i18n.t('How To Work Well With High-Nervosity People')}</h3>
            
            <h4>{i18n.t('at-work')}</h4>
            <ReactMarkdown>{i18n.t('Give them enough safe space to express themselves')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Give them enough time to share their ideas')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Leverage their ability to think of multiple scenarios')}</ReactMarkdown>

            <h4>{i18n.t('with-family-and-friends')}</h4>
            <ReactMarkdown>{i18n.t('give-them-enough-safe-space-to-express-themselves-both-orally-and-in-written-review-together-the-alternatives-pros-and-cons-and-backup-plans-let-them-be-thorough-enough-so-that-they-dont-spin-their-wheels-and-overthink-things-while-they-are-working')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('give-them-enough-time-to-share-their-ideas-thoughts-and-concerns-resist-the-urge-to-put-them-against-a-wall-or-to-let-them-ruminate-over-their-concerns-after-conversations-reassure-them-by-letting-them-know-that-you-are-trying-to-cover-multiple-potentialities-so-they-dont-have-to-worry-about-them-themselves-this-will-make-them-trust-you-and-reduce-their-wariness')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('leverage-their-ability-to-think-of-multiple-scenarios-to-see-which-opportunities-and-risks-you-may-be-confronted-with-how-can-you-prevent-a-worst-case-scenario-from-happening-how-can-you-repair-the-resulting-damages')}</ReactMarkdown>

            <div className="myimghor">
                <img src={worry3img} alt="Working with high-nervosity individuals" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@andrew">Andrew Neel</a> {i18n.t('from (photo credits)')} <a
                    href="https://www.pexels.com/photo/photo-of-man-leaning-on-wooden-table-3132388/">Pexels</a></p>
            </div>
            </section>
        </div>
    );
};

function workwithlowN(i18n) {
    return (
        <div className="trait-workwith">
         
            <h3>{i18n.t('How To Work Well With Low-Nervosity People')}</h3>

            <h4>{i18n.t('at-work')}, {i18n.t('with-family-and-friends')}</h4>
            <ReactMarkdown>{i18n.t('If you tend to worry a lot let them know')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('ask for support during crises')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Leverage one-on-one conversations to raise your concerns')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Take the time to prepare for your conversations')}</ReactMarkdown>

            <div className="myimghor">
                <img src={highfive} alt="Working with low-nervosity individuals" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@fauxels">fauxels</a> {i18n.t('from (photo credits)')} <a
                    href="https://www.pexels.com/photo/photo-of-people-holding-each-other-s-hands-3184433/">Pexels</a>
                </p>
            </div>
        </div>
    );
};

function highOIdeas(i18n) {
    return <div>
        <h4 id="Oideas">{i18n.t('o1-or-high-openness-to-ideas')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-ideas-describes-your-intellectual-curiosity')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-enjoy-thinking-about-abstract-concepts-and-things-and-can-handle-a-lot-of-information-at-once-which-helps-you-to-think-outside-the-box-and-be-more-inventive-original-and-innovative-than-other-people-you-love-to-read-challenging-material-and-have-a-rich-vocabulary')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('ou-are-more-interested-in-intellectual-pursuits-than-anything-else-and-seek-out-to-understand-the-patterns-of-the-universe-around-you-you-are-an-experimental-person-who-likes-to-test-their-ideas')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('an-invasion-of-armies-can-be-resisted-but-not-an-idea-whose-time-has-come')}</ReactMarkdown>
            <ReactMarkdown>**- Victor Hugo**</ReactMarkdown>
        </div>
    </div>;
}

function highOFantasy(i18n) {
    return <div>
        <h4 id="Ofantasy">{i18n.t('o2-or-high-openness-to-fantasy')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-fantasy-describes-your-receptivity-to-the-inner-world-of-imagination-imagination-is-extremely-useful-to-step-outside-of-yourself-and-reflect-on-how-other-people-may-experience-and-perceive-you')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-have-a-vividly-imaginative-nature-and-enjoy-getting-lost-in-thought-time-tends-to-pass-quickly-when-you-reflect-on-things-and-start-daydreaming')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('fantasy-is-escapist-and-that-is-its-glory')}</ReactMarkdown>
            <ReactMarkdown>**- J.R.R. Tolkien**</ReactMarkdown>
        </div>
    </div>
}


function highOAesthetics(i18n) {
    return <>
        <h4 id="Oaesthetics">{i18n.t('o3-or-high-openness-to-aesthetics')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-aesthetics-describes-your-appreciation-of-art-and-beauty')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-believe-in-the-importance-of-art-and-are-sensitive-to-the-beauty-of-the-world-that-surrounds-you-your-artistic-nature-makes-you-enjoy-all-expressions-of-art-and-helps-you-to-curate-your-sophisticated-appearance-and-environment-you-sometimes-crave-to-express-yourself-creatively')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-enjoy-the-beauty-of-nature-and-see-beauty-in-things-that-others-might-not-notice-such-as-birds-or-flowers-beautifully-designed-machines-and-constructions-may-move-you')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('the-world-always-seems-brighter-when-youve-just-made-something-that-wasnt-there-before')}</ReactMarkdown>
            <ReactMarkdown>**- Neil Gaiman**</ReactMarkdown>
        </div>
    </>;
}

function highOEmotionality(i18n) {
    return <>
        <h4 id="Oemotionality">{i18n.t('o4-or-high-openness-to-emotionality')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-emotionality-describes-your-ability-and-willingness-to-explore-your-inner-emotions-and-feelings')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-tend-to-experience-your-emotions-intensely-and-are-able-to-feel-and-understand-the-others-emotions-well-you-try-to-understand-yourself-and-enjoy-examining-yourself-and-your-life-through-deep-introspection')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-are-passionate-about-the-causes-you-want-to-advance-and-speak-of-them-with-a-contagious-enthusiasm-that-inspires-others')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('nothing-great-in-the-world-has-ever-been-accomplished-without-passion')}</ReactMarkdown>
            <ReactMarkdown>**- George Hegel**</ReactMarkdown>
        </div>
    </>;
}

function highOAdventure(i18n) {
    return <>
        <h4 id="Oadventure">{i18n.t('o5-or-high-openness-to-adventure')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-adventure-and-action-describes-your-openness-to-new-experiences-on-a-practical-level')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-prefer-variety-to-routine-and-have-a-wide-range-of-interests-you-are-focused-on-novelty-and-tackling-new-challenges-to-satisfy-your-adventurous-nature-you-are-usually-willing-and-eager-to-try-new-things-and-visit-new-places')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('jobs-fill-your-pockets-but-adventures-fill-your-soul')}</ReactMarkdown>
            <ReactMarkdown>**- Jaime Lyn**</ReactMarkdown>
        </div>
    </>;
}

function highOValues(i18n) {
    return <>
        <h4 id="Ovalues">{i18n.t('o6-or-high-openness-to-values')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-values-describes-your-readiness-to-challenge-your-own-values-and-those-of-authority-figures')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-tend-to-be-an-idealist-and-believe-that-there-is-no-absolute-right-or-wrong-you-are-more-tolerant-of-other-peoples-beliefs-behaviors-and-social-diversity-you-appreciate-discussing-diverse-views-you-may-be-more-interested-in-discussions-of-more-liberal-ideas-than-conservative-ones-and-following-a-more-unconventional-path')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('anger-and-intolerance-are-the-enemies-of-correct-understanding')}</ReactMarkdown>
            <ReactMarkdown>**- Mahatma Gandhi**</ReactMarkdown>
        </div>
    </>;
}

function highO(i18n, oSubStyle: OSubstyle) {
    return (
        <div className="trait-high">
            <section id="Odescription" className="offset-anchor">
            <h3>{i18n.t('Your High-Openness Levels')}</h3>

            <div className="myimghor">
                <img src={adventureimg} alt="High openness adventure" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('What will be your new adventure?')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@alex-tim-249063">Alex Tim</a> {i18n.t('from')} <a
                    href="https://www.pexels.com/photo/woman-hiking-1183986/">Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('You tend to be excited about discovering new activities')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('A mind is like a parachute')}</ReactMarkdown>
                <ReactMarkdown>**- Frank Zappa**</ReactMarkdown>
            </div>

            <section id="Ofacets" className="offset-anchor list-short-height">
            <h3>{i18n.t('Your 6 Openness to Experience Facets')}</h3>

            <p className="myreportP">{i18n.t('Openness to experience can be broken down into six facets')}</p>

            <ol>
                <li><b>{i18n.t('openness-to-ideas')}</b></li>
                <li><b>{i18n.t('openness-to-fantasy')}</b></li>
                <li><b>{i18n.t('openness-to-aesthetics')}</b></li>
                <li><b>{i18n.t('openness-to-emotionality')}</b></li>
                <li><b>{i18n.t('openness-to-adventure')}</b></li>
                <li><b>{i18n.t('openness-to-values')}</b></li>
            </ol>
            </section>

            <section className="accordion-width mt-5">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('Discover Your 6 Openness to Experience Facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Ideas, lowOIdeas(i18n), highOIdeas(i18n)) : highOIdeas(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Fantasy, lowOFantasy(i18n), highOFantasy(i18n)) : highOFantasy(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Aesthetics, lowOAesthetics(i18n), highOAesthetics(i18n)) : highOAesthetics(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Emotionality, lowOEmotionality(i18n), highOEmotionality(i18n)) : highOEmotionality(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Adventure, lowOAdventure(i18n), highOAdventure(i18n)) : highOAdventure(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Values, lowOValues(i18n), highOValues(i18n)) : highOValues(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>


            <section id="Operception" className="offset-anchor">
            <h3>{i18n.t('How You May Be Perceived')}</h3>
            <ReactMarkdown>{i18n.t('Because of your intense curiosity and willingness to experiment and learn as much as possible')}</ReactMarkdown>

            <h4>{i18n.t('Self-Development Advice')}</h4>
            <ReactMarkdown>{i18n.t('Take the time to explore your passions and interests')}</ReactMarkdown>
            </section>

            </section>
        </div>
    );
}

//TODO: translations
function highCAchievement(i18n) {
    return <>
        <h4 id="Cachievement">{i18n.t('C1 | High Achievement-Striving')}</h4>
        <ReactMarkdown>{i18n.t('Achievement striving describes')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-are-naturally-skilled-at-getting-things-done-you-are-hard-working-and-turn-plans-into-actions-plunging-into-tasks-with-all-your-heart-and-sticking-to-them-until-completion-you-go-straight-for-your-goals-never-giving-up')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-are-ambitious-and-set-high-standards-for-yourself-and-others-and-tend-to-do-more-than-whats-expected-of-you-this-helps-you-to-become-a-high-achiever-who-stays-goal-oriented-no-matter-what-your-ambition-and-diligent-work-may-make-others-perceive-you-as-a-workaholic')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('i-find-that-the-harder-i-work-the-more-luck-i-seem-to-have')}</ReactMarkdown>
            <ReactMarkdown>**- Thomas Jefferson**</ReactMarkdown>
        </div>
    </>;
}

function highCSelfEfficacy(i18n) {
    return <>
        <h4 id="Cselfefficacy">{i18n.t('c2-or-high-self-efficacy')}</h4>
        <ReactMarkdown>{i18n.t('self-efficacy-describes-your-beliefs-about-how-well-you-can-execute-courses-of-action-required-to-deal-with-prospective-situations')}</ReactMarkdown><a
                    href="https://www.semanticscholar.org/paper/Self-efficacy-mechanism-in-human-agency.-Bandura/8beec556fe7a650120544a99e9e063eb8fcd987b"
                    className="intlink"> Source</a>

        <ReactMarkdown>{i18n.t('you-excel-in-what-you-do-and-know-how-to-get-things-done-your-diligence-allows-you-to-successfully-and-smoothly-complete-your-tasks-you-are-confident-that-you-will-be-able-to-overcome-difficult-situations-and-come-up-with-good-solutions-to-any-problems-you-face')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('it-always-seems-impossible-until-its-done')}</ReactMarkdown>
            <ReactMarkdown>**- Nelson Mandela**</ReactMarkdown>
        </div>
    </>;
}

function highCSelfDiscipline(i18n) {
    return <>
        <h4 id="Cselfdiscipline">{i18n.t('c3-or-high-self-discipline')}</h4>
        <ReactMarkdown>{i18n.t('self-discipline-describes-your-capacity-to-begin-tasks-and-follow-through-to-completion-despite-boredom-or-distractions')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-start-tasks-right-away-and-finish-them-promptly-when-they-are-important-getting-to-work-at-once-is-a-priority-for-you-as-is-punctuality-you-see-yourself-as-a-planner-who-spends-time-to-prepare-which-helps-you-to-carry-out-your-plans')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-are-more-controlled-than-random-and-are-able-to-regulate-immediate-gratification-with-your-self-discipline-your-perseverance-and-grit-allow-you-to-obtain-extraordinary-results')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('we-all-have-dreams-but-in-order-to-make-dreams-come-into-reality-it-takes-an-awful-lot-of-determination-dedication-self-discipline-and-effort')}</ReactMarkdown>
            <ReactMarkdown>**- Jesse Owens**</ReactMarkdown>
        </div>
    </>;
}

function highCCautiosness(i18n) {
    return <>
        <h4 id="Cautiousness">{i18n.t('c4-or-high-cautiousness')}</h4>
        <ReactMarkdown>{i18n.t('cautiousness-describes-your-tendency-to-think-things-through-before-acting-or-speaking')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('getting-things-right-is-a-priority-to-you-you-actively-seek-to-avoid-mistakes-because-of-your-desire-for-quality-and-accuracy-sometimes-you-may-need-to-check-your-work-two-or-three-times-before-being-satisfied-you-can-never-be-too-careful-and-controlled-work-matters-to-you')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('predictable-outcomes-are-important-to-you-which-will-drive-you-to-make-sure-that-everyone-is-on-the-same-page-before-proceeding-because-of-this-you-may-ask-a-lot-of-in-depth-skeptical-questions-to-clarify-what-others-are-thinking-or-point-out-flaws-that-others-may-have-missed-paying-attention-to-details-matters-to-you')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-like-to-take-time-to-carefully-think-things-through-before-you-speak-to-make-sure-people-will-clearly-understand-what-you-think-you-also-tend-to-prefer-analyzing-all-the-options-available-taking-your-time-rather-than-taking-quick-or-risky-decisions-stay-aware-that-your-careful-nature-and-slower-pace-may-be-perceived-by-others-as-perfectionism')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('be-not-afraid-of-going-slowly-be-afraid-only-of-standing-still')}</ReactMarkdown>
            <ReactMarkdown>**- Chinese proverb**</ReactMarkdown>
        </div>
    </>;
}

function highCDutifulness(i18n) {
    return <>
        <h4 id="Cdutifulness">{i18n.t('c5-or-high-dutifulness')}</h4>
        <ReactMarkdown>{i18n.t('dutifulness-describes-how-important-fulfilling-your-moral-obligations-is-to-you')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('fulfilling-your-duties-and-keeping-your-promises-is-a-priority-to-you-you-listen-to-your-conscience-and-try-your-best-do-to-the-right-thing-and-do-it-the-right-way-telling-the-truth-following-the-rules-and-paying-your-bills-on-time-is-natural-and-important-to-you')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('good-people-do-not-need-laws-to-tell-them-to-act-responsibly-while-bad-people-will-find-a-way-around-the-laws')}</ReactMarkdown>
            <ReactMarkdown>**- Plato**</ReactMarkdown>
        </div>
    </>;
}

function highCOrderlisness(i18n) {
    return <>
        <h4 id="Corderliness">{i18n.t('c6-or-high-orderliness')}</h4>
        <ReactMarkdown>{i18n.t('orderliness-describes-your-ability-to-be-well-organized-and-diligent')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-prefer-structured-environments-order-regularity-and-doing-things-methodically-systematically-and-efficiently-following-a-set-schedule-being-organized-doing-things-according-to-a-plan-and-doing-everything-just-right-comes-naturally-to-you')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('thanks-to-these-skills-people-may-perceive-you-as-being-more-responsible-dependable-reliable-and-efficient-than-others-to-obtain-predictable-and-optimal-outcomes')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('fools-wait-for-a-lucky-day-but-everyday-is-a-lucky-day-for-an-industrious-man')}</ReactMarkdown>
            <ReactMarkdown>**- Gautama Buddha**</ReactMarkdown>
        </div>
    </>;
}

function highC(i18n, cSubStyle: CSubstyle) {
    return (
        <div className="trait-high">
            <section id="Cdescription" className="offset-anchor">
            <h3>{i18n.t('Your High-Conscientiousness Levels')}</h3>

            <div className="myimghor">
                <img src={cleandeskimg} alt="High conscientiousness clean desk" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('Being organized makes life easier, right?')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@ken-tomita-127057">Ken
                    Tomita</a> {i18n.t('from')} <a href="https://www.pexels.com/photo/architecture-business-clean-computer-389818/"
                    >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('You tend to pay great attention to details')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('For every minute spent organizing, an hour is earned')}</ReactMarkdown>
                <p><b>- Benjamin Franklin</b></p>
            </div>


            <section id="Cfacets" className="offset-anchor list-short-height">
            <h3>{i18n.t('Your 6 Conscientiousness Facets')}</h3>

            <ReactMarkdown>{i18n.t('Conscientiousness can be broken down into six facets')}</ReactMarkdown>

            <ol>
                <li><ReactMarkdown>{i18n.t('achievement-striving')}</ReactMarkdown></li>
                <li><ReactMarkdown>{i18n.t('self-efficacy')}</ReactMarkdown></li>
                <li><ReactMarkdown>{i18n.t('self-discipline')}</ReactMarkdown></li>
                <li><ReactMarkdown>{i18n.t('cautiousness')}</ReactMarkdown></li>
                <li><ReactMarkdown>{i18n.t('dutifulness')}</ReactMarkdown></li>
                <li><ReactMarkdown>{i18n.t('orderliness')}</ReactMarkdown></li>
            </ol>
            </section>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('Discover Your 6 Conscientiousness Facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.AchievementStriving, lowCAchievement(i18n), highCAchievement(i18n)) : highCAchievement(i18n)}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.SelfEfficacy, lowCSelfEfficacy(i18n), highCSelfEfficacy(i18n)) : highCSelfEfficacy(i18n)}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.SelfDiscipline, lowCSelfDiscipline(i18n), highCSelfDiscipline(i18n)) : highCSelfDiscipline(i18n)}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.Cautiousness, lowCCautiousness(i18n), highCCautiosness(i18n)) : highCCautiosness(i18n)}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.Dutifulness, lowCDutifulness(i18n), highCDutifulness(i18n)) : highCDutifulness(i18n)}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.Orderliness, lowCOrderliness(i18n), highCOrderlisness(i18n)) : highCOrderlisness(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />


            <h3>{i18n.t('How You May Be Perceived')}</h3>
            <ReactMarkdown>{i18n.t('Because of your attention to details')}</ReactMarkdown>

            <h4>{i18n.t('Self-development advice')}</h4>
            <ReactMarkdown>{i18n.t('Make the most of your strengths, because they allow you to be highly reliable')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Stay aware, especially when you communicate with others')}</ReactMarkdown>
            </section>
        </div>
    );
};

function highEEgragariousness(i18n) {
    return <>
        <h4 id="Egregariousness">{i18n.t('e1-or-high-gregariousness')}</h4>
        <ReactMarkdown>{i18n.t('you-enjoy-being-part-of-a-group-and-involve-others-in-what-you-are-doing-you-tend-to-be-the-life-of-the-party-and-are-comfortable-with-speaking-to-a-lot-of-different-people-at-events-regardless-of-how-large-they-are-you-actively-seek-social-connections-and-interactions-and-have-no-issues-joining-a-conversation-or-an-existing-group-of-people')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('im-the-most-gregarious-of-men-and-love-good-company-but-never-less-alone-when-alone')}</ReactMarkdown>
            <ReactMarkdown>**- Peter O'Toole**</ReactMarkdown>
        </div>
    </>;
}

function highECheerfulness(i18n) {
    return <>
        <h4 id="Echeerfulness">{i18n.t('e2-or-high-cheerfulness')}</h4>
        <ReactMarkdown>{i18n.t('you-radiate-joy-and-love-life-having-fun-laughing-and-amusing-your-friends-is-a-priority-to-you-your-positive-optimistic-enthusiastic-looking-at-the-bright-side-of-life-nature-is-highly-contagious')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('walk-with-the-dreamers-the-believers-the-courageous-the-cheerful-the-planners-the-doers-the-successful-people-with-their-heads-in-the-clouds-and-their-feet-on-the-ground-let-their-spirit-ignite-a-fire-within-you-to-leave-this-world-better-than-you-found-it')}</ReactMarkdown>
            <ReactMarkdown>**- Wilferd A. Peterson**</ReactMarkdown>
        </div>
    </>;
}

function highEWarmth(i18n) {
    return <>
        <h4 id="Ewarmth">{i18n.t('e3-or-high-warmth')}</h4>
        <ReactMarkdown>{i18n.t('you-warm-up-quickly-to-others-and-make-friends-easily-because-you-naturally-feel-comfortable-around-people-you-make-sure-that-they-will-also-feel-comfortable-with-you-and-cheer-them-up')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('life-is-about-warm-friendships-memorable-wanderings-and-moments-of-extraordinary-beauty')}
            </ReactMarkdown>
            <ReactMarkdown>**- Avijeet Das**</ReactMarkdown>
        </div>
    </>;
}

function highEExcitement(i18n) {
    return <>
        <h4 id="Eexcitement">{i18n.t('e4-or-high-excitement-seeking')}</h4>
        <ReactMarkdown>{i18n.t('you-love-excitement-and-seek-adventure-and-thrill-in-certain-circumstances-you-may-actively-seek-danger-acting-more-wildly-and-crazily-than-others-would-dare-to-fulfill-your-desire-to-try-anything-exciting-at-least-once')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('loud-environments-filled-with-people-and-activities-energize-you-you-draw-inspiration-from-social-situations-and-external-stimulation')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('i-feel-the-need-to-endanger-myself-every-so-often')}</ReactMarkdown>
            <ReactMarkdown>**- Tim Daly**</ReactMarkdown>
        </div>
    </>;
}

function highEActivity(i18n) {
    return <>
        <h4 id="Eactivity">{i18n.t('e5-or-high-activity-level')}</h4>
        <ReactMarkdown>{i18n.t('you-do-a-lot-in-your-spare-time-and-are-always-busy-and-on-the-go-you-are-capable-of-handling-many-things-in-a-short-amount-of-time-and-react-quickly-when-somebody-or-something-requires-your-attention')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('keep-in-mind-that-busyness-does-not-mean-productivity-and-may-actually-be-bad-for-business-make-sure-you-prioritize-the-tasks-that-will-support-you-and-your-teams-the-most-first')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('i-wanted-to-figure-out-why-i-was-so-busy-but-i-couldnt-find-the-time-to-do-it')}</ReactMarkdown>
            <ReactMarkdown>**- Todd Stocker**</ReactMarkdown>
        </div>
    </>;
}

function highEAssertivness(i18n) {
    return <>
        <h4 id="Eassertiveness">{i18n.t('e6-or-high-assertiveness')}</h4>
        <ReactMarkdown>{i18n.t('you-like-to-take-charge-and-to-lead-others-leveraging-your-skills-at-influencing-others-and-getting-them-to-do-what-needs-to-be-done-to-advance-the-groups-interests-and-causes-taking-control-of-things-comes-naturally-to-you-and-standing-in-the-spotlight-does-not-intimidate-you')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('a-true-leader-has-the-confidence-to-stand-alone-the-courage-to-make-tough-decisions-and-the-compassion-to-listen-to-the-needs-of-others-he-does-not-set-out-to-be-a-leader-but-becomes-one-by-the-equality-of-his-actions-and-the-integrity-of-his-intent')}</ReactMarkdown>
            <ReactMarkdown>**- Douglas MacArthur**</ReactMarkdown>
        </div>
    </>;
}

function highE(i18n, eSubStyle: ESubstyle) {
    return (
        <div className="trait-high">
            <h3>{i18n.t('Your High-Extroversion Levels')}</h3>

            <div className="myimghor">
                <img src={partyimg} alt="High extroversion party time" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('Life is a party!')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@cottonbro" className="intlink">cottonbro</a> {i18n.t('from')} <a
                    href="https://www.pexels.com/photo/people-toasting-wine-glasses-3171837/"
                    className="intlink">Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('As an extrovert, you enjoy social interactions')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('I have social disease. I have to go out every')}</ReactMarkdown>
                <ReactMarkdown>**- Andy Warhol**</ReactMarkdown>
            </div>

            <h3>{i18n.t('Your 6 Extroversion Facets')}</h3>

            <ReactMarkdown>{i18n.t('Extroversion can be broken down into six facets')}</ReactMarkdown>

            <ol>
                <li> <ReactMarkdown>{i18n.t('describes your preference for the company')}</ReactMarkdown></li>
                <li> <ReactMarkdown>{i18n.t('Cheerfulness** describes your tendency to experience')}</ReactMarkdown></li>
                <li> <ReactMarkdown>{i18n.t('Warmth** describes your interest in and friendliness')}</ReactMarkdown></li>
                <li> <ReactMarkdown>{i18n.t('Excitement-seeking** describes your need for environmental')}</ReactMarkdown></li>
                <li> <ReactMarkdown>{i18n.t('Activity level** describes your pace')}</ReactMarkdown></li>
                <li> <ReactMarkdown>{i18n.t('Assertiveness** describes your social ascendancy')}</ReactMarkdown></li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('Discover Your 6 Extroversion Facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Gregariousness, lowEGragariousness(i18n), highEEgragariousness(i18n)) : highEEgragariousness(i18n)}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Cheerfulness, lowECheerfulness(i18n), highECheerfulness(i18n)) : highECheerfulness(i18n)}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Warmth, lowEWarmth(i18n), highEWarmth(i18n)) : highEWarmth(i18n)}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.ExcitementSeeking, lowEExcitement(i18n), highEExcitement(i18n)) : highEExcitement(i18n)}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.ActivityLevel, lowEActivity(i18n), highEActivity(i18n)) : highEActivity(i18n)}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Assertiveness, lowEAssertivness(i18n), highEAssertivness(i18n)) : highEAssertivness(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />

            <h3>{i18n.t('How You May Be Perceived')}</h3>
            <ReactMarkdown>{i18n.t('Your natural energy during in-person interactions')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Keep in mind that your enthusiasm to initiate contact')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('Extroverts tend to fill their environments')}</ReactMarkdown>
        </div>
    );
};

function highATender(i18n) {
    return <>
        <h4 id="Atender">{i18n.t('a1-or-high-tender-mindedness')}</h4>
        <ReactMarkdown>{i18n.t('your-caring-nature-makes-you-care-for-and-understand-for-other-peoples-suffering-this-means-that-you-often-suffer-from-seeing-other-peoples-sorrows-and-suffering')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-tend-to-feel-sympathy-for-those-who-are-worse-off-than-yourself-such-as-the-homeless-and-the-less-fortunate-this-also-will-make-you-more-prone-to-collaborate-with-others-rather-than-actively-compete-with-them-in-a-win-lose-game')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('too-often-we-underestimate-the-power-of-a-touch-a-smile-a-kind-word-a-listening-ear-an-honest-compliment-or-the-smallest-act-of-caring-all-of-which-have-the-potential-to-turn-a-life-around')}
            </ReactMarkdown>
            <ReactMarkdown>**- Leo Buscaglia**</ReactMarkdown>
        </div>
    </>;
}

function highAAltruism(i18n) {
    return <>
        <h4 id="Aaltruism">{i18n.t('a2-or-high-altruism')}</h4>
        <ReactMarkdown>{i18n.t('you-are-concerned-about-other-peoples-wellbeing-and-want-to-help-others-and-make-them-feel-welcome-your-tendency-to-anticipate-the-need-of-others-thoughtfulness-and-charitable-nature-are-your-foundation-you-dont-hesitate-to-serve-others-and-contribute-to-the-benefit-of-your-group-putting-the-needs-of-others-ahead-of-your-own-willingly-doing-anything-for-others')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('keep-in-mind-that-your-willingness-to-sacrifice-your-interests-for-the-sake-of-your-relationships-may-be-taken-advantage-of-by-less-scrupulous-people-who-may-see-you-as-a-pushover-or-a-doormat')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('nobody-cares-how-much-you-know-until-they-know-how-much-you-care')}</ReactMarkdown>
            <ReactMarkdown>**- Theodore Roosevelt**</ReactMarkdown>
        </div>
        <div className="quote">
            <ReactMarkdown>{i18n.t('the-test-of-our-progress-is-not-whether-we-add-more-to-the-abundance-of-those-who-have-much-it-is-whether-we-provide-enough-for-those-who-have-too-little')}</ReactMarkdown>
            <ReactMarkdown>**- Franklin D. Roosevelt**</ReactMarkdown>
        </div>
    </>;
}

function highATrust(i18n) {
    return <>
        <h4 id="Atrust">{i18n.t('a3-or-high-trust')}</h4>
        <ReactMarkdown>{i18n.t('you-easily-trust-others-and-what-they-say-and-tend-to-believe-that-they-have-good-intentions-you-believe-in-human-goodness-and-that-people-are-basically-moral-and-good-which-leads-you-to-view-life-with-a-more-optimistic-thinking-that-all-will-be-well')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('trust-is-the-glue-of-life-its-the-most-essential-ingredient-to-effective-communication-its-the-foundational-principle-that-holds-all-relationships')}</ReactMarkdown>
            <ReactMarkdown>**- Stephen Covey**</ReactMarkdown>
        </div>
    </>;
}

function highACompliance(i18n) {
    return <>
        <h4 id="Acompliance">{i18n.t('a4-or-high-compliance')}</h4>
        <ReactMarkdown>{i18n.t('you-usually-cant-stand-confrontations-and-conflicts-you-prefer-to-maintain-harmony-with-your-relationships-and-the-group-this-means-you-tend-to-express-yourself-quietly-modestly-tactfully-and-with-consideration-to-the-other-person-you-do-so-to-avoid-seeming-pushy-arrogant-or-smug')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-are-also-easy-to-satisfy-when-things-dont-go-your-way-and-easily-forgive-when-people-do-you-harm')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('courage-is-what-it-takes-to-stand-up-and-speak-courage-is-also-what-it-takes-to-sit-down-and-listen')}</ReactMarkdown>
            <ReactMarkdown>**- Winston Churchill**</ReactMarkdown>
        </div>
    </>;
}

function highAModestly(i18n) {
    return <>
        <h4 id="Amodesty">{i18n.t('a5-or-high-modesty')}</h4>
        <ReactMarkdown>{i18n.t('because-you-tend-to-see-yourself-as-a-humble-average-person-you-tend-to-dislike-being-the-center-of-attention-and-talking-about-yourself-while-you-value-your-achievements-it-doesnt-mean-that-you-will-toot-your-own-horn-and-brag-about-them')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('humility-is-not-thinking-less-of-yourself-its-thinking-of-yourself-less')}</ReactMarkdown>
            <ReactMarkdown>**- C.S. Lewis**</ReactMarkdown>
        </div>
    </>;
}

function highAStraightforwardness(i18n) {
    return <>
        <h4 id="Astraightforwardness">{i18n.t('a6-or-high-straightforwardness')}</h4>
        <ReactMarkdown>{i18n.t('you-have-a-strong-moral-compass-and-act-morally-this-means-you-tend-to-stick-to-the-rules-and-would-never-cheat-to-get-ahead-in-life-lying-to-others-or-hurting-them-to-get-what-you-want-is-out-of-the-question')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('instead-you-want-to-make-sure-you-dont-put-people-under-too-much-pressure-out-of-your-concern-for-them-you-seek-solutions-that-will-help-you-both-make-progress-on-your-goals')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('about-morals-i-know-only-that-what-is-moral-is-what-you-feel-good-after-and-what-is-immoral-is-what-you-feel-bad-after')}</ReactMarkdown>
            <ReactMarkdown>**- Ernest Hemingway**</ReactMarkdown>
        </div>
    </>;
}

function highA(i18n, aSubStyle: ASubstyle) {
    return (
        <div className="trait-high">
            <h3>{i18n.t('Your High-Agreeableness Levels')}</h3>

            <div className="myimghor">
                <img src={trustimg} alt="High agreeableness trust" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('trust-makes-anything-possible')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@pixabay">Pixabay</a> from <a
                    href="https://www.pexels.com/photo/hands-people-friends-communication-45842/"
                >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('in-situations-where-you-feel-safe-and-at-ease-you-will-care-about-others-and-easily-trust-them-you-will-encourage-people-to-come-to-you-for-help-and-avoid-confrontations')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('when-you-are-kind-to-others-it-not-only-changes-you-it-changes-the-world')}</ReactMarkdown>
                <ReactMarkdown>**- Harold Kushner**</ReactMarkdown>
            </div>

            <h3>{i18n.t('your-6-agreeableness-facets')}</h3>

            <ReactMarkdown>{i18n.t('agreeableness-can-be-broken-down-into-six-facets-which-describe-your-levels-of-compassion-and-politeness')}</ReactMarkdown>

            <ol>
                <li>{i18n.t('tender-mindedness-describes-your-attitude-of-sympathy-for-others')}</li>
                <li>{i18n.t('altruism-describes-your-active-concern-for-the-welfare-of-others')}</li>
                <li>{i18n.t('trust-describes-your-beliefs-in-the-sincerity-and-good-intentions-of-others')}</li>
                <li>{i18n.t('compliance-describes-your-response-to-interpersonal-conflict')}</li>
                <li>{i18n.t('modesty-describes-your-tendency-to-play-down-your-own-achievements-and-to-be-humble')}</li>
                <li>{i18n.t('straightforwardness-describes-your-frankness-in-expression')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('discover-your-6-agreeableness-facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.TenderMindedness, lowATender(i18n), highATender(i18n)) : highATender(i18n)}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Altruism, lowAAltruism(i18n), highAAltruism(i18n)) : highAAltruism(i18n)}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Trust, lowATrust(i18n), highATrust(i18n)) : highATrust(i18n)}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Compliance, lowACompliance(i18n), highACompliance(i18n)) : highACompliance(i18n)}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Modesty, lowAModesty(i18n), highAModestly(i18n)) : highAModestly(i18n)}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Straightforwardness, lowAStraightforwardness(i18n), highAStraightforwardness(i18n)) : highAStraightforwardness(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />


            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('your-radiant-enthusiasm-may-be-exploited-by-some-people-make-sure-that-you-dont-forget-to-protect-your-own-self-interests-when-needed-by-trying-to-please-everyone-at-all-costs-saying-yes-by-default-even-when-you-dont-mean-it-you-may-be-perceived-as-a-pushover-and-be-taken-advantage-of-your-caring-nature')}</ReactMarkdown>

            <h4>{i18n.t('self-development-advice')}</h4>
            <ReactMarkdown>{i18n.t('learn-to-speak-up-and-defend-what-you-truly-stand-for-think-of-ways-to-create-win-win-situations-instead-of-allowing-people-to-walk-over-you-to-get-what-they-want')}</ReactMarkdown>
        </div>
    );
};

function highNAnger(i18n) {
    return <>
        <h4 id="Nanger">{i18n.t('n1-or-high-anger-irritability')}</h4>
        <ReactMarkdown>{i18n.t('your-tendency-to-become-angry-or-irritated-easily-will-sometimes-drag-you-down-by-setting-you-in-a-bad-mood-at-times-your-frustration-may-degenerate-and-turn-into-your-losing-your-temper-and-become-aggressive-and-hostile-towards-other-people')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('remember-that-anger-fuels-anger-like-kindling-a-fire-which-suddenly-starts-to-rage-with-an-intense-blaze-anger-will-consume-you-and-those-around-you-if-you-dont-pay-attention-to-it-and-let-it-dissipate-before-interacting-with-others')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('anger-is-an-acid-that-can-do-more-harm-to-the-vessel-in-which-it-is-stored-than-to-anything-on-which-it-is-poured')}</ReactMarkdown>
            <ReactMarkdown>**- Mark Twain**</ReactMarkdown>
        </div>

        <div className="quote">
            <ReactMarkdown>{i18n.t('speak-when-you-are-angry-and-you-will-make-the-best-speech-you-will-ever-regret')}</ReactMarkdown>
            <ReactMarkdown>**- Ambrose Bierce**</ReactMarkdown>
        </div>
    </>;
}

function highNImpulsivness(i18n) {
    return <>
        <h4 id="Nimpulsiveness">{i18n.t('n2-or-high-impulsiveness')}</h4>
        <ReactMarkdown>{i18n.t('you-sometimes-do-things-you-later-regret-and-have-difficulties-understanding-why-you-do-some-of-the-things-you-do-without-realizing-it-you-may-act-on-a-whim-without-considering-the-consequences-of-your-actions-on-yourself-and-others-around-you')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('when-you-do-so-you-choose-short-term-gains-over-long-term-ones-the-tempting-immediate-gain-lures-you-in-without-regard-for-the-long-term-consequences-these-behaviors-can-impact-your-eating-drinking-or-spending-habits-and-in-the-long-term-your-health-and-well-being')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('wisely-and-slowly-they-stumble-that-run-fast')}</ReactMarkdown>
            <ReactMarkdown>**- William Shakespeare**</ReactMarkdown>
        </div>
    </>;
}

function highNAnxiety(i18n) {
    return <>
        <h4 id="Nanxiety">{i18n.t('n3-or-high-anxiety')}</h4>
        <ReactMarkdown>{i18n.t('life-can-sometimes-feel-overwhelming-you-tend-to-worry-about-things-that-may-happen-and-are-preoccupied-by-your-existing-problems-at-times-you-may-be-absorbed-into-reflecting-on-something-you-did-or-which-happened-to-you-feeling-stuck-in-the-past')} 
    </ReactMarkdown>
        <ReactMarkdown>{i18n.t('to-get-out-of-this-mental-trap-you-need-to-try-to-understand-what-lesson-you-can-learn-about-your-experiences-and-the-mistakes-you-made-to-make-sure-that-you-can-either-prevent-them-from-happening-again-in-the-future-or-correct-them-should-they-still-happen')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('worry-is-a-state-of-min-based-upon-fear-it-works-slowly-but-persistently-it-is-insidious-and-subtle-step-by-step-it-digs-itself-in-until-it-paralyzes-ones-reasoning-faculty-destroys-self-confidence-and-initiative-worry-is-a-form-of-sustained-fear-caused-by-indecision-therefore-it-is-a-state-of-mind-which-can-be-controlled')}</ReactMarkdown>
            <ReactMarkdown>**- Napoleon Hill**</ReactMarkdown>
        </div>

        <div className="quote">
            <ReactMarkdown>{i18n.t('there-is-only-one-way-to-happiness-and-that-is-to-cease-worrying-about-things-which-are-beyond-the-power-of-our-will')}</ReactMarkdown>
            <ReactMarkdown>**- Epitectus**</ReactMarkdown>
        </div>
    </>;
}

function highNDepression(i18n) {
    return <>
        <h4 id="Ndepression">{i18n.t('n4-or-high-depression')}</h4>
        <ReactMarkdown>{i18n.t('you-tend-to-experience-frequent-mood-swings-with-intense-ups-and-downs-you-often-feel-down-at-times-you-may-feel-desperate-disheartened-and-that-your-life-lacks-direction')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('because-of-the-sadness-you-experience-you-sometimes-start-doubting-yourself-have-a-low-opinion-of-yourself-and-may-at-times-even-dislike-yourself')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('im-here-i-love-you-i-dont-care-if-you-need-to-stay-up-crying-all-night-long-i-will-stay-with-you-theres-nothing-you-can-ever-do-to-lose-my-love-i-will-protect-you-until-you-die-and-after-your-death-i-will-still-protect-you-i-am-stronger-than-depression-and-i-am-braver-than-loneliness-and-nothing-will-ever-exhaust-me')}</ReactMarkdown>
            <ReactMarkdown>**- Elizabeth Gilbert**</ReactMarkdown>
        </div>
    </>;
}

function highNSelfConsioussness(i18n) {
    return <>
        <h4 id="Nselfconsciousness">{i18n.t('n5-or-high-self-consciousness')}</h4>
        <ReactMarkdown>{i18n.t('self-consciousness-describes-a-high-preoccupation-with-yourself-and-the-worrying-feeling-that-everyone-is-watching-you-this-translates-into-being-easily-hurt-intimidated-or-embarrassed-by-everyday-events')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('when-feeling-overly-self-conscious-you-pay-too-much-attention-to-what-you-are-doing-and-therefore-perform-worse-than-if-you-were-in-a-more-detached-state-of-flow-by-worrying-about-what-could-go-wrong-things-can-actually-go-wrong')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('this-also-makes-your-social-life-more-difficult-only-feeling-comfortable-with-close-friends-and-finding-it-difficult-to-approach-others-or-fear-of-drawing-attention-to-yourself-will-prevent-you-from-creating-new-meaningful-relationships')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('keep-in-mind-the-words-of-henry-ford-whether-you-think-you-can-or-think-you-cant-you-are-right-your-worries-may-become-self-fulling-prophecies-stay-careful-not-to-become-their-victim')}
        </ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('if-you-dont-believe-inside-yourself-that-you-can-learn-a-lot-nobodys-ever-going-to-do-that-for-you-nobodys-ever-gonna-give-you-self-drive-nobodys-ever-gonna-give-you-self-esteem-nobodys-ever-gonna-give-you-your-self-worth-you-have-to-set-it-for-yourself')}</ReactMarkdown>
            <ReactMarkdown>**- Brother Ali**</ReactMarkdown>
        </div>
    </>;
}

function highNVulnerability(i18n) {
    return <>
        <h4 id="Nvulnerability">{i18n.t('n6-or-high-vulnerability')}</h4>
        <ReactMarkdown>{i18n.t('your-intense-emotions-can-sometimes-get-the-best-of-you-when-they-overwhelm-you-you-lose-your-ability-to-effectively-make-decisions-when-this-happens-small-setbacks-seem-to-become-unsurmountable-challenges-which-makes-you-feel-that-you-are-unable-to-deal-with-them')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('to-better-deal-with-your-powerful-emotions-you-need-to-learn-to-recognize-them-when-they-start-appearing-and-apply-strategies-that-work-for-you-to-soothe-them-ask-for-help-if-you-need-it-you-are-not-alone')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('if-you-hold-back-on-the-emotions-if-you-dont-allow-yourself-to-go-all-the-way-through-them-you-can-never-get-to-being-detached-youre-too-busy-being-afraid-youre-afraid-of-the-pain-youre-afraid-of-the-grief-youre-afraid-of-the-vulnerability-that-loving-entails-but-by-throwing-yourself-into-these-emotions-by-allowing-yourself-to-dive-in-all-the-way-over-your-head-even-you-experience-them-fully-and-completely')}</ReactMarkdown>
            <ReactMarkdown>**- Mitch Albom**</ReactMarkdown>
        </div>

        <h4>{i18n.t('how-you-may-be-perceived')}</h4>
        <ReactMarkdown>{i18n.t('your-tendency-to-worry-about-future-challenges-is-valuable-because-it-helps-you-to-cover-potential-scenarios-and-prepare-for-the-worst-worrying-about-others-well-being-also-helps-you-to-be-seen-as-a-caring-person')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('however-some-people-may-perceive-you-as-being-insecure-or-anxious-to-avoid-making-things-looking-only-pessimistic-you-can-leverage-your-tendency-to-think-of-possible-scenarios-to-also-consider-what-could-go-right')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('your-emotions-are-a-strength-and-are-contagious-you-need-to-learn-to-recognize-them-when-they-are-setting-in-to-avoid-experiencing-extreme-mood-swings-and-to-become-better-able-at-soothing-them-doing-so-is-a-sign-of-higher-emotional-intelligence-focusing-on-both-self-awareness-and-self-development')}</ReactMarkdown>
    </>;
}

function highN(i18n, nSubStyle: NSubstyle) {
    return (
        <div className="trait-high">
            <h3>{i18n.t('your-high-nervosity-levels')}</h3>

            <div className="myimghor">
                <img src={worry1img} alt="High nervousness anxiety" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('life-is-full-of-ups-and-downs-filled-with-trials-for-us-to-overcome')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@katlovessteve">Kat Jayne</a> from <a
                    href="https://www.pexels.com/photo/adult-alone-anxious-black-and-white-568027/"
                >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('as-a-high-nervosity-person-you-are-likely-a-worrier-someone-who-experiences-intense-emotions-you-may-reminisce-over-the-past-and-worry-about-whats-ahead-and-what-others-think-of-you-you-may-be-unconsciously-looking-for-the-challenges-and-downsides-of-everyday-situations-some-people-might-call-this-pessimism-but-it-likely-has-to-do-with-your-need-to-search-for-multiple-scenarios-and-see-how-you-can-address-them')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('we-suffer-more-often-from-imagination-than-from-reality')}</ReactMarkdown>
                <ReactMarkdown>**- Seneca**</ReactMarkdown>
            </div>

            <h3>{i18n.t('your-6-nervosity-facets')}</h3>

            <ReactMarkdown>{i18n.t('nervosity-can-be-broken-down-into-six-facets-which-describe-your-levels-of-emotional-volatility-and-withdrawal')}</ReactMarkdown>

            <ol>
                <li>{i18n.t('anger-irritability-describes-your-tendency-to-experience-anger-frustration-bitterness-and-resentment')}</li>
                <li>{i18n.t('impulsiveness-describes-your-tendency-to-act-on-cravings-and-urges-rather-than-reining-them-in-and-delaying-gratification')}</li>
                <li>{i18n.t('anxiety-describes-your-levels-of-free-floating-anxiety-that-is-experiencing-fear-and-anxiety-in-everyday-situations-without-being-able-to-identify-any-real-observable-threat')}</li>
                <li>{i18n.t('depression-describes-your-tendency-to-experience-crushing-feelings-of-sadness-guilt-despondency-and-loneliness')}</li>
                <li>{i18n.t('self-consciousness-describes-your-shyness-or-social-anxiety')}</li>
                <li>{i18n.t('vulnerability-describes-your-general-susceptibility-to-stress')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('discover-your-6-nervosity-facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Anger, lowNAnger(i18n), highNAnger(i18n)) : highNAnger(i18n)}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Impulsiveness, lowNImpulsivness(i18n), highNImpulsivness(i18n)) : highNImpulsivness(i18n)}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Anxiety, lowNAnxiety(i18n), highNAnxiety(i18n)) : highNAnxiety(i18n)}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Depression, lowNDepression(i18n), highNDepression(i18n)) : highNDepression(i18n)}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.SelfConsciousness, lowNSelfConsciousness(i18n), highNSelfConsioussness(i18n)) : highNSelfConsioussness(i18n)}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Vulnerability, lowNVulnerability(i18n), highNVulnerability(i18n)) : highNVulnerability(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />


            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('your-tendency-to-worry-about-future-challenges-is-valuable-because-it-helps-you-to-cover-potential-scenarios-and-prepare-for-the-worst-worrying-about-others-well-being-also-helps-you-to-be-seen-as-a-caring-person')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('however-some-people-may-perceive-you-as-being-insecure-or-anxious-to-avoid-making-things-looking-only-pessimistic-you-can-leverage-your-tendency-to-think-of-possible-scenarios-to-also-consider-what-could-go-right')}</ReactMarkdown>

            <h4>{i18n.t('self-development-advice')}</h4>
            <ReactMarkdown>{i18n.t('your-emotions-are-a-strength-and-are-contagious-you-need-to-learn-to-recognize-them-when-they-are-setting-in-to-avoid-experiencing-extreme-mood-swings-and-to-become-better-able-at-soothing-them-doing-so-is-a-sign-of-higher-emotional-intelligence-focusing-on-both-self-awareness-and-self-development')}</ReactMarkdown>
        </div>
    );
}


function lowOIdeas(i18n) {
    return <>
        <h4 id="Oideas">{i18n.t('o1-or-low-openness-to-ideas')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-ideas-describes-your-intellectual-curiosity')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-are-more-down-to-earth-practical-and-pragmatic-than-others-abstract-ideas-dont-interest-you-that-much-and-you-have-difficulties-understanding-them-you-may-even-sometimes-resist-new-ideas')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-tend-to-avoid-or-are-not-interested-in-theoretical-or-philosophical-discussions-and-prefer-actions-over-theories-you-may-avoid-difficult-reading-material-or-skip-words-while-reading')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('it-is-much-easier-to-propose-than-to-execute')}</ReactMarkdown>
            <ReactMarkdown>**- David Noonan**</ReactMarkdown>
        </div>
    </>;
}

function lowOFantasy(i18n) {
    return <>
        <h4 id="Ofantasy">{i18n.t('o2-or-low-openness-to-fantasy')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-fantasy-describes-your-receptivity-to-the-inner-world-of-imagination-imagination-is-extremely-useful-to-step-outside-of-yourself-and-reflect-on-how-other-people-may-experience-and-perceive-you')}</ReactMarkdown>
        
        <ReactMarkdown>{i18n.t('you-prefer-acting-to-thinking-you-seldom-daydream-or-get-lost-in-thought-your-practical-nature-makes-it-more-difficult-for-you-to-imagine-things-and-you-may-sometimes-feel-that-you-dont-have-such-a-good-imagination')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('vision-wihout-action-is-merely-a-dream-action-without-vision-just-passes-the-time-vision-with-action-can-change-the-world')}</ReactMarkdown>
            <ReactMarkdown>**- Joel A. Barker**</ReactMarkdown>
        </div>
    </>;
}

function lowOAesthetics(i18n) {
    return <>
        <h4 id="Oaesthetics">{i18n.t('o3-or-low-openness-to-aesthetics')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-aesthetics-describes-your-appreciation-of-art-and-beauty')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('art-rarely-makes-you-feel-anything-significant-and-you-may-not-understand-it-you-tend-not-to-be-interested-going-to-concerts-museums-dance-performances-or-poetry-recitals')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('sometimes-it-is-the-simplest-seemingly-most-inane-most-practical-stuff-that-matters-the-most-to-someone')}</ReactMarkdown>
            <ReactMarkdown>**- Patty Duke**</ReactMarkdown>
        </div>
    </>;
}

function lowOEmotionality(i18n) {
    return <>
        <h4 id="Oemotionality">{i18n.t('o4-or-low-openness-to-emotionality')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-emotionality-describes-your-ability-and-willingness-to-explore-your-inner-emotions-and-feelings')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-are-not-easily-affected-by-your-emotions-and-seldom-get-emotional-or-rarely-notice-your-emotional-reactions-you-experience-very-few-emotional-highs-and-lows-and-have-a-difficult-time-understanding-people-who-get-emotional')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('true-courage-is-cool-and-calm-the-bravest-of-men-have-the-least-of-a-brutal-bullying-insolence-and-in-the-very-time-of-danger-are-found-the-most-serene-and-free')}</ReactMarkdown>
            <ReactMarkdown>**- Lord Shaftesbury**</ReactMarkdown>
        </div>
    </>;
}

function lowOAdventure(i18n) {
    return <>
        <h4 id="Oadventure">{i18n.t('o5-or-low-openness-to-adventure')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-adventure-and-action-describes-your-openness-to-new-experiences-on-a-practical-level')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-prefer-to-stick-with-things-that-you-know-and-like-the-tried-and-true-your-routines-matter-more-to-you-than-variety-of-experiences')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-tend-to-dislike-change-and-are-more-a-creature-of-habit-attached-to-your-conventional-ways-and-you-thrive-in-your-narrower-field-of-interests-you-tend-to-be-more-risk-averse-and-change-averse-than-others-and-are-therefore-more-cautious-and-consistent-by-nature')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('good-habits-are-worth-being-fanatical-about')}</ReactMarkdown>
            <ReactMarkdown>**- John Irving**</ReactMarkdown>
        </div>
    </>;
}

function lowOValues(i18n) {
    return <>
        <h4 id="Ovalues">{i18n.t('o6-or-low-openness-to-values')}</h4>
        <ReactMarkdown>{i18n.t('openness-to-values-describes-your-readiness-to-challenge-your-own-values-and-those-of-authority-figures')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-see-yourself-as-more-of-a-conservative-conventional-and-conforming-person-you-may-be-more-interested-in-discussing-traditional-ideas-over-liberal-ones')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('to-be-conservative-then-is-to-prefer-the-familiar-to-the-unknown-to-prefer-the-tried-to-the-untried-fact-to-mystery-the-actual-to-the-possible-the-limited-to-the-unbounded-the-near-to-the-distant-the-sufficient-to-the-superabundant-the-convenient-to-the-perfect-present-laughter-to-utopian-bliss')}</ReactMarkdown>
            <ReactMarkdown>**- Michael Joseph Oakeshott**</ReactMarkdown>
        </div>
    </>;
}

function scoreBasedSubstryle(score: number, lowElement: JSX.Element, highElement: JSX.Element) {
    if (score < 50) {
        return lowElement
    } else {
        return highElement
    }
}

function lowO(i18n, oSubStyle: OSubstyle) {
    return (
        <div className="trait-low">
            <section id="Odescription" className="offset-anchor">
            <h3>{i18n.t('You Have Low Openness Levels')}</h3>

            <div className="myimghor">
                <img src={newspaperimg} alt="Low Openness Routine" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('What are your favorite routines?')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@elijahsad">Elijah O'Donnell</a> {i18n.t('from')} <a
                    href="https://www.pexels.com/photo/black-man-reading-a-newspaper-3473492/"
                >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('You tend to prefer sticking to your habits and routines')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('good habits are hard to develop')}</ReactMarkdown>
                <ReactMarkdown>**- Brian Tracy**</ReactMarkdown>
            </div>

            <section id="Ofacets" className="offset-anchor list-short-height">
            <h3>{i18n.t('Your 6 Openness to Experience Facets')}</h3>

            <p className="myreportP">{i18n.t('Openness to experience can be broken down into six facets')}</p>

            <ol>
                <li><b>{i18n.t('openness-to-ideas')}</b></li>
                <li><b>{i18n.t('openness-to-fantasy')}</b></li>
                <li><b>{i18n.t('openness-to-aesthetics')}</b></li>
                <li><b>{i18n.t('openness-to-emotionality')}</b></li>
                <li><b>{i18n.t('openness-to-adventure')}</b></li>
                <li><b>{i18n.t('openness-to-values')}</b></li>
            </ol>
            </section>

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('Discover Your 6 Openness to Experience Facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Ideas, lowOIdeas(i18n), highOIdeas(i18n)) : lowOIdeas(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Fantasy, lowOFantasy(i18n), highOFantasy(i18n)) : lowOFantasy(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Aesthetics, lowOAesthetics(i18n), highOAesthetics(i18n)) : lowOAesthetics(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Emotionality, lowOEmotionality(i18n), highOEmotionality(i18n)) : lowOEmotionality(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Adventure, lowOAdventure(i18n), highOAdventure(i18n)) : lowOAdventure(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Values, lowOValues(i18n), highOValues(i18n)) : lowOValues(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />


            <h3>{i18n.t('How You May Be Perceived')}</h3>
            <ReactMarkdown>{i18n.t('Your cautious nature may make others')}</ReactMarkdown>

            <h4>{i18n.t('Self-development advice')}</h4>
            <ReactMarkdown>{i18n.t('You need to cherish your habits')}</ReactMarkdown>
            </section>
        </div>
    );
};


function lowCAchievement(i18n) {
    return <>
        <h4 id="Cachievement">{i18n.t('c1-or-low-achievement-striving')}</h4>
        <ReactMarkdown>{i18n.t('Achievement striving describes')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('succeeding-at-all-costs-is-not-your-priority-you-tend-to-be-satisfied-by-good-enough-solutions-and-prefer-to-put-less-time-and-effort-into-your-work-over-grinding-sometimes-your-tendency-to-do-just-enough-work-to-get-by-may-mean-that-you-fail-to-complete-what-you-were-supposed-to-do-you-tend-to-look-for-shortcuts-or-at-least-for-the-easiest-way-to-complete-certain-tasks')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('self-development-advice-keep-in-mind-that-although-you-may-get-by-in-some-situations-with-a-minimum-effort-in-doing-so-you-will-not-fully-realize-your-potential-in-the-long-run-there-is-no-substitute-for-hard-work')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('done-is-better-than-perfect')}</ReactMarkdown>
            <ReactMarkdown>**- Sheryl Sandberg**</ReactMarkdown>
        </div>
    </>;
}

function lowCSelfEfficacy(i18n) {
    return <>
        <h4 id="Cselfefficacy">{i18n.t('c2-or-low-self-efficacy')}</h4>
        <ReactMarkdown>{i18n.t('self-efficacy-describes-your-beliefs-about-how-well-you-can-execute-courses-of-action-required-to-deal-with-prospective-situations')}</ReactMarkdown><a
                    href="https://www.semanticscholar.org/paper/Self-efficacy-mechanism-in-human-agency.-Bandura/8beec556fe7a650120544a99e9e063eb8fcd987b"
                    className="intlink"> Source</a>

        <ReactMarkdown>{i18n.t('you-sometimes-think-difficult-situations-are-beyond-your-capabilities-or-that-you-have-little-to-contribute-to-the-team-you-may-misjudge-situations-and-dont-see-the-consequences-of-your-actions')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('never-give-in-except-to-convictions-of-honor-and-good-sense-never-yield-to-force-never-yield-to-the-apparently-overwhelming-might-of-the-enemy')}</ReactMarkdown>
            <ReactMarkdown>**- Winston Churchill**</ReactMarkdown>
        </div>
    </>;
}

function lowCSelfDiscipline(i18n) {
    return <>
        <h4 id="Cselfdiscipline">{i18n.t('c3-or-low-self-discipline')}</h4>
        <ReactMarkdown>{i18n.t('self-discipline-describes-your-capacity-to-begin-tasks-and-follow-through-to-completion-despite-boredom-or-distractions')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-may-find-it-difficult-to-get-down-to-work-and-need-a-push-to-get-started-to-avoid-neglecting-your-duties-you-have-a-hard-time-starting-tasks-because-of-your-tendencies-to-postpone-decisions-and-to-procrastinate-important-things-in-your-life-sometimes-you-also-may-leave-things-unfinished-because-something-distracted-you-from-what-you-were-doing')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('as-a-result-you-may-be-late-or-miss-deadlines-because-of-your-unconscious-tendency-to-waste-your-time-keep-in-mind-that-this-may-impact-the-people-you-work-with-and-may-irritate-them')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('in-any-moment-of-decision-the-best-thing-you-can-do-is-the-right-thing-the-next-best-thing-is-the-wrong-thing-and-the-worst-thing-you-can-do-is-nothing')}</ReactMarkdown>
            <ReactMarkdown>**- Theodore Roosevelt**</ReactMarkdown>
        </div>
    </>;
}

function lowCCautiousness(i18n) {
    return <>
        <h4 id="Ccautiousness">{i18n.t('c4-or-low-cautiousness')}</h4>
        <ReactMarkdown>{i18n.t('cautiousness-describes-your-tendency-to-think-things-through-before-acting-or-speaking')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-feel-comfortable-making-quick-decisions-and-jumping-into-things-without-too-much-thinking-your-spontaneity-helps-you-to-get-things-moving-and-done-you-are-flexible-and-handle-well-when-confronted-with-last-minute-change-of-plans-your-easy-going-and-carefree-nature-helps-you-to-rapidly-adjust-when-life-throws-you-lemons-others-may-perceive-you-as-being-too-impulsive-or-careless-sometimes')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('i-am-not-a-product-of-my-circumstances-i-am-a-product-of-my-decisions')}</ReactMarkdown>
            <ReactMarkdown>**- Stephen Covey**</ReactMarkdown>
        </div>
    </>;
}

function lowCDutifulness(i18n) {
    return <>
        <h4 id="Cdutifulness">{i18n.t('c5-or-low-dutifulness')}</h4>
        <ReactMarkdown>{i18n.t('dutifulness-describes-how-important-fulfilling-your-moral-obligations-is-to-you')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('sometimes-you-believe-that-the-ends-justify-the-means-this-means-that-you-may-break-your-promises-when-you-believe-they-are-wrong-or-break-the-rules-when-necessary-you-dont-mind-getting-others-to-do-your-duties-or-doing-the-opposite-of-what-is-asked-as-long-as-you-can-get-things-done')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('it-is-better-to-act-and-repent-than-not-to-act-and-regret')}</ReactMarkdown>
            <ReactMarkdown>**- Niccolo Machiavelli**</ReactMarkdown>
        </div>
    </>;
}

function lowCOrderliness(i18n) {
    return <>
        <h4 id="Corderliness">{i18n.t('c6-or-low-orderliness')}</h4>
        <ReactMarkdown>{i18n.t('orderliness-describes-your-ability-to-be-well-organized-and-diligent')}</ReactMarkdown>

        <ReactMarkdown>{i18n.t('you-are-usually-not-bothered-by-disorder-taking-care-of-things-is-not-very-important-to-you-and-you-may-forget-to-return-things-or-put-them-back-where-they-belong-you-are-content-with-your-usual-appearance-and-environment-sometimes-you-have-to-ask-for-pens-and-chargers-because-you-forgot-your-own')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-have-no-issues-to-improvise-acting-in-a-more-disorganized-and-unplanned-fashion-than-other-people-you-tend-to-dislike-excessive-structure-and-schedule-keep-in-mind-that-your-carefree-nature-may-be-perceived-as-negligent-lazy-and-messy')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('clutter-and-mess-show-us-that-life-is-being-lived')}</ReactMarkdown>
            <ReactMarkdown>**- Anne Lamott**</ReactMarkdown>
        </div>
    </>;
}

function lowC(i18n, cSubStyle: CSubstyle) {
    return (
        <div className="trait-low">
            <section id="Cdescription" className="offset-anchor">
            <h3>{i18n.t('you-have-low-conscientiousness-levels')}</h3>

            <div className="myimghor">
                <img src={carefreeimg} alt="Low conscientiousness carefree" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('being-carefree-and-easygoing-makes-life-more-enjoyable-right')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@nappy">nappy</a> from <a
                    href="https://www.pexels.com/photo/female-leisure-recreation-relaxation-1154638/"
                >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('you-tend-to-prefer-strategic-thinking-and-keeping-things-simple-avoiding-the-hassle-of-planning-everything-in-excruciating-details')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('perfection-is-achieved-not-when-there-is-nothing-more-to-add-but-when-there-is-nothing-left-to-take-away')}</ReactMarkdown>
                <ReactMarkdown>**- Antoine de Saint-Exupéry**</ReactMarkdown>
            </div>


            <section id="Cfacets" className="offset-anchor list-short-height">
            <h3>{i18n.t('your-6-conscientiousness-facets')}</h3>

            <ReactMarkdown>{i18n.t('conscientiousness-can-be-broken-down-into-six-facets-which-describe-how-industrious-and-orderly-you-are')}</ReactMarkdown>

            <ol>
                <li>{i18n.t('achievement-striving-describes-your-need-for-personal-achievement-and-sense-of-direction')}
                </li>
                <li>{i18n.t('self-efficacy-describes-your-beliefs-about-how-well-you-can-execute-courses-of-action-required-to-deal-with-prospective-situations')}<a
                        href="https://www.semanticscholar.org/paper/Self-efficacy-mechanism-in-human-agency.-Bandura/8beec556fe7a650120544a99e9e063eb8fcd987b"
                        className="intlink">Source</a></li>
                <li>{i18n.t('self-discipline-describes-your-capacity-to-begin-tasks-and-follow-through-to-completion-despite-boredom-or-distractions')}
                </li>
                <li>{i18n.t('cautiousness-describes-your-tendency-to-think-things-through-before-acting-or-speaking')}</li>
                <li>{i18n.t('dutifulness-describes-how-important-fulfilling-your-moral-obligations-is-to-you')}</li>
                <li>{i18n.t('orderliness-describes-your-ability-to-be-well-organized-and-diligent')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('Discover Your 6 Conscientiousness Facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.AchievementStriving, lowCAchievement(i18n), highCAchievement(i18n)) : lowCAchievement(i18n)}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.SelfEfficacy, lowCSelfEfficacy(i18n), highCSelfEfficacy(i18n)) : lowCSelfEfficacy(i18n)}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.SelfDiscipline, lowCSelfDiscipline(i18n), highCSelfDiscipline(i18n)) : lowCSelfDiscipline(i18n)}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.Cautiousness, lowCCautiousness(i18n), highCCautiosness(i18n)) : lowCCautiousness(i18n)}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.Dutifulness, lowCDutifulness(i18n), highCDutifulness(i18n)) : lowCDutifulness(i18n)}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.Orderliness, lowCOrderliness(i18n), highCOrderlisness(i18n)) : lowCOrderliness(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>
            
            </section>
            <br /> <br />
            </section>

            <h4>{i18n.t('how-you-may-be-perceived')}</h4>
            <ReactMarkdown>{i18n.t('your-desire-for-being-more-strategic-and-your-ability-to-navigate-uncertainty-with-fewer-information-is-a-strength-to-making-tough-decisions-in-difficult-times')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('however-people-especially-individuals-with-higher-levels-of-conscientiousness-may-start-questioning-how-reliable-you-are-when-you-forget-about-what-they-asked-you-to-do-or-when-you-miss-a-deadline-they-may-look-down-upon-your-tendency-to-forget-important-events-or-discussions-or-on-how-unorganized-it-makes-you-look')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('taking-good-notes-planning-your-priorities-in-advance-and-preparing-for-emergencies-are-simple-steps-you-can-take-to-become-better-prepared-without-having-to-worry-too-much-about-it')}</ReactMarkdown>
        </div>
    );
};

function lowEGragariousness(i18n) {
    return <>
        <h4 id="Egregariousness">{i18n.t('e1-or-low-gregariousness')}</h4>
        <ReactMarkdown>{i18n.t('you-prefer-to-be-alone-and-avoid-crowded-events-you-tend-to-work-and-think-best-alone-needing-time-to-think-before-you-answer-and-get-back-to-the-group-your-solitary-withdrawn-and-reserved-nature-may-make-others-perceive-you-as-a-loner-before-they-get-to-know-you-better')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('quiet-more-personal-interactions-allow-you-to-thrive-larger-groups-and-crowded-events-are-likely-going-to-intimidate-and-drain-you-socializing-with-others-may-feel-exhausting-after-a-period-of-time-and-will-require-that-you-take-time-alone-to-recover-from-it')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('the-highest-form-of-love-is-to-be-the-protector-of-another-persons-solitude')}</ReactMarkdown>
            <ReactMarkdown>**- Rainer Maria Rilke**</ReactMarkdown>
        </div>

        <div className="quote">
            <ReactMarkdown>{i18n.t('loneliness-is-the-poverty-of-self-solitude-is-the-richness-of-self')}</ReactMarkdown>
            <ReactMarkdown>**- Mary Sarton**</ReactMarkdown>
        </div>
    </>;
}

function lowECheerfulness(i18n) {
    return <>
        <h4 id="Echeerfulness">{i18n.t('e2-or-low-cheerfulness')}</h4>
        <ReactMarkdown>{i18n.t('you-tend-to-be-a-more-serious-person-who-seldom-jokes-around-and-smiles-occasionally-your-more-earnest-nature-allows-you-to-keep-people-focused-on-what-needs-to-get-done')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('earnestness-is-enthusiasm-tempered-by-reason')}</ReactMarkdown>
            <ReactMarkdown>**- Blaise Pascal**</ReactMarkdown>
        </div>
    </>;
}

function lowEWarmth(i18n) {
    return <>
        <h4 id="Ewarmth">{i18n.t('e3-or-low-warmth')}</h4>
        <ReactMarkdown>{i18n.t('because-you-may-feel-uncomfortable-around-others-or-arent-necessarily-interested-in-others-you-are-sometimes-hard-to-get-to-know-you-tend-to-dislike-being-the-center-of-attention-your-reserved-nature-will-often-drive-you-to-keep-others-at-a-distance-or-to-avoid-contact-with-them-altogether')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('forgive-my-indifference-id-rather-be-distant-than-devastated')}</ReactMarkdown>
            <ReactMarkdown>**- Ahmed Mostafa**</ReactMarkdown>
        </div>
    </>;
}

function lowEExcitement(i18n) {
    return <>
        <h4 id="Eexcitement">{i18n.t('e4-or-low-excitement-seeking')}</h4>
        <ReactMarkdown>{i18n.t('you-prefer-comfort-over-adventure-and-are-more-cautious-trying-not-to-hurt-yourself-or-put-yourself-in-dangerous-situations-no-matter-how-thrilling-they-seem-high-adrenaline-situations-like-hand-gliding-or-bungee-jumping-dont-attract-you')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-tend-to-be-energized-by-your-inner-world-rather-than-by-external-stimulation-which-makes-you-dislike-loud-music-and-events')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('the-scars-of-others-should-teach-us-caution')}</ReactMarkdown>
            <ReactMarkdown>**- St. Jerome**</ReactMarkdown>
        </div>
    </>;
}

function lowEActivity(i18n) {
    return <>
        <h4 id="Eactivity">{i18n.t('e5-or-low-activity-level')}</h4>
        <ReactMarkdown>{i18n.t('you-like-to-take-your-time-and-let-things-proceed-at-their-own-steady-pace-you-also-enjoy-calming-down-relaxing-and-enjoying-life-without-rushing-through-it-living-a-leisurely-lifestyle')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('never-discourage-anyone-who-continually-makes-progress-no-matter-how-slow')}</ReactMarkdown>
            <ReactMarkdown>**- Plato**</ReactMarkdown>
        </div>
    </>;
}

function lowEAssertivness(i18n) {
    return <>
        <h4 id="Eassertiveness">{i18n.t('e6-or-low-assertiveness')}</h4>
        <ReactMarkdown>{i18n.t('because-you-tend-to-be-more-quiet-and-say-little-preferring-to-keep-in-the-background-and-holding-back-your-opinions-you-tend-to-wait-for-others-to-lead-the-way-you-dont-like-to-draw-attention-to-yourself-and-prefer-to-be-more-passive-than-active-during-conversations-to-avoid-standing-in-the-spotlight-and-being-the-center-of-attention')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('i-think-a-lot-but-i-dont-say-much')}</ReactMarkdown>
            <ReactMarkdown>**- Anne Frank**</ReactMarkdown>
        </div>
    </>;
}

function lowE(i18n, eSubStyle: ESubstyle) {
    return (
        <div className="trait-low">

            <h3>{i18n.t('you-have-low-extroversion-levels')}</h3>

            <div className="myimg">
                <img src={readingimg} alt="Low extroversion quiet time" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('we-all-need-some-quiet-time-to-recharge')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@daria">Daria Shevtsova</a> from <a
                    href="https://www.pexels.com/photo/woman-sitting-while-reading-a-book-1616779/"
                >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('as-an-introvert-you-tend-to-prefer-one-on-one-interactions-and-evolving-in-groups-of-three-to-four-people-you-may-dislike-small-talk-have-difficulties-initiating-conversations-getting-to-know-other-people-and-sharing-private-information-about-yourself-that-being-said-once-you-trust-someone-the-walls-usually-slowly-come-down')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('quiet-people-have-the-loudest-minds')}</ReactMarkdown>
                <ReactMarkdown>**- Stephen Hawking**</ReactMarkdown>
            </div>

            <h3>{i18n.t('your-6-extroversion-facets')}</h3>

            <ReactMarkdown>{i18n.t('extroversion-can-be-broken-down-into-six-facets-which-describe-your-levels-of-enthusiasm-and-assertiveness')}</ReactMarkdown>

            <ol>
                <li>{i18n.t('gregariousness-describes-your-preference-for-the-company-of-others-over-solitude')}</li>
                <li>{i18n.t('cheerfulness-describes-your-tendency-to-experience-and-spread-positive-emotions')}</li>
                <li>{i18n.t('warmth-describes-your-interest-in-and-friendliness-towards-others')}</li>
                <li>{i18n.t('excitement-seeking-describes-your-need-for-environmental-stimulation')}</li>
                <li>{i18n.t('activity-level-describes-your-pace-of-living')}</li>
                <li>{i18n.t('assertiveness-describes-your-social-ascendancy-and-forcefulness-of-expression')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('Discover Your 6 Extroversion Facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Gregariousness, lowEGragariousness(i18n), highEEgragariousness(i18n)) : lowEGragariousness(i18n)}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Cheerfulness, lowECheerfulness(i18n), highECheerfulness(i18n)) : lowECheerfulness(i18n)}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Warmth, lowEWarmth(i18n), highEWarmth(i18n)) : lowEWarmth(i18n)}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.ExcitementSeeking, lowEExcitement(i18n), highEExcitement(i18n)) : lowEExcitement(i18n)}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.ActivityLevel, lowEActivity(i18n), highEActivity(i18n)) : lowEActivity(i18n)}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Assertiveness, lowEAssertivness(i18n), highEAssertivness(i18n)) : lowEAssertivness(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />


            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('your-tendency-to-do-your-best-thinking-alone-and-getting-back-to-the-group-after-in-person-interactions-may-make-others-especially-people-with-higher-levels-of-extroversion-perceive-you-as-distant-sometimes-even-cold')}</ReactMarkdown>
        </div>
    );
};

function lowATender(i18n) {
    return <>
        <h4 id="Atender">{i18n.t('a1-or-low-tender-mindedness')}</h4>
        <ReactMarkdown>{i18n.t('you-tend-to-have-little-interest-in-other-peoples-problems-nor-to-think-about-those-in-need-or-less-fortunate-than-you-in-some-situations-you-may-even-believe-that-people-should-fend-for-themselves-because-of-this-you-may-tend-to-dislike-soft-hearted-people-who-seem-weak-because-of-their-overly-concern-about-others-circumstances')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-usually-feel-comfortable-challenging-others-even-when-it-means-competing-with-them-rather-than-collaborating-with-them-while-being-unapologetic-for-your-competitive-sometimes-harsh-or-belittling-behavior')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('when-others-have-spited-you-you-may-engage-in-an-eye-for-an-eye-behaviors-which-can-make-you-seem-unsympathetic')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('it-is-impossible-to-suffer-without-making-someone-pay-for-it-every-complaint-already-contains-revenge')}</ReactMarkdown>
            <ReactMarkdown>**- Friedrich Nietzsche**</ReactMarkdown>
        </div>
    </>;
}

function lowAAltruism(i18n) {
    return <>
        <h4 id="Aaltruism">{i18n.t('a2-or-low-altruism')}</h4>
        <ReactMarkdown>{i18n.t('you-tend-to-be-indifferent-to-the-feelings-of-others-and-take-little-time-to-help-them-preferring-to-see-to-your-own-needs-first-at-times-you-may-even-look-down-on-others')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-may-sometimes-believe-and-act-like-your-own-happiness-and-success-are-more-important-than-those-of-others-even-when-it-means-turning-your-back-on-others-who-need-your-help')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('i-believe-all-suffering-is-caused-by-ignorance-people-inflict-pain-on-others-in-the-selfish-pursuit-of-their-happiness-or-satisfaction-yet-true-happiness-comes-from-a-sense-of-inner-peace-and-contentment-which-in-turn-must-be-achieved-through-the-cultivation-of-altruism-of-love-and-compassion-and-elimination-of-ignorance-selfishness-and-greed')}</ReactMarkdown>
            <ReactMarkdown>**- Dalai Lama**</ReactMarkdown>
        </div>
    </>;
}

function lowATrust(i18n) {
    return <>
        <h4 id="Atrust">{i18n.t('a3-or-low-trust')}</h4>
        <ReactMarkdown>{i18n.t('you-have-a-hard-time-trusting-people-or-may-even-be-wary-of-them-suspecting-hidden-motive-in-their-actions-and-words-you-tend-to-believe-that-people-are-essentially-self-interested-and-therefore-stay-suspicious-of-their-every-move')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('the-more-two-people-open-to-each-other-the-more-this-wide-openness-also-brings-to-the-surface-all-the-obstacles-to-it-their-deepest-darkest-wounds-their-desperation-and-mistrust-and-their-rawest-emotional-trigger-points-just-as-the-suns-warmth-causes-clouds-to-arise-by-prompting-the-earth-to-release-its-moisture-so-loves-pure-openness-activates-the-thick-clouds-of-our-emotional-wounding-the-tight-places-where-we-are-shut-down-where-we-live-in-fear-and-resist-love')}</ReactMarkdown>
            <ReactMarkdown>**- John Welwood**</ReactMarkdown>
        </div>
    </>;
}

function lowACompliance(i18n) {
    return <>
        <h4 id="Acompliance">{i18n.t('a4-or-low-compliance')}</h4>
        <ReactMarkdown>{i18n.t('you-are-not-afraid-of-confrontations-and-conflicts-in-some-instances-you-may-even-enjoy-a-good-fight-this-means-that-you-tend-to-challenge-and-contradict-others-even-when-it-means-having-a-sharp-tongue-and-openly-criticizing-what-people-have-done-beware-that-your-assertive-nature-will-not-escalade-into-yelling-insults-or-your-outright-snapping-at-people')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('this-also-means-that-you-may-tend-to-hold-a-grudge-when-someone-does-you-wrong-and-may-even-seek-to-get-back-at-people-and-get-revenge-for-their-misdeeds')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('there-is-nothing-i-love-as-much-as-a-good-fight')}</ReactMarkdown>
            <ReactMarkdown>**- Franklin D. Roosevelt**</ReactMarkdown>
        </div>
    </>;
}

function lowAModesty(i18n) {
    return <>
        <h4 id="Amodesty">{i18n.t('a5-or-low-modesty')}</h4>
        <ReactMarkdown>{i18n.t('you-tend-to-be-proud-of-who-you-are-and-what-youve-achieved-talking-with-pride-about-your-achievements-because-you-learned-the-answers-to-many-questions-you-are-more-likely-going-to-think-highly-of-yourself-openly-sharing-your-thoughts-and-therefore-making-yourself-the-center-of-attention-doesnt-bother-you')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('keep-in-mind-that-others-may-perceive-this-as-you-boasting-about-your-virtues-and-believing-that-you-are-better-than-them')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('vanity-and-pride-are-different-things-though-the-words-are-often-used-synonymously-a-person-may-be-proud-without-being-vain-pride-relates-more-to-our-opinion-of-ourselves-vanity-to-what-we-would-have-others-think-of-us')}</ReactMarkdown>
            <ReactMarkdown>**- Jane Austen**</ReactMarkdown>
        </div>
    </>;
}

function lowAStraightforwardness(i18n) {
    return <>
        <h4 id="Astraightforwardness">{i18n.t('a6-or-low-straightforwardness')}</h4>
        <ReactMarkdown>{i18n.t('sometimes-the-ends-justify-the-means-this-means-that-you-may-have-to-do-whatever-it-takes-to-achieve-what-you-desire-even-when-it-means-getting-around-some-rules-in-some-circumstances-you-may-obstruct-others-plans-take-advantage-of-them-use-flattery-or-pressure-them-for-your-own-ends')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('keep-in-mind-that-people-may-want-to-get-back-to-you-for-treating-them-unfairly-and-that-your-reputation-may-suffer-from-these-extreme-behaviors-what-goes-around-comes-around')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('entrepreneurs-are-simply-those-who-understand-that-there-is-little-difference-between-obstacle-and-opportunity-and-are-able-to-turn-both-into-their-advantage')}</ReactMarkdown>
            <ReactMarkdown>**- Niccolo Machiavelli**</ReactMarkdown>
        </div>
    </>;
}

function lowA(i18n, aSubStyle: ASubstyle) {
    return (
        <div className="trait-low">
            <h3>{i18n.t('you-have-low-agreeableness-levels')}</h3>

            <div className="myimghor">
                <img src={disagreementimg} alt="Low agreeableness disagreement" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('disagreements-are-a-part-of-life')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@ketut-subiyanto">Ketut
                    Subiyanto</a> from <a
                        href="https://www.pexels.com/photo/upset-young-indian-couple-after-conflict-4308057/"
                    >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('in-situations-where-the-stakes-are-high-you-may-become-more-pragmatic-how-people-feel-may-be-less-important-to-you-than-solving-the-problem-or-getting-to-the-right-answer')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('honest-disagreement-is-often-a-good-sign-of-progress')}</ReactMarkdown>
                <ReactMarkdown>**- Mahatma Gandhi**</ReactMarkdown>
            </div>

            <h3>{i18n.t('your-6-agreeableness-facets')}</h3>

            <ReactMarkdown>{i18n.t('agreeableness-can-be-broken-down-into-six-facets-which-describe-your-levels-of-compassion-and-politeness')}</ReactMarkdown>

            <ol>
                <li>{i18n.t('tender-mindedness-describes-your-attitude-of-sympathy-for-others')}</li>
                <li>{i18n.t('altruism-describes-your-active-concern-for-the-welfare-of-others')}</li>
                <li>{i18n.t('trust-describes-your-beliefs-in-the-sincerity-and-good-intentions-of-others')}</li>
                <li>{i18n.t('compliance-describes-your-response-to-interpersonal-conflict')}</li>
                <li>{i18n.t('modesty-describes-your-tendency-to-play-down-your-own-achievements-and-to-be-humble')}</li>
                <li>{i18n.t('straightforwardness-describes-your-frankness-in-expression')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('discover-your-6-agreeableness-facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.TenderMindedness, lowATender(i18n), highATender(i18n)) : lowATender(i18n)}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Altruism, lowAAltruism(i18n), highAAltruism(i18n)) : lowAAltruism(i18n)}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Trust, lowATrust(i18n), highATrust(i18n)) : lowATrust(i18n)}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Compliance, lowACompliance(i18n), highACompliance(i18n)) : lowACompliance(i18n)}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Modesty, lowAModesty(i18n), highAModestly(i18n)) : lowAModesty(i18n)}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Straightforwardness, lowAStraightforwardness(i18n), highAStraightforwardness(i18n)) : lowAStraightforwardness(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />


            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('your-practical-and-skeptical-nature-may-be-perceived-as-harsh-almost-robot-like-because-of-your-detachment-from-how-others-feel')}</ReactMarkdown>

            <h4>{i18n.t('self-development-advice')}</h4>
            <ReactMarkdown>{i18n.t('solving-challenges-is-crucial-in-life-and-at-work-but-you-need-to-remember-that-you-are-interacting-with-human-beings-when-their-feelings-get-hurt-some-people-may-disengage-or-sabotage-you-and-your-work')}</ReactMarkdown>
        </div>
    );
};

function lowNAnger(i18n) {
    return <>
        <h4 id="Nanger">{i18n.t('n1-or-low-anger-irritability')}</h4>
        <ReactMarkdown>{i18n.t('you-are-usually-adept-at-keeping-your-emotions-under-control-and-prone-to-keeping-a-cool-head-in-heated-situations-when-facing-stress-and-conflict')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-rarely-get-annoyed-upset-irritated-or-mad-at-people-or-during-situations-which-dont-go-your-way-instead-you-tend-to-stoically-attend-to-your-duties-without-complaining-about-your-misfortunes')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('a-moment-of-patience-in-a-moment-of-anger-prevents-a-thousand-moments-of-regret')}</ReactMarkdown>
            <ReactMarkdown>**- Ali inn Abi Talib**</ReactMarkdown>
        </div>
    </>;
}

function lowNImpulsivness(i18n) {
    return <>
        <h4 id="Nimpulsiveness">{i18n.t('n2-or-low-impulsiveness')}</h4>
        <ReactMarkdown>{i18n.t('your-calm-nature-enables-you-to-resist-temptation-easily-and-efficiently-controlling-your-cravings-rarely-overindulging-and-never-spending-more-than-you-can-afford-means-that-you-will-be-able-to-persist-and-keep-pursuing-what-you-desire')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('the-impatient-man-is-his-own-enemy-he-slams-the-door-on-his-own-progress')}</ReactMarkdown>
            <ReactMarkdown>**- Idries Shah**</ReactMarkdown>
        </div>
    </>;
}

function lowNAnxiety(i18n) {
    return <>
        <h4 id="Nanxiety">{i18n.t('n3-or-low-anxiety')}</h4>
        <ReactMarkdown>{i18n.t('you-tend-to-feel-relaxed-most-of-the-time-and-not-to-be-easily-bothered-by-events-you-only-rarely-feel-stressed-and-worried-usually-feeling-calm-and-even-tempered')}</ReactMarkdown>
        <ReactMarkdown>{i18n.t('you-easily-adapt-to-new-situations-and-face-challenges-with-ease-you-dont-worry-about-things-that-have-already-happened-and-rarely-get-absorbed-in-reflecting-on-the-mistakes-you-did-in-the-past')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('worry-does-not-empty-tomorrow-of-its-sorrow-it-empties-today-of-its-strength')}</ReactMarkdown>
            <ReactMarkdown>**- Corrie Ten Boom**</ReactMarkdown>
        </div>
    </>;
}

function lowNDepression(i18n) {
    return <>
        <h4 id="Ndepression">{i18n.t('n4-or-low-depression')}</h4>
        <ReactMarkdown>{i18n.t('you-tend-to-experience-life-in-a-calm-stable-comfortable-and-satisfying-way-rarely-experiencing-ups-and-downs-and-deep-feelings-of-sadness-since-you-often-feel-like-your-life-has-purpose-you-tend-to-feel-comfortable-secure-and-pleased-with-yourself')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('storms-make-trees-take-deeper-roots')}</ReactMarkdown>
            <ReactMarkdown>**- Dolly Parton**</ReactMarkdown>
        </div>
    </>;
}

function lowNSelfConsciousness(i18n) {
    return <>
        <h4 id="Nselfconsciousness">{i18n.t('n5-or-low-self-consciousness')}</h4>
        <ReactMarkdown>{i18n.t('you-feel-self-confident-and-are-able-to-stand-up-for-yourself-difficult-social-situations-do-not-bother-you-nor-do-unfamiliar-situations-make-you-uncomfortable-its-difficult-to-make-you-feel-embarrassed')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('the-man-who-moves-a-mountain-begins-by-carrying-away-small-stones')}</ReactMarkdown>
            <ReactMarkdown>**- Confucius**</ReactMarkdown>
        </div>
    </>;
}

function lowNVulnerability(i18n) {
    return <>
        <h4 id="Nvulnerability">{i18n.t('n6-or-low-vulnerability')}</h4>
        <ReactMarkdown>{i18n.t('you-are-able-to-stay-calm-even-in-tense-situations-and-unfazed-by-setbacks-in-life-your-ability-to-stay-calm-under-pressure-allows-you-to-handle-complex-problems-and-to-cope-with-stress')}</ReactMarkdown>

        <div className="quote">
            <ReactMarkdown>{i18n.t('equanmity-is-calamitys-medicine')}</ReactMarkdown>
            <ReactMarkdown>**- Publilius Syrus**</ReactMarkdown>
        </div>
    </>;
}

function lowN(i18n, nSubStyle: NSubstyle) {
    return (
        <div className="trait-low">
            <h3>{i18n.t('you-have-low-nervosity-levels')}</h3>

            <div className="myimghor">
                <img src={relaxedimg} alt="Low nervosity relaxation" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('how-can-you-stay-calm-and-at-peace-within-the-storm')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@mateusz-dach-99805">Mateusz
                    Dach</a> from <a href="https://www.pexels.com/photo/pair-of-red-and-white-low-top-sneakers-914929/"
                    >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('as-a-low-nervosity-person-you-are-more-likely-able-to-keep-a-clear-mind-in-difficult-situations-which-makes-you-invaluable-at-solving-crises-while-others-may-panic-and-struggle-to-see-the-light-at-the-end-of-the-tunnel-your-calmness-will-allow-you-to-reassure-them-and-guide-them')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('you-should-also-be-better-able-to-consistently-refrain-from-experiencing-extreme-mood-swings-as-well-as-staying-more-optimistic-and-believing-that-everything-will-work-out-in-the-end')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('life-is-10-what-you-experience-and-90-how-you-respond-to-it')}</ReactMarkdown>
                <ReactMarkdown>**- Dorothy M. Neddermeyer**</ReactMarkdown>
            </div>

            <h3>{i18n.t('your-6-nervosity-facets')}</h3>

            <ReactMarkdown>{i18n.t('nervosity-can-be-broken-down-into-six-facets-which-describe-your-levels-of-emotional-volatility-and-withdrawal')}</ReactMarkdown>

            <ol>
                <li>{i18n.t('anger-irritability-describes-your-tendency-to-experience-anger-frustration-bitterness-and-resentment')}</li>
                <li>{i18n.t('impulsiveness-describes-your-tendency-to-act-on-cravings-and-urges-rather-than-reining-them-in-and-delaying-gratification')}</li>
                <li>{i18n.t('anxiety-describes-your-levels-of-free-floating-anxiety-that-is-experiencing-fear-and-anxiety-in-everyday-situations-without-being-able-to-identify-any-real-observable-threat')}</li>
                <li>{i18n.t('depression-describes-your-tendency-to-experience-crushing-feelings-of-sadness-guilt-despondency-and-loneliness')}</li>
                <li>{i18n.t('self-consciousness-describes-your-shyness-or-social-anxiety')}</li>
                <li>{i18n.t('vulnerability-describes-your-general-susceptibility-to-stress')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('discover-your-6-nervosity-facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Anger, lowNAnger(i18n), highNAnger(i18n)) : lowNAnger(i18n)}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Impulsiveness, lowNImpulsivness(i18n), highNImpulsivness(i18n)) : lowNImpulsivness(i18n)}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Anxiety, lowNAnxiety(i18n), highNAnxiety(i18n)) : lowNAnxiety(i18n)}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Depression, lowNDepression(i18n), highNDepression(i18n)) : lowNDepression(i18n)}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.SelfConsciousness, lowNSelfConsciousness(i18n), highNSelfConsioussness(i18n)) : lowNSelfConsciousness(i18n)}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Vulnerability, lowNVulnerability(i18n), highNVulnerability(i18n)) : lowNVulnerability(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />


            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('people-with-higher-levels-of-nervosity-tend-to-focus-on-worst-case-scenarios-and-may-look-up-to-your-tendency-to-focus-on-what-is-going-right-rather-than-what-is-going-wrong-support-and-inspire-them')}</ReactMarkdown>

            <h4>{i18n.t('self-development-advice')}</h4>
            <ReactMarkdown>{i18n.t('while-your-ability-to-stay-calm-is-valuable-during-difficult-times-some-people-may-perceive-you-as-an-unemotional-or-cold-person-they-may-perceive-your-poise-and-stoic-ability-to-be-more-relaxed-about-your-busyness-as-an-ability-to-absorb-more-work-be-careful-that-you-dont-take-on-too-many-responsibilities-at-the-same-time-that-you-will-not-be-able-to-effectively-manage')}</ReactMarkdown>

        </div>
    );
};

function med(high, low) {
    return <div>
        {high}
        {low}
    </div>
}

function medO(i18n, oSubStyle: OSubstyle) {
    return (
        <div className="trait-med">
            <section id="Odescription" className="offset-anchor">
            <h3>{i18n.t('you-have-medium-openness-levels')}</h3>
            <ReactMarkdown>{i18n.t('as-a-medium-openness-person-your-inclinations-may-vary-from-situation-to-situation-one-influencing-factor-could-be-how-comfortable-you-feel-at-any-given-time')}</ReactMarkdown>

            <h3>{i18n.t('your-high-openness-levels')}</h3>

            <div className="myimghor">
                <img src={adventureimg} alt="High openness adventure" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('what-will-be-your-new-adventure')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@alex-tim-249063">Alex Tim</a> from <a
                    href="https://www.pexels.com/photo/woman-hiking-1183986/">Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('you-tend-to-be-excited-about-discovering-new-activities-trying-new-experiences-learning-new-skills-and-exploring-new-opportunities-in-other-words-you-usually-enjoy-the-thrill-of-the-unknown-and-challenging-the-status-quo-what-got-you-here-will-not-get-you-there-why-not-go-on-an-adventure')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('a-mind-is-like-a-parachute-it-doesnt-work-if-it-is-not-open')}</ReactMarkdown>
                <ReactMarkdown>**- Frank Zappa**</ReactMarkdown>
            </div>

            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('because-of-your-intense-curiosity-and-willingness-to-experiment-and-learn-as-much-as-possible-others-may-perceive-you-as-unfocused-unrealistic-or-as-a-daydreamer')}</ReactMarkdown>

            <h4>{i18n.t('self-development-advice')}</h4>
            <ReactMarkdown>{i18n.t('take-the-time-to-explore-your-passions-and-interests-while-staying-aware-that-others-especially-people-with-lower-levels-of-openness-to-experience-may-feel-like-you-are-going-all-over-the-place-work-on-staying-focused-in-the-present-moment-and-plan-your-experiences')}</ReactMarkdown>


            <h3>{i18n.t('you-have-low-openness-levels')}</h3>

            <div className="myimghor">
                <img src={newspaperimg} alt="Low Openness Routine" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('what-are-your-favorite-routines')}</ReactMarkdown>    
            </div>

        
            <div className="trait-low">
            <h3>{i18n.t('You Have Low Openness Levels')}</h3>

                    <div className="myimghor">
                        <img src={newspaperimg} alt="Low Openness Routine" />
                    </div>

                    <div className="imgcaption">
                        <ReactMarkdown>{i18n.t('What are your favorite routines?')}</ReactMarkdown>
                        <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@elijahsad">Elijah O'Donnell</a> {i18n.t('from')} <a
                            href="https://www.pexels.com/photo/black-man-reading-a-newspaper-3473492/"
                        >Pexels</a></p>
                    </div>

                    <ReactMarkdown>{i18n.t('You tend to prefer sticking to your habits and routines')}</ReactMarkdown>

                    <div className="quote">
                        <ReactMarkdown>{i18n.t('good habits are hard to develop')}</ReactMarkdown>
                        <ReactMarkdown>**- Brian Tracy**</ReactMarkdown>
                    </div>

                    <h3>{i18n.t('How You May Be Perceived')}</h3>
                    <ReactMarkdown>{i18n.t('Your cautious nature may make others')}</ReactMarkdown>

                    <h4>{i18n.t('Self-development advice')}</h4>
                    <ReactMarkdown>{i18n.t('You need to cherish your habits')}</ReactMarkdown>
                    </div>

                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@elijahsad">Elijah O'Donnell</a> {i18n.t('from')} <a
                    href="https://www.pexels.com/photo/black-man-reading-a-newspaper-3473492/"
                >Pexels</a></p>

            <h3>{i18n.t('your-6-openness-to-experience-facets')}</h3>

            <p className="myreportP">{i18n.t('openness-to-experience-can-be-broken-down-into-six-facets-which-explore-how-sensible-and-curious-you-are-to-your-inner-worlds-and-the-world-around-you')}</p>

            <ol>
                <li>{i18n.t('openness-to-ideas-describes-your-intellectual-curiosity')}</li>
                <li>{i18n.t('openness-to-fantasy-describes-your-receptivity-to-the-inner-world-of-imagination-imagination-is-extremely-useful-to-step-outside-of-yourself-and-reflect-on-how-other-people-may-experience-and-perceive-you')}</li>
                <li>{i18n.t('openness-to-aesthetics-describes-your-appreciation-of-art-and-beauty')}</li>
                <li>{i18n.t('openness-to-emotionality-describes-your-ability-and-willingness-to-explore-your-inner-emotions-and-feelings')}</li>
                <li>{i18n.t('openness-to-adventure-and-action-describes-your-openness-to-new-experiences-on-a-practical-level')}</li>
                <li>{i18n.t('openness-to-values-describes-your-readiness-to-challenge-your-own-values-and-those-of-authority-figures')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('Discover Your 6 Openness to Experience Facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Ideas, lowOIdeas(i18n), highOIdeas(i18n)) : lowOIdeas(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Fantasy, lowOFantasy(i18n), highOFantasy(i18n)) : lowOFantasy(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Aesthetics, lowOAesthetics(i18n), highOAesthetics(i18n)) : lowOAesthetics(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Emotionality, lowOEmotionality(i18n), highOEmotionality(i18n)) : lowOEmotionality(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Adventure, lowOAdventure(i18n), highOAdventure(i18n)) : lowOAdventure(i18n)}
                            {oSubStyle ? scoreBasedSubstryle(oSubStyle.Values, lowOValues(i18n), highOValues(i18n)) : lowOValues(i18n)}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />
            </section>
        </div>
    );
};

function medC(i18n, cSubStyle: CSubstyle) {
    return (
        <div className="trait-med">
            <section id="Cdescription" className="offset-anchor">
            <h3>{i18n.t('you-have-medium-conscientiousness-levels')}</h3>
            <ReactMarkdown>{i18n.t('as-a-medium-conscientiousness-person-your-attention-to-details-and-organization-skills-will-depend-on-the-situation-and-your-mental-state')}</ReactMarkdown>

            <h3>{i18n.t('your-high-conscientiousness-levels')}</h3>

            <div className="myimghor">
                <img src={cleandeskimg} alt="High conscientiousness clean desk" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('being-organized-makes-life-easier-right')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@ken-tomita-127057">Ken
                    Tomita</a> {i18n.t('from')} <a href="https://www.pexels.com/photo/architecture-business-clean-computer-389818/"
                    >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('you-tend-to-pay-great-attention-to-details-organize-your-life-meticulously-and-enjoy-feeling-like-you-are-in-control-of-the-situation')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('for-every-minute-spent-organizing-an-hour-is-earned')}</ReactMarkdown>
                <ReactMarkdown>**- Benjamin Franklin**</ReactMarkdown>
            </div>

            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('because-of-your-attention-to-details-and-efforts-for-being-organized-others-especially-people-with-lower-levels-of-conscientiousness-may-perceive-you-as-controlling-rigid-or-as-a-perfectionist')}</ReactMarkdown>

            <h4>{i18n.t('self-development-advice')}</h4>
            <ReactMarkdown>{i18n.t('make-the-most-of-your-strengths-because-they-allow-you-to-be-highly-reliable-and-will-help-you-build-a-positive-reputation-as-someone-people-can-count-on-to-get-things-done')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('stay-aware-especially-when-you-communicate-with-others-that-they-may-likely-not-need-as-much-information-as-you-do-and-may-even-feel-overwhelmed-by-it-start-with-simple-accessible-information-and-move-to-more-accurate-information-if-they-ask-for-more-details-or-clarifications')}</ReactMarkdown>

            <h3>{i18n.t('you-have-low-conscientiousness-levels')}</h3>

            <div className="myimghor">
                <img src={carefreeimg} alt="Low conscientiousness carefree" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('being-carefree-and-easygoing-makes-life-more-enjoyable-right')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@nappy">nappy</a> {i18n.t('from')} <a
                    href="https://www.pexels.com/photo/female-leisure-recreation-relaxation-1154638/"
                >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('you-tend-to-prefer-strategic-thinking-and-keeping-things-simple-avoiding-the-hassle-of-planning-everything-in-excruciating-details')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('perfection-is-achieved-not-when-there-is-nothing-more-to-add-but-when-there-is-nothing-left-to-take-away')}</ReactMarkdown>
                <ReactMarkdown>**- Antoine de Saint-Exupéry**</ReactMarkdown>
            </div>

            <h4>{i18n.t('how-you-may-be-perceived')}</h4>
            <ReactMarkdown>{i18n.t('your-desire-for-being-more-strategic-and-your-ability-to-navigate-uncertainty-with-fewer-information-is-a-strength-to-making-tough-decisions-in-difficult-times')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('however-people-especially-individuals-with-higher-levels-of-conscientiousness-may-start-questioning-how-reliable-you-are-when-you-forget-about-what-they-asked-you-to-do-or-when-you-miss-a-deadline-they-may-look-down-upon-your-tendency-to-forget-important-events-or-discussions-or-on-how-unorganized-it-makes-you-look')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('taking-good-notes-planning-your-priorities-in-advance-and-preparing-for-emergencies-are-simple-steps-you-can-take-to-become-better-prepared-without-having-to-worry-too-much-about-it')}</ReactMarkdown>


            <section id="Cfacets" className="offset-anchor list-short-height">
            <h3>{i18n.t('your-6-conscientiousness-facets')}</h3>

            <ReactMarkdown>{i18n.t('conscientiousness-can-be-broken-down-into-six-facets-which-describe-how-industrious-and-orderly-you-are')}</ReactMarkdown>

            <ol>
                <li>{i18n.t('achievement-striving-describes-your-need-for-personal-achievement-and-sense-of-direction')}</li>
                <li>{i18n.t('self-efficacy-describes-your-beliefs-about-how-well-you-can-execute-courses-of-action-required-to-deal-with-prospective-situations')}<a
                        href="https://www.semanticscholar.org/paper/Self-efficacy-mechanism-in-human-agency.-Bandura/8beec556fe7a650120544a99e9e063eb8fcd987b"
                        className="intlink"> Source</a></li>
                <li>{i18n.t('self-discipline-describes-your-capacity-to-begin-tasks-and-follow-through-to-completion-despite-boredom-or-distractions')}</li>
                <li>{i18n.t('cautiousness-describes-your-tendency-to-think-things-through-before-acting-or-speaking')}</li>
                <li>{i18n.t('dutifulness-describes-how-important-fulfilling-your-moral-obligations-is-to-you')}</li>
                <li>{i18n.t('orderliness-describes-your-ability-to-be-well-organized-and-diligent')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('Discover Your 6 Conscientiousness Facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.AchievementStriving, lowCAchievement(i18n), highCAchievement(i18n)) : med(lowCAchievement(i18n), highCAchievement(i18n))}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.SelfEfficacy, lowCSelfEfficacy(i18n), highCSelfEfficacy(i18n)) : med(lowCSelfEfficacy(i18n), highCSelfEfficacy(i18n))}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.SelfDiscipline, lowCSelfDiscipline(i18n), highCSelfDiscipline(i18n)) : med(lowCSelfDiscipline(i18n), highCSelfDiscipline(i18n))}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.Cautiousness, lowCCautiousness(i18n), highCCautiosness(i18n)) : med(lowCCautiousness(i18n), highCCautiosness(i18n))}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.Dutifulness, lowCDutifulness(i18n), highCDutifulness(i18n)) : med(lowCDutifulness(i18n), highCDutifulness(i18n))}
                            {cSubStyle ? scoreBasedSubstryle(cSubStyle.Orderliness, lowCOrderliness(i18n), highCOrderlisness(i18n)) : med(lowCOrderliness(i18n), highCOrderlisness(i18n))}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>
            </section>
            
            </section>
            <br /> <br />
        </div>
    );
};

function medE(i18n, eSubStyle: ESubstyle) {
    return (
        <div className="trait-med">

            <h3>{i18n.t('you-have-medium-extroversion-levels')}</h3>
            <ReactMarkdown>{i18n.t('as-an-ambivert-or-medium-extroversion-person-how-you-deal-with-people-may-vary-from-situation-to-situation-one-influencing-factor-could-be-how-you-are-feeling-at-any-given-time')}</ReactMarkdown>


            <h3>{i18n.t('your-high-extroversion-levels')}</h3>

            <div className="myimghor">
                <img src={partyimg} alt="High extroversion party time" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('life-is-a-party')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@cottonbro" className="intlink">cottonbro</a> from <a
                    href="https://www.pexels.com/photo/people-toasting-wine-glasses-3171837/"
                    className="intlink">Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('as-an-extrovert-you-enjoy-social-interactions-which-energize-you-initiating-conversations-getting-to-know-other-people-and-sharing-about-you-excites-you-you-tend-to-work-better-in-groups-than-alone-and-to-be-more-actively-engaged-with-the-group')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('i-have-social-disease-i-have-to-go-out-every-night-if-i-stay-home-one-night-i-start-spreading-rumours-to-my-dogs')}</ReactMarkdown>
                <ReactMarkdown>**- Andy Warhol**</ReactMarkdown>
            </div>

            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('your-natural-energy-during-in-person-interactions-usually-make-others-perceive-you-as-cheerful-energetic-and-optimistic-your-upbeat-attitude-and-tendency-to-smile-to-show-your-enjoyment-can-be-contagious')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('keep-in-mind-that-your-enthusiasm-to-initiate-contact-share-about-yourself-and-inquire-about-others-lives-may-be-perceived-as-attention-seeking-and-intrusive-especially-for-people-with-lower-levels-of-extroversion-give-people-enough-space-if-they-seem-uncomfortable-with-sharing-about-themselves-or-learning-more-about-you')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('interesting-fact-extroverts-tend-to-fill-their-environments-with-trinkets-to-make-them-more-inviting')}</ReactMarkdown>

            <h3>{i18n.t('you-have-low-extroversion-levels')}</h3>

            <div className="myimgvert">
                <img src={readingimg} alt="Low extroversion quiet time" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('we-all-need-some-quiet-time-to-recharge')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@daria">Daria Shevtsova</a> from <a
                    href="https://www.pexels.com/photo/woman-sitting-while-reading-a-book-1616779/"
                >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('as-an-introvert-you-tend-to-prefer-one-on-one-interactions-and-evolving-in-groups-of-three-to-four-people-you-may-dislike-small-talk-have-difficulties-initiating-conversations-getting-to-know-other-people-and-sharing-private-information-about-yourself-that-being-said-once-you-trust-someone-the-walls-usually-slowly-come-down')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('quiet-people-have-the-loudest-minds')}</ReactMarkdown>
                <ReactMarkdown>**- Stephen Hawking**</ReactMarkdown>
            </div>

            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('your-tendency-to-do-your-best-thinking-alone-and-getting-back-to-the-group-after-in-person-interactions-may-make-others-especially-people-with-higher-levels-of-extroversion-perceive-you-as-distant-sometimes-even-cold')}</ReactMarkdown>


            <h3>{i18n.t('your-6-extroversion-facets')}</h3>

            <ReactMarkdown>{i18n.t('extroversion-can-be-broken-down-into-six-facets-which-describe-your-levels-of-enthusiasm-and-assertiveness')}</ReactMarkdown>

            <ol>
                <li>{i18n.t('gregariousness-describes-your-preference-for-the-company-of-others-over-solitude')}</li>
                <li>{i18n.t('cheerfulness-describes-your-tendency-to-experience-and-spread-positive-emotions')}</li>
                <li>{i18n.t('warmth-describes-your-interest-in-and-friendliness-towards-others')}</li>
                <li>{i18n.t('excitement-seeking-describes-your-need-for-environmental-stimulation')}</li>
                <li>{i18n.t('activity-level-describes-your-pace-of-living')}</li>
                <li>{i18n.t('assertiveness-describes-your-social-ascendancy-and-forcefulness-of-expression')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('Discover Your 6 Extroversion Facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Gregariousness, lowEGragariousness(i18n), highEEgragariousness(i18n)) : med(highEEgragariousness(i18n), lowEGragariousness(i18n))}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Cheerfulness, lowECheerfulness(i18n), highECheerfulness(i18n)) : med(highECheerfulness(i18n), lowECheerfulness(i18n))}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Warmth, lowEWarmth(i18n), highEWarmth(i18n)) : med(highEWarmth(i18n), lowEWarmth(i18n))}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.ExcitementSeeking, lowEExcitement(i18n), highEExcitement(i18n)) : med(highEExcitement(i18n), lowEExcitement(i18n))}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.ActivityLevel, lowEActivity(i18n), highEActivity(i18n)) : med(highEActivity(i18n), lowEActivity(i18n))}
                            {eSubStyle ? scoreBasedSubstryle(eSubStyle.Assertiveness, lowEAssertivness(i18n), highEAssertivness(i18n)) : med(highEAssertivness(i18n), lowEAssertivness(i18n))}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />
        </div>
    );
};

function medA(i18n, aSubStyle: ASubstyle) {
    return (
        <div className="trait-med">

            <h3>{i18n.t('you-have-medium-agreeableness-levels')}</h3>
            <ReactMarkdown>{i18n.t('as-a-medium-agreeableness-person-you-can-express-signs-of-both-low-agreeableness-and-high-agreeabelness-depending-on-the-situation-and-or-the-people-you-are-interacting-with')}</ReactMarkdown> <br />

            <h3>{i18n.t('your-high-agreeableness-levels')}</h3>

            <div className="myimghor">
                <img src={trustimg} alt="High agreeableness trust" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('trust-makes-anything-possible')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@pixabay">Pixabay</a> from <a
                    href="https://www.pexels.com/photo/hands-people-friends-communication-45842/"
                >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('in-situations-where-you-feel-safe-and-at-ease-you-will-care-about-others-and-easily-trust-them-you-will-encourage-people-to-come-to-you-for-help-and-avoid-confrontations')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('when-you-are-kind-to-others-it-not-only-changes-you-it-changes-the-world')}</ReactMarkdown>
                <ReactMarkdown>**- Harold Kushner**</ReactMarkdown>
            </div>

            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('your-radiant-enthusiasm-may-be-exploited-by-some-people-make-sure-that-you-dont-forget-to-protect-your-own-self-interests-when-needed-by-trying-to-please-everyone-at-all-costs-saying-yes-by-default-even-when-you-dont-mean-it-you-may-be-perceived-as-a-pushover-and-be-taken-advantage-of-your-caring-nature')}</ReactMarkdown>

            <h4>{i18n.t('self-development-advice')}</h4>
            <ReactMarkdown>{i18n.t('learn-to-speak-up-and-defend-what-you-truly-stand-for-think-of-ways-to-create-win-win-situations-instead-of-allowing-people-to-walk-over-you-to-get-what-they-want')}</ReactMarkdown>

            <h3>{i18n.t('you-have-low-agreeableness-levels')}</h3>

            <div className="myimghor">
                <img src={disagreementimg} alt="Low agreeableness disagreement" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('disagreements-are-a-part-of-life')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@ketut-subiyanto">Ketut
                    Subiyanto</a> from <a
                        href="https://www.pexels.com/photo/upset-young-indian-couple-after-conflict-4308057/"
                    >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('in-situations-where-the-stakes-are-high-you-may-become-more-pragmatic-how-people-feel-may-be-less-important-to-you-than-solving-the-problem-or-getting-to-the-right-answer')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('honest-disagreement-is-often-a-good-sign-of-progress')}</ReactMarkdown>
                <ReactMarkdown>**- Mahatma Gandhi**</ReactMarkdown>
            </div>

            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('your-practical-and-skeptical-nature-may-be-perceived-as-harsh-almost-robot-like-because-of-your-detachment-from-how-others-feel')}</ReactMarkdown>

            <h4>{i18n.t('self-development-advice')}</h4>
            <ReactMarkdown>{i18n.t('solving-challenges-is-crucial-in-life-and-at-work-but-you-need-to-remember-that-you-are-interacting-with-human-beings-when-their-feelings-get-hurt-some-people-may-disengage-or-sabotage-you-and-your-work')}</ReactMarkdown>


            <h3>{i18n.t('your-6-agreeableness-facets')}</h3>

            <ReactMarkdown>{i18n.t('agreeableness-can-be-broken-down-into-six-facets-which-describe-your-levels-of-compassion-and-politeness')}</ReactMarkdown>

            <ol>
                <li>{i18n.t('tender-mindedness-describes-your-attitude-of-sympathy-for-others')}</li>
                <li>{i18n.t('altruism-describes-your-active-concern-for-the-welfare-of-others')}</li>
                <li>{i18n.t('trust-describes-your-beliefs-in-the-sincerity-and-good-intentions-of-others')}</li>
                <li>{i18n.t('compliance-describes-your-response-to-interpersonal-conflict')}</li>
                <li>{i18n.t('modesty-describes-your-tendency-to-play-down-your-own-achievements-and-to-be-humble')}</li>
                <li>{i18n.t('straightforwardness-describes-your-frankness-in-expression')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('discover-your-6-agreeableness-facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.TenderMindedness, lowATender(i18n), highATender(i18n)) : med(highATender(i18n), lowATender(i18n))}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Altruism, lowAAltruism(i18n), highAAltruism(i18n)) : med(highAAltruism(i18n), lowAAltruism(i18n))}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Trust, lowATrust(i18n), highATrust(i18n)) : med(highATrust(i18n), lowATrust(i18n))}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Compliance, lowACompliance(i18n), highACompliance(i18n)) : med(highACompliance(i18n), lowACompliance(i18n))}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Modesty, lowAModesty(i18n), highAModestly(i18n)) : med(highAModestly(i18n), lowAModesty(i18n))}
                            {aSubStyle ? scoreBasedSubstryle(aSubStyle.Straightforwardness, lowAStraightforwardness(i18n), highAStraightforwardness(i18n)) : med(highAStraightforwardness(i18n), lowAStraightforwardness(i18n))}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />
        </div>
    );
};

function medN(i18n, nSubStyle: NSubstyle) {
    return (
        <div className="trait-med">

            <h3>{i18n.t('You Have Medium Nervosity Levels')}</h3>
            <ReactMarkdown>{i18n.t('as-a-medium-nervosity-person-you-can-express-signs-of-both-low-nervosity-and-high-nervosity-depending-on-the-situation-and-or-the-people-you-are-interacting-with')}</ReactMarkdown> <br />

            <h3>{i18n.t('your-high-nervosity-levels')}</h3>

            <div className="myimghor">
                <img src={worry1img} alt="High nervousness anxiety" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('life-is-full-of-ups-and-downs-filled-with-trials-for-us-to-overcome')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@katlovessteve">Kat Jayne</a> from <a
                    href="https://www.pexels.com/photo/adult-alone-anxious-black-and-white-568027/"
                >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('as-a-high-nervosity-person-you-are-likely-a-worrier-someone-who-experiences-intense-emotions-you-may-reminisce-over-the-past-and-worry-about-whats-ahead-and-what-others-think-of-you-you-may-be-unconsciously-looking-for-the-challenges-and-downsides-of-everyday-situations-some-people-might-call-this-pessimism-but-it-likely-has-to-do-with-your-need-to-search-for-multiple-scenarios-and-see-how-you-can-address-them')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('we-suffer-more-often-from-imagination-than-from-reality')}</ReactMarkdown>
                <ReactMarkdown>**- Seneca**</ReactMarkdown>
            </div>

            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('your-tendency-to-worry-about-future-challenges-is-valuable-because-it-helps-you-to-cover-potential-scenarios-and-prepare-for-the-worst-worrying-about-others-well-being-also-helps-you-to-be-seen-as-a-caring-person')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('however-some-people-may-perceive-you-as-being-insecure-or-anxious-to-avoid-making-things-looking-only-pessimistic-you-can-leverage-your-tendency-to-think-of-possible-scenarios-to-also-consider-what-could-go-right')}</ReactMarkdown>

            <h4>{i18n.t('self-development-advice')}</h4>
            <ReactMarkdown>{i18n.t('your-emotions-are-a-strength-and-are-contagious-you-need-to-learn-to-recognize-them-when-they-are-setting-in-to-avoid-experiencing-extreme-mood-swings-and-to-become-better-able-at-soothing-them-doing-so-is-a-sign-of-higher-emotional-intelligence-focusing-on-both-self-awareness-and-self-development')}</ReactMarkdown>

            <h3>{i18n.t('you-have-low-nervosity-levels')}</h3>

            <div className="myimghor">
                <img src={relaxedimg} alt="Low nervosity relaxation" />
            </div>

            <div className="imgcaption">
                <ReactMarkdown>{i18n.t('how-can-you-stay-calm-and-at-peace-within-the-storm')}</ReactMarkdown>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@mateusz-dach-99805">Mateusz
                    Dach</a> from <a href="https://www.pexels.com/photo/pair-of-red-and-white-low-top-sneakers-914929/"
                    >Pexels</a></p>
            </div>

            <ReactMarkdown>{i18n.t('as-a-low-nervosity-person-you-are-more-likely-able-to-keep-a-clear-mind-in-difficult-situations-which-makes-you-invaluable-at-solving-crises-while-others-may-panic-and-struggle-to-see-the-light-at-the-end-of-the-tunnel-your-calmness-will-allow-you-to-reassure-them-and-guide-them')}</ReactMarkdown>
            <ReactMarkdown>{i18n.t('you-should-also-be-better-able-to-consistently-refrain-from-experiencing-extreme-mood-swings-as-well-as-staying-more-optimistic-and-believing-that-everything-will-work-out-in-the-end')}</ReactMarkdown>

            <div className="quote">
                <ReactMarkdown>{i18n.t('life-is-10-what-you-experience-and-90-how-you-respond-to-it')}</ReactMarkdown>
                <ReactMarkdown>**- Dorothy M. Neddermeyer**</ReactMarkdown>
            </div>

            <h3>{i18n.t('how-you-may-be-perceived')}</h3>
            <ReactMarkdown>{i18n.t('people-with-higher-levels-of-nervosity-tend-to-focus-on-worst-case-scenarios-and-may-look-up-to-your-tendency-to-focus-on-what-is-going-right-rather-than-what-is-going-wrong-support-and-inspire-them')}</ReactMarkdown>

            <h4>{i18n.t('self-development-advice')}</h4>
            <ReactMarkdown>{i18n.t('while-your-ability-to-stay-calm-is-valuable-during-difficult-times-some-people-may-perceive-you-as-an-unemotional-or-cold-person-they-may-perceive-your-poise-and-stoic-ability-to-be-more-relaxed-about-your-busyness-as-an-ability-to-absorb-more-work-be-careful-that-you-dont-take-on-too-many-responsibilities-at-the-same-time-that-you-will-not-be-able-to-effectively-manage')}</ReactMarkdown>


            <h3>{i18n.t('your-6-nervosity-facets')}</h3>

            <ReactMarkdown>{i18n.t('nervosity-can-be-broken-down-into-six-facets-which-describe-your-levels-of-emotional-volatility-and-withdrawal')}</ReactMarkdown>

            <ol>
                <li>{i18n.t('anger-irritability-describes-your-tendency-to-experience-anger-frustration-bitterness-and-resentment')}</li>
                <li>{i18n.t('impulsiveness-describes-your-tendency-to-act-on-cravings-and-urges-rather-than-reining-them-in-and-delaying-gratification')}</li>
                <li>{i18n.t('anxiety-describes-your-levels-of-free-floating-anxiety-that-is-experiencing-fear-and-anxiety-in-everyday-situations-without-being-able-to-identify-any-real-observable-threat')}</li>
                <li>{i18n.t('depression-describes-your-tendency-to-experience-crushing-feelings-of-sadness-guilt-despondency-and-loneliness')}</li>
                <li>{i18n.t('self-consciousness-describes-your-shyness-or-social-anxiety')}</li>
                <li>{i18n.t('vulnerability-describes-your-general-susceptibility-to-stress')}</li>
            </ol>

            <br /> <br />

            <section className="accordion-width">
            <Accordion>
                <Card className="border-0">
                    <Accordion.Toggle eventKey="0" className="d-flex justify-content-center accordion-toggle align-items-center">
                    <b className="mr-3">{i18n.t('discover-your-6-nervosity-facets')}</b>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Anger, lowNAnger(i18n), highNAnger(i18n)) : med(highNAnger(i18n), lowNAnger(i18n))}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Impulsiveness, lowNImpulsivness(i18n), highNImpulsivness(i18n)) : med(highNImpulsivness(i18n), lowNImpulsivness(i18n))}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Anxiety, lowNAnxiety(i18n), highNAnxiety(i18n)) : med(highNAnxiety(i18n), lowNAnxiety(i18n))}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Depression, lowNDepression(i18n), highNDepression(i18n)) : med(highNDepression(i18n), lowNDepression(i18n))}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.SelfConsciousness, lowNSelfConsciousness(i18n), highNSelfConsioussness(i18n)) : med(highNSelfConsioussness(i18n), lowNSelfConsciousness(i18n))}
                            {nSubStyle ? scoreBasedSubstryle(nSubStyle.Vulnerability, lowNVulnerability(i18n), highNVulnerability(i18n)) : med(highNVulnerability(i18n), lowNVulnerability(i18n))}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </section>

            <br /> <br />
        </div>
    );
};


export const content = {
    "Openness": {
        intro: introO,
        mismatch: mismatchO,
        questions: questionsO,
        workwithhigh: workwithhighO,
        workwithlow: workwithlowO,
        profile: {
            low: (i18n, o) => lowO(i18n, o),
            medium: (i18n, o) => medO(i18n, o),
            high: (i18n, o) => highO(i18n, o),
        }
    },
    "Conscientiousness": {
        intro: introC,
        mismatch: mismatchC,
        questions: questionsC,
        workwithhigh: workwithhighC,
        workwithlow: workwithlowC,
        profile: {
            low: (i18n, c) => lowC(i18n, c),
            medium: (i18n, c) => medC(i18n, c),
            high: (i18n, c) => highC(i18n, c)
        }
    },
    "Extroversion": {
        intro: introE,
        mismatch: mismatchE,
        questions: questionsE,
        workwithhigh: workwithhighE,
        workwithlow: workwithlowE,
        profile: {
            low: (i18n, e) => lowE(i18n, e),
            medium: (i18n, e) => medE(i18n, e),
            high: (i18n, e) => highE(i18n, e)
        }
    },
    "Agreeableness": {
        intro: introA,
        mismatch: mismatchA,
        questions: questionsA,
        workwithhigh: workwithhighA,
        workwithlow: workwithlowA,
        profile: {
            low: (i18n, a) => lowA(i18n, a),
            medium: (i18n, a) => medA(i18n, a),
            high: (i18n, a) => highA(i18n, a)
        }
    },
    "Nervosity": {
        intro: introN,
        mismatch: mismatchN,
        questions: questionsN,
        workwithhigh: workwithhighN,
        workwithlow: workwithlowN,
        profile: {
            low: (i18n, n) => lowN(i18n, n),
            medium: (i18n, n) => medN(i18n, n),
            high: (i18n, n) => highN(i18n, n)
        }
    }
}

export default content;
