import React from 'react';

function WhatIsMyOceanity(props: any) {
    return (
        <div>
            <div className="overlay">
            </div>
            <video className="bgvideo" playsInline autoPlay muted loop>
                <source src="mp4/bg.mp4" type="video/mp4"/>
            </video>
            <div className="masthead position-relative">
                <div className="text-white">
                    <h1 className="mb-3">What is MyOceanity?</h1>
                    <p className="mb-4">MyOceanity helps people realize their potential by growing their emotional intelligence.</p>
                    <p> How? By leveraging two powerful tools: </p>
                    <p className="mb-4">1. Taking personality traits assessments, which will provide personal advice on how to take
                        better care of themselves and of their relationships</p>
                    <p className="mb-5">2. A continuously growing learning and development course, focused on emotional intelligence,
                        psychology and personal development</p>
                </div>
            </div>
        </div>
    );
}

export default WhatIsMyOceanity;
