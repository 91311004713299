import React from 'react';
import { Button, Card, CardDeck, } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from "@auth0/auth0-react";


function WhatWeDo(props: any) {
	const { loginWithRedirect } = useAuth0()
	
	const iconList = Object
	.keys(Icons)
	.filter(key => key !== "fas" && key !== "prefix" )
	.map(icon => Icons[icon])
  
  	library.add(...iconList)

	return (
		<div className="masthead">
			<h1>What We Do</h1>
				
			<h2>What is MyOceanity?</h2>
			<div className="notabene">
				MyOceanity helps you realize your potential by growing your emotional intelligence.
			</div>
			
			<div className="separator"></div>

			<CardDeck>
                    
				<Card className="mb-2 mycardwrapper">
					<Card.Body className="text-center mycard">
						<a href="/user/premium/my-assessments"> <FontAwesomeIcon icon="clipboard-check" size="6x" className="card-icon" /> </a>
						<Card.Title><a href="/user/premium/my-assessments" className="cardlink stretched-link"><h5 className="cardtitle card-btn">Assess your personality traits</h5></a></Card.Title>
						<Card.Text className="cardtext">
							<p>Who are you? What makes you tick?</p>
							<p>How are your personality traits affecting others? How can you improve?</p>
						</Card.Text>
					</Card.Body>
				</Card>
			
				<Card className="mb-2 mycardwrapper">
					<Card.Body className="text-center mycard">
							<a href="/user/premium/send-invite"> <FontAwesomeIcon icon="users" size="6x" className="card-icon" /> </a>
							<Card.Title><a href="/user/premium/send-invite" className="cardlink stretched-link"><h5 className="cardtitle card-btn">Invite and compare to others</h5></a></Card.Title>
							<Card.Text className="cardtext">
								<p>How similar and different are you?</p>
								<p>How can you better understand and interact with each other?</p>
							</Card.Text>
					</Card.Body>
				</Card>
				
				<Card className="mb-2 mycardwrapper">
					<Card.Body className="text-center mycard">
						<a href="/ei-course"> <FontAwesomeIcon icon="chalkboard-teacher" size="6x" className="card-icon" /> </a>
						<Card.Title><a href="/ei-course" className="cardlink stretched-link"><h5 className="cardtitle card-btn">Go to the online course</h5></a></Card.Title>
						<Card.Text className="cardtext">
							<p>Join our learning & development program</p>
							<p>Study at your own pace, anytime, anywhere, without any distractions</p>
						</Card.Text>
					</Card.Body>
				</Card>

			</CardDeck>
			
			<div className="separator"></div>

			<p><b>Please note that you first need to complete your personality traits assessment to compare it to people you invited or who have invited you.</b></p>


			<h2>Why did we create MyOceanity?</h2>	
			<div className="notabene">
			Because we want to spread emotional intelligence with as many people as we can <br />
			Because it can make a difference in everyone’s lives <br />
			Because it helped me heal and recover from my burnout
			</div>
			
			<p>I was privileged to participate in multiple enriching trainings during my 5 years at Johnson & Johnson, some of which <em>many</em> other employees could not join.</p>
			<p>After a few years, I was shocked when I realized a large proportion of people <em>inside</em> the company were missing out on several life-changing ideas. Then, I also realized that an overwhelming number of people outside the company were missing out on them as well.</p>
			<p>Therefore, I wanted to change things and create MyOceanity: to spread these ideas with as many people as possible. Because I believe we need to take steps to <b>reduce the gaps between the elite and the rest of the world</b>.</p>
			
			<div className="input-group">
				<div className="input-group-append content-btn-box">
							<a href="/why-ei-matters" className="content-btn">Learn why emotional intelligence matters</a>
					</div>
			</div>


			<h2>How does MyOceanity work?</h2>	
			
			<div className="separator"></div>
			
            <CardDeck>
                
				<Card className="mb-2 mycardwrapper">
					<Card.Body className="text-center mycard">
						<FontAwesomeIcon icon="user-plus" size="6x" className="card-icon" />
						<Card.Title><Button className="card-btn-signup" onClick={() => loginWithRedirect()}><p>Create an account</p></Button></Card.Title>
						<Card.Text className="cardtext">
							<p>Your personal and comparison profiles will be saved in your account</p>
                            <p>You can take as many as you want!</p>
						</Card.Text>
					</Card.Body>
				</Card>
			
				<Card className="mb-2 mycardwrapper">
					<Card.Body className="text-center mycard">
						<FontAwesomeIcon icon="shopping-cart" size="6x" className="card-icon" />
						<Card.Title><h5 className="cardtitle card-btn-disabled">Activate your account</h5></Card.Title>
						<Card.Text className="cardtext">
							<p>You will be redirected to our payment provider during your first assessment</p>
							<p><b>USD 20 for lifetime access</b></p>
						</Card.Text>
					</Card.Body>
				</Card>
				
				<Card className="mb-2 mycardwrapper">
					<Card.Body className="text-center mycard">
						<FontAwesomeIcon icon="thumbs-up" size="6x" className="card-icon" />
						<Card.Title><h5 className="cardtitle card-btn-disabled">That's it!</h5></Card.Title>
						<Card.Text className="cardtext">
							<p>Enjoy the full MyOceanity experience! Learn, grow, and realize your potential</p>
                            <div className="white-text"><p> Hello</p></div>
						</Card.Text>
					</Card.Body>
				</Card>

			</CardDeck>

			<div className="separator"></div>
			
			<p>We want to centralize research on emotional intelligence, and make it practical, to enable you to learn by doing and to improve your life and the lives of those around you. Why? Because we couldn't find an accessible, one-stop shop holding the key lessons we learned. So we built it!</p>
			<p>MyOceanity’s body of knowledge constantly grows and improves, thanks to our community. Your support and feedback helps us to become better.</p>
			
			<div className="notabene">
				With everything we do, we try to follow the snowball principle:<br />
				<b>Something small grows larger, given enough time and effort.</b>

				<div className="separator" />
				
				<FontAwesomeIcon icon="sync" size="2x" spin />
				
				<div className="separator" />
			</div>

			<h2>Why a pay-for-use account?</h2>
			<div className="notabene">
				Because we want to offer you the best experience possible without any external influence from third-party organizations.
			</div>
			
			<p>We believe ads intrude enough in our lives and keep us feeling like we are not enough and that we need to buy more things to feel happy. We disagree.</p>
			<p>We want to create a richer, more focused experience for your personal and social growth. No distractions. No manipulations. Only learning and development.</p>
			<p>Therefore, we decided to opt for a pay-for-use model, where the MyOceanity community makes our work possible, to spread emotional intelligence around the world.</p>
			<p>If you are a student, currently without a job, live in a developing country or face any other financial difficulties that prevent you from being able to afford the USD 20 activation fee, please <a href="/contact" className="intlink">contact us</a> so we can find an arrangement together.</p>


			<h2>How are we giving forward?</h2>
			<div className="notabene">
				Contributing to the advancement of healthcare and education around the world is what drives us. <br />
				It’s part of our Mission, to preserve and improve human life, doing well by doing good.
			</div>
			
			<p>Therefore, we pledge to give away 10% of our profits and 80% of your donations to charities that help the less fortunate in developing countries. Hoping that one day, they will be able to live lives like ours.</p>
			<p>We also pledge to provide free access to people in the world’s poorest countries and communities, thanks to the generous support of our community.</p>
			<p>We are grateful to <a href="https://givewell.org" className="intlink">GiveWell.org</a> for their efforts in helping us find the most effective charities, which save or improve lives the most per dollar.</p>
			
			<div className="separator"></div>
			<FontAwesomeIcon icon="hand-holding-heart" size="6x" className="small-icon" />

			<h2>Your data, your life, your choice</h2>
			<p>We guarantee that your personal information, including your answers to any assessment, will not be communicated nor sold to third-party organization.</p>
			<p>You are free to delete your results if you want to. The choice is yours. To gain back access to your personal reports and comparisons with people you invited, all you need to do is take another assessment. These functionalities will not work without a completed assessment.</p>

			<div className="separator"></div>
			<Icon.ShieldLock className="small-icon"/>

			<div className="separator"></div>
		</div>
	)
}
export default WhatWeDo;

