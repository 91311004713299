import React from 'react';
import { Table } from 'react-bootstrap';
import emotionscollage from "../images/emotions-collage.jpg";
import elementsofei from "../images/elements-of-ei.png";


function EmotionalLiteracy(props: any) {
	   
  return (
   <div className="masthead">
			<h1>How to Grow Your Emotional Literacy</h1>
			
			<div className="separator"></div>
			<div className="separator"></div>

			<div className="myimg">
			<img src={emotionscollage} alt="Palette of emotions" />
			</div>

			<div className="imgcaption">
			<p>We all experience a rich palette of emotions</p>
			<p>Photo by <a href="https://www.pexels.com/@olly?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels" className="intlink">Andrea Piacquadio</a> from <a href="https://www.pexels.com/photo/collage-photo-of-woman-3812743/?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels" className="intlink">Pexels</a></p>
			</div>

			<p>Words matter. An essential part of developing our emotional intelligence is to become aware of the emotions we are feeling and to name them properly. Why? Because different emotions require different strategies to be productively expressed and leveraged.</p>
			<p>We need to recognize our own emotions, through Self-Awareness, as well as those of other people, through Social Awareness. Doing so will help us to develop ourselves and our relationships, for everyone's benefit.</p>
			<p>In doing so, we are practicing and developing the four elements of emotional intelligence, as represented in the following image.</p>
			
			<div className="myimghor">
			<img src={elementsofei} alt="The 4 elements of emotional intelligence" />
			</div>

			<div className="imgcaption">
				<p>The 4 elements of emotional intelligence</p>
			</div>

			<p>A great way to start growing our emotional literacy is to consider the five core emotions that make up most of what we feel every day:</p>
			
			<ol>
				<li><b>Joy</b></li>
				<li><b>Anger</b></li>
				<li><b>Fear</b></li>
				<li><b>Sadness</b></li>
				<li><b>Shame</b></li>
			</ol>
			
			<p>The next step is to consider the intensity of these core emotions. From them, we can give color and better express how we are feeling.</p>
			
			<div className="quote">
			<p>Think, "I feel ____." or "I am ____."</p>
			</div>

			<p>Julia West calls this intensity of emotions, which can be summarized as follows.</p>
			
			<div className="separator"></div>

			<section className="accordion-width">
			<Table striped borderless hover>
				<thead>
					<tr>
					<th className="course-table-header-medium">Intensity <br /> of emotion</th>
					<th className="course-table-header-medium">Joy</th>
					<th className="course-table-header-medium">Anger</th>
					<th className="course-table-header-medium">Fear</th>
					<th className="course-table-header-medium">Sadness</th>
					<th className="course-table-header-medium">Shame</th>
					</tr>
				</thead>

				<tbody className="table-emotions-body">
					<tr>
						<td><b>High</b></td>
						<td>Excited</td>
						<td>Furious</td>
						<td>Terrified</td>
						<td>Depressed</td>
						<td>Sorrowful</td>
					</tr>
					<tr>
						<td> </td>
						<td>Thrilled</td>
						<td>Enraged</td>
						<td>Horrified</td>
						<td>Disappointed</td>
						<td>Remorseful</td>
					</tr>
					<tr>
						<td> </td>
						<td>Ecstatic</td>
						<td>Outraged</td>
						<td>Petrified</td>
						<td>Alone</td>
						<td>Ashamed</td>
					</tr>
					<tr>
						<td> </td>
						<td>Passionate</td>
						<td>Infuriated</td>
						<td>Frantic</td>
						<td>Hurt</td>
						<td>Worthless</td>
					</tr>
					<tr>
						<td> </td>
						<td>Delighted</td>
						<td>Betrayed</td>
						<td>Panicky</td>
						<td>Crushed</td>
						<td>Wicked</td>
					</tr>
					<tr>
						<td> </td>
						<td>Happy</td>
						<td>Livid</td>
						<td>Shocked</td>
						<td>Hopeless</td>
						<td> </td>
					</tr>
					<tr>
						<td><b>Medium</b></td>
						<td>Moved</td>
						<td>Upset</td>
						<td>Anxious</td>
						<td>Left out</td>
						<td>Unworthy</td>
					</tr>
					<tr>
						<td> </td>
						<td>Cheerful</td>
						<td>Mad</td>
						<td>Threatened</td>
						<td>Heartbroken</td>
						<td>Guilty</td>
					</tr>
					<tr>
						<td> </td>
						<td>Relieved</td>
						<td>Frustrated</td>
						<td>Insecure</td>
						<td>Lost</td>
						<td>Embarrassed</td>
					</tr>
					<tr>
						<td> </td>
						<td>Motivated</td>
						<td> </td>
						<td>Uneasy</td>
						<td>Melancholy</td>
						<td>Apologetic</td>
					</tr>
					<tr>
						<td> </td>
						<td>Good</td>
						<td> </td>
						<td>Intimidated</td>
						<td> </td>
						<td> </td>
					</tr>
					<tr>
						<td><b>Low</b></td>
						<td>Glad</td>
						<td>Irritated</td>
						<td>Nervous</td>
						<td>Blue</td>
						<td>Regretful</td>
					</tr>
					<tr>
						<td> </td>
						<td>Content</td>
						<td>Annoyed</td>
						<td>Worried</td>
						<td>Sorry</td>
						<td>Uncomfortable</td>
					</tr>
					<tr>
						<td> </td>
						<td>Pleased</td>
						<td>Fed up</td>
						<td>Unsure</td>
						<td>Dissatisfied</td>
						<td>Pitied</td>
					</tr>
					<tr>
						<td> </td>
						<td>Satisfied</td>
						<td> </td>
						<td>Cautious</td>
						<td> </td>
						<td> </td>
					</tr>
				</tbody>
			</Table>
			</section>
			
			<div className="separator"></div>

			<p>When asking others to describe how they are feeling, you should try to help them frame their emotions by focusing on the core five emotions, rather than a more specific description.</p>
			<p>Why? Because we may use different words to describe the same core emotions. Although it is important for them to use their own words to describe how they are feeling, it is also important for you to make sure you understand what core emotion is moving them.</p>
			<p>You need to listen with the intent to understand how they are feeling, not with the assumption that you already know.</p>
			
			<div className="quote"> 
			<p><i>When you <b>ASSUME</b>,</i></p>
			<p><i>You make an <b>ASS</b> of <b>U</b> and <b>ME</b>.</i></p>
			</div>

			<p>Likewise, when you are describing how you are feeling to other people, focus on the five core emotions. While your words may be less nuanced, the message you will communicate will be clearer for them to understand.</p>
			
			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>Emotional intelligence is a crucial key to living a richer, more meaningful life filled with strong relationships.</p>
			<p>The best way to become more emotionally literate is to practice, by making the words you use to describe how you are feeling your own. Remember, words matter.</p>
			<p>There are two ways you can do this:</p>
			<p>First, download and take a look at this document and highlight the words you identify the most with.</p>
			
			<p>Download placeholder: Emotional intensity worksheet</p>
			
			<p>Second, download one of the following blank templates and use your own words to define the different emotions you usually feel, based on their levels of intensity. There is a version for Microsoft Word and another for Apple Pages.</p>
			
			<p>Download placeholder: Apple Pages template</p>
			<p>Download placeholder: Microsoft Word template</p>
			
			<p>Learning about and developing your emotional intelligence is a journey. I will be long, challenging, and necessary. It will also be worth it: Developing lasting, mutually beneficial relationships filled with trust will improve your life and career, as well as those of everyone around you.</p>
			<p>This second module deals with the importance of emotions. Here are other topics you can explore:</p>
			
			<Table striped borderless hover responsive className="course-table">
				<thead>
					<tr><th className="course-table-header">Module 2: The Importance Of Emotions</th></tr>
				</thead>

				<tbody className="course-table-body">
					<tr> <td> <a href="/core-emotions">What are our core emotions?</a></td> </tr>
					<tr> <td> <a href="/reading-emotions">How can you better read and understand how other people are feeling? (WIP)</a></td> </tr>
				</tbody>
			</Table>

			<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
            </div>
			
			<div className="separator"></div>
			<div className="separator"></div>
   </div>
  )
}
export default EmotionalLiteracy;

