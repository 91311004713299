import React from 'react';
import {Col, Container, ProgressBar, Row} from 'react-bootstrap';
import {ResultProps} from '../components/types';
import StyleResult from '../components/StyleResult'
import AttributeCloud from '../components/AttributeCloud';
import { useTranslation } from "react-i18next";
import Scrollspy from 'react-scrollspy';

import * as Icon from 'react-bootstrap-icons';
import opennesstoexperience from '../images/openness-to-experience.png';



const wording = (i18n) => {
    return {
        OPENNESS: {
            lowTitle: i18n.t('Low Openness'),
            lowSubTitle: i18n.t('openness.low.Consistent'),
            lowAttributes: [i18n.t('openness.low.- Practical'), i18n.t('openness.low.- Conventional'), i18n.t('openness.low.- Prefers routine'), i18n.t('openness.low.- Consistent')],
            highTitle: i18n.t('openness.high.High Openness'),
            highSubTitle: i18n.t('openness.high.Inventive'),
            highAttributes: [i18n.t('openness.high.- Curious'), i18n.t('openness.high.- Inventive'), i18n.t('openness.high.- Wide range of interest')],
        },
        CONSCIENTIOUSNESS: {
            lowTitle: i18n.t('Low Conscientiousness'),
            lowSubTitle: i18n.t('Easygoing'),
            lowAttributes: [i18n.t('conscientiousness.low.- Impulsive'), i18n.t('conscientiousness.low.- Careless'), i18n.t('conscientiousness.low.- Disorganized'), i18n.t('conscientiousness.low- Easygoing')],
            highTitle: i18n.t('High Conscientiousness'),
            highSubTitle: i18n.t('conscientiousness.high.Efficient'),
            highAttributes: [i18n.t('conscientiousness.high.- Dependable'), i18n.t('conscientiousness.high.- Organized'), i18n.t('conscientiousness.high.- Efficient'), i18n.t('conscientiousness.high.- Hard working')],
        },
        EXTROVERSION: {
            lowTitle: i18n.t('Low Extroversion'),
            lowSubTitle: i18n.t("extroversion.low.Reserved"),
            lowAttributes: [i18n.t("extroversion.low.- Quiet"), i18n.t("extroversion.low.- Reserved"), i18n.t("extroversion.low.- Withdrawn")],
            highTitle: i18n.t('High Extroversion'),
            highSubTitle: i18n.t("extroversion.high.Energetic"),
            highAttributes: [i18n.t("extroversion.high.- Outgoing"), i18n.t("extroversion.high.- Warm"), i18n.t("extroversion.high- Energetic"), i18n.t("extroversion.high.- Seek Adventure")],
        },
        AGREEABLENESS: {
            lowTitle: i18n.t('Low Agreeableness'),
            lowSubTitle: i18n.t('agreeableness.low.Detached'),
            lowAttributes: [i18n.t('agreeableness.low.- Critical'), i18n.t('agreeableness.low.- Uncooperative'), i18n.t('agreeableness.low.- Suspicious'), i18n.t('agreeableness.low.- Detached')],
            highTitle: i18n.t('High Agreeableness'),
            highSubTitle: i18n.t('agreeableness.high.Compassionate'),
            highAttributes: [i18n.t('agreeableness.high.- Helpful'), i18n.t('agreeableness.high.- Trusting'), i18n.t('agreeableness.high.- Empathetic'), i18n.t('agreeableness.high.- Compassionate')],
        },
        NERVOSITY: {
            lowTitle: i18n.t('Low Nervosity'),
            lowSubTitle: i18n.t('nervosity.low.Confident'),
            lowAttributes: [i18n.t('nervosity.low.- Calm'), i18n.t('nervosity.low.- Tempered'), i18n.t('nervosity.low.- Secured'), i18n.t('nervosity.low.- Confident')],
            highTitle: i18n.t('High Nervosity'),
            highSubTitle: i18n.t('nervosity.high.Sensitive'),
            highAttributes: [i18n.t('nervosity.high.- Sensitive'), i18n.t('nervosity.high.- Anxious'), i18n.t('nervosity.high.- Prone to negative emotions')],
        }
    }
}

function lowMediumHigh(score: number) {
    if (score >= 55) {
        return "high"
    }
    if (score <= 45) {
        return "low"
    }
    return "medium"
}

function Result(props: ResultProps) {
    const {i18n} = useTranslation();

    const chartData = [
        {style: 'O', score: props.quizResult.OPENNESS.score},
        {style: 'C', score: props.quizResult.CONSCIENTIOUSNESS.score},
        {style: 'E', score: props.quizResult.EXTROVERSION.score},
        {style: 'A', score: props.quizResult.AGREEABLENESS.score},
        {style: 'N', score: props.quizResult.NERVOSITY.score}
    ];

    return (
        <div id="myres" className="border">
	        <body className="masthead" data-spy="scroll" data-offset="60" data-target="#myNavbar">
            
            <Scrollspy items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5', 'section-6' ] } currentClassName="is-current" className="sticky-top scrollspy overflow-scroll-horizontal">
				<li><a href="#section-1" className="scrollspy-link"><Icon.ChevronUp className="mr-2 circled" />{i18n.t('top')}</a></li>
				<li><a href="#section-2" className="scrollspy-link">{i18n.t('OPENNESS')}</a></li>
				<li><a href="#section-3" className="scrollspy-link">{i18n.t('conscientiousness ')}</a></li>
				<li><a href="#section-4" className="scrollspy-link">{i18n.t('extroversion ')}</a></li>
				<li><a href="#section-5" className="scrollspy-link">{i18n.t('agreeableness ')}</a></li>
				<li><a href="#section-6" className="scrollspy-link">{i18n.t('nervosity ')}</a></li>
			</Scrollspy>
            
            <h1>{i18n.t('Your OCEAN Personality Traits')}</h1>
            <br/>
            <br/>

            <section id="section-1" className="offset-anchor">
            <br/>
            <p>{i18n.t('Dear')} <b>{props.username}</b>,</p>
            <p>{i18n.t('Thank you for taking...')}</p>
            <p>{i18n.t('This report...')}</p>
            <p>{i18n.t('Growing as a person')}</p>
            <p>{i18n.t('This OCEAN Personality Report will also inform you')}</p>
            <p>{i18n.t('It will take you through the five traits')}</p>
            
            <ul>
                <li>{i18n.t('Tendencies you may usually have')}</li>
                <li>{i18n.t('How others may perceive you')}</li>
                <li>{i18n.t('Examples of Personality Traits misunderstandings')}</li>
                <li>{i18n.t('How to work well with different Personality Traits')}</li>
                <li>{i18n.t('Questions to assess each Personality Trait')}</li>
            </ul>

            <div className="notabene">
                <p><b>{i18n.t('It is crucial to understand')}</b></p>
                <p><b>{i18n.t('What matters is understanding how our differences')}</b>
                    <b>{i18n.t('How they make us stronger, together')}</b></p>
            </div>

            <p>{i18n.t('After reading your profile, you will be able to')}</p>

            <div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/user/premium/send-invite" className="content-btn">{i18n.t('Button.InviteSomeone')}<Icon.ArrowRight className="ml-2" /></a>
                </div>
            </div>

            <h2>{i18n.t('Your Big Five Traits at a Glance')}</h2>
            <p className="text-center">
                <b>{i18n.t('Please note that clicking any of the traits will take you directly to its description!')}</b>
            </p>

            <Container className="responsive-width ocean-overview">
                <Row className="my-report-row mb-2">
                    <Col className="my-report-col-title"><h3 className="myreporth3"><a href="#section-2"
                                                                                                   className="report-btn-a">O</a>
                    </h3></Col>
                    <Col className="col-10"><ProgressBar className="reportprogressbar"
                                                         now={props.quizResult.OPENNESS.score}
                                                         label={`${props.quizResult.OPENNESS.score}%`}/></Col>
                </Row>

                <Row className="my-report-row mb-2">
                    <Col className="my-report-col-title"><h3 className="myreporth3"><a href="#section-3"
                                                                                       className="report-btn-a">C</a>
                    </h3></Col>
                    <Col className="col-10"><ProgressBar className="reportprogressbar"
                                                         now={props.quizResult.CONSCIENTIOUSNESS.score}
                                                         label={`${props.quizResult.CONSCIENTIOUSNESS.score}%`}/></Col>
                </Row>


                <Row className="my-report-row mb-2">
                    <Col className="my-report-col-title"><h3 className="myreporth3"><a href="#section-4"
                                                                                       className="report-btn-a">E</a>
                    </h3></Col>
                    <Col className="col-10"><ProgressBar className="reportprogressbar"
                                                         now={props.quizResult.EXTROVERSION.score}
                                                         label={`${props.quizResult.EXTROVERSION.score}%`}/></Col>
                </Row>

                <Row className="my-report-row mb-2">
                    <Col className="my-report-col-title"><h3 className="myreporth3"><a href="#section-5"
                                                                                       className="report-btn-a">A</a>
                    </h3></Col>
                    <Col className="col-10"><ProgressBar className="reportprogressbar"
                                                         now={props.quizResult.AGREEABLENESS.score}
                                                         label={`${props.quizResult.AGREEABLENESS.score}%`}/></Col>
                </Row>

                <Row className="my-report-row mb-2">
                    <Col className="my-report-col-title"><h3 className="myreporth3"><a href="#section-6"
                                                                                       className="report-btn-a">N</a>
                    </h3></Col>
                    <Col className="col-10"><ProgressBar className="reportprogressbar"
                                                         now={props.quizResult.NERVOSITY.score}
                                                         label={`${props.quizResult.NERVOSITY.score}%`}/></Col>
                </Row>
            </Container>
            </section>

            <div className="separator" />

            <section className="attributecloud">
                <AttributeCloud quizResult={props.quizResult} username={props.username}/>
            </section>

            <div className="three-dots-hr">
                <p>...</p>
            </div>

            <section id="section-2" className="offset-anchor">
            <div className="myimghor">
                    <img src={opennesstoexperience} alt="Openness to experience mismatch" />
            </div>


            <h2 id="Otitle">{i18n.t('Your Openness To Experience')}</h2>

            <StyleResult style='Openness' score={props.quizResult.OPENNESS.score}
                         subStyle={props.quizResult.OPENNESS.subStyles} wording={wording(i18n).OPENNESS}/>
                         
            </section>

            <div className="three-dots-hr">
                <p>...</p>
            </div>

            <section id="section-3" className="offset-anchor">
            <h2 id="Ctitle">{i18n.t('Your Conscientiousness')}</h2>
            <StyleResult style='Conscientiousness' score={props.quizResult.CONSCIENTIOUSNESS.score}
                         wording={wording(i18n).CONSCIENTIOUSNESS}/>
            </section>

            <div className="three-dots-hr">
                <p>...</p>
            </div>

            <section id="section-4" className="offset-anchor">
            <h2 id="Etitle">{i18n.t('Your Extroversion')}</h2>
            <StyleResult style='Extroversion' score={props.quizResult.EXTROVERSION.score}
                         wording={wording(i18n).EXTROVERSION}/>
            </section>

            <div className="three-dots-hr">
                <p>...</p>
            </div>

            <section id="section-5" className="offset-anchor">
            <h2 id="Atitle">{i18n.t('Your Agreeableness')}</h2>
            <StyleResult style='Agreeableness' score={props.quizResult.AGREEABLENESS.score}
                         wording={wording(i18n).AGREEABLENESS}/>
            </section>

            <div className="three-dots-hr">
                <p>...</p>
            </div>

            <section id="section-6" className="offset-anchor">
            <h2 id="Ntitle">{i18n.t('Your Nervosity')}</h2>
            <StyleResult style='Nervosity' score={props.quizResult.NERVOSITY.score} wording={wording(i18n).NERVOSITY}/>
            </section>
            <br/>

            <div className="three-dots-hr">
                <p>...</p>
            </div>

            <h2>{i18n.t('Your Next Steps')}</h2>

            <p>{i18n.t('Now that you gained...')}</p>

            <div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/user/premium/send-invite" className="content-btn">{i18n.t('Button.Invite.LetsGo')}</a>
                </div>
            </div>
            </body>
            <br/> <br/><br/>
        </div>
    );
}

export default Result;

/*    AGREEABLENESS: float
  CONSCIENTIOUSNESS: float
  EXTROVERSION: float
  NERVOSITY: float
  : float*/
