import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {useTranslation} from "react-i18next";
import {Accordion, Alert, Card} from "react-bootstrap";
import Quiz from "./Quiz";
import {InputTags} from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import {useParams} from "react-router-dom";
import Loading from "./Loading";
import {questionsApi} from '../api/_360_questions'
import StepWizard from "react-step-wizard";

import * as Icon from 'react-bootstrap-icons';
import assessmentimg from "../images/assessment.jpg";


function _360AnonymousResponse(props: any) {
    const { i18n } = useTranslation();
    let {signature} = useParams();
    const [linkValid, setLinkValid] = useState(false)
    const [requestedBy, setRequestedBy] = useState("...")
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        //check if this link is still valid :
        fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/360-feedback-ok', {
            method: 'POST',
            body: JSON.stringify({
                signature: signature
            })
        }).then(response => {
            if (response.status === 200) {
                response.json().then(res => {
                    setLinkValid(true);
                    setRequestedBy(res.requested_by);
                    console.log(res);
                })
            } else {
                setLinkValid(false);
            }
            setLoading(false);
        });
    },[])


    const questions = questionsApi[i18n.language]
    const [currentAnswer, setCurrentAnswer] = useState("0")
    const [currentQuestion, setCurrentQuestion] = useState(questions[0].respondentQuestion)
    const [currentQuestionId, setCurrentQuestionId] = useState(questions[0].id)
    const [counter, setCounter] = useState(0)
    const [reportLink, setReportLink] = useState("")
    const [answerState, setAnswerState] = useState(new Map<String, String>())
    const [quizCompleted, setQuizCompleted] = useState(false);

    const answerOptions = ["0","1", "2", "3", "4", "5","6","7"]
    const [emails, setEmails] = useState<string[]>([])

    const setUserAnswer = answer => {
        let newAnswerState = answerState
        newAnswerState.set(questions[counter].id, answer)
        setCurrentAnswer(answer)
        setAnswerState(newAnswerState)
    }
    const setNextQuestion = () => {
        if (counter < questions.length - 1) {
            setCounter(counter + 1)
            setCurrentAnswer('0')
        }
    }
    const getResults = () => {
        setQuizCompleted(true);
    }
    const handleAnswerSelected = event => {
        console.log("answer selected " + event.currentTarget.value)
        setUserAnswer(event.currentTarget.value);
        if (counter < questions.length - 1) {
            setTimeout(() => setNextQuestion(), 500);
        } else {
            setTimeout(() => getResults(), 300);
        }
    }

    const handleBackSelected = () => {
        if (counter > 0) {
            setCounter(counter - 1)
        }
    }

    const sendFeedback = () => {
        setLoading(true)
        const convMap: any = {};
        answerState.forEach((val, key) => {
            convMap[key as string] = val;
        });
        fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/360-feedback-response', {
            method: 'POST',
            body: JSON.stringify({
                signature: signature,
                feedback:convMap
            })
        }).then(response => {
            if (response.status === 200) {
                response.json().then(res => {
                    setLinkValid(false);
                    console.log(res);
                })
            }
            setLoading(false);
        });
    }

    const ThreeSixtyIntro = (props) => {

		return (
			<div className="masthead">
                <h3 className="my-4">Welcome to MyOceanity!</h3>

                <p>The following survey will allow you to provide feedback to {requestedBy}. Thank you for contributing to their learning and development efforts!</p>
                <p>Once you have answered every question, you will be able to submit your feedback. Please note that you can only submit your feedback once. Your answers will not be saved until submission.</p>

				<div className="notabene">
					Your feedback is anonymous, safe, and confidential. {requestedBy} will not be able to trace your answers back to you. Nobody will be able to review them.
				</div>
				
				<div className="accordion-width text-center">
					<Button className="login-btn-big" onClick={props.nextStep}>Start the survey <Icon.ArrowRight className="ml-2"/></Button>
				</div>

				<div className="separator"/>
			</div>
		)
	}


    const meaningMap = (i18n) => new Map(Object.entries({
        "0": {label:i18n.t('0 - I dont know'),hotkey:"0"},
        "1": {label:i18n.t('1 - I strongly disagree'),hotkey:"1"},
        "2": {label:i18n.t('2 - I disagree'),hotkey:"2"},
        "3": {label:i18n.t('3 - I somewhat disagree'),hotkey:"3"},
        "4": {label:i18n.t('4 - I neither agree nor disagree'),hotkey:"4"},
        "5": {label:i18n.t('5 - I somewhat agree'),hotkey:"5"},
        "6": {label:i18n.t('6 - I agree'),hotkey:"6"},
        "7": {label:i18n.t('7 - I strongly agree'),hotkey:"7"}
    }))


    const content = ()=> {
        return (<div>
            {linkValid ? <div>
                <Quiz
                    answer={answerState.get(questions[counter].id) as string}
                    answerOptions={answerOptions}
                    questionId={questions[counter].id}
                    question={questions[counter].respondentQuestion.replaceAll("{USER}",requestedBy)}
                    questionTotal={questions.length}
                    onAnswerSelected={handleAnswerSelected}
                    onBack={handleBackSelected}
                    counter={counter}
                    reportLink={reportLink}
                    meaningMap={meaningMap}
                />
                <Button disabled={!quizCompleted} onClick={()=>sendFeedback()}>{i18n.t('submit-your-feedback')}</Button>
                <div className="separator"/>
                <br />
            </div> : <div>{i18n.t('Thank-you-!-You-have-already-submitted-your-feedback-or')}</div>}</div>)

    }
    return !loading?content():<Loading />;
}

export default _360AnonymousResponse;
