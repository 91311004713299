import React from 'react';
import { Badge, Card, CardDeck, Image } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

import * as Icon from 'react-bootstrap-icons';

import elementsofei from "../images/elements-of-ei.png";
import meyouus from "../images/me-you-us.png";
import intensityofemotions from "../images/intensity-of-emotions.png"
import christraits from "../images/chris-ocean-traits.png";
import ecourseimg from "../images/e-course.jpg";
import axeimg from "../images/axe.jpg";
import heartheadhandshake from "../images/heart-head-handshake.jpg";
import whyeimatters from "../images/emotions.png";
import nurturinggrowth from "../images/nurturing-growth.jpg";
import warmthimg from "../images/warmth.jpg";
import teamworkimg from "../images/teamwork.png";
import workshopimg from "../images/workshop.jpg";
import familyimg from "../images/family.jpg";
import emotionalityimg from "../images/emotionality.png";
import emojisimg from "../images/emojis.jpg";
import readingemotions from "../images/reading-emotions.jpg";
import groupoffriends from "../images/group-of-friends.jpg";
import studyingmaterials from "../images/studying-materials.jpg";
import identitiesimg from "../images/identities-2.jpg";
import objectiveimg from "../images/objective.jpg";
import valuesimg from "../images/o-values.jpg";
import reflectionimg from "../images/reflection.jpg";
import writingimg from "../images/writing.jpg";
import strenghtsweaknessesimg from "../images/strengths-weaknesses.jpg";
import fulfilledimg from "../images/fulfilled.jpg";
import chrispersonalitytraits from "../images/chris-ocean-traits.png";
import mirrorimg from "../images/mirror.jpg";



function EICourse(props: any) {

	const {i18n} = useTranslation();   

  return (
	<div className="masthead">
		
		<div className="separator" />

		<div className="myimghor">
			<img src={ecourseimg} alt="Online course" />
		</div>

		<h2>Course Overview</h2>
		
		<section className="course-tables-center">

			<h3>Module 1: Introduction to Emotional Intelligence</h3>
			
			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={heartheadhandshake} variant="top" className="mb-3" />
					<Card.Title className="m-3">What is emotional intelligence?<Icon.ArrowRight className="ml-2 hover-move-right" /></Card.Title>
					<a href="/what-is-ei" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={whyeimatters} variant="top" className="mb-3" />
					<Card.Title className="m-3">Why emotional intelligence matters<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/why-ei-matters" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={nurturinggrowth} variant="top" className="mb-3" />
					<Card.Title className="m-3">Grow your emotional intelligence skills<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/grow-your-ei" className="stretched-link"></a>
				</Card>
			</CardDeck>


			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={warmthimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">What are the 3 elements of trust?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/elements-of-trust" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={teamworkimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Emotional intelligence at work<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ei-benefits-work" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={workshopimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Strategies for emotional intelligence<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/strategies-for-ei" className="stretched-link"></a>
				</Card>
			</CardDeck>

			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={familyimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Emotional intelligence at home<Icon.ArrowRight className="ml-2" /> <br /> <Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
					<a href="/ei-benefits-home" className="stretched-link"></a>
				</Card>
			</CardDeck>

			<div className="myimg">
				<img src={meyouus} alt="Me, You, Us" />
			</div>

			<div className="imgcaption">
				<p>How can we create an us from you and me? How can we build lasting, mutually beneficial relationships? This is what emotional intelligence is all about.</p>
			</div>


			<section className="justify-content-center d-flex flex-row my-3">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>


			<h3>Module 2: The Importance of Emotions</h3>

			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={emotionalityimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">What are your core emotions?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/core-emotions" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={whyeimatters} variant="top" className="mb-3" />
					<Card.Title className="m-3">How to grow your emotional literacy<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/emotional-literacy" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={readingemotions} variant="top" className="mb-3" />
					<Card.Title className="m-3">How to read emotions on people's faces<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/reading-emotions" className="stretched-link"></a>
				</Card>
			</CardDeck>

			<div className="myimg mb-3">
				<img src={intensityofemotions} alt="The intensity of our core emotions" />
			</div>

			<div className="imgcaption">
				<p>We are moved by 5 core emotions; how can you best describe how you are feeling?</p>
				<p>Adapted from Julia West's "<a href="http://juliahwest.com/prompts/emotion_intensity.html">Intensity of emotions table</a>"</p>
			</div>


			<section className="justify-content-center d-flex flex-row my-3">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>


			<h3>Module 3: OCEAN Personality Traits and Emotional Intelligence</h3>

			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={groupoffriends} variant="top" className="mb-3" />
					<Card.Title className="m-3">What are the Big Five personality traits?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ocean-personality-traits" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={nurturinggrowth} variant="top" className="mb-3" />
					<Card.Title className="m-3">Grow your EI with MyOceanity<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/myoceanity-and-ei" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={studyingmaterials} variant="top" className="mb-3" />
					<Card.Title className="m-3">Learn more about the Big Five Model<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/oceanology" className="stretched-link"></a>
				</Card>
			</CardDeck>

			<div className="separator" />

			<div className="myimg">
				<img src={christraits} alt="Chris' personality traits" />
			</div>

			<div className="imgcaption">
				<p>Here are Chris' personality traits</p>
			</div>

			<Card className="hover-increase" style={{ width: '18rem' }}>
				<Card.Img as={Image} src={mirrorimg} variant="top" className="mb-3" />
				<Card.Title className="m-3">Discover YOUR Big Five personality Traits<Icon.ArrowRight className="ml-2" /></Card.Title>
				<br />
				<a href="/user/premium/my-assessments" className="stretched-link"></a>
			</Card>

			<section className="justify-content-center d-flex flex-row my-3">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>


			<h3>Module 4: Discovering Your Identity</h3>

			<CardDeck className="mt-1 mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={identitiesimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Introduction to the concept of Identity<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/identity-introduction" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={fulfilledimg} variant="top" className="mt-0 mb-3" />
					<Card.Title className="m-3">Fulfillment comes from serving others<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/fulfillment" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={objectiveimg} variant="top" className="mt-0 mb-3" />
					<Card.Title className="m-3">What is your mission in life? Why are you here?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/identity-mission" className="stretched-link"></a>
				</Card>
			</CardDeck>

			<CardDeck className="mt-1 mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={valuesimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">What are your core values? What do you stand for?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/identity-values" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={reflectionimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Who are you? What are your beliefs in life?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/identity-beliefs" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={teamworkimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Ikigai: What makes a life worth living<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ikigai" className="stretched-link"></a>
				</Card>
			</CardDeck>

			<div className="notabene mb-1"> 
				<b>Identity = Mission + Values + Beliefs</b>
			</div>

			<div className="imgcaption mt-0">
				<p>Your identity is defined by your mission, values, and beliefs in life</p>
			</div>


			<section className="justify-content-center d-flex flex-row my-3">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>


			<h3>Module 5: Why Introspection Matters in Emotional Intelligence</h3>

			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={reflectionimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">How journaling helps you become more self-aware<Icon.ArrowRight className="ml-2" /> <br /><br /> <Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
					<a href="/journaling-self-awareness" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={strenghtsweaknessesimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">From self-awareness to self-development<Icon.ArrowRight className="ml-2" /> <br /><br /> <Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
					<a href="/journaling-self-development" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={writingimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">How introspections help you dig towards the core of your self<Icon.ArrowRight className="ml-2" /><br /> <Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
					<a href="/ikigai" className="stretched-link"></a>
				</Card>
			</CardDeck>


			<section className="justify-content-center d-flex flex-row my-3">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>


			<h3>Miscealleneous Ideas</h3>
			<Card className="hover-increase" style={{ width: '18rem' }}>
				<Card.Img as={Image} src={axeimg} variant="top" className="mb-3" />
				<Card.Title className="m-3">Sharpen The Axe: Work Smarter, Not Harder<Icon.ArrowRight className="ml-2" /></Card.Title>
				<a href="/sharpen-the-axe" className="stretched-link"></a>
			</Card>

		</section>


		<section className="justify-content-center d-flex flex-row my-3">
			<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
		</section>


		<h2>About This Course</h2>
		<p>MyOceanity's course represents my life's work and the body of knowledge I have accumulated over the past years. It's a continuously improving project: We review the existing lessons and add new ones each week, so make sure you frequently revisit the course to see how our latest findings can help you learn better! Reviewing the lessons will help you to better memorize them and find how you can apply them during everyday situations.</p>
		<p>The course is primarily written-article-based today, and will also include audio podcasts and video courses to enrich your learning and best match your preferred learning media. You can stay updated with the changes we make to the platform by checking out our updates page, which breaks down what is new and what has changed by week.</p>

		<div className="myimghor">
			<img src={elementsofei} alt="The 4 elements of emotional intelligence" />
		</div>

		<div className="imgcaption">
			<p>The 4 elements of emotional intelligence we will discuss</p>
		</div>


		<section className="justify-content-center d-flex flex-row my-3">
			<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
		</section>


		<h2>Frequently Asked Questions</h2>


		<h3>Q: Is this course free?</h3>
		<p><b>Yes! The MyOceanity course is free for everyone</b>, because it contains ideas worth spreading. It includes worksheets and exercises that will help you reflect and deepen your understanding of emotional intelligence, which will enable you to grow your personal effectiveness and nurture your relationships.</p>


		<h3>Q: What do I get when I join MyOceanity?</h3>
		<p>The USD 20 activation fee allows you to:</p>
		
		<ol>
			<li>Take assessments to discover your style and</li>
			<li>Compare your style to people you know</li>
		</ol>

		<p>Together, the course and these 2 tools were designed to help you grow your emotional intelligence and realize your potential, by taking action on the ideas you will learn about in the course.</p>

			<div className="content-btn-box">
				<a href="/user/premium/my-assessments" className="content-btn">Get Started<Icon.ArrowRight className="ml-2" /></a>
			</div>

		<h3>Q: Do I have to follow the course's curriculum?</h3>
		<p>Not necessarily. We designed the course to make sure that each module could build on each other, however you are free to choose which lesson you want to discover and in which order. Follow your curiosity and interests!</p>
		<p>We want the course to be self-paced, giving you the liberty to choose how quickly or deeply you want to go through it. Plus, you will never lose access to the course, which means you can take as long as you need to revisit it and discuss it with other members of the community. The more lessons and modules you explore, the greater your understanding and “aha” moments.</p>
		<p><b>No start date. No end date. No pressure. Only learning and development.</b></p>


		<h3>Q: What if I struggle to keep up or don't know where to start?</h3>
		<p>You can follow the course curriculum if you don't know where to start. We design the curriculum and the lessons to guide the learners through reflection and experimentation. The worksheets you will find in some lessons will help you gain greater insights and put what you learn into action. </p>
		<p>You may share your lessons learned with the rest of the MyOceanity community. We are all here to help each other and to learn how to become stronger together. You are not alone!</p>

		<div className="separator" />
		<div className="separator" />	
   
   	</div>
  )
}
export default EICourse;

