import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { useTranslation } from "react-i18next";

import comparisonsampleimg from '../images/comparison-sample.png';
import ocomparison from '../images/o-comparison.png';
import ccomparison from '../images/c-comparison.png';
import ecomparison from '../images/e-comparison.png';
import acomparison from '../images/a-comparison.png';
import ncomparison from '../images/n-comparison.png';
import omismatch from '../images/openness-mismatch.png';
import cmismatch from '../images/conscientiousness-mismatch.png';
import emismatch from '../images/extroversion-mismatch.png';
import amismatch from '../images/agreeableness-mismatch.png';
import nmismatch1 from '../images/nervosity-mismatch-1.png';
import nmismatch2 from '../images/nervosity-mismatch-2.png';


function SampleCompareReport() {
	const { i18n } = useTranslation();

        return (
            <div className="masthead"><br/><br/>
                	<section className="grey-section">
                        <h2 className="text-white font-weight-bold">{i18n.t('this-is-what-your-comparison-report-will-look-like-this-sample-report-compares-random-traits-to-chris')}</h2>

                        <div className="input-group">
                            <div className="input-group-append content-btn-box">
                                <a href="/user/premium/my-assessments" className="content-btn-white">{i18n.t('discover-your-traits')}<Icon.ArrowRight className="ml-2" /></a>
                            </div>
                        </div>
                        <div className="separator mb-5" />
                    </section>

                 <h2>You and <b>Chris</b></h2><br/>

                <div className="myimghor">
                    <img src={comparisonsampleimg} alt="Comparing your traits with Chris'" />
                </div>

                <br/>
                <h3><b>Chris</b> cares about </h3>
                <br/>
                <ul style={{textAlign: 'left'}}>
                    <li><b>Openness</b>: What is new</li>
                    <li><b>Conscientiousness</b>: Being carefree and easygoing</li>
                    <li><b>Extroversion</b>: Enjoying a more reserved and solitary life</li>
                    <li><b>Agreeableness</b>: Being benevolent</li>
                    <li><b>Nervosity</b>: Focus on what could go wrong</li>
                </ul>
                <br/>

                <h2><b> OPENNESS TO EXPERIENCE </b></h2>
                <div className="myimghor">
                    <img src={ocomparison} alt="Comparing your openness with Chris''" />
                </div>
                
                <h3>You: Low (27%) <br/> Chris: High (65%)</h3>

                <h3><b>Chris'</b> Openness to Experience Levels</h3>

                <p><b>Chris</b> tends to be excited about:</p>

                <ul>
                    <li>Discovering new activities</li>
                    <li>Trying new experiences</li>
                    <li>Learning new skills</li>
                    <li>Exploring new opportunities</li>
                </ul>

                <p>In other words, <b>Chris</b> usually enjoys the thrill of the unknown and challenging the status quo. Keep in mind <b>Chris</b>' motto:</p>

                <div className="quote">
                    <p><i>"What got you here, will not get you there. Why not go on an adventure?"</i></p>
                </div>

                <h3>How <b>Chris</b> Can Perceive You</h3>
                <p>Your cautious nature may make <b>Chris</b> perceive you as closed-minded or inflexible.</p>
                
                <h4>Self-development advice</h4>
                <p>You need to cherish your habits because they are part of who you are and contribute to your comfort and well-being. That being said, you will sometimes also need to go outside of your comfort zone and discover new activities or ways of doing things. Because <b>you never know what new activity today will become one of your favorite traditions tomorrow</b>.</p>    
        
                <h3>Example of Openness to Experience Misunderstandings</h3>
                <div className="story">
                <p><b>Pauline, a High-Openness manager</b>, has recently taken over Patricia and Gary’s team. Together, the team has discussed how they can improve how they work as individual contributors and as a team.</p>
                <p>Unfortunately, Patricia did not realize that <b>Gary, a strongly Low-Openness person</b>, feels overwhelmed by all the changes she wants to implement. Gary has been with the company for over twenty years and enjoys the routines he created around his responsibilities and his workflow.</p>
                <p>Frustrated by Pauline’s unwillingness to understand how this situation affects him, he starts shutting down and struggles to adjust. Over time, their relationship sours, and his performance slowly declines.</p>
                
                <div className="myimghor">
                    <img src={omismatch} alt="Openness to experience mismatch" />
                </div>
                </div>

                <h3>How to Better Interact with <b>Chris</b></h3>
                <p>
                <ul>
                    <li>Ask what new skill sets or experiences <b>Chris</b> wants to acquire and assign them on relevant projects that match their interests and development plans.</li>
                    <li>Encourage <b>Chris</b>' desire to learn new things and give them enough time and resources to work on expanding their skill sets.</li>
                    <li>Emphasize the benefits or opportunities when pitching <b>Chris</b> to try something new. Then, let them share their thoughts, ideas or concerns to build on your proposal. Let them internalize them and make them their own.</li>
                </ul>
                </p>


                <h2><b> CONSCIENTIOUSNESS </b></h2>
                <div className="myimghor">
                    <img src={ccomparison} alt="Comparing your conscientiousness with Chris''" />
                </div>

                <h3>You: High (67%) <br/> Chris: Low (44%)</h3>

                <h3><b>Chris</b>' Conscientiousness Levels</h3>

                <p><b>Chris</b> tends to:</p>
        
                <ul>
                    <li>Prefer big picture, strategic thinking and keeping things simple</li>
                    <li>Avoid the hassle of planning everything in excruciating details</li>
                </ul>
                
                <h3>How <b>Chris</b> Can Perceive You</h3>
                <p>Because of your attention to details and efforts for being organized, <b>Chris</b> may perceive you as controlling, rigid or as a perfectionist. </p>
                
                <h4>Self-development advice</h4>
                <p>Make the most of your strengths, because they allow you to be highly reliable and will help you build a positive reputation as someone people can count on to get things done.</p>
                <p>Stay aware, especially when you communicate with <b>Chris</b>, that they may likely not need as much information as you do, and may even feel overwhelmed by it. Start with simple, accessible information and move to more accurate information if <b>Chris</b> asks for more details or clarifications.</p>
                
                <h3>Example of Conscientiousness Misunderstandings</h3>
                <div className="story">
                <p><b>Jon, a highly conscientious person</b>, writes a lengthy email filled with details, hoping to reassure and guide <b>Stacy, the low-conscientious intern</b> who recently joined the company.</p>
                <p>As a result, Stacy is overwhelmed, confused, and doesn’t know how or where to start the work Jon asked her to do. Too much information paralyses her.</p>
                <p>At the end of her six months internship, Stacy is frustrated. She was not able to learn as much as she could because of how long it took her to understand and become comfortable with Jon’s communication style and continuous attention to details.</p>
                <div className="myimghor">
                    <img src={cmismatch} alt="Conscientiousness mismatch" />
                </div>
                </div>

                <h3>How to Better Interact with <b>Chris</b></h3>
                <ul>
                    <li><b>Clarify the big goals of each projects as well as your overall expectations</b>. Avoid going into too many details, as too much information will likely overwhelm <b>Chris</b>. Instead, give them a brief summary, which contains the most critical facts.</li>
                    <li>Give <b>Chris</b> the freedom to come up with their own way of doing things to achieve your goals and expectations. <b>Discuss together the next steps to make sure you are aligned and they aren’t missing key steps</b>.</li>
                </ul>

                
                <h2><b> EXTROVERSION </b></h2>
                <div className="myimghor">
                    <img src={ecomparison} alt="Comparing your extroversion with Chris''" />
                </div>

                <h3>You: High (80%) <br/> Chris: Low (42%)</h3>
                
                <h3><b>Chris</b>' Extroversion Levels</h3>

                <p>As an introvert, <b>Chris</b> tends to prefer one-on-one interactions and evolving in groups of three to four people. <b>Chris</b> may:</p>
                <ul>
                    <li>Dislike small talk</li>
                    <li>Have difficulties initiating and continuing conversations</li>
                    <li>Feel uncomfortable when getting to know other people and sharing private information about themselves</li>
                </ul>
                <p>That being said, once <b>Chris</b> trusts someone, the walls usually slowly come down.</p>
                

                <h3>How <b>Chris</b> Can Perceive You</h3>
                <p>Your natural energy during in-person interactions usually makes <b>Chris</b> perceive you as cheerful, energetic, and optimistic. <b>Chris</b> may think that Your upbeat attitude and tendency to smile to show your enjoyment is contagious.</p>
                
                <h4>Self-development advice</h4>
                <p>Keep in mind that your enthusiasm to initiate contact, share about yourself and inquire about <b>Chris</b>' live may be perceived as attention-seeking and intrusive. Give <b>Chris</b> enough space if they seem uncomfortable with sharing about themselves or learning more about you.</p>


                <h3>Example of Extroversion Misunderstandings</h3>
                <div className="story">
                    <p><b>Dana is extremely introverted</b>. Her previous manager, Ellen, highly recommended her because of her ability to gather brilliant ideas and insights during projects.</p>
                    <p>Her new manager, <b>a cheerful extrovert called Philip</b>, is excited about having her on the team and has high expectations about what she will bring to their meetings and projects.</p>
                    <p>Unfortunately, Philip doesn’t understand how terrified Dana feels when he puts her on the spot in front of everyone or why she sometimes acts awkwardly during meetings. She doesn’t dare to speak up about it.</p>
                    <p>Dana misses Ellen, who understood her preference to remain silent during meetings to think, and asked her to share her ideas later, either by email or during one-on-one conversations.</p>
                    <div className="myimghor">
                        <img src={emismatch} alt="Extroversion mismatch"  />
                    </div>
                </div>

                <h3>How to Better Interact with <b>Chris</b></h3>
                
                <ul>
                    <li>Assign <b>Chris</b> to projects with smaller teams and with fewer face-to-face interactions.</li>
                    <li>Give <b>Chris</b> enough time alone to do their thinking and their work.</li>
                    <li>Give <b>Chris</b> enough resources and learning materials to process things at their on pace and on their own.</li>
                    <li>Leverage written communication to let <b>Chris</b> come back to you if they feel uncomfortable sharing their thoughts during your one-on-one conversations.</li>
                    <li>Accept that <b>Chris</b> may come back a few days after your previous conversation to share their thoughts.</li>
                    <li>Accept that <b>Chris</b> may need personal space outside of their desk to work alone, away from the distractions and potentially draining interruptions they could otherwise experience.</li>
                    <li>Unless <b>Chris</b> asks otherwise, praise their personal achievements and team-building efforts during one-on-one sessions rather than putting them on the spot in front of everyone else. They dislike being the center of attention.</li>            
                </ul>

                <p>First, it’s important to address a common misconception: Being shy is not necessarily equal to being an introvert. Research suggests that shyness is a blend between introversion and high-nervosity. Introverts are usually more difficult to approach in larger groups, but they may be more expressive and outgoing in one-on-one interactions or in small groups of a few people. Although it may seem counterintuitive, introverts can know as many people as extroverts do. The difference is that they thrive in smaller groups when connecting with people, while extroverts feel comfortable with engaging wither larger audiences.</p>
                

                <h2><b> AGREEABLENESS </b></h2>
                <div className="myimghor">
                    <img src={acomparison} alt="Comparing your agreeableness with Chris''" />
                </div>
                
                <h3>You: Low (33%) <br/> Chris: High (93%)</h3>
                
                
                <h3><b>Chris</b>' Agreeableness Levels</h3>
                <p>In situations where <b>Chris</b> feels safe and at ease, they will care about others and easily trust them. <b>Chris</b> will encourage people to come to then for help and avoid confrontations.</p>

                
                <h3>How <b>Chris</b> Can Perceive You</h3>
                <p>Your practical and skeptical nature may be perceived as harsh, almost robot-like because of your detachment from how <b>Chris</b> may feel.</p>
                
                <h4>Self-development advice</h4>
                <p>Solving challenges is crucial in life and at work, but you need to remember that you are interacting with human beings. When <b>Chris</b> feelings get hurt, they may disengage or sabotage your work.</p>


                <h3>Example of Agreeableness Misunderstandings</h3>
                <div className="story">
                    <p><b>Highly-agreeable Takeda</b> is stepping in a new role, working with <b>Anja, who is highly skeptical and analytical because of her scientific background</b>. In contrast, Takeda always agrees to everything, even when he’s not sure he fully understood what he was asked to do.</p>
                    <p>His empathetic nature makes it hard to understand why Anja is so distant with him. His desire to please her makes it even harder for him to open up and share his concerns and about the potential misunderstandings of his assignments. He sometimes feels isolated and like he is banging his head against an unbreakable wall.</p>
                    <div className="myimghor">
                        <img src={amismatch} alt="Agreeableness mismatch"  />
                    </div>
                </div>

                <h3>How to Better Interact with <b>Chris</b></h3>
                <ul>
                    <li>Keep in mind <b>Chris</b>' desire to please you. Make sure that when they agree to doing something, they have clearly understood your expectations and know how to get started. If needed, you may ask <b>Chris</b> to write down their plans of action to make sure they will not get stuck and not dare asking you for help, out of fear of conflict.</li>
                    <li>Ask <b>Chris</b> to share the concerns they have and how they are feeling, emphasizing how this matters to you, because they need to feel heard and like they belong to do their best work.</li>
                    <li>During conversations, take time to pause and gather <b>Chris</b>' thoughts and concerns. Emphasize how getting as much information as possible upfront can prevent further unnecessary challenges and misunderstandings from arising.</li>
                    <li>Ask for written thoughts and feedback between your one-on-one conversations to give <b>Chris</b> a safe space to share their ideas, needs, thoughts, and concerns.</li>
                </ul>
                
                <h2><b> NERVOSITY </b></h2>
                <div className="myimghor">
                    <img src={ncomparison} alt="Comparing your nervosity with Chris''" />
                </div>

                <h3>You: Low (42%) <br/> Chris: High (74%)</h3>
                

                <h3><b>Chris</b>' Nervosity Levels</h3>
                <p>As a high-nervosity person, <b>Chris</b> is likely a worrier, someone who experiences intense emotions. <b>Chris</b> may:</p>

                <ul>
                    <li>Reminisce over the past and worry about what’s ahead and what others think of them</li>
                    <li>Be unconsciously looking for the challenges and downsides of everyday situations.</li>
                </ul>

                <p>Some people might call this pessimism, but it likely has to do with <b>Chris</b>' need to search for multiple scenarios and see how they can address them.</p>


                <h3>How <b>Chris</b> Can Perceive You</h3>
                <p>While your ability to stay calm is valuable during difficult times, <b>Chris</b> may perceive you as an unemotional or cold person. They may perceive your poise and stoic ability to be more relaxed about your busyness as an ability to absorb more work.</p>
        
                <h4>Self-development advice</h4>
                <p>Be careful that you don’t take on too many responsibilities at the same time, that you will not be able to effectively manage. People with higher levels of nervosity tend to focus on worst-case scenarios and may look up to your tendency to focus on what is going right rather than what is going wrong. Support and inspire them.</p>

                <h3>Example of Nervosity Misunderstandings</h3>
                <div className="story">
                    <h4>1) Project Management</h4>
                    <p><b>Mary is a chronicle worrier</b>. Her project is stuck and depends on <b>low-nervosity Gwen</b> finishing the tasks she was assigned. Mary sends Gwen concerned emails on a daily basis. This makes Gwen feel so frustrated and pressured that she starts making mistakes, which slows her down even more.</p>
                    <p>After being two weeks behind schedule, she is finally done. She feels relieved and hopes that she will not have to work with Mary anymore.</p>
                    <div className="myimghor">
                        <img src={nmismatch1} alt="Nervosity mismatch example 1"  />
                    </div>

                    <h4>2) Prevent & Correct Mistakes</h4>
                    <p><b>I (Chris)</b> have worked with <b>Andi, a low-nervosity manager</b> who perfectly understood how to make the most of our differences - given that I am a highly-nervous person.</p>
                    <p>He taught me that my tendency to search for the worst-possible scenarios would allow us to prevent them from happening or to correct them, should they still occur. </p>
                    <p>In the world of quality, this is known as CAPA, an acronym which stands for “Corrective Actions & Preventive Actions”:</p>

                    <ul>
                        <li><b>How can we prevent this problem from happening?</b></li>
                        <li><b>How can we correct the damages and learn from our mistakes, to prevent them from occurring again?</b></li>
                    </ul>

                    <p>His calm nature allowed me to channel my strengths, while calming me down in the process. This is a lesson I will never forget!</p>

                    <div className="myimghor">
                        <img src={nmismatch2} alt="Nervosity mismatch example 2"  />
                    </div>
                </div>


                <h3>How to Better Interact with <b>Chris</b></h3>
                <ul>
                    <li>Give <b>Chris</b> enough safe space to express themselves, both orally and in written. Review together the alternatives, pros and cons, and backup plans. Let <b>Chris</b> be thorough enough so that they don’t spin their wheels and overthink things while they are working.</li>
                    <li>Give <b>Chris</b> enough time to share their ideas, thoughts, and concerns. Resist the urge to put them against a wall or to let them ruminate over their concerns after conversations. Reassure <b>Chris</b> by letting them know that you are trying to cover multiple potentialities, so they don’t have to worry about them themselves. This will make them trust you and reduce their wariness.</li>
                    <li>Leverage <b>Chris</b>' ability to think of multiple scenarios to see which opportunities and risks you may be confronted with. How can you prevent a worst-case scenario from happening? How can you repair the resulting damages?</li>
                </ul>

                <div className="separator" />
                <div className="separator" />

            </div>
        );
}

export default SampleCompareReport;