import React from 'react';

function HowDoesItWork(props : any) {
    return (
        <div>
            <div className="overlay">
            </div>
            <video className="bgvideo" playsInline autoPlay muted loop>
                <source src="mp4/bg.mp4" type="video/mp4"/>
            </video>
            <div className="masthead position-relative">
                <div className="text-white">
                    <h1 className="mb-3">How does it work?</h1>
                    <h2 className="mb-4">3 steps process :</h2>
                    <ol>
                        <li>Create an account</li>
                        <li>Activate your account (for USD20)</li>
                        <li>Enjoy the full MyOceanity experience !</li>
                    </ol>
                    <p> OCEAN personality traits assessments & comparisons with people you know </p>
                    <p> Enrollment in the Emotional Intelligence e-learning courses </p>
                </div>
            </div>
        </div>
    );
  }
export default HowDoesItWork;
