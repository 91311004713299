
export const questionsApi = {
    'en' : [
        {id:'887fb4f0-74ef-491a-8abb-b3686d04de9b', userQuestion:'I break down raw information into specific, workable components.', respondentQuestion: '{USER} breaks down raw information into specific, workable components.'},
        {id:'f937b297-349e-4ce0-897a-f00bf60f9105', userQuestion:'I make logical conclusion, anticipate obstacles, and consider different approaches to the decision-making process.', respondentQuestion: '{USER} makes logical conclusions, anticipates obstacles, and considers different approaches to the decision-making process.'},
        {id:'cdc02e6c-ace4-4361-9692-b3c7b51a3c0e', userQuestion:'I am able to translate analytical reports into advice which provides guidance to resolve issues.', respondentQuestion: '{USER} is able to translate analytical reports into advice which provides guidance to resolve issues.'},
        {id:'abcf1f5f-f928-442d-99f0-aaa301b9977f', userQuestion:'I am able to clearly and effectively transmit my ideas, feelings, opinions and conclusions orally and in writing.', respondentQuestion: '{USER} is able to clearly and effectively transmit their ideas, feelings, opinions and conclusions orally and in writing.'},
        {id:'3ff889da-9223-47e4-a171-c85003228c3b', userQuestion:'I listen attentively and for comprehension.', respondentQuestion: '{USER} listens attentively and for comprehension.'},
        {id:'4c4fcb2d-cdb7-42df-8b1b-91242fb6ad82', userQuestion:'I reinforce my words through empathetic body language and tone.', respondentQuestion: '{USER} reinforces their words through empathetic body language and tone.'},
        {id:'5428308d-080a-4a2f-8a7c-0b468643a93a', userQuestion:'I finish what I start.', respondentQuestion: '{USER} finishes what they start.'},
        {id:'bf38df98-a9fa-4eea-937e-286c40e72834', userQuestion:'I set expectations that are realistic and achievable.', respondentQuestion: '{USER} sets expectations that are realistic and achievable.'},
        {id:'d57f542f-179d-4bc5-9b0d-e2ab72547a10', userQuestion:'I hold myself and others accountable to a high standard of performance.', respondentQuestion: '{USER} holds themselves and others accountable to a high standard of performance.'},
        {id:'5ee3000c-efe8-4450-ba88-b7a655254c91', userQuestion:'I remain calm and optimistic when dealing with challenges.', respondentQuestion: '{USER} remains calm and optimistic when dealing with challenges.'},
        {id:'f8addacc-a159-4850-b85b-6314d4359a44', userQuestion:'I understand how excessively sharing my worries and sorrows could sap morale.', respondentQuestion: '{USER} understands how excessively sharing their worries and sorrows could sap morale.'},
        {id:'e081b27c-a1a8-4f58-a759-07c724f8890e', userQuestion:'I encourage candid discussion of how decisions might impact other people.', respondentQuestion: '{USER} encourages candid discussion of how decisions might impact other people.'},
        {id:'799b7508-2f83-44f8-b6e0-a4198005feb4', userQuestion:'I carefully manage my time and energy to perform well while avoiding burnout', respondentQuestion: '{USER} carefully manages their time and energy to perform well while avoiding burnout.'},
        {id:'61034909-df99-448a-acc5-53f66b93e85e', userQuestion:'I encourage others to prioritize their well-being and avoid burnout.', respondentQuestion: '{USER} encourages others to prioritize their well-being and avoid burnout.'},
        {id:'87fb48ce-50c2-44fe-bbf8-1d33be5707ff', userQuestion:'I am able to stay focused and to keep my spirits high when I work.', respondentQuestion: '{USER} is able to stay focused and keep their spirits high when they work.'},
        {id:'8c70aadb-e979-4780-8261-03f372bb7283', userQuestion:'My knowledge and expertise make an important contribution to achieving results.', respondentQuestion: '{USER}\'s knowledge and expertise make an important contribution to achieving results.'},
        {id:'af33084e-506f-4e71-9675-218e65198ebc', userQuestion:'Others seek after my opinions.', respondentQuestion: '{USER}\'s advice is helpful.'},
        {id:'7ceb6063-584b-4bb4-b201-b80cfed37ae2', userQuestion:'I can accomplish complex tasks in my area of expertise without supervision.', respondentQuestion: '{USER} can accomplish complex tasks in their area of expertise without supervision.'},
        {id:'096a7e5e-5e3f-4462-94e3-55c463ea8233', userQuestion:'I embrace mistakes as learning opportunities.', respondentQuestion: '{USER} embraces mistakes as learning opportunities.'},
        {id:'19913602-ba56-40cf-b367-8e1ab558b36e', userQuestion:'I believe and advocate that making mistakes is inevitable and acceptable.', respondentQuestion: '{USER} believes and advocates that making mistakes is inevitable and acceptable.'},
        {id:'efcf9388-cd39-4e1c-a2b5-ae979b6a378a', userQuestion:'I learn and grow from setbacks or failures.', respondentQuestion: '{USER} learns and grows from setbacks or failures.'},
        {id:'deda25f2-7376-4b1b-880a-2e1bcec51644', userQuestion:'I support change initiatives, raising my concerns and challenging the team to make sure all alternatives are examined.', respondentQuestion: '{USER} supports change initiatives, raising their concerns and challenging the team to make sure all alternatives are examined.'},
        {id:'4c1c62a7-c5a1-4f17-bdeb-89ed0f76acc3', userQuestion:'I demonstrate the courage to take calculated risks in order to test new ideas and encourage others to do the same.', respondentQuestion: '{USER} demonstrates the courage to take calculated risks in order to test new ideas and encourages others to do the same.'},
        {id:'c7043990-4c07-488f-b405-954abf20e7af', userQuestion:'I encourage others to come up with new and innovative ideas.', respondentQuestion: '{USER} encourages others to come up with new and innovative ideas.'},
        {id:'8cdc6af2-1c05-467f-8378-8b51614b5f5a', userQuestion:'I motivate others to persevere through challenging situations.', respondentQuestion: '{USER} motivates others to persevere through challenging situations.'},
        {id:'d4a7f918-8503-4046-b78d-7d596620a401', userQuestion:'I am willing to engage in difficult conversations.', respondentQuestion: '{USER} is willing to engage in difficult conversations.'},
        {id:'6e32174f-a851-494b-ba1c-fd72abd0fd9e', userQuestion:'I ask guiding questions to help people find their own way, instead of giving answers or instructions.', respondentQuestion: '{USER} asks guiding questions to help people find their own way forward, rather than just giving answers or instructions.'},
        {id:'fb41440b-8a0c-4636-8efd-316a787903d1', userQuestion:'I ask for feedback and advice from others on ideas.', respondentQuestion: '{USER} asks for feedback and advice from others on ideas.'},
        {id:'dbc624f0-914a-48f6-aa58-89a6849e06e4', userQuestion:'I am willing to consider or accept new suggestions and ideas.', respondentQuestion: '{USER} is willing to consider or accept new suggestions and ideas.'},
        {id:'7328e351-8503-4dfe-91f2-87fa477e1fb3', userQuestion:'I take enough time to translate the feedback I receive into a development plan.', respondentQuestion: '{USER} takes enough time to translate the feedback they receive into a development plan.'},
        {id:'bb3dd9a1-a3ff-48e3-8578-8e793874b6b8', userQuestion:'I look for the root cause of problems, how to correct them and how to prevent them.', respondentQuestion: '{USER} looks for the root causes of problems, how to correct them and how to prevent them.'},
        {id:'863e0bdc-8c6e-42b6-b271-170051419294', userQuestion:'I take enough time to accurately define my problems instead of jumping to conclusions.', respondentQuestion: '{USER} takes enough time to accurately define their problems, instead of jumping to conclusions.'},
        {id:'7c07dab7-934b-4f82-8d0b-8e617280072f', userQuestion:'I rely on facts rather than opinions to make a decision about a problem.', respondentQuestion: '{USER} relies on facts rather than opinions to make a decision about a problem.'},
        {id:'3f9d268a-d533-4eb5-ae25-9ac23fc5ca80', userQuestion:'I understand how my efforts contribute to my company\'s mission.', respondentQuestion: '{USER} understands how their efforts contribute to their company\'s mission.'},
        {id:'510bf8bb-3db1-4bbb-8968-7a928dabbb14', userQuestion:'I motivate others by highlighting how their efforts contribute to our company\'s mission.', respondentQuestion: '{USER} motivates others by highlighting how their efforts contribute to their company\'s mission.'},
        {id:'e04c03b1-907d-4f70-ab4e-40cf9f2cfe49', userQuestion:'I feel like what I do makes a difference in the world.', respondentQuestion: '{USER} seems inspired by what they do.'},
        {id:'3aeaae3f-8a95-4b41-81de-780fc96c333b', userQuestion:'I make sure that decisions don\'t benefit short-term results at the expense of long-term commitments and benefits.', respondentQuestion: '{USER} makes sure that decisions don\'t benefit short-term results at the expense of long-term commitments and benefits.'},
    {id:'86c6f04a-b0a3-4d49-a6fc-af52869dda83', userQuestion:'I openly share the credit where it is due. I do not selfishly take undue credit.', respondentQuestion: '{USER} openly shares the credit where it is due. {USER} does not selfishly take undue credit.'},
    {id:'6b77ec9c-0c9d-4ee1-8646-57432b4062af', userQuestion:'I openly admit when making mistakes and try to correct them.', respondentQuestion: '{USER} openly admits when making mistakes and tries to correct them.'},
    {id:'2b3888d8-b5f4-4b60-9901-d33b3f3b76db', userQuestion:'I recognize and celebrate accomplishments - big and small - in private and in public.', respondentQuestion: '{USER} recognizes and celebrates accomplishments - big and small - in private and in public.'},
    {id:'e77e6a6b-a4d6-48e7-b0f4-a71b2933f15c', userQuestion:'I connect people who can help each other.', respondentQuestion: '{USER} connects people who can help each other to accomplish their goals.'},
    {id:'fb6ab099-d18a-4f22-9088-903abbc263c6', userQuestion:'I embrace opportunities to work with others and help them with their struggles.', respondentQuestion: '{USER} embraces opportunities to work with others and help them with their struggles.'},
    {id:'79b95808-4ad0-48f3-9920-3fad46301952', userQuestion:'I demonstrate attention to detail and accuracy.', respondentQuestion: '{USER} demonstrates attention to detail and accuracy.'},
    {id:'d42bfa7f-ef1c-4867-90e9-0c3616df21d3', userQuestion:'I clearly define and organize tasks, responsibilities, and priorities.', respondentQuestion: '{USER} clearly defines and organizes tasks, responsibilities, and priorities.'},
    {id:'99d45a46-c4f2-4e2b-b440-7607369d29e3', userQuestion:'I take responsibility for timely completion and quality work.', respondentQuestion: '{USER} takes responsibility for timely completion and quality work.'},
    {id:'d242e8b6-6222-4af4-9116-c09e7fb9c629', userQuestion:'I encourage people to safely discuss their issues or concerns.', respondentQuestion: '{USER} encourages people to safely discuss their issues or concerns.'},
    {id:'0489df5f-b61c-4111-a780-fef7aa257901', userQuestion:'I demonstrate respect by listening with the intent to understand and allow people to make their voices be heard.', respondentQuestion: '{USER} demonstrates respect by listening with the intent to understand and allowing people to make their voices be heard.'},
    {id:'ffcfe058-5a31-40bd-baf7-228d50723c9a', userQuestion:'I ensure that others feel heard and valued.', respondentQuestion: '{USER} ensures that others feel heard and valued.'},
    ],
    'fr' :  [
        {id:'887fb4f0-74ef-491a-8abb-b3686d04de9b', userQuestion:'Je décompose des données brutes en éléments spécifiques et réalistes.', respondentQuestion: '{USER} décompose des données brutes en éléments spécifiques et réalistes.'},
        {id:'f937b297-349e-4ce0-897a-f00bf60f9105', userQuestion:'Je tire des conclusions logiques, anticipe les obstacles et considère plusieurs approches lorsque je prends des décisions.', respondentQuestion: '{USER} tire des conclusions logiques, anticipe les obstacles et considère plusieurs approches quand il/elle prend des décisions.'},
        {id:'cdc02e6c-ace4-4361-9692-b3c7b51a3c0e', userQuestion:'Je suis capable de convertir des rapports analytiques en recommendations qui prodiguent des conseils afin de résoudre les problèmes.', respondentQuestion: '{USER} arrive à convertir des rapports analytiques en recommendations qui prodiguent des conseils afin de résoudre les problèmes.'},
        {id:'abcf1f5f-f928-442d-99f0-aaa301b9977f', userQuestion:'Je peux communiquer mes idées, sentiments, opinions et conclusions clairement et efficacement, à l\'oral et à l\'écrit.', respondentQuestion: '{USER} arrive à communiquer ses idées, sentiments, opinions et conclusions clairement et efficacement, à l\'oral et à l\'écrit.'},
        {id:'3ff889da-9223-47e4-a171-c85003228c3b', userQuestion:'Je cherche à écouter attentivement et à comprendre ce que les autres me disent.', respondentQuestion: '{USER} écoute attentivement et cherche à comprendre ce que je lui dis.'},
        {id:'4c4fcb2d-cdb7-42df-8b1b-91242fb6ad82', userQuestion:'Je souligne mon discours avec un language corporel et un ton empathiques.', respondentQuestion: '{USER} souligne son discours avec un language corporel et un ton empathiques.'},
        {id:'5428308d-080a-4a2f-8a7c-0b468643a93a', userQuestion:'Je termine ce que je commence.', respondentQuestion: '{USER} termine ce qu\'il/elle commence.'},
        {id:'bf38df98-a9fa-4eea-937e-286c40e72834', userQuestion:'Je définis des attentes réalistes et réalisables.', respondentQuestion: '{USER} définit des attentes réalistes et réalisables.'},
        {id:'d57f542f-179d-4bc5-9b0d-e2ab72547a10', userQuestion:'Je cherche à et encourage les autres à atteindre un haut degré de performance.', respondentQuestion: '{USER} cherche à et encourage les autres à atteindre un haut degré de performance.'},
        {id:'5ee3000c-efe8-4450-ba88-b7a655254c91', userQuestion:'Je reste calme et optimiste lorsque je fais face à des difficultés.', respondentQuestion: '{USER} reste calme et optimiste lorsqu\'il/elle fait face à des difficultés.'},
        {id:'f8addacc-a159-4850-b85b-6314d4359a44', userQuestion:'J\'ai conscience à quel point surpartager mes inquiétudes et chagrins peuvent saper le moral des autres.', respondentQuestion: '{USER} a conscience à quel point surpartager ses inquiétudes et chagrins peuvent saper le moral des autres.'},
        {id:'e081b27c-a1a8-4f58-a759-07c724f8890e', userQuestion:'J\'encourage des discussions candides sur l\'impact que peuvent avoir nos décisions sur autrui.', respondentQuestion: '{USER} encourage des discussions candides sur l\'impact que peuvent avoir nos décisions sur autrui.'},
        {id:'799b7508-2f83-44f8-b6e0-a4198005feb4', userQuestion:'Je gère avec attention mon temps et mon énergie afin de rester performant/e tout en évitant le burnout.', respondentQuestion: '{USER} gère avec attention son temps et son énergie afin de rester performant/e tout en évitant le burnout.'},
        {id:'61034909-df99-448a-acc5-53f66b93e85e', userQuestion:'J\'encourage les autres à prioriser leur bien-être et à éviter le burnout.', respondentQuestion: '{USER} encourage les autres à prioriser leur bien-être et à éviter le burnout.'},
        {id:'87fb48ce-50c2-44fe-bbf8-1d33be5707ff', userQuestion:'J\'arrive à rester concentré et à garder le moral lorsque je travaille.', respondentQuestion: '{USER} arrive à rester concentré/e et à garder le moral lorsqu\'il/elle travaille.'},
        {id:'8c70aadb-e979-4780-8261-03f372bb7283', userQuestion:'Mes connaissances et mon expertise contribuent fortement à l\'atteinte de résultats.', respondentQuestion: '{USER} contribue fortement à l\'atteinte des résultats grâce à ses connaissances et à son expertise.'},
        {id:'af33084e-506f-4e71-9675-218e65198ebc', userQuestion:'D\'autres me demandent mon avis.', respondentQuestion: '{USER} donne des conseils avisés.'},
        {id:'7ceb6063-584b-4bb4-b201-b80cfed37ae2', userQuestion:'Je peux réaliser des tâches complexes dans mon domaine d\'expertise sans supervision.', respondentQuestion: '{USER} peut réaliser des tâches complexes dans son domaine d\'expertise sans supervision.'},
        {id:'096a7e5e-5e3f-4462-94e3-55c463ea8233', userQuestion:'J\'accepte de faire des erreurs et en tire des leçons.', respondentQuestion: '{USER} accepte de faire des erreurs et en tire des leçons.'},
        {id:'19913602-ba56-40cf-b367-8e1ab558b36e', userQuestion:'Je crois et prône que faire des erreurs est inévitable et acceptable.', respondentQuestion: '{USER} croit et prône que faire des erreurs est inévitable et acceptable.'},
        {id:'efcf9388-cd39-4e1c-a2b5-ae979b6a378a', userQuestion:'J\'apprends et progresse grâce à mes revers et à mes échecs.', respondentQuestion: '{USER} apprend et progresse grâce à ses revers et à ses échecs.'},
        {id:'deda25f2-7376-4b1b-880a-2e1bcec51644', userQuestion:'Je soutiens le changement et cherche à assurer que toutes les alternatives soient considérées.', respondentQuestion: '{USER} soutient le changement et cherche à assurer que toutes les alternatives soient considérées.'},
        {id:'4c1c62a7-c5a1-4f17-bdeb-89ed0f76acc3', userQuestion:'Je fais preuve de courage et prends des risques calculés afin de tester de nouvelles idées et j\'encourage autrui à faire pareil.', respondentQuestion: '{USER} fait preuve de courage et prend des risques calculés afin de tester de nouvelles idées et encourage autrui à faire pareil.'},
        {id:'c7043990-4c07-488f-b405-954abf20e7af', userQuestion:'J\'encourage autrui à trouver des solutions innovantes.', respondentQuestion: '{USER} encourage autrui à trouver des solutions innovantes.'},
        {id:'8cdc6af2-1c05-467f-8378-8b51614b5f5a', userQuestion:'J\'encourage les autres à persévérer face à des situations difficiles.', respondentQuestion: '{USER} encourage les autres à persévérer face à des situations difficiles.'},
        {id:'d4a7f918-8503-4046-b78d-7d596620a401', userQuestion:'Je n\'hésite pas à engager des conversations difficiles.', respondentQuestion: '{USER} n\'hésite pas à engager des conversations difficiles.'},
        {id:'6e32174f-a851-494b-ba1c-fd72abd0fd9e', userQuestion:'Je pose des questions directrices afin d\'aider les autres à trouver leurs propres chemins, plutôt que de leur donner des instructions ou des réponses toutes faites.', respondentQuestion: '{USER} pose des questions directrices afin d\'aider les autres à trouver leurs propres chemins, plutôt que de leur donner des instructions ou des réponses toutes faites.'},
        {id:'fb41440b-8a0c-4636-8efd-316a787903d1', userQuestion:'Je demande conseil aux autres pour évaluer mes idées.', respondentQuestion: '{USER} demande conseil aux autres pour évaluer ses idées.'},
        {id:'dbc624f0-914a-48f6-aa58-89a6849e06e4', userQuestion:'J\'accepte de recevoir des suggestions et des idées.', respondentQuestion: '{USER} accepte de recevoir des suggestions et des idées.'},
        {id:'7328e351-8503-4dfe-91f2-87fa477e1fb3', userQuestion:'Je prends suffisamment de temps pour transformer les conseils que je reçois en un plan de développement.', respondentQuestion: '{USER} prend suffisamment de temps pour transformer les conseils qu\'il/elle reçoit en un plan de développement.'},
        {id:'bb3dd9a1-a3ff-48e3-8578-8e793874b6b8', userQuestion:'Je cherche la cause principale des problèmes, comment les corriger et comment les prévenir.', respondentQuestion: '{USER} cherche la cause principale des problèmes, comment les corriger et comment les prévenir.'},
        {id:'863e0bdc-8c6e-42b6-b271-170051419294', userQuestion:'Je prends suffisamment de temps pour définir mes problèmes plutôt que de tirer des conclusions trop hâtives.', respondentQuestion: '{USER} prend suffisamment de temps pour définir ses problèmes plutôt que de tirer des conclusions trop hâtives.'},
        {id:'7c07dab7-934b-4f82-8d0b-8e617280072f', userQuestion:'Je m\'appuie sur des faits plutôt que des opinions lorsque je prends des décisions.', respondentQuestion: '{USER} s\'appuie sur des faits plutôt que des opinions lorsqu\'il/elle prend des décisions.'},
        {id:'3f9d268a-d533-4eb5-ae25-9ac23fc5ca80', userQuestion:'Je comprends comment mes efforts contribuent à la mission de mon entreprise.', respondentQuestion: '{USER} comprend comment ses efforts contribuent à la mission de son entreprise.'},
        {id:'510bf8bb-3db1-4bbb-8968-7a928dabbb14', userQuestion:'Je motive les autres en leur rappelant comment leurs efforts contribuent à la mission de leur entreprise.', respondentQuestion: '{USER} motive les autres en leur rappelant comment leurs efforts contribuent à la mission de leur entreprise.'},
        {id:'e04c03b1-907d-4f70-ab4e-40cf9f2cfe49', userQuestion:'J\'ai l\'impression que ce que je fais améliore la vie des gens.', respondentQuestion: '{USER} a l\'impression que ce qu\'il/elle fait améliore la vie des gens.'},
        {id:'3aeaae3f-8a95-4b41-81de-780fc96c333b', userQuestion:'Je m\'assure que mes décisions ne favorisent pas des résultats à court terme au dépens d\'avantages et d\'engagements à long terme.', respondentQuestion: '{USER} s\'assure que ses décisions ne favorisent pas des résultats à court terme au dépens d\'avantages et d\'engagements à long terme.'},
        {id:'86c6f04a-b0a3-4d49-a6fc-af52869dda83', userQuestion:'Je ne m\'attribue pas le mérite de ce qui n\'est pas mien.', respondentQuestion: '{USER} ne s\'attribue pas le mérite qui ne lui revient pas.'},
        {id:'6b77ec9c-0c9d-4ee1-8646-57432b4062af', userQuestion:'J\'admets ouvertement lorsque je fais des erreurs et cherche à les corriger.', respondentQuestion: '{USER} admet ouvertement lorsqu\'il/elle fait des erreurs et cherche à les corriger.'},
        {id:'2b3888d8-b5f4-4b60-9901-d33b3f3b76db', userQuestion:'Je félicite et récompense les progrès des autres - grands ou petits - en privé et en public.', respondentQuestion: '{USER} félicite et récompense les progrès des autres - grands ou petits - en privé et en public.'},
        {id:'e77e6a6b-a4d6-48e7-b0f4-a71b2933f15c', userQuestion:'Je mets en relation des personnes pouvant s\'entraider.', respondentQuestion: '{USER} met en relation des personnes pouvant s\'entraider.'},
        {id:'fb6ab099-d18a-4f22-9088-903abbc263c6', userQuestion:'J\'aime travailler avec les autres et les aider à résoudre leurs problèmes.', respondentQuestion: '{USER} aime travailler avec les autres et les aider à résoudre leurs problèmes.'},
        {id:'79b95808-4ad0-48f3-9920-3fad46301952', userQuestion:'Je fais preuve d\'attention aux détails et de précision.', respondentQuestion: '{USER} fait preuve d\'attention aux détails et de précision.'},
        {id:'d42bfa7f-ef1c-4867-90e9-0c3616df21d3', userQuestion:'Je définis et organise mes tâches, responsabilités et priorités avec clarté.', respondentQuestion: '{USER} définit et organise ses tâches, responsabilités et priorités avec clarté.'},
        {id:'99d45a46-c4f2-4e2b-b440-7607369d29e3', userQuestion:'J\'assume la responsabilité de finir dans les délais impartis et de fournir un travail de qualité.', respondentQuestion: '{USER} assume la responsabilité de finir dans les délais impartis et de fournir un travail de qualité.'},
        {id:'d242e8b6-6222-4af4-9116-c09e7fb9c629', userQuestion:'J\'encourage autrui à partager leurs inquiétudes en toute sécurité.', respondentQuestion: '{USER} encourage autrui à partager leurs inquiétudes en toute sécurité.'},
        {id:'0489df5f-b61c-4111-a780-fef7aa257901', userQuestion:'Je fais preuve de respect en écoutant avec l\'intention de comprendre les gens afin qu\'ils ressentent que leur opinion soit entendue.', respondentQuestion: '{USER} fait preuve de respect en écoutant avec l\'intention de comprendre les gens afin qu\'ils ressentent que leur opinion soit entendue.'},
        {id:'ffcfe058-5a31-40bd-baf7-228d50723c9a', userQuestion:'Je m\'assure que les autres se sentent entendus et appréciés.', respondentQuestion: '{USER} s\'assure que les autres se sentent entendus et appréciés.'},
    ]
}