import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import {loadStripe} from '@stripe/stripe-js';
import i18n from 'i18next';
import * as Icon from 'react-bootstrap-icons';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB!)

export interface PrePaymentProps {
    email: string
    userId: string
}

export class PrePayment extends Component<PrePaymentProps, any> {

    constructor(props) {
        super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }


    async handleClick(event) {

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        if (stripe) {
            const {error} = await stripe.redirectToCheckout({
                lineItems: [{
                    price: 'price_1HP7YcC6mG131FMuB4T7448u', // Replace with the ID of your price
                    quantity: 1
                }],
                mode: 'payment',
                customerEmail: this.props.email,
                clientReferenceId: this.props.userId,
                successUrl: "https://myoceanity.com/user/premium/quiz",
                cancelUrl: "https://myoceanity.com/user/premium/quiz",
            });
        }
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
    };


    render() {

        return (
            <div className="masthead">
                <br/><br/><br/><br/>
                <h2 className="font-weight-bold">{i18n.t('lifetime-access-one-off-payment')}</h2>
                <p>{i18n.t('to-access-all-the-resources-of-myoceanity-please-click-the-button-below-you-are-free-to-accept-or-refuse')}</p> <br/>

                <ul>
                    <li><b>{i18n.t('one-time')}<b>{i18n.t('20')}</b> {i18n.t('payment-for-lifetime-access')}</b></li>
                    <li>{i18n.t('take-as-many-assessments-as-you-want-including-future-functionalities')}</li>
                    <li>{i18n.t('invite-as-many-people-as-you-want-to-learn-more-about-them')}</li>
                    <li>{i18n.t('your-contribution-enables-us-to-secure-your-personal-information-and-results-thank-you-for-your-trust')}</li>
                    <li>{i18n.t('we-donate-10-of-our-profits-to-charity')}</li>
                </ul>
                
                <br/>
                
                <p>{i18n.t('you-will-be-redirected-to-our-payment-partner')}</p>
                <br/> <br/>
                <section className="accordion-width">
                <Button role="link" onClick={this.handleClick}>{i18n.t('activate-my-account-for-20')}<Icon.ArrowRight className="ml-2" /></Button>
                </section>
                <br/> <br/>
                <section className="justify-content-center">
                <a href="/">{i18n.t('no-thanks-im-good')}</a>
                </section>
            </div>
        );
    }

}

export default PrePayment;