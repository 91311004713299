import React from 'react';
import Table from 'react-bootstrap/Table';

import intentionalliving from "../images/intentional-living.png";


function IdentityIntroduction(props: any) {
	   
  return (
   <div className="masthead">
			<h1>Introduction to The Concept of Identity</h1>
			
			<h2>What is Your Identity?</h2>
			<p>Your identity drives your life, whether you realize it nor. It's your Why. <b>It's the reason you're excited to wake up in the morning and do something meaningful with your time</b>.</p>
			<p>I break down identity into three elements:</p>
			
			<div className="notabene"> 
				<b>Identity = Mission + Values + Beliefs</b>
			</div>

			<ul>
				<li>Your <a href="/identity-mission" className="intlink">Mission</a>, or purpose in life, answers the question "Why am I here?"</li>
				<li>Your <a href="/identity-values" className="intlink">Core Values</a> answer the question "What do I stand for?"</li>
				<li>Your <a href="/identity-beliefs" className="intlink">Beliefs</a> answer the question "Who am I?"</li>
			</ul>

			<p>Once you start finding answers to these questions, keep them close by. Write in a journal or use the following printable documents. I also included mine for reference.</p>
			<p>Why? Because referring to them from time to time helps when you are feeling lost. It helps to find your way back on track. </p>
			
			<p>Download placeholder: Identity Template</p>
			<p>Download placeholder: Identity Chris</p>
			
			<p>This lesson will focus on why identity and living an intentional life matters to renewing your spiritual energy.</p>
			<p>Schwartz & McCarthy wrote in their Harvard Business Review article, "Managing Your Energy, Not Your Time", that our energy can be broken down into four dimensions:</p>
			
			<ol>
				<li><b>Physical energy</b>, which we get by taking care of our bodies.</li>
				<li><b>Emotional energy</b>, which we get by seeking the brighter and more hopeful side of life, and which we can develop through better emotional intelligence.</li>
				<li><b>Mental energy</b>, which we get with conscious thinking, a way of living mindfully, by being both aware and focused in the present moment.</li>
				<li><b>Spiritual energy</b>, which we get by defining our ideals in life, in other words, our ideal identity and selves, and intentionally moving towards this potential future.</li>
			</ol>
			
			<p><b>Lessons on how to better manage your energy will be available soon in the self-development module.</b></p>

			<p>Schwartz & McCarthy created the Energy Project, an organization where they teach energy management. According to their research, contrary to time, which is a finite resource, our energy can be renewed throughout the day, with specific rituals for each of the four dimensions.</p>
			<p>Let's now discuss how living a more intentional life, through the sense of fulfillment and self-actualization it breeds, can help to renew your spiritual energy.</p>
			

			<h2>How Self-Fulfillment Recharges Your Spiritual Battery</h2>
			
			<div className="notabene">
				What is self-fulfillment? It's the feeling you get when you live an intentional life. If your actions are helping you progress towards your Mission, aligning with your Values or reinforcing your Beliefs, you will feel fulfilled. 
			</div>

			<div className="myimghor w-75">
				<img src={intentionalliving} alt="Living an intentional life" />
			</div>

			<div className="imgcaption">
				<p><b>Living an intentional life means continuously trying to align your actions with your identity</b></p>
			</div>
			
			<p>I believe the spiritual stories shared across the world follow a similar principle. The stories depicted in these holy books describe how people should think, feel, and act to become better people. They describe the Mission, Values, and Beliefs that their archetypical characters embodied and set a path for you to follow.</p>
			<p>Through my research and reflections, I realized that I am part of something bigger than myself, that my life is not about me alone. It's about how I can contribute to something bigger than myself, to help others around me - even people I will never meet, for as long as possible - even after I will no longer be here. </p>
			<p>This feels humbling and liberating. Helping others feels much more rewarding than taking everything I can from the world and egoistically trying to prove that I am special (which I am not, thank you for asking).</p>
			<p>Your identity tries to capture a few things:</p>
			
			<ul>
				<li>Who are you today? (What is)</li>
				<li>Who do you want to become tomorrow? (What could be)</li>
				<li>What do you want to bring to the world? (What could be)</li>
			</ul>
			
			<p>Once you better understand where you are today and where you want to go tomorrow, it's then up to you to follow the invisible path that will lead you there.</p>
			<p>As long as you're trying to stay aware of these questions and try to truthfully answer them, you should feel when you are making progress towards your ideal identity, and when you are straying off the path.</p>
			<p><b>Your mistakes are here to teach you. Accept them, learn lessons from them</b>, get back on the path and keep going. This is what your Journey is about. It's a never-ending process of self-acceptation, self-clarification and self-development.</p>
			<p>Here is my personal story and thoughts to illustrate this lesson.</p>
			
			<h2>Chris' Personal Illustration</h2>
			<p>In the words of Simon Sinek, I believe that starting with Why is essential and I regret not having realized this earlier. Still, I'm grateful for having the rest of my life to try.</p>
			<p>Throughout my life, I frequently gave myself challenges that never stuck with me. Looking back, I think I wasn't able to follow through on them because I saw them as challenges I had to overcome instead of something which better defined who I am.</p>
			<p>Think diets. Exercise routines. Life hacks. Buying whatever widget would change my life. In sum, things that were a How that I could not internalize within my Why.</p>
			<p>For example, during my 20s, I wanted to do 50 pushups in a row. After weeks of exercising, I repeatedly got stuck at 35 or 40, for weeks on end. And I gave up.</p>
			<p>I didn't persevere for long enough because I was obsessed with the goal and not with the process of getting there. I didn't even realize that I was getting stronger along the way, since I could now do more pushups than before! </p>
			<p>The new way I approach this now is to see how many pushups I can do in a row, trying to reinforce my belief that "I am fit & healthy" without minding the actual number. I may never get to that number, and I'm okay with this. It does not define who I am. What I do does.</p>
			<p>It took me almost two years to better define my identity, and it's been almost two years that I've adjusted my behavior accordingly.</p>
			<p>I can't stress enough how dramatic the results were, in terms of happiness and satisfaction, considering how small the changes were over time. This positive feedback loop makes me more perseverant and, as a result, the process seems to require less and less effort over time.</p>

			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>Whether we realize it or not, our identity shapes our lives.</p>
			<p>I'm concerned that our busy lifestyles are preventing us to pause and go through these phases of introspection and deep thinking. It's like we are continuously running forward, always faster, without even noticing that we are heading towards the edge of the cliff.</p>
			<p>It's a shame, considering that some people may never find what gives their lives meaning and purpose, which may lead them to many regrets on their deathbeds. </p>
			<p>We are chasing fleeting birds without realizing doing so scares them off. If we stopped more to reflect and give the bird enough time, it would eventually lie on the welcoming hand we extend to it.</p>
			<p>The following questions can help you find what drives you, which will help you to better define your identity, as you will notice trends and answers emerge.</p>
			<p>I recommend that you schedule some time at the end of the day to reflect on them. Every day. Write them down and look for what your unconscious mind is trying to tell you.</p>

			<ol><b>
				<li>What made me enthusiastic and energized today? What went really well today?</li>
				<li>What made me lose track of time today?</li>
				<li>What drained and exhausted me today? What did I dread doing? What went wrong today?</li>
				<li>Why did I feel this way, both positively and negatively?</li>
				<li>How can I start doing more of what excites me and less of what drains me?</li>
			</b></ol>

			<p>In the end, <b>your actions dictate what you will be remembered for</b>. As you search answers to your life-defining, existential questions, it's also time to take the first step. It's time to enjoy the Journey.</p>

			<p>There are a few ways you can discover your identity:</p>

			<Table striped borderless hover responsive className="course-table">
				<thead>
					<tr><th className="course-table-header">Module 4: Discovering Your Identity</th></tr>
				</thead>
				<tbody className="course-table-body">
					<tr> <td> <a href="/ikigai">What is a meaningful life? What is Ikigai?</a></td> </tr>
					<tr> <td> <a href="/identity-mission">Why are you here? What is your Mission? What gives your life purpose?</a></td> </tr>
					<tr> <td> <a href="/identity-values">What do you stand for? What are your core values in life?</a></td> </tr>
					<tr> <td> <a href="/identity-beliefs">Who are you? What are your beliefs about yourself and the world around you?</a></td> </tr>
				</tbody>
			</Table>

			<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>
            </div>
			<br /><br /><br />
   </div>
  )
}
export default IdentityIntroduction;

