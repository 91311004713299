import React from 'react';
import { Card, CardDeck, Image } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown';


import * as Icon from 'react-bootstrap-icons';
import fulfilledimg from "../images/fulfilled.jpg";
import dogoodfeelgood from "../images/do-good-feel-good.png";
import ultimatumgameimg from "../images/ultimatum-game-offers.png";
import rewardingfairplayersimg from "../images/rewarding-fair-players.png";
import teamworkimg from "../images/teamwork.png";
import identitiesimg from "../images/identities-2.jpg";
import objectiveimg from "../images/objective.jpg";
import valuesimg from "../images/o-values.jpg";
import reflectionimg from "../images/reflection.jpg";


function Fulfillment() {
    const {i18n} = useTranslation();

	return (
		<div className="masthead">

			<div className="myimg">
				<img src={fulfilledimg} alt="An image of a happy-looking man in a red shirt" />
			</div>

			<div className="imgcaption">
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@olly">Andrea Piacquadio</a> {i18n.t('from')} <a
                    href="https://www.pexels.com/photo/man-in-red-crew-neck-sweatshirt-photography-941693/">Pexels</a>
                </p>
            </div>


			<h2>Fulfillment Comes From Serving Others</h2>
			
			<div className="notabene">
				The feeling of fulfillment comes from sacrificing some of your time and energy for someone else. This is what service is all about.
			</div>

			<section className="story">
				<h4>What it means to be a Marine</h4>
				<p>One day, the elder of a group of villagers - who fled their home because it was invaded by the Taliban - knocked on the door of a Marine base.</p>
				<p>"You need to come down to the river and kill us," he said.</p>
				<p>"What do you mean?" asked the confused Marine who answered the door.</p>
				<p>"Winter is coming," the elder answered. "We cannot go back to the village, because if we do, then the Taliban will kill us. If we stay where we are, we will die a slow and painful death. It's much easier if you come and kill us, please."</p>
				<p>What did the Marines do? They risked their lives, pushed the Taliban out of the village and gave it back to its rightful owners.</p>
				
				<br />

				<div className="imgcaption">
					<p className="text-black">This story reported by Simon Sinek illustrates what it means to be a Marine: to serve others.</p>
				</div>
			</section>


			<div className="content-btn-box">
				<a href="https://www.wfp.org/support-us/stories/families-afghanistan-need-help?utm_source=google&utm_medium=cpc&utm_campaign=14343548076&utm_content=126969859195&gclid=Cj0KCQjwkIGKBhCxARIsAINMioIIS3whQPsotl5apNwbZquqsQKmIrlW2yl7qpbXneWv3xoDs37q03QaAo7CEALw_wcB&gclsrc=aw.ds" className="content-btn">Donate to WFP to help the Afghan people<Icon.ArrowRight className="ml-2" /></a>
			</div>


			<div className="separator"></div>

			<ReactMarkdown>For these Marines, **this is what gives meaning to their sacrifices: to serve others**. When they put themselves at risk so that others may prevail, they feel fulfilled.</ReactMarkdown>

			
			<div className="myimg">
				<img src={dogoodfeelgood} alt="When you do good, then you feel good"/>
			</div>
			
			<div className="imgcaption">
				<p>When you do good, then you feel good</p>
			</div>

			<p>This may explain why service - doing something for someone else - is <a href="/ikigai">one of the four elements of Ikigai</a>, along with:</p>

			<ol>
				<li>Doing what you love</li>
				<li>Doing what you are good at</li>
				<li>Receiving a compensation for your efforts</li>
			</ol>

			<ReactMarkdown>Humans are extremely social beings who engage in a variety of **prosocial behaviors**:</ReactMarkdown>

			<ul>
				<li>Interpersonal trust</li>
				<li>Empathy</li>
				<li>Compassion</li>
				<li>Altruism</li>
				<li>Mutual cooperation</li>
				<li>Donating to charity</li>
				<li>Inequality aversion</li>
				<li>Guilt aversion</li>
				<li>Costly punishment of norm violations and antisocial behaviors</li>
			</ul>

			<div className="quote">
				<p><i>"Prosocial behaviors occur in any situation where an individual engages in costly or risky acts to improve the welfare of another person."</i></p>
				<p><strong>Nina Marsh et al.</strong></p>
			</div>

			<ReactMarkdown>Here is what Marsh means when she talks about "risky acts": when you help someone, you are taking a risk, because you have no certainty that you will receive any help or gratitude in return.</ReactMarkdown>

			
			<div className="three-dots-hr">
				<p>...</p>
			</div>

			<h3>The Cost of Selfishness</h3>
			<ReactMarkdown>Since helping others has been one key reason why humanity has thrived, we became highly attuned to how altruistic or selfish people's behaviors are. **We are grateful to those who help us and may seek revenge on those who play us**. Let's review another example coming from Simon Sinek's interaction with the Marine Corps.</ReactMarkdown>

			<p>During the Marine Corps boot camp, the recruits go through 2 types of exercices:</p>
			<ol>
				<li>Exercices which make their bodies and minds stronger</li>
				<li>Exercices which increase their levels of trust and teamwork</li>
			</ol>

			<p>The first kind, the obstacle course, is timed and can be done alone, which tends to create more competitive behaviors to determine who is the fastest or the strongest.</p>
			<ReactMarkdown>The second kind, however, is not timed and cannot be finished alone. **The recruits have to work together to finish it together**. What is fascinating is that members who do not help others - no matter how strong or weak they physically are - **naturally become ostracized by the rest of the group**. This way, the group punishes selfish behaviors and unites those who look out for each other. Can the ostracized recruits be welcomed back to the group? Yes, but first, they need to assist other recruits before they receive any help in return.</ReactMarkdown>

			<ReactMarkdown>Such behaviors have also been identified in behavioral economics research, most well known as **the ultimatum game**.</ReactMarkdown>


			<h4>Illustration - The Ultimatum Game</h4>
			<ReactMarkdown>In the ultimatum game, **2 players are asked to split a certain amount of money**, for instance, $100 in $1 bills. Player 1 makes an offer, which Player 2 can either accept or reject.</ReactMarkdown>

			<ul>
				<li>If the offer is accepted, then the money is split between the players according to the offer made by Player 1.</li>
				<li>If the offer is rejected, then no one gets any money.</li>
			</ul>

			<ReactMarkdown>In its simplest form, this game is played only once by pairing strangers, to make sure reputation does not taint the results of the game. As long as the offer is perceived as fair, Players 2 tend to accept it. However, when the offer is perceived as unfair - around 80-20 or worse - Players 2 tend to reject it. **They would rather walk away with nothing than allowing someone else to take advantage of them**.</ReactMarkdown>

			<div className="myimg">
				<img src={ultimatumgameimg} alt="Acceptance rates of different offers"/>
			</div>

			<div className="imgcaption">
				<p>Selfish offers tend not to pay off. Adapted from <a href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q=Predicting+Decisions+in+Human+Social+Interactions+Using+Real-Time+fMRI+and+Pattern+Classification&btnG=">Hollmann et al.</a></p>
			</div>

			<p>In more advanced forms, after the initial game is over, Players 2 have the possibility to make one last decision:</p>
			<ul>
				<li>Splitting $12 with someone who previously made an unfair offer</li>
				<li>Splitting $10 with someone who previously made a fair offer</li>
			</ul>

			<p>Which one do you prefer? Here is what the participants decided:</p>

			<div className="myimg">
				<img src={rewardingfairplayersimg} alt="People would rather reward a fair player than an unfair one"/>
			</div>
			
			<div className="imgcaption">
				<p>80% of the participants preferred rewarding fair players, even if it meant they would personally earn less money doing so.</p>
			</div>

			<h5>What This Means for You</h5>
			<ReactMarkdown>When you help others by sacrificing some of your time and energy for their sake, not only will you feel great, you will also build stronger relationships with the group, who will want to reward your altruism.</ReactMarkdown>
			<ReactMarkdown>In contrast, acting selfishly and serving your own interests will leave you feeling unfulfilled and may push others away from you to punish your selfishness.</ReactMarkdown>

			<div className="quote">
				<p><i>"If you want to feel happy, do something for yourself. If you want to feel fulfilled, do something for someone else."</i></p>
				<p className="quote-source"><b> &mdash; Simon Sinek</b></p>
			</div>

			<h3>Closing Thoughts & Your Next Steps</h3>
			<p>Serving others is the best way to build lasting trust and achieving outstanding collective results. In contrast, acting selfishly may likely come back to haunt you in the future. Acts of service will also bring you feelings of fulfillment, because you sacrificed some of your time and energy for someone else. </p>
			<p>Whether you realize it or not, your identity shapes your life. There are a few ways to discover your identity: </p>

			<div className="separator"></div>

			<section className="course-tables-center">
			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={identitiesimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Introduction to the concept of Identity<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/identity-introduction" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={teamworkimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Ikigai: What makes a life worth living<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ikigai" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={objectiveimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">What is your mission in life? Why are you here?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/identity-mission" className="stretched-link"></a>
				</Card>
			</CardDeck>

			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={valuesimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">What are your core values? What do you stand for?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/identity-values" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={reflectionimg} variant="top" className="mb-3" />
					<Card.Title className="m-3">Who are you? What are your beliefs in life?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/identity-beliefs" className="stretched-link"></a>
				</Card>
			</CardDeck>
			</section>


			<div className="content-btn-box">
				<a href="/ei-course" className="content-btn">Back to the course<Icon.ArrowRight className="ml-2" /></a>
			</div>

			<div className="separator"></div>
			<div className="separator"></div>

		</div>
	)
}
export default Fulfillment;

