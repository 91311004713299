import React from 'react';
import './App.css';
import quizQuestions, {quizQuestionsFR, quizQuestionsV2} from './api/QuizQuestions';
import Quiz from './components/Quiz'
import Intro from './components/Intro'
import Result from './components/Result';
import Loading from './components/Loading'
import Fake from './components/Fake';
import InviteAccepted from './components/InviteAccepted';
import ViewReport from './components/ViewReport';
import MyReports from './components/MyReports';
import CompareProfile from './components/CompareProfile';
import CompareReport from './components/CompareReport';
import {createBrowserHistory} from 'history';
import {Auth0ContextInterface, useAuth0, withAuth0} from "@auth0/auth0-react";
import {StyleScore, UserData, UserLevel} from './components/types';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import Home from "./Home";
import MyOceanityNav from "./components/MyOceanityNav";
import ContactForm from "./components/content/ContactForm";
import WhatIsMyOceanity from "./components/content/WhatIsMyOceanity";
import HowDoesItWork from "./components/content/HowDoesItWork";
import WhoWeAre from "./components/content/WhoWeAre";
import FAQ from "./components/content/FAQ";
import OceanMethodology from "./components/content/OceanMethodology";
import EICourseOverview from "./components/content/EICourseOverview";
import OrganizeAnEvent from "./components/content/OrganizeAnEvent";
import Donations from "./components/content/Donations";
import WhatWeDo from './components/what-we-do';
import MyOceanityAndEI from './components/myoceanity-and-ei';
import Oceanology from './components/oceanology';
import OceanPersonalityTraits from './components/ocean-personality-traits';
import WhatIsEI from './components/what-is-ei';
import WhyEIMatters from './components/why-ei-matters';
import GrowYourEI from './components/grow-your-ei';
import EICourse from './components/ei-course';
import EIBenefitsHome from './components/ei-benefits-home';
import EIBenefitsWork from './components/ei-benefits-work';
import StrategiesForEI from './components/strategies-for-ei';
import CoreEmotions from './components/core-emotions';
import EmotionalLiteracy from './components/emotional-literacy';
import ReadingEmotions from './components/reading-emotions';
import IdentityIntroduction from './components/identity-introduction';
import Ikigai from './components/ikigai';
import IdentityMission from './components/identity-mission';
import IdentityValues from './components/identity-values';
import IdentityBeliefs from './components/identity-beliefs';
import JournalingSelfAwareness from './components/journaling-self-awareness';
import JournalingSelfDevelopment from './components/journaling-self-development';
import JournalingIntrospection from './components/journaling-introspection';
import Updates from './components/updates';
import Resources from './components/resources';
import SupportAndDonations from './components/support-and-donations';
import Events from './components/events';
import PrivacyPolicy from "./components/PrivacyPolicy";
import queryString from 'query-string';
import ReactGA from 'react-ga';
import ElementsOfTrust from "./components/elements-of-trust";
import SamplePersonalReport from './components/sample-personal-report';
import SampleComparisonReport from './components/sample-comparison-report';
import DemoMyReports from './components/Demo-MyReports';
import DemoCompareProfile from './components/Demo-CompareProfile';
import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import SharpenTheAxe from './components/sharpen-the-axe';
import Fulfillment from './components/fulfillment';
import OpennessToExperience from './components/openness-to-experience';
import OpennessFacets from './components/openness-facets';
import OceanMisunderstandings from './components/ocean-misunderstandings';
import ThreeSixtyFeedbackContent from './components/360-feedback-content';
import _360MainPage from "./components/_360MainPage";
import _360AnonymousResponse from "./components/_360AnonymousResponse";
import YourGrowth from './components/Demo-AboutYou';
import StepWizardTest from './components/stepwizard-test';
import UpgradeToPro from "./components/UpgradeToPro";

export enum VIEW_STATE {
    INTRO,
    QUIZ,
    LOADING,
    UNPAID,
    REPORT
}

export interface AppState {
    questions: Array<any>
    counter: number;
    currentQuestionId: string;
    currentQuestion: string;
    answerOptions: Array<string>;
    currentAnswer: string;
    answerState: Map<string, string>;
    result: {
        AGREEABLENESS: StyleScore
        CONSCIENTIOUSNESS: StyleScore
        EXTROVERSION: StyleScore
        NERVOSITY: StyleScore
        OPENNESS: StyleScore
    };
    viewState: VIEW_STATE;
    userData: UserData | null;
    jwt: string | null;
    loading: boolean;
    reportLink: string;
    quizVersion : string;
}

export interface AppProps {
    auth0: Auth0ContextInterface
}

function ProRoute({children, ...rest}) {
    const {isAuthenticated, loginWithRedirect, isLoading} = useAuth0();
    if (isLoading) {
        return null;
    }
    console.log("pro route",rest.level)
    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuthenticated ? (
                    rest.level==UserLevel.PRO?
                    children : (rest.level? <UpgradeToPro /> : <Loading />)
                ) : (loginWithRedirect({redirectUri:window.location.origin, appState: { targetUrl: window.location.pathname },ui_locales: 'fr' }))
            }
        />

    );
}

function PrivateRoute({children, ...rest}) {
    const {isAuthenticated, loginWithRedirect, isLoading} = useAuth0();
    if (isLoading) {
        return null;
    }
    return (
        <Route
            {...rest}
            render={({location}) =>
                 isAuthenticated ? (
                    children
                ) : (loginWithRedirect({redirectUri:window.location.origin, appState: { targetUrl: window.location.pathname },ui_locales: 'fr' }))
            }
        />

    );
}



export class App extends React.Component<AppProps, AppState> {


    constructor(props: any) {
        super(props);
        this.state = {
            questions: [],
            counter: 0,//quizQuestions.length - 1,
            currentQuestionId: '',
            currentQuestion: '',
            answerOptions: ['1', '2', '3', '4', '5'],
            currentAnswer: '0',
            answerState: new Map<string, string>(),
            result: {
                AGREEABLENESS: {score: 0},
                CONSCIENTIOUSNESS: {score: 0},
                EXTROVERSION: {score: 0},
                NERVOSITY: {score: 0},
                OPENNESS: {score: 0}
            },
            viewState: VIEW_STATE.LOADING,
            userData: null,
            jwt: null,
            loading: true,
            reportLink: '',
            quizVersion: 'v1'
        };
        this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
        this.handleBackSelected = this.handleBackSelected.bind(this);
        this.handleStartQuiz = this.handleStartQuiz.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
    }

    async componentDidMount() {
        this.setState({loading: true});
        i18n.use(initReactI18next)
            .use(LanguageDetector)
            .init({
                debug: true,
                resources: {
                    en: {translation :translationEN},
                    fr: {translation : translationFR}
                },
                fallbackLng:'en',
                whitelist: ["en", "fr"],
            });
        const qv = queryString.parse(window.location.search)['quizVersion'];
        let shuffledAnswerOptions = this.shuffleArray(i18n.language=='en'?quizQuestions:quizQuestionsFR);
        console.log(`current lang is ${i18n.language} so showing ${i18n.language=='en'?'quizQuestions':'quizQuestionsFR'}`)

        if (qv === 'v2') {
            console.log("showing v2");
            shuffledAnswerOptions = this.shuffleArray(quizQuestionsV2);
        } else {
            console.log("showing v1")
        }
        this.setState({
            quizVersion: qv,
            questions: shuffledAnswerOptions,
            currentQuestionId: shuffledAnswerOptions[0].id,
            currentQuestion: shuffledAnswerOptions[0].question
        });

        ReactGA.initialize('UA-188439840-1', {
            debug: false,
            gaOptions: {
                siteSpeedSampleRate: 100
            }
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

        if (window.location.href.includes("/user")) {
            await this.refreshUserData().then(value => {
                this.setState({loading: false});
            });
        } else {
            this.setState({loading: false});
        }


    }


    private async refreshUserData() {
        this.setState({loading: true});
        if (!this.props.auth0.isLoading) {
            ReactGA.set({userId: this.props.auth0.user?.username})
            await this.props.auth0.getAccessTokenSilently({
                audience: 'quiz-backend'
            }).then(token => {
                this.setState({'jwt': token})
                fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/user-data', {
                    headers: {
                        'Authorization': `Bearer ${this.state.jwt}`,
                    }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        if (response.status === 204) {
                            return ({quiz: []})
                        }
                    }
                }).then(data => {
                    const userData = data;
                    //migrate old reports
                    if (userData && userData['quiz']) {
                        for (let i = 0; i < userData['quiz'].length; i = i + 1) {
                            let qr = userData['quiz'][i]['quizResults']
                            if (!qr['AGREEABLENESS'].hasOwnProperty("score")) {
                                userData['quiz'][i]['quizResults'] = {
                                    'OPENNESS': {'score': qr['OPENNESS']},
                                    'CONSCIENTIOUSNESS': {'score': qr['CONSCIENTIOUSNESS']},
                                    'EXTROVERSION': {'score': qr['EXTROVERSION']},
                                    'AGREEABLENESS': {'score': qr['AGREEABLENESS']},
                                    'NERVOSITY': {'score': qr['NERVOSITY']}
                                }
                            }
                        }
                    }
                    if(userData && ! userData.level){
                        userData.level = UserLevel.PUBLIC;
                    }

                    this.setState({userData: userData, loading: false});
                }).catch(reason =>
                {
                    console.log("caught error during login, try redirect to ", window.location.href)
                    console.log(reason);
                    //this.props.auth0.loginWithRedirect({redirectUri:window.location.origin+"/callback",appState: { targetUrl: window.location.pathname },ui_locales: i18n.language})

                });
            }).catch(reason => {
                console.log("caught error during login, try redirect to ", window.location.href)
                console.log(reason);
                //this.props.auth0.loginWithRedirect({redirectUri:window.location.origin+"/callback",appState: { targetUrl: window.location.pathname },ui_locales: i18n.language})
            });
        } else {
            //console.log("can't refresh user data while auth is loading");
            setTimeout(() => this.refreshUserData().then(value => this.setState({loading: false})), 50)
        }
    }

    shuffleArray(array: any) {
        var currentIndex = array.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    };


    handleBackSelected(event: any) {
        if (this.state.counter === 0) {
            this.setState({viewState: VIEW_STATE.INTRO});
        } else {
            const counter = this.state.counter - 1;
            const questionId = this.state.questions[counter].id;
            this.setState({
                counter: counter,
                currentQuestionId: questionId,
                currentQuestion: this.state.questions[counter].question,
                currentAnswer: this.state.answerState.get(questionId) as string
            });
        }

    }

    handleStartQuiz() {
        this.setState({viewState: VIEW_STATE.QUIZ});
    }

    handleAnswerSelected(event: any) {
        this.setUserAnswer(event.currentTarget.value);
        if (this.state.counter < this.state.questions.length - 1) {
            setTimeout(() => this.setNextQuestion(), 500);
        } else {
            setTimeout(() => this.getResults(), 300);
        }
    }

    setUserAnswer(answer: string) {
        let answerState = this.state.answerState
        answerState.set(this.state.currentQuestionId, answer)
        this.setState({currentAnswer: answer, answerState: answerState})
    }

    setNextQuestion() {
        if (this.state.counter < this.state.questions.length - 1) {
            const counter = this.state.counter + 1;
            const currentQuestionId = this.state.questions[counter].id;
            this.setState({
                counter: counter,
                currentQuestionId: currentQuestionId,
                currentQuestion: this.state.questions[counter].question,
                currentAnswer: '0'
            });
        }
    }

    deleteReport(reportId: string) {
        let url = 'https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/quiz?reportId=' + reportId;
        //this.setState({loading: true})
        fetch(url, {
            method: 'DELETE',

            headers: {
                'Authorization': `Bearer ${this.state.jwt}`,
            }
        }).then(response => {
            if (response.status === 200 || response.status === 201) {
                this.refreshUserData();
            }

        }).finally(() => {
           // this.setState({loading: false});
        })
    }

    getResults() {
        this.setState({counter: this.state.counter + 1})
        const convMap: any = {};
        this.state.answerState.forEach((val: string, key: string) => {
            convMap[key] = val;
        });

        let url = 'https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/quiz';
        if (this.state.quizVersion === 'v2') {
            url = url + '-v2'
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify({answerState: convMap}),
            headers: {
                'Authorization': `Bearer ${this.state.jwt}`,
            }
        }).then(response => {
            if (response.status === 200 || response.status === 201) {
                return response.json();
            }
        }).then(data => {
            this.refreshUserData().then(() => {
                console.log(data);
                this.setState({result: data, reportLink: `/user/premium/view/${data['quizId']}`});
            });
        }).finally(() => {
            this.setState({loading: false});
        });
    }



    renderQuiz() {
        const meaningMap = (i18n) => new Map(Object.entries({
            "1": {label:i18n.t('1 - I strongly disagree'),hotkey:"1"},
            "2": {label:i18n.t('2 - I disagree'),hotkey:"2"},
            "3": {label:i18n.t('3 - I am neutral'),hotkey:"3"},
            "4": {label:i18n.t('4 - I agree'),hotkey:"4"},
            "5": {label:i18n.t('5 - I strongly agree'),hotkey:"5"}
        }))

        return (
            !this.props.auth0.isLoading && this.state.userData ?
                <Quiz
                    answer={this.state.currentAnswer}
                    answerOptions={this.state.answerOptions}
                    questionId={this.state.currentQuestionId}
                    question={this.state.currentQuestion}
                    questionTotal={this.state.questions.length}
                    onAnswerSelected={this.handleAnswerSelected}
                    onBack={this.handleBackSelected}
                    counter={this.state.counter}
                    reportLink={this.state.reportLink}
                    meaningMap={meaningMap}
                />
                 : <Loading/>
        )
    }

    renderResult() {
        return (
            // @ts-ignore
            <Result quizResult={this.state.result} username={this.props.auth0.user.name}/>
        );
    }

    renderIntro() {
        return (
            <Intro action={this.handleStartQuiz}/>
        );
    }

    renderLoading() {
        return (
            <Loading/>
        );
    }

    gated() {
        // @ts-ignore
        return ((this.state.loading) ? <Loading/> :
                <div className="App">
                    <header>
                            <MyOceanityNav userData={this.state.userData}/>
                    </header>
                    <Router>
                        <Switch>
                            <Route path="/quiz/fake/o/:o/c/:c/e/:e/a/:a/n/:n">
                                <Fake/>
                            </Route>
                            <Route path="/360/anonymous/feedback/:signature">
                                <_360AnonymousResponse/>
                            </Route>
                            <PrivateRoute path="/user/premium/view/:reportId">
                                {this.state.userData && !this.props.auth0.isLoading ?
                                    <ViewReport userData={this.state.userData}
                                                // @ts-ignore
                                                username={this.props.auth0.user.nickname}/> : <Loading/>}
                            </PrivateRoute>
                            <ProRoute path="/user/pro/360" level={this.state.userData?.level}>
                                {this.state.userData && !this.props.auth0.isLoading ?
                                    <_360MainPage userData={this.state.userData} jwt={this.state.jwt}/> : <Loading/>}
                            </ProRoute>
                            <PrivateRoute path="/user/premium/my-assessments">
                                {this.state.userData && !this.props.auth0.isLoading ?
                                    <MyReports userData={this.state.userData}
                                               deleteReportHandler={this.deleteReport}/> : <Loading/>}

                            </PrivateRoute>
                            <PrivateRoute path="/user/premium/quiz">
                                {this.state.userData ? this.renderQuiz() : <Loading/>}
                            </PrivateRoute>
                            <PrivateRoute path="/user/premium/send-invite">
                                {this.state.userData && !this.props.auth0.isLoading ?
                                    <CompareProfile quiz={this.state.userData.quiz}
                                                    // @ts-ignore
                                                    username={this.props.auth0.user.username ? this.props.auth0.user.username : this.props.auth0.user.name}
                                                    chosenName={this.state.userData.chosenName}
                                                    inviteCount={this.state.userData.inviteCount}
                                                    userId={this.state.userData.uid}
                                                    //backwards compatibility for  users who were "paid" and newer users who have payment levels
                                                    userPaid={this.state.userData.paid || this.state.userData.level == UserLevel.PRO || this.state.userData.level == UserLevel.PREMIUM}
                                                    // @ts-ignore
                                                    jwt={this.state.jwt} userEmail={this.props.auth0.user.email}
                                                    profileSharedWith={this.state.userData.profileSharedWith}/> :
                                    <Loading/>}
                            </PrivateRoute>
                            <PrivateRoute path="/user/premium/compare/:otherUid">
                                {this.state.userData && !this.props.auth0.isLoading ?
                                    <CompareReport quiz={this.state.userData.quiz}
                                        // @ts-ignore
                                                   username={this.props.auth0.user.nickname}
                                                   jwt={this.state.jwt}/> : <Loading/>}
                            </PrivateRoute>
                            <PrivateRoute path="/user/premium/accept-invite/:inviteCode">
                                {this.state.userData && !this.props.auth0.isLoading ?
                                    <InviteAccepted quiz={this.state.userData.quiz}
                                        // @ts-ignore
                                                    username={this.props.auth0.user.nickname}
                                                    jwt={this.state.jwt}/> : <Loading/>}
                            </PrivateRoute>
                            <Route path="/contact/">
                                <ContactForm/>
                            </Route>
                            <Route path="/what-is-myoceanity">
                                <WhatIsMyOceanity/>
                            </Route>
                            <Route path="/how-does-it-work">
                                <HowDoesItWork/>
                            </Route>
                            <Route path="/who-we-are">
                                <WhoWeAre/>
                            </Route>
                            <Route path="/faq">
                                <FAQ/>
                            </Route>
                            <Route path="/ocean-methodology/">
                                <OceanMethodology/>
                            </Route>
                            <Route path="/ei-course-overview/">
                                <EICourseOverview/>
                            </Route>
                            <Route path="/organize-an-event/">
                                <OrganizeAnEvent/>
                            </Route>
                            <Route path="/donations/">
                                <Donations/>
                            </Route>
                            <Route path="/what-we-do">
                                <WhatWeDo/>
                            </Route>
                            <Route path="/myoceanity-and-ei">
                                <MyOceanityAndEI/>
                            </Route>
                            <Route path="/oceanology">
                                <Oceanology/>
                            </Route>
                            <Route path="/ocean-personality-traits">
                                <OceanPersonalityTraits/>
                            </Route>
                            <Route path="/what-is-ei">
                                <WhatIsEI/>
                            </Route>
                            <Route path="/why-ei-matters">
                                <WhyEIMatters/>
                            </Route>
                            <Route path="/grow-your-ei">
                                <GrowYourEI/>
                            </Route>
                            <Route path="/ei-course">
                                <EICourse/>
                            </Route>
                            <Route path="/ei-benefits-home">
                                <EIBenefitsHome/>
                            </Route>
                            <Route path="/ei-benefits-work">
                                <EIBenefitsWork/>
                            </Route>
                            <Route path="/strategies-for-ei">
                                <StrategiesForEI/>
                            </Route>
                            <Route path="/core-emotions">
                                <CoreEmotions/>
                            </Route>
                            <Route path="/emotional-literacy">
                                <EmotionalLiteracy/>
                            </Route>
                            <Route path="/reading-emotions">
                                <ReadingEmotions/>
                            </Route>
                            <Route path="/identity-introduction">
                                <IdentityIntroduction/>
                            </Route>
                            <Route path="/ikigai">
                                <Ikigai/>
                            </Route>
                            <Route path="/identity-mission">
                                <IdentityMission/>
                            </Route>
                            <Route path="/identity-values">
                                <IdentityValues/>
                            </Route>
                            <Route path="/identity-beliefs">
                                <IdentityBeliefs/>
                            </Route>
                            <Route path="/journaling-self-awareness">
                                <JournalingSelfAwareness/>
                            </Route>
                            <Route path="/journaling-self-development">
                                <JournalingSelfDevelopment/>
                            </Route>
                            <Route path="/journaling-introspection">
                                <JournalingIntrospection/>
                            </Route>
                            <Route path="/updates">
                                <Updates/>
                            </Route>
                            <Route path="/resources">
                                <Resources/>
                            </Route>
                            <Route path="/support-and-donations">
                                <SupportAndDonations/>
                            </Route>
                            <Route path="/events">
                                <Events/>
                            </Route>
                            <Route path="/privacy-policy">
                                <PrivacyPolicy/>
                            </Route>
                            <Route path="/elements-of-trust">
                                <ElementsOfTrust/>
                            </Route>
                            <Route path="/sample-personal-report">
                                <SamplePersonalReport/>
                            </Route>
                            <Route path="/sample-comparison-report">
                                <SampleComparisonReport/>
                            </Route>
                            <Route path="/demo-personal-report">
                                <DemoMyReports/>
                            </Route>
                            <Route path="/demo-my-people">
                                <DemoCompareProfile/>
                            </Route>
                            <Route path="/sharpen-the-axe">
                                <SharpenTheAxe/>
                            </Route>
                            <Route path="/fulfillment">
                                <Fulfillment/>
                            </Route>
                            <Route path="/openness-to-experience">
                                <OpennessToExperience/>
                            </Route>
                            <Route path="/openness-facets">
                                <OpennessFacets/>
                            </Route>
                            <Route path="/ocean-misunderstandings">
                                <OceanMisunderstandings/>
                            </Route>
                            <Route path="/360-content">
                                <ThreeSixtyFeedbackContent/>
                            </Route>
                            <Route path="/your-growth">
                                <YourGrowth/>
                            </Route>
                            <Route path="/stepwizard-test">
                                <StepWizardTest/>
                            </Route>
                            <Route path="/callback">
                                <Redirect to={"/"}/>
                            </Route>
                            <Route path="/">
                                <Home/>
                            </Route>
                        </Switch>
                    </Router>
                </div>
        )
    }

    render() {
        const history = createBrowserHistory();

        const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
        if (path) {
            history.replace(path);
        }
        return this.gated()

    }
}



export default withAuth0(App);