import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown';


import * as Icon from 'react-bootstrap-icons';
import axeimg from "../images/axe.jpg";



function SharpenTheAxe() {
    const {i18n} = useTranslation();

	return (
		<div className="masthead">
			<div className="separator"></div>

			<div className="myimghor">
				<img src={axeimg} alt="Sharp axe" />
			</div>

			<div className="imgcaption">
				<p>Would you rather work harder or smarter?</p>
                <p>{i18n.t('Photo by')} <a href="https://www.pexels.com/@lum3n-44775">Lum3n</a> {i18n.t('from')} <a
                    href="https://www.pexels.com/photo/brown-wooden-axe-besides-brown-leather-knife-holster-167696/">Pexels</a>
                </p>
            </div>

			<h2>Sharpen the Axe: Work Smarter, Not Harder</h2>

			<section className="story">
				<h4>The Tale of the Two Woodcutters</h4>
				<p>Once upon a time, there were two mighty woodcutters. To determine who was the strongest, they decided to see who could cut down the most trees from dawn to dusk.</p>
				<p>Every so often, the elder stopped and sat down to rest, asking the younger to join him.</p>
				<p>“Not a chance, old man,” said the younger between two swings of his axe, “the more I cut while you rest, the more trees I will chop down. Victory will be mine!”</p>
				<p>When the last lights of the day shone over the horizon, the winner was clearly the elder.</p>
				<p>“That is impossible!” Shouted the younger with a bemused look on his face. “How could you have achieved so much with so little effort?”</p>
				<p>“It’s simple, my dear friend,” said the elder with a warm and compassionate smile, “every time I sat down to rest my body, I took the time to sharpen my axe. Yours, meanwhile, became dull, just like your body grew weary. Each of your swings lost some power, whereas mine never relented.”</p>
			</section>

			<h3>What This Means For You | Moral of the Story</h3>
			<ReactMarkdown>We live in a fast-paced world which never stops, which makes us believe that we, too, should never stop working. We think that if we can do *this one last task* or answer *this one last email*, then we will prevail.</ReactMarkdown>
			<p>However, like a sharp axe can cut down more trees than a dull one, a fresh and focused mind can achieve more than an exhausted and distracted one.</p>
			<ReactMarkdown>**Recovery matters just as much as activity for your productivity**. Remember that busyness does not mean productivity and can actually hurt business. Do not mistake movement for progress.</ReactMarkdown>
			
			<h4>What You Can Do</h4>
			<ReactMarkdown>**Take breaks, get enough rest**, and you will achieve more than if you tried to plough through your challenges.</ReactMarkdown>
			<ReactMarkdown>**Pausing and reflecting** on how to tackle your tasks can inspire you ideas on how to work smarter, not harder. You may find that coming back to what seemed like an impossible problem with a different mindset may prove it doable.</ReactMarkdown>
			<ReactMarkdown>**Learn from your previous mistakes**, sharpen your axe multiple times during the day - especially at work - and you will be surprised of how much you can achieve without having to sacrifice your health, your well-being or your relationships.</ReactMarkdown>

			<div className="separator"></div>

			<div className="content-btn-box">
				<a href="/ei-course" className="content-btn">Back to the course<Icon.ArrowRight className="ml-2" /></a>
			</div>

			<div className="separator"></div>
			<div className="separator"></div>

		</div>
	)
}
export default SharpenTheAxe;

