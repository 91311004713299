import React from 'react';
import {AnswerOptionProps} from '../components/types';


function AnswerOption(props:AnswerOptionProps) {
    return (
      <li className="answerOption">
        <input
          type="checkbox"
          className="radioCustomButton"
          name="radioGroup"
          checked={props.selected}
          id={`${props.questionId}-${props.answer}`}
          value={props.answer}
          onClick={props.onAnswerSelected}
        />
        <label className="radioCustomLabel" htmlFor={`${props.questionId}-${props.answer}`}>
          {props.meaning}
        </label>
      </li>
    );
  }
  export default AnswerOption;