import React from 'react';

function OceanMethodology(props : any) {
    return (
      <div>
      <br /><br />

      <h3>Your feedback and comments are always welcome</h3>
      <br />
        <h4> Please contact us at <a href="mailto:contact@myoceanity.com">contact@myoceanity.com</a></h4>
      </div>
    );
  }
export default OceanMethodology;
