import React from 'react';
import { Badge, Card, CardDeck, Image } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';

import * as Icon from 'react-bootstrap-icons';

import { useTranslation } from "react-i18next";

import comparisonexample from "../images/comparison-example.png";
import chrispersonalitytraits from "../images/chris-ocean-traits.png";
import chriscomparaisontraits from "../images/chris-traits-comparisons.png";
import comfortingimg from "../images/comforting.jpg";
import groupoffriends from "../images/group-of-friends.jpg";
import feedback2img from "../images/feedback-2.jpg";
import classroomimg from "../images/classroom.jpg";
import teamworkhandsimg from "../images/teamwork-hands.jpg";
import teamimg from "../images/team.jpg";
import happyimg from "../images/happy.jpg";
import biasimg from "../images/bias.jpg";
import strengthimg from "../images/strength.jpg";
import motivationimg from "../images/motivation.jpg";
import meetingimg from "../images/meeting.jpg";
import heartheadhandshake from "../images/heart-head-handshake.jpg";
import mirrorimg from "../images/mirror.jpg";
import studentsimg from "../images/students.jpg";
import feedbackladiesimg from "../images/feedback-ladies.jpg";

import edworkplaceimg from "../images/ed-workplace.png";
import edagileeqimg from "../images/ed-agile-eq.png";
import edmanagementimg from "../images/ed-management.jpg";
import edwolimg from "../images/ed-wol.png";
import edconflictimg from "../images/ed-conflict.png";
import edsalesimg from "../images/ed-sales.png";
import edauthorizedpartnerimg from "../images/ed-authorized-partner.png";



function YourGrowth() {
    const { i18n } = useTranslation();

    return (
        <div>
            <body className="masthead" data-spy="scroll" data-offset="60" data-target="#myNavbar">
		   
           <Scrollspy items={ ['section-1', 'section-2'] } currentClassName="is-current" className="sticky-top scrollspy overflow-scroll-horizontal" offset={20}>
                <li><a href="#section-1" className="scrollspy-link">1. Grow Your Emotional Intelligence</a></li>
                <li><a href="#section-2" className="scrollspy-link">2. Accelerate Your Career</a></li>
            </Scrollspy>

            <div className="myimghor w-100 my-0">
				<img src={meetingimg} alt="Group of friends together" />
			</div>

			<div className="imgcaption">
				MyOceanity's mission is to help you realize your potential
			</div>
			
            <section id="section-1" className="offset-anchor">
            <div className="separator" />

            <div className="dot-container">
                <span className="dot">1</span>
            </div>
            <h1 className="mt-3">Grow Your Emotional Intelligence</h1>
                
            <div className="separator" />
            
            <section className="course-tables-center">

                <CardDeck className="mb-3">
                    <Card className="hover-increase" style={{ width: '18rem' }}>
                        <Card.Img as={Image} src={mirrorimg} variant="top" className="mb-3" />
                        <Card.Title className="m-3">Discover Your Big Five personality Traits<Icon.ArrowRight className="ml-2" /></Card.Title>
                        <br />
                        <a href="/user/premium/my-assessments" className="stretched-link"></a>
                    </Card>

                    <Card className="hover-increase" style={{ width: '18rem' }}>
                        <Card.Img as={Image} src={studentsimg} variant="top" className="mb-3" />
                        <Card.Title className="m-3">Compare Your Traits With People You Know<Icon.ArrowRight className="ml-2" /></Card.Title>
                        <br />
                        <a href="/user/premium/send-invite" className="stretched-link"></a>
                    </Card>

                    <Card className="hover-increase" style={{ width: '18rem' }}>
                        <Card.Img as={Image} src={heartheadhandshake}  className="mb-3" />
                        <Card.Title className="m-3">How Emotionally Intelligent Are You?<Icon.ArrowRight className="ml-2" /> <br /> <Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
                    </Card>
                </CardDeck>

                <CardDeck className="mb-3">
                    <Card className="hover-increase" style={{ width: '18rem' }}>
                        <Card.Img as={Image} src={strengthimg} variant="top" className="mb-3" />
                        <Card.Title className="m-3">Discover Your Strengths<Icon.ArrowRight className="ml-2" /><Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
                    </Card>

                    <Card className="hover-increase" style={{ width: '18rem' }}>
                        <Card.Img as={Image} src={comfortingimg} variant="top" className="mb-3" />
                        <Card.Title className="m-3">Your Empathy Levels<Icon.ArrowRight className="ml-2" /> <br /> <Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
                    </Card>

                    <Card className="hover-increase" style={{ width: '18rem' }}>
                        <Card.Img as={Image} src={happyimg} variant="top" className="mb-3" />
                        <Card.Title className="m-3">How Happy Are You?<Icon.ArrowRight className="ml-2" /><Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
                    </Card>
                </CardDeck>

                <CardDeck className="mb-3">
                    <Card className="hover-increase" style={{ width: '18rem' }}>
                        <Card.Img as={Image} src={classroomimg} variant="top" className="mb-3" />
                        <Card.Title className="m-3">Explore the "Fundamentals of EI" Course<Icon.ArrowRight className="ml-2" /></Card.Title>
                        <a href="/ei-course" className="stretched-link"></a>
                    </Card>
                </CardDeck>
            </section>
            </section>

            <div className="separator" />

            <section id="section-2" className="offset-anchor">
            <div className="separator" />

            <div className="dot-container">
                <span className="dot">2</span>
            </div>        


            <h1 className="mt-3">Accelerate Your Career</h1>
                
                <div className="separator" />
                
                <section className="course-tables-center">
    
                    <CardDeck className="mb-3">
                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={feedback2img} variant="top" className="mb-3" />
                            <Card.Title className="m-3">360 Degree Feedback: How Others Perceive You and Your Skills <Icon.ArrowRight className="ml-2" /></Card.Title>
                            <a href="/user/pro/360" className="stretched-link"></a>
                        </Card>
    
                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={motivationimg} variant="top" className="mb-3" />
                            <Card.Title className="m-3">What Motivates You? What Keeps You Engaged?<Icon.ArrowRight className="ml-2" /><Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
                        </Card>

                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={teamimg} variant="top" className="mb-3" />
                            <Card.Title className="m-3">What Makes You Thrive at Work? <Icon.ArrowRight className="ml-2" /> <br /> <Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
                        </Card>
                    </CardDeck>
    
                    <CardDeck className="mb-3">
                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={classroomimg} variant="top" className="mb-3" />
                            <Card.Title className="m-3">Leadership Effectiveness Survey<Icon.ArrowRight className="ml-2" /> <br /> <Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
                        </Card>
    
                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={teamworkhandsimg} variant="top" className="mb-3" />
                            <Card.Title className="m-3">What Great Teams Do Differently<Icon.ArrowRight className="ml-2" /> <br /> <Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
                        </Card>

                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={biasimg} variant="top" className="mb-3" />
                            <Card.Title className="m-3">How To Overcome Your Natural Biases<Icon.ArrowRight className="ml-2" /> <br /> <Badge pill className="pill px-2 py-1">Coming Soon</Badge></Card.Title>
                        </Card>
                    </CardDeck>
                </section>

            <div className="separator" />

            <h3>Discover The Everything DiSC® Solutions</h3>
            
            <div className="myimgmini">
                <img src={edauthorizedpartnerimg} alt="Everything DiSC A Wiley Brand Authorized Partner Certificate" />
			</div>

                <div className="separator" />
                <div className="separator" />
                
                <section className="course-tables-center">
    
                    <CardDeck className="mb-3">
                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={edworkplaceimg} variant="top" className="mb-3 mx-2" />
                            <Card.Title className="m-3">Everything DiSC® Workplace<Icon.ArrowRight className="ml-2" /></Card.Title>
                        </Card>
    
                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={edagileeqimg} variant="top" className="mb-3 mx-2" />
                            <Card.Title className="m-3">Everything DiSC® Agile EQ<Icon.ArrowRight className="ml-2" /></Card.Title>
                        </Card>

                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={edmanagementimg} variant="top" className="mb-3 mx-2" />
                            <Card.Title className="m-3">Everything DiSC® Management Style<Icon.ArrowRight className="ml-2" /></Card.Title>
                        </Card>
                    </CardDeck>
    
                    <CardDeck className="mb-3">
                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={edwolimg} variant="top" className="mb-3 mx-2" />
                            <Card.Title className="m-3">Everything DiSC® Work of Leaders<Icon.ArrowRight className="ml-2" /></Card.Title>
                        </Card>
    
                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={edconflictimg} variant="top" className="mb-3 mx-2" />
                            <Card.Title className="m-3">Everything DiSC® Productive Conflict<Icon.ArrowRight className="ml-2" /></Card.Title>
                        </Card>

                        <Card className="hover-increase" style={{ width: '18rem' }}>
                            <Card.Img as={Image} src={edsalesimg} variant="top" className="mb-3 mx-2" />
                            <Card.Title className="m-3">Everything DiSC® For Salespeople <Icon.ArrowRight className="ml-2" /></Card.Title>
                        </Card>
                    </CardDeck>
                </section>

                </section>
            

            <div className="myimg">
				<img src={groupoffriends} alt="Group of friends together" />
			</div>

            <div className="separator" />
            
            </body>
        </div>);
}

export default YourGrowth;