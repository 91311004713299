export interface AnswerOptionProps {
  answer :  string
  meaning : string
  onAnswerSelected : any
  questionId : string
  selected : boolean
}

export interface StyleScore {
	score : number
	subStyles ? : OSubstyle|CSubstyle|ESubstyle|ASubstyle|NSubstyle
}

export interface ResultStyle {
	AGREEABLENESS: StyleScore
	CONSCIENTIOUSNESS: StyleScore
	EXTROVERSION: StyleScore
	NERVOSITY: StyleScore
	OPENNESS: StyleScore
}

export interface ResultProps {
	username : string
	quizResult : {
		AGREEABLENESS: StyleScore
		CONSCIENTIOUSNESS: StyleScore
		EXTROVERSION: StyleScore
		NERVOSITY: StyleScore
		OPENNESS: StyleScore
	}
}

export interface _360SessionResponse{

}

export interface _360Session {
	createdOn : string
	sendCount : number
	sessionId : string
	responses? : Array<_360SessionResponse>
	userAnswers? : object
}

export enum UserLevel {
	PUBLIC = "PUBLIC",
	PREMIUM = "PREMIUM",
	PRO = "PRO"
}
export interface UserData {
	uid : string,
	username : string,
	chosenName? : string,
	quiz : Array<QuizReport>,
	paid : boolean,
	profileSharedWith : Array<string>
	inviteCount? : number
	feedback360Sessions? : Array<_360Session>
	level? : UserLevel
}

export interface MyReportProps {
	userData : UserData | null
	deleteReportHandler : Function
}
export interface QuizReport {
	quizId : string,
	completedOn : string,
	quizResults : ResultStyle
}

export interface ViewReportProps {
	userData : UserData,
	username : string
}

export interface StyleResultPros {
	style : "Openness"|"Conscientiousness"|"Extroversion"|"Agreeableness"|"Nervosity"
	subStyle? : OSubstyle|CSubstyle|ESubstyle|ASubstyle|NSubstyle
	score : number
	wording : any
}

export interface OSubstyle {
	Fantasy : number
	Aesthetics : number
	Emotionality : number
	Adventure : number
	Ideas : number
	Values : number
}

export interface CSubstyle {
	SelfEfficacy : number
	Orderliness : number
	Dutifulness : number
	AchievementStriving : number
	SelfDiscipline : number
	Cautiousness : number
}
export interface ESubstyle {
	Warmth : number
	Gregariousness: number
	Assertiveness : number
	ActivityLevel : number
	ExcitementSeeking :number
	Cheerfulness : number
}

export interface ASubstyle {
	Straightforwardness : number,
	Altruism : number,
	Compliance : number,
	Modesty : number,
	Trust : number,
	TenderMindedness : number
}

export interface  NSubstyle {
	Anxiety : number
	Anger : number
	Depression : number
	SelfConsciousness : number
	Impulsiveness : number
	Vulnerability : number
}

export interface QuizProps {
	answer: string
	answerOptions: Array<string>
	counter: number
	question : string
	questionId : string
	questionTotal : number
	onAnswerSelected : any
	onBack : any
	reportLink : string
	meaningMap : any
}

export interface QuestionCountProps {
  counter:number
  total:number
}


export interface QuestionProps {
	content : string
}