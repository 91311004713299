import React from 'react';
import { Nav, Navbar, Button, Dropdown } from 'react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser, faGlobeAfrica,faUserTie,faStar } from '@fortawesome/free-solid-svg-icons';
import {UserLevel} from "./types";

function getFlag(language: string) {
    switch (language){
        case 'en' : return "🇬🇧"
        case 'fr' : return "🇫🇷"
    }
}

function MyOceanityNav(props :any) {

    const { i18n } = useTranslation();
    const { isAuthenticated, loginWithRedirect, logout, user, isLoading } = useAuth0()

        function userLevel() {
        console.log(props);
        if (!props.userData || !props.userData.level || props.userData.level == UserLevel.PUBLIC) {
            return null;
        }else{
            if (props.userData.level == UserLevel.PREMIUM){
                return <FontAwesomeIcon title="Level : premium" className="mr-2" icon={faStar}/>;
            }else{
                if (props.userData.level == UserLevel.PRO){
                    return <FontAwesomeIcon title="Level : Pro" className="mr-2" icon={faUserTie}/>;

                }
            }
        }
    }

    const showAuth = () => {
        if(!isLoading) {
            if(isAuthenticated) {
                return (<span className="ml-auto menuNickname"> {i18n.t('hi')} {user?.nickname} {userLevel()} <Button className="ml-3 login-btn" onClick={() => logout({ returnTo: window.location.origin })}>{i18n.t('sign-out')}</Button></span>);
            } else {
                return (<div className="ml-auto"> <Button variant="outline-success" className="ml-2 login-btn" onClick={() => loginWithRedirect({ appState: { targetUrl: window.location.pathname } ,ui_locales: i18n.language})}>{i18n.t('start-here')}</Button></div>);
            }
        }   
    };
    
    return (
        <div>
            <Navbar fixed={props.fixed} className="navWrapper" bg="light" variant="light" expand="lg">
                <Navbar.Brand className="logo" href="/">MYOCEANITY</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">

                    <Nav className="mr-2">
                        <Nav.Link title="Course" id="collasible-nav-dropdown" href="/ei-course" >
                            <div className="navbartext">{i18n.t('Course')}</div>
                        </Nav.Link>
                    </Nav>

                    <Nav className="mr-2">
                        <Nav.Link title="My Traits" id="collasible-nav-dropdown" href="/your-growth" >
                        <div className="navbartext"><FontAwesomeIcon icon={faUser} className="mr-2" /> {i18n.t('My Traits')}</div>
                        </Nav.Link>
                    </Nav>

                    <Nav className="mr-2">
                        <Nav.Link title="Contact Us" id="collasible-nav-dropdown" href="/contact" >
                             <div className="navbartext">{i18n.t('Contact Us')}</div>
                        </Nav.Link>
                    </Nav>

                    <Nav className="mr-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                {getFlag(i18n.language)} {i18n.language}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onSelect={()=>i18n.changeLanguage('en')}>{getFlag('en')} English</Dropdown.Item>
                                <Dropdown.Item href="#" onSelect={()=>i18n.changeLanguage('fr')}>{getFlag('fr')} Francais</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>

                    <Nav className="ml-lg-auto">
                        {showAuth()}
                    </Nav>

                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}
export default MyOceanityNav;