import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Accordion, Alert, Button, Card, Col, Container, Row} from "react-bootstrap";
import Quiz from "./Quiz";
import StepWizard from "react-step-wizard";
import Loading from "./Loading";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import {questionsApi} from '../api/_360_questions'
import 'react-multi-email/style.css';
import ThreeSixtyFeedbackContent from "./360-feedback-content";
import i18n from "i18next";

import * as Icon from 'react-bootstrap-icons';


const EmailStep = (props) => {
    const [emails, setEmails] = useState<string[]>([])
    const [displayName,setDisplayName] = useState(props.chosenName)

    const sendEmails = (emails) => {
        props.setLoading(true)
        for (const email of emails) {
            console.log("send " + email);
        }
        fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/360-feedback-request', {
            method: 'POST',
            body: JSON.stringify({
                sessionId : props.sessionId,
                displayName : displayName,
                emails : emails,
                language : i18n.language
            }),
            headers: {
                'Authorization': `Bearer ${props.jwt}`,
            }
        }).then(response => {
            props.setLoading(false);
            if (response.status === 200) {
                response.json().then(res => {
                    props.setTotalSent(res.totalRequestsSent)
                    props.setResponsesSoFar(res.responsesReceived)
                })
            } else {
            }
        });
    }
    return (
        <div>
            <div  style={{margin: 10}}>
                <h4 className="my-3">Your Full Name</h4>
                
                <input key={`displayName+${props.hashKey}`} id={`displayName+${props.hashKey}`} value={displayName} onChange={e=>setDisplayName(e.currentTarget.value)}/>
                                
                <p className="my-4">(Your name will be displayed to your respondents in your invitation and their survey questions)</p>
            </div>

            <br />
           
            <div style={{margin: 10}}>
                <h4>Your Recipients' Email Adresses</h4>

                <br />

                <ReactMultiEmail
                    placeholder="Enter email addresses here"
                    emails={emails}
                    onChange={(_emails: string[]) => {
                        setEmails(_emails);
                    }}
                    validateEmail={email => {
                        return isEmail(email); // return boolean
                    }}
                    getLabel={(
                        email: string,
                        index: number,
                        removeEmail: (index: number) => void,
                    ) => {
                        return (
                            <div data-tag key={index}>
                                {email}
                                <span data-tag-handle onClick={() => removeEmail(index)}> × </span>
                            </div>
                        );
                    }}
                />
                
                <br />
                
                <p>(Each of your recipients will receive a unique and secured link, which will allow them to provide anonymous feedback.)</p>


                <Button className="login-btn" onClick={()=>sendEmails(emails)}>Send Invitation <Icon.ArrowRight className="ml-2"/></Button>
                
                <br />

                {props.totalSent>0 ? <Alert variant={'success'} > Total requests sent : {props.totalSent} | Responses received so far : {props.responsesSoFar}</Alert> : <div />}
            </div>

			<section className="justify-content-center d-flex flex-row my-2">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>

            <Button className="login-btn mr-2" onClick={props.previousStep}><Icon.ChevronLeft className="mr-2" />Back to quiz</Button>
                    
            <Button className="login-btn" onClick={props.nextStep}>View report <Icon.ClipboardData className="ml-2"/></Button>
        </div>)
}

function _360MainPage(props: any) {
    const {i18n} = useTranslation();
    const [sessionId, setSessionId] = useState(null);
    const questions = questionsApi[i18n.language]
    const [currentAnswer, setCurrentAnswer] = useState("0")
    const [counter, setCounter] = useState(0)
    const [reportLink, setReportLink] = useState("")
    const [answerState, setAnswerState] = useState(new Map<String, String>())
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [activeTab, setActiveTab] = useState("0")
    const [loading, setLoading]  = useState(false);
    const answerOptions = ["1", "2", "3", "4", "5", "6", "7"]
    const [emailsSent, setEmailsSent] = useState<string[]>([])
    const [totalSent, setTotalSent] = useState(0)
    const [responsesSoFar, setResponsesSoFar] = useState(0)
    const [_360Results, set360Results] = useState({user:{},feedback:{},feedbackByQuestion : {}})


    //setup session id
    useEffect(() => {
        const sessions = props.userData.feedback360Sessions;
        console.log(props.userData);
        if (sessions && sessions.length>0) {
            //take the most recent session
            let lastSession = sessions
                .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
                .reverse()[0]
            setSessionId(lastSession.sessionId);
            if(lastSession.userAnswers){
                //feed the previous responses
                let newAnswerState = answerState
                for (const [key, value] of Object.entries(lastSession.userAnswers)) {
                    newAnswerState.set(key, value as string)
                    setAnswerState(newAnswerState)
                }
                setCounter(questions.length-1)
                setQuizCompleted(true);
                setActiveTab("1");
            }
        } else {
            //if there are no sessions yet, we need to create one
            fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/360-report-init', {
                method: 'POST',
                body: JSON.stringify({}),
                headers: {
                    'Authorization': `Bearer ${props.jwt}`,
                }
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(res => {
                        if(res && res.feedback360Sessions) {
                            setSessionId(res.feedback360Sessions[0].sessionId)
                        }
                    })
                } else {
                }
            });
        }

    },[])


    const setUserAnswer = answer => {
        let newAnswerState = answerState
        newAnswerState.set(questions[counter].id, answer)
        setCurrentAnswer(answer)
        setAnswerState(newAnswerState)
    }
    const setNextQuestion = () => {
        if (counter < questions.length - 1) {
            setCounter(counter + 1)
            setCurrentAnswer('0')
        }
        //  console.log(counter,currentQuestionId,currentQuestion,answerState);
    }
    const getResults = () => {
        setLoading(true);
        setQuizCompleted(true);
        setActiveTab("1");
        const convMap: any = {};
        answerState.forEach((val, key) => {
            convMap[key as string] = val;
        });
        //if there are no sessions yet, we need to create one
        fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/360-report-init', {
            method: 'POST',
            body: JSON.stringify({
                sessionId: sessionId,
                answers: convMap
            }),
            headers: {
                'Authorization': `Bearer ${props.jwt}`,
            }
        }).then(response => {
            if (response.status === 200) {
                response.json().then(res => {
                    setLoading(false);
                })
            } else {
            }
        });
    }

    const handleAnswerSelected = event => {
        console.log("answer selected " + event.currentTarget.value)
        setUserAnswer(event.currentTarget.value);
        if (counter < questions.length - 1) {
            setTimeout(() => setNextQuestion(), 500);
        } else {
            setTimeout(() => getResults(), 300);
        }
    }

    const handleBackSelected = () => {
        if (counter > 0) {
            setCounter(counter - 1)
        }
    }


    const editAnswers = () => {
        setQuizCompleted(false)
    }

    const meaningMap = (i18n) => new Map(Object.entries({
        "1": {label:i18n.t('1 - I strongly disagree'),hotkey:"1"},
        "2": {label:i18n.t('2 - I disagree'),hotkey:"2"},
        "3": {label:i18n.t('3 - I somewhat disagree'),hotkey:"3"},
        "4": {label:i18n.t('4 - I neither agree nor disagree'),hotkey:"4"},
        "5": {label:i18n.t('5 - I somewhat agree'),hotkey:"5"},
        "6": {label:i18n.t('6 - I agree'),hotkey:"6"},
        "7": {label:i18n.t('7 - I strongly agree'),hotkey:"7"}
    }))

    const QuizStep = (props) => {
        return (
            <div>
                <h3> This tool allows you to get 360 feedback from others </h3>
                {!quizCompleted ? <Quiz
                    answer={answerState.get(questions[counter].id) as string}
                    answerOptions={answerOptions}
                    questionId={questions[counter].id}
                    question={questions[counter].userQuestion}
                    questionTotal={questions.length}
                    onAnswerSelected={handleAnswerSelected}
                    onBack={handleBackSelected}
                    counter={counter}
                    reportLink={reportLink}
                    meaningMap={meaningMap}
                    />:
                <Alert variant="success">
                    Congratulations! You've completed the self-assessment <Button variant="link" onClick={editAnswers}> (Edit
                    my answers) <Icon.ArrowRight className="ml-2"/></Button> 
                </Alert>
                }

                <Button className="login-btn" onClick={props.nextStep} disabled={!quizCompleted}>Send invitations <Icon.ArrowRight className="ml-2"/></Button>
                <Button className="login-btn" onClick={()=>props.goToStep('results')} disabled={!quizCompleted}>View report <Icon.ArrowRight className="ml-2"/></Button>
            </div>)
    }




    const ReportStep = (props) => {
        setLoading(true);
        useEffect(() => {
            //setLoading(true);
            if (props.isActive && props.sessionId && _360Results && Object.keys(_360Results.feedback).length ==0 ) {
                fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/360-report-build', {
                    method: 'POST',
                    body: JSON.stringify({
                        sessionId: props.sessionId
                    }),
                    headers: {
                        'Authorization': `Bearer ${props.jwt}`
                    }
                }).then(response => {
                    if (response.status === 200) {
                        response.json().then(res => {
                            setResponsesSoFar(res.responseCount);
                            setTotalSent(res.requestsSent);
                            set360Results(res.results);
                        })
                    } else {
                    }

                });
            }
            setLoading(false);
            },[]);

        setLoading(false);

        return (
            <div>
                {!loading && responsesSoFar<=2? <Alert variant={"warning"}>Not enough responses received to display the report: {responsesSoFar} response(s) completed</Alert>: <div />}
                {!loading && responsesSoFar>2? <ThreeSixtyFeedbackContent totalSent={totalSent} responsesSoFar={responsesSoFar} results={_360Results} userData={props.userData}/>:<div />}
                <Button className="login-btn" onClick={props.previousStep}>Send more feedback requests <Icon.ArrowRight className="ml-2"/></Button>
            </div>)
    }

    function stepChange(activeStep: number) {
        if (activeStep === 3){
            setLoading(true);
            console.log("step 3!");
            fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/360-report-build', {
                method: 'POST',
                body: JSON.stringify({
                    sessionId : sessionId
                }),
                headers: {
                    'Authorization': `Bearer ${props.jwt}`
                }
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(res => {
                        console.log(res);
                        setResponsesSoFar(res.responseCount)
                    })
                } else {
                }
                setLoading(false);
            });
        }
    }

    const content = () => {
        return (
            <div>
                <br/><br/>
                <StepWizard initialStep={3} isHashEnabled={true} onStepChange={(stats) => stepChange(stats.activeStep)}>
                    <QuizStep hashKey={'quiz'}/>
                    <EmailStep hashKey={'send-invites'} chosenName={props.userData['360chosenName']} jwt={props.jwt} sessionId={sessionId} totalSent={totalSent} responsesSoFar={responsesSoFar} setTotalSent={setTotalSent} setResponsesSoFar={setResponsesSoFar} setLoading={setLoading}/>
                    <ReportStep hashKey={'results'} jwt={props.jwt} sessionId={sessionId} name={props.userdata}/>
                </StepWizard>
{/*
                sessionId : {sessionId}
*/}
            </div>
        );
    }

    return !loading?content():<Loading />;
}

export default _360MainPage;
