import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import {loadStripe} from '@stripe/stripe-js';
import i18n from 'i18next';
import * as Icon from 'react-bootstrap-icons';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB!)

function UpgradeToPro (props :any) {

        return (
            <div className="masthead">
                <br/><br/><br/><br/>

                This functionality requires a Professional access level to myoceanity tools.

                Please click here if you would like to upgrade your account.
            </div>
        );

}

export default UpgradeToPro;