import React from 'react';
import { Table } from 'react-bootstrap';
import eilowperformers from "../images/ei-low-performers.png";
import eihighperformers from "../images/ei-high-performers.png";

function EIBenefitsWork(props: any) {
	   
  return (
	<div className="masthead">
		<h1>Benefits of Emotional Intelligence in the Workplace</h1>

		<h2 id="page-title">Is There a Link Between Emotional Intelligence & Performance at Work?</h2>
		
		<div className="notabene">
			Emotional intelligence is positively related to job performance, leadership, and physical and mental health. Let's explore why.
		</div>

		<div className="quote">
			<p><i>"Without emotional intelligence, a person can have the best training in the world, an incisive, analytical mind, and an endless supply of smart ideas, but they still won't make a great leader."</i></p>
			<p><strong>- Daniel Goleman, leading authority on emotional intelligence</strong></p>
		</div>
		
		<p>Bradberry & Greaves, authors of the book Emotional Intelligence 2.0, have helped thousands of people grow their emotional intelligence skills. In each of their interventions, they did two things:</p>
		
		<ol>
			<li>First, they assessed how emotionally intelligent a person currently was;</li>
			<li>Second, they assessed the performance of a person, by looking at their current and previous performance reviews, as well as by asking others feedback about the person - this is also known as 360-degree feedback surveys.</li>
		</ol>
		
		<p>To simplify, we can divide the population they studied and mentored into two groups: The best performers and the worst performers, according to their rankings in terms of job performance.</p>
		<p>Please take a moment to reflect on the following before reading on:</p>
		
		<ul>
			<li>What percentage of the <b>high-performers</b> do you think had <b>higher levels</b> of emotional intelligence?</li>
			<li>What percentage of the <b>low-performers</b> do you think had <b>lower levels</b> of emotional intelligence?</li>
		</ul>
		
		<p>What about Bradberry & Greaves' results? Have a look:</p>
		
		<div className="myimghor w-75">
			<img src={eihighperformers} alt="Emotional intelligence and higher performance" />
		</div>

		<div className="imgcaption">
			<p><b>90% of the high-performers were also highly skilled in emotional intelligence</b></p>
		</div>

		<div className="myimghor w-75">
			<img src={eilowperformers} alt="Emotional intelligence and lower performance" />
		</div>

		<div className="imgcaption">
			<p><b>80% of the low-performers were also poorly skilled in emotional intelligence</b></p>
		</div>
		
		<p>This message also came with good news:</p>
		
		<div className="quote">
			<p>Emotional intelligence can be developed.</p>
			<p>And doing so will also increase job performance.</p>
		</div>

		<p>O’Boyle et al. found similarly encouraging results in their 2011 research, where they also highlighted that conscientiousness, one of the Big Five OCEAN personality traits, was also a good predictor of job performance.</p>
		<p>Based on my research, I believe there are 4 main benefits to growing your emotional intelligence in the workplace:</p>
		
		<ol>
			<li>Better health, both mental and physical</li>
			<li>Better leadership and working relationships</li>
			<li>More creativity and innovation</li>
			<li>Improved communication and negotiation skills</li>
		</ol>
		
		<p>Let's discuss each benefits in greater details.</p>

		<h2 id="better-health">1st Benefit | Healthier Hearts, Minds, and Bodies</h2>
		
		<div className="notabene">
			People with higher levels of emotional intelligence enjoy greater physical and mental health.
		</div>

		<ul>
			<li>Mentally, they are able to soothe their emotions, become calmer, more optimistic, and keep their eyes on their goals and their work, even during trials.</li>
			<li>Physically, they are able to better deal with stress, experiencing less destructive symptoms of chronic stress.</li>
		</ul>

		<p>Because they enjoy healthier levels of mental and physical health, they tend to be more resilient against obstacles and frustrations, as well as more persistent in their efforts after encountering setbacks and crisis. Ultimately, their grit will help them achieve what is meaningful in their lives as well as what they aspire to pursue.</p>
		<p>Humphrey’s research also suggests that <b>people working with emotionally intelligent leaders experience less stress and physical symptoms.</b></p>
		<p>If you are interested in more detailed research, you can read the 2007 Schutte et al. meta-analysis of 8000 participants, investigating the relationship between emotional intelligence and health.</p>
		

		<h2 id="leadership">2nd Benefit | Greater Leadership, Trust And Stronger Working Relationships</h2>
		
		<div className="notabene">
			People with higher levels of emotional intelligence are able to work more effectively with people and tend to be rated higher on leadership by their people.
		</div>
		
		<p>This can be explained by the fact that they are diligently trying to understand other people’s emotions, tendencies, and motivations in life, to see how they can best support them and assign them to tasks that will leverage their strengths.</p>
		<p>Work and leadership can be divided into two parts:</p>
		
		<ul>
			<li>Tasks, and</li>
			<li>Relationships</li>
		</ul>
		
		<p>Leading tasks - in other words, getting things done - is essential in making progress towards an organization’s objectives. People focus on WHAT they are doing and HOW they are doing it, without regards to HOW they interact with each other. This last aspect falls into leading people and building relationships with them, to make sure that they will stay engaged, motivated, and inspired. This means that there is a constant balance to be made between leading tasks and leading people, because tasks will be distributed among people.</p>
		<p>When we focus solely on one, we make things harder on ourselves and everyone around us:</p>
		
		<ul>
			<li>Focusing only on tasks, getting results and getting progress, at the expense of how we treat others will lead to lower levels of trust, teamwork, and will potentially lead to sabotage.</li>
			<li>Focusing only on people, without striving forward, will put the organization at risk of disappearing.</li>
		</ul>

		<p>People who are skilled at both leading tasks and people tend to be rated higher in performance by their own supervisors, peers, and subordinates (Sadri, Weber, and Gentry 2011).</p>
		<p>Working with an emphatic leader who seeks to understand how we are doing and how they can help makes us more resilient. We like people who demonstrate care, concern, and empathy towards us and will give everything we have to support them through our work.</p>
		<p>Working with tyrannic leaders is one of the three main reasons people quit their jobs, along with not seeing opportunities for promotion or growth, or getting offered a better position elsewhere, according to a 2016 Harvard Business Review article called “Why people quit their jobs”.</p>
		<p>Excessive stress is a common issue in organizations today. Leaders who fail to recognize that their people are struggling and understand why they are suffering will likely slowly burn them out. In contrast, empathic leaders are more effective at helping their people cope with stress, which makes them more trustworthy and breeds loyalty.</p>
		

		<h2 id="innovation">3rd Benefit | More Creativity And Innovation</h2>
		<p>People with higher levels of emotional intelligence observe people, trends, and opportunities, to understand when people are excited and enthusiastic about new ideas, which makes them better able to stay on the leading edge of market trends.</p>
		<p>One of the main reasons why startups fail is that they create products or services that people don’t want or need, according to CB Insights.</p>
		<p>Social awareness, one of the four elements of emotional intelligence, allows us to recognize and understand others’ emotions and to identify which ideas, products or services will people want and will buy and why.</p>
		<p>I believe innovation requires four elements to shine, which can be summarized by the acronym VOTE:</p>
		
		<ol>
			<li>Vision</li>
			<li>Orchestration</li>
			<li>Trust</li>
			<li>Execution</li>
		</ol>
		
		<p>People with higher levels of emotional intelligence strive to do their best and have their people do their best in these four elements:</p>
		
		<ol>
			<li>First, they explore the world, crafting a vision of a potential future - what could become - remaining open to ideas and actively seeking counsel.</li>
			<li>Second, they are able to share their vision of a potential future with others in a way which inspires them to act. They make sure that everyone is aligned on what needs to happen and on how they will contribute to making the vision become a reality. They orchestrate their symphony by giving each player their personal music sheets.</li>
			<li>Third, they build trust between people to make sure that people will help each other when they need help.</li>
			<li>Finally, they champion execution by leading the way and supporting others, keeping them engaged, motivated, and inspired along the way.</li>
		</ol>
		
		<p>Companies which care about their people, by being considerate of their people’s opinions, attitudes, feelings, and desires, as well as offering autonomy and freedom to work on projects of their choosing, develop more engaged, loyal, and creative workers. Over time, this can become a dramatic competitive advantage.</p>
		
		<h2  id="communication">4th Benefit | Becoming Better Communicators And Negotiators</h2>
		<p>In sales, people with higher levels of emotional intelligence deeply understand their buying- and selling-styles, and are able to adjust them by understanding the styles of their counterparts.</p>
		<p>In communication, they seek to understand what matters to the other person and how they prefer to communicate; for instance, people will lower levels of conscientiousness will prefer the big picture over minute details.</p>
		<p>Our personal, preferred style - whether in communicating, buying or selling - stems from our personality traits.</p>
		<p>Emotional intelligence helps in negotiations, because people are more self-aware of how they are feeling in heated moments, and can then develop more constructive alternative strategies, to bargain in a more calm and rationale manner - which belongs to the Self-Development element of emotional intelligence.</p>
		<p>In negotiations, fierce, competitive, winner-loser strategies can leave both parties with suboptimal outcomes. These strategies usually lead to one-time or few-times interactions, after which both parties will have to look at different partners.</p>
		<p>In contrast, striving to build lasting, mutually beneficial relationships, by sharing information and building trust, can lead to better outcomes for both parties involved. They work together to create optimal outcomes.</p>
		<p>More emphatic people tend to be truly concerned with their clients’ needs, wants, preferences, and satisfaction. We can feel when people are not being authentic, although we may not be able to explain it.</p>
		<p>Try this experiment:</p>

		<ul>
			<li>How many situations can you recall, where you experienced terrible customer service?</li>
			<li>How many situations can you recall as being extremely satisfying? When did you feel served and understood?</li>
		</ul>


		<h2 id="closing-thoughts">Closing Thoughts & Your Next Steps</h2>
		<p>There are many benefits to growing your emotional intelligence. I hope this article lit a fire in you to get started.</p>
		<p>Emotional intelligence was included in the top 5 soft skills companies need most in 2020, according to the LinkedIn Learning blog.</p>
		<p>There are different ways you can deepen your understanding of emotional intelligence and see how it can help you improve your life and the lives of those around you:</p>

		<Table striped borderless hover responsive className="course-table">
			<thead>
				<tr><th className="course-table-header">Module 1: Introduction To Emotional Intelligence</th></tr>
			</thead>
			
			<tbody className="course-table-body">
				<tr> <td> <a href="/what-is-ei">What is emotional intelligence?</a></td> </tr>
				<tr> <td> <a href="/why-ei-matters">Why does emotional intelligence matter in our lives?</a></td> </tr>
				<tr> <td> <a href="/grow-your-ei">How can you grow your emotional intelligence skills?</a></td> </tr>
				<tr> <td> <a href="/elements-of-trust">The 3 elements of trust: Likability, Expertise, Reliability</a></td> </tr>
				<tr> <td> <a href="/ei-benefits-home">Benefits of emotional intelligence at home (WIP)</a></td> </tr>					
				<tr> <td> <a href="/strategies-for-ei">Strategies to build the 4 elements of emotional intelligence (WIP)</a></td> </tr>
			</tbody>
		</Table>

		<div className="input-group">
			<div className="input-group-append content-btn-box">
				<a href="/ei-course" className="content-btn">Back to the course</a>
			</div>
		</div>
		
		<div className="separator"></div>
		<div className="separator"></div>
	</div>
  )
}
export default EIBenefitsWork;

