import React from 'react';
import { Button, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { Area, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTranslation } from "react-i18next";


import comparisonexample from "../images/comparison-example.png";
import groupoffriends from "../images/group-of-friends.jpg";
import differentpeople from "../images/different-people.jpg";



function DemoMyReports() {
    const { i18n } = useTranslation();
    
    const data = [
        {name: 'O', score: 65,},
        {name: 'C', score: 44,},
        {name: 'E', score: 42,},
        {name: 'A', score: 93,},
        {name: 'N', score: 74,},
    ];


    return (
        <div className="masthead">

            <section className="grey-section">
                <h2 className="text-white font-weight-bold">{i18n.t('this-is-what-your-personal-page-will-look-like-the-below-report-is-based-on-chris-traits-discover-your-traits-by-clicking-on-the-button-below')}</h2>

                <div className="input-group">
                    <div className="input-group-append content-btn-box">
                        <a href="/user/premium/my-assessments" className="content-btn-white">{i18n.t('discover-your-traits')}<Icon.ArrowRight className="ml-2" /></a>
                    </div>
                </div>
                <div className="separator mb-5" />
            </section>

            <div className="separator" />

            <div className="myimghor">
				<img src={differentpeople} alt="Different types of people" />
			</div>

            <h2>{i18n.t('your-personality-style')}</h2>
                
            <div className="separator" />
            <Table striped borderless hover responsive className="myreports-table">
                <thead>
                <tr>
                    <th className="course-table-header">{i18n.t('report')}</th>
                    <th className="course-table-header">{i18n.t('your-traits-at-a-glance')}</th>
                </tr>
                </thead>
                <tbody className="course-table-body">
                <tr>
                    <td className="d-flex flex-column justify-content-center">
                        <Button className="content-btn d-flex flex-row justify-content-center align-items-center w-75 m-auto" variant="info" href="/sample-personal-report">
                            {i18n.t('read')}
                            <Icon.CardHeading className="ml-2"/> {" "}
                        </Button>
                    </td>

                    <td>
                        <div style={{
                            height: 200,
                            position: 'relative',
                            minWidth: 200,
                            maxWidth: 700
                        }}>
                            <ResponsiveContainer>
                                <ComposedChart
                                    width={700}
                                    height={150}
                                    data={data}
                                    margin={{
                                        top: 2, right: 0, bottom: 2, left: 0,
                                    }}
                                >
                                    <XAxis dataKey="name"/>
                                    <Tooltip/>
                                    <Area dataKey="score" />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </div>
                    </td>

                </tr>
                </tbody>
            </Table>
            
            <div className="separator" />
            
            <a href="/user/premium/quiz" className="important-link">{i18n.t('want-to-take-your-own-assessment')}<Icon.ArrowRight className="ml-2" /></a>

            <div className="separator" />
            <div className="separator" />

            <div className="myimghor">
				<img src={groupoffriends} alt="Group of friends together" />
			</div>

            <h2>{i18n.t('invite-your-family-friends-and-coworkers')}</h2>
            <p>{i18n.t('now-that-youve-learned-more-about-your-style-and-personality-traits-you-can-invite-your-family-friends-and-coworkers-to')} <b>{i18n.t('learn-more-about-their-traits-and-how-you-can-better-interact-with-them')}</b></p>

            <div className="separator" />

            <div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/demo-my-people" className="content-btn">{i18n.t('get-to-know-your-people')}<Icon.ArrowRight className="ml-2" /></a>
                </div>
            </div>

            <div className="separator" />
            <div className="separator" />
            
            <a href="/sample-comparison-report" className="important-link">{i18n.t('see-what-it-looks-like')}<Icon.ArrowRight className="ml-2" /></a>

            <div className="separator" />
            <div className="separator" />

            <div className="myimgvert">
                <img src={comparisonexample} alt="Comparing Your traits to Chris" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('here-is-part-of-what-you-will-learn-about-your-framily-friends-and-coworkers')}</p>
            </div>

            <div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/demo-my-people" className="content-btn">{i18n.t('get-to-know-your-people')}<Icon.ArrowRight className="ml-2" /></a>
                </div>
            </div>
            
            <div className="separator" />
            <div className="separator" />
            <div className="separator" />

        </div>);
}

export default DemoMyReports;