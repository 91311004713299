import React from 'react';
import Question from '../components/Question';
import QuestionCount from '../components/QuestionCount';
import AnswerOption from '../components/AnswerOption';
import { QuizProps } from '../components/types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link } from "react-router-dom";
import Loading from "./Loading";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import ReactHotkeys from "react-hot-keys";
import { useTranslation } from "react-i18next";


function Quiz(props: QuizProps) {

    const { i18n } = useTranslation();

    function renderAnswerOptions(answer: string) {
        const meaning = props.meaningMap(i18n).get(answer)?.label as string
        const key = props.questionId + "-" + answer
        return (
            <ReactHotkeys key={key} keyName={props.meaningMap(i18n).get(answer)?.hotkey}  onKeyDown={()=>props.onAnswerSelected({currentTarget:{value:answer}})}>
                <AnswerOption
                    key={key}
                    answer={answer}
                    meaning={meaning}
                    selected={props.answer === answer}
                    questionId={props.questionId}
                    onAnswerSelected={props.onAnswerSelected}
                />
            </ReactHotkeys>

        );
    }
    if (props.counter === props.questionTotal){
        return (
          <div>
              <br /><br />
              {i18n.t('thank-you-for-your-trust-you-are-almost-there')}
              <br /> 
              {i18n.t('please-do-not-close-or-refresh-this-window-we-are-saving-your-report')}
              <br /> <br />
              {props.reportLink? <Link to={props.reportLink}> <b className="big-text">{i18n.t('here-is-your-personal-report')}</b> </Link> : <Loading/>}
          </div>
        );
    }

    return (
        <div className="quiz masthead" key={props.questionId}>
            <div className="separator-small" />
            
            <section className="progress-bar-small-height">
                <ProgressBar className="progress-bar-small-height" now={100 * props.counter / props.questionTotal} />
            </section>

            <ReactCSSTransitionGroup
                className="container"
                component="div"
                transitionName="fade"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
                transitionAppear
                transitionAppearTimeout={300}
            >

            <span className="font-weight-bold question-count">
                <FontAwesomeIcon icon={faChevronCircleLeft} size="2x" color="#2B599D" onClick={props.onBack} className="hover-cursor" />

                <QuestionCount
                    counter={props.counter}
                    total={props.questionTotal}
                />
            </span>

            <Question content={props.question}/>
            </ReactCSSTransitionGroup>

            <div className="separator" />

            <ul className="answerOptions">
                {props.answerOptions.map(renderAnswerOptions)}
            </ul>

            <div className="separator" />
            <div className="separator" />
        </div>
    );
}

export default Quiz;