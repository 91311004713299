import React from 'react';
import Result from './Result'
import {QuizReport, ViewReportProps} from '../components/types';
import {useParams} from "react-router-dom";

function findQuizResults(quiz : Array<QuizReport>, reportId : string) {
  for (var i = 0; i < quiz.length; i++) {
    if(reportId === quiz[i].quizId) {
      return quiz[i].quizResults;
    }
  };
}


function ViewReport(props : ViewReportProps) {
      let {reportId}  = useParams();
      let quizResults = findQuizResults(props.userData.quiz,reportId)
      if(props.userData && quizResults){
         return (<Result quizResult={quizResults} username={props.username}/>)
      }else{
         return (<div>Error</div>)
      }
  }
export default ViewReport;