import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function Loading(props : any) {
    return (
      <div className="loading-wrapper">
        <br /><br /><br /><br /><br /><br /><br /><br />
        <Spinner animation="border" variant="primary" />
        </div> 
    );
  }
export default Loading;