import React, {useState} from 'react';
import {Alert, Button, Table} from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import {useTranslation} from "react-i18next";

import * as Icon from 'react-bootstrap-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

import groupoffriends from "../images/group-of-friends.jpg";
import invitationimg from "../images/invitation.jpg";
import testimg from "../images/test-img.jpg";
import PrePayment from "./PrePayment";


function row(other, i18n) {

    return (
        <tr key={other.uid}>
            <td><a href={`/user/premium/compare/${other.uid}`}>{i18n.t('discover')}
                <b>{other.username}</b>{i18n.t('s-traits')} <Icon.ArrowRight className="ml-2"/> </a></td>
        </tr>);
}

function invite(props, i18n, emailHandler, fromHandler, captchaHandler, handleClick, doing) {
    return (<div><h1 className="font-weight-bold">{i18n.t('invite-your-people-and-get-to-know-them-better')}</h1>

        <br/><br/>

        <div className="form-group">
            <label htmlFor="email" className="mr-2"><b>{i18n.t('recipient-email-address')}</b> </label> <br/>
            <input className="" placeholder="Enter email" onChange={emailHandler}/> <br/> <br/>

            <label htmlFor="from"><b>{i18n.t('your-full-name')}</b> </label> <br/>
            <input id="from" placeholder={props.chosenName ? props.chosenName : "Jane Doe"}
                   onChange={fromHandler}/> <br/> <br/>
            <p className="responsive-width">{i18n.t('your-name-will-appear-in-the-invitation-to-tell-people-its-you-you-only-need-to-fill-it-once-and-can-change-it-at-any-time')}</p>


            <div className="captcha">
                <ReCAPTCHA sitekey="6Lemvr8ZAAAAAJHcZhhBSQIvBktYmpOua9DHte8u" onChange={captchaHandler}/>
            </div>

            <br/>

            <Button className="login-btn-big" role="link" onClick={handleClick}
                    disabled={doing}
            >{i18n.t('send-invite')}<Icon.ArrowRight className="ml-2"/></Button>
        </div>

        <br/><br/>
        <p className="responsive-width">
            <b>{i18n.t('myoceanity-respects-your-privacy-and-will-not-store-or-use-the-email-you-entered-once-the-invitation-has-been-sent')}</b>
        </p>

        <span className="small-dot" /><span className="small-dot" /><span className="small-dot" />

        <h2 className="font-weight-bold">{i18n.t('how-does-it-work')}</h2> <br/>
        <p className="responsive-width">{i18n.t('after-your-invitation-is-accepted-and-the-recipients-assessment-is-completed-you-will-be-able-to-learn-more-about-their-personality-traits')}</p>

        <br/><br/>

        <div className="myimg">
            <img src={invitationimg} alt="Invite your family, friends, and coworkers"/>
        </div>

        <br/><br/><br/>
    </div>)
}

function invitationForm(props, i18n, emailHandler, fromHandler, captchaHandler, handleClick, doing) {
    if (props.userPaid) {
        return (<div>

            {invite(props, i18n, emailHandler, fromHandler, captchaHandler, handleClick, doing)}
        </div>);
    } else {

        //free users get 1 free invite
        if(!props.inviteCount || props.inviteCount < 1){
            return invite(props, i18n, emailHandler, fromHandler, captchaHandler, handleClick, doing);
        }else{
            return (<div>
                <b>{i18n.t("already-sent-free-invite")}</b>
                <PrePayment userId={props.userId} email={props.email}/>
            </div>)

        }
    }
}

function comparaisons(props, i18n) {

    if (props.profileSharedWith && props.profileSharedWith.length > 0) {
        return (
            <div className="responsive-box">
                <div className="myimghor mt-0">
                    <img src={groupoffriends} alt="Group of friends hanging out together"/>
                </div>

                <h1 className="font-weight-bold">{i18n.t('these-profiles-are-shared-with-you')}</h1> <br/><br/>

                <p>{i18n.t('your-comparison-doesnt-load-this-means-your-recipient-hasnt-finished-their-assessment-yet')}</p>

                <Table striped borderless hover className="mypeople-table-width mt-5">
                    <thead>
                    <tr>
                        <th className="course-table-header">{i18n.t('your-people')}</th>
                    </tr>
                    </thead>
                    <tbody className="course-table-body">
                    {props.profileSharedWith.map(other => {
                        return row(other, i18n);
                    })}
                    </tbody>
                </Table>
            </div>
        );
    } else {
        return (<div></div>);
    }
}

function CompareProfile(props) {

    const [done, setDone] = useState(false);
    const [doing, setDoing] = useState(false)
    const [captcha, setCaptcha] = useState(null);
    const [email, setEmail] = useState(null);
    const [from, setFrom] = useState(props.chosenName);
    const [error, setError] = useState(false);

    const handleClick = event => {
        setDoing(true)

        if (!captcha) {
            alert("please complete the captcha first");
        } else {
            if (!email) {
                alert("please type in an email");
            } else {
                if (!from) {
                    setFrom(props.chosenName ? props.chosenName : props.username);
                }
                console.log("respond to " + props.userEmail);
                fetch('https://9ap0wh9yya.execute-api.eu-central-1.amazonaws.com/compare-profile-request', {
                    method: 'POST',
                    body: JSON.stringify({
                        captcha: captcha,
                        email: email,
                        from: from ? from : props.username,
                        respondTo: props.userEmail,
                        language: i18n.language
                    }),
                    headers: {
                        'Authorization': `Bearer ${props.jwt}`,
                    }
                }).then(response => {
                    if (response.status === 200) {
                        setDone(true);
                        let res = response.json();
                        return res;
                    } else {
                        setError(true);
                    }
                });

            }
        }
        //post invite with name
        setDoing(false);
    }
    const captchaHandler = value => setCaptcha(value);
    const emailHandler = event => setEmail(event.target.value);
    const fromHandler = event => setFrom(event.target.value);
    const {i18n} = useTranslation();

    if (props.quiz.length > 0) {
        if (error) {
            return (
                <div>
                    <Alert
                        variant="danger"> {i18n.t('sorry-we-couldnt-send-your-invitation-to')} {email}{i18n.t('please-try-again-in-a-moment')} </Alert>

                    <div className="separator"/>
                    <div className="separator"/>

                    <FontAwesomeIcon icon={faTimesCircle} size="3x" className="ml-2 red-error"/>


                        <div className="content-btn-box">
                            <a href="/user/premium/send-invite"
                               className="content-btn">{i18n.t('back-to-your-comparisons')}<Icon.ArrowRight
                                className="ml-2"/></a>
                        </div>
                </div>
            )
        }

        if (done) {
            return (
                <div>
                    <Alert variant="success"> <b>{i18n.t('invitation-sent-to')} {email} !</b> </Alert>

                    <div className="separator"/>
                    <div className="separator"/>

                    <FontAwesomeIcon icon={faCheckCircle} size="3x" className="ml-2 green-thumbs-up"/>

                    <div className="separator"/>
                    <div className="separator"/>

                    <div className="content-btn-box">
                        <a href="/user/premium/send-invite" className="content-btn">{i18n.t('back-to-your-comparisons')}<Icon.ArrowRight
                            className="ml-2"/></a>
                    </div>
                </div>
            )

        } else {
            return (
                <div>
                    {comparaisons(props, i18n)}

                    <span className="small-dot"></span><span className="small-dot"></span><span className="small-dot"></span>

                    {invitationForm(props, i18n, emailHandler, fromHandler, captchaHandler, handleClick, doing)}
                </div>
            );
        }

    } else {
        return (
            <div className="masthead">
                <div className="separator"/>

                <h3>{i18n.t('you-are-almost-there')}</h3>
                <p className="text-center">{i18n.t('you-need-to')} <a href="/user/premium/quiz"
                                                                      className="font-weight-bold">{i18n.t('complete-an-assessment')}</a> {i18n.t('before-you-can-invite-people-you-know')}
                </p>

                <div className="content-btn-box">
                    <a href="/user/premium/quiz" className="content-btn">{i18n.t('lets-go')}<Icon.ArrowRight
                        className="ml-2"/></a>
                </div>

                <div className="separator"/>

                <div className="myimg">
                    <img src={testimg} alt="Take the test"/>
                </div>

                <div className="separator"/>

                <div className="content-btn-box">
                    <a href="/user/premium/quiz" className="content-btn">{i18n.t('lets-go')}<Icon.ArrowRight
                        className="ml-2"/></a>
                </div>

                <div className="separator"/>
                <div className="separator"/>

            </div>
        )
    }


}

export default CompareProfile;