import React from 'react';
import { StyleResultPros } from '../components/types';
import { Card, CardDeck, ProgressBar } from 'react-bootstrap';
import { content } from '../api/ResultContent';
import {useTranslation} from "react-i18next";

	
function StyleResult(props:StyleResultPros) {
	const { i18n } = useTranslation();

	return (
    	<div>
			<section className="trait-spectrum">
			<CardDeck className="justify-content-center d-flex flex-row">
				<Card className="bg-light report-card">
					<Card.Header className="report-card-header"> {props.wording.lowTitle} </Card.Header>
					<Card.Body className="report-card-text">
						<Card.Text className="report-card-text">
							<p>
							{props.wording.lowAttributes.map((value :string, index:string) => {
							return <span key={index}>{value}<br /></span>
							})}
							</p>
						</Card.Text>
					</Card.Body>
				</Card>

				<ProgressBar className="report-card-progressbar">
					<ProgressBar now={props.score} key={1} label={`${props.score}%`} />
				</ProgressBar>	

				<Card className="bg-light report-card">
					<Card.Header className="report-card-header"> {props.wording.highTitle} </Card.Header>
					<Card.Body className="report-card-text">
					<Card.Text className="report-card-text">
						<p>
						{props.wording.highAttributes.map((value :string, index:string) => {
						return <span key={index}>{value}<br /></span>
						})}
						</p>
					</Card.Text>
					</Card.Body>
				</Card>
			</CardDeck>
			</section>

			{content[props.style].intro(i18n)}

			<div className="trait-intro">
			{(props.score<45)?content[props.style].profile.low(i18n, props.subStyle)
				:(props.score>55)?content[props.style].profile.high(i18n, props.subStyle)
				:content[props.style].profile.medium(i18n, props.subStyle)}
			</div>

			{content[props.style].mismatch(i18n)}

			{content[props.style].workwithhigh(i18n)}

			{content[props.style].workwithlow(i18n)}

			{content[props.style].questions(i18n)}

    	</div>
    );
}

export default StyleResult;
