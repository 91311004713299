import React from 'react';

import { QuestionCountProps } from '../components/types';
import { useTranslation } from "react-i18next";


function QuestionCount(props:QuestionCountProps) {
  const { i18n } = useTranslation();

    return (
      <div className="questionCount">
        Question <span>{props.counter+1}</span> {i18n.t('of')} <span>{props.total}</span>
      </div>
    );
  }
  export default QuestionCount;