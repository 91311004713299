import React from 'react';
import { Image } from 'react-bootstrap';
import chriscircle from "../../images/chris-circle-picture.png";


function WhoWeAre(props : any) {
  
  return (
    
    <div className="masthead">
      <h1>Who we are</h1>
      
      <div className="separator"></div>

      <div className="notabene">
        <a href="https://www.linkedin.com/in/chrisostorero/" className="intlink">Chris</a> dreamed of a world where people could thrive and take care of each other by growing their emotional intelligence, and Dan made this dream become a reality.
      </div>

      <h2>Our Vision</h2>

      <div className="quote">
        <p><i>"To help people realize their potential with emotional intelligence"</i></p>
      </div>

      <p>What does that mean? We believe that the phrase “realize your potential” is paramount, because it has two meanings:</p>

      <ul>
        <li>First, <b>to become aware of what is already there, but that you cannot see yet</b>. I have often been surprised by what I was able to achieve, thanks to the right encouragements and support. Others told me they felt the same way.</li>
        <li>Second, <b>to strive to make this idea or ideal become a reality</b>. Bringing something to life requires a lot of hard work and sacrifices. What is important is to keep in mind that although it will be hard, it will also be worth it and necessary to do, because it will support your personal Mission.</li>
      </ul>

      <p>We also believe that emotional intelligence is a key to making everyone’s lives better, including our own.</p>

      <div className="input-group">
			<div className="input-group-append content-btn-box">
						<a href="/why-ei-matters" className="content-btn">Why emotional intelligence matters</a>
				</div>
	  	</div>


      <h2>Our Mission</h2>
      <div className="quote">
        <p><i>"To preserve and improve human life, doing well by doing good."</i></p>
      </div>
      
      <p>What does that mean? It means two things:</p>
      
      <ul>
        <li>First, that we are searching for ways to help as many people as we can to become more emotionally intelligent and to live richer, meaningful lives. This is what we mean by “preserving and improving human life”.</li>
        <li>Second, it means that our primary motivation is not to die rich. Rather, we want to live a rich life.</li>
      </ul>

      <p>Therefore, we try to make MyOceanity as affordable and accessible as we can, to enable as many people as possible to join our community. This is what we mean by “doing well by doing good”.</p>

      <h2>Our Values</h2>
      <p>Three values drive our work, and we do our best to live by them in everything we undertake:</p>
        
      <div className="quote">
        <ol>
          <li><b>Respect</b></li>
          <li><b>Development</b></li>
          <li><b>Durability</b></li>
        </ol>
      </div>


      <h3>About Respect</h3>
      <div className="notabene">
        We believe that every person deserves to be treated with respect and dignity.
      </div>

      <p>This is the reason why we decided not to display advertisements or to sell our community’s private data to third-party organizations.</p>
      <p>This is also the reason why we decided to offer people facing financial difficulties the possibility to arrange a solution that will be viable for them. </p>
      <p><a href="/what-we-do" className="intlink">Learn more</a> about our business model and how to apply for an arrangement.</p>

      <h3>About Development</h3>
      <div className="notabene">
        We believe we can always become better today than we were yesterday, by taking minute steps and growing our constructive habits.
      </div>

      <p>This is the reason why we update and review content every week, in addition to researching and adding new content as well.</p>
      <p>This is also the reason why we regularly emphasize the necessity of self-development as well as relationships development, which are two of the four elements of emotional intelligence.</p>
      <p>You will find a weekly summary of what we have published and changed <a href="/updates" className="intlink">here</a>.</p>

      <h3>About Durability</h3>
      <div className="notabene">
        We believe we must live with an infinite mindset, considering the long-term impacts of our immediate decisions and actions.
      </div>
      
      <p>This is the reason why we decided to consider MyOceanity as an e-learning platform, rather than a more traditional book-selling-and-workshops-based platform: Because we want our work to outlive us. Better yet, we want to live in a world where MyOceanity needs not exist, because the principles we follow became the norm.</p>
      <p>We also learned from the COVID-19 pandemic that digital tools need to encourage and support life-long learning, which should ideally require in-person interactions to strengthen the collective learning and relationships.</p>
      <p>This is also the reason why we try to build lasting, mutually beneficial relationships with our community. Because we believe that as we take care of them, they will take care of each other and of us as well.</p>
      <p>Finally, this is the reason why we strive to operate as leanly as we can, to continue being able to advance our cause as long as we can, while having the smallest impact on the planet as we can, as well as helping those who need education and healthcare the most.</p>


      <h2>About Chris Ostorero</h2>
      <p>During my 5 years at Johnson &amp; Johnson, one of the world’s leading healthcare companies, I went through burnout. This experience made me reconsider what was truly important in my life and how I could start taking better care of myself and of those around me.</p>
      <p>To heal, I started researching psychology, emotional intelligence, time management, and energy management. I also talked about my findings with others, who encouraged me to share them with others.</p>
      <p>This journey was filled with insights that I wish I knew back then and that could help other people. I wanted to share my story and the lessons I learned along the way. Enter MyOceanity.</p>
      <p>I like to write in the first person. When you see “I”, then you will know that I’m the one behind the keyboard. </p>

      <div className="separator"></div>

      <div className="chris-img-lg">
        <Image src={chriscircle} alt="Chris Picture Circle"  />
      </div>

      <div className="separator"></div>
      <div className="separator"></div>

    </div>
  );
}
export default WhoWeAre;
