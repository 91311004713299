import React from 'react';
import Table from 'react-bootstrap/Table';

import elephantrider from "../images/elephant-rider.jpg";
import journal from "../images/journal.jpg"


function StrategiesForEI(props: any) {
	   
  return (
   <div className="masthead">
		<h1>Strategies To Build The Four Elements of EI</h1>
		
		<h2>Strategies To Build Self-Awareness</h2>

		<h3>Hearts, Heads, and Bodies</h3>
		
		<div className="notabene">
			Our heart, head, and body are strongly connected together. <br />
			Different emotions create different physical reactions in our bodies.
		</div>
		
		<p>When an emotion arises, it impacts our ability to think by clouding our mind and affects us physically:</p>
		
		<ul>
			<li>Our hearts start to race</li>
			<li>Our breathing becomes quicker and more shallow</li>
			<li>Our stomaches start to churn and tighten</li>
			<li>Our muscles become tenser and we may start shaking</li>
		</ul>
		
		<p>Paying attention to our bodies when we are calm and when we are experiencing emotions is key to becoming better aware of when they arise.</p>
		<p>Because when we learn to quickly recognize when emotions move us, we become able to prevent them from completely taking over us and leading us to destructive behaviors, against ourselves or against people around us.</p>
		<p>Every now and then, close your eyes and take a moment to evaluate how your body is feeling:</p>
		
		<ul>
			<li>How quick is your heartbeat?</li>
			<li>How deep and quick is your breathing?</li>
			<li>How relaxed are your muscles - in your neck, back, arms and legs?</li>
			<li>How relaxed are your stomach and guts?</li>
		</ul>
		
		<p>Becoming aware of when you are stressed and how you respond to stressful situations is also key. <b>Stress can make or break us.</b> Without any stress or stimulation, we become bored and apathetic. </p>
		<p>When the stakes increase, we become more interested and attentive to - even excited about - what is happening. We are alert, energetic, and concentrate on the task at hand.</p>
		<p>We are stimulated, which can lead us to optimal levels of performance. This is when stress, viewed as our bodies preparing to raise to the challenges ahead, makes us give it everything we have. We feel strong enough to handle the situation and like we are in control.</p>
		<p>After such productive events, we feel satisfied by what we’ve achieved and the progress we are making. Trust of others, confidence in our own abilities, initiative, and creativity increase. When the task is done, we can relax and our stress dissipates.</p>
		<p>When stress becomes excessive - either in intensity or in duration over time - we become nervous and anxious. Stress becomes a disturbance, which makes it more difficult for us to concentrate on what we need to do. </p>
		<p>We tend to become forgetful of what we needed to do, which makes us less reliable. Sometimes, we may feel hopeless and helpless, unable to deal with what is happening.</p>
		<p>This will likely lead to poorer performance, because our nervosity and anxiety work against us. We start to overthink things and our performance drops as a result. Even professional athletes suffer from poorer performance when they are overthinking before and during competitions.</p>
		<p>People who suffer from such chronic destructive stress may get physically injured:</p>
		
		<ul>
			<li>Their stomachs hurt and may develop ulcers</li>
			<li>Their heads start to ache and they may develop chronic migraines</li>
			<li>Their muscles, especially their shoulders and their backs get stiff and start to hurt, at day and night, which robs them from the restorative sleep they need to keep going.</li>
			<li>This fatigue leads to exhaustion.</li>
		</ul>
		
		<p>In such situations, their bodies are crying for help. Asking to find solutions to reduce the levels of stress, because the damages become greater and potentially irreversible. At extreme levels of stress, as our physical health deteriorates, so does our mental health. We burn out and may suffer from a complete meltdown. </p>
		<p>Alternatively, you may pay attention to your body language. Your posture and facial expressions are great ways of checking in with yourself.</p>
		<p>If you notice you are slouching, change your posture and stand tall with your shoulders back. You will likely feel better, because good posture is associated with higher levels of serotonin.</p>
		<p>Likewise, if you notice your mouth curves down or you are frowning your eyebrows, take a deep breath and relax your face. Try to smile, because smiling also tends to make us feel happier.</p>
		<p>How you dress and take care of your appearance also matters. Dressing up, even when you are working from home, is important, because it changes the way you view yourself and how you behave.</p>
		<p>People who have given up on life tend to wear dirty clothes and never take care of their hair.</p>
		<p>Your body language is heavily influenced by your emotions. Take a moment to assess your posture and facial expressions and make sure that you correct them, to make you feel more confident. This will also make others perceive you as more confident.</p>
		<p>With enough time and effort, your conscious posture may become your default posture. Stay mindful of how you appear in the world.</p>
		

		<h3>Take Good Notes On Your Life</h3>
		
		<div className="separator"></div>

		<div className="myimg">
			<img src={journal} alt="Journaling" />
		</div>

		<div className="separator"></div>

		<div className="imgcaption">
		<p>What's on your heart and mind? Write it down to soothe it</p>
		<p>Photo by <a href="https://www.pexels.com/@thepaintedsquare?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels" className="intlink">Jessica Lewis</a> from <a href="https://www.pexels.com/photo/ballpen-blank-desk-journal-606541/?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels" className="intlink">Pexels</a></p>
		</div>

		<div className="notabene">
			Writing things down is a great way of alleviating mental and emotional burdens.
		</div>
		
		<p>After going through a burnout in 2018, I started writing down my fears, dreams, thoughts and ideas in a small red notebook. No strict schedule or format, only writing down what was going through my head and prevented me from focusing on what I wanted to do.</p>
		<p>Sometimes, the stream of consciousness that flowed on the page surprised me. Since I was not judging what I wrote, only letting it all out, I was often surprised by what I read afterwards. Some deep, unconscious thoughts revealed themselves to me. They sometimes spoke the truth I was not willing to hear. </p>
		<p>Writing my thoughts down felt like such a relief. It made the burdens slightly lighter and more bearable. Which is why I kept going, day after day.</p>
		<p>Over time, I started to notice some patterns in my thoughts and concerns. I began to do what I call a “daily debrief”, where I answer a few questions:</p>
		
		<ul>
			<li>What went well today? What did I really enjoy doing? Why?</li>
			<li>What went wrong today? What did I dread doing or hated doing? Why?</li>
			<li>How can I start doing more of the enjoyable experiences and lessen the pain of the more difficult ones?</li>
			<li>What was I grateful for today?</li>
		</ul>
		
		<div className="notabene">
			Time gives us greater perspective. I noticed which recurring events, behaviors, and people who made me happy, sad or mad.
		</div>
		
		<p>For instance, I was frequently annoyed by how loud the open space I worked in was and how it distracted me from my work. A pair of noise-cancelling headphones helped cut the noise and become more focused. </p>
		<p>I also noticed that I really enjoyed days where I could help people, by listening to their worries, asking how I could help, sharing my lessons learned or giving them a hand with their work. </p>
		<p>When I discussed this with my manager, she agreed that she often received feedback that I was a very considerate and helpful person. </p>
		<p>While she was first worried that I may give too much time and energy to help others, sacrificing my personal objectives in doing so, she later realized that she could put me in roles where my active helping would be required, rather than focusing on solitary work, building reports. Thanks to this shift of work, we all benefited and I enjoyed the work I was doing much more than before.</p>
		<p>Writing is great, not only because it allows you to get things out of your head, but also because they give you the opportunity to take a step back and consider the bigger picture of your actions and how you contribute to your team’s or your organization’s efforts in making a difference in the world.</p>
		<p>This greater perspective is extremely helpful to think of different strategies you could use to get out of difficult situations, rather than suffering and inflicting pain around you meaninglessly.</p>
		<p>Learning lessons from our past and understanding how they can make the future slightly easier and more meaningful is a wonderful way of living.</p>
		<p>By making peace with the past and planning for the future, we gain the ability to live in the present, with greater focus on what truly matters to us and awareness of how the world affects us and vice versa.</p>
		<p>What events are causing you to feel powerful emotions? How did you react to them? What could you do differently, to create more positive outcomes?</p>
		<p>Understanding our priorities, motivations and fears in life, as well as our tendencies, is the key to self-awareness. </p>
		<p>When we do, we can make the most of our strengths, passions, and learn how we can deal with our weaknesses and with the anchors that hold us down. </p>
		<p>Therefore, we can move from potentially destructive behaviors - for ourselves and others alike - to more constructive ones.</p>
		<p>Make sure you frequently refer back to your notes and see which pieces of wisdom you can extract from your past, to create a better future.</p>
		<p>Seek to understand why a certain situation elicited the emotions you felt and why you were motivated to act in certain manners. Emotions give us clues about what is important in our lives, which we may not be consciously aware of.</p>
		<p>Art is a great way of taking notes on your emotions, because it conveys the emotions from the artist. Some paintings, books, movies and music speak to us on an emotional level. Some scenes are more meaningful to us. </p>
		<p>Try and understand, what is that, for you? What important part is speaking to your core?</p>
		<p>Learn to recognize the patterns of emotions in your life and to follow the clues they are trying to give you.</p>
		<p>Life is really demanding on us: We need to juggle tasks at work and chores at home, take care of our loved ones, the list goes on.</p>
		<p>This demanding aspect of life explains why many of us struggle with paying attention to what is going on inside of us - how we are feeling and why that may be. </p>
		<p>When we don’t spend enough time in introspection and deep reflection, we run at the risk of focusing our time and energy checking off tasks on our to-do lists that may not be the most important or meaningful ones, therefore preventing us from enjoying the satisfaction of living a purpose-driven life, in accordance to our values and beliefs.</p>
		<p>Acting against our beliefs or values will never make us feel good. We may feel shameful for our actions and lash out at people, making things even worse.</p>
		<p>When reviewing your core values and beliefs, consider the behaviors you had recently that made and still make you feel uncomfortable. Then, consider the following questions:</p>
		
		<ul>
			<li>Which value or belief did they betray? </li>
			<li>Where were you acting out of alignment with them?</li>
			<li>How could you have behaved differently, in a way more aligned with them?</li>
		</ul>

		<p>This last question is key, because it provides you with alternative strategies, should you find yourself in similar situations in the future. When they occur, you may catch yourself before you act out of character, and change your responses to ones which are truer to who you are and what you believe in. This is what self-development is about.</p>
		

		<h3>This Too Shall Pass</h3>
		<p>Life is ephemeral. We live and we die, and the cycle repeats. Memento mori. I believe our responsibility is to find how we can contribute to improving life for other people tomorrow. Just like life is ephemeral, so are our emotions, as long as we fully feel them and live in a way that lets them heal and teach us.</p>
		
		<p>When we feel negative emotions - like fear, anger, sadness, and shame - we tend to lose sight of what is good in our lives. </p>
		
		<ul>
			<li>We hate our jobs, although they enable us to provide for our families.</li>
			<li>We cause trouble in our relationships with our loved ones, although they provide us the love, encouragements and support we need to keep moving forward.</li>
			<li>We forget how privileged we may be compared to most people living in poorer countries and take many things for granted, rather than being grateful for them. It seems like we never have enough, while there are people who have nothing, literally.</li>
		</ul>

		<p>The truth usually is that things aren’t as bad as we imagine them. Our brains are bringing to our attention that some things are wrong in our lives, so that we can do something about them. </p>
		
		<div className="quote">
			<p><i>"We suffer more in imagination than in reality."</i></p>
			<p><strong>Seneca</strong></p>
			</div>
		
		
		<p>When facing difficult times and the consequent emotions that come with them, we need to remember that these too shall pass. Dwelling on them will only make the future also harder, not just the present. </p>
		<p>Learning the lessons that life and our emotions want to teach us is a great way of preventing making the same mistakes in the future and making life worse than it has to be. We can get out of the downward spiral that life can drag us in, if we stay aware of our moods and how they may impact our lives and the lives of others around us.</p>
		<p>The same is unfortunately also true for joy and other positive emotions. They too shall pass. We need to enjoy them while we have them and keep making progress towards living a more meaningful life, to keep experiencing more of them.</p>
		<p>Joy, just like bad moods, can influence our judgements, by making us too excited about the situation and ending up making decisions we may later regret. When we are in a good mood, we may be more easily influenced and manipulated by people.</p>
		
		<h3>How Do Others Perceive You?</h3>
		<p>Part of self-awareness is understanding how your words and actions affect other people, and how they perceive you.</p>
		<p>Many times, we are convinced that we are consistently acting in alignment with our values and beliefs.</p>
		<p>As many other people, I believe I am a good listener. However, I notice that sometimes, the faces of people I speak with flash expressions of annoyance or anger. </p>
		<p>It turns out, people told me I tend to interrupt them to make sure I understand what they are trying to say. My intentions were good, but I am still not truly behaving like a good listener would.</p>
		<p>Many times, people may tell us more about our blindspots, the things we may say or do without being aware of the damages they do around us.</p>
		<p>Feedback should be as specific as possible. If you are unable to get direct feedback right after a certain situation, ask people for concrete examples where your behaviors may have hurt them.</p>
		<p>Like when journaling, look for patterns. The more people provide similar feedback, the more you know you should address this issue first.</p>
		<p>Psychologists Joseph Luft and Harry Ingham summarized the importance of sharing about yourself and learning from others to build understanding and trust, known as the Johari window, as represented in the below image. </p>

		<p>Image: Johari Window</p>

		<p>The Johari window can be broken down into four elements. Imagine a 2x2 grid:</p>

		<ul>
			<li>On the horizontal axis are what is known by the self, and what is unknown by the self.</li>
			<li>On the vertical axis are what is known by others, and what is unknown by others.</li>
		</ul>

		<p>By bringing these together, we get the four elements of the Johari Window:</p>

		<ol>
			<li>Our Face: What we openly share with the world.</li>
			<li>Our Mask: What we hide from the world.</li>
			<li>Our Shadow: What we are not aware of, what hides in our blindspots.</li>
			<li>Our Unknown potential: What we can still achieve together and the unknown potential we can realize</li>
		</ol>

		<p>I believe that the purpose of this concept is to help us answer two questions:</p>

		<ol>
			<li>How can I make the Face box as large as possible?</li>
			<li>How can I make the Unknown Potential box as small as possible?</li>
		</ol>

		<p>When we share about ourselves with others, we get rid of the masks we are wearing and show our true faces. </p>
		<p>Doing so requires the courage to be vulnerable, which means to be willing to be hurt by others, should they react negatively to our acts of vulnerability. When people shame us, ignore or diminish our suffering, then our relationships suffer and trust is broken.</p>
		<p>When people respond positively to our acts of vulnerability, by showing empathy and compassion, a desire to help, then our relationships strengthen and trust is built.</p>
		<p>Sharing about ourselves helps us to connect with people, because we know we can be vulnerable with them, trusting that they will not hurt us but support us.</p>
		<p>Sharing our thoughts, feelings, dreams, aspirations, motivations, fears, likes, and dislikes with others can feel liberating. The goal is to reveal what matters to and affects us, whether in writing or by speaking.</p>
		<p>When we seek to learn from others, their ideas and perspectives will broaden ours and make them better. Learning is not necessarily about confirmation or contradiction of our ideas per se, it’s about better understanding them and continuously improving them.</p>
		<p>We can learn from others by reading or by asking and attentively listening to what they have to say. When we learn from others, we are standing on the shoulders of giants. We can see further and imagine ways to continue progressing towards our ideas and ideals.</p>
		<p>We can also learn by measuring our skills and knowledge, by taking tests for example. We tend to overestimate our abilities and competence, especially when we have low levels of knowledge and practice. </p>
		<p>In psychology, this is known as the Dunning-Kruger effect. We tend to be unaware of our own lack of competence. We suffer from innocent ignorance.</p>
		<p>Knowing our current limitations is key for self-development. These insights can spark a new learning and development journey, which can ultimately lead us to continuously seeking mastery of the skills and knowledge we were first lacking, through deliberate practice spread over time, until we can fully internalize them. </p>
		<p>This was illustrated in an experiment ran by Dunning, Kruger and their team with university students in psychology. The team asked students, when they walked out of an exam, how well they believe they did on the test.</p>
		<p>Those who performed the worst - getting the lowest grades - greatly overestimated their performance.</p>
		<p>In contrast, those who performed the best - getting the highest grades - were accurate about their performance. Some even performed better than they predicted!</p>
		<p>While the results we get from measuring our performance may hurt, feeling disappointed about it is better than deceiving ourselves about our abilities. Gaining the insights that we still can learn and grow gives us the opportunity to do so. The choice is ours.</p>

		<h2>Strategies For Self-Development</h2>

		<h3>Breathe Deeply And Slowly</h3>
		<p>As we’ve seen before, when challenges arise, our bodies experience stress to prepare us to handle the situation. </p>
		<p>As a result, our hearts start to race and we breathe more quickly and shallowly.</p>
		<p>Unfortunately, this means that the distribution of oxygen to the brain will be lower than usual. Therefore, to ensure our survival, the brain feeds in priority its deeper, more ancient structures, which are meant to regulate vital cardiac and respiratory functions and the treatment of the information transmitted from our senses.</p>
		<p>This means that our prefrontal cortex, which is responsible for focusing on tasks, planning and prioritizing them, and keeping track of what we are saying and doing, gets less oxygen and therefore functions less optimally than desired. Our thoughts become foggy.</p>
		<p>The good news is that we can consciously regulate our breathing. When you notice you are in a state of stress, emotionally moved, take a few deep breaths.</p>
		<p>I really like the square breathing exercise:</p>

		<ol>
			<li>Breathe in through your nose for 4 seconds;</li>
			<li>Keep the air inside your lungs for 4 seconds;</li>
			<li>Exhale through your mouth for 4 seconds;</li>
			<li>Let your empty lungs rest for 4 seconds;</li>
			<li>Repeat.</li>
		</ol>

		<p>Note that breathing is most effective when we breathe through our stomachs, which inflates them, rather than with our torso, which raises it along with our shoulders.</p>
		<p>Deep breaths are great to clear your head. You can count to four in your head if that helps you to consciously breathe slowly. </p>
		<p>I also like to slowly mentally repeat “I am getting better and better” through each step of the square breathing exercise, because it takes about 4 seconds to do so. These words help calming me down.</p>
		<p>As you go through this exercise, thoughts will come to mind. That’s okay. You need to consciously accept them - for example, by saying to yourself “That’s okay” - and continue breathing deeply for as long as you need to feel calm.</p>
		<p>This exercise can last about a minute, enough to do four cycles of square breathing, and I encourage you to practice it daily for a few minutes.</p>
		<p>Why? Because repetition will make this exercise more habitual and therefore more impactful and easier to do.</p>
		<p>Studies have found that such exercises, aiming to calm you down, focus on your breath and this one thought, while staying aware of the other ones which may intrude during your practice, has positive effects on the brain.</p>
		<p>If you remember the metaphor of the Rider and the Elephant, deep breathing is how we can calm our elephant down, to allow the rider to get back in its saddle and guide the elephant back on the right path.</p>

		<div className="separator"></div>

		<div className="myimg">
		<img src={elephantrider} alt="The Elephant and the Rider"/>
		</div>
		
		<div className="imgcaption">
		<p>One brain, two minds | The elephant and the rider</p>
		</div>

		<h4>Benefits Of The Square Breathing Exercise</h4>
		<p>Sara Lazar, Christina Congleton and Britta K. Hölzel published a Harvard Business Review article called “Mindfulness can literally change your brain”, where they describe the benefits of such an exercise. </p>
		<p>Their original 2011 study focused on a group of participants who completed an eight-week mindfulness program. They highlighted two main benefits:</p>

		<ol>
			<li>Better self-regulation</li>
			<li>Greater resilience to adversity</li>
		</ol>

		<p>Let’s quickly review each.</p>

		<h5>1st Benefit | Better Self-Regulation</h5>
		<p>The first benefit is greater self-regulation. This means to reduce impulsive, potentially inappropriate and destructive behaviors, and purposefully direct attention and behavior to more constructive and productive behaviors.</p>
		<p>Participants were able to better resist distractions and stay focused on their work, as well as better able to learn from their past mistakes for better future decision-making.</p>

		<h5>2nd Benefit | Greater Resilience To Adversity</h5>
		<p>The second benefit is greater resilience to adversity and stress. </p>
		<p>Chronic stress over time starts to damage the hippocampus - a key structure in our brains involved in learning and memory processes as well as the modulation of emotional control - by releasing cortisol in our bloodstream. </p>
		<p>By preserving our hippocampus from the attacks of stress, we can better modulate, understand, and work with our emotions constructively. </p>
		<p>When we do, we give ourselves the opportunity to learn how a new, more productive behavior can help ourselves and those around us, memorize the situation and its benefits, and remember how we could repeat this positive behavior in the future. </p>
		<p>Resilient people are able to productively deal with stressful situations, rather than be crushed under pressure. This means that they tend to get back up and keep going when they face setbacks, rather than giving up.</p>
		<p>Resilience is key to achieving our dreams in lives, because doing so will require a lot of time and effort. Giving up too early robs us from the opportunity to fully realize our potential and live truly meaningful lives.</p>

		<h3>Let Emotions And Reason Work Together For You Instead Of Against You</h3>
		<p>To live better lives, we need to learn how to let our emotions and reason work together. This helps us to organize our thoughts, understand our motivations, and choose the best solutions to solve our challenges.</p>
		<p>Focusing on one while ignoring the other is dangerous and may get us into trouble. Reason without passion will likely never get us moving. Emotion without reason may lead us to meaningless paths filled with semblances of happiness.</p>
		<p>The following exercise helps to make emotions and reason work together for you, rather than against you. It was heavily inspired by Tim Ferris’ “Fear-setting” exercise, which he presented in one of his TED Talks, and to which he attributes many of his achievements.</p>
		<p>The first part requires that you reflect on a situation which scares you, something you know you should be doing but are not doing yet.</p>

		<ul>
			<li>Why am I feeling this way? What are my emotions trying to tell me? How may they cloud my judgement?</li>
			<li>How can I benefit from this situation (emotionally, physically, financially, socially, etc.)? What do I have to gain? How will my life be better than today, a year from now? How may others benefit from it?</li>
			<li>What is the cost of inaction (emotionally, physically, financially, socially, etc.)? What do I have to lose? How will my life be worse than today, a year from now or 3 years from now?</li>
		</ul>

		<p>Once you’ve been able to reflect on the situation and its potential benefits, you also need to consider the negative outcomes or setbacks you may face. </p>
		<p>In the second part of this exercise, you need to consider which corrective actions and preventive actions you can leverage, to respectively correct these negative events, should they still happen, or prevent them from happening altogether, ideally.</p>
		<p>Consider everything that may go wrong, from minor inconveniences to major catastrophes. For each of them, reflect on:</p>

		<ul>
			<li>How can I prevent this from happening? What could I do? Who could help me? What should I learn?</li>
			<li>How can I correct these mistakes? What could I do? Who could help me? What should I learn?</li>
		</ul>

		<p>The goal of this second part is to free you from learned helplessness. We need to realize that we are not alone in this. </p>
		<p>Many people have overcome similar trials in the past and we can learn from the lessons they learned. We also can reach out to our loved ones for support and advice. </p>
		<p>Again, you are not alone. Suffering alone will only make us become helpless and hopeless. Reaching out may save us from this unnecessary, miserable destiny.</p>
		<p>In an ideal world, we should be able to prevent disasters from happening. However, some will still likely occur. Such is life. In these instances, having a strategy for how we will deal with them releases us from the paralysis we may experience due to our fears, anxieties, and panics.</p>
		<p>Breaking down what needs to be done and who can help, step by step, is the only way out of the abyss and chaos. </p>
		<p>This step by step approach is essential to make things more manageable and less overwhelming. We can get rid of our debts, one dollar at a time. Over time, all our efforts will compound and yield unimaginable results.</p>
		<p>Reaching out for help in difficult times and offering help to those who need it, when we are facing less challenging times, is key. We can pull each other out of the abyss, instead of taking each other further down.</p>
		
		<h2>Closing Thoughts & Your Next Steps</h2>
		<p>Emotional intelligence is a crucial key to living a richer, more meaningful life filled with strong relationships. There are different ways you can deepen your understanding of emotional intelligence and see how it can help you improve your life and the lives of those around you:</p>

		<Table striped borderless hover responsive className="course-table">
				<thead>
					<tr><th className="course-table-header">Module 1: Introduction To Emotional Intelligence</th></tr>
				</thead>
				
				<tbody className="course-table-body">
					<tr> <td> <a href="/what-is-ei">What is emotional intelligence?</a></td> </tr>
					<tr> <td> <a href="/why-ei-matters">Why does emotional intelligence matter in our lives?</a></td> </tr>
					<tr> <td> <a href="/grow-your-ei">How can you grow your emotional intelligence skills?</a></td> </tr>
					<tr> <td> <a href="/elements-of-trust">The 3 elements of trust: Likability, Expertise, Reliability</a></td> </tr>
					<tr> <td> <a href="/ei-benefits-work">Benefits of emotional intelligence at work</a></td> </tr>
					<tr> <td> <a href="/ei-benefits-home">Benefits of emotional intelligence at home (WIP)</a></td> </tr>					
				</tbody>
			</Table>
		
		<div className="input-group">
			<div className="input-group-append content-btn-box">
				<a href="/ei-course" className="content-btn">Back to the course</a>
			</div>
		</div>
		
		<div className="separator"></div>
		<div className="separator"></div>
   </div>
  )
}
export default StrategiesForEI;

