import React from 'react';
import { Button, Table, Card, CardDeck, Image } from 'react-bootstrap';
import { MyReportProps } from '../components/types';
import { Area, ComposedChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import ReactTimeAgo from 'react-time-ago'
import Scrollspy from 'react-scrollspy';

import { useTranslation } from "react-i18next";


import * as Icon from 'react-bootstrap-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMugHot } from '@fortawesome/free-solid-svg-icons';

import groupoffriends from "../images/group-of-friends.jpg";
import differentpeople from "../images/different-people.jpg";
import comparisonexample from "../images/comparison-example.png";
import testimg from "../images/test-img.jpg";
import assessmentimg from "../images/assessment.jpg";
import chriscomparaisontraits from "../images/chris-traits-comparisons.png";
import teamimg from "../images/conversation1.jpg";



import ReactMarkdown from 'react-markdown';


function row(quizResults, id, completedOn,deleteReportHandler,i18n) {
    const data = [
        {
            name: 'O', score: quizResults.OPENNESS.score,
        },
        {
            name: 'C', score: quizResults.CONSCIENTIOUSNESS.score,
        },
        {
            name: 'E', score: quizResults.EXTROVERSION.score,
        },
        {
            name: 'A', score: quizResults.AGREEABLENESS.score,
        },
        {
            name: 'N', score: quizResults.NERVOSITY.score,
        },

    ];
    return (
        <tr key={id}>
            <td className="d-flex flex-column justify-content-center">
                <Button className="content-btn d-flex flex-row justify-content-center align-items-center w-75 m-auto" href={`/user/premium/view/${id}`}>
                    {i18n.t('read')}
                    <Icon.CardHeading className="ml-2"/> {" "}
                </Button>
                
                <div className="separator" />

                <Button className="content-btn-grey d-flex flex-row justify-content-center align-items-center w-75 m-auto" onClick={()=>deleteReportHandler(id)}>
                    {i18n.t('delete')}
                    <Icon.TrashFill  className="ml-2"/> {" "}
                </Button>

                <div className="separator" />
                <b><ReactTimeAgo date={new Date(completedOn)}/></b>
            </td>

            <td>
                <div style={{
                    height: 200,
                    position: 'relative',
                    minWidth: 200,
                    maxWidth: 700
                }}>
                    <ResponsiveContainer>
                        <ComposedChart
                            data={data}
                            margin={{ top: 2, right: 0, bottom: 2, left: 0, }}
                        >
                            <XAxis dataKey="name"/>
                            <Tooltip/>
                            <Area dataKey="score" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </td>
        </tr>
    )
}

function NoQuizData() {

    const {i18n} = useTranslation();

    return (<div className="masthead">

        <h3>{i18n.t('discover-your-personality-traits')}</h3>

        <div className="content-btn-box">
            <a href="/user/premium/quiz" className="content-btn">{i18n.t('lets-go')}<Icon.ArrowRight className="ml-2" /></a>
        </div>

        <div className="separator" />

        <p>{i18n.t('all-you-need-is-10-15-minutes-and')} <FontAwesomeIcon icon={faMugHot} size="2x" className="ml-2" /> </p>
        <p className="desktop-display-only">{i18n.t('tip-you-can-use-the-keys-1-5-on-your-keyboard-to-answer')}</p>

        <div className="myimg">
            <img src={testimg} alt="Take the test" />
        </div>

        <div className="separator" />

        <div className="content-btn-box">
            <a href="/user/premium/quiz" className="content-btn">{i18n.t('lets-go')}<Icon.ArrowRight className="ml-2" /></a>
        </div>

        <div className="separator" />


    </div>)
}


function MyReports(props : MyReportProps) {

    const {i18n} = useTranslation();

    if (!props.userData || props.userData?.quiz.length === 0) {
        return NoQuizData();
    }

    const sortedReports = props.userData.quiz
        .sort((a,b) => a.completedOn.localeCompare(b.completedOn))
        .reverse();

    return (
        <div className="masthead">

            <div className="myimghor my-0">
				<img src={differentpeople} alt="Different types of people" />
			</div>
            

            <h2>{i18n.t('your-personality-style')}</h2>
                
            <div className="separator" />
            <Table striped borderless hover responsive className="myreports-table">
                <thead>
                <tr>
                    <th className="course-table-header">{i18n.t('report')}</th>
                    <th className="course-table-header">{i18n.t('your-traits-at-a-glance')}</th>
                </tr>
                </thead>
                <tbody className="course-table-body text-center">
                {sortedReports.map(quiz => {

                    return row(quiz.quizResults, quiz.quizId, quiz.completedOn, props.deleteReportHandler,i18n);

                })}
                </tbody>
            </Table>
            
            <div className="separator" />
            <section className="course-tables-center">
                <Card className="hover-increase" style={{ width: '18rem' }}>
                    <Card.Img as={Image} src={assessmentimg} variant="top" className="mb-3" />
                    <Card.Title className="m-3">Want to Take Another Assessment? <Icon.ArrowRight className="ml-2" /></Card.Title>
                    <a href="/user/premium/quiz" className="stretched-link"></a>
                </Card>
            </section>


            <div className="separator" />
            <div className="separator" />

			<div className="myimg">
				<img src={groupoffriends} alt="Group of friends together" />
			</div>

            <h2>{i18n.t('invite-your-family-friends-and-coworkers')}</h2>
            <p>{i18n.t('now-that-youve-learned-more-about-your-style-and-personality-traits-make-sure-you-invite-your-family-friends-and-coworkers-to')} <b>{i18n.t('learn-more-about-their-traits-and-how-you-can-better-interact-with-them')}</b></p>

            <div className="separator" />

            <section className="course-tables-center">
                <CardDeck>
                    <Card className="hover-increase" style={{ width: '18rem' }}>
                        <Card.Img as={Image} src={teamimg} variant="top" className="mb-3" />
                        <Card.Title className="m-3">{i18n.t('get-to-know-your-people')}<Icon.ArrowRight className="ml-2" /></Card.Title>
                        <a href="/user/premium/send-invite" className="stretched-link"></a>
                    </Card>

                    <Card className="hover-increase" style={{ width: '18rem' }}>
                        <Card.Img as={Image} src={chriscomparaisontraits} variant="top" className="mb-3" />
                        <Card.Title className="m-3">See What It Looks Like <Icon.ArrowRight className="ml-2" /></Card.Title>
                        <a href="/user/premium/compare/auth0|5f25d108f476aa00372baf5d" className="stretched-link"></a>
                    </Card>
                </CardDeck>
            </section>

            <div className="separator" />

            <div className="myimg">
                <img src={comparisonexample} alt="Comparing Your traits to Chris" />
            </div>

            <div className="imgcaption">
                <p>{i18n.t('here-is-part-of-what-you-will-learn-about-your-framily-friends-and-coworkers')}</p>
            </div>

            
            <div className="separator" />
            <div className="separator" />
        </div>
    );
}

export default MyReports;