import React from 'react';
import { useTranslation } from "react-i18next";

import feedbackimg from "../../images/feedback.jpg";


function ContactForm() {
  const { i18n } = useTranslation();

    return (
      <div className="masthead">
      
      <h1>{i18n.t('how-can-we-help-how-can-we-improve')}</h1>
      
      <h3>{i18n.t('your-feedback-and-comments-help-us-make-myoceanity-better-for-everyone-thank-you')}</h3>

      <h4> {i18n.t('please-contact-us-at')} <a href="mailto:contact@myoceanity.com">contact@myoceanity.com</a></h4>

      <div className="myimghor">
				<img src={feedbackimg} alt="We want your feedback" />
			</div>

      <div className="separator" />
      <div className="separator" />

      </div>
    );
  }
export default ContactForm;
