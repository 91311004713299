import React from 'react';
import ReactWordcloud from "react-wordcloud";
import {ResultProps} from '../components/types';
import {useTranslation} from "react-i18next";
import i18n from "i18next";

const attributes = (i18n) => {return {
      'AGREEABLENESS': {
        low: [i18n.t('attributes.Sceptical'),i18n.t('attributes.Critical'),i18n.t('attributes.Challenging'),i18n.t('attributes.Demanding')],
        high:[i18n.t('attributes.Trusting'),i18n.t('attributes.Benevolent'),i18n.t('attributes.Compassionate'),i18n.t('attributes.Gentle')]},
      'CONSCIENTIOUSNESS':  {
        low: [i18n.t('attributes.Careless'),i18n.t('attributes.Indolent'),i18n.t('attributes.Disorganized'),i18n.t('attributes.Doubtful')],
        high:[i18n.t('attributes.Meticulous'),i18n.t('attributes.Hard-working'),i18n.t('attributes.Well-organized'),i18n.t('attributes.Accurate')]},
      'EXTROVERSION':  {
        low: [i18n.t('attributes.Lone wolf'),i18n.t('attributes.Quiet'),i18n.t('attributes.Compliant'),i18n.t('attributes.Reserved')],
        high:[i18n.t('attributes.Sociable'),i18n.t('attributes.Talkative'),i18n.t('attributes.Energetic'),i18n.t('attributes.Affectionate')]},
      'NERVOSITY':  {
        low: [i18n.t('attributes.Calm'),i18n.t('attributes.Even-tempered'),i18n.t('attributes.Comfortable'),i18n.t('attributes.Serene')],
        high:[i18n.t('attributes.Worried'),i18n.t('attributes.Sensitive'),i18n.t('attributes.Self-conscious'),i18n.t('attributes.Emotional')]},
      'OPENNESS':  {
        low: [i18n.t('attributes.Pragmatic'),i18n.t('attributes.Cautious'),i18n.t('attributes.Conventional'),i18n.t('attributes.Constant')],
        high:[i18n.t('attributes.Imaginative'),i18n.t('attributes.Creative'),i18n.t('attributes.Original'),i18n.t('attributes.Curious')]}
}};


interface WordEntry{
  text:string
  value:number
}
type WordStructure = Array<WordEntry>;


function words(props : ResultProps, i18n) : WordStructure{

  let words : WordStructure  = [];
  const base : Array<any> = ["OPENNESS","CONSCIENTIOUSNESS","EXTROVERSION","AGREEABLENESS","NERVOSITY"];
  base.forEach((S) =>{
    attributes(i18n)[S].low.forEach(w=> {
      if (props.quizResult[(S)].score<50) {
        words.push({text:w, value:100-props.quizResult[S].score})
      };
    });
    attributes(i18n)[S].high.forEach(w => {
      if (props.quizResult[S].score>=50) {
        words.push({text:w, value:1*props.quizResult[S].score})
      };
    });
  })
  console.log(words);
  return words;
}

const options = {
  colors: ["#ABD2FA", "#7692FF", "#1B2CC1", "#3D518C", "#091540", "#cd9557"],
  enableTooltip: false,
  deterministic: true,
  fontFamily: "helvetica neue",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSizes : [12,60] as [number,number],
  padding: 1,
  rotationAngles: [0,0] as [number,number],
  rotations: 3,
  scale: "linear" as const,
  spiral: "archimedean" as const,
  transitionDuration: 1000
} ;


function AttributeCloud(props : ResultProps) {
  const { i18n } = useTranslation();

  return (
        <div style={{ height: '90%', width: '90%' }}>
          <ReactWordcloud options={options} words={words(props,i18n)} />
        </div>
      )

}
export default AttributeCloud;
