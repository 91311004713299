import React from 'react';
import { Card, CardDeck, Image, Table } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

import * as Icon from 'react-bootstrap-icons';

import globalnetwork from "../images/global-network.png";
import biggerthanourselves from "../images/bigger-than-ourselves.png";
import interdependenceimg from "../images/interdependence.png";
import interpretationimg from "../images/interpretation.png";
import elephantrider from "../images/elephant-rider.jpg";
import dogoodfeelgood from "../images/do-good-feel-good.png";
import joyface from "../images/joy-face.png";
import constructiveemotionsimg from "../images/constructive-emotions.png";
import passportimg from "../images/passport.jpg";
import fulfilledimg from "../images/fulfilled.jpg";
import quoteangeloufeel from "../images/quote-angelou-feel.png";

import quoteangeloufeelfr from "../images/quote-angelou-feel-fr.png";

import i18n from "i18next";

function WhyEIMatters(props: any) {
    const { i18n } = useTranslation();

	const i18nquoteangeloufeel = () => {
		switch (i18n.language) {
			case "fr" :
				return quoteangeloufeelfr
			case "en" :
			default :
				return quoteangeloufeel
		}
	}

	return (
	<div className="masthead">

		<h1>Why Emotional Intelligence Matters in our Lives</h1>
		<p><Icon.CalendarWeek className="mr-3" />Latest update: Feb 25, 2022</p>

		<div className="notabene">
			Please take a moment to reflect on the following: <br />
			Have you ever noticed that you can say <i>the same thing</i> to different people and get <i>completely different</i> answers and reactions?
		</div>

		<p>Why is that? Emotional intelligence gives us some answers. I believe there are 3 reasons why emotional intelligence matters in our lives:</p>

		<ol>
			<li><a href="#actions-consequences">Because our actions have emotional consequences on others.</a></li>
			<li><a href="#elephant-rider">Because we are emotional beings capable of rational thinking.</a></li>
			<li><a href="#interdependence">Because we are interdependent: I need you and you need me.</a></li>
		</ol>

		<hr />

		<h2 id="actions-consequences">1. Actions Have Emotional Consequences</h2>
		<p>American poet and civil rights activist Maya Angelou summarized beautifully why emotional intelligence matters in our lives:</p>
		
		<div className="myimghor">
			<img src={i18nquoteangeloufeel()} alt="Quote by Maya Angelou: People will forget what you say; they will forget what you do; but they will never forget how you made them feel." />
		</div>

		<p>I read this quote for the first time in October 2019. Her words stuck with me ever since. Because I believe we all realize that our actions have consequences. What we may not realize are the <b>emotional consequences of our actions</b>. What happens beneath the surface, which leaves invisible scars. This is the first reason why emotional intelligence is key:</p>
		
		<div className="notabene">
			Our actions have emotional consequences, which others may hold against us.
		</div>

		<p>Emotional consequences are essential, because they are <b>immediate, automatic, and often unconscious</b>. Without realizing it, we may be destroying ourselves and our relationships because of our words and actions. We all know the saying,</p>
		
		<div className="quote">
			<p><i>“Actions speak louder than words.”</i></p>
		</div>

		<p>I believe that <b>emotions speak the loudest</b>, but that we don’t always listen to or understand them well enough.</p>


		<h3>How We React to Experiences</h3>
		<p>Do you recall the situation where different people had different reactions and answers to the same input? Psychology suggests that <b>whenever we go through an experience in life, our brains are interpreting the situation</b>. Comparing it to previous, often similar experiences. Trying to make sense out of it.</p>
		<p>In the blink of an eye, based on our interpretations, we react. Mostly, without being conscious of what we are saying or doing nor how it affects others.</p>
	
		<div className="myimg">
			<img src={interpretationimg} alt="Experience, Interpretation, Reaction" />
		</div>

		<div className="imgcaption">
			<p>At all times, we can choose how to react to a given situation and consider how helpful or harmful the consequences of our actions will be</p>
		</div>
	
		<p>Emotional intelligence helps us become aware of these unconscious mechanisms, within ourselves and others. And it gives us a choice, on how to move away from destructive behaviors and towards more constructive ones.</p>
		<p><b>One way our brains try to interpret the situation is to assess: Am I safe, or am I threatened?</b></p>
		
		<ul>
			<li>When our brains consider that we are safe, emotions stay put. This allows our prefrontal cortex to calmly and clearly analyze the situation and determine the best, logical course of action we should take.</li>
			<li>When our brains consider that we are being threatened, emotions run wild. We become angry, afraid, sad or ashamed. Our emotions hijack our prefrontal cortex, which prevents us from thinking clearly.</li>
		</ul>
		
		<div className="story">
			<p><b>Imagine the following scenario: You are running late for an important flight. As you dash to your front door, luggage in hand, you notice you lost your passport. How are you feeling? What emotion(s) are you feeling?</b></p>
		</div>

		<div className="myimghor mt-1 mb-5">
			<img src={passportimg} alt="Picture of a person holding a blurred passport." />
		</div>

		<ul>
			<li>If you are like me, <b>fear sets in and I start panicking</b>. I frantically search the apartment, throwing stuff everywhere. My only thoughts are “I need to find it. Where is it? This is a disaster!” I don’t even notice that my passport was lying on the table next to my front door.</li>
			<li><b>Others told me they felt angry</b>. At themselves, for losing their passports. Others, at their passports, for disappearing at such a terrible time.</li>
			<li><b>Others said they felt ashamed</b>, because they could not do something as simple as knowing where their passports were.</li>
		</ul>

		<p>This is an example I love using when I speak at conferences or workshops. It clearly demonstrates how different people can feel and react differently to the same situation.</p>
		
		<hr/>


		<h2 id="elephant-rider">2. The Elephant & The Rider</h2>
		<div className="notabene">
			We like to think we are rational beings who feel emotions.
			It seems truer that we are emotional beings capable of rational thinking.
		</div>

		<p>Since thousands of years, philosophers and psychologists claim that our minds can be split into 2 parts: <b>1) Our rational minds, and 2) our emotional minds.</b> I love the metaphor used by Professor Jonathan Haidt from New York University to illustrate this:</p>
		
		<div className="myimgmini mt-4">
			<img src={elephantrider} alt="The Elephant and the Rider"/>
		</div>
		
		<div className="imgcaption">
			<p>One brain, two minds | The elephant and the rider</p>
		</div>

		<ul>
			<li><b>The Rider represents our rational mind</b>: Our ability to envision a potential future, to communicate it to others, to create action plans and to orchestrate them to make this vision become a reality.</li>
			<li><b>The Elephant represents our emotional mind</b>: What moves us towards or holds us back from realizing our potential.</li>
		</ul>

		<p><b>Why are emotions so powerful and critical? I believe this is because they are essential for our survival. As individuals, and as a species.</b></p>
		<p>We experience two main kinds of emotions:</p>

		<ol>
			<li><a href="#positive-emotions">Positive emotions, like love, joy, passion, and motivation.</a></li>
			<li><a href="#constructive-emotions">Constructive emotions, like fear, anger, sadness, and shame.</a></li>
		</ol>

		<h3 id="positive-emotions">Our Positive Emotions | Joy, Passion, Motivation</h3>
		
		<div className="myimgmini">
			<img src={joyface} alt="Woman smiling broadly." />
		</div>
		
		<div className="notabene">
			Positive emotions make us feel connected to each other and to a higher purpose in life. Something bigger than ourselves. Our Mission or cause.
		</div>
		
		<p>When we act in a way which is beneficial to others and/or ourselves, we feel good. Our brains are rewarding us with good feelings through neurotransmitters such as dopamine, serotonin, and oxytocin. This reward system exists to encourage us to repeat beneficial behaviors, which help others and ourselves. This is one reason why eating feels good when we are hungry, or why we get a rush when we progress towards achieving something meaningful to us.</p>
		<p><b>Good behaviors get rewarded by good feelings, which makes us want to repeat them, because we crave the good feeling that derives from doing them.</b></p>
		
		<div className="myimg">
			<img src={dogoodfeelgood} alt="When you do good, then you feel good"/>
		</div>
		
		<div className="imgcaption">
			<p>When you do good, then you feel good</p>
		</div>
		
		<div className="story">
			<p><b>Imagine this: As you are walking down the street, the person in front of you drops something. You pick it up and give it back. How would that feel? Chances are, it feels good, because you helped someone.</b></p>
		</div>

		<p>Research suggests that the person on the receiving end also felt good, because someone helped them. They appreciate your kind gesture, although they may not explicitly express their gratitude. Research also suggests that people who saw your random act of generosity also felt good. They may think, “Hey, that was nice. I should try to help more people too.” It’s their brains encouraging them to do good, because it’s good for the survival of our species.</p>
		
		<section className="course-tables-center my-5">
			<Card className="hover-increase" style={{ width: '18rem' }}>
				<Card.Img as={Image} src={fulfilledimg} variant="top" className="mt-0 mb-3" />
				<Card.Title className="m-3">Fulfillment comes from serving others<Icon.ArrowRight className="ml-2" /></Card.Title>
				<a href="/fulfillment" className="stretched-link"></a>
			</Card>
		</section>

		<h3 id="constructive-emotions">Our Constructive emotions | Fear, Anger, Sadness, Shame</h3>

		<div className="myimghor my-0">
			<img src={constructiveemotionsimg} alt="People displaying fearful, angry, sad, and shameful facial expressions." />
		</div>


		<div className="notabene">
			Constructive emotions act as a warning signal. They are our bodies’ way of telling us that something is wrong. That we need to act to change our circumstances. They are a <i>call to action.</i>
		</div>
		
		<p>They are similar to the physical pain we experience from touching a hot stove. Our bodies want to move away from the source of pain, and want us to learn from our negative and painful experience. To prevent them from hurting us again.</p>
		<p>Here is what I find fascinating about emotions: When we act productively and solve the challenges we are facing, we are rewarded. We feel good. We are encouraged to repeat this constructive behavior. Because it’s good for our sake and for the sake of those around us.</p>
		<p><b>Positive and constructive emotions are meant to work together, for us, if we allow them to </b> by listening to them and correctly answering their call.</p>
		<p>This is the second reason why I believe emotional intelligence matters: <b>Because it helps us realize that our emotions help us solve our problems and make us feel good when we do.</b></p>

		<div className="myimg">
			<img src={dogoodfeelgood} alt="When you do good, then you feel good"/>
		</div>

		<div className="imgcaption">
			<p>Remember, when you do good, then you feel good</p>
		</div>

		<p>Yes, difficult situations are uncomfortable and painful. This discomfort and pain are how our bodies try to tell us, “Do something.”</p>
		<p>Take another look at the image of the elephant and the rider. Is there anything that jumps out to you? When I first saw this image, two thoughts occurred to me:</p>
		
		<div className="myimgmini">
			<img src={elephantrider} alt="The Elephant and the Rider"/>
		</div>
		
		<p>First, how bigger and stronger the elephant seems to be, compared to the rider. This illustrates well emotional hijacking: When the elephant becomes moved by emotions, the rider cannot force him into submission. He is outmatched.</p>
		<p>This explains why talking rationally to someone emotionally affected will not work: Their rider cannot listen to yours, because they are too busy running after their elephant and trying to calm them down.</p>
		<p>Second, it seems that the rider is trying to keep the elephant under control, by keeping it restrained. Because of the differences in strengths between them, the rider can only nudge the elephant into a different direction. His purpose is to guide the elephant back on a better path, should it stray off course.</p>
	
		<hr/>


		<h2 id="interdependence">3. Interdependence & The Importance Of Relationships</h2>
		<div className="notabene">
			Relationships are a key part of our existence.
		</div>
		
		<p>I believe there are 3 reasons why this is so:</p>

		<ol>
			<li>First, <b>there is one "Me" and seven billion "You"</b>.</li>
			<li>Second, <b>everyone is not like me</b>. We all have different ideas, experiences, and personalities. Our differences enrich us.</li>
			<li>Third, <b>I cannot make it on my own</b>. In all likelihood, neither can you. We need to support each other. We need to build lasting and mutually beneficial relationships.</li>
		</ol>

		<p><b>Throughout our lives, we rely on others to achieve what we desire, and vice versa</b>. Stephen Covey, author of “The Seven Habits of Highly Effective People” called this interdependence. I need you and you need me. Together, we can achieve more than we could on our own. I believe we are stronger together. Thanks to our differences.</p>
		<p>Covey argued that we go through three stages in life and at work:</p>

		<ol>
			<li><b>Dependence: I need you.</b></li>
			<li><b>Independence: I don't need you anymore.</b></li>
			<li><b>Interdependence: I take care of you & you take care of me.</b></li>
		</ol>

		<div className="myimg">
			<img src={interdependenceimg} alt="From Dependence To Interdependence"/>
		</div>

		<div className="imgcaption">
			<p>The 3 stages of relationships: Dependence, independence, interdependence</p>
		</div>

		<h5>Stage 1: Dependence</h5>
		<p>Early in life, <b>young children depend on their parents to survive</b>. To fulfill their physiological needs of food, their safety needs of shelter, their emotional needs for love and attention, and to share with them values that will support them in their lives, to become the people they want to become and to realize their potential.</p>
		
		<h5>Stage 2: Indepdendence</h5>
		<p>In teenage years and early adulthood, <b>they seek independence from their parents</b>. They try to forge their own identities and to get the approval from their peers, rather than from their parents.</p>
		<p>However, <b>when they enter the professional world, most people regress somewhat back to dependence</b>. They depend on their teams to teach them how to do their job, how the company works and who to reach out to for help. </p>
		<p>Given enough time and experience, they become independent again, meaning they do not need much supervision to do what is expected of them. </p>
		
		<h5>Stage 3: Interdependence</h5>
		<p>Covey argued that some people unfortunately never move to the last stage, interdependence. While they become productive and capable individuals, their contributions to their teams’ and their organizations’ efforts are limited. </p>
		<p>They convince others to help them achieve their personal objectives, yet have a hard time reciprocating. <b>They fail to realize how mutually beneficial relationships make everyone better off</b>. They are obsessed with serving their personal interests at the expense of the group's.</p>
		
		<p>I like to explain our interdependence with 2 principles:</p>

		<ol>
			<li><a href="#bigger-than-ourselves">We all belong to something bigger than ourselves.</a></li>
			<li><a href="#global-network">We are all nodes in a global network.</a></li>
		</ol>
		

		<h3 id="bigger-than-ourselves">We All Belong To Something Bigger Than Ourselves</h3>
		
		<div className="notabene">
			Something I noticed during my studies and at work was, that we tend to fight internally.
			Within our teams and organizations.
		</div>

		<p><b>I believe we all belong to something bigger than ourselves. </b>Here is one way to look at it:</p>
		
		<div className="myimg">
			<img src={biggerthanourselves} alt="We All Belong To Something Bigger Than Ourselves"/>
		</div>

		<div className="imgcaption">
			<p>We all belong to something bigger than ourselves</p>
		</div>
		
		<ul>
			<li>At the center is <b>me</b>. Because only I can live my life.</li>
			<li>Around me is <b>my team</b>, people who share a common vision and responsibilities.</li>
			<li>Around my team are other teams. Together, we constitute <b>our company</b>.</li>
			<li>Around my company are other people and organizations. Together, we constitute <b>the world</b>.</li>
		</ul>
		
		<p>Fighting inside our team's circle or our company's circle is not the solution. Because when we do so, we don't realize that the real threats lie outside of our corporate walls. Our true competitors are outside of the company. <b>Internal fights only make us weaker to external threats.</b> They make work much harder and take longer than it should. Lack of teamwork slowed people down. It slowed progress, for everyone.</p>
		<p>This is a situation I personally went through when I started working. When I first joined my team, I was solely focusing on my personal performance. I had a hard time understanding how small collaborative efforts would help the team dramatically. When I did, I started contributing more. Together, we achieved much more. We all progressed, together as one team.</p>
		<p>Over the years, I noticed that this "<b>Me versus You</b>" mentality was not limited to the circle of my team. It seemed that others were applying a similar "<b>Us versus Them</b>" mentality. People outside their teams were considered as outsiders and almost as rivals or enemies. This internal competition was hell. It made everything so much harder than necessary. It felt like we spent more time fighting against each other than getting anything done.</p>
		<p>There is an African proverb which summarizes this beautifully. It says:</p>
	
		<div className="quote"> 
			<p><i>"If there is no enemy within, the enemy outside can du us no harm."</i><br /></p>
			<p><strong>African Proverb</strong></p>
		</div>

		<h3 id="global-network">We Are All Nodes In a Global Network</h3>
		
		<div className="notabene">
			We are all part of a larger network, connecting people and opportunities.
		</div>

		<p>Another way I like to look at our interdependence is by looking at how interconnected we are:</p>

		<div className="myimg">
			<img src={globalnetwork} alt="Our Global Network"/>
		</div>

		<div className="imgcaption">
			<p>The world is one giant, interconnected network</p>
		</div>
		
		<ul>
			<li>Each circle represents a person's network of relationships. Its size is determined by the quantity and quality of the relationships within the network.</li>
			<li>The lines connecting the bubbles together represent the people different networks have in common.</li>
			<li>Finally, by tracing a circle around the outmost networks, we can see the world around us.</li>
		</ul>
		
		<hr/>


		<h2>Closing Thoughts & Your Next Steps</h2>
		<p>Emotional intelligence is a crucial key to living a richer, more meaningful life filled with strong relationships. There are different ways you can deepen your understanding of emotional intelligence and see how it can help you improve your life and the lives of those around you:</p>

		<Table striped borderless hover responsive className="course-table">
			<thead>
				<tr><th className="course-table-header">Module 1: Introduction To Emotional Intelligence</th></tr>
			</thead>
			
			<tbody className="course-table-body">
				<tr> <td> <a href="/what-is-ei">What is emotional intelligence?</a></td> </tr>
				<tr> <td> <a href="/grow-your-ei">How can you grow your emotional intelligence skills?</a></td> </tr>
				<tr> <td> <a href="/elements-of-trust">The 3 elements of trust: Likability, Expertise, Reliability</a></td> </tr>
				<tr> <td> <a href="/ei-benefits-work">Benefits of emotional intelligence at work</a></td> </tr>
				<tr> <td> <a href="/ei-benefits-home">Benefits of emotional intelligence at home (WIP)</a></td> </tr>					
				<tr> <td> <a href="/strategies-for-ei">Strategies to build the 4 elements of emotional intelligence (WIP)</a></td> </tr>
			</tbody>
		</Table>

		<div className="input-group">
			<div className="input-group-append content-btn-box">
				<a href="/ei-course" className="content-btn">Back to the course</a>
			</div>
		</div>
		
		<div className="separator"></div>
		<div className="separator"></div>
		
   	</div>
	)
}
export default WhyEIMatters;

