import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

import * as Icon from 'react-bootstrap-icons';
import groupoffriends from "../images/group-of-friends.jpg";
import invitationimg from "../images/invitation.jpg";



function DemoCompareProfile() {
  const { i18n } = useTranslation();


return ( 

<div className="masthead">
  <section className="grey-section">
    <h2 className="text-white font-weight-bold">{i18n.t('this-is-what-your-personal-page-will-look-like-the-below-report-is-based-on-chris-traits-discover-your-traits-by-clicking-on-the-button-below')}</h2>

    <div className="input-group">
        <div className="input-group-append content-btn-box">
            <a href="/user/premium/my-assessments" className="content-btn-white">{i18n.t('discover-your-traits')}<Icon.ArrowRight className="ml-2" /></a>
        </div>
    </div>
    <div className="separator mb-5" />
  </section>

  <div className="separator" />

  <div className="myimghor">
    <img src={groupoffriends} alt="Group of friends together" />
  </div>

  <h2>{i18n.t('these-profiles-are-shared-with-you')}</h2>

  <section className="accordion-width">
  <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th className="course-table-header">{i18n.t('your-people')}</th>
    </tr>
    </thead>
    <tbody className="course-table-body">
    <tr>
      <td><a href="/sample-comparison-report">{i18n.t('discover')} <b>Chris'</b> {i18n.t('traits')} <Icon.ArrowRight/> </a></td>
    </tr>
    </tbody>
  </Table>
  </section>

  <div className="separator" />
  <div className="separator" />

  <a href="/demo-personal-report" className="important-link">{i18n.t('want-to-learn-more-about-your-personality-traits')}<Icon.ArrowRight /></a>

  <div className="separator" />

  <h2>{i18n.t('invite-your-people-and-get-to-know-them-better')}</h2>    

  <div className="input-group">
      <div className="input-group-append content-btn-box">
          <a href="/user/premium/my-assessments" className="content-btn">{i18n.t('discover-your-traits-and-get-started')}</a>
      </div>
  </div>

  <div className="separator" />

  <p><b>{i18n.t('myoceanity-respects-your-privacy-and-will-not-store-or-use-the-email-you-entered-once-the-invitation-has-been-sent')}</b></p>
          
  <h3 className="font-weight-bold">{i18n.t('how-does-it-work')}</h3>
  <p>{i18n.t('after-your-invitation-is-accepted-and-the-recipients-assessment-is-completed-you-will-be-able-to-learn-more-about-their-personality-traits')}</p>

  <div className="myimghor">
    <img src={invitationimg} alt="Invite your family, friends, and coworkers" />
  </div>

  <div className="separator" />
  <div className="separator" />
  <div className="separator" />
  <div className="separator" />

</div>
);
}

export default DemoCompareProfile;