import React from 'react';
import { Card, CardDeck, Image } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';

import { useTranslation } from "react-i18next";

import * as Icon from 'react-bootstrap-icons';

import personalitytraitsranking from "../images/personality-traits-ranking.png";
import conscientiousnesscontinuum from "../images/conscientiousness-continuum.png";
import conscientiousnessexample from "../images/high-conscientiousness-example.png";
import studyingmaterials from "../images/studying-materials.jpg";
import groupoffriends from "../images/group-of-friends.jpg";
import nurturinggrowth from "../images/nurturing-growth.jpg";
import argumentimg from "../images/argument.jpg";
import oceanimg from "../images/ocean.jpg";
import quotefunder from "../images/funder-personality-definition.png";
import quoteassume from "../images/quote-assume.png";


function Oceanology(props: any) {
    const {i18n} = useTranslation();

	return (
		<div>
		<body className="masthead" data-spy="scroll" data-offset="60" data-target="#myNavbar">

			<Scrollspy items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5' ] } currentClassName="is-current" className="sticky-top scrollspy overflow-scroll-horizontal" offset={-50}>
				<li><a href="#section-1" className="scrollspy-link">Top</a></li>
				<li><a href="#section-2" className="scrollspy-link">Why OCEAN</a></li>
				<li><a href="#section-3" className="scrollspy-link">Benefits</a></li>
				<li><a href="#section-4" className="scrollspy-link">Misuses</a></li>
				<li><a href="#section-5" className="scrollspy-link">Your Next Steps</a></li>
			</Scrollspy>
		
		<section id="section-1">
		
		<div className="myimghor my-0">
			<img src={studyingmaterials} alt="People studying" />
		</div>
			
		<h2 className="mt-3">What Are the Big Five Personality Traits? What Does <b>OCEAN</b> Mean?</h2>
		
		<p>Psychologists have studied how our personality traits influence our preferences, motivations, and tendencies since the late 1930s, as well as how to create models which would best capture human characteristics and individual differences between people.</p>
		
		<div className="myimghor mt-4">
			<img src={quotefunder} alt="Ranking the 3 main personality traits models" />
		</div>

		<p>Over decades, psychologists have rigorously grouped thousands of descriptions used in common language into 5 key dimensions, which are known as the Big-Five personality traits and can be remembered with the acronym “OCEAN”:</p>
			
		<ul>
			<li><b>Openness to experience</b></li>
			<li><b>Conscientiousness</b></li>
			<li><b>Extroversion</b></li>
			<li><b>Agreeableness</b></li>
			<li><b>Nervosity</b> (commonly referred to as neuroticism)</li>
		</ul>
			
		<p>This grouping was achieved through factor analysis, a statistical method aiming  at organizing many variables by a few factors that summarize the interrelations among the variables (<a href="https://scholar.google.com/scholar?hl=fr&as_sdt=0%2C5&q=Goldberg+velicer+2006&btnG=">Goldberg and Velicer, 2006</a>).</p>
		<p>For instance, researchers have grouped the following 6 attributes under the <b>Agreeableness</b> personality trait:</p>

		<ol>
			<li>Tender-mindedness</li>
			<li>Altruism</li>
			<li>Trust</li>
			<li>Compliance</li>
			<li>Modesty</li>
			<li>Straightforwardness</li>
		</ol>
		</section>

		<div className="separator" />

		<section id="section-2" className="offset-anchor">

		<h2>Why OCEAN?</h2>

		<div className="myimghor">
			<img src={personalitytraitsranking} alt="Ranking the 3 main personality traits models" />
		</div>

		<div className="imgcaption">
			<p>Google Scholar results for the main 3 personality traits models</p>
		</div>

		<p>2 aspects make the OCEAN personality traits model especially interesting, compared to other models such as DISC or Myers-Briggs:</p>

		<ol>
			<li>It seems to offer <b>the most holistic understanding of how a person experiences life and how they are likely to behave</b> in certain situations;</li>
			<li>It places people’s personality traits <b>on a continuum, rather than giving a binary, black-and-white understanding</b> of who people are.</li>
		</ol>

		<p>This second aspect is crucial, because <b>how we behave in different situations is not entirely fixed</b>. Our traits tend to be relatively stable over time and circumstances, yet <b>we can still choose how we will respond to certain experiences.</b> Let’s take an example:</p>
		
		<ul>
			<li>When we feel at ease, we may behave in a more extroverted manner with people, even those we may not know well or at all. Here, we may be enthused to get to know them, act warmly towards them, and assertively express our ideas and opinions.</li>
			<li>However, feeling nervous may make us shy away from interacting with strangers, therefore behaving in a more introverted manner. Here, we may be more reserved about our private life, try to socialize with a smaller group of people, and seek more quiet spaces to preserve our energy levels.</li>
		</ul>
		</section>

		<section id="section-3" className="offset-anchor">

		<div className="myimghor">
			<img src={oceanimg} alt="Ocean wave on the shore" />
		</div>
		
		<div className="imgcaption text-center">
			{i18n.t('Photo by')} <a href="https://www.pexels.com/@pixabay">Pixabay</a> {i18n.t('from')} <a
				href="https://www.pexels.com/photo/seashore-under-white-and-blue-sky-during-sunset-210205/">Pexels</a>
		</div>

		<h3>Benefits of the OCEAN Model</h3>

		<p>OCEAN personality traits assessments allow individuals to better understand both:</p>
		
		<ol>
			<li><b>The direction of their traits:</b> for example, low-conscientiousness versus high-conscientiousness</li>
			<li><b>The magnitude of their traits:</b> for example, scoring extremely high in conscientiousness, at the 87th percentile</li>
		</ol>

		<p>Take a look at the image below. Let’s split the continuum at its center, which represents a perfectly medium inclination to the conscientiousness personality traits. </p>
		
		<div className="myimghor">
			<img src={conscientiousnesscontinuum} alt="Conscientiousness continuum" />
		</div>

		<div className="imgcaption">
			<p>The conscientiousness personality trait continuum</p>
		</div>

		<ul>
			<li>To the left of the center lies low-conscientiousness;</li>
			<li>To the right of the center lies high-conscientiousness.</li>
		</ul>	
		
		<p>The stronger a person feels connected to one side of the continuum, the more their dot will be placed towards that extreme. This means that they tend to normally behave in a given way, and more rarely in another. <b>Their preferences, motivations, and tendencies are therefore more consistent and easier to predict.</b></p>

		<div className="myimghor">
			<img src={conscientiousnessexample} alt="Example of high-conscientiousness" />
		</div>

		<div className="imgcaption">
			<p>High-conscientiousness person with an 87% score</p>
		</div>

		<p>For instance, <b>a highly-conscientious person is more likely to behave in an organized and industrious manner, rather than improvising and procrastinating</b>. Only rarely will they be destabilized enough to change their preferred style.</p>
		
		<p>This continuum aspect explains why understanding the entire OCEAN personality traits model matters:</p>
		
		<ul>
			<li><b>Because we may need to adjust how we interact with others</b> to make sure that we can strengthen our relationships, our trust, and our collective effectiveness.</li>
			<li>Because how we behave may be influenced by our circumstances and states of mind.</li>
		</ul>
		</section>


		<section id="section-4" className="offset-anchor">
		<div className="myimghor">
			<img src={argumentimg} alt="Couple arguing" />
		</div>

		<div className="imgcaption text-center">
			{i18n.t('Photo by')} <a href="https://www.pexels.com/@vera-arsic-304265">Vera Arsic</a> {i18n.t('from')} <a
				href="https://www.pexels.com/photo/woman-and-man-sitting-on-brown-wooden-bench-984949/">Pexels</a>
		</div>
		

		<h3>2 Misuses of Knowledge of Personality Traits</h3>

		<p>Using a more black-and-white approach, such as, <i>“I am extroverted and you are introverted and that’s it”</i>, can be dangerous as it may create tensions and conflicts. Unfortunately, the MBTI model suffers from such a binary approach.</p>
		
		<h4>Misuse #1: Stereotyping</h4>
		<p><b>The first misuse of personality traits is stereotyping - assuming what people think, feel or do because of their traits</b> - and accusing them of acting poorly because of them. By doing this, we put the relationship at risk.</p>
		
		<div className="myimghor">
			<img src={quoteassume} alt="Quote by Felix Hunger: Never assume, because when you do, you make an ass of u and me." />
		</div>
		
		<h4>Misuse #2: Making Excuses</h4>
		<p><b>The second misuse of personality traits is making excuses for your own behaviors</b>. For example, <i>“I can’t help it that I live in a messy environment, I am low-conscientiousness after all. There is nothing I can do to go against my nature.”</i></p>
		
		<div className="notabene">
			Making excuses robs you from the possibility to learn and develop.
		</div>

		<p>I happen to have low-conscientiousness levels and tend to allow chaos to build into my environment, because it doesn’t bother me all that much. This doesn’t mean that I blindly accept this as my reality. Rather, I make efforts to take care of my environment, because my mess may affect and bother those around me. Ask my family.</p>
		<p><b>I have often been trapped by my lack of organization, forgetting important deadlines and not being able to find crucial information when I urgently needed it.</b> Therefore, I believe learning how not to let my lack of conscientiousness affect my life and work will help me live a more satisfying life.</p>
		</section>

		<section id="section-5" className="offset-anchor">
		<h3>Your Next Steps</h3>
		<p><b>Your OCEAN personality traits shape your life, and your life shapes your personality traits</b>. In this third module, you will learn more about how you can better understand the OCEAN personality traits model and how to take action. Feel free to explore the following topics:</p>

		<section className="course-tables-center mt-4">
			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={groupoffriends} variant="top" className="mb-3" />
					<Card.Title className="m-3">What are your Big Five personality traits?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ocean-personality-traits" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={nurturinggrowth} variant="top" className="mb-3" />
					<Card.Title className="m-3">Grow your EI with MyOceanity<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/myoceanity-and-ei" className="stretched-link"></a>
				</Card>
			</CardDeck>
		</section>
		
		
		</section>

                <div className="content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course</a>
                </div>

		<div className="separator"></div>
		<div className="separator"></div>
		</body>
   		</div>
  	)
}
export default Oceanology;

