import React from 'react';
import { Button, Card, CardDeck, Image, Table } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';
import ReactMarkdown from 'react-markdown'
import { useTranslation } from "react-i18next";

import * as Icon from 'react-bootstrap-icons';

import oceanandei from "../images/ocean-and-ei.png";
import nurturinggrowth from "../images/nurturing-growth.jpg";
import heartheadhandshake from "../images/heart-head-handshake.jpg";
import reflectionimg from "../images/reflection.jpg";
import teamworkimg from "../images/teamwork.png";
import groupoffriends from "../images/group-of-friends.jpg";
import meyouus from "../images/me-you-us.png";
import friendshipimg from "../images/friendship.jpg";
import elementsoftrust from "../images/likability-expertise-reliability.png";
import studyingmaterials from "../images/studying-materials.jpg";
import chrispersonalitytraits from "../images/chris-ocean-traits.png";
import chriscomparaisontraits from "../images/chris-traits-comparisons.png";
import classroomimg from "../images/classroom.jpg";
import quoteassume from "../images/quote-assume.png";
import quotechrisworldwithin from "../images/quote-chris-world-within.png";



function MyOceanityAndEI(props: any) {
    const {i18n} = useTranslation();

  return (
   	<div>
		<body className="masthead" data-spy="scroll" data-offset="60" data-target="#myNavbar">

			<Scrollspy items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5', 'section-6' ] } currentClassName="is-current" className="sticky-top scrollspy overflow-scroll-horizontal" offset={20}>
				<li><a href="#section-1" className="scrollspy-link">Top</a></li>
				<li><a href="#section-2" className="scrollspy-link">Self-Awareness</a></li>
				<li><a href="#section-3" className="scrollspy-link">Self-Development</a></li>
				<li><a href="#section-4" className="scrollspy-link">Social Awareness</a></li>
				<li><a href="#section-5" className="scrollspy-link">Relationships Development</a></li>
				<li><a href="#section-6" className="scrollspy-link">Your next steps</a></li>
			</Scrollspy>

			<div className="myimg">
				<img src={nurturinggrowth} alt="The 4 elements of emotional intelligence" />
			</div>


			<section id="section-1">
			<h2>Grow Your Emotional Intelligence Using Your OCEAN Personality Traits</h2>

			<p>MyOceanity will help you grow your emotional intelligence by leveraging the 4 elements of emotional intelligence:</p>

			<ol>
				<li><b>Self-Awareness</b>: Your ability to notice and understand your emotions, motivations, tendencies, and how they may impact those around you.</li>
				<li><b>Self-Development</b>: Your ability to focus your time, attention, and energy to achieve what matters truly to you in life, and how to get out of your own way.</li>
				<li><b>Social Awareness</b>: The mirror of Self-Awareness: Your ability to notice and understand the emotions, motivations and tendencies of others, how they might differ from your own, and how you can help each other.</li>
				<li><b>Relationships Development</b>: Your ability to use the knowledge of your differences and similarities with other people, in order to build lasting and mutually beneficial relationships with them.</li>
			</ol>
			
			<div className="myimghor">
				<img src={oceanandei} alt="The 4 elements of emotional intelligence" />
			</div>

			<div className="imgcaption">
				<p>Combining the 4 elements of emotional intelligence with OCEAN personality traits</p>
			</div>

			<section className="course-tables-center mt-5">
				<CardDeck>
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={heartheadhandshake} variant="top" className="mb-3" />
					<Card.Title className="m-3">Learn more about emotional intelligence<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/what-is-ei" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={groupoffriends} variant="top" className="mb-3" />
					<Card.Title className="m-3">What are your Big Five personality traits?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ocean-personality-traits" className="stretched-link"></a>
				</Card>
				</CardDeck>
			</section>
			</section>


			<section className="justify-content-center d-flex flex-row mt-5">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>


			<section id="section-2" className="offset-anchor">
			<div className="myimg">
				<img src={reflectionimg} alt="Woman reflecting" />
			</div>


			<h3>1. Grow Your Self-Awareness</h3>
			<p>Assessing your personality traits and reflecting on the story your personal report tells you is a great way to build self-awareness.</p>
			<p>This will help you better understand: </p>
			
			<ol>
				<li>Your motivations and tendencies</li>
				<li>How they impact those around you</li>
				<li>How others may perceive you</li>
			</ol>


			<div className="myimghor">
				<img src={quotechrisworldwithin} alt="When you start understanding how the world within you works, then the world around you starts to make sense." />
			</div>


            <section className="course-tables-center mt-5">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={chrispersonalitytraits} variant="top" className="mb-3" />
					<Card.Title className="m-3">Discover Your Big Five personality Traits<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/user/premium/my-assessments" className="stretched-link"></a>
				</Card>
			</section>

			</section>


			<section className="justify-content-center d-flex flex-row mt-5">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>


			<section id="section-3" className="offset-anchor">

			<div className="myimg">
				<img src={teamworkimg} alt="Chris' OCEAN wave" />
			</div>

			<h3>2. Work on Your Self-Development</h3>
			<ReactMarkdown>In your personal reports and many lessons from the e-learning course, **you will learn ideas and strategies you can test to stretch yourself and to stop sabotaging yourself.**</ReactMarkdown>
			<ReactMarkdown>Self-development and self-care are key to developing your relationships, because your ability to care for and help others grow is diminished when you suffer too much yourself.</ReactMarkdown>

			<section className="course-tables-center">
			<Card className="hover-increase" style={{ width: '18rem' }}>
                        <Card.Img as={Image} src={classroomimg} variant="top" className="mb-3" />
                        <Card.Title className="m-3">Explore the "Fundamentals of EI" Course<Icon.ArrowRight className="ml-2" /></Card.Title>
                        <a href="/ei-course" className="stretched-link"></a>
                    </Card>
			</section>
			</section>


			<section className="justify-content-center d-flex flex-row mt-5">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>


			<section id="section-4" className="offset-anchor">
			<div className="myimg">
				<img src={groupoffriends} alt="Group of friends together" />
			</div>

			<h2>3. Grow Your Social Awareness</h2>
			<ul>
				<li>What are our similarities?</li>
				<li>What are our differences?</li>
				<li>How can our differences enrich us rather than limit us?</li>
				<li>How can your strenghts compensate for my weaknesses, and vice versa?</li>
			</ul>

			<p>These are the questions we need to ask ourselves when getting to know other people. Because of our genes, education, and experiences, we are all slightly different.</p>
			
			<div className="myimg">
				<img src={meyouus} alt="Chris' OCEAN wave" />
			</div>

			<div className="imgcaption">
				<p>Our similarities are represented by the intersection of the 2 circles; our differences by the rest.</p>
			</div>
			
			<p>What makes emotional intelligence challenging is that we tend to forget about our differences and believe that everyone else knows what we know. This is known as the curse of knowledge.</p>
			<p>We know how we would like to be treated and, with good intentions, we do unto others as we would have done unto ourselves. The risk is, that other people with different personality traits would prefer to be treated differently.</p>
			
			<div className="notabene">
				MyOceanity helps you to better understand your key relationships by giving you practical insights and ideas based on your personality traits and theirs.
			</div>

			<div className="myimghor">
				<img src={quoteassume} alt="Quote by Felix Hunger: Never assume, because when you do, you make an ass of u and me." />
			</div>
			
			<ReactMarkdown>Instead of assuming, you can **discuss with the other person which suggestions you could try, and see how you can improve on these recommendations.**</ReactMarkdown>
			<ReactMarkdown>And if you want to help the rest of the MyOceanity community, you can also share your findings with the rest of us! So that we can all become stronger together.</ReactMarkdown>

			<section className="course-tables-center mt-5">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={chriscomparaisontraits} variant="top" className="mb-3" />
					<Card.Title className="m-3">Compare Your Traits With People You Know<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/user/premium/send-invite" className="stretched-link"></a>
				</Card>
			</section>
			</section>


			<section className="justify-content-center d-flex flex-row mt-5">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>


			<section id="section-5" className="offset-anchor">
			<div className="myimghor">
				<img src={friendshipimg} alt="Chris' OCEAN wave" />
			</div>

			<h3>4. Take Care of Your Relationships</h3>
			<p>The last and perhaps most important element of emotional intelligence to build trust is developing your relationships. By treating each other in ways which are meaningful to each of you, the bond that unites you grows stronger over time.</p>
			
			<section className="course-tables-center my-5">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={elementsoftrust} variant="top" className="mb-3" />
					<Card.Title className="m-3">Learn more about the 3 elements of trust<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/what-is-ei" className="stretched-link"></a>
				</Card>
			</section>
			
			<ReactMarkdown>Trust matters in life, because it’s the foundation upon which we can realize our potential together. Patrick Lencioni identified **5 major dysfunctions of a team**, which are:</ReactMarkdown>

			<ol>
				<li>Absence of trust</li>
				<li>Fear of destructive conflict</li>
				<li>Lack of commitment to each other</li>
				<li>Avoidance of accountability</li>
				<li>Inattention to results</li>
			</ol>
			
			<p>As you may expect, these 5 dysfunctional behaviors are a recipe for disaster. Unfortunately, many teams are affected by them. I suspect that families and friendships also suffer from them in a different way.</p>
			<p>What can you do about this problem? You will learn how you can build trust with others, as well as how to address the other 4 dysfunctional behaviors. </p>
			

			
			<p>Lencioni also believes that, with the right mindset and behaviors, we can transform our dysfunctional behaviors into productive ones. When we do, then we:</p>

			<ol>
				<li>Start trusting one another</li>
				<li>Engage in constructive dialogue</li>
				<li>Commit to the decisions the team made</li>
				<li>Hold one another accountable for our efforts</li>
				<li>Focus on contributing to and advancing our common cause rather than on our individual agendas</li>
			</ol>
			</section>
			

			<section className="justify-content-center d-flex flex-row mt-5">
				<span className="small-dot" /><span className="small-dot" /><span className="small-dot" />
			</section>


			<section id="section-6" className="offset-anchor">
			<h2>Closing Thoughts & Your Next Steps</h2>
			<p>Your OCEAN personality traits shape your life, and your life shapes your personality traits. In this third module, you will learn more about the OCEAN personality traits model and how to take action. Feel free to explore the following topics:</p>

			<section className="course-tables-center my-4">
			<CardDeck className="mb-3">
				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={groupoffriends} variant="top" className="mb-3" />
					<Card.Title className="m-3">What are your Big Five personality traits?<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/ocean-personality-traits" className="stretched-link"></a>
				</Card>

				<Card className="hover-increase" style={{ width: '18rem' }}>
					<Card.Img as={Image} src={studyingmaterials} variant="top" className="mb-3" />
					<Card.Title className="m-3">Learn more about the Big Five Model<Icon.ArrowRight className="ml-2" /></Card.Title>
					<a href="/oceanology" className="stretched-link"></a>
				</Card>
			</CardDeck>
			</section>

                <div className="content-btn-box">
                    <a href="/ei-course" className="content-btn">Back to the course <Icon.ArrowRight className="ml-2" /></a>
                </div>
			<br /><br /><br />
			</section>
		</body>
   </div>
  )
}
export default MyOceanityAndEI;

