import React from 'react';
import {QuestionProps} from '../components/types';


function Question(props:QuestionProps) {
    return (
      <div className="question">
        <h4>{props.content}</h4>
      </div>
    );
  }
  export default Question;
