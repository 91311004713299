import React from 'react';


function Events(props: any) {
	   
  return (
   <div className="masthead">
			<h1>Organize an event</h1>

			<div className="separator"></div>

			<p>How can we best learn? Research on inverted classrooms shows that people learn better when they have the chance to </p>

			<ol>
				<li>Become familiar with the materials at their own pace, outside of the classroom, and then </li>
				<li>Engage with the content at a deeper level with their mentors and peers, inside the classroom.</li>
			</ol>

			<p>With this idea in mind, we want to offer the MyOceanity community the chance to dive deeper into the ideas highlighted in our courses on emotional intelligence, time management, energy management, and psychology.</p>
			<p>To make sure that people can challenge their understanding, the mentors, and each other. So that everyone can learn and develop even further, together. Because life-long learning is not done once you’ve finished the e-learning course. It’s just the beginning. There is no certificate to be earned.</p>
			<p>Knowledge needs to breed change in how people think, feel, and act. Without change for the better, there is no true learning, because there is no development.</p>

			<div className="input-group">
                <div className="input-group-append content-btn-box">
                    <a href="/contact" className="content-btn">Contact Us To Organize An Event</a>
                </div>
            </div>

			<h2>Who is this for?</h2>

			<ul>
				<li>Organizations who want to encourage their people’s learning &amp; development</li>
				<li>Schools who want to prepare their students for the workplace</li>
				<li>Team leaders who want to grow their team’s trust levels and teamwork</li>
				<li>And many more!</li>
			</ul>

			<h2>Virtual or In-Person?</h2>
			<p>Conferences and workshops can be organized either in an in-person, or a virtual format.</p>
			<p>One benefit of virtual events is that they can be broken down into shorter, more focused discussions. </p>
			<p>For instance, rather than organizing a full-day event, which would last 4-5 hours, you could setup multiple 1h meetings every week, which would offer your attendees the opportunity to learn, apply and reflect on what they have learned.</p>
			<p>This spaced practice, in which people space out their study over time, increase memorization. </p>

			<h2>Previous Events</h2>

			<ul>
				<li>Lessons learned for students and young professionals | University of Neuchâtel | April 2019</li>
				<li>Introduction to EI and OCEAN personality traits | Neuchâtel | September 2019</li>
				<li>How sharing feeds creativity | TEDxJNJ Allschwil Talk | Basel | November 2019</li>
				<li>Workshop on EI and personality traits | Bern | December 2019</li>
				<li>Introduction to EI and OCEAN personality traits | Lausanne | February 2020</li>
				<li>Workshop on EI and personality traits | Bern | July 2020</li>
				<li>Introduction to EI and OCEAN personality traits | Webinar with Johnson &amp; Johnson Swiss Campuses | August 2020</li>
			</ul>

			<h2>More about inverted classrooms</h2>
			<p>Why were inverted classrooms created? One reason was to allow for more in-depth investigations of the materials. </p>
			<p>By enabling learners to become familiar with the course content in a less stressful context and environment, they are more likely to identify their learning breakdowns, which are the specific parts of the materials that they don’t understand <strong>yet</strong>.</p>
			<p>Then, when the students regroup in the classroom, they have the opportunity to discuss what they understand and what they still struggle with. This also enables the learning breakdowns to be corrected by students who have mastered the materials. </p>
			<p>By challenging and teaching one another, students are more likely to better retain what they have learned. Because teaching someone else is the best way to learn.</p>
			<p>This brings us to a second reason why inverted classrooms were created: To facilitate the creation of stable and connected learning communities. Because interaction between students and with their mentors are crucial.</p>
			<p>Learning communities matter, because ideas must spread into other minds and be brought to life to be truly effective. As people share their experiences and the lessons they’ve learned with the rest of the community, so will the entire group benefit and grow stronger together.</p>
			<p>Collaborative learning is extremely useful, whether online or in classroom group activities, because we all learn differently. For instance, some people learn faster than others. Alternatively, working with other people becomes synergistic, because one person may understand something which another person doesn’t, and vice versa.</p>
			<p>By allowing people to correct each other’s learning breakdowns, both individuals gain greater mastery over the content, which they are more likely to be able to use later. And this is crucial: <b>learning and acting need to go together.</b> </p>

			<div className="separator"></div>
			<div className="separator"></div>

   </div>
  )
}
export default Events;

